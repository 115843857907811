import Profile from "../../pages/profile";
import Language from "../../pages/languageChange";

const routes = [{
    path: "/profile",
    component: Profile
},{
    path: "/language/change",
    component: Language
}];

export default routes;