import React, { Component } from "react";
import { connect } from "react-redux";
// import { setLocale, t } from "react-i18nify";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeaderV2";
import TransPanel from "../../components/transPanelV2";
import LoadingMask from "../../components/loadingMask";
import Modal from "../../components/modal";
import HyperModal from "../../components/HyperModal";
import ErrorIcon from "../../components/ErrorAlert";
import { t } from "../../common/translation";
import { postWalletStatement } from "../../store/ducks/home/actions";
import {
    postTransactionList,
    postTransactionSalesCancel,
} from "../../store/ducks/walletDetails/actions";
import { postAutoSell } from "../../store/ducks/home/actions";
import { postAutoSellAction } from "../../store/ducks/autoSell/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";

import { GenHash } from "../../common/helpers";

import "./programStatement.scss";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import { Button, Switch } from "antd";
import SuccessIcon from "../../components/SuccessAlert";
import NumberInput from "../../components/input/NumberInput";
import arrowWhite from "../../public/images/arrow-white@3x.png";
import DropDownBox from "../../components/input/DropDownBox";

class WalletDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading_visible: false,
            statement_type: true,
            totalPage: 0,
            currentPage: 1,
            transaction_data: [],
            hasMoreItems: true,
        };

        // this.handleRowClick = this.handleRowClick.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
    }

    loadMoreList(e, page) {
        const trans = {};
        let currentPage = parseInt(this.state.currentPage) + 1;

        trans.username = sessionStorage.getItem("email");
        trans.token = sessionStorage.getItem("token");
        // trans.wallet_type = "PAC";
        trans.wallet_type = this.state.staking_type;
        trans.page = currentPage;
        trans.lang = sessionStorage.getItem("lang");
        let hash = GenHash(trans, process.env.REACT_APP_GM_SECRET_KEY);
        trans.hash = hash;
        trans.limit = 15;
        if (currentPage >= this.state.totalPage) {
            this.setState({
                hasMoreItems: false,
                loading: false,
            });
        }

        this.props.postWalletStatement(trans);
    }

    renderItem(item, i) {
        const itemRows = [
            <tr key={i}>
                <td>{item.transDate}</td>
                <td>{item.total_in}</td>
                <td>{item.total_out}</td>
                <td>{item.balance}</td>
            </tr>,
        ];
        return itemRows;
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {
            const { staking_type } = this.props.location.state;

            this.setState({
                staking_type: staking_type,
            });
            const trans = {};
            let currentPage = parseInt(this.state.currentPage);

            trans.username = sessionStorage.getItem("email");
            trans.token = sessionStorage.getItem("token");
            // trans.wallet_type = "PAC"; //staking_type;
            trans.wallet_type = staking_type;
            trans.page = currentPage;
            trans.lang = sessionStorage.getItem("lang");
            let hash = GenHash(trans, process.env.REACT_APP_GM_SECRET_KEY);
            trans.hash = hash;
            trans.limit = 15;

            this.props.postWalletStatement(trans);
        } else {
            this.props.history.push({
                pathname: "/home",
                state: { error: "invalid_statement_type" },
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.autoSellAction != this.props.autoSellAction) {
            this.setState({
                modal_msg: nextProps.autoSellAction.data.msg,
                modal_type:
                    nextProps.autoSellAction.data.rst == "0"
                        ? "error"
                        : "success",
                modal_visible: true,
                // loading_visible: false,
            });
        }

        if (nextProps.postAutoSellResult !== this.props.postAutoSellResult) {
            this.setState(
                {
                    autoSellSetting: nextProps.postAutoSellResult.data,
                    // loading_visible: false,
                },
                () => {
                    sessionStorage.setItem(
                        "autoSellStatus",
                        nextProps.postAutoSellResult.data
                    );
                }
            );
        }

        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "notice-modal") {
                this.setState({
                    showNotice: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName ===
                "cancel-trans-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName ===
                "cancel-trans-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.transaction_list !== this.props.transaction_list) {
            if (nextProps.transaction_list.rst === "1") {
                let array;

                array = this.state.transaction_data;

                nextProps.transaction_list.data.currentPageItems.map(function (
                    item,
                    i
                ) {
                    array.push(item);

                    return array;
                });

                if (
                    this.state.currentPage ===
                    nextProps.transaction_list.data.totalPage
                ) {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                if (nextProps.transaction_list.data.totalPage === "0") {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                this.setState({ loading: false });
                this.setState({ transaction_data: array });
                this.setState({
                    totalPage: nextProps.transaction_list.data.totalPage,
                });
                this.setState({
                    currentPage: nextProps.transaction_list.data.currentPage,
                });
            } else {
                switch (nextProps.transaction_list.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                        this.setState({
                            errMsg: nextProps.transaction_list.msg,
                        });
                        this.props.openModal("error-modal");
                        break;
                }
            }
        }

        if (nextProps.cancel_data !== this.props.cancel_data) {
            if (nextProps.cancel_data.rst === "1") {
                this.props.openModal("success-modal");

                this.setState({
                    successMsg: nextProps.cancel_data.msg,
                    showLoadingModal: false,
                });
            }
        }

        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    render() {
        const {
            transaction_data,

            totalPage,
            currentPage,

            showErrorModal,

            errMsg,

            showSuccessModal,

            successMsg,

            loading_visible,
        } = this.state;

        const { walletType } =
            this.props.location.state === undefined
                ? ""
                : this.props.location.state;

        let allItemRows = [];

        transaction_data.map((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);

            return allItemRows;
        });

        return (
            <div className="dark-theme">
                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}
                <HyperModal
                    category="response"
                    modalVisible={loading_visible}
                    modalType="loading"
                    footer={[]}
                />

                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack="/program/staking"
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={
                        this.state.staking_type +
                        t("programStatement.statement")
                    }
                    arrowLeft={true}
                />
                <div className="offset-md-2 col-md-8 pl-0 pr-0">
                    <div className="program-statement">
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadMoreList}
                            hasMore={this.state.hasMoreItems}
                            initialLoad={false}
                            threshold={15}
                            useWindow={false}
                        >
                            <table className="table">
                                <thead className="statement-header">
                                    <tr>
                                        <th>{t("programStatement.date")}</th>
                                        <th className="text-right">
                                            {t("programStatement.in")}
                                        </th>
                                        <th className="text-right">
                                            {t("programStatement.out")}
                                        </th>
                                        <th className="text-right">
                                            {t("programStatement.balance")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{allItemRows}</tbody>
                            </table>
                        </InfiniteScroll>
                        {/* </table> */}
                        {/* </div> */}
                    </div>
                    {((!loading_visible && totalPage === "1") ||
                        (!loading_visible && currentPage === totalPage)) && (
                        <div className="col-12 text-white">
                            <div className="row">
                                <div className="mx-auto">
                                    <span>
                                        <small>
                                            <b>{t("common.end_of_record")}</b>
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {!loading_visible && totalPage < 1 && (
                        <div className="col-12 text-white">
                            <div className="row">
                                <div className="mx-auto">
                                    <span>
                                        <small>
                                            <b>{t("common.no_record")}</b>
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance } = state;

    return {
        isLoading: walletBalance.isLoading,
        transaction_list: walletBalance.walletStatementList,
    };
};

const mapDispatchToProps = {
    postWalletStatement,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletDetails);
