import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale, t } from "react-i18nify";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";
import HyperModal from "../../components/HyperModal";
import PageHeader from "../../components/PageHeaderV2";

import { postMultinaryTreeGroup } from "../../store/ducks/multinaryTree/actions";

import { GenHash } from "../../common/helpers";

import btnPlus from "../../public/images/btn-plus@3x.png";
import btnMinus from "../../public/images/btn-minus@3x.png";

import "react-accessible-accordion/dist/fancy-example.css";
import "./multinaryTree.scss";

class myGroups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading_visible: false,
            expandedKeys: [],
            searchValue: "",
            autoExpandParent: true,
            sponsor_tree_data: [],
            treeData: [],
            sponsorTreeShow: false,
            screenHeight: window.innerHeight - 80,
            loadingMsg: t("common.loading"),
            groups: [],
            details: {
                totMultinaryBonus: "0.00",
            },
            group_one: {
                accumulate: "0.00",
                total: "0.00",
                bringForward: "0.00",
                new: "0.00",
                first_distribution_percent: 0,
                days_challenge: 0,
                totMultinaryBonus: 0,
                remaining_days: 0,
                leg: [],
            },
            group_two: {
                accumulate: "0.00",
                total: "0.00",
                bringForward: "0.00",
                new: "0.00",
                first_distribution_percent: 0,
                days_challenge: 0,
                totMultinaryBonus: 0,
                remaining_days: 0,
                leg: [],
            },
            accordionItem1ExpandStatus: btnPlus,
            accordionHeading1Css: "",
            accordionItem2ExpandStatus: btnPlus,
            accordionHeading2Css: "",
        };

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `50px`);

        const data = {};

        data.username =
            this.props.location.state === undefined
                ? sessionStorage.getItem("email")
                : this.props.location.state.selectedCommMember;
        data.oriusername = sessionStorage.getItem("email");
        data.lot =
            this.props.location.state === undefined
                ? 1
                : this.props.location.state.lot;
        data.token = sessionStorage.getItem("token");
        data.lang = sessionStorage.getItem("lang");
        data.page = 1;
        data.limit = 5;
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postMultinaryTreeGroup(data);

        this.updateHeight = this.updateHeight.bind(this);
        this.onClickAccordionOne = this.onClickAccordionOne.bind(this);
        this.onClickAccordionTwo = this.onClickAccordionTwo.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentWillMount() {
        setLocale(sessionStorage.getItem("lang"));
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });
        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }

        if (nextProps.multinary_group !== this.props.multinary_group) {
            if (nextProps.multinary_group.rst === "1") {
                this.setState({
                    groups: nextProps.multinary_group.data.community,
                    details: nextProps.multinary_group.data.details,
                    loadingMsg:
                        nextProps.multinary_group.data.community.length === 0
                            ? t("walletDetails.no_record")
                            : "",
                });
            } else {
                switch (nextProps.multinary_group.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        break;
                    case "system_error":
                        this.setState({
                            errMsg: t(
                                "common.err." + nextProps.multinary_group.msg
                            ),
                            errType: "system_error",
                            sponsorTreeShow: true,
                        });
                        break;
                    default:
                        this.setState({
                            errMsg: nextProps.multinary_group.msg,
                        });
                        break;
                }
            }
        }
    }

    updateHeight() {
        this.setState({ screenHeight: window.innerHeight - 80 });
    }

    onClickAccordionOne(e) {
        if (e.length > 0) {
            this.setState({
                accordionItem1ExpandStatus: btnMinus,
                accordionHeading1Css: "bottom__radius",
            });
        } else {
            this.setState({
                accordionItem1ExpandStatus: btnPlus,
                accordionHeading1Css: "",
            });
        }
    }

    handleBack() {
        this.props.history.goBack();
    }

    onClickAccordionTwo(e) {
        if (e.length > 0) {
            this.setState({
                accordionItem2ExpandStatus: btnMinus,
                accordionHeading2Css: "bottom__radius",
            });
        } else {
            this.setState({
                accordionItem2ExpandStatus: btnPlus,
                accordionHeading2Css: "",
            });
        }
    }

    render() {
        const detail_prop = this.props.location;
        const groupsData = this.state.groups.map(function (item, i) {
            return (
                <div
                    className="mt-2 px-3 offset-md-2 col-md-8 px-0"
                    key={`group` + i}
                >
                    <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                            <AccordionItemHeading
                                className={`accordion__heading `}
                            >
                                <AccordionItemButton>
                                    <div className="row">
                                        <div className="col-6 text-light justify-content-center align-self-center">
                                            {t("multinaryTree.group")}{" "}
                                            {item.group}
                                        </div>
                                        <div className="col-6 text-right">
                                            <Link
                                                to={{
                                                    pathname:
                                                        "/friends/group/details",
                                                    state: {
                                                        group: item.group,
                                                        lot: item.lot,
                                                        displayGroup: i,
                                                        member:
                                                            detail_prop.state ===
                                                            undefined
                                                                ? sessionStorage.getItem(
                                                                      "email"
                                                                  )
                                                                : detail_prop
                                                                      .state
                                                                      .selectedCommMember,
                                                    },
                                                }}
                                            >
                                                <div className="depth-blue-btn">
                                                    {t("multinaryTree.details")}
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-6 text-left text-secondary">
                                            {t(
                                                "multinaryTree.accumulative_left"
                                            )}
                                        </div>
                                        <div className="col-6 text-right text-secondary">
                                            {t(
                                                "multinaryTree.accumulative_right"
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 text-left text-light">
                                            {item.l_accumulate}
                                        </div>
                                        <div className="col-6 text-right text-light">
                                            {item.r_accumulate}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 text-left text-secondary">
                                            <strong>
                                                {t("multinaryTree.yesterday")} :
                                            </strong>{" "}
                                            <span className="text-white">
                                                {item.l_group_yesterday_amount}
                                            </span>
                                        </div>
                                        <div className="col-6 text-right text-secondary">
                                            <strong>
                                                {t("multinaryTree.yesterday")} :
                                            </strong>{" "}
                                            <span className="text-white">
                                                {item.r_group_yesterday_amount}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 text-left text-secondary">
                                            <strong>
                                                {t("multinaryTree.today")} :
                                            </strong>{" "}
                                            <span className="text-white">
                                                {item.l_group_today_amount}
                                            </span>
                                        </div>
                                        <div className="col-6 text-right text-secondary">
                                            <strong>
                                                {t("multinaryTree.today")} :
                                            </strong>{" "}
                                            <span className="text-white">
                                                {item.r_group_today_amount}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <strong className="col-6 text-left"></strong>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                        </AccordionItem>
                    </Accordion>
                </div>
            );
        });

        return (
            <div className="myGroups">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    goBackFunc={this.handleBack}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("multinaryTree.group_title")}
                    arrowLeft={true}
                />

                <div className="offset-md-2 col-md-8 ">
                    {/*<Accordion allowZeroExpanded={true}>*/}
                    {/*    <AccordionItem>*/}
                    {/*        <AccordionItemHeading className={`accordion__heading `}>*/}
                    {/*            <AccordionItemButton>*/}
                    {/*                <div className="row">*/}
                    {/*                    <strong className="col-8">{t('multinaryTree.day_challenge')} ({t('multinaryTree.remaining_days', {days: this.state.details.remaining_days})})</strong>*/}
                    {/*                    <div className="col-4 text-right">*/}
                    {/*                        {this.state.details.totMultinaryBonus}*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*    </AccordionItemButton>*/}
                    {/*    </AccordionItemHeading>*/}
                    {/*    </AccordionItem>*/}
                    {/*</Accordion>*/}
                    {/*<Progress*/}
                    {/*    strokeColor={{*/}
                    {/*        from: '#5800FF',*/}
                    {/*        to: '#5800FF',*/}
                    {/*    }}*/}
                    {/*    percent={this.state.details.days_challenge}*/}
                    {/*    status="active"*/}
                    {/*    showInfo={false}*/}
                    {/*/>*/}
                </div>
                <div className="custom-height overflow-auto">
                    {this.state.groups.length > 0 && groupsData}
                </div>

                <HyperModal
                    category="response"
                    modalVisible={this.state.loading_visible}
                    modalType="loading"
                    footer={[]}
                />

                {/* {this.state.groups.length === 0 &&
                    <div className="col-12">
                        <div className="row">
                            <div className="mx-auto">
                                <span>
                                    <small>
                                        <b className="text-white">{this.state.loadingMsg}</b>
                                    </small>
                                </span>
                            </div>
                        </div>
                    </div>
                } */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { multinaryTreeGroup, walletBalance } = state;

    return {
        multinary_group: multinaryTreeGroup.data,
        isLoading: walletBalance.isLoading,
    };
};

const mapDispatchToProps = {
    postMultinaryTreeGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(myGroups);
