import React, { Component } from "react";
// import {setLocale, t} from 'react-i18nify';
import { Link } from "react-router-dom";
import {
    getLanguageList,
    getLanguageFile,
} from "../../../store/ducks/home/actions";
import bgVideo from "../../../public/video/cb video.mp4";
import logo from "../../../public/logo/logo-celebreneur.png";
import "./landingPage.scss";
import { Menu, Dropdown, Icon } from "antd";
import { t, setLanguageFileJson } from "../../../common/translation";
import { connect } from "react-redux";

class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            screenHeight: window.innerHeight,
            screenWidth: window.innerWidth,
            imgWidth: "80%",
            options:
                sessionStorage.getItem("langList") !== null
                    ? JSON.parse(sessionStorage.getItem("langList"))
                    : [
                          { value: "en", name: "ENGLISH" },
                          { value: "zh-CN", name: "简体中文" },
                      ],
            lang:
                sessionStorage.getItem("lang") === null
                    ? "en"
                    : sessionStorage.getItem("lang"),
        };

        this.handleLanguage = this.handleLanguage.bind(this);

        if (sessionStorage.getItem("langList") === null) {
            this.props.getLanguageList();
        }
    }

    componentDidMount() {
        if (sessionStorage.getItem("lang") === null) {
            sessionStorage.setItem("lang", "en");
        }

        // setLocale(sessionStorage.getItem("lang"));
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.language_data !== this.props.language_data) {
            setLanguageFileJson();
        }
        if (nextProps.language_data !== this.props.language_data) {
            if (nextProps.language_data.rst === "1") {
                this.setState({
                    options: nextProps.language_data.data,
                });

                sessionStorage.setItem(
                    "langList",
                    JSON.stringify(nextProps.language_data.data)
                );
            }
        }
    }

    handleLanguage(e) {
        // setLocale(this.state.options[e.key].value);
        this.props.getLanguageFile(this.state.options[e.key].value);
        sessionStorage.setItem("lang", this.state.options[e.key].value);
        this.setState({ lang: this.state.options[e.key].value });
    }

    render() {
        const { options } = this.state;

        const handleLanguage = this.handleLanguage;

        const menu = options.map(function (option, index) {
            return (
                <Menu.Item key={index} onClick={handleLanguage}>
                    {option.name}
                </Menu.Item>
            );
        });
        // const menu = (
        //     <>
        //         <Menu.Item key="0">
        //             <a href="http://www.alipay.com/">1st menu item</a>
        //         </Menu.Item>
        //         <Menu.Item key="1">
        //             <a href="http://www.taobao.com/">2nd menu item</a>
        //         </Menu.Item>
        //         <Menu.Divider />
        //         <Menu.Item key="3">3rd menu item</Menu.Item>
        //     </>
        // );
        return (
            <div id="landingPage">
                <video playsInline autoPlay loop muted className="bg-video">
                    <source src={bgVideo} type="video/mp4" />
                </video>

                <div className="text-right col-12 mt-3">
                    <Dropdown overlay={<Menu>{menu}</Menu>} trigger={["click"]}>
                        <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                        >
                            <Icon type="global" style={{ fontSize: "30px" }} />{" "}
                            {/*<Icon type="down" style={{ fontSize: '20px'}}/>*/}
                        </a>
                    </Dropdown>
                    {/*<DropDownBox*/}
                    {/*    id="withdraw_platform"*/}
                    {/*    size="small"*/}
                    {/*    className="col-3"*/}
                    {/*    name="purchase_method"*/}
                    {/*    options={*/}
                    {/*        options*/}
                    {/*    }*/}
                    {/*    value={lang}*/}
                    {/*    onChange={this.handleLanguage}*/}
                    {/*/>*/}
                    {/*<Select*/}
                    {/*    id="language"*/}
                    {/*    name="language"*/}
                    {/*    className="mt-4 ghost-lang-input"*/}
                    {/*    options={options}*/}
                    {/*    // onChange={this.handleLanguage}*/}
                    {/*    // value={sessionStorage.getItem("lang")}*/}
                    {/*/>*/}
                    {/*<span className="caret"></span>*/}
                </div>

                <div className="landing-logo-panel col-12 text-center">
                    <img src={logo} alt="logo" />

                    <div className="row mt-4">
                        <Link to="/login" className="col-6 pull-left">
                            <button className="depth-blue-btn col-12 text-uppercase">
                                {t("landing.login")}
                            </button>
                        </Link>

                        <Link to="/register" className="col-6 pull-right">
                            <button className="depth-blue-btn col-12 text-uppercase">
                                {t("landing.signup")}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance } = state;

    return {
        language_data: walletBalance.getLanguageList,
        language_data: walletBalance.getLanguageFile,
    };
};

const mapDispatchToProps = {
    getLanguageList,
    getLanguageFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
