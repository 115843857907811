import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Select from "../input/Select/index";
// import { t } from "react-i18nify";
import { t } from "../../common/translation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import RightArrow from "../../public/images/arrow-white@3x.png";
import emallLogo from "../../public/images/header/logo-celebbazaar.png";
import InfoImg from "../../public/icon-info@3x.png";
import StarImg from "../../public/brightstar.svg";

import "./PageHeaderV2.scss";

class PageHeaderV2 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            title,
            titleClass,
            subTitle,
            subTitleClass,
            backgroundClass,
            backgroundHeight,
            language_id,
            language_name,
            language_class,
            onLanguageChange,
            options,

            historyBack,
            historyBackText,
            historyBackClass,
            historyBackLinkClass,
            titleHeader,
            avatar,
            avatarImg,
            /*avatarImgClass,*/
            avatarLevel,
            avatarStar,
            avatarDivClass,
            /*ranking,*/
            walletType,
            textBelowBalance,
            textBelowBalance2,
            extraInfo,
            balance,
            walletLabel,
            arrowLeft,
            arrowRight,
            isLogoHeader,
            historyGoBack,
            availableAmount,
            lockedAmount,
            headerBanner,
            cancelBtnRight,
            redeem,
            redeemUrl,
            redeemProp,
            redeemStatus,
            missionPoint,
            btnRight,
            staking_max_amount,
            averagePurchasePrice,
        } = this.props;

        const stylebackgroundHeight = {
            height: backgroundHeight,
        };

        const headerBannerClass = headerBanner ? "background-header pb-5" : "";

        return (
            <div
                className={`pageHeaderV2 ${headerBannerClass} ${
                    backgroundClass !== undefined ? backgroundClass : ""
                }`}
                style={stylebackgroundHeight}
            >
                {historyBack && arrowRight && (
                    <div>
                        <div className={historyBackClass}>
                            <Link to={historyBack}>
                                <span className={historyBackLinkClass}>
                                    {" "}
                                    {historyBackText}{" "}
                                    <img
                                        className="fa-pull-right mt-1"
                                        alt={1}
                                        src={RightArrow}
                                        width={10}
                                    />
                                </span>
                            </Link>
                        </div>
                        <div className="row col-12 pt-4 mx-0 text-light">
                            {title && (
                                <h4
                                    className={`${titleHeader} header-white-color`}
                                >
                                    {title}
                                </h4>
                            )}
                        </div>
                    </div>
                )}

                {historyBack !== undefined && (
                    <div className="container-fluid header_background">
                        <nav className="navbar text-light px-0">
                            {arrowLeft && (
                                <div
                                    className={`${historyBackClass} position-absolute`}
                                >
                                    <Link to={historyBack}>
                                        <span className={historyBackLinkClass}>
                                            {" "}
                                            {historyBackText}{" "}
                                            <FontAwesomeIcon
                                                icon={faArrowLeft}
                                            />
                                        </span>
                                    </Link>
                                </div>
                            )}
                            {/* {redeem && 
                        <div className={`${historyBackClass} position-absolute`}>
                            <Link to={historyBack}>
                                <span className="fa-pull-right mt-1"> {historyBackText} <FontAwesomeIcon
                                    icon={faArrowLeft}/></span>
                            </Link>
                        </div>
                        } */}

                            {title && (
                                <div className={`pt-2 col-12`}>
                                    <div className="header text-uppercase">
                                        <h4
                                            className={`${titleHeader} title text-white`}
                                        >
                                            {title}
                                        </h4>
                                    </div>
                                </div>
                            )}
                            {btnRight && (
                                <div className={`position-absolute right-0`}>
                                    {btnRight}
                                </div>
                            )}
                        </nav>
                    </div>
                )}

                {historyBack === undefined && (
                    <div className="container-fluid header_background">
                        <nav className="navbar text-light px-0">
                            {arrowLeft && (
                                <div
                                    className={`${historyBackClass} position-absolute`}
                                    onClick={this.props.goBackFunc}
                                >
                                    <span className={historyBackLinkClass}>
                                        {" "}
                                        {historyBackText}{" "}
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </span>
                                </div>
                            )}

                            {title && redeem && (
                                <div className={`pt-2 col-12`}>
                                    {redeemStatus == "ACTIVE" && (
                                        <Link
                                            to={{
                                                pathname: redeemUrl,
                                                state: { doc_no: redeemProp },
                                            }}
                                        >
                                            <small className="text-blue">
                                                <a className="position-absolute right-0 redeem text-capitalize">
                                                    {t("common.redeem")}
                                                </a>
                                            </small>
                                        </Link>
                                    )}
                                    <div className="header text-uppercase">
                                        <h5
                                            className={`${titleHeader} title text-white`}
                                        >
                                            {title}{" "}
                                        </h5>
                                    </div>
                                </div>
                            )}

                            {redeem === undefined && title && (
                                <div className={`pt-2 col-12`}>
                                    <div className="header text-uppercase">
                                        <h4
                                            className={`${titleHeader} title text-white`}
                                        >
                                            {title}
                                        </h4>
                                    </div>
                                </div>
                            )}

                            {cancelBtnRight && (
                                <div className={`position-absolute right-0`}>
                                    {cancelBtnRight}
                                </div>
                            )}
                        </nav>
                    </div>
                )}

                {options && (
                    <div className="text-right">
                        <Select
                            id={language_id}
                            name={language_name}
                            className={language_class}
                            options={options}
                            onChange={onLanguageChange}
                        />
                    </div>
                )}

                {title && !historyBack && isLogoHeader && (
                    <div className={`${titleClass} header`}>
                        <img src={emallLogo} height={120} alt="" />
                    </div>
                )}

                {/* {(title && !historyBack && !isLogoHeader) && (
                    <div className={`${titleClass} header`}>
                        <h4 className={`${titleHeader} title text-white text-uppercase`}>{title}</h4>
                    </div>
                )} */}

                {subTitle && (
                    <div>
                        <div className={`fa-pull-left mt-4 ml-3 sub-title-div`}>
                            {historyGoBack === undefined && (
                                <Link onClick={this.props.goBackFunc}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Link>
                            )}

                            {historyGoBack !== undefined && (
                                <Link onClick={this.props.historyGoBack}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Link>
                            )}
                        </div>
                        <div className={`${subTitleClass} text-left pt-4 pl-3`}>
                            <div className="container header">
                                <h4 className="col-12 text-center title text-white text-uppercase">
                                    {subTitle}
                                </h4>
                            </div>
                        </div>
                    </div>
                )}

                {avatarImg && <div style={{ zIndex: 100 }}>{avatar}</div>}

                {walletType && (
                    <div
                        className={`${avatarDivClass} pt-5 offset-md-2 col-md-8 px-0`}
                    >
                        <h3 className={avatarLevel}>
                            {balance} {walletType}
                        </h3>

                        {staking_max_amount &&
                        parseInt(staking_max_amount) !== 0 ? (
                            <small className={avatarLevel}>
                                {t("stakingProgram.max_capacity_amount")}:{" "}
                                {parseFloat(staking_max_amount).toFixed(8)}
                            </small>
                        ) : (
                            ""
                        )}

                        {averagePurchasePrice && averagePurchasePrice !== 0 ? (
                            <small className={avatarLevel}>
                                {t("convert.avg_purchase_price")}:
                                {averagePurchasePrice}
                            </small>
                        ) : (
                            ""
                        )}

                        <div className="row col-12 px-4 mx-0">
                            {textBelowBalance2 && (
                                <div
                                    className={`${walletLabel} mx-auto py-2 col-12`}
                                >
                                    <small className="text-light text-uppercase">
                                        {textBelowBalance2}
                                    </small>
                                    &nbsp;
                                </div>
                            )}

                            <div className={`${walletLabel} py-2 col-6`}>
                                <div className="col-12">
                                    <small className="col-12 text-light text-uppercase fa-pull-left">
                                        {t("common.available")}
                                    </small>
                                    <small className="col-12 text-light text-uppercase fa-pull-left">
                                        {availableAmount}
                                    </small>
                                </div>
                            </div>

                            <div className={`${walletLabel} py-2 col-6`}>
                                <div className="col-12">
                                    <small className="col-12 text-light text-uppercase fa-pull-right">
                                        {t("common.locked")}
                                    </small>
                                    <small className="col-12 text-light text-uppercase fa-pull-right">
                                        {lockedAmount}
                                    </small>
                                </div>
                            </div>

                            {textBelowBalance && (
                                <div
                                    className={`${walletLabel} mx-auto py-2 col-12`}
                                >
                                    <small className="text-light text-uppercase">
                                        {textBelowBalance}
                                    </small>
                                    &nbsp;
                                    {extraInfo === true && (
                                        <Link
                                            to={{
                                                pathname: "/staking/history",
                                                state: {
                                                    walletType: walletType,
                                                },
                                            }}
                                        >
                                            <img
                                                src={InfoImg}
                                                width={20}
                                                alt=""
                                            />
                                        </Link>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {missionPoint >= 0 && (
                    <div
                        className={`${avatarDivClass} pt-5 offset-md-2 col-md-8 px-0`}
                    >
                        <h3 className={avatarLevel}>{missionPoint}</h3>
                    </div>
                )}

                {avatarStar && (
                    <div className="col-12 row mx-auto">
                        <div className={avatarStar}>
                            <img
                                src={StarImg}
                                width={10}
                                alt={1}
                                className="mb-1"
                            />
                            <img
                                src={StarImg}
                                width={10}
                                alt={1}
                                className="mb-1"
                            />
                            <img
                                src={StarImg}
                                width={10}
                                alt={1}
                                className="mb-1"
                            />
                            <img
                                src={StarImg}
                                width={10}
                                alt={1}
                                className="mb-1"
                            />
                            <img
                                src={StarImg}
                                width={10}
                                alt={1}
                                className="mb-1"
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default PageHeaderV2;

PageHeaderV2.propTypes = {
    backgroundClass: PropTypes.string,
    title: PropTypes.string,
    titleClass: PropTypes.string,
    titleHeader: PropTypes.string,
    subTitle: PropTypes.string,
    subTitleClass: PropTypes.string,
    backgroundHeight: PropTypes.number, //Standard height backgroundHeight={230}
    options: PropTypes.string,
    onLanguageChange: PropTypes.func,
    language_id: PropTypes.string,
    language_name: PropTypes.string,
    language_class: PropTypes.string,

    historyBack: PropTypes.string,
    historyBackText: PropTypes.string,
    historyBackClass: PropTypes.string,
    historyBackLinkClass: PropTypes.string,
    avatarImg: PropTypes.string,
    avatarImgClass: PropTypes.string,
    avatarLevel: PropTypes.string,
    avatarStar: PropTypes.string,
    avatarDivClass: PropTypes.string,
    walletType: PropTypes.string,
    textBelowBalance: PropTypes.string,
    textBelowBalance2: PropTypes.string,
    balance: PropTypes.string,
    walletLabel: PropTypes.string,
    arrowLeft: PropTypes.bool,
    arrowRight: PropTypes.bool,
    extraInfo: PropTypes.string,
    isLogoHeader: PropTypes.bool,
    historyGoBack: PropTypes.func,
    availableAmount: PropTypes.string,
    lockedAmount: PropTypes.string,
    headerBanner: PropTypes.bool, //True to have header banner like walletDetails
    goBackFunc: PropTypes.func,
    cancelBtnRight: PropTypes.string,
    redeem: PropTypes.bool,
    redeemUrl: PropTypes.string,
    redeemProp: PropTypes.string,
    redeemStatus: PropTypes.string,
    missionPoint: PropTypes.any,
    averagePurchasePrice: PropTypes.any,
};
