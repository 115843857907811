import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
    cancel_data: []
};

const missionPointReducer = createReducer(initialState) ({

    [type.GET_MISSION_POINT_LIST]: (state, action) => ({
        ...state,
        data: action.payload.data,
    })


});

export default missionPointReducer;