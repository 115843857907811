import WalletDetails from "../../pages/walletDetails";
import StakingHistory from "../../pages/stakingHistory";

const routes = [{
    path: "/wallet/details",
    component: WalletDetails
},
    {
        path: "/staking/history",
        component: StakingHistory
    }];

export default routes;