import TradingHistory from "../../pages/tradingHistory";

const routes = [
    {
        path: "/market/history",
        component: TradingHistory,
    },
];

export default routes;
