import rewards from "../../pages/rewards";
import rewardDetails from "../../pages/rewardDetails/index";

const routes = [
    {
        path: "/rewards",
        component: rewards,
    },
    {
        path: "/reward/details",
        component: rewardDetails,
    },
];

export default routes;
