import React, { Component } from "react";
import { Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import QRCode from "qrcode.react";
import { setLocale, t } from "react-i18nify";
import { message, Tabs } from "antd";
import copy from "copy-to-clipboard";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { openModal, closeModal } from "../../../store/ducks/modal/actions";
import { postWalletAddress } from "../../../store/ducks/deposit/actions";
import { getMarketPrice } from "../../../store/ducks/globalTrend/actions";
import { postWalletBalance } from "../../../store/ducks/home/actions";

import LoadingMask from "../../../components/loadingMask";
import Modal from "../../../components/modal";
import ErrorIcon from "../../../components/ErrorAlert";
import NumberInput from "../../../components/input/NumberInput";
import PageHeader from "../../../components/PageHeader/index";

import CopyIcon from "../../../public/images/icon-copy@3x.png";

import {
    GenHash,
    toFixedTrunc,
    amountChecking2,
} from "../../../common/helpers";

import "./convert.scss";

class Convert extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: sessionStorage.getItem("email"),
            token: sessionStorage.getItem("token"),
            data: [],
            price_data: [],
            qr_path: "",
            setOpen: false,
            open: false,
            address: "",
            walletType: "",
            cryptoAddress: "",
            showErrorModal: false,
            errMsg: "",
            errType: "",
            estimated_amount: 0,
            amount_usdt: "",
            wallet_balance_data: [],
            display: "",
            showLoadingModal: false,
        };

        this.handleTooltipClose = this.handleTooltipClose.bind(this);
        this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleAmount = this.handleAmount.bind(this);
    }

    componentDidMount() {
        setLocale(sessionStorage.getItem("lang"));

        if (this.props.location.state !== undefined) {
            const data = {};
            const walletBalance = {};

            data.username = sessionStorage.getItem("email");
            data.token = sessionStorage.getItem("token");
            data.cryptoCode = this.props.location.state.walletType;

            let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
            data.hash = hash;

            walletBalance.username = sessionStorage.getItem("email");
            walletBalance.token = sessionStorage.getItem("token");
            let balance_hash = GenHash(
                walletBalance,
                process.env.REACT_APP_GM_SECRET_KEY
            );
            walletBalance.hash = balance_hash;

            this.props.openModal("loading-modal");

            this.setState({
                showLoadingModal: true,
            });

            this.props.postWalletAddress(data);

            this.props.postWalletBalance(walletBalance);

            this.props.getMarketPrice();

            this.setState({
                walletType: this.props.location.state.walletType,
                cryptoAddress: this.state.cryptoAddress,
            });
        } else {
            this.props.history.push({
                pathname: "/home",
                state: { error: "invalid_wallet_type_details" },
            });
        }

        this.props.closeModal("deposit-modal");
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "loading-modal") {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.wallet_balance_data !== this.props.wallet_balance_data) {
            //Check login success update the routes for react
            if (nextProps.wallet_balance_data.rst === "1") {
                let array;
                let display = "";

                array = nextProps.wallet_balance_data.data;
                array.walletSetupList.map(function (balance_item, i) {
                    if (
                        balance_item.currencyCode ===
                        nextProps.location.state.walletType
                    ) {
                        if (balance_item.showCalculator === "0") {
                            display = "not_display";
                        }
                    }

                    return i;
                });

                this.setState({
                    wallet_balance_data: array.walletSetupList,
                    display: display,
                });
            }
        }

        if (nextProps.market_data !== this.props.market_data) {
            if (nextProps.market_data.rst === "1") {
                let array;
                array = nextProps.market_data.data;

                this.setState({ price_data: array });
            }
        }

        if (nextProps.deposit_data !== this.props.deposit_data) {
            if (nextProps.deposit_data.rst === "1") {
                this.setState({
                    address: nextProps.deposit_data.data.cryptoAddress,
                    showLoadingModal: false,
                });
            } else {
                switch (nextProps.deposit_data.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                        this.setState({
                            errMsg: nextProps.deposit_data.msg,
                        });
                        this.props.openModal("error-modal");
                        break;
                }
            }
        }
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleTooltipClose = () => {
        this.setState({ open: false });
    };

    handleTooltipOpen = () => {
        copy(this.state.address);

        message.success(t("copied"));
    };

    handleTabChange(event) {
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.cryptoCode = event;

        let display = "";
        let crypto = event;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.openModal("loading-modal");

        this.setState({
            showLoadingModal: true,
        });

        this.props.postWalletAddress(data);

        this.state.wallet_balance_data.map(function (balance_item, i) {
            if (balance_item.currencyCode === crypto) {
                if (balance_item.showCalculator === "0") {
                    display = "not_display";
                }
            }

            return i;
        });

        this.setState({
            display: display,
            amount_usdt: "",
            estimated_amount: 0,
            walletType: event,
            cryptoAddress: this.state.cryptoAddress,
        });
        this.props.closeModal("deposit-modal");
    }

    handleAmount(event) {
        let usdt_price = 0;
        let crypto_price = 0;
        let crypto = this.state.walletType;

        this.state.price_data.map(function (item, i) {
            if (item.cryptoType === "USDT") {
                usdt_price = item.price;
            }

            if (item.cryptoType === crypto) {
                crypto_price = item.price;
            }

            return i;
        });

        if (amountChecking2(event.target.value)) {
            this.setState({
                estimated_amount:
                    (event.target.value * usdt_price) / crypto_price,
                amount_usdt: event.target.value,
            });
        }
    }

    render() {
        const {
            address,
            walletType,
            cryptoAddress,
            showErrorModal,
            errMsg,
            estimated_amount,
            amount_usdt,
            display,
            showLoadingModal,
        } = this.state;

        const { TabPane } = Tabs;

        return (
            <div id="convert">
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                <div className="container-fluid px-0">
                    <PageHeader
                        subTitle={t("deposit.deposit")}
                        navClass="title white-color"
                        backgroundHeight={80}
                        subPageHistoryBack="/home"
                        arrowLeft={true}
                    />
                </div>

                <Tabs
                    defaultActiveKey={
                        this.props.location.state === undefined
                            ? this.props.history.push("/home")
                            : this.props.location.state.walletType
                    }
                    onTabClick={this.handleTabChange}
                >
                    <TabPane tab={t("convert.buy_btc")} key="BTC"></TabPane>
                    <TabPane tab={t("convert.buy_eth")} key="ETH"></TabPane>
                    <TabPane tab={t("convert.buy_acm")} key="ACM"></TabPane>
                    <TabPane tab={t("convert.buy_usdt")} key="USDT"></TabPane>
                    <TabPane tab="" disabled key="key4"></TabPane>
                </Tabs>

                <div>
                    <div className="col-7 col-md-4 col-lg-4 mx-auto">
                        <div className="bg-white pt-2 pb-2">
                            <div className="text-center mt-3 mb-3">
                                {address !== "" && (
                                    <QRCode value={address} size={150} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body text-center pt-1 pb-1 word-wrap">
                    <div className="pr-3 pl-3">
                        <strong id="address">{address}</strong>
                    </div>
                </div>

                <div className="col-12 text-center pt-3" id="TooltipExample">
                    <ClickAwayListener onClickAway={this.handleTooltipClose}>
                        <div>
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={this.handleTooltipClose}
                                open={this.state.open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                placement="top"
                                title="Copy"
                            >
                                <strong>
                                    {t("deposit.your")}{" "}
                                    {t("convert." + walletType)} {cryptoAddress}{" "}
                                    {t("deposit.address")}
                                    <img
                                        onClick={this.handleTooltipOpen}
                                        className="mb-1"
                                        src={CopyIcon}
                                        width={15}
                                        alt=""
                                    ></img>
                                </strong>
                            </Tooltip>
                        </div>
                    </ClickAwayListener>
                </div>

                {display !== "not_display" && (
                    <div>
                        <div className="card pt-2 pb-2">
                            <div className="card-body pt-1 pb-1">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <span className="convert-note">
                                            <strong>
                                                {t("convert.convert_note")}
                                            </strong>
                                        </span>
                                    </div>
                                </div>

                                <form
                                    className="form-bg-transparent txt-black needs-validation"
                                    noValidate
                                >
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <label
                                                htmlFor="username"
                                                className="form-label text-uppercase"
                                            >
                                                <b>
                                                    {t("convert.amount_usdt")}
                                                </b>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <NumberInput
                                                id="amount"
                                                name="amount"
                                                className="remove-placeholder"
                                                value={amount_usdt}
                                                placeholder={t(
                                                    "convert.please_enter_amount_usdt"
                                                )}
                                                onChange={this.handleAmount}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <span>
                                                <strong>
                                                    {t("convert.estimated")}{" "}
                                                    {walletType}{" "}
                                                    {t("convert.amount")}
                                                </strong>
                                            </span>
                                        </div>
                                        <div className="col-12 text-center">
                                            <strong className="form-label">
                                                {toFixedTrunc(
                                                    estimated_amount,
                                                    8
                                                )}
                                            </strong>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { deposit, modal, market, walletBalance } = state;

    return {
        deposit_data: deposit.data,
        classIsShowing: modal.classIsShowing,
        market_data: market.data,
        wallet_balance_data: walletBalance.data,
    };
};

const mapDispatchToProps = {
    postWalletBalance,
    closeModal,
    postWalletAddress,
    openModal,
    getMarketPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Convert);
