import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

class DropDownBox extends Component {

    render() {
        const { id, name, className, onChange, size, required, value, disabled, defaultValue, type } = this.props;

        return (
            <Select
                defaultValue={defaultValue}
                onChange={onChange}
                value={value}
                size={size}
                style={{ width: '100%' }}
                id={id}
                name={name}
                className={className}
                required={required}
                disabled={disabled}
            >
                {/*{this.props.options.map((option, index) =>*/}
                {/*    <Option key={index} value={option.value}>{option.name}</Option>*/}
                {/*)}*/}
                {this.props.options.map(function (option, index) {
                    if (type === 'special' && option.show){
                        return <Option key={index} value={option.value}>{option.name}</Option>;
                    }
                    if (type === undefined){
                        return <Option key={index} value={option.value}>{option.name}</Option>;
                    }
                })}
            </Select>
        )
    }
}

export default DropDownBox;

DropDownBox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.any,
    style: PropTypes.object,
    value: PropTypes.any,
    size: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string
};

DropDownBox.defaultProps = {
    className: '',
    style: '',
};
