import * as type from './types';
import axios from 'axios';


/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Aun
 */
export const postTransactionList = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/wallet/transaction/list', {
        "token":data['token'],
        "username": data['username'],
        "walletType": data['walletType'],
        "viewType": data['viewType'],
        "page": data['page'],
        "hash": data['hash'],
        "lang": data['lang']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_WALLET_TRANSACRION,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_WALLET_TRANSACRION,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const getStakingHistory = (data) => dispatch => {
    axios.get(process.env.REACT_APP_GM_URL + '/member/staking/price/average', {
        params: data,
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_STAKING_HISTORY,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_STAKING_HISTORY,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postTransactionSalesCancel = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/wallet/transaction/cancel', data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_TRANS_CANCEL,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_TRANS_CANCEL,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};
