import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const subscribePinReducer = createReducer(initialState) ({

    [type.POST_SUBSCRIBE_PIN]: (state, action) => ({
        ...state,
        post_subscribe_pin: action.payload,
    }),


});

export default subscribePinReducer;