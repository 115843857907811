import React, { Component } from "react";
import { setLocale, t } from "react-i18nify";
import { connect } from "react-redux";

import PageHeader from "../../components/PageHeader";
import NumberInput from "../../components/input/NumberInput";
import PasswordInput from "../../components/input/PasswordInput";
import LoadingMask from "../../components/loadingMask";
import Modal from "../../components/modal";

import { postWalletBalance } from "../../store/ducks/home/actions";
import { postEmallConvert } from "../../store/ducks/emallConvert/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";

import { formValidation } from "../../common/validator/formValidation";

import { amountChecking1, fieldChecking, GenHash } from "../../common/helpers";

// import ErrorIcon from "../../../public/images/mark-cancel.png";
// import SuccessIcon from "../../../public/images/mark-check.png";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";

import "./emallConvert.scss";

class EmallConvert extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            s_password: "",
            address: "",
            amount: "",
            balance: 0,
            showLoadingModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            minAmount: "1",
            errMsg: "",
            successMsg: "",
            current_price: "0.00",
            converted_amount: "0",
            walletTo:
                props.location.state.walletTo === undefined
                    ? this.props.history.push({
                          pathname: "/emall",
                          state: { error: "invalid_staking_entry" },
                      })
                    : props.location.state.walletTo,
            walletFrom:
                props.location.state.walletFrom === undefined
                    ? this.props.history.push({
                          pathname: "/emall",
                          state: { error: "invalid_staking_entry" },
                      })
                    : props.location.state.walletFrom,
        };

        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.walletType = props.location.state.walletFrom;
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postWalletBalance(data);

        this.onChange = this.onChange.bind(this);
        this.handleSubmitConvert = this.handleSubmitConvert.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
    }

    componentDidMount() {
        formValidation("staking-form");
        setLocale(sessionStorage.getItem("lang"));
    }

    handleSubmitConvert(event) {
        event.preventDefault();

        var form = document.getElementById("staking-form");

        var validate = fieldChecking(form);

        if (validate === false) {
            return false;
        }

        const data = [];
        var hash = "";

        data["quantity"] = this.state.amount;
        data["secondaryPassword"] = this.state.s_password;
        data["username"] = sessionStorage.getItem("email");
        data["salesType"] = this.props.location.state.productCode;
        hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data["hash"] = hash;

        this.props.openModal("staking-loading-modal");

        this.props.postEmallConvert(data);
    }

    onChange(e) {
        const { name, value } = e.target;

        if (
            (name === "amount" && amountChecking1(value)) ||
            name !== "amount"
        ) {
            this.setState({ [name]: value });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName === "staking-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.data !== this.props.data) {
            if (nextProps.data.rst === "1") {
                var array;
                array = nextProps.data.data;

                this.setState({ data: array.walletBalance[0] });
            }
        }

        if (
            nextProps.post_emall_convert_data !==
            this.props.post_emall_convert_data
        ) {
            let msg = "";

            if (nextProps.post_emall_convert_data.rst === "1") {
                const data = {};
                data.username = sessionStorage.getItem("email");
                data.token = sessionStorage.getItem("token");
                data.walletType = this.props.location.state.walletFrom;

                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;

                this.setState({
                    amount: "",
                    s_password: "",
                    showLoadingModal: false,
                    successMsg: t(
                        "emallConvert." + nextProps.post_emall_convert_data.msg
                    ),
                });

                this.props.openModal("success-modal");

                this.props.postWalletBalance(data);
            } else {
                msg = t(
                    "emallConvert." + nextProps.post_emall_convert_data.msg
                );
                this.setState({
                    showLoadingModal: false,
                    errMsg: msg,
                });

                this.props.openModal("error-modal");
                /*switch(nextProps.staking_data.msg){
                    case "please_enter_amount" :
                        msg = t('staking.please_enter_amount');
                        this.setState({
                            showLoadingModal: false,
                            errMsg: msg
                        });

                        this.props.openModal("error-modal");
                        break;
                    case "invalid_secondary_password" :
                        msg = t('staking.invalid_secondary_password');
                        this.setState({
                            showLoadingModal: false,
                            errMsg: msg
                        });

                        this.props.openModal("error-modal");
                        break;
                    default:
                        this.setState({
                            showLoadingModal: false,
                            errMsg: nextProps.staking_data.msg
                        });

                        this.props.openModal("error-modal");
                        return;
                }*/
            }
        }
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/emall");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");
    }

    render() {
        const {
            data,
            /*current_price,*/ balance,
            showLoadingModal,
            showErrorModal,
            showSuccessModal,
            errMsg,
            successMsg /*, walletFrom, converted_amount, walletTo*/,
        } = this.state;

        const { productCode } = this.props.location.state;

        return (
            <div className="staking">
                <PageHeader
                    title={t("emallConvert." + productCode)}
                    titleHeader="col-12 text-center"
                    backgroundHeight={230}
                    prevousPageLink=""
                    navClass=""
                    historyBack="/emall"
                    historyBackClass="pt-3"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType="PAC"
                    textBelowBalance={data.walletTypeName}
                    balance={data.amount ? data.amount : balance}
                    arrowLeft={true}
                />

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="staking-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div className="p-4 form-bg-transparent">
                    <div className="col-12 pt-3">
                        <div className="row">
                            <label className="form-label text-uppercase">
                                {t("emallConvert.quantity")}
                            </label>
                        </div>
                    </div>

                    <form
                        id="staking-form"
                        onSubmit={this.handleSubmitConvert}
                        className="form-bg-transparent needs-validation col-12 px-0"
                        noValidate
                    >
                        <div className="col-12">
                            <div className="row">
                                <NumberInput
                                    id="amount"
                                    name="amount"
                                    onChange={this.onChange}
                                    value={this.state.amount}
                                    min={this.state.minAmount}
                                    placeholder={t(
                                        "emallConvert.enter_quantity"
                                    )}
                                    className="remove-placeholder"
                                    requiredMsg={t("validator.requiredMsg")}
                                    textMoreThan={t(
                                        "validator.amount_should_more_than"
                                    )}
                                    required
                                />
                            </div>
                        </div>

                        <div className="col-12 pt-3">
                            <div className="row">
                                <label className="form-label text-uppercase">
                                    {t("staking.secondary_password")}
                                </label>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <PasswordInput
                                    id="s_password"
                                    name="s_password"
                                    onChange={this.onChange}
                                    value={this.state.s_password}
                                    placeholder={t(
                                        "staking.secondary_password"
                                    )}
                                    className="remove-placeholder"
                                    requiredMsg={t("validator.requiredMsg")}
                                    required
                                />
                            </div>
                        </div>

                        <div className="pt-4">
                            <button
                                type="submit"
                                className="col-12 btn btn-primary text-uppercase"
                            >
                                {t("staking.submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, modal, postEmallConvert } = state;

    return {
        data: walletBalance.data,
        classIsShowing: modal.classIsShowing,
        post_emall_convert_data: postEmallConvert.data,
    };
};

const mapDispatchToProps = {
    postWalletBalance,
    postEmallConvert,
    openModal,
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmallConvert);
