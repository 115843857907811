import React, { Component } from "react";
//import { setLocale, t } from "react-i18nify";
import { connect } from "react-redux";
import { Input, Select, Divider } from "antd";
import { t } from "../../common/translation";
import PageHeader from "../../components/PageHeaderV2";
import { Tabs } from "antd";
import NumberInput from "../../components/input/NumberInput";
import LoadingMask from "../../components/loadingMask";

import {
    postWalletBalanceLite,
    getExchangeSetting,
    getMemberWallet,
} from "../../store/ducks/home/actions";
import {
    postExchangeConvertRate,
    postSelectBalance,
    postExchangeConvert,
} from "../../store/ducks/exchange/actions";
import { postPurchaseRate } from "../../store/ducks/purchaseRate/actions";

import { formValidation } from "../../common/validator/formValidation";

import {
    GenHash,
    slice_decimal_wt_rounding,
    parseFloatV2,
} from "../../common/helpers";

import ConvertIcon from "../../public/v2/icon/icon-convert.svg";

import "./exchange.scss";
import WalletBadge from "../../components/WalletBadge";
import HyperModal from "../../components/HyperModal";

const { Option } = Select;

class Exchange extends Component {
    constructor(props) {
        super(props);

        // let exchangeFirstBadge = sessionStorage.getItem("exchangeFirstBadge");
        // let exchangeFirstBadge = "PAC";
        this.state = {
            data: [],
            amountFrom: "0",
            amountTo: "0",
            fromTo: "",
            amountSpend: "0",
            adminFee: "0",
            estimate_value: "0",
            selected_wallet: "TC",
            dropdown_block: "",
            menu_block: "",
            payment_wallets: ["TC", "USDT"],
            walletType: "",
            // walletType:
            //     props.location.state !== undefined
            //         ? props.location.state.walletType
            //         : exchangeFirstBadge,
            cryptoAddress: "",
            displayWalletType: "",
            // displayWalletType:
            //     props.location.state !== undefined
            //         ? props.location.state.walletType
            //         : exchangeFirstBadge,
            exchangeSetting: [],
            setupList: [],
            available_balance: 0,
            average_price: 0,
            avg_purchased_rate: 0,
            available_amountL: 0,
            live_rate: "",
            sellDepth: "",
            buyDepth: "",
            //loading: true,
            purchaseRate: [],
            tabActive: "PAC",
            modal_visible: false,
            loading_visible: true,
            modal_type: "",
            modal_msg: "",
            current_tab: "buy",
        };

        const data = {};

        // data.username = sessionStorage.getItem("email");
        // data.token = sessionStorage.getItem("token");
        // data.walletType =
        //     props.location.state !== undefined
        //         ? props.location.state.walletType
        //         : exchangeFirstBadge;
        // data.lang = sessionStorage.getItem("lang");
        // const hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        // data.hash = hash;

        //this.props.postWalletBalanceLite(data);

        this.callback = this.callback.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleExchangeRate = this.handleExchangeRate.bind(this);
        this.handleSubmitExchange = this.handleSubmitExchange.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleClickWallet = this.handleClickWallet.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
    }

    UNSAFE_componentWillMount() {
        //   setLocale(sessionStorage.getItem("lang"));
    }

    componentDidMount() {
        formValidation("staking-form");

        const data = {};
        data.token = this.state.token;
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        if (this.props.exchangeSetting) {
            let exchangeSetting;
            exchangeSetting = this.props.exchangeSetting.data.data;
            let firstBadge = exchangeSetting[0].walletTypeCode;
            let rate = 0;
            let available_balance = 0;
            let live_rate = 0;
            let sellDepth = 0;
            let buyDepth = 0;
            let walletTypeCode = "";
            exchangeSetting
                .filter((item) => item.walletTypeCode == firstBadge)
                .map((filterItem) => {
                    rate = filterItem.rate;
                    available_balance = filterItem.exchange_available_balance;
                    live_rate = filterItem.live_rate;
                    sellDepth = filterItem.sellMarketDepth;
                    buyDepth = filterItem.buyMarketDepth;
                    walletTypeCode = filterItem.walletTypeCode;
                });

            this.setState({
                exchangeSetting: exchangeSetting,
                available_balance: available_balance,
                live_rate: live_rate,
                sellDepth: sellDepth,
                buyDepth: buyDepth,
            });
            data.walletType = walletTypeCode;
            this.props.getMemberWallet(data);
        } else {
            this.props.getExchangeSetting(data);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.select_balance !== this.props.select_balance) {
            this.setState({
                available_balance:
                    nextProps.select_balance.data.walletBalance[0]
                        .available_amount,
                loading_visible: false,
            });
        }

        if (nextProps.exchangeSetting !== this.props.exchangeSetting) {
            // console.log(nextProps.exchangeSetting);
            if (nextProps.exchangeSetting.data.rst === "1") {
                let exchangeSetting;
                exchangeSetting = nextProps.exchangeSetting.data.data;

                let rate = 0;
                let available_balance = 0;
                let live_rate = 0;
                let sellDepth = 0;
                let buyDepth = 0;
                let walletTypeCode = "";
                let count = 0;
                let b_exchange_to_input = 0;
                let b_exchange_from_input = 0;

                for (let i = 0; i < exchangeSetting.length; i++) {
                    if (exchangeSetting[i].isUnderMaintenance === "1") {
                        count += 1;
                        // array.exchangeSetting.splice(i,1);
                        // console.log(array)
                        if (count == 2) {
                            this.props.history.push({
                                pathname: "/home",
                                state: { error: "invalid_wallet_type_details" },
                            });
                        }
                    }
                }
                exchangeSetting = exchangeSetting.filter(
                    (item) => item.show_buy == 1
                );

                //console.log(exchangeSetting);
                let firstBadge = exchangeSetting[0].walletTypeCode;
                exchangeSetting
                    .filter((item) => item.walletTypeCode == firstBadge)
                    .map((filterItem) => {
                        rate = filterItem.rate;
                        available_balance =
                            filterItem.exchange_available_balance;
                        live_rate = filterItem.live_rate;
                        sellDepth = filterItem.sellMarketDepth;
                        buyDepth = filterItem.buyMarketDepth;
                        walletTypeCode = filterItem.walletTypeCode;
                        b_exchange_to_input = filterItem.b_exchange_to_input;
                        b_exchange_from_input =
                            filterItem.b_exchange_from_input;
                    });
                this.setState({
                    exchangeSetting: exchangeSetting,
                    available_balance: available_balance,
                    live_rate: live_rate,
                    sellDepth: sellDepth,
                    buyDepth: buyDepth,
                    b_exchange_from_input,
                    b_exchange_to_input,
                    //   loading: false,
                    //  loading_visible: false,
                });

                const data = {};
                data.token = this.state.token;
                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;
                data.walletType = walletTypeCode;
                this.props.getMemberWallet(data);
            }
        }

        if (nextProps.memberWallet !== this.props.memberWallet) {
            if (nextProps.memberWallet.data.rst === "1") {
                //console.log("in", this.state);
                this.setState({
                    data: nextProps.memberWallet.data.data.walletBalance[0],
                    //  loading: false,
                    // loading_visible: false,
                    price_code:
                        nextProps.memberWallet.data.data.walletBalance[0]
                            .priceCode,
                });
                // console.log(this.state.loading_visible);
            }
        }

        // if (nextProps.data !== this.props.data) {
        //     //Check login success update the routes for react
        //     if (
        //         nextProps.data.rst === "1" &&
        //         nextProps.data.data.walletDetailsSetup.exchangeSetting !==
        //             undefined
        //     ) {
        //         let array;
        //         let count = 0;
        //         array = nextProps.data.data;
        //         for (let i = 0; i < array.exchangeSetting.length; i++) {
        //             if (array.exchangeSetting[i].isUnderMaintenance === "1") {
        //                 count += 1;
        //                 // array.exchangeSetting.splice(i,1);
        //                 // console.log(array)
        //                 if (count == 2) {
        //                     this.props.history.push({
        //                         pathname: "/home",
        //                         state: { error: "invalid_wallet_type_details" },
        //                     });
        //                 }
        //             }
        //         }

        //         this.setState({
        //             data: array.walletBalance[0],
        //             available_amount:
        //                 nextProps.data.data.walletBalance[0]
        //                     .available_withdraw_limit,
        //             average_price:
        //                 nextProps.data.data.walletBalance[0].avg_rate,
        //             avg_purchased_rate:
        //                 nextProps.data.data.walletBalance[0].avg_token_rate,
        //             exchangeSetting: array.exchangeSetting,
        //             available_balance:
        //                 array.exchangeSetting[0].exchange_available_balance,
        //             setupList: array.walletDetailsSetup.exchangeSetting,
        //             live_rate:
        //                 array.walletDetailsSetup.exchangeSetting.live_rate,
        //             sellDepth:
        //                 array.walletDetailsSetup.exchangeSetting
        //                     .sellMarketDepth,
        //             buyDepth:
        //                 array.walletDetailsSetup.exchangeSetting.buyMarketDepth,
        //             loading_visible: false,
        //             loading: false,
        //             price_code:
        //                 array.walletDetailsSetup.exchangeSetting.price_code,
        //         });
        //     } else {
        //         this.props.history.push({
        //             pathname: "/home",
        //             state: { error: "invalid_wallet_type_details" },
        //         });
        //     }
        // }

        if (nextProps.exchange_data !== this.props.exchange_data) {
            if (nextProps.exchange_data.rst === "1") {
                let array;
                array = nextProps.exchange_data.data;

                if (this.state.fromTo === "from") {
                    this.setState({
                        amountTo: array.converted_amount,
                        adminFee: array.admin_fee,
                    });
                } else if (this.state.fromTo === "to") {
                    this.setState({
                        amountFrom: array.converted_amount,
                        adminFee: array.admin_fee,
                    });
                }
            } else {
                this.setState({
                    modal_msg: nextProps.exchange_data.data.msg,
                    modal_type: "error",
                    modal_visible: true,
                    // loading_visible: false,
                });
            }
        }

        if (nextProps.convert_data !== this.props.convert_data) {
            if (nextProps.convert_data.rst === "1") {
                this.setState({
                    modal_msg: nextProps.convert_data.msg,
                    modal_type: "success",
                    modal_visible: true,
                    //  loading_visible: false,
                });
            } else {
                this.setState({
                    modal_msg: nextProps.convert_data.msg,
                    modal_type: "error",
                    modal_visible: true,
                    // loading_visible: false,
                });
            }
        }

        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }
    }

    handleClickWallet(e, value) {
        this.setState(
            {
                menu_block: "",
                selected_wallet: e,
                loading_visible: true,
            },
            () => {
                const data = {};

                data.username = sessionStorage.getItem("email");
                data.token = sessionStorage.getItem("token");
                data.walletType = e === "TC" ? "TW" : e;
                data.lang = sessionStorage.getItem("lang");
                const hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;
                //console.log(data);
                this.props.postSelectBalance(data);
            }
        );
    }

    handleChange() {
        this.setState({
            dropdown_block: "block",
            menu_block: "input-group-block",
        });
    }

    handleTabChange(event, ew, dw) {
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.walletType = ew;
        data.lang = sessionStorage.getItem("lang");
        var hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        let available_balance = 0;
        let live_rate = 0;
        let sellDepth = 0;
        let buyDepth = 0;
        let walletTypeCode = "";
        let rate = 0;
        let b_exchange_to_input = 0;
        let b_exchange_from_input = 0;

        this.state.exchangeSetting
            .filter((item) => item.walletTypeCode == ew)
            .map((filterItem) => {
                rate = filterItem.rate;
                available_balance = filterItem.exchange_available_balance;
                live_rate = filterItem.live_rate;
                sellDepth = filterItem.sellMarketDepth;
                buyDepth = filterItem.buyMarketDepth;
                walletTypeCode = filterItem.walletTypeCode;
                b_exchange_to_input = filterItem.b_exchange_to_input;
                b_exchange_from_input = filterItem.b_exchange_from_input;
            });

        // this.setState({
        //     exchangeSetting: exchangeSetting,
        //     available_balance: available_balance,
        //     live_rate: live_rate,
        //     sellDepth: sellDepth,
        //     buyDepth: buyDepth,
        //     //   loading: false,
        //     //  loading_visible: false,
        // });

        this.setState({
            available_balance: available_balance,
            live_rate: live_rate,
            sellDepth: sellDepth,
            buyDepth: buyDepth,
            walletType: ew,
            cryptoAddress: this.state.cryptoAddress,
            displayWalletType: dw,
            amountFrom: "0",
            amountTo: "0",
            adminFee: "0",
            amountSpend: "0",
            estimate_value: "0",
            tabActive: ew,
            b_exchange_to_input: b_exchange_to_input,
            b_exchange_from_input: b_exchange_from_input,
            //loading_visible: true,
        });
        const item = {};
        // if (ew == "CVT") {
        if (ew == "CVT") {
            item.walletType = "USDT";
            this.setState({
                selected_wallet: "USDT",
            });
        } else {
            item.walletType = "TW";
            this.setState({
                selected_wallet: "TC",
            });
        }

        item.username = sessionStorage.getItem("email");
        item.token = sessionStorage.getItem("token");

        item.lang = sessionStorage.getItem("lang");
        const hash1 = GenHash(item, process.env.REACT_APP_GM_SECRET_KEY);
        item.hash = hash1;

        this.props.postSelectBalance(item);
        // }

        this.props.getMemberWallet(data);
    }

    handleSubmitExchange(e, type) {
        const data = {};

        data.email = sessionStorage.getItem("email");
        data.amount = this.state.amountSpend;
        data.wallet_type = this.state.price_code;
        data.main_wallet =
            this.state.selected_wallet === "TC"
                ? "TW"
                : this.state.selected_wallet;
        data.order_type = type;
        // data.lang = sessionStorage.getItem("lang");
        data.wallet_type_code = this.state.walletType;

        data.estimate_value = this.state.estimate_value;
        this.props.postExchangeConvert(data);

        // this.setState({
        //     loading_visible: true,
        // });
    }

    handleExchangeRate(e, detector, fromTo, walletTo) {
        //Detector 1 - Buy , 2 - Sell
        let { name, value } = e.target;

        let amountTo = "0";
        let amountFrom = "0";
        let adminFee = "0";

        const data = {};
        console.log(this.state.buyDepth);
        if (e.target.value !== "" && e.target.value > 0) {
            e.target.value = slice_decimal_wt_rounding(value, 2);

            this.setState({
                [name]: e.target.value,
                fromTo: fromTo,
            });
            // console.log(detector, fromTo, walletTo);
            if (detector === 1) {
                //Start Buy PAC

                if (fromTo === "from") {
                    data.market_from = "TC";
                    data.market_to = walletTo;
                    data.amount_from =
                        e.target.value === "" ? 0 : e.target.value;
                    data.amount_to = "0";
                    data.type = "buy";

                    amountTo = slice_decimal_wt_rounding(
                        parseFloat(e.target.value) /
                            parseFloat(this.state.buyDepth),
                        8
                    );
                    // console.log(e.target.value, "/", this.state.buyDepth);
                    // console.log(
                    //     parseFloat(e.target.value) /
                    //         parseFloat(this.state.buyDepth)
                    // );
                    adminFee = slice_decimal_wt_rounding(
                        parseFloatV2(amountTo) * 0.01,
                        8
                    );

                    this.setState({
                        amountTo: amountTo,
                        adminFee: slice_decimal_wt_rounding(
                            parseFloat(amountTo) * 0.01,
                            8
                        ),
                        amountSpend: e.target.value,
                        estimate_value: slice_decimal_wt_rounding(
                            amountTo - adminFee,
                            8
                        ),
                    });
                } else {
                    // data.market_from = "";
                    // data.market_from = "TC";
                    // data.market_to = walletTo;
                    // data.amount_from = "0";
                    // data.amount_to = e.target.value === "" ? 0 : e.target.value;
                    // data.type = "buy";

                    amountFrom = slice_decimal_wt_rounding(
                        parseFloatV2(e.target.value) *
                            parseFloatV2(this.state.buyDepth),
                        2
                    );
                    adminFee = slice_decimal_wt_rounding(
                        parseFloatV2(e.target.value) * 0.01,
                        8
                    );

                    this.setState({
                        amountFrom: amountFrom,
                        amountTo: e.target.value === "" ? 0 : e.target.value,
                        adminFee: adminFee,
                        amountSpend: amountFrom,
                        estimate_value: slice_decimal_wt_rounding(
                            e.target.value - adminFee,
                            8
                        ),
                    });
                }
            } else {
                //Start Sell PAC
                if (fromTo === "from") {
                    data.market_from = walletTo;
                    data.market_to = "USDT";
                    data.amount_from =
                        e.target.value === "" ? 0 : e.target.value;
                    data.amount_to = "0";
                    data.type = "sell";

                    amountTo = slice_decimal_wt_rounding(
                        parseFloat(e.target.value) *
                            parseFloat(this.state.sellDepth),
                        8
                    );
                    adminFee = slice_decimal_wt_rounding(amountTo * 0.01, 8);
                    //console.log(amountTo, adminFee);
                    this.setState({
                        amountTo: amountTo,
                        adminFee: adminFee,
                        amountSpend: e.target.value,
                        estimate_value: slice_decimal_wt_rounding(
                            amountTo - adminFee,
                            8
                        ),
                    });
                } else {
                    data.market_from = walletTo;
                    data.market_to = "USDT";
                    data.amount_from = "0";
                    data.amount_to = e.target.value === "" ? 0 : e.target.value;
                    data.type = "sell";

                    amountFrom = slice_decimal_wt_rounding(
                        parseFloat(e.target.value) /
                            parseFloat(this.state.sellDepth),
                        8
                    );
                    adminFee = slice_decimal_wt_rounding(
                        e.target.value * 0.01,
                        8
                    );

                    this.setState({
                        amountFrom: amountFrom,
                        adminFee: slice_decimal_wt_rounding(
                            parseFloat(amountFrom) * 0.01,
                            2
                        ),
                        amountSpend: amountFrom,
                        estimate_value: slice_decimal_wt_rounding(
                            e.target.value - adminFee,
                            8
                        ),
                    });
                }
            }

            // this.props.postExchangeConvertRate(data);
        } else {
            this.setState({
                amountFrom: "",
                amountTo: "0",
                amountSpend: "0",
                adminFee: "0",
                estimate_value: "0",
            });
        }
    }

    onChange(e) {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    callback(key) {
        this.setState({
            amountTo: "",
            amountFrom: "",
            adminFee: "",
            amountSpend: "0",
            estimate_value: "0",
        });
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false, modal_gcox: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    render() {
        // sellDepth, buyDepth, live_rate, setupList
        const {
            data,
            estimate_value,
            amountSpend,
            loading,
            exchangeSetting,
            adminFee,
            purchaseRate,
            tabActive,
            loading_visible,
            modal_visible,
            modal_type,
            modal_msg,
        } = this.state;
        const { TabPane } = Tabs;
        const payment_wallet = this.state.payment_wallets.map(function (
            item,
            i
        ) {
            return (
                <Option key={i} value={item}>
                    {item}
                </Option>
            );
        });
        const selectAfter = (
            <Select
                defaultValue="TC"
                className="select-after select-width"
                onSelect={this.handleClickWallet}
            >
                {payment_wallet}
            </Select>
        );

        const p_rate = purchaseRate.map(function (item, i) {
            if (i < 2) {
                return (
                    <React.Fragment>
                        <div className="row col-12 mx-0 px-0">
                            <div className="col-6 text-left text-light">
                                {item.email}
                            </div>
                            <div className="col-6 text-right text-light">
                                {item.avg_price}
                            </div>
                        </div>
                        <hr />
                    </React.Fragment>
                );
            }
        });

        return (
            <div className="exchange">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack="/home"
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("exchange.exchange")}
                    arrowLeft={true}
                />

                <HyperModal
                    category="response"
                    modalVisible={loading_visible}
                    modalType="loading"
                    footer={[]}
                />

                <HyperModal
                    category="response"
                    modalVisible={modal_visible}
                    modalType={modal_type}
                    modalMsg={modal_msg}
                    modalCloseFunc={this.handleModalClose}
                    cancelFunc={this.handleModalClose}
                />

                <div className="offset-md-2 col-md-8 pl-0 pr-0">
                    {/* {!loading && (
                        <Tabs defaultActiveKey="1" onChange={this.callback}>
                            <TabPane tab={t("exchange.buy")} key="1"> */}

                    {exchangeSetting.length > 0 && (
                        <WalletBadge
                            currentTab={"buy"}
                            walletArrProps={
                                this.state.current_tab == "buy"
                                    ? exchangeSetting.filter(
                                          (item) => item.show_buy == 1
                                      )
                                    : exchangeSetting.filter(
                                          (item) => item.show_sell == 1
                                      )
                            }
                            badgeActive={tabActive}
                            // badgeActive={
                            //     displayWalletType === ""
                            //         ? "PAC"
                            //             ? "PAC"
                            //             : ""
                            //         : displayWalletType
                            // }
                            badgeOnClick={this.handleTabChange}
                        />
                    )}

                    <div className="pb-5">
                        <div className="col-12 text-center">
                            <b className="light-green-label">
                                {t("exchange.buy")} {data.displayCode}
                            </b>
                        </div>

                        {/*<div className="col-12 text-center">*/}
                        {/*<b className="light-white-label-18">1.00 {data.displayCode} ≈ {buyDepth} TC</b>*/}
                        {/*</div>*/}

                        <div className="col-12 text-center">
                            <b className="light-white-label-16">
                                {t("common.available")}:{" "}
                                {this.state.available_balance}{" "}
                                {this.state.selected_wallet}
                            </b>
                        </div>
                    </div>

                    {/* start here */}
                    {this.state.b_exchange_to_input == 1 ? (
                        <div className="mx-auto row container pb-4 px-0">
                            <div className="col-5">
                                <div style={{ marginBottom: 16 }}>
                                    <Input
                                        className="remove-placeholder exchange-input exchange-buy"
                                        addonAfter={
                                            <div style={{ color: "white" }}>
                                                {" "}
                                                {data.displayCode}
                                            </div>
                                        }
                                        placeholder="0.00000000"
                                        onChange={(e) =>
                                            this.handleExchangeRate(
                                                e,
                                                1,
                                                "to",
                                                data.displayCode
                                            )
                                        }
                                        value={this.state.amountTo}
                                        type="number"
                                    />
                                    <small className="col-12 px-0 text-light">
                                        {t("exchange.estimated_receive")}
                                        &nbsp;{estimate_value}{" "}
                                        {data.displayCode}
                                    </small>
                                </div>
                            </div>
                            <div className="col-2 text-center my-2">
                                <img src={ConvertIcon} alt="" width={30} />
                            </div>

                            <div className="col-5 text-center my-2 text-light">
                                <label className="col-12 px-0">
                                    {this.state.amountSpend}&nbsp; USDT
                                </label>
                                {/* <small className="col-12 px-0 text-light">
                                    {t("exchange.estimated_receive")}
                                    &nbsp;{estimate_value} {data.displayCode}
                                </small> */}
                            </div>
                        </div>
                    ) : (
                        <div className="mx-auto row container pb-4 px-0">
                            <div className="col-5 text-center my-2 text-light">
                                <label className="col-12 px-0">
                                    {this.state.amountTo}&nbsp;
                                    {data.displayCode}
                                </label>
                                <small className="col-12 px-0 text-light">
                                    {t("exchange.estimated_receive")}
                                    &nbsp;{estimate_value} {data.displayCode}
                                </small>
                            </div>

                            <div className="col-2 text-center my-2">
                                <img src={ConvertIcon} alt="" width={30} />
                            </div>

                            <div className="col-5">
                                <div style={{ marginBottom: 16 }}>
                                    <Input
                                        className="remove-placeholder exchange-input"
                                        addonAfter={selectAfter}
                                        placeholder="0.00000000"
                                        onChange={(e) =>
                                            this.handleExchangeRate(
                                                e,
                                                1,
                                                "from",
                                                data.displayCode
                                            )
                                        }
                                        value={this.state.amountSpend}
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {/* end here */}
                    <div className="row col-12 mx-0 px-0">
                        <div className="col-6">
                            <label className="depth-gray-label fa-pull-left">
                                {t("exchange.spending_amount")}
                            </label>
                        </div>
                        <div className="col-6">
                            <label className="depth-gray-label fa-pull-right">
                                {amountSpend} {this.state.selected_wallet}
                            </label>
                        </div>
                    </div>

                    <div className="row col-12 mx-0 px-0">
                        <div className="col-6">
                            <label className="depth-gray-label fa-pull-left">
                                {t("exchange.fee")} (1%)
                            </label>
                        </div>
                        <div className="col-6">
                            <label className="depth-gray-label fa-pull-right">
                                {adminFee} {data.displayCode}
                            </label>
                        </div>
                    </div>

                    <hr />

                    <div className="row col-12 mx-0 px-0">
                        <div className="col-6">
                            <label className="depth-gray-label fa-pull-left">
                                {t("exchange.estimated_receive")}
                            </label>
                        </div>
                        <div className="col-6">
                            <label className="depth-gray-label fa-pull-right">
                                {estimate_value} {data.displayCode}
                            </label>
                        </div>
                    </div>

                    <div className="pt-4 px-3">
                        <button
                            type="button"
                            className="col-12 depth-green-btn"
                            onClick={(e) => this.handleSubmitExchange(e, "buy")}
                        >
                            {t("exchange.buy")}
                        </button>
                    </div>
                    {/* </TabPane> */}
                    {/* <TabPane tab={t("exchange.sell")} key="2">
                                {exchangeSetting.length > 0 && (
                                    <WalletBadge
                                        currentTab={"sell"}
                                        walletArrProps={exchangeSetting}
                                        badgeActive={tabActive}
                                        // badgeActive={
                                        //     displayWalletType === ""
                                        //         ? "PAC"
                                        //             ? "PAC"
                                        //             : ""
                                        //         : displayWalletType
                                        // }
                                        badgeOnClick={this.handleTabChange}
                                    />
                                )}

                                <div className="px-0">
                                    <div className="pb-2">
                                        <div className="col-12 text-center">
                                            <b className="light-red-label">
                                                {t("exchange.sell")}{" "}
                                                {data.displayCode}
                                            </b>
                                        </div>

                                        <div className="col-12 text-center">
                                        <b className="light-white-label-18">1.00 {data.displayCode} ≈ {sellDepth} USDT</b>
                                        </div>

                                        <div className="col-12 text-center">
                                            <b className="light-white-label-16">
                                                {t("common.available")}:{" "}
                                                {data.available_amount}{" "}
                                                {data.displayCode}
                                            </b>
                                        </div>
                                    </div>

                                    <div className="col-12 text-white p-1 border-top">
                                        <div className="row">
                                            <div className="col-6 text-center depth-gray-label text-uppercase">
                                                {t(
                                                    "withdrawal.average_purchased_rate"
                                                )}
                                                <br />
                                                {this.state.avg_purchased_rate}
                                            </div>
                                            <div className="col-6 text-center border-left-light depth-gray-label text-uppercase">
                                                {t("withdrawal.average_rate")}
                                                <br />
                                                {this.state.average_price}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="view-avg px-0 mx-0 bg-black">
                                        <div className="row col-12 mx-0 px-0">
                                            <div className="col-6 text-left text-grey">
                                                {t("exchange.email")}
                                            </div>
                                            <div className="col-6 text-right text-grey">
                                                {t("exchange.average_price")}
                                            </div>
                                        </div>
                                        <hr />
                                        {p_rate}
                                        <div className="view text-center">
                                            <span
                                                onClick={
                                                    this.handleViewAvgPrice
                                                }
                                                className="text-center"
                                            >
                                                {t("exchange.view_all_list")}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mx-auto row container px-0 pt-2">
                                        <div className="col-5">
                                            <NumberInput
                                                type="number"
                                                id="amountFrom"
                                                name="amountFrom"
                                                onChange={(e) =>
                                                    this.handleExchangeRate(
                                                        e,
                                                        0,
                                                        "from",
                                                        data.displayCode
                                                    )
                                                }
                                                value={this.state.amountFrom}
                                                min={"0"}
                                                // max={delimiter(
                                                //     this.state.available_amount
                                                // )}
                                                placeholder="0.00000000"
                                                className="remove-placeholder depth-input"
                                                requiredMsg={t(
                                                    "validator.requiredMsg"
                                                )}
                                                textLessThan={t(
                                                    "validator.amount_should_less_than"
                                                )}
                                                textMoreThan={t(
                                                    "validator.amount_should_more_than"
                                                )}
                                                backInputGroup={
                                                    data.displayCode
                                                }
                                                required
                                            />
                                        </div>

                                        <div className="col-2 text-center my-2">
                                            <img
                                                src={ConvertIcon}
                                                alt=""
                                                width={30}
                                            />
                                        </div>

                                        <div className="col-5 text-center my-2 text-light">
                                            <label className="col-12 px-0">
                                                {estimate_value}&nbsp;USDT
                                            </label>
                                            <small className="col-12 px-0 text-light">
                                                {t(
                                                    "exchange.estimated_receive"
                                                )}
                                                &nbsp;{estimate_value} USDT
                                            </small>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row col-12 mx-0 px-0">
                                        <div className="col-6">
                                            <label className="depth-gray-label fa-pull-left">
                                                {t("exchange.limit")}
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label className="depth-gray-label fa-pull-right">
                                                {this.state.available_amount}{" "}
                                                {data.displayCode}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row col-12 mx-0 px-0">
                                        <div className="col-6">
                                            <label className="depth-gray-label fa-pull-left">
                                                {t("exchange.spending_amount")}
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label className="depth-gray-label fa-pull-right">
                                                {amountSpend} {data.displayCode}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row col-12 mx-0 px-0">
                                        <div className="col-6">
                                            <label className="depth-gray-label fa-pull-left">
                                                {t("exchange.fee")} (1%)
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label className="depth-gray-label fa-pull-right">
                                                {adminFee} USDT
                                            </label>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row col-12 mx-0 px-0">
                                        <div className="col-6">
                                            <label className="depth-gray-label fa-pull-left">
                                                {t(
                                                    "exchange.estimated_receive"
                                                )}
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label className="depth-gray-label fa-pull-right">
                                                {estimate_value} USDT
                                            </label>
                                        </div>
                                    </div>

                                    <div className="pt-4 px-3">
                                        <button
                                            type="button"
                                            className="col-12 depth-red-btn"
                                            onClick={(e) =>
                                                this.handleSubmitExchange(
                                                    e,
                                                    "sell"
                                                )
                                            }
                                        >
                                            {t("exchange.sell")}
                                        </button>
                                    </div>
                                </div>
                            </TabPane> */}
                    {/* </Tabs> */}
                </div>
                {/* )} */}

                {/* {loading && (
                        <div className="container pt-5">
                            <div className="row">
                                <div className="mx-auto">
                                    <LoadingMask width={80} />
                                </div>
                            </div>
                        </div>
                    )} */}
            </div>
            // </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, exchange, purchaseRate } = state;

    return {
        //  data: walletBalance.lite_data,
        //exchange_data: exchange.data,
        convert_data: exchange.convert_data,
        select_balance: exchange.select_balance,
        purchase_rate: purchaseRate.data,
        memberWallet: walletBalance.memberWallet,
        exchangeSetting: walletBalance.exchangeData,
        isLoading: walletBalance.isLoading,
    };
};

const mapDispatchToProps = {
    //  postWalletBalanceLite,
    postSelectBalance,
    postExchangeConvertRate,
    postExchangeConvert,
    postPurchaseRate,
    getMemberWallet,
    getExchangeSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Exchange);
