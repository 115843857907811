export const text = "-----BEGIN PUBLIC KEY-----" +
    "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1XPwaNoK1EJ5V7T/vSuI"+
    "p+RNI2nPdMGU9J2Ad49AdcI/yTmr12zy8vwea/NAGk5EXXnwUlzl9kzaU3I3MbrS"+
    "IAePwdMnuItymGQj13z7cW+g1F8OVU1fh6dvMJwLA9jN+8diNIpCEhLzajFx5feL"+
    "VpuBNBQ696k0y9JBgR7Tm3pgX8eJY4x9gu6q9LZIQ34mDSsxs9oe0Ly01HEM05+C"+
    "DDnTUsh3/WEuZ7I8TVsckBF6PUtasZZ+HSCYQPNMnJq+3+txvwPBCidL/k0zwyTC"+
    "rvmiLe/KF8RLRzHpTSAweemE7smqM1ZqlNbIjA8lVNJLjiWTpg2KMbwJ/YzViMJ0"+
    "sMzvHkA1yTgoSMOy3Bfs8iWxPwGGZa6sLst+r6eAGdXYpL4KAhAAeyTmmY3kqqTZ"+
    "DGX83cHCf18Cc9wbce52b4qgucbl5g052eZkjDTewjyxowQcuJOTohMfldxq/fFE"+
    "qkBSQ1h5ssnjtcGuFV9dqQEX7FYxqUtn1kRkxOFAiMxpY+a8QTTgvLC+muA+CL/b"+
    "EGwtEbVBvkPE20+tw9ueK+26XO0rzYNP6zg98tk8fHTIPv1R7Vc+dHO13By9lYDo"+
    "U4nCkqckvvG/AawPhhU4yWc1F+9TR5w31QCssWICBeFf6T06VZ+oaKEuLYg4RpBJ"+
    "Y8wGvUECZSB3vMT8a3XsdP8CAwEAAQ=="+
    "-----END PUBLIC KEY-----";