import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setLocale, t} from 'react-i18nify';
import { getOrderDetails } from '../../store/ducks/emall/actions';

import PageHeader from "../../components/PageHeader";
import {GenHash} from "../../assets/js/helpers";

import './orderDetails.scss';
import LoadingMask from "../../components/loadingMask";
import {Link} from "react-router-dom";

class orderDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
        initialAmount: 1,
        amount: 1,
        payments: [],
        orderItems: [],
        doc_no: '',
        doc_date: '',
        delivery_status: '',
        shipment_name: '',
        shipment_contact_no: '',
        shipment_address: '',
        delivery_label_class: '',
        headerHeight: window.innerWidth,
        loading: true
    };

    this.crousel = React.createRef();

    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
    this.onTest = this.onTest.bind(this);
  }

  componentWillMount() {
      const data = {};

      data.username = sessionStorage.getItem("email");
      data.lang = sessionStorage.getItem("lang");
      data.doc_no = this.props.match.params.doc_no;
      let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
      data.hash = hash;

      this.props.getOrderDetails(data);
  }

  componentDidMount() {
    setLocale(sessionStorage.getItem("lang"));

    window.addEventListener("resize", this.updateHeight);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
      if(nextProps.orderDetails !== this.props.orderDetails){
          if(nextProps.orderDetails.rst === "1"){
              this.setState({
                  doc_no: nextProps.orderDetails.data.details.doc_no,
                  doc_date: nextProps.orderDetails.data.details.created_at,
                  delivery_status: nextProps.orderDetails.data.details.delivery_status,
                  payments: nextProps.orderDetails.data.details.payment,
                  orderItems: nextProps.orderDetails.data.details.items,
                  shipment_name: nextProps.orderDetails.data.address.shipment_name,
                  shipment_contact_no: nextProps.orderDetails.data.address.mobile_prefix + nextProps.orderDetails.data.address.mobile_no,
                  delivery_label_class: nextProps.orderDetails.data.details.delivery_label_class,
                  shipment_address: nextProps.orderDetails.data.address,
                  loading: false
              });
          }
      }
  }

  updateHeight(){
    if (window.innerWidth <= 768) {
      this.setState({headerHeight: window.innerWidth});
    }
    else {
      this.setState({headerHeight: 768});
    }
  }

  onTest(e){
    this.crousel.goTo(1);
  }

  onChangeAmount(e) {

    let amount = this.state.amount;

    if (e.target.id === 'plus'){
      this.setState({amount: amount + 1});
    }
    else if (e.target.id === 'minus' && amount !== 0){
      this.setState({amount: amount - 1});
    }
  }

  render() {

      const {shipment_address, delivery_label_class, loading} = this.state;

      const allItemRows = this.state.orderItems.map(function (item, i){
        return <li className="list-group-item asset-panel-list-group-item col-12 px-0" key={i}>
            <div className="col-4 col-md-2 fa-pull-left">
                <img src={item.image} sizes={10} alt={i}/>
            </div>
            <div className="col-8 col-md-10 fa-pull-right px-0 pt-2">
                <div className="col-12">
                    <small className="row col-12 mx-0 px-0 overflow-hidden">{item.product_name}</small>
                </div>

                <div className="col-12">
                {item.prices.map(function (pItem, j) {
                    return <small key={j}><b>{pItem.display_name}&nbsp;{pItem.unit_price}{item.prices.length !== j + 1 ? ',' : ''}&nbsp;</b></small>
                })}
                </div>
                <div className="col-12">
                    <sub><b>{t('emall.qty')}: {item.quantity}</b></sub>
                </div>
            </div>
        </li>;
      });

    return (
        <div className="myOrders">
          <div className="container-fluid px-0">
            <PageHeader
                subTitle={t('emall.order_details')}
                navClass="title white-color"
                backgroundHeight={80}
                subPageHistoryBack={"/emall/my-orders"}
                walletType={""}
                arrowLeft={true}
            />
          </div>

            {loading &&
            <div className="container pt-5">
                <div className="row">
                    <div className="mx-auto">
                        <LoadingMask
                            width={80}
                        />
                    </div>
                </div>
            </div>
            }

            {!loading &&
            <>
                <div className="container-fluid">
                    <div className="row">
                        <li className="list-group-item asset-panel-list-group-item col-12 px-0">
                            <div className="col-12">
                                <small>{t('emall.ship_and_bill_to')}</small>
                            </div>
                            <div className="col-12">
                                <small><b>{this.state.shipment_name}</b></small>
                            </div>
                            <div className="col-12">
                                <small><b>{this.state.shipment_contact_no}</b></small>
                            </div>
                            <div className="col-12">
                                <small className="text-uppercase">{shipment_address.address_1}{shipment_address.address_2 !== undefined && shipment_address.address_2 !== '' ? ', ' + shipment_address.address_2 : ''}{shipment_address.address_3 !== undefined && shipment_address.address_3 !== '' ? ', ' + shipment_address.address_3 : ''} {shipment_address.state_name !== "" && ", "+shipment_address.state_name} {", "+shipment_address.country_name}.</small>
                            </div>
                        </li>
                    </div>

                    <div className="row">
                        {allItemRows}
                    </div>
                </div>

                <li className="list-group-item asset-panel-list-group-item col-12">
                    <div className="row">
                        <div className="col-12 container pt-2">
                            <b className="fa-pull-left">{t('emall.order')} #{this.state.doc_no}</b>
                            <span className={"badge badge-pill badge-"+delivery_label_class+" fa-pull-right"}>{this.state.delivery_status}</span>
                        </div>
                        <div className="col-12">
                            <small>{t('emall.placed_on')} {this.state.doc_date}</small>
                        </div>
                    </div>
                </li>

                <hr className="invisible-hr"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <div className="fa-pull-left col-6">
                                <label>{this.state.orderItems.length} {t('emall.items')}</label>
                            </div>
                            <div className="fa-pull-right col-6">
                                <div className="col-12 pr-0">
                                    {this.state.payments.map(function (item, j) {
                                        return <div key={j}>
                                            <div className="col-12 text-right"><b>{item.display_name} {item.total_amount}</b></div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>
                <div className="row col-12 mx-0">
                    <div className="col-12 px-0">
                        <Link to={{pathname: `/emall/my-orders`}}>
                            <button type="button" className="btn btn-outline-primary text-uppercase col-12">{t('emall.return')}</button>
                        </Link>
                    </div>

                    {/*<div className="col-6">*/}
                    {/*<button type="button" className="btn btn-success text-uppercase col-12">Write A Review</button>*/}
                    {/*</div>*/}
                </div>
            </>
            }
        </div>
    );
  }
}

const mapStateToProps = state => {
  const { emall } = state;

  return {
      orderDetails: emall.orderDetails
  };
};

const mapDispatchToProps = {
  getOrderDetails
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(orderDetails);
