import * as type from './types';
import axios from 'axios';

export const GetMemberPackage = (data) => dispatch => {
    axios.post( process.env.REACT_APP_GM_URL +'/member/pin/setup', data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_MEMBER_PACKAGE,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.GET_MEMBER_PACKAGE,
            payload: {"rst":"0","msg":"system_error","data":[]}
        })
    })
};

export const PostPurchasePackage = (res_data, url) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/pin/purchase', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_PURCHASE_PACKAGE,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_PURCHASE_PACKAGE,
            payload: {"rst":"0","msg":"system_error"}
        })
    })
};

export const GetMemberCvPackages = (data) => dispatch => {
    axios.put( process.env.REACT_APP_GM_URL +'/member/packages/cash_voucher', data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_MEMBER_CV_PACKAGES,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.GET_MEMBER_CV_PACKAGES,
            payload: {"rst":"0","msg":"system_error","data":[]}
        })
    })
};

export const GetMemberPackages = (data) => dispatch => {
    axios.put( process.env.REACT_APP_GM_URL +'/member/packages/token_voucher', data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_MEMBER_PACKAGES,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.GET_MEMBER_PACKAGES,
            payload: {"rst":"0","msg":"system_error","data":[]}
        })
    })
};

export const PostPurchaseTvPackage = (res_data, url) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/packages/token/purchase', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_PURCHASE_TV_PACKAGE,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_PURCHASE_TV_PACKAGE,
            payload: {"rst":"0","msg":"system_error"}
        })
    })
};
