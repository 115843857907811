import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setLocale, t} from 'react-i18nify';

import PageHeader from '../../components/PageHeader';

import { postWalletBalance } from '../../store/ducks/home/actions';
import { getStakingHistory } from '../../store/ducks/walletDetails/actions';
import plusImg from "../../public/images/icon-add@3x.png";

import './addressList.scss';
import {Icon} from "antd";

class AddressList extends Component {
  constructor(props) {
    super(props);

    this.state = {
        data: [],
        transaction_data: [],
        expandedRows: [],
        depositStatus: 0,
        transferStatus: 0,
        withdrawalStatus: 0,
        convertStatus: 0,
        stakingStatus: 0,
        loading: true,
        loadingText: true,
        totalPage: 0,
        currentPage: 1,
        walletType: '',
        balance: '0.00000000',
        hasMoreItems: true,
        showErrorModal: false,
        totalStakingAmount: '0',
        errMsg: '',
        errType: ''
    };

    this.handleRowClick = this.handleRowClick.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.loadMoreList = this.loadMoreList.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
  }

  loadMoreList(e, page) {
    const trans = {};
    let currentPage = parseInt(this.state.currentPage) + 1;
    // const { walletType } = this.props.location.state === undefined ? "" : this.props.location.state;

    trans.username = sessionStorage.getItem('email');
    trans.page = currentPage;

    if(currentPage >= this.state.totalPage) {
      this.setState({
          hasMoreItems: false,
          loading: false
      });
    }

    this.props.getStakingHistory(trans);
  }

  handleErrorClose(){
    this.props.closeModal("error-modal");

    if (this.state.errType === 'token_expiry'){
      this.props.history.push("/login");
    }
  }

  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
        ? currentExpandedRows.filter(id => id !== rowId)
        : currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  renderItem(item, i) {
    const itemRows = [
      <li className="list-group-item asset-panel-list-group-item" key={i}>
        <div className="pl-4">
          <div className="row">
            <div className="col-12 pl-0">
              <div className="fa-pull-left" style={{ color: '#25BD2B' }}>
                <small>
                  <b>
                    {window.location.search.slice(1, 10)} {item.total_amount}
                  </b>
                </small>
              </div>
              <div className="fa-pull-right">
                <small>
                  <b>{item.created_at + " "}</b>
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pl-0 fa-pull-left">
              <small>
                <b>
                  {t("walletDetails.rate_desc")}{item.price_rate}
                </b>
              </small>
            </div>
            {item.blk_explorer &&
            <div className="col-6 pl-0 text-right">
              <small>
                  <a href={item.blk_explorer} target="_blank" rel="noopener noreferrer"> {t("walletDetails.view_on_explorer")}</a>
              </small>
            </div>
            }
          </div>
          <div className="row">
            <div className="col-12 pl-0">
              <small>
                <b>
                  {item.remark}
                </b>
              </small>
            </div>
          </div>
        </div>
      </li>
    ];

    return itemRows;
  }

  componentDidMount() {
    setLocale(sessionStorage.getItem("lang"));

      const trans = {};

      trans.username = sessionStorage.getItem('email');
      trans.page = 1;
      this.props.getStakingHistory(trans);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.data !== this.props.data) {
      //Check login success update the routes for react
      if (nextProps.data.rst === '1') {
        let array;
        array = nextProps.data.data;

        this.setState({ data: array.walletBalance[0] });
        this.setState({ depositStatus: array.walletSetupList[0].depositStatus });
        this.setState({ transferStatus: array.walletSetupList[0].transferStatus });
        this.setState({ withdrawalStatus: array.walletSetupList[0].withdrawStatus });
        this.setState({ stakingStatus: array.walletSetupList[0].stakingStatus });
        this.setState({ convertStatus: array.walletSetupList[0].convertStatus });
        this.setState({ auctionStatus: array.walletSetupList[0].auctionStatus });
        this.setState({ totalStakingAmount: array.walletBalance[0].total_staking_amount });

      }
      else {
        switch (nextProps.data.msg) {
          case "invalid_session":
            let response = "'";
            response = t('profile.token_expiry');

            this.setState({
              errMsg: response,
              errType: "token_expiry"
            });
            this.props.openModal('error-modal');
            break;
          default:
            this.setState({
              errMsg: nextProps.data.msg
            });
            this.props.openModal('error-modal');
            break;
        }
      }
    }

    if (nextProps.transaction_list !== this.props.transaction_list) {
      if (nextProps.transaction_list.rst === 1) {
        let array;

        array = this.state.transaction_data;
        nextProps.transaction_list.data.currentPageItems.map(function(item, i) {
          array.push(item);

          return array;
        });

          if(this.state.currentPage === nextProps.transaction_list.data.totalPage){
              this.setState({
                  hasMoreItems: false
              });
          }

          if(nextProps.transaction_list.data.totalPage === "0"){
              this.setState({
                  hasMoreItems: false
              });
          }

        this.setState({ loading: false });
        this.setState({ transaction_data: array });
        this.setState({ totalPage: nextProps.transaction_list.data.totalPage });
        this.setState({ currentPage: nextProps.transaction_list.data.currentPage });
      }
      else {
        switch (nextProps.transaction_list.msg) {
          case "invalid_session":
            let response = "'";
            response = t('profile.token_expiry');

            this.setState({
              errMsg: response,
              errType: "token_expiry"
            });
            this.props.openModal('error-modal');
            break;
          default:
            this.setState({
              errMsg: nextProps.transaction_list.msg
            });
            this.props.openModal('error-modal');
            break;
        }
      }
    }
  }

  render() {
      // const {} = this.state;

      const allItemRows =
          <li className="list-group-item asset-panel-list-group-item col-12">
              <div className="row">
                  <div className="col-12">
                      <div className="fa-pull-left">
                          <small style={{color: '#FD2C5C'}}><b>[DEFAULT]</b></small>&nbsp;
                          <small><b>SHIPPING & BILLING ADDRESS</b></small>
                      </div>
                      <div className="fa-pull-right">
                          <small style={{color: "#0089FF"}}>Edit</small>
                      </div>
                  </div>
                  <div className="col-12">
                      {/*Delivery Adrress*/}
                      <div className="col-11 px-0">
                          <small><b>Delivery Address</b></small>
                      </div>
                      {/*Address*/}
                      <div className="row col-11">
                          <Icon type="environment" className="pt-1" />
                          <div className="col-11 container-fluid px-0 mx-0">
                              <div className="col-12">
                                  <small>John Doe</small>
                              </div>
                              <div className="col-12">
                                  <small>15Mohamed Sultan Rd, 238964, Singapore</small>
                              </div>
                          </div>
                      </div>
                      {/*Contact*/}
                      <div className="row col-11">
                          <Icon type="phone" className="pt-1" />

                          <div className="col-11 mx-0 px-0 container-fluid">
                              <div className="col-12">
                                  <small>+6012 123 1234</small>
                              </div>
                          </div>
                      </div>

                      {/*Billing Adrress*/}
                      <div className="col-11 px-0">
                          <small><b>Billing Address</b></small>
                      </div>
                      {/*Address*/}
                      <div className="row col-11">
                          <Icon type="home" className="pt-1" />
                          <div className="col-11 container-fluid px-0 mx-0">
                              <div className="col-12">
                                  <small>No.8F-2, Tower 2 @ PFCC, Jalan Puteri 1/2, Bandar Puteri Puchong, 47100 Puchong, Selangor.</small>
                              </div>
                          </div>
                      </div>

                      {/*Contact*/}
                      <div className="row col-11">
                          <Icon type="mail" className="pt-1" />

                          <div className="col-11 mx-0 px-0 container-fluid">
                              <div className="col-12">
                                  <small>John@gmail.com</small>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </li>;

      const orderList = <aside className="list-group">{allItemRows}</aside>;

    return (
        <div>
          <PageHeader
              subTitle={t('addressList.address_list')}
              navClass="title white-color"
              backgroundHeight={80}
              subPageHistoryBack={"/profile"}
              walletType={""}
              arrowLeft={true}
          />

          <div className="addressList">
              {orderList}

              <li className="list-group-item asset-panel-list-group-item col-12">
                  <div className="row">
                      <div className="col-10">
                          <label className="fa-pull-left text-uppercase" style={{color: "#314C6E"}}><b>Add New Address</b></label>
                      </div>
                      <div className="col-2">
                          <img className="fa-pull-right" src={plusImg} width={15} alt={1}/>
                      </div>
                  </div>
              </li>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  const { walletBalance, walletDetails } = state;

  return {
    data: walletBalance.data,
    transaction_list: walletDetails.data
  };
};

const mapDispatchToProps = {
  postWalletBalance,
  getStakingHistory
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressList);
