import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setLocale, t } from "react-i18nify";
import { message, Divider, Button } from "antd";
import copy from "copy-to-clipboard";
import InfiniteScroll from "react-infinite-scroller";
import { openModal, closeModal } from "../../store/ducks/modal/actions";
import {
    postSharePrice,
    postShare,
    postShareList,
    postShareContract,
} from "../../store/ducks/share/actions";
import { postWalletBalanceLite } from "../../store/ducks/home/actions";
import LoadingMask from "../../components/loadingMask";
import Modal from "../../components/modal";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";
import PasswordInput from "../../components/input/PasswordInput";
import NumberInput from "../../components/input/NumberInput";
import PageHeader from "../../components/PageHeaderV2";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import { toFixedTrunc, GenHash } from "../../common/helpers";
import TabFooter from "../../components/TabFooter";

import "./share.scss";

class Share extends Component {
    constructor(props) {
        super(props);

        let auctionFirstBadge = sessionStorage.getItem("auctionFirstBadge");
        this.myRef = React.createRef();
        this.state = {
            username: sessionStorage.getItem("email"),
            token: sessionStorage.getItem("token"),
            lang: sessionStorage.getItem("lang"),
            data: [],
            price_data: [],
            qr_path: "",
            setOpen: false,
            open: false,
            address: "",
            walletType:
                props.location.state !== undefined
                    ? props.location.state.walletType
                    : auctionFirstBadge,
            displayWalletType:
                props.location.state !== undefined
                    ? props.location.state.walletType
                    : auctionFirstBadge,
            cryptoAddress: "",
            showErrorModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            showLoadingModal: false,
            minAmount: 0,
            maxAmount: "",
            errMsg: "",
            errType: "",
            successMsg: "",
            s_password: "",
            estimated_amount: 0,
            available_amount: "0",
            amount_usdt: 0,
            wallet_balance_data: [],
            display: "not_display",
            auctionSetting: [],
            share_price: "",
            share_data: "",
            share_quantity: 1,
            list_data: [],
            hasMoreItems: true,
            totalPage: 0,
            currentPage: 1,
            loading: true,
            secondaryPassword: "",
            reload: true,
            validate: true,
        };
        const data = {};

        this.props.postSharePrice(data);

        const l_data = {};
        l_data.page = this.state.currentPage;

        this.props.postShareList(l_data);

        this.getBalance();

        this.handleTooltipClose = this.handleTooltipClose.bind(this);
        this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
        this.handleDoc = this.handleDoc.bind(this);
        this.handleDiv = this.handleDiv.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleSubmitClose = this.handleSubmitClose.bind(this);
        this.handleSecondaryPassword = this.handleSecondaryPassword.bind(this);
    }

    getBalance() {
        const w_data = {};
        w_data.username = sessionStorage.getItem("email");
        w_data.token = sessionStorage.getItem("token");
        w_data.walletType = "ACMV";
        w_data.lang = sessionStorage.getItem("lang");
        let hash = GenHash(w_data, process.env.REACT_APP_GM_SECRET_KEY);
        w_data.hash = hash;

        this.props.postWalletBalanceLite(w_data);
    }

    handleSecondaryPassword(event) {
        this.setState({
            secondaryPassword: event.target.value,
        });
    }

    handleDoc(doc) {
        this.props.history.push({
            pathname: "/share/auc",
            state: { doc_no: doc },
        });
    }

    handleDiv(doc) {
        this.props.history.push({
            pathname: "/share/invoice",
            state: { doc_no: doc },
        });
    }

    loadMoreList(e, page) {
        const list = {};
        let currentPage = parseInt(this.state.currentPage) + 1;

        list.page = currentPage;

        if (currentPage >= this.state.totalPage) {
            this.setState({
                hasMoreItems: false,
                loading: false,
            });
        }
        this.props.postShareList(list);
    }

    handleConfirm() {
        if (this.state.secondaryPassword == "") {
            document.getElementById("secondary_password").className +=
                " is-invalid";
            this.setState({
                // errMsg: t("share.secondary_password_required")
            });
        } else {
            this.setState({
                showConfirmationModal: true,
            });
        }
    }

    handleSubmitClose() {
        this.setState({
            showConfirmationModal: false,
        });
    }

    handleSubmit(e) {
        const data = {};

        data.quantity = this.state.share_quantity;
        data.secondary_password = this.state.secondaryPassword;
        data.lang = sessionStorage.getItem("lang");

        this.props.postShare(data);

        this.setState({
            showLoadingModal: true,
            showConfirmationModal: false,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "loading-modal") {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }
            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
            if (
                nextProps.classIsShowing.modalName ===
                "auction-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.share_price !== this.props.share_price) {
            if (nextProps.share_price.rst === 1) {
                let array;
                array = nextProps.share_price.data;

                this.setState({
                    share_price: array,
                });
            }
        }

        if (nextProps.share_data !== this.props.share_data) {
            if (nextProps.share_data.rst === "1") {
                let array;
                array = nextProps.share_data.data;

                this.setState({
                    loading: true,
                    share_data: array,
                    showSuccessModal: true,
                    successMsg: nextProps.share_data.msg,
                    showConfirmationModal: false,
                    secondaryPassword: "",
                    showLoadingModal: false,
                    reload: false,
                    list_data: [],
                });

                const l_data = {};
                l_data.page = 1;
                this.getBalance();
                this.props.postShareList(l_data);
            } else {
                this.setState({
                    showErrorModal: true,
                    errMsg: nextProps.share_data.msg,
                    showConfirmationModal: false,
                    showLoadingModal: false,
                });
            }
        }

        if (nextProps.share_list !== this.props.share_list) {
            if (nextProps.share_list.rst === 1) {
                let array;

                array = this.state.list_data;
                nextProps.share_list.data.currentPageItems.map(function (
                    item,
                    i
                ) {
                    array.push(item);

                    return array;
                });

                if (
                    this.state.currentPage ===
                    nextProps.share_list.data.totalPage
                ) {
                    this.setState({
                        hasMoreItems: false,
                    });
                }
                if (nextProps.share_list.data.totalPage === "0") {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                this.setState({
                    loading: false,
                    list_data: array,
                    totalPage: nextProps.share_list.data.totalPage,
                    currentPage: nextProps.share_list.data.currentPage,
                    reload: true,
                });
            } else {
                this.setState({
                    loading: false,
                    reload: true,
                });
            }
        }
        if (nextProps.wallet_balance_data !== this.props.wallet_balance_data) {
            if (nextProps.wallet_balance_data.rst === "1") {
                this.setState({
                    available_amount:
                        nextProps.wallet_balance_data.data.walletBalance[0]
                            .available_amount,
                });
            }
        }
    }
    handleSuccessClose() {
        this.props.closeModal("success-modal");

        // this.props.history.push('/home');
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleTooltipClose = () => {
        this.setState({ open: false });
    };

    handleTooltipOpen = () => {
        copy(this.state.address);

        message.success(t("copied"));
    };

    onChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    render() {
        const {
            showErrorModal,
            errMsg,
            showLoadingModal,
            wallet_balance_data,
            available_amount,
            showConfirmationModal,
            successMsg,
            showSuccessModal,
            walletType,
            share_price,
            share_quantity,
            list_data,
            totalPage,
            loading,
            currentPage,
            secondaryPassword,
            reload,
            validate,
        } = this.state;

        let handleDoc = (doc) => {
            this.handleDoc(doc);
        };
        let handleDiv = (doc) => {
            this.handleDiv(doc);
        };
        let share_list = [];
        share_list = list_data.map(function (item, i) {
            if (item.status === "ACTIVE") {
                return (
                    <React.Fragment>
                        <div className="share-list row" key={i}>
                            <div
                                className="col-7 text-roboto text-white col-md-9"
                                onClick={(a) => {
                                    handleDiv(item.doc_no);
                                }}
                            >
                                <div className="col-12">
                                    <div className="row col-md-8">
                                        <span className="date text-grey text-left">
                                            {item.created_at}
                                        </span>
                                    </div>
                                    <div className="row col-md-8">
                                        <p
                                            id="gcox"
                                            className="gcox-amount text-white"
                                        >
                                            {item.amount}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-5 text-roboto text-white share-button col-md-3 text-right">
                                <button
                                    type="button"
                                    onClick={(a) => {
                                        handleDoc(item.doc_no);
                                    }}
                                    className="share-button col-12 depth-blue-btn"
                                >
                                    <small>{t("share.auc_for_share")}</small>
                                </button>
                            </div>
                        </div>
                        <hr />
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <div className="share-list row" key={i}>
                            <div
                                className="col-7 text-roboto text-white col-md-9"
                                onClick={(a) => {
                                    handleDiv(item.doc_no);
                                }}
                            >
                                <div className="col-12">
                                    <div className="row col-md-8">
                                        <span className="date text-grey text-left">
                                            {item.created_at}
                                        </span>
                                    </div>
                                    <div className="row col-md-8">
                                        <p
                                            id="gcox"
                                            className="gcox-amount text-white"
                                        >
                                            {item.amount}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col-5 text-roboto text-white share-button col-md-3 text-right"
                                onClick={(a) => {
                                    handleDiv(item.doc_no);
                                }}
                            >
                                <small>{item.doc_no}</small>
                            </div>
                        </div>
                        <hr />
                    </React.Fragment>
                );
            }
        });

        return (
            <div className="auction">
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="btn depth-red-btn col-12"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}
                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("transfer.confirmation_msg")}</h4>
                                </div>
                            </div>
                        }
                        closeText={t("purchasePin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={this.handleSubmitClose}
                        closeId="share"
                        submitText={t("common.submit")}
                        submitClassName="btn depth-blue-btn"
                        submitFunc={this.handleSubmit}
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="auction-confirmation-modal"
                    />
                )}
                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="col-12 btn depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div className="container-fluid px-0">
                    <PageHeader
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        availableAmount={wallet_balance_data.available_amount}
                        title={t("share.gcox_title")}
                    />
                </div>

                <div className="gcox-bg offset-md-2 mb-1 col-md-8 pl-0 pr-0">
                    <div className="gcox p-5"></div>
                </div>

                <div className="current-price p-4 bg-black offset-md-2 col-md-8">
                    <div className="row">
                        <div className="price col-6 text-roboto text-grey">
                            {t("share.current_price")}
                        </div>
                        <div className="rate col-6 text-right text-roboto-bold">
                            {share_price}
                        </div>
                    </div>
                </div>
                <div className="gcox p-3 offset-md-2 col-md-8">
                    <h5 className="text-center text-white">
                        <strong>{t("share.purchase_gcox_share")}</strong>
                    </h5>
                    <hr />
                    <div className="row">
                        <div className="col-7 text-roboto text-white">
                            <div className="row col-md-8">
                                <span className="gcox-title text-white text-left">
                                    {t("share.gcox")}
                                </span>
                            </div>
                            <div className="row col-md-8">
                                <small>
                                    <small id="gcox" className="text-white">
                                        {t("share.gcox_reminder")}
                                    </small>
                                </small>
                            </div>
                        </div>
                        <div className="col-5 text-right text-roboto-bold">
                            <NumberInput
                                id="amount_usdt"
                                name="share_quantity"
                                className="remove-placeholder depth-input"
                                value={share_quantity}
                                min={1}
                                // max={delimiter(this.state.available_amount)}
                                placeholder={t("auction.amount")}
                                onChange={this.onChange}
                                requiredMsg={t("validator.requiredMsg")}
                                textLessThan={t(
                                    "validator.amount_should_less_than"
                                )}
                                textMoreThan={t(
                                    "validator.amount_should_more_than"
                                )}
                                required
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="card mx-auto mb-2 bg-transparent">
                        <div className="row">
                            <div className="col-12">
                                <span className="text-uppercase text-grey">
                                    {t("purchasePin.secondary_password")}
                                </span>
                            </div>

                            <div className="col-12">
                                <PasswordInput
                                    id="secondary_password"
                                    name="secondary_password"
                                    value={secondaryPassword}
                                    onChange={this.handleSecondaryPassword}
                                    className="depth-input trading_password"
                                    placeholder={t(
                                        "purchasePin.please_enter_trading_password"
                                    )}
                                    requiredMsg={t("validator.requiredMsg")}
                                    required
                                />
                                {!validate && (
                                    <span>
                                        <small className="text-danger">
                                            {t(
                                                "share.secondary_password_required"
                                            )}
                                        </small>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="text-white">
                        <div className="row">
                            <div className="col-6 text-left">
                                <small>
                                    {t("share.your_available_balance")}
                                </small>
                            </div>
                            <div className="col-6 text-right">
                                <small>
                                    {toFixedTrunc(available_amount, 2)}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="offset-md-2 col-md-8">
                    <button
                        onClick={this.handleConfirm}
                        type="submit"
                        className="col-12 depth-blue-btn"
                    >
                        {t("auction.submit")}
                    </button>
                </div>
                <div className="p-3"></div>

                <div className="th p-2 bg-black offset-md-2 col-md-8">
                    <div className="p-2">
                        <div className="transaction-history text-left text-roboto-bold">
                            {t("share.transaction_history")}
                        </div>
                    </div>
                </div>

                <div className="th-list p-3 offset-md-2 col-md-8">
                    {reload && (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadMoreList}
                            hasMore={this.state.hasMoreItems}
                            loader={
                                (!loading && totalPage <= 1) ||
                                (!loading && currentPage === totalPage) ? (
                                    ""
                                ) : (
                                    <div className="row" key={0}>
                                        <div className="mx-auto">
                                            <LoadingMask width={80} />
                                        </div>
                                    </div>
                                )
                            }
                            initialLoad={false}
                            threshold={15}
                        >
                            {share_list}
                        </InfiniteScroll>
                    )}
                    {((!loading && totalPage === 1) ||
                        (!loading && currentPage === totalPage)) && (
                        <div className="col-12">
                            <div className="row">
                                <div className="mx-auto">
                                    <span>
                                        <small>
                                            <b className="text-white">
                                                {t(
                                                    "walletDetails.end_of_record"
                                                )}
                                            </b>
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {!loading && totalPage < 1 && (
                        <div className="col-12">
                            <div className="row">
                                <div className="mx-auto">
                                    <span>
                                        <small>
                                            <b className="text-white">
                                                {t("walletDetails.no_record")}
                                            </b>
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <TabFooter shareActive="active" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auction, modal, market, walletBalance, share } = state;

    return {
        auction_data: auction.data,
        classIsShowing: modal.classIsShowing,
        market_data: market.data,
        wallet_balance_data: walletBalance.lite_data,
        share_price: share.data,
        share_data: share.share_data,
        share_list: share.list_data,
    };
};

const mapDispatchToProps = {
    closeModal,
    openModal,
    postSharePrice,
    postShare,
    postShareList,
    postShareContract,
    postWalletBalanceLite,
};

export default connect(mapStateToProps, mapDispatchToProps)(Share);
