import React, { Component } from "react";
import QRCode from "qrcode.react";
import connect from "react-redux/es/connect/connect";
import { message } from "antd";
//import { setLocale, t } from "react-i18nify";
import { t } from "../../common/translation";
import PageHeader from "../../components/PageHeaderV2";
import Modal from "../../components/modal";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";
import LoadingMask from "../../components/loadingMask";
import StringInput from "../../components/input/StringInput";

import {
    getTwoFactorAuthentication,
    postTwoFactorAuthentication,
} from "../../store/ducks/twoFactorAuthentication/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";

import { formValidation } from "../../common/validator/formValidation";
import { fieldChecking, GenHash } from "../../common/helpers";

import CopyIcon from "../../public/images/icon-copy@3x.png";

import "./twoFactorAuthetication.scss";
import HyperModal from "../../components/HyperModal";
import ConfirmationIcon from "../../components/ConfirmationAlert";

class TwoFactorAuthentication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            qrCodeString: t("setup2FA.generating") + "...",
            codeUrl: "",
            action: "enable",
            twoFAToken: "",
            showLoadingModal: false,
            showSuccessModal: false,
            showErrorModal: false,
            errMsg: "",
            successMsg: "",
            errType: "",
            modal_type: "",
            modal_msg: "",
            confirm_visible: false,
        };

        this.handleToken = this.handleToken.bind(this);
        this.handleSubmit2FA = this.handleSubmit2FA.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleCopyText = this.handleCopyText.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);

        this.get2faStatusPreload();
    }

    handleCopyText(event) {
        this.copied = false;

        const textarea = document.createElement("textarea");
        textarea.value = document
            .getElementById(event.target.id)
            .getAttribute("value");

        document.body.appendChild(textarea);

        textarea.select();

        try {
            document.execCommand("copy");

            this.copied = true;

            setTimeout(function () {
                message.success(t("referral.copied"));
            }, 500);
        } catch (err) {
            this.copied = false;
        }

        textarea.remove();
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    get2faStatusPreload = () => {
        let twoFAObj = {};
        twoFAObj.username = sessionStorage.getItem("email");
        twoFAObj.token = sessionStorage.getItem("token");
        twoFAObj.accessToken = sessionStorage.getItem("accessToken");

        let hash = GenHash(twoFAObj, process.env.REACT_APP_GM_SECRET_KEY);
        twoFAObj.hash = hash;

        this.props.getTwoFactorAuthentication(twoFAObj);
    };

    handleSubmit2FA(event) {
        this.setState({ loading_visible: true });

        event.preventDefault();

        let validate = fieldChecking(
            document.getElementById("enable-2FA-form")
        );

        if (validate === false) {
            return false;
        }

        let sTwoFAObj = {};
        // sTwoFAObj.username = sessionStorage.getItem('email');
        sTwoFAObj.token = sessionStorage.getItem("token");
        sTwoFAObj.codeUrl = this.state.codeUrl;
        sTwoFAObj.twoFaAction = "enable";
        sTwoFAObj.twoFaToken = this.state.twoFAToken;
        sTwoFAObj.secret = this.state.qrCodeString;
        sTwoFAObj.accessToken = sessionStorage.getItem("accessToken");

        let hash = GenHash(sTwoFAObj, process.env.REACT_APP_GM_SECRET_KEY);
        sTwoFAObj.hash = hash;

        this.props.postTwoFactorAuthentication(sTwoFAObj);
    }

    handleToken(event) {
        this.setState({ twoFAToken: event.target.value });
    }

    componentDidMount() {
        // setLocale(sessionStorage.getItem("lang"));
        formValidation("enable-2FA-form");

        this.setState({
            loading_visible: true,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.twoFactorAuthData.rst === "1") {
            this.setState({
                qrCodeString: nextProps.twoFactorAuthData.data.secret,
                codeUrl: nextProps.twoFactorAuthData.data.secret,
                loading_visible: false,
            });
        }

        if (
            nextProps.postTwoFactorAuthData !== this.props.postTwoFactorAuthData
        ) {
            if (nextProps.postTwoFactorAuthData.rst === "1") {
                this.setState({
                    modal_msg: nextProps.postTwoFactorAuthData.msg,
                    modal_type: "success",
                    modal_visible: true,
                    loading_visible: false,
                });
            } else {
                this.setState({
                    modal_msg: nextProps.postTwoFactorAuthData.msg,
                    modal_type: "error",
                    modal_visible: true,
                    loading_visible: false,
                });
            }
        }
    }

    handleCloseModal() {
        this.props.closeModal("disable-2fa-modal");
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");
        this.props.history.push("/profile");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        this.props.history.push("/profile");
    }

    render() {
        const {
            confirm_visible,
            loading_visible,
            modal_msg,
            modal_visible,
            modal_type,
            showLoadingModal,
            showSuccessModal,
            showErrorModal,
            successMsg,
            errMsg,
            twoFAToken,
        } = this.state;

        return (
            <div className="setupTwoFA">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack={"/profile"}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("setup2FA.setup_2fa")}
                    arrowLeft={true}
                />
                <div className="mt-4  offset-md-2 col-md-8 px-0">
                    {showLoadingModal && (
                        <Modal
                            title=""
                            children={
                                <div className="container pt-5">
                                    <div className="row">
                                        <div className="mx-auto">
                                            <LoadingMask width={80} />
                                        </div>
                                    </div>
                                </div>
                            }
                            isShowing={showLoadingModal}
                            modalId="loading-modal"
                            modalClassName=""
                            modalClassId="enable-2fa-loading-modal"
                            modalClassContent="loading-bg"
                        />
                    )}

                    {showSuccessModal && (
                        <Modal
                            title=""
                            children={
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <SuccessIcon
                                            width="100px"
                                            height="0px !important"
                                        />
                                        <h4>{successMsg}</h4>
                                    </div>
                                </div>
                            }
                            closeText=""
                            closeClassName=""
                            closeFunc=""
                            closeId=""
                            submitText={t("common.close")}
                            submitClassName="depth-blue-btn"
                            submitFunc={this.handleSuccessClose}
                            submitId=""
                            modalFooterClassName="custom-modal-footer"
                            isShowing={showSuccessModal}
                            modalClassName=""
                            modalClassId="success-modal"
                        />
                    )}

                    {showErrorModal && (
                        <Modal
                            title=""
                            children={
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <ErrorIcon
                                            width="100px"
                                            height="0px !important"
                                        />
                                        <h4>{errMsg}</h4>
                                    </div>
                                </div>
                            }
                            closeText=""
                            closeClassName=""
                            closeFunc=""
                            closeId=""
                            submitText={t("common.close")}
                            submitClassName="depth-red-btn"
                            submitFunc={this.handleErrorClose}
                            submitId=""
                            modalFooterClassName="custom-modal-footer"
                            isShowing={showErrorModal}
                            modalClassName=""
                            modalClassId="error-modal"
                        />
                    )}

                    <HyperModal
                        category="response"
                        modalVisible={loading_visible}
                        modalType="loading"
                        footer={[]}
                    />

                    <HyperModal
                        category="normal"
                        modalVisible={confirm_visible}
                        onOk={this.handleModalSubmit}
                        footer={
                            <div className="col-12 text-center">
                                <button
                                    key="submit"
                                    className="col-3 btn btn-primary"
                                    onClick={this.handleModalSubmit}
                                >
                                    {t("common.submit")}
                                </button>
                                <button
                                    key="button"
                                    className="col-3 btn btn-danger"
                                    onClick={(e) =>
                                        this.handleModalClose(e, "confirm")
                                    }
                                >
                                    {t("purchasePin.modal_close")}
                                </button>
                            </div>
                        }
                        modalContent={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>
                                        {t(
                                            "disable2FA.confirmation_header_msg"
                                        )}
                                    </h4>
                                    <p>{t("disable2FA.confirmation_msg")}</p>
                                </div>
                            </div>
                        }
                        onCancel={this.handleModalClose}
                        closable={false}
                    ></HyperModal>

                    <HyperModal
                        category="response"
                        modalVisible={modal_visible}
                        modalType={modal_type}
                        modalMsg={modal_msg}
                        modalCloseFunc={this.handleModalClose}
                        cancelFunc={this.handleModalClose}
                    />

                    <div className="">
                        <form
                            id="enable-2FA-form"
                            onSubmit={this.handleSubmit2FA}
                            className="needs-validation col-12"
                            noValidate
                        >
                            <div className="col-12">
                                <ol>
                                    <li className="depth-gray-label">
                                        {t("setup2FA.setup_2fa_step1")}
                                    </li>
                                    <li className="depth-gray-label">
                                        {t("setup2FA.setup_2fa_step2")}
                                    </li>
                                    {/*<div className="card">*/}
                                    {/*<div className="card-body">*/}
                                    <div className="text-center py-2">
                                        <div className="p-2 offset-md-4 col-md-4">
                                            <QRCode
                                                value={
                                                    "otpauth://totp/" +
                                                    process.env
                                                        .REACT_APP_TITLE +
                                                    "?secret=" +
                                                    this.state.qrCodeString
                                                }
                                                size={160}
                                                className="bg-white p-2"
                                            />
                                        </div>
                                    </div>
                                    {/*</div>*/}
                                    {/*</div>*/}

                                    <div className="depth-gray-label">
                                        {t("setup2FA.setup_2fa_step3")}
                                    </div>

                                    <div className="py-4 text-break depth-gray-label">
                                        <strong>
                                            {this.state.qrCodeString}
                                        </strong>
                                        <div className="text-center txt-blue cursor-pointer">
                                            <span
                                                id="2fa-secret"
                                                onClick={this.handleCopyText}
                                                value={this.state.qrCodeString}
                                            >
                                                {t(
                                                    "setup2FA.copy_two2fa_address"
                                                )}
                                            </span>{" "}
                                            <img
                                                className="mb-1"
                                                src={CopyIcon}
                                                width={15}
                                                alt=""
                                                onClick={this.handleCopyText}
                                                id="img-2fa-secret"
                                                value={this.state.qrCodeString}
                                            ></img>
                                        </div>
                                    </div>
                                    {/*<span id="2fa-secret" className="badge badge-secondary col-12 lbl-2fa-secret" onClick={this.handleCopyText} value={this.state.qrCodeString}>{this.state.qrCodeString}</span>*/}

                                    <li className="depth-gray-label">
                                        {t("setup2FA.setup_2fa_step4")}
                                        <br />

                                        <div className="col-12">
                                            <div className="row">
                                                <StringInput
                                                    className="col-12 form-control depth-input"
                                                    id="twofa_key"
                                                    name="twofa_key"
                                                    placeholder={t(
                                                        "setup2FA.enter_token"
                                                    )}
                                                    onChange={this.handleToken}
                                                    value={twoFAToken}
                                                    required
                                                    requiredMsg={t(
                                                        "validator.requiredMsg"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                </ol>

                                <button
                                    type="submit"
                                    className="col-12 depth-blue-btn"
                                >
                                    {t("setup2FA.activate")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { twoFactorAuthentication, modal } = state;

    return {
        twoFactorAuthData: twoFactorAuthentication.twoFactorAuthData,
        classIsShowing: modal.classIsShowing,
        postTwoFactorAuthData: twoFactorAuthentication.postTwoFactorAuthData,
    };
};

const mapDispatchToProps = {
    getTwoFactorAuthentication,
    postTwoFactorAuthentication,
    openModal,
    closeModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TwoFactorAuthentication);
