import * as type from './types';
import axios from 'axios';


/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Aun
 */

export const postConvertDeposit = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/wallet/convert', {
        "token":data['token'],
        "convertAmount": data['convertAmount'],
        "email": data['email'],
        "secondaryPassword": data['secondaryPassword'],
        "walletTypeFrom": data['walletTypeFrom'],
        // "walletTypeTo": data['walletTypeTo'],
        "method": data['method'],
        "purchase_method": data['purchase_method'],
        "lang": data['lang'],
        "hash": data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.CONVERT_DEPOSIT,
            payload: response
        })
    }).catch (err => {
        dispatch ({
            type: type.CONVERT_DEPOSIT,
            payload: {"rst":"0","msg":"system_error"}
        })
    })
};
