import React, { Component } from "react";
import QRCode from "qrcode.react";
import { t, setLocale } from "react-i18nify";
import { message, Tabs, Checkbox } from "antd";

import PageHeader from "../../components/PageHeaderV2";
import CopyIcon from "../../public/images/icon-copy@3x.png";
import "./referral.scss";
import StringInput from "../../components/input/StringInput";
import PasswordInput from "../../components/input/PasswordInput";
// import Select from "../../components/input/Select";
import DropDropBox from "../../components/input/DropDownBox";
import NumberInput from "../../components/input/NumberInput";
import {
    amountChecking2,
    amountChecking8,
    fieldChecking,
    GenHash,
} from "../../common/helpers";
import { getCountry } from "../../store/ducks/emall/actions";
import { postRegisterDownline } from "../../store/ducks/auth/actions";
import { connect } from "react-redux";
import HyperModal from "../../components/HyperModal";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import { Select, Input } from "antd";

const { Option } = Select;

class Referral extends Component {
    constructor(props) {
        super(props);

        this.state = {
            qrCodeReferral: sessionStorage.getItem("uname"),
            referralName: sessionStorage.getItem("uname"),
            open: false,
            username: "",
            password: "",
            confirmPassword: "",
            country_prefix: "+93",
            mobile_no: "",
            // email: props.location.state ? props.location.state.r_email : "",
            email: "",
            placementUsername: props.location.state
                ? props.location.state.r_email
                : sessionStorage.getItem("uname"),
            b_sponsor_placement: true,
            mobile_prefix: [],
            country_new: [],
            referralUsername: props.location.state
                ? props.location.state.r_email
                : sessionStorage.getItem("uname"),
            modal_visible: false,
            loading_visible: this.props.loading_visible,
            modal_type: "",
            modal_msg: "",
            defaultTab: props.location.state ? "2" : "1",
        };

        this.handleCopyText = this.handleCopyText.bind(this);
        this.handleSubmitRegister = this.handleSubmitRegister.bind(this);
        this.callback = this.callback.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.onDropDownChange = this.onDropDownChange.bind(this);

        let data = {};

        data.langCode = sessionStorage.getItem("lang");
        data.parentId = 0;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.getCountry(data);
    }

    handleCopyText() {
        this.copied = false;

        const textarea = document.createElement("textarea");
        textarea.value = document.getElementById("referral_link").innerText;

        document.body.appendChild(textarea);

        textarea.select();

        try {
            document.execCommand("copy");

            this.copied = true;

            message.success(t("referral.copied"));
        } catch (err) {
            this.copied = false;
        }

        textarea.remove();
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    componentWillMount() {
        setLocale(sessionStorage.getItem("lang"));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.rst === "1") {
                let prefix_arr = [];

                this.setState({
                    country_new: nextProps.country_data.data[0].country,
                });

                nextProps.country_data.data[0].country.map((option, i) => {
                    prefix_arr.push({
                        country_id: option.calling_no_prefix,
                        country_name: option.name,
                        country_mobile_no_prefix: option.calling_no_prefix,
                        country_code: option.code,
                    });
                    return i;
                });

                this.setState({ mobile_prefix: prefix_arr });
            }
        }

        if (nextProps.register_downline !== this.props.register_downline) {
            if (nextProps.register_downline.rst === "1") {
                console.log(nextProps.register_downline);
                this.setState({
                    loading_visible: false,
                    modal_type: "success",
                    modal_visible: true,
                    modal_msg: nextProps.register_downline.msg,
                });
            } else {
                this.setState({
                    loading_visible: false,
                    modal_type: "error",
                    modal_visible: true,
                    modal_msg: nextProps.register_downline.msg,
                });
            }
        }
    }

    onCheck(e) {
        if (e.target.checked) {
            this.setState({
                placementUsername: this.state.referralUsername,
                b_sponsor_placement: e.target.checked,
            });
        } else {
            this.setState({
                placementUsername: "",
                b_sponsor_placement: e.target.checked,
            });
        }
    }

    handleSubmitRegister(e) {
        e.preventDefault();

        let validate = fieldChecking(document.getElementById("register-form"));

        if (validate === false) {
            return false;
        }

        const data = {};
        let hash = "";

        data.t_username = this.state.username;
        data.password = this.state.password;
        data.confirmPassword = this.state.confirmPassword;
        data.mobilePrefix = this.state.country_prefix;
        data.mobileNumber = this.state.mobile_no;
        data.t_email = this.state.email;
        data.referralUsername = this.state.referralUsername;
        data.placementUsername = this.state.placementUsername;
        data.lang = sessionStorage.getItem("lang");
        hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postRegisterDownline(data);

        this.setState({
            loading_visible: true,
        });
    }

    onChange(e) {
        const { name, value } = e.target;

        this.setState({ [name]: value });

        if (this.state.b_sponsor_placement && name === "referralUsername") {
            this.setState({ placementUsername: e.target.value });
        }
    }

    onDropDownChange(e) {
        this.setState({ country_prefix: e });
    }

    callback(e) {}

    render() {
        const { TabPane } = Tabs;
        const {
            b_sponsor_placement,
            modal_visible,
            loading_visible,
            modal_type,
            modal_msg,
        } = this.state;

        return (
            <div className="referral">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack={"/profile"}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("referral.refer_friend")}
                    arrowLeft={true}
                />

                <HyperModal
                    category="response"
                    modalVisible={loading_visible}
                    modalType="loading"
                    footer={[]}
                />

                <HyperModal
                    category="response"
                    modalVisible={modal_visible}
                    modalType={modal_type}
                    modalMsg={modal_msg}
                    modalCloseFunc={this.handleModalClose}
                    cancelFunc={this.handleModalClose}
                />
                {/*<Tabs*/}
                {/*    defaultActiveKey={this.state.defaultTab}*/}
                {/*    onChange={this.callback}*/}
                {/*>*/}
                {/*<TabPane tab={t("referral.qr_code")} key="1">*/}
                <div className="container offset-md-2 col-md-8 px-0">
                    <div className="card bg-transparent">
                        <div className="card-body">
                            <div className="text-center">
                                <QRCode
                                    className="bg-white pt-2 pb-2"
                                    style={{ padding: "10px" }}
                                    size={256}
                                    value={
                                        process.env.REACT_APP_URL +
                                        "register?referral=" +
                                        this.state.qrCodeReferral
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pt-1 offset-md-2 col-md-8 ">
                    <div className="col-12 text-center depth-gray-label">
                        {t("referral.scan_qr_to_register")}
                    </div>
                </div>

                <hr />

                <div className="card-body text-center pt-1 pb-1 word-wrap ">
                    <div className="pr-3 pl-3 text-light">
                        <small id="referral_link">
                            {process.env.REACT_APP_URL}
                            register?referral=
                            {sessionStorage.getItem("email")}
                        </small>
                    </div>
                </div>

                <div className="col-12 text-center pt-3" id="TooltipExample">
                    <div className="text-copy-blue">
                        <small
                            id="copy_referral"
                            onClick={this.handleTooltipOpen}
                        >
                            {t("referral.copy_your_referral_link")}{" "}
                            <img
                                onClick={this.handleCopyText}
                                className="mb-1"
                                src={CopyIcon}
                                width={15}
                                alt="Copy"
                            ></img>
                        </small>
                    </div>
                </div>
                {/*</TabPane>*/}

                {/*<TabPane tab={t("referral.register_downline")} key="2">*/}
                {/*    <form*/}
                {/*        id="register-form"*/}
                {/*        onSubmit={this.handleSubmitRegister}*/}
                {/*        className="txt-black needs-validation offset-md-2 col-md-8"*/}
                {/*        noValidate*/}
                {/*    >*/}
                {/*        <input*/}
                {/*            type="hidden"*/}
                {/*            name="referral_username"*/}
                {/*            value={this.state.referralUsername}*/}
                {/*        />*/}

                {/*        <label className="depth-gray-label text-uppercase">*/}
                {/*            {t("register.email")}**/}
                {/*        </label>*/}
                {/*        <div className="form-group">*/}
                {/*            <StringInput*/}
                {/*                id="email"*/}
                {/*                name="email"*/}
                {/*                onChange={this.onChange}*/}
                {/*                value={this.state.email}*/}
                {/*                placeholder={t(*/}
                {/*                    "register.please_enter_email"*/}
                {/*                )}*/}
                {/*                className="remove-placeholder depth-input"*/}
                {/*                required*/}
                {/*                requiredMsg={t("validator.requiredMsg")}*/}
                {/*                valid_email="true"*/}
                {/*                emailMsg={t("register.validEmail")}*/}
                {/*            />*/}
                {/*        </div>*/}

                {/*        <label className="depth-gray-label text-uppercase">*/}
                {/*            {t("register.username")}**/}
                {/*        </label>*/}
                {/*        <div className="form-group">*/}
                {/*            <StringInput*/}
                {/*                id="username"*/}
                {/*                name="username"*/}
                {/*                onChange={this.onChange}*/}
                {/*                value={this.state.username}*/}
                {/*                placeholder={t(*/}
                {/*                    "register.please_enter_username"*/}
                {/*                )}*/}
                {/*                className="remove-placeholder depth-input"*/}
                {/*                required*/}
                {/*                requiredMsg={t("validator.requiredMsg")}*/}
                {/*                minLengthMsg={t("register.minLengthMsg", {*/}
                {/*                    count: 3,*/}
                {/*                })}*/}
                {/*                minLength="3"*/}
                {/*                maxLength="20"*/}
                {/*                maxLengthMsg={t("register.maxLengthMsg", {*/}
                {/*                    count: 20,*/}
                {/*                })}*/}
                {/*            />*/}
                {/*            <p className="remark-gray-style">*/}
                {/*                {t("register.username_remark")}*/}
                {/*            </p>*/}
                {/*        </div>*/}

                {/*        <label className="depth-gray-label text-uppercase">*/}
                {/*            {t("register.password")}**/}
                {/*        </label>*/}
                {/*        <div className="form-group">*/}
                {/*            <PasswordInput*/}
                {/*                id="password"*/}
                {/*                name="password"*/}
                {/*                onChange={this.onChange}*/}
                {/*                value={this.state.password}*/}
                {/*                placeholder={t(*/}
                {/*                    "register.please_enter_password"*/}
                {/*                )}*/}
                {/*                className="remove-placeholder depth-input"*/}
                {/*                required*/}
                {/*                requiredMsg={t("validator.requiredMsg")}*/}
                {/*                minLengthMsg={t("register.minLengthMsg", {*/}
                {/*                    count: 8,*/}
                {/*                })}*/}
                {/*                minLength="8"*/}
                {/*                maxLength="16"*/}
                {/*                maxLengthMsg={t("register.maxLengthMsg", {*/}
                {/*                    count: 16,*/}
                {/*                })}*/}
                {/*                passwordFormatMsg={t(*/}
                {/*                    "register.passwordFormatMsg"*/}
                {/*                )}*/}
                {/*                passwordformat="true"*/}
                {/*            />*/}
                {/*            <p className="remark-gray-style">*/}
                {/*                {t("register.password_remark")}*/}
                {/*            </p>*/}
                {/*        </div>*/}

                {/*        <label className="depth-gray-label text-uppercase">*/}
                {/*            {t("register.confirm_password")}**/}
                {/*        </label>*/}
                {/*        <div className="form-group">*/}
                {/*            <PasswordInput*/}
                {/*                id="confirmPassword"*/}
                {/*                name="confirmPassword"*/}
                {/*                onChange={this.onChange}*/}
                {/*                value={this.state.confirmPassword}*/}
                {/*                placeholder={t(*/}
                {/*                    "register.please_re_enter_password"*/}
                {/*                )}*/}
                {/*                className="remove-placeholder depth-input"*/}
                {/*                required*/}
                {/*                requiredMsg={t("validator.requiredMsg")}*/}
                {/*                confirmpassword="true"*/}
                {/*                oldPassword={this.state.password}*/}
                {/*                confirmPasswordMsg={t(*/}
                {/*                    "register.checkPasswordMatch"*/}
                {/*                )}*/}
                {/*            />*/}
                {/*        </div>*/}

                {/*        <label className="depth-gray-label text-uppercase">*/}
                {/*            {t("register.mobile")}*/}
                {/*        </label>*/}
                {/*        <div className="form-group">*/}
                {/*            <div className="input-group">*/}
                {/*                <Input.Group compact>*/}
                {/*                    <Select*/}
                {/*                        size="large"*/}
                {/*                        style={{ width: "20%" }}*/}
                {/*                        defaultValue={*/}
                {/*                            this.state.country_prefix*/}
                {/*                        }*/}
                {/*                        value={this.state.country_prefix}*/}
                {/*                        id="status"*/}
                {/*                        name="country_prefix"*/}
                {/*                        onChange={this.onDropDownChange}*/}
                {/*                    >*/}
                {/*                        {this.state.mobile_prefix.map(*/}
                {/*                            (option, index) => (*/}
                {/*                                <Option*/}
                {/*                                    value={*/}
                {/*                                        option.country_id*/}
                {/*                                    }*/}
                {/*                                >*/}
                {/*                                    {option.country_mobile_no_prefix &&*/}
                {/*                                        option.country_mobile_no_prefix +*/}
                {/*                                            " - "}*/}

                {/*                                    {option.country_code}*/}
                {/*                                </Option>*/}
                {/*                            )*/}
                {/*                        )}*/}
                {/*                    </Select>*/}
                {/*                    <Input*/}
                {/*                        style={{ width: "80%" }}*/}
                {/*                        id="mobile_no"*/}
                {/*                        name="mobile_no"*/}
                {/*                        size="large"*/}
                {/*                        onChange={this.onChange}*/}
                {/*                        value={this.state.mobile_no}*/}
                {/*                        className="depth-input"*/}
                {/*                        placeholder={t(*/}
                {/*                            "register.please_enter_phone_number"*/}
                {/*                        )}*/}
                {/*                    />*/}
                {/*                </Input.Group>*/}
                {/*                /!*<NumberInput*!/*/}
                {/*                /!*    type="number"*!/*/}
                {/*                /!*    id="mobile_no"*!/*/}
                {/*                /!*    name="mobile_no"*!/*/}
                {/*                /!*    onChange={this.onChange}*!/*/}
                {/*                /!*    value={this.state.mobile_no}*!/*/}
                {/*                /!*    className="form-control depth-input"*!/*/}
                {/*                /!*    placeholder={t("register.please_enter_phone_number")}*!/*/}
                {/*/>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <label className="depth-gray-label text-uppercase">*/}
                {/*            {t("register.referral_username")}**/}
                {/*        </label>*/}
                {/*        <div className="form-group">*/}
                {/*            <input*/}
                {/*                type="text"*/}
                {/*                placeholder={t(*/}
                {/*                    "register.please_enter_referral_username"*/}
                {/*                )}*/}
                {/*                onChange={this.onChange}*/}
                {/*                value={this.state.referralUsername}*/}
                {/*                id="referralUsername"*/}
                {/*                name="referralUsername"*/}
                {/*                className="form-control remove-placeholder depth-input"*/}
                {/*                disabled={this.state.referralStatus}*/}
                {/*                required*/}
                {/*            />*/}
                {/*        </div>*/}

                {/*        <div className="col-12 px-0 mb-2">*/}
                {/*            <Checkbox*/}
                {/*                defaultChecked={true}*/}
                {/*                onChange={this.onCheck}*/}
                {/*                className="depth-gray-label"*/}
                {/*            >*/}
                {/*                {t(*/}
                {/*                    "register.placement_same_as_sponsor_name"*/}
                {/*                )}*/}
                {/*            </Checkbox>*/}
                {/*        </div>*/}

                {/*        {!b_sponsor_placement && (*/}
                {/*            <>*/}
                {/*                <label className="depth-gray-label text-uppercase">*/}
                {/*                    {t("register.placement_username")}**/}
                {/*                </label>*/}
                {/*                <div className="form-group">*/}
                {/*                    <input*/}
                {/*                        type="text"*/}
                {/*                        placeholder={t(*/}
                {/*                            "register.please_enter_placement_username"*/}
                {/*                        )}*/}
                {/*                        onChange={this.onChange}*/}
                {/*                        value={this.state.placementUsername}*/}
                {/*                        id="placementUsername"*/}
                {/*                        name="placementUsername"*/}
                {/*                        className="form-control remove-placeholder depth-input"*/}
                {/*                        required*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </>*/}
                {/*        )}*/}

                {/*        <div className="form-group">*/}
                {/*            <button*/}
                {/*                type="submit"*/}
                {/*                className="col-12 depth-blue-btn"*/}
                {/*            >*/}
                {/*                {t("register.register")}*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*    </form>*/}
                {/*</TabPane>*/}
                {/*</Tabs>*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { emall, isAuthenticated } = state;

    return {
        country_data: emall.get_country,
        register_downline: isAuthenticated.register_downline,
    };
};

const mapDispatchToProps = {
    getCountry,
    postRegisterDownline,
};

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
