import React, { Component } from "react";
import { connect } from "react-redux";
// import { setLocale, t } from "react-i18nify";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeaderV2";
import TransPanel from "../../components/transPanelV2";
import LoadingMask from "../../components/loadingMask";
import Modal from "../../components/modal";
import HyperModal from "../../components/HyperModal";
import ErrorIcon from "../../components/ErrorAlert";
import { t } from "../../common/translation";
import {
    postWalletBalance,
    postWalletBalanceLite,
    getMemberWallet,
    getWalletDetailSetting,
} from "../../store/ducks/home/actions";
import {
    postTransactionList,
    postTransactionSalesCancel,
} from "../../store/ducks/walletDetails/actions";
import { postAutoSell } from "../../store/ducks/home/actions";
import { postAutoSellAction } from "../../store/ducks/autoSell/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";

import { GenHash } from "../../common/helpers";

import "./walletDetails.scss";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import { Button, Switch } from "antd";
import SuccessIcon from "../../components/SuccessAlert";
import NumberInput from "../../components/input/NumberInput";
import arrowWhite from "../../public/images/arrow-white@3x.png";

class WalletDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            autoSellSetting: sessionStorage.getItem("autoSellStatus"),
            auto_sell_available_qty: 0,
            auto_sell_qty: 0,
            weekly_sold: "0.00000000",
            daily_sold: "0.00000000",
            missionPoint: 0,
            data: [],
            transaction_data: [],
            expandedRows: [],
            depositStatus: "0",
            transferStatus: "0",
            withdrawalStatus: "0",
            autoSellStatus: 0,
            convertStatus: 0,
            stakingStatus: 0,
            auctionStatus: 0,
            exchangeStatus: 0,
            kycStatus: 0,
            //loading: true,
            loadingText: true,
            totalPage: 0,
            currentPage: 1,
            walletType: "",
            balance: "0.00000000",
            hasMoreItems: true,
            showErrorModal: false,
            isUnderMaintenance: "0",
            kycCheck: "1",
            showNotice: false,
            totalStakingAmount: "0",
            showSuccessModal: false,
            showLoadingModal: false,
            showConfirmationModal: false,
            errMsg: "",
            errType: "",
            successMsg: "",
            docNo: "",
            productCodeArr: {},
            label: undefined,
            transaction_type: "",
            url: "",
            button_label: "",
            walletTypeCode: "",
            displayWalletCode: "",
            modal_visible: false,
            loading_visible: false,
            modal_type: "",
            modal_msg: "",
            confirm_visible: false,
            staking_max_amount: "0",
            locked_amount: "0",
            average_purchase_price: 0,
        };

        this.handleRowClick = this.handleRowClick.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleNoticeOpen = this.handleNoticeOpen.bind(this);
        this.handleNoticeClose = this.handleNoticeClose.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleCancelSalesTrans = this.handleCancelSalesTrans.bind(this);
        this.handleSubmitCancelTransConfirmation =
            this.handleSubmitCancelTransConfirmation.bind(this);
        this.handleAutoSell = this.handleAutoSell.bind(this);
        this.handleAutoSellAction = this.handleAutoSellAction.bind(this);
        this.handleAutoSellQty = this.handleAutoSellQty.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleAutoSellSubmission =
            this.handleAutoSellSubmission.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `260px`);
    }

    loadMoreList(e, page) {
        const trans = {};
        let currentPage = parseInt(this.state.currentPage) + 1;
        const { walletType } =
            this.props.location.state === undefined
                ? ""
                : this.props.location.state;

        trans.username = sessionStorage.getItem("email");
        trans.token = sessionStorage.getItem("token");
        trans.walletType = walletType;
        trans.page = currentPage;
        trans.lang = sessionStorage.getItem("lang");
        let hash = GenHash(trans, process.env.REACT_APP_GM_SECRET_KEY);
        trans.hash = hash;
        // console.log("in here", currentPage, ">=", this.state.totalPage);
        if (currentPage >= this.state.totalPage) {
            // console.log("exit here");
            this.setState({
                hasMoreItems: false,
                loading: false,
            });
        }

        // this.setState({ loading: true });
        this.props.postTransactionList(trans);
    }

    handleNoticeOpen(e) {
        this.props.openModal("notice-modal");
    }

    handleNoticeClose(e) {
        this.props.closeModal("notice-modal");
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/home");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleSubmitWithdrawalClose(event) {
        event.preventDefault();

        this.props.closeModal("cancel-trans-confirmation-modal");
    }

    async handleSubmitCancelTransConfirmation(e, docNo) {
        e.preventDefault();

        this.setState({ docNo: docNo });

        this.props.openModal("cancel-trans-confirmation-modal");
    }

    handleCancelSalesTrans(e) {
        const cancelTrans = {};

        cancelTrans.username = sessionStorage.getItem("email");
        cancelTrans.salesDocNo = this.state.docNo;
        cancelTrans.lang = sessionStorage.getItem("lang");
        let hash = GenHash(cancelTrans, process.env.REACT_APP_GM_SECRET_KEY);
        cancelTrans.hash = hash;

        this.setState({
            showConfirmationModal: false,
        });

        this.props.openModal("cancel-trans-loading-modal");

        this.props.postTransactionSalesCancel(cancelTrans);
    }

    handleRowClick(rowId) {
        const currentExpandedRows = this.state.expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

        const newExpandedRows = isRowCurrentlyExpanded
            ? currentExpandedRows.filter((id) => id !== rowId)
            : currentExpandedRows.concat(rowId);

        this.setState({ expandedRows: newExpandedRows });
    }

    renderItem(item, i) {
        const itemRows = [
            <li className="list-group-item asset-panel-list-group-item" key={i}>
                <div className="pl-4">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div
                                className="fa-pull-left"
                                style={{ color: item.amountColor }}
                            >
                                <small>
                                    <b>
                                        {window.location.search.slice(1, 10)}{" "}
                                        {item.amount}
                                    </b>
                                </small>
                            </div>
                            <div className="fa-pull-right">
                                <small>
                                    <b>{item.transDate + " "}</b>
                                </small>
                                <small>
                                    <b>{item.transTime}</b>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 pl-0 fa-pull-left">
                            <small>
                                <b>
                                    {item.withdrawTo}{" "}
                                    {item.convertedCurrencyCode}{" "}
                                    {t("walletDetails." + item.transType)}
                                </b>
                            </small>
                        </div>
                        {item.routineDay === "1" &&
                            item.saleStatus === "approved" && (
                                <div className="col-6 pl-0 text-right">
                                    <Button
                                        type="danger"
                                        size="small"
                                        onClick={(e) =>
                                            this.handleSubmitCancelTransConfirmation(
                                                e,
                                                item.salesDocNo
                                            )
                                        }
                                    >
                                        <small>
                                            <b>Cancel</b>
                                        </small>
                                    </Button>
                                </div>
                            )}
                        {item.blk_explorer && (
                            <div className="col-6 pl-0 text-right">
                                <small>
                                    <a
                                        href={item.blk_explorer}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {" "}
                                        {t("walletDetails.view_on_explorer")}
                                    </a>
                                </small>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-12 pl-0">
                            <small>
                                <b>
                                    {item.remark}
                                    {/*&nbsp;{item.hash}*/}
                                </b>
                            </small>
                        </div>
                    </div>
                </div>
            </li>,
        ];

        return itemRows;
    }

    componentDidMount() {
        //setLocale(sessionStorage.getItem("lang"));
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `260px`);
        });
        this.setState({
            autoSellSetting: sessionStorage.getItem("autoSellStatus"),
        });

        const data = {};
        if (this.props.location.state !== undefined) {
            const { walletType } = this.props.location.state;

            data.username = sessionStorage.getItem("email");
            data.token = sessionStorage.getItem("token");
            data.walletType = walletType;
            data.lang = sessionStorage.getItem("lang");
            let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
            data.hash = hash;

            const trans = {};

            // trans.username = sessionStorage.getItem("email");
            trans.token = sessionStorage.getItem("token");
            trans.walletType = walletType;
            trans.page = 1;
            trans.lang = sessionStorage.getItem("lang");
            hash = GenHash(trans, process.env.REACT_APP_GM_SECRET_KEY);
            trans.hash = hash;

            //change this
            // this.props.postWalletBalanceLite(data);

            this.props.getWalletDetailSetting(data);
            this.props.getMemberWallet(data);
            this.props.postTransactionList(trans);
        } else {
            this.props.history.push({
                pathname: "/home",
                state: { error: "invalid_wallet_type_details" },
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.autoSellAction != this.props.autoSellAction) {
            this.setState({
                modal_msg: nextProps.autoSellAction.data.msg,
                modal_type:
                    nextProps.autoSellAction.data.rst == "0"
                        ? "error"
                        : "success",
                modal_visible: true,
                // loading_visible: false,
            });
        }

        if (nextProps.postAutoSellResult !== this.props.postAutoSellResult) {
            this.setState(
                {
                    autoSellSetting: nextProps.postAutoSellResult.data,
                    // loading_visible: false,
                },
                () => {
                    sessionStorage.setItem(
                        "autoSellStatus",
                        nextProps.postAutoSellResult.data
                    );
                }
            );
        }

        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "notice-modal") {
                this.setState({
                    showNotice: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName ===
                "cancel-trans-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName ===
                "cancel-trans-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.memberWallet !== this.props.memberWallet) {
            if (nextProps.memberWallet.data.rst === "1") {
                let average_purchase_price =
                    nextProps.memberWallet.data.data.calculatedMarketAmount
                        .average_purchased_price;
                let memberWallet =
                    nextProps.memberWallet.data.data.walletBalance[0];
                //console.log(average_purchase_price);
                this.setState({
                    average_purchase_price: average_purchase_price,
                    data: memberWallet,
                    daily_sold: memberWallet.auto_sell.daily_sold,
                    weekly_sold: memberWallet.auto_sell.weekly_sold,
                    avg_purchased_rate: memberWallet.avg_purchase_price,
                    totalStakingAmount: memberWallet.total_staking_amount,
                    label: memberWallet.notification_box.label,
                    transaction_type:
                        memberWallet.notification_box.transaction_type,
                    url: memberWallet.notification_box.url,
                    button_label: memberWallet.notification_box.button_label,
                    walletTypeCode: memberWallet.walletTypeCode,
                    displayWalletCode: memberWallet.walletTypeCode,
                    locked_amount: memberWallet.locked_amount.toString(),
                    staking_max_amount:
                        memberWallet.staking_max_amount.toString(),
                });
            }
        }

        if (nextProps.walletSetting !== this.props.walletSetting) {
            if (nextProps.walletSetting.rst === "1") {
                let walletSettings = nextProps.walletSetting.data;
                this.setState({
                    autoSellStatus: walletSettings.b_auto_sell.toString(),
                    depositStatus:
                        walletSettings.topup_from_exchange !== undefined
                            ? "1"
                            : "0",

                    transferStatus:
                        walletSettings.isTransferUnderMaintenance === 1
                            ? walletSettings.isTransferUnderMaintenance.toString()
                            : walletSettings.transfer.toString(),

                    withdrawalStatus:
                        walletSettings.exchange_withdraw.toString(),
                    auctionStatus: walletSettings.b_auction.toString(),
                    isUnderMaintenance:
                        walletSettings.isWithdrawalUnderMaintenance.toString(),
                    kycCheck: walletSettings.b_kyc_check.toString(),
                    kycStatus: walletSettings.kyc_status.toString(),
                    exchangeStatus: walletSettings.b_exchange.toString(),
                    stakingStatus: walletSettings.b_staking.toString(),
                    convertStatus: walletSettings.b_convert.toString(),
                });
            }
        }
        // if (nextProps.lite_data !== this.props.lite_data) {
        //     console.log(nextProps.lite_data.data);
        //     if (nextProps.lite_data.rst === "1") {
        //         let array;
        //         array = nextProps.lite_data.data;

        //         if (Array.isArray(array.walletDetailsSetup)) {
        //             array.walletDetailsSetup = Object.assign(
        //                 {},
        //                 array.walletDetailsSetup
        //             );
        //         }

        //         this.setState({
        //             autoSellStatus:
        //                 array.walletDetailsSetup.autoSellSetting !== undefined
        //                     ? "1"
        //                     : "0",
        //             daily_sold: array.walletBalance[0].auto_sell.daily_sold,
        //             weekly_sold: array.walletBalance[0].auto_sell.weekly_sold,
        //             avg_purchased_rate:
        //                 array.walletBalance[0].avg_purchase_price,
        //         });

        //         this.setState({ data: array.walletBalance[0] });
        //         this.setState({
        //             depositStatus:
        //                 array.walletDetailsSetup.depositSetting !== undefined
        //                     ? "1"
        //                     : "0",
        //         });
        //         this.setState({
        //             transferStatus:
        //                 array.walletDetailsSetup.transferSetting !== undefined
        //                     ? array.walletDetailsSetup.transferSetting
        //                           .isUnderMaintenance === "0"
        //                         ? "1"
        //                         : "0"
        //                     : "0",
        //         });
        //         this.setState({
        //             withdrawalStatus:
        //                 array.walletDetailsSetup.withdrawSetting !== undefined
        //                     ? "1"
        //                     : "0",
        //         });
        //         this.setState({
        //             auctionStatus:
        //                 array.walletDetailsSetup.auctionSetting !== undefined
        //                     ? "1"
        //                     : "0",
        //         });
        //         this.setState({
        //             isUnderMaintenance:
        //                 array.walletDetailsSetup.withdrawSetting !== undefined
        //                     ? Object.keys(
        //                           array.walletDetailsSetup.withdrawSetting
        //                       ).length > 0
        //                         ? array.walletDetailsSetup.withdrawSetting
        //                               .isUnderMaintenance
        //                         : "0"
        //                     : "0",
        //         });
        //         this.setState({
        //             kycCheck:
        //                 array.walletDetailsSetup.withdrawSetting !== undefined
        //                     ? Object.keys(
        //                           array.walletDetailsSetup.withdrawSetting
        //                       ).length > 0
        //                         ? array.walletDetailsSetup.withdrawSetting
        //                               .kycCheck
        //                         : "0"
        //                     : "0",
        //         });
        //         this.setState({
        //             kycStatus:
        //                 array.walletDetailsSetup.withdrawSetting !== undefined
        //                     ? Object.keys(
        //                           array.walletDetailsSetup.withdrawSetting
        //                       ).length > 0
        //                         ? array.walletDetailsSetup.withdrawSetting
        //                               .kycStatus
        //                         : "0"
        //                     : "0",
        //         });
        //         this.setState({
        //             stakingStatus:
        //                 array.walletDetailsSetup.stakingSetting !== undefined
        //                     ? "1"
        //                     : "0",
        //         });
        //         this.setState({
        //             exchangeStatus:
        //                 array.walletDetailsSetup.exchangeSetting !== undefined
        //                     ? array.walletDetailsSetup.exchangeSetting
        //                           .isUnderMaintenance === "0"
        //                         ? "1"
        //                         : "0"
        //                     : "0",
        //         });
        //         this.setState({
        //             convertStatus:
        //                 array.walletDetailsSetup.convertSetting !== undefined
        //                     ? "1"
        //                     : "0",
        //         });
        //         this.setState({
        //             totalStakingAmount:
        //                 array.walletBalance[0].total_staking_amount,
        //         });
        //         this.setState({
        //             label: array.walletBalance[0].notification_box.label,
        //         });
        //         this.setState({
        //             transaction_type:
        //                 array.walletBalance[0].notification_box
        //                     .transaction_type,
        //         });
        //         this.setState({
        //             url: array.walletBalance[0].notification_box.url,
        //         });
        //         this.setState({
        //             button_label:
        //                 array.walletBalance[0].notification_box.button_label,
        //         });
        //         this.setState({
        //             walletTypeCode: array.walletBalance[0].walletTypeCode,
        //         });
        //         this.setState({
        //             displayWalletCode: array.walletBalance[0].walletTypeCode,
        //         });
        //     }
        // }

        if (nextProps.transaction_list !== this.props.transaction_list) {
            if (nextProps.transaction_list.rst === "1") {
                let array;

                array = this.state.transaction_data;
                nextProps.transaction_list.data.currentPageItems.map(function (
                    item,
                    i
                ) {
                    array.push(item);

                    return array;
                });

                if (
                    this.state.currentPage ===
                    nextProps.transaction_list.data.totalPage
                ) {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                if (nextProps.transaction_list.data.totalPage === "0") {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                this.setState({ loading: false });
                // console.log("set here", nextProps.transaction_list.data);
                this.setState({ transaction_data: array });
                this.setState({
                    totalPage: nextProps.transaction_list.data.totalPage,
                });
                this.setState({
                    currentPage: nextProps.transaction_list.data.currentPage,
                });
            } else {
                switch (nextProps.transaction_list.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                        this.setState({
                            errMsg: nextProps.transaction_list.msg,
                        });
                        this.props.openModal("error-modal");
                        break;
                }
            }
        }

        if (nextProps.cancel_data !== this.props.cancel_data) {
            if (nextProps.cancel_data.rst === "1") {
                this.props.openModal("success-modal");

                this.setState({
                    successMsg: nextProps.cancel_data.msg,
                    showLoadingModal: false,
                });
            }
        }

        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }
    }

    handleAutoSellQty(event) {
        this.setState({
            auto_sell_qty: event.target.value,
        });
    }

    handleAutoSell(e) {
        let autoSellObj = {};
        autoSellObj.username = sessionStorage.getItem("email");
        autoSellObj.token = sessionStorage.getItem("token");

        let hash = GenHash(autoSellObj, process.env.REACT_APP_GM_SECRET_KEY);
        autoSellObj.hash = hash;

        // this.setState({ loading_visible: true });

        this.props.postAutoSell(autoSellObj);
    }

    handleAutoSellAction(e) {
        if (
            this.state.auto_sell_qty > 0 &&
            this.state.auto_sell_qty <= this.state.data.auto_sell_available_qty
        ) {
            this.setState({
                confirm_visible: true,
            });
        }
    }

    handleAutoSellSubmission() {
        const submitSell = {};
        const { walletType } =
            this.props.location.state === undefined
                ? ""
                : this.props.location.state;

        submitSell.qty = this.state.auto_sell_qty;
        submitSell.lang = sessionStorage.getItem("lang");
        submitSell.walletType = walletType;
        let hash = GenHash(submitSell, process.env.REACT_APP_GM_SECRET_KEY);
        submitSell.hash = hash;

        this.setState({
            //   loading_visible: true,
            confirm_visible: false,
        });

        this.props.postAutoSellAction(submitSell);
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    render() {
        const {
            data,
            transaction_data,
            depositStatus,
            transferStatus,
            withdrawalStatus,
            stakingStatus,
            exchangeStatus,
            convertStatus,
            auctionStatus,
            loading,
            totalPage,
            currentPage,
            balance,
            showErrorModal,
            isUnderMaintenance,
            kycCheck,
            errMsg,
            kycStatus,
            showNotice,
            showSuccessModal,
            showLoadingModal,
            successMsg,
            showConfirmationModal,
            productCodeArr,
            label,
            url,
            transaction_type,
            button_label,
            walletTypeCode,
            loading_visible,
            confirm_visible,
            modal_visible,
            modal_type,
            modal_msg,
            staking_max_amount,
            locked_amount,
            average_purchase_price,
        } = this.state;

        const { walletType } =
            this.props.location.state === undefined
                ? ""
                : this.props.location.state;

        let allItemRows = [];

        transaction_data.map((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);

            return allItemRows;
        });

        return (
            <div className="dark-theme">
                {showNotice && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("profile.maintenance_notice")}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("profile.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleNoticeClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showNotice}
                        modalClassName=""
                        modalClassId="notice-modal"
                    />
                )}
                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}
                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("transfer.confirmation_msg")}</h4>
                                </div>
                            </div>
                        }
                        closeText={t("purchasePin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={this.handleSubmitWithdrawalClose}
                        closeId=""
                        submitText={t("common.submit")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleCancelSalesTrans}
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="cancel-trans-confirmation-modal"
                    />
                )}
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}
                <HyperModal
                    category="response"
                    modalVisible={loading_visible}
                    modalType="loading"
                    footer={[]}
                />
                <HyperModal
                    category="response"
                    modalVisible={modal_visible}
                    modalType={modal_type}
                    modalMsg={modal_msg}
                    modalCloseFunc={this.handleModalClose}
                    footer={[
                        <button
                            key="submit"
                            className="col-12 btn btn-primary text-capitalize"
                            onClick={(e) =>
                                this.handleModalClose(e, modal_type)
                            }
                        >
                            {t("login.close")}
                        </button>,
                    ]}
                    cancelFunc={this.handleModalClose}
                />
                <HyperModal
                    category="normal"
                    modalVisible={confirm_visible}
                    footer={
                        <div className="col-12 text-center">
                            <button
                                key="submit"
                                className="col-3 btn btn-primary"
                                onClick={this.handleAutoSellSubmission}
                            >
                                {t("common.submit")}
                            </button>
                            <button
                                key="button"
                                className="col-3 btn btn-danger"
                                onClick={(e) =>
                                    this.handleModalClose(e, "confirm")
                                }
                            >
                                {t("purchasePin.modal_close")}
                            </button>
                        </div>
                    }
                    modalContent={
                        <div className="row">
                            <div className="col-12 text-center">
                                <ConfirmationIcon
                                    width="100px"
                                    height="0px !important"
                                />
                                <h4>
                                    {t(
                                        "walletDetails.confirmation_sell_qty",
                                        this.props.location.state
                                    )}
                                    ?
                                </h4>
                                <p>{t("purchasePin.confirmation_msg")}</p>
                            </div>
                        </div>
                    }
                    onCancel={this.handleSubmitWithdrawalClose}
                    closable={false}
                ></HyperModal>
                <PageHeader
                    titleHeader="col-12 text-center"
                    backgroundHeight={230}
                    prevousPageLink=""
                    navClass=""
                    historyBack="/home"
                    historyBackClass="py-2"
                    avatarDivClass="text-center header offset-md-2 col-md-8"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={data.displayCode ? data.displayCode : ""}
                    title={data.displayCode ? data.displayCode : ""}
                    balance={data.total_amount ? data.total_amount : balance}
                    staking_max_amount={staking_max_amount.toString()}
                    lockedAmount={locked_amount.toString()}
                    availableAmount={data.available_amount}
                    averagePurchasePrice={average_purchase_price}
                    arrowLeft={true}
                    headerBanner={true}
                    backgroundHeight={
                        parseInt(staking_max_amount) !== 0 ||
                        average_purchase_price !== 0
                            ? 260
                            : 230
                    }
                />
                <div className="walletDetails">
                    <div className="px-4 offset-md-2 col-md-8 px-0">
                        <div className="wallet-details-asset-panel-padding">
                            {/* use for here */}
                            <TransPanel
                                depositStatus={
                                    depositStatus === "1" ? true : false
                                }
                                walletType={walletType}
                                displayWalletCode={data.displayCode}
                                transferStatus={transferStatus === "1"}
                                // withdrawalStatus={(withdrawalStatus === '1' && isUnderMaintenance === '0' && kycCheck === '1') ? (kycStatus === '1' ? true : false) : ((withdrawalStatus === '1' && isUnderMaintenance === '0' && kycCheck === '0') ? true : false)}
                                // withdrawalStatus={
                                //     withdrawalStatus === "1" &&
                                //     isUnderMaintenance === "0"
                                // }
                                withdrawalStatus={false}
                                stakingStatus={stakingStatus === "1"}
                                exchangeStatus={exchangeStatus === "1"}
                                convertStatus={convertStatus === "1"}
                                auctionStatus={auctionStatus === "1"}
                                convertNavProps={
                                    Object.keys(productCodeArr).length > 0 &&
                                    productCodeArr.productCode !== null
                                        ? productCodeArr
                                        : null
                                }
                                withdrawalOnClick={
                                    isUnderMaintenance === "1"
                                        ? this.handleNoticeOpen
                                        : undefined
                                }
                            />
                        </div>
                    </div>
                    <div className="">
                        {label !== undefined && (
                            <div className="px-0 offset-md-2 col-md-8">
                                <div className="px-4 mt-3">
                                    <div className="card mx-auto shadow">
                                        <div
                                            className="card-body text-light"
                                            id="card-1"
                                        >
                                            <div className="row">
                                                <div className="col-12 list-title">
                                                    <h5>{label}</h5>
                                                    <h6>{transaction_type}</h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="pt-4 col-12">
                                                    <Link
                                                        to={{
                                                            pathname: "/" + url,
                                                            state: {
                                                                walletType:
                                                                    walletTypeCode,
                                                                cryptoAddress:
                                                                    walletTypeCode,
                                                            },
                                                        }}
                                                    >
                                                        <button
                                                            type="submit"
                                                            className="col-12 depth-blue-btn"
                                                        >
                                                            {button_label}
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* 
                    {this.state.autoSellStatus === "1" && (
                        <div className="offset-md-2 col-md-8">
                            <div className="px-2 mt-3">
                                <div className="card mx-auto shadow bg-dark-purple">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="row col-12 justify-content-center align-self-center pr-0">
                                                <div className="col-6 text-left">
                                                    <h5 className="sky-blue-small-text-style text-uppercase profile-main-label">
                                                        <span>
                                                            {t(
                                                                "walletDetails.auto_sell"
                                                            )}
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="col-6 text-right pr-0"> */}
                        {/* <Switch
                                                        checked={parseInt(this.state.autoSellSetting)}
                                                        onChange={this.handleAutoSell}
                                                    /> */}

                        {/* <Link
                                                        className="text-light"
                                                        to={{
                                                            pathname:
                                                                "/autoSell/statements",
                                                            state: {
                                                                walletType: this
                                                                    .props
                                                                    .location
                                                                    .state
                                                                    .walletype,
                                                            },
                                                        }}
                                                    >
                                                        {t(
                                                            "walletDetails.view_statement"
                                                        )}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div> */}
                        {/* <hr className="auto-sell-hr my-2" /> */}
                        {/* <div className="row">
                                            <div className="row col-12 text-light pr-0">
                                                <div className="col-6 text-left">
                                                    {t(
                                                        "walletDetails.total_daily_sold"
                                                    )}
                                                </div>
                                                <div className="col-6 text-right pr-0">
                                                    <Link
                                                        className="text-light"
                                                        to={{
                                                            pathname:
                                                                "/autoSell/statements",
                                                            state: {
                                                                walletType: this
                                                                    .props
                                                                    .location
                                                                    .state
                                                                    .walletype,
                                                            },
                                                        }}
                                                    >
                                                        {this.state.daily_sold}{" "}
                                                        USDT
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="row col-12 text-light pr-0">
                                                <div className="col-6 text-left">
                                                    {t(
                                                        "walletDetails.total_weekly_sold"
                                                    )}
                                                </div>
                                                <div className="col-6 text-right pr-0">
                                                    <Link
                                                        className="text-light"
                                                        to={{
                                                            pathname:
                                                                "/autoSell/statements",
                                                            state: {
                                                                walletType: this
                                                                    .props
                                                                    .location
                                                                    .state
                                                                    .walletype,
                                                            },
                                                        }}
                                                    >
                                                        {this.state.weekly_sold}{" "}
                                                        USDT
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="row col-12 text-light pr-0">
                                                <div className="col-6 text-left">
                                                    {t(
                                                        "walletDetails.average_purchased_price"
                                                    )}
                                                </div>
                                                <div className="col-6 text-right pr-0">
                                                    {
                                                        this.state
                                                            .avg_purchased_rate
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="auto-sell-hr my-2" />
                                        <div className="row">
                                            <div className="row col-12 text-light pr-0">
                                                <div className="col-12">
                                                    {t(
                                                        "walletDetails.available_qty"
                                                    )}{" "}
                                                    (
                                                    {
                                                        this.state.data
                                                            .auto_sell_available_qty
                                                    }
                                                    )
                                                </div>
                                                <div className="col-6 text-left">
                                                    <NumberInput
                                                        className={`form-control remove-placeholder depth-input`}
                                                        placeholder="0.00"
                                                        onChange={
                                                            this
                                                                .handleAutoSellQty
                                                        }
                                                        min={0.0}
                                                        max={
                                                            this.state.data
                                                                .auto_sell_available_qty
                                                        }
                                                        value={
                                                            this.state
                                                                .auto_sell_qty
                                                        }
                                                    />
                                                </div>
                                                <div className="col-6 text-right pr-0">
                                                    <button
                                                        key="submit"
                                                        className="col-12 depth-blue-btn text-uppercase"
                                                        onClick={
                                                            this
                                                                .handleAutoSellAction
                                                        }
                                                    >
                                                        {t("common.sell")}
                                                    </button>
                                                </div> */}
                        {/* </div>
                                        </div> */}
                        {/* </div>
                                </div>
                            </div>
                        </div>
                    )} */}

                        <div
                            className={
                                depositStatus === "1" ||
                                (withdrawalStatus === "1" &&
                                    (kycCheck === "1"
                                        ? kycStatus === "1" &&
                                          withdrawalStatus === "1"
                                            ? true
                                            : false
                                        : true)) ||
                                convertStatus === "1"
                                    ? "pt-3 offset-md-2 col-md-8 px-0"
                                    : "pt-5 offset-md-2 col-md-8 px-0"
                            }
                        >
                            <div className="col-12 pl-4 py-2 list-title-background">
                                <div className="details-size-80 list-title-color">
                                    <span>
                                        {t("walletDetails.transaction_history")}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="trans-detail overflow-auto custom-height"
                                // style={{
                                //     height: "calc(100vh - 330px)",
                                //     // paddingBottom: "100px",
                                // }}
                            >
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadMoreList}
                                    hasMore={this.state.hasMoreItems}
                                    useWindow={false}
                                    // loader={
                                    //     (!loading && totalPage === 1) ||
                                    //     (!loading && currentPage === totalPage) ? (
                                    //         ""
                                    //     ) : (
                                    //         <div className="row col-12" key={0}>
                                    //             <div className="mx-auto">
                                    //                 <LoadingMask width={80} />
                                    //             </div>
                                    //         </div>
                                    //     )
                                    // }
                                    initialLoad={false}
                                    threshold={15}
                                >
                                    {
                                        <aside className="list-group">
                                            {allItemRows}
                                        </aside>
                                    }
                                </InfiniteScroll>
                                {((!loading_visible && totalPage === "1") ||
                                    (!loading_visible &&
                                        currentPage === totalPage)) && (
                                    <div className="col-12 text-white">
                                        <div className="row">
                                            <div className="mx-auto">
                                                <span>
                                                    <small>
                                                        <b>
                                                            {t(
                                                                "common.end_of_record"
                                                            )}
                                                        </b>
                                                    </small>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {!loading_visible && totalPage < 1 && (
                            <div className="col-12 text-white">
                                <div className="row">
                                    <div className="mx-auto">
                                        <span>
                                            <small>
                                                <b>{t("common.no_record")}</b>
                                            </small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, modal, walletDetails, autoSell } = state;

    return {
        // data: walletBalance.data,
        // lite_data: walletBalance.lite_data,
        classIsShowing: modal.classIsShowing,
        postAutoSellResult: walletBalance.postAutoSell,
        autoSellAction: autoSell.data,
        transaction_list: walletDetails.data,
        cancel_data: walletDetails.cancel_data,
        isLoading: walletBalance.isLoading,
        walletSetting: walletBalance.walletSetting,
        memberWallet: walletBalance.memberWallet,
    };
};

const mapDispatchToProps = {
    postWalletBalance,
    postTransactionList,
    postTransactionSalesCancel,
    // postWalletBalanceLite,
    postAutoSellAction,
    closeModal,
    openModal,
    postAutoSell,
    getMemberWallet,
    getWalletDetailSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletDetails);
