import React, { Component } from "react";
import { connect } from "react-redux";
import { t } from "react-i18nify";
import { message } from "antd";
import copy from "copy-to-clipboard";
import { openModal, closeModal } from "../../../store/ducks/modal/actions";
import { postWalletBalanceLite } from "../../../store/ducks/home/actions";
import { postShareContract } from "../../../store/ducks/share/actions";
import LoadingMask from "../../../components/loadingMask";
import Modal from "../../../components/modal";
import ErrorIcon from "../../../components/ErrorAlert";
import SuccessIcon from "../../../components/SuccessAlert";
import PageHeader from "../../../components/PageHeaderV2";
import ConfirmationIcon from "../../../components/ConfirmationAlert";

import "./invoice.scss";

class aucShare extends Component {
    constructor(props) {
        super(props);

        let auctionFirstBadge = sessionStorage.getItem("auctionFirstBadge");
        this.myRef = React.createRef();
        this.state = {
            username: sessionStorage.getItem("email"),
            token: sessionStorage.getItem("token"),
            lang: sessionStorage.getItem("lang"),
            data: [],
            price_data: [],
            qr_path: "",
            setOpen: false,
            open: false,
            address: "",
            walletType:
                props.location.state !== undefined
                    ? props.location.state.walletType
                    : auctionFirstBadge,
            displayWalletType:
                props.location.state !== undefined
                    ? props.location.state.walletType
                    : auctionFirstBadge,
            cryptoAddress: "",
            showErrorModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            showLoadingModal: false,
            minAmount: 0,
            maxAmount: "",
            errMsg: "",
            errType: "",
            successMsg: "",
            s_password: "",
            estimated_amount: 0,
            available_amount: "0",
            amount_usdt: 0,
            wallet_balance_data: [],
            display: "not_display",
            auctionSetting: [],
            contract_data: {},
        };
        const data = {};
        data.doc_no =
            this.props.location.state !== undefined
                ? this.props.location.state.doc_no
                : "";

        this.props.postShareContract(data);

        this.handleTooltipClose = this.handleTooltipClose.bind(this);
        this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    handleBack() {
        this.props.history.goBack();
    }

    UNSAFE_componentWillMount() {
        if (this.props.location.state === undefined) {
            message.error("");
            this.props.history.push("/home");
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "loading-modal") {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }
            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
            if (
                nextProps.classIsShowing.modalName ===
                "auction-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.contract_data !== this.props.contract_data) {
            if (nextProps.contract_data.rst === "1") {
                let array;
                array = nextProps.contract_data.data;

                this.setState({
                    contract_data: array,
                });
            }
        }
    }
    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/home");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleTooltipClose = () => {
        this.setState({ open: false });
    };

    handleTooltipOpen = () => {
        copy(this.state.address);

        message.success(t("copied"));
    };

    onChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    render() {
        const {
            showErrorModal,
            errMsg,
            showLoadingModal,
            wallet_balance_data,
            showConfirmationModal,
            successMsg,
            showSuccessModal,
            contract_data,
        } = this.state;

        return (
            <div className="invoice-page">
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="btn depth-red-btn col-12"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}
                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("transfer.confirmation_msg")}</h4>
                                </div>
                            </div>
                        }
                        closeText={t("purchasePin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={this.handleSubmitAuctionClose}
                        closeId=""
                        submitText={t("common.submit")}
                        submitClassName="btn depth-blue-btn"
                        submitFunc={this.handleSubmitAuction}
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="auction-confirmation-modal"
                    />
                )}
                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="col-12 btn depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div className="container-fluid px-0">
                    <PageHeader
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        // historyBack="/home"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        availableAmount={wallet_balance_data.available_amount}
                        title={t("share.detail_gcox_share")}
                        arrowLeft={true}
                        goBackFunc={this.handleBack}
                        redeem={true}
                        redeemUrl="/share/auc"
                        redeemProp={contract_data.doc_no}
                        redeemStatus={contract_data.status}
                    />
                </div>
                <div className="invoice-bg offset-md-2 col-md-8 mb-2 pl-0 pr-0">
                    <div className="gcox p-5"></div>
                </div>
                <div className="p-2 offset-md-2 col-md-8">
                    <div className="row">
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-left">
                                {t("share.email")}
                            </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-right">
                                {contract_data.email}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-left">
                                {t("share.order_id")}
                            </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-right">
                                {contract_data.doc_no}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-left">
                                {t("share.order_date")}
                            </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-right">
                                {contract_data.trans_date}
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                {/*<div className="gcox p-2 offset-md-2 col-md-8">*/}
                {/*    <strong className="auc-payment text-left text-white">{t('share.gcox')}</strong>*/}
                {/*</div>*/}
                <div className="p-2 offset-md-2 col-md-8">
                    <div className="row">
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-left">
                                {t("share.price")}
                            </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-right">
                                {contract_data.price}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-left">
                                {t("share.quota")}
                            </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-right">
                                {contract_data.quota_limit}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="invoice-footer p-2 offset-md-2 col-md-8"></div>
                <div className="invoice-footer p-2 offset-md-2 col-md-8">
                    <div className="row">
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-left">
                                {t("share.total_payment")}
                            </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-right">
                                {t("share.12_months")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="invoice-footer p-2 offset-md-2 col-md-8"></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { modal, walletBalance, share } = state;

    return {
        classIsShowing: modal.classIsShowing,
        wallet_balance_data: walletBalance.lite_data,
        contract_data: share.contract_data,
    };
};

const mapDispatchToProps = {
    postWalletBalanceLite,
    closeModal,
    openModal,
    postShareContract,
};

export default connect(mapStateToProps, mapDispatchToProps)(aucShare);
