import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './DateIcon.scss'
import moment from "moment";

class DateIcon extends Component {

    render() {

        const { date, className } = this.props;

        // let dateTime = new Date(date);
        // let getDate = dateTime.getDate();
        // let getMonth = dateTime.getMonth() + 1;
        //
        // if (dateTime.getDate() < 10){
        //     getDate = '0' + dateTime.getDate();
        // }
        //
        // if (dateTime.getMonth() + 1 < 10){
        //     getMonth = '0' + (dateTime.getMonth() + 1);
        // }
        //
        // let t_date = dateTime.getFullYear() + '/' + getMonth + '/' + getDate;
        //
        // const val = t_date.split('/');

        var check = moment(date, 'YYYY/MM/DD');

        var month = check.format('M');
        var day   = check.format('D');
        if (day < 10){
            day = '0' + day;
        }
        var year  = check.format('YYYY');

        return (
            <div className={`${className} dateIcon`}>
                <div className='dateIcon date-display'>
                    <div className='date-day'>
                        {day}
                    </div>
                    <div>
                        {month}/{year}
                    </div>
                </div>
            </div>
        )
    }
}

export default DateIcon;

DateIcon.propTypes = {
    date: PropTypes.string.isRequired,
    className: PropTypes.string
};
