import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { setLocale, t } from "react-i18nify";
import connect from "react-redux/es/connect/connect";
import { t } from "../../common/translation";
import TabFooter from "../../components/TabFooter";
import PageHeader from "../../components/PageHeaderV2";
import Modal from "../../components/modal";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import LoadingMask from "../../components/loadingMask";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";
import Select from "../../components/input/Select2";
import { Switch } from "antd";
import HyperModal from "../../components/HyperModal";

import {
    getProfile,
    postUpdateLang,
    postLogout,
    postChangeBonusPayoutStatus,
    postAutoPlacement,
} from "../../store/ducks/profile/actions";
import { postTwoFactorAuthentication } from "../../store/ducks/twoFactorAuthentication/actions";
import { postIsAuthenticated } from "../../store/ducks/auth/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";

import { GenHash, fakeAuth, hashing256 } from "../../common/helpers";

import RightArrow from "../../public/images/arrow-next@3x.png";

import PasswordImg from "../../public/v2/icon/icon-account-password.svg";
import TwoFAImg from "../../public/v2/icon/icon-account-2fa.svg";
import LangImg from "../../public/v2/icon/icon-account-language.svg";
import FriendImg from "../../public/v2/icon/icon-account-friends.svg";
import GroupImg from "../../public/v2/icon/icon-account-my group.svg";
import RewardImg from "../../public/v2/icon/icon-account-rewards.svg";

import PersonalImg from "../../public/v2/icon/icon-account-personal.svg";
import ReferralImg from "../../public/v2/icon/icon-account-referral.svg";
import PlacementImg from "../../public/v2/icon/icon-account-auto-placement.svg";
import AnnouncementImg from "../../public/v2/icon/icon-account-announcement.svg";

import "./profile.scss";

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            email: "",
            mobile: "",
            mobilePrefix: "",
            twoFAStatus: 0,
            ranking: 1,
            twoFASecret: "",
            twoFACodeUrl: "",
            package: "",
            isChecked: true,
            loading: false,
            showLoadingModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            avatarImgUrl: "",
            showErrorModal: false,
            errMsg: "",
            errType: "",
            successHeaderMsg: "",
            successMsg: "",
            screenHeight: window.innerHeight - 55,
            secondaryPwd: "0",
            options: [
                { value: "en", name: "ENGLISH" },
                { value: "zh-CN", name: "简体中文" },
                // {'value': 'kr', 'name': '대한민국'},
                // {'value': 'jp', 'name': 'ジャパン'}
            ],
            languageSelected: sessionStorage.getItem("lang"),
            placementSetting: false,
            placementCount: 0,
            loading_visible: true,
        };

        this.handleLogout = this.handleLogout.bind(this);
        this.handleSubmitBonusPayoutStatus = this.handleSubmitBonusPayoutStatus.bind(
            this
        );
        this.updateHeight = this.updateHeight.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleSubmitBonusPayout = this.handleSubmitBonusPayout.bind(this);
        this.handleLanguage = this.handleLanguage.bind(this);
        this.handleAutoPlacement = this.handleAutoPlacement.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `110px`);
    }

    UNSAFE_componentWillMount() {
        this.setState({
            showLoadingModal: true,
        });
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `110px`);
        });

        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isAuthenticated !== this.props.isAuthenticated) {
            if (nextProps.isAuthenticated.data === true) {
                let profileObj = {};
                profileObj.token = sessionStorage.getItem("token");

                let hash = GenHash(
                    profileObj,
                    process.env.REACT_APP_GM_SECRET_KEY
                );
                profileObj.hash = hash;

                this.props.getProfile(profileObj);
            } else {
                let profileObj = {};
                profileObj.token = sessionStorage.getItem("token");

                let hash = GenHash(
                    profileObj,
                    process.env.REACT_APP_GM_SECRET_KEY
                );
                profileObj.hash = hash;

                this.props.getProfile(profileObj);
            }
        }

        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName ===
                "bonus-payout-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName ===
                "bonus-payout-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.profile_data !== this.props.profile_data) {
            if (nextProps.profile_data.rst === "1") {
                this.setState(
                    {
                        twoFAStatus: nextProps.profile_data.data.twoFaStatus,
                        email: nextProps.profile_data.data.email,
                        name: nextProps.profile_data.data.name,
                        username: sessionStorage.getItem("uname"),
                        mobile: nextProps.profile_data.data.mobileNumber,
                        mobilePrefix: nextProps.profile_data.data.mobilePrefix,
                        secondaryPwd:
                            nextProps.profile_data.data.secondaryPasswordStatus,
                        ranking: nextProps.profile_data.data.rank,
                        placementCount:
                            nextProps.profile_data.data.placementCount,
                        isChecked:
                            nextProps.profile_data.data.bonusPayout === "0"
                                ? false
                                : true,
                        package: nextProps.profile_data.data.package,
                        avatarImgUrl: nextProps.profile_data.data.profilePicUrl,
                        placementSetting:
                            nextProps.profile_data.data.placementSetting === "1"
                                ? true
                                : false,
                        showLoadingModal: false,
                    },
                    () => {
                        sessionStorage.setItem(
                            "placement",
                            hashing256(
                                nextProps.profile_data.data.placementSetting,
                                process.env.REACT_APP_GM_SECRET_KEY
                            )
                        );
                    }
                );
            }
        }

        if (nextProps.placement_data !== this.props.placement_data) {
            if (nextProps.placement_data.rst === "1") {
                let profileObj = {};
                profileObj.username = sessionStorage.getItem("email");
                profileObj.token = sessionStorage.getItem("token");

                let hash2 = GenHash(
                    profileObj,
                    process.env.REACT_APP_GM_SECRET_KEY
                );
                profileObj.hash = hash2;

                this.props.getProfile(profileObj);
            } else {
                this.setState(
                    {
                        showLoadingModal: false,
                        errMsg: t("profile.failed_update_placement"),
                    },
                    () => {}
                );

                this.props.openModal("error-modal");
            }
        }

        if (
            nextProps.bonusPayoutStatusData !== this.props.bonusPayoutStatusData
        ) {
            this.setState({
                showLoadingModal: false,
            });

            if (nextProps.bonusPayoutStatusData.rst === "1") {
                this.props.openModal("success-modal");

                this.setState({
                    isChecked: !this.state.isChecked, // flip boolean value
                    successMsg: t(
                        "profile." + nextProps.bonusPayoutStatusData.msg
                    ),
                });
            } else {
                this.props.openModal("error-modal");
            }
        }

        if (nextProps.twoFactorAuthData !== this.props.twoFactorAuthData) {
            this.setState({
                twoFASecret: nextProps.twoFactorAuthData.data.secret,
                twoFACodeUrl: nextProps.twoFactorAuthData.data.codeUrl,
            });
        }

        if (
            nextProps.postTwoFactorAuthData !== this.props.postTwoFactorAuthData
        ) {
            if (nextProps.postTwoFactorAuthData.rst === "1") {
                this.setState({
                    twoFAStatus: 0,
                    successMsg: nextProps.postTwoFactorAuthData.msg,
                    showLoadingModal: false,
                });

                this.props.openModal("success-modal");

                setTimeout(function () {
                    nextProps.history.push("/profile");
                }, 1000);
            } else {
                switch (nextProps.postTwoFactorAuthData.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    case "system_error":
                        this.setState({
                            errMsg: t(
                                "common.err." +
                                    nextProps.postTwoFactorAuthData.msg
                            ),
                            errType: "system_error",
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                        this.setState({
                            showLoadingModal: false,
                            errMsg: nextProps.postTwoFactorAuthData.msg,
                        });

                        this.props.openModal("error-modal");
                        break;
                }
            }
        }

        if (nextProps.logout_data !== this.props.logout_data) {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("email");
            sessionStorage.removeItem("subscriptionToken");
            sessionStorage.removeItem("uname");
            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("accessToken");
            sessionStorage.removeItem("showAnnoucement");
            sessionStorage.removeItem("kycStatus");
            sessionStorage.removeItem("checkoutAddress");

            fakeAuth.signout();

            this.props.history.push("/login");

            this.props.closeModal("bonus-payout-confirmation-modal");
            this.props.closeModal("bonus-payout-loading-modal");
        }

        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }
    }

    handleAutoPlacement(e) {
        let auto_placement = "0";

        if (e) {
            auto_placement = "1";
        } else {
            auto_placement = "0";
        }

        let placementObj = {};
        placementObj.username = sessionStorage.getItem("email");
        placementObj.autoPlacement = auto_placement;
        placementObj.token = sessionStorage.getItem("token");

        let hash = GenHash(placementObj, process.env.REACT_APP_GM_SECRET_KEY);
        placementObj.hash = hash;

        this.setState({ showLoadingModal: true });

        this.props.postAutoPlacement(placementObj);
    }

    updateHeight() {
        this.setState({ screenHeight: window.innerHeight - 70 });
    }

    handleLogout(event) {
        let logoutObj = {};
        logoutObj.username = sessionStorage.getItem("email");
        logoutObj.token = sessionStorage.getItem("token");
        logoutObj.lang = sessionStorage.getItem("lang");
        let hash = GenHash(logoutObj, process.env.REACT_APP_GM_SECRET_KEY);
        logoutObj.hash = hash;

        this.props.openModal("bonus-payout-loading-modal");

        this.props.postLogout(logoutObj);
    }

    async handleSubmitBonusPayoutStatus(event) {
        this.props.openModal("bonus-payout-confirmation-modal");
    }

    handleSubmitBonusPayout(event) {
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.bonusPayout = this.state.isChecked === false ? "1" : "0";

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({ showConfirmationModal: false });

        this.props.openModal("bonus-payout-loading-modal");

        this.props.postChangeBonusPayoutStatus(data);
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleConfirmationClose() {
        this.props.closeModal("bonus-payout-confirmation-modal");
    }

    handleLanguage(event) {
        //   setLocale(event.target.value);

        sessionStorage.setItem("lang", event.target.value);

        this.setState({
            languageSelected: event.target.value,
        });

        const data = {};

        data.lang = event.target.value;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postUpdateLang(data);
    }

    render() {
        const {
            placementCount,
            placementSetting,
            showLoadingModal,
            showErrorModal,
            showSuccessModal,
            showConfirmationModal,
            errMsg,
            successHeaderMsg,
            successMsg,
            languageSelected,
            ranking,
            loading_visible,
        } = this.state;

        return (
            <div className="profile">
                <div
                    // className="overflow-auto"
                    style={{ height: this.state.screenHeight }}
                >
                    <PageHeader
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        historyBack="/home"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        walletType={""}
                        title={t("profile.profile")}
                        arrowLeft={false}
                    />

                    <div className="overflow-auto custom-height">
                        {showConfirmationModal && (
                            <Modal
                                title=""
                                children={
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <ConfirmationIcon
                                                width="100px"
                                                height="0px !important"
                                            />
                                            <h4>
                                                {t(
                                                    "profile.are_you_sure_to_change_payout_status"
                                                )}
                                                ?
                                            </h4>
                                        </div>
                                    </div>
                                }
                                closeText="Close"
                                closeClassName="btn modal-btn"
                                closeFunc={this.handleConfirmationClose}
                                closeId=""
                                submitText={t("common.submit")}
                                submitClassName="depth-blue-btn"
                                submitFunc={this.handleSubmitBonusPayout}
                                submitId="submit"
                                modalFooterClassName="custom-modal-footer"
                                isShowing={showConfirmationModal}
                                modalClassName=""
                                modalClassId="bonus-payout-confirmation-modal"
                            />
                        )}
                        <HyperModal
                            category="response"
                            modalVisible={loading_visible}
                            modalType="loading"
                            footer={[]}
                        />

                        {/* {showLoadingModal && (
                        <Modal
                            title=""
                            children={
                                <div className="container pt-5">
                                    <div className="row">
                                        <div className="mx-auto">
                                            <LoadingMask width={80} />
                                        </div>
                                    </div>
                                </div>
                            }
                            isShowing={showLoadingModal}
                            modalId="loading-modal"
                            modalClassName=""
                            modalClassId="bonus-payout-loading-modal"
                            modalClassContent="loading-bg"
                        />
                    )} */}

                        {showErrorModal && (
                            <Modal
                                title=""
                                children={
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <ErrorIcon
                                                width="100px"
                                                height="0px !important"
                                            />
                                            <h4>{errMsg}</h4>
                                        </div>
                                    </div>
                                }
                                closeText=""
                                closeClassName=""
                                closeFunc=""
                                closeId=""
                                submitText={t("common.close")}
                                submitClassName="depth-red-btn"
                                submitFunc={this.handleErrorClose}
                                submitId=""
                                modalFooterClassName="custom-modal-footer"
                                isShowing={showErrorModal}
                                modalClassName=""
                                modalClassId="error-modal"
                            />
                        )}

                        {showSuccessModal && (
                            <Modal
                                title=""
                                children={
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <SuccessIcon
                                                width="100px"
                                                height="0px !important"
                                            />
                                            <h4>{successHeaderMsg}</h4>
                                            <p>{successMsg}</p>
                                        </div>
                                    </div>
                                }
                                closeText=""
                                closeClassName=""
                                closeFunc=""
                                closeId=""
                                submitText={t("common.close")}
                                submitClassName="depth-blue-btn"
                                submitFunc={this.handleSuccessClose}
                                submitId=""
                                modalFooterClassName="custom-modal-footer"
                                isShowing={showSuccessModal}
                                modalClassName=""
                                modalClassId="success-modal"
                            />
                        )}

                        {/*<div className="px-4 mt-3">*/}
                        {/*    <div className="card mx-auto shadow">*/}
                        {/*        <div className="card-body">*/}
                        {/*            <div className="row">*/}
                        {/*                <div className="col-12"><strong className="card-title">{t('profile.invite_friends_to_join', {'title': process.env.REACT_APP_TITLE})}</strong></div>*/}
                        {/*                <div className="col-12">*/}
                        {/*                    <img src={profileReferralImg} width="100%" alt="" />*/}
                        {/*                </div>*/}
                        {/*                <div className="col-12 mt-1">*/}
                        {/*                    /!*<p className="card-text">{t('profile.increase_your_income')}</p>*!/*/}
                        {/*                    <Link to="/referral">*/}
                        {/*                        <button className="btn btn-warning btn-outline-warning col-12 text-uppercase">{t('profile.invite_now')}</button>*/}
                        {/*                    </Link>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="offset-md-2 col-md-8 pl-0 pr-0">
                            {/*<div className="px-4 mt-3">*/}
                            {/*    <div className="card mx-auto shadow">*/}
                            {/*        <div className="card-body">*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-12">*/}
                            {/*                    <h5 className="card-text text-uppercase profile-main-label"><img src={PersonalInfoImg} alt="" width={15}/><span className="pl-2">{t('profile.personal_info')}</span></h5>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <br/>*/}

                            {/*            <div className="form-group border-bottom-label">*/}
                            {/*                <div className="row">*/}
                            {/*                    <span className="col-4 text-uppercase label-info label-size">{t('profile.username')}</span>*/}
                            {/*                    <div className="col-8 text-right">{this.state.name}</div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <div className="form-group border-bottom-label">*/}
                            {/*                <div className="row">*/}
                            {/*                    <div className="col-4 text-uppercase label-info label-size">{t('profile.email')}</div>*/}
                            {/*                    <div className="col-8 text-right">{this.state.email}</div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <div className="form-group border-bottom-label">*/}
                            {/*                <div className="row">*/}
                            {/*                    <div className="col-4 text-uppercase label-info label-size">{t('profile.mobile')}</div>*/}
                            {/*                    <div className="col-8 text-right">{this.state.mobilePrefix} {this.state.mobile}</div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <div className="form-group border-bottom-label">*/}
                            {/*                <div className="row">*/}
                            {/*                    <div className="col-4 text-uppercase label-info label-size">{t('profile.tier')}</div>*/}
                            {/*                    <div className="col-8 text-right">{t('profile.'+ this.state.package)}</div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="px-0">
                                <div className="px-2 mt-3">
                                    <div className="card mx-auto shadow">
                                        <div className="card-body">
                                            <Link
                                                to={{
                                                    pathname: "/profile/info",
                                                    state: {
                                                        name: this.state.name,
                                                        email: this.state.email,
                                                        mobile_no:
                                                            this.state
                                                                .mobilePrefix +
                                                            " " +
                                                            this.state.mobile,
                                                        tier: t(
                                                            "profile." +
                                                                this.state
                                                                    .package
                                                        ),
                                                        rank: ranking,
                                                    },
                                                }}
                                            >
                                                <div className="row">
                                                    <div className="col-6 justify-content-center align-self-center">
                                                        <h5 className="card-text text-uppercase profile-main-label">
                                                            <img
                                                                src={
                                                                    PersonalImg
                                                                }
                                                                alt=""
                                                                width={15}
                                                            />
                                                            <span className="pl-2">
                                                                {t(
                                                                    "profile.personal_info"
                                                                )}
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <img
                                                            className="mb-1"
                                                            src={RightArrow}
                                                            alt=""
                                                            width={10}
                                                        />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-0">
                                <div className="px-2 mt-3">
                                    <div className="card mx-auto shadow">
                                        <div className="card-body">
                                            <Link to="/announcement">
                                                <div className="row">
                                                    <div className="col-6 justify-content-center align-self-center">
                                                        <h5 className="card-text text-uppercase profile-main-label">
                                                            <img
                                                                src={
                                                                    AnnouncementImg
                                                                }
                                                                alt=""
                                                                width={15}
                                                            />
                                                            <span className="pl-2">
                                                                {t(
                                                                    "profile.announcement"
                                                                )}
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <img
                                                            className="mb-1"
                                                            src={RightArrow}
                                                            alt=""
                                                            width={10}
                                                        />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="px-0">
                            <div className="px-2 mt-3">
                                <div className="card mx-auto shadow">
                                    <div className="card-body">
                                        <Link to="/referral">
                                            <div className="row">
                                                <div className="col-6 justify-content-center align-self-center">
                                                    <h5 className="card-text text-uppercase profile-main-label">
                                                        <img
                                                            src={ReferralImg}
                                                            alt=""
                                                            width={15}
                                                        />
                                                        <span className="pl-2">
                                                            {t(
                                                                "profile.referral"
                                                            )}
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <img
                                                        className="mb-1"
                                                        src={RightArrow}
                                                        alt=""
                                                        width={10}
                                                    />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                            <div className="px-0">
                                <div className="px-2 mt-3">
                                    <div className="card mx-auto shadow">
                                        <div className="card-body">
                                            <Link to="/friends/tree">
                                                <div className="row">
                                                    <div className="col-6 justify-content-center align-self-center">
                                                        <h5 className="card-text text-uppercase profile-main-label">
                                                            <img
                                                                src={FriendImg}
                                                                alt=""
                                                                width={15}
                                                            />
                                                            <span className="pl-2">
                                                                {t(
                                                                    "profile.my_friend"
                                                                )}
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <img
                                                            className="mb-1"
                                                            src={RightArrow}
                                                            alt=""
                                                            width={10}
                                                        />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*<div className="px-0">*/}
                            {/*<div className="px-2 mt-3">*/}
                            {/*<div className="card mx-auto shadow">*/}
                            {/*<div className="card-body">*/}
                            {/*<Link to="/friends/group">*/}
                            {/*<div className="row">*/}
                            {/*<div className="col-6 justify-content-center align-self-center">*/}
                            {/*<h5 className="card-text text-uppercase profile-main-label">*/}
                            {/*<img*/}
                            {/*src={GroupImg}*/}
                            {/*alt=""*/}
                            {/*width={15}*/}
                            {/*/>*/}
                            {/*<span className="pl-2">*/}
                            {/*{t(*/}
                            {/*"profile.my_group"*/}
                            {/*)}*/}
                            {/*</span>*/}
                            {/*</h5>*/}
                            {/*</div>*/}
                            {/*<div className="col-6 text-right">*/}
                            {/*<img*/}
                            {/*className="mb-1"*/}
                            {/*src={RightArrow}*/}
                            {/*alt=""*/}
                            {/*width={10}*/}
                            {/*/>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</Link>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}

                            <div className="px-0">
                                <div className="px-2 mt-3">
                                    <div className="card mx-auto shadow">
                                        <div className="card-body">
                                            <Link to="/rewards">
                                                <div className="row">
                                                    <div className="col-6 justify-content-center align-self-center">
                                                        <h5 className="card-text text-uppercase profile-main-label">
                                                            <img
                                                                src={RewardImg}
                                                                alt=""
                                                                width={15}
                                                            />
                                                            <span className="pl-2">
                                                                {t(
                                                                    "profile.reward"
                                                                )}
                                                            </span>
                                                        </h5>
                                                    </div>

                                                    <div className="col-6 text-right">
                                                        <img
                                                            className="mb-1"
                                                            src={RightArrow}
                                                            alt=""
                                                            width={10}
                                                        />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*<div className="px-0">*/}
                            {/*    <div className="px-4 mt-3">*/}
                            {/*        <div className="card mx-auto shadow">*/}
                            {/*            <div className="card-body">*/}
                            {/*                <Link to="/emall/my-orders">*/}
                            {/*                    <div className="row">*/}
                            {/*                        <div className="col-6 justify-content-center align-self-center">*/}
                            {/*                            <h5 className="card-text text-uppercase profile-main-label"><img src={OrderImg} alt="" width={15}/><span className="pl-2">{t('profile.order_details')}</span></h5>*/}
                            {/*                        </div>*/}

                            {/*                        <div className="col-6 text-right">*/}
                            {/*                            <img className="mb-1" src={RightArrow} alt="" width={10}/>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="px-0">*/}
                            {/*<div className="px-4 mt-3">*/}
                            {/*<div className="card mx-auto shadow">*/}
                            {/*<div className="card-body">*/}
                            {/*<Link to="/address/list">*/}
                            {/*<div className="row">*/}
                            {/*<div className="col-6 justify-content-center align-self-center">*/}
                            {/*<h5 className="card-text text-uppercase profile-main-label"><img src={DeliveryImg} alt="" width={15}/><span className="pl-2">{t('profile.billing_addr')}</span></h5>*/}
                            {/*</div>*/}

                            {/*<div className="col-6 text-right">*/}
                            {/*<img className="mb-1" src={RightArrow} alt="" width={10}/>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</Link>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}

                            <div className="px-0">
                                <div className="px-2 mt-3">
                                    <div className="card mx-auto shadow">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-6 justify-content-center align-self-center">
                                                    <h5 className="card-text text-uppercase profile-main-label">
                                                        <img
                                                            src={PasswordImg}
                                                            alt=""
                                                            width={15}
                                                        />
                                                        <span className="pl-2">
                                                            {t(
                                                                "profile.password"
                                                            )}
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="col-6 text-right">
                                                    {/*<span className="span-password-asterisk">******</span>*/}
                                                    <small>
                                                        <Link to="/password/update">
                                                            {t(
                                                                "profile.change"
                                                            )}
                                                        </Link>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-0">
                                <div className="px-2 mt-3">
                                    <div className="card mx-auto shadow">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-7 justify-content-center align-self-center">
                                                    <h5 className="card-text text-uppercase profile-main-label">
                                                        <img
                                                            src={PasswordImg}
                                                            alt=""
                                                            width={15}
                                                        />
                                                        <span className="pl-2">
                                                            {t(
                                                                "profile.secondary_password"
                                                            )}
                                                        </span>
                                                    </h5>
                                                </div>

                                                <div className="col-5 text-right">
                                                    {/*<span className="span-password-asterisk">******</span>*/}
                                                    <small>
                                                        <Link to="/secondaryPassword/update">
                                                            {t(
                                                                "profile.change"
                                                            )}
                                                        </Link>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-0">
                                <div className="px-2 mt-3">
                                    <div className="card mx-auto shadow">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8 justify-content-center align-self-center">
                                                    <h5 className="card-text text-uppercase profile-main-label">
                                                        <img
                                                            src={TwoFAImg}
                                                            alt=""
                                                            width={15}
                                                        />
                                                        <span className="pl-2">
                                                            {t("profile.2fa")}
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="col-4 text-right">
                                                    {this.state.twoFAStatus ===
                                                        "0" && (
                                                        <Link to="/2FA">
                                                            {" "}
                                                            {t(
                                                                "profile.enable"
                                                            )}
                                                        </Link>
                                                    )}
                                                    {this.state.twoFAStatus ===
                                                        "1" && (
                                                        <Link
                                                            to="/Disable2FA"
                                                            className="text-danger"
                                                        >
                                                            {t(
                                                                "profile.disable"
                                                            )}
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-0">
                                <div className="px-2 mt-3">
                                    <div className="card mx-auto shadow">
                                        <div className="card-body">
                                            <Link
                                                to={{
                                                    pathname:
                                                        "/language/change",
                                                    state: {
                                                        options: this.state
                                                            .options,
                                                    },
                                                }}
                                            >
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h5 className="card-text text-uppercase profile-main-label">
                                                            <img
                                                                src={LangImg}
                                                                alt=""
                                                                width={15}
                                                            />
                                                            <span className="pl-2">
                                                                {t(
                                                                    "profile.language"
                                                                )}
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <img
                                                            className="mb-1"
                                                            src={RightArrow}
                                                            alt=""
                                                            width={10}
                                                        />
                                                    </div>
                                                    {/*<small className="col-6 px-0 text-right language-options">*/}
                                                    {/*    <Select*/}
                                                    {/*        id="language"*/}
                                                    {/*        name="language"*/}
                                                    {/*        className="lang-color "*/}
                                                    {/*        options={this.state.options}*/}
                                                    {/*        value={languageSelected}*/}
                                                    {/*        onChange={this.handleLanguage}*/}
                                                    {/*        data*/}
                                                    {/*    />*/}
                                                    {/*</small>*/}
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*<div className="px-0">*/}
                            {/*    <div className="px-2 mt-3">*/}
                            {/*        <div className="card mx-auto shadow">*/}
                            {/*            <div className="card-body">*/}
                            {/*                <div className="row">*/}
                            {/*                    <div className="col-6">*/}
                            {/*                        <h5 className="card-text text-uppercase profile-main-label"><img src={PlacementImg} alt="" width={15}/><span className="pl-2">{t('profile.auto_placement')}</span></h5>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="col-6 px-0 text-right">*/}
                            {/*                        <Switch checked={placementSetting} onChange={this.handleAutoPlacement} />*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="px-0">*/}
                            {/*    <div className="px-2 mt-3">*/}
                            {/*        <div className="card mx-auto shadow">*/}
                            {/*            <div className="card-body">*/}
                            {/*                <Link to="/placement">*/}
                            {/*                    <div className="row">*/}
                            {/*                        <div className="col-6 justify-content-center align-self-center">*/}
                            {/*                            <h5 className="card-text text-uppercase profile-main-label">*/}
                            {/*                                <img*/}
                            {/*                                    src={PlacementImg}*/}
                            {/*                                    alt=""*/}
                            {/*                                    width={15}*/}
                            {/*                                />*/}
                            {/*                                <span className="pl-2">*/}
                            {/*                                    {t(*/}
                            {/*                                        "profile.auto_placement"*/}
                            {/*                                    )}*/}
                            {/*                                </span>*/}
                            {/*                                &nbsp;*/}
                            {/*                                {placementCount > 0 && (*/}
                            {/*                                    <span className="badge badge-pill badge-danger">*/}
                            {/*                                        {placementCount}*/}
                            {/*                                    </span>*/}
                            {/*                                )}*/}
                            {/*                            </h5>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="col-6 text-right">*/}
                            {/*                            <img*/}
                            {/*                                className="mb-1"*/}
                            {/*                                src={RightArrow}*/}
                            {/*                                alt=""*/}
                            {/*                                width={10}*/}
                            {/*                            />*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*{placementSetting && (*/}
                            {/*    <div className="col-12">*/}
                            {/*        <p className="subscribe-note">*/}
                            {/*            {t("profile.placement_notes")}*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            {/*<div className="px-0">*/}
                            {/*<div className="px-4 mt-3">*/}
                            {/*<div className="card mx-auto">*/}
                            {/*<div className="card-body">*/}
                            {/*<div className="row">*/}
                            {/*<div className="col-6">*/}
                            {/*<h5 className="card-text text-uppercase profile-main-label">{t('profile.bonus_payout')}</h5>*/}
                            {/*</div>*/}
                            {/*<div className="col-6 text-right">*/}
                            {/*<label className="switch">*/}
                            {/*<input type="checkbox"*/}
                            {/*checked={this.state.isChecked}*/}
                            {/*onChange={this.handleSubmitBonusPayoutStatus}*/}
                            {/*/>*/}
                            {/*<span className="slider round"></span>*/}
                            {/*</label>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}

                            <div className="px-2 mt-3">
                                <button
                                    className="col-12 btn btn-danger text-uppercase"
                                    onClick={this.handleLogout}
                                >
                                    {t("profile.logout")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <TabFooter
                    accountActive="active"
                    lang={sessionStorage.getItem("lang")}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        profile,
        twoFactorAuthentication,
        modal,
        isAuthenticated,
        walletBalance,
    } = state;

    return {
        profile_data: profile.profileData,
        update_lang_data: profile.updateLangData,
        placement_data: profile.placementData,
        logout_data: profile.logoutData,
        twoFactorAuthData: twoFactorAuthentication.twoFactorAuthData,
        postTwoFactorAuthData: twoFactorAuthentication.postTwoFactorAuthData,
        bonusPayoutStatusData: profile.bonusPayoutStatusData,
        classIsShowing: modal.classIsShowing,
        isAuthenticated: isAuthenticated.data,
        isLoading: walletBalance.isLoading,
    };
};

const mapDispatchToProps = {
    getProfile,
    postUpdateLang,
    postLogout,
    postTwoFactorAuthentication,
    postChangeBonusPayoutStatus,
    postIsAuthenticated,
    postAutoPlacement,
    openModal,
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
