import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
    redeem_data: [],
};

const marketOrderReducer = createReducer(initialState)({
    [type.GET_MARKET_LIST]: (state, action) => ({
        ...state,
        market_data: action.payload,
    }),

    [type.GET_ORDER_LIST]: (state, action) => ({
        ...state,
        order_data: action.payload,
    }),
    [type.GET_MEMBER_OPEN_ORDER]: (state, action) => ({
        ...state,
        mem_open_order: action.payload,
    }),
    [type.GET_MEMBER_TRADE_HISTORY]: (state, action) => ({
        ...state,
        mem_trade_history: action.payload,
    }),
    [type.GET_BUY_ORDER_BOOK]: (state, action) => ({
        ...state,
        buy_order_book: action.payload,
    }),
    [type.GET_SELL_ORDER_BOOK]: (state, action) => ({
        ...state,
        sell_order_book: action.payload,
    }),
    [type.POST_EXCHANGE]: (state, action) => ({
        ...state,
        post_exchange_result: action.payload,
    }),
    [type.POST_MEMBER_CANCEL_ORDER]: (state, action) => ({
        ...state,
        post_exchange_result: action.payload,
    }),
    [type.GET_MEMBER_ORDER_HISTORY]: (state, action) => ({
        ...state,
        order_history_data: action.payload,
    }),
    [type.GET_MEMBER_ORDER_DETAIL]: (state, action) => ({
        ...state,
        member_order_detail: action.payload,
    }),
    [type.GET_ORDER_MATCH_LIST]: (state, action) => ({
        ...state,
        order_match_list: action.payload,
    }),

    [type.GET_TRADING_VIEW]: (state, action) => ({
        ...state,
        trading_view: action.payload,
    }),
});

export default marketOrderReducer;
