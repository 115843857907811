export const GET_MARKET_LIST = "GET_MARKET_LIST";
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const GET_MEMBER_OPEN_ORDER = "GET_MEMBER_OPEN_ORDER";
export const GET_MEMBER_TRADE_HISTORY = "GET_MEMBER_TRADE_HISTORY";
export const GET_BUY_ORDER_BOOK = "GET_BUY_ORDER_BOOK";
export const GET_SELL_ORDER_BOOK = "GET_SELL_ORDER_BOOK";
export const POST_EXCHANGE = "POST_EXCHANGE";
export const GET_MEMBER_ORDER_HISTORY = "GET_MEMBER_ORDER_HISTORY";
export const POST_MEMBER_CANCEL_ORDER = "POST_MEMBER_CANCEL_ORDER";
export const GET_MEMBER_ORDER_DETAIL = "GET_MEMBER_ORDER_DETAIL";
export const GET_ORDER_MATCH_LIST = "GET_ORDER_MATCH_LIST";
export const GET_TRADING_VIEW = "GET_TRADING_VIEW";
