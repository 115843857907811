import React, { Component } from "react";
import { connect } from "react-redux";
import { t } from "react-i18nify";
import { message } from "antd";
import copy from "copy-to-clipboard";
import { openModal, closeModal } from "../../../store/ducks/modal/actions";
import {
    postShareContract,
    postShareMethod,
    postSharePayment,
} from "../../../store/ducks/share/actions";
import LoadingMask from "../../../components/loadingMask";
import Modal from "../../../components/modal";
import ErrorIcon from "../../../components/ErrorAlert";
import SuccessIcon from "../../../components/SuccessAlert";
import NumberInput from "../../../components/input/NumberInput";
import PageHeader from "../../../components/PageHeaderV2";
import ConfirmationIcon from "../../../components/ConfirmationAlert";
import {
    toFixedTrunc,
    delimiter,
    slice_decimal_wt_rounding,
} from "../../../common/helpers";
import equalIcon from "../../../public/icon-equal.png";

import "./auc_share.scss";

class aucShare extends Component {
    constructor(props) {
        super(props);

        let auctionFirstBadge = sessionStorage.getItem("auctionFirstBadge");
        this.myRef = React.createRef();
        this.state = {
            username: sessionStorage.getItem("email"),
            token: sessionStorage.getItem("token"),
            lang: sessionStorage.getItem("lang"),
            data: [],
            price_data: [],
            qr_path: "",
            setOpen: false,
            open: false,
            address: "",
            walletType:
                props.location.state !== undefined
                    ? props.location.state.walletType
                    : auctionFirstBadge,
            displayWalletType:
                props.location.state !== undefined
                    ? props.location.state.walletType
                    : auctionFirstBadge,
            cryptoAddress: "",
            showErrorModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            showLoadingModal: false,
            minAmount: 0,
            maxAmount: "",
            errMsg: "",
            errType: "",
            successMsg: "",
            s_password: "",
            estimated_amount: 0,
            available_amount: "0",
            amount_usdt: 0,
            wallet_balance_data: [],
            display: "not_display",
            auctionSetting: [],
            contract_data: { quota_limit: 1 },
            method_data: [],
            payment_usdt: 0,
            payment_pac: 0,
            payment_liga: 0,
            payment_data: [],
            payment_list: [],
        };

        const data = {};
        data.doc_no =
            this.props.location.state !== undefined
                ? this.props.location.state.doc_no
                : "";

        this.props.postShareContract(data);

        const m_data = {};
        m_data.email = sessionStorage.getItem("email");

        this.props.postShareMethod(m_data);

        this.handleTooltipClose = this.handleTooltipClose.bind(this);
        this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.onChangePayment = this.onChangePayment.bind(this);
        this.onChangeUsdt = this.onChangeUsdt.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleConfirmClose = this.handleConfirmClose.bind(this);
    }
    handleBack() {
        this.props.history.goBack();
    }

    UNSAFE_componentWillMount() {
        if (this.props.location.state === undefined) {
            message.error("");
            this.props.history.push("/home");
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "loading-modal") {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }
            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
            if (
                nextProps.classIsShowing.modalName ===
                "auction-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.contract_data !== this.props.contract_data) {
            if (nextProps.contract_data.rst === "1") {
                let array;
                array = nextProps.contract_data.data;

                this.setState({
                    contract_data: array,
                });
            }
        }

        if (nextProps.method_data !== this.props.method_data) {
            if (nextProps.method_data.rst === "1") {
                let array;
                array = nextProps.method_data.data;

                this.setState({
                    method_data: array,
                });
            }
        }

        if (nextProps.payment_data !== this.props.payment_data) {
            if (nextProps.payment_data.rst === "1") {
                let array;
                array = nextProps.payment_data.data;

                this.setState({
                    payment_data: array,
                    showConfirmationModal: false,
                });
            } else {
                this.setState({
                    errMsg: nextProps.payment_data.msg,
                    showErrorModal: true,
                    showConfirmationModal: false,
                });
            }
        }
    }

    handleConfirm() {
        this.setState({
            showConfirmationModal: true,
        });
    }

    handleConfirmClose() {
        this.setState({
            showConfirmationModal: false,
        });
    }

    handleSubmit() {
        let total_amount = 0;
        let paymentInfo = [];
        let method_data = this.state.payment_list;

        for (var key in method_data) {
            paymentInfo.push({ currency_code: key, amount: method_data[key] });
            total_amount += method_data[key];
        }

        this.setState({
            showConfirmationModal: false,
        });

        if (total_amount > this.state.amount_usdt) {
            this.setState({
                errMsg:
                    t("validator.amount_should_less_than") +
                    " " +
                    this.state.amount_usdt,
                showErrorModal: true,
            });
            return;
        }

        if (total_amount < this.state.amount_usdt) {
            this.setState({
                errMsg: t("stakingProgram.invalid_amount"),
                showErrorModal: true,
            });
            return;
        }

        const data = {};

        data.doc_no = this.state.contract_data.doc_no;
        data.amount = total_amount;
        data.payments = JSON.stringify(paymentInfo);

        this.props.postSharePayment(data);
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/home");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleTooltipClose = () => {
        this.setState({ open: false });
    };

    handleTooltipOpen = () => {
        copy(this.state.address);

        message.success(t("copied"));
    };

    onChange(event) {
        const { name, value } = event.target;
        event.target.value = event.target.value * 1;
        if (value !== "") {
            this.setState({
                [name]: event.target.value,
                payment_usdt: event.target.value * 0.5,
            });
        } else {
            this.setState({
                [name]: 0,
                payment_usdt: 0,
            });
        }
    }

    onChangePayment(event, wallet_type) {
        const { name, value } = event.target;
        event.target.value = event.target.value * 1;

        let payments = this.state.payment_list;

        payments[wallet_type] = event.target.value;

        this.setState({ payment_list: payments });
        // if (name == "payment_PAC") {
        //     this.setState({
        //         payment_pac: event.target.value
        //     });
        // } else {
        //     this.setState({
        //         payment_liga: event.target.value
        //     });
        // }
    }

    onChangeUsdt(event) {
        event.target.value = event.target.value * 1;

        this.setState({
            payment_usdt: event.target.value,
        });
    }

    render() {
        const {
            showErrorModal,
            errMsg,
            payment_list,
            amount_usdt,
            showLoadingModal,
            wallet_balance_data,
            available_amount,
            showConfirmationModal,
            successMsg,
            showSuccessModal,
            minAmount,
            walletType,
            contract_data,
            method_data,
            payment_usdt,
            payment_liga,
            payment_pac,
        } = this.state;
        const Icon = () => (
            <img
                src={equalIcon}
                alt="Girl in a jacket"
                width="18"
                height="18"
            />
        );

        let method_list;

        let onChangePayment = (e, wallet_type) => {
            this.onChangePayment(e, wallet_type);
        };

        let onChangeUsdt = (e) => {
            this.onChangeUsdt(e);
        };
        method_list = method_data.map(function (item, i) {
            if (item.currency_code == "USDT") {
                return (
                    <div className="p-2 offset-md-2 col-md-8">
                        <small className="auc-balance">
                            {t("share.available_balance_USDT")}:{" "}
                            {toFixedTrunc(item.current_balance, 2)}
                        </small>
                        <div className="text-right text-roboto-bold">
                            <NumberInput
                                id="amount_usdt"
                                name="payment_usdt"
                                className="usdt remove-placeholder depth-input bg-transparent"
                                value={payment_usdt}
                                min={delimiter(
                                    amount_usdt * item.min_payment_percent
                                )}
                                max={delimiter(amount_usdt)}
                                placeholder={t("auction.amount") + walletType}
                                onChange={onChangeUsdt}
                                requiredMsg={t("validator.requiredMsg")}
                                textLessThan={t(
                                    "validator.amount_should_less_than"
                                )}
                                textMoreThan={t(
                                    "validator.amount_should_more_than"
                                )}
                                required
                            />
                        </div>
                    </div>
                );
            }
            console.log(payment_list[item.currency_code]);
            return (
                <div className="p-2 offset-md-2 col-md-8">
                    <small className="auc-balance">
                        {t(`share.available_balance_${item.currency_code}`)}:{" "}
                        {toFixedTrunc(item.current_balance, 8)}
                    </small>
                    <div className="row">
                        <div className="col-5 text-right text-roboto-bold pr-0">
                            <NumberInput
                                name={`payment_${item.currency_code}`}
                                className="left remove-placeholder depth-input border-right-0 bg-transparent"
                                value={
                                    payment_list[item.currency_code] ===
                                    undefined
                                        ? 0
                                        : payment_list[item.currency_code]
                                }
                                // min={delimiter(minAmount)}
                                max={delimiter(amount_usdt)}
                                placeholder={t("auction.amount") + walletType}
                                onChange={(e) =>
                                    onChangePayment(e, item.currency_code)
                                }
                                requiredMsg={t("validator.requiredMsg")}
                                textLessThan={t(
                                    "validator.amount_should_less_than"
                                )}
                                textMoreThan={t(
                                    "validator.amount_should_more_than"
                                )}
                                required
                            />
                        </div>
                        <div className="col-2 text-right text-roboto-bold pl-0 pr-0">
                            <NumberInput
                                className="middle depth-input border-right-0 border-left-0 rounded-0 bg-transparent"
                                placeholder={Icon}
                                disabled
                            />
                        </div>
                        <div className="col-5 text-right text-roboto-bold pl-0">
                            <NumberInput
                                className={`right-${item.currency_code} remove-placeholder depth-input border-left-0 bg-transparent`}
                                value={
                                    item.currency_code == "PAC"
                                        ? slice_decimal_wt_rounding(
                                              payment_pac / item.rate,
                                              8
                                          )
                                        : slice_decimal_wt_rounding(
                                              payment_liga / item.rate,
                                              8
                                          )
                                }
                                // min={delimiter(minAmount)}
                                // max={delimiter(this.state.available_amount)}
                                placeholder={t("auction.amount") + walletType}
                                disabled
                            />
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div className="auction">
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="btn depth-red-btn col-12"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}
                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("transfer.confirmation_msg")}</h4>
                                </div>
                            </div>
                        }
                        closeText={t("purchasePin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={this.handleConfirmClose}
                        closeId="share"
                        submitText={t("common.submit")}
                        submitClassName="btn depth-blue-btn"
                        submitFunc={this.handleSubmit}
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="auction-confirmation-modal"
                    />
                )}
                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="col-12 btn depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div className="container-fluid px-0">
                    <PageHeader
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        // historyBack="/home"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        availableAmount={wallet_balance_data.available_amount}
                        goBackFunc={this.handleBack}
                        title={t("share.auc_for_share")}
                        arrowLeft={true}
                    />
                </div>
                <div className="p-2 offset-md-2 col-md-8">
                    <div className="row">
                        <div className="price col-12 text-roboto text-white">
                            {contract_data.doc_no}
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-left">
                                {t("share.quota")}
                            </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-right">
                                {contract_data.quota_limit}{" "}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-left">
                                {t("share.price")}
                            </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-right">
                                {contract_data.price}{" "}
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="gcox p-2 offset-md-2 col-md-8">
                    <div className="auc-payment text-left">
                        {t("share.purchase")}
                    </div>
                </div>

                <div className="p-2 offset-md-2 col-md-8">
                    <div className="text-right text-roboto-bold">
                        <NumberInput
                            name="amount_usdt"
                            className="usd remove-placeholder depth-input bg-transparent"
                            value={amount_usdt}
                            // min={delimiter(minAmount)}
                            max={delimiter(contract_data.quota_limit)}
                            // placeholder={t('auction.amount') + walletType}
                            onChange={this.onChange}
                            requiredMsg={t("validator.requiredMsg")}
                            textLessThan={t(
                                "validator.amount_should_less_than"
                            )}
                            textMoreThan={t(
                                "validator.amount_should_more_than"
                            )}
                            required
                        />
                    </div>
                </div>

                <div className="gcox p-2 offset-md-2 col-md-8">
                    <div className="auc-payment text-left">
                        {t("share.payment")}
                    </div>
                </div>

                <div className="gcox p-2 offset-md-2 col-md-8">
                    <span className="gcox-title text-white text-left">
                        {t("share.auc_reminder")}
                    </span>
                </div>

                {method_list}

                <div className="p-2 offset-md-2 col-md-8">
                    <span>
                        <small className="text-red">
                            {t("share.reminder")}
                        </small>
                    </span>
                    <div className="row mt-2">
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-left">
                                {t("share.grand_total")}
                            </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
                            <div className="col-12 text-roboto text-white text-right">
                                {toFixedTrunc(amount_usdt, 2)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-2 offset-md-2 col-md-8">
                    <button
                        type="submit"
                        onClick={this.handleConfirm}
                        className="col-12 depth-blue-btn"
                    >
                        {t("auction.submit")}
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { modal, walletBalance, share } = state;

    return {
        classIsShowing: modal.classIsShowing,
        wallet_balance_data: walletBalance.lite_data,
        contract_data: share.contract_data,
        method_data: share.method_data,
        payment_data: share.payment_data,
    };
};

const mapDispatchToProps = {
    closeModal,
    openModal,
    postShareContract,
    postShareMethod,
    postSharePayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(aucShare);
