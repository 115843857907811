import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: []
};

const eMallConvertReducer = createReducer(initialState) ({

    [type.POST_EMALL_CONVERT]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),
});

export default eMallConvertReducer;