import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from '../input/Select/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import RightArrow from "../../public/images/arrow-white@3x.png";
import emallLogo from "../../public/images/header/logo-celebbazaar.png";
import InfoImg from "../../public/icon-info@3x.png";
import StarImg from "../../public/brightstar.svg";

import './PageHeader.scss';


class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {
            title,
            titleClass,
            subTitle,
            subTitleClass,
            backgroundClass,
            backgroundHeight,
            language_id,
            language_name,
            language_class,
            onLanguageChange,
            options,
            /*subPageHistoryBack,*/
            historyBack,
            historyBackText,
            historyBackClass,
            historyBackLinkClass,
            titleHeader,
            avatar,
            avatarImg,
            /*avatarImgClass,*/
            avatarLevel,
            avatarStar,
            avatarDivClass,
            /*ranking,*/
            walletType,
            textBelowBalance,
            textBelowBalance2,
            extraInfo,
            balance,
            walletLabel,
            arrowLeft,
            arrowRight,
            isLogoHeader,
            historyGoBack/*,
            selectedCommMember*/
        } = this.props;

        const stylebackgroundHeight = {
            height: backgroundHeight
        };

        return (
            <div
                className={`pageHeader background-header pb-5 ${backgroundClass !== undefined ? backgroundClass : ''}`}
                style={stylebackgroundHeight}
            >
                {(historyBack && arrowRight) && (
                    <div>
                        <div className={historyBackClass}>
                            <Link to={historyBack}>
                        <span className={historyBackLinkClass}>
                          {' '}
                            {historyBackText} <img className="fa-pull-right mt-1" alt={1} src={RightArrow} width={10}/>
                        </span>
                            </Link>
                        </div>
                        <div className="row col-12 pt-4 mx-0 text-light">
                            {title &&
                            <h4 className={`${titleHeader} header-white-color`}>{title}</h4>
                            }
                        </div>
                    </div>
                )}

                {(historyBack && arrowLeft) &&
                <div className="container-fluid">
                    <nav className="navbar text-light">
                        <div className={`${historyBackClass}`}>
                            <Link to={historyBack} onClick={ this.props.history.goBack()}>
                                <span className={historyBackLinkClass}> {historyBackText} <FontAwesomeIcon icon={faArrowLeft}/></span>
                            </Link>
                        </div>
                        <div className="row col-12">
                            {title &&
                            <div className="col-12 pt-2 pr-5">
                                <div className="header text-uppercase">
                                    <h4 className={`${titleHeader} title white-color`}>{title}</h4>
                                </div>
                            </div>
                            }
                        </div>
                    </nav>
                </div>
                }

                {options && (
                    <div className="text-right">
                        <Select
                            id={language_id}
                            name={language_name}
                            className={language_class}
                            options={options}
                            onChange={onLanguageChange}
                        />
                    </div>
                )}

                {(title && !historyBack && isLogoHeader) && (
                    <div className={`${titleClass} header`}>
                        <img src={emallLogo} height={120} alt=""/>
                    </div>
                )}

                {(title && !historyBack && !isLogoHeader) && (
                    <div className={`${titleClass} header`}>
                        <h4 className={`${titleHeader} title white-color text-uppercase`}>{title}</h4>
                    </div>
                )}

                {subTitle && (
                    <div>
                        <div className={`fa-pull-left mt-4 ml-3 sub-title-div`}>
                            {(historyGoBack === undefined) &&
                            <Link onClick={this.props.goBackFunc}>
                                <FontAwesomeIcon icon={faArrowLeft}/>
                            </Link>
                            }

                            {(historyGoBack !== undefined) &&
                            <Link to={{pathname: '#', state: {walletType: walletType}}} onClick={this.props.historyGoBack}>
                                <FontAwesomeIcon icon={faArrowLeft}/>
                            </Link>
                            }

                        </div>
                        <div className={`${subTitleClass} text-left pt-4 pl-3`}>
                            <div className="container header">
                                <h4 className="col-12 text-center title white-color text-uppercase">{subTitle}</h4>
                            </div>
                        </div>
                    </div>
                )}

                {avatarImg && (
                    <div style={{ zIndex: 100 }}>
                        {avatar}
                    </div>
                )}

                {walletType &&
                <div className={`${avatarDivClass} pt-5`}>
                    <h3 className={avatarLevel}>{walletType} {balance}</h3>

                    <div className="row col-12 px-0 mx-0">
                        {textBelowBalance2 &&
                        <div className={`${walletLabel} mx-auto py-2 col-12`}>
                            <small className="text-light text-uppercase">{textBelowBalance2}</small>&nbsp;
                        </div>
                        }

                        {textBelowBalance &&
                        <div className={`${walletLabel} mx-auto py-2 col-12`}>
                            <small className="text-light text-uppercase">{textBelowBalance}</small>&nbsp;
                            {extraInfo === true &&
                            <Link to={{ pathname: '/staking/history', state: { walletType: walletType } }}>
                                <img src={InfoImg} width={20} alt=""/>
                            </Link>
                            }
                        </div>
                        }
                    </div>

                </div>
                }

                {avatarStar &&
                <div className="col-12 row mx-auto">
                    <div className={avatarStar}>
                        <img src={StarImg} width={10} alt={1} className="mb-1"/>
                        <img src={StarImg} width={10} alt={1} className="mb-1"/>
                        <img src={StarImg} width={10} alt={1} className="mb-1"/>
                        <img src={StarImg} width={10} alt={1} className="mb-1"/>
                        <img src={StarImg} width={10} alt={1} className="mb-1"/>
                    </div>
                </div>
                }

            </div>
        );
    }
}

export default PageHeader;

PageHeader.propTypes = {
    backgroundClass: PropTypes.string,
    title: PropTypes.string,
    titleClass: PropTypes.string,
    titleHeader: PropTypes.string,
    subTitle: PropTypes.string,
    subTitleClass: PropTypes.string,
    backgroundHeight: PropTypes.number,
    options: PropTypes.string,
    onLanguageChange: PropTypes.func,
    language_id: PropTypes.string,
    language_name: PropTypes.string,
    language_class: PropTypes.string,
    subPageHistoryBack: PropTypes.string,
    historyBack: PropTypes.string,
    historyBackText: PropTypes.string,
    historyBackClass: PropTypes.string,
    historyBackLinkClass: PropTypes.string,
    avatarImg: PropTypes.string,
    avatarImgClass: PropTypes.string,
    avatarLevel: PropTypes.string,
    avatarStar: PropTypes.string,
    avatarDivClass: PropTypes.string,
    walletType: PropTypes.string,
    textBelowBalance: PropTypes.string,
    textBelowBalance2: PropTypes.string,
    balance: PropTypes.string,
    walletLabel: PropTypes.string,
    arrowLeft: PropTypes.bool,
    arrowRight: PropTypes.bool,
    extraInfo: PropTypes.string,
    isLogoHeader: PropTypes.bool,
    historyGoBack: PropTypes.func,
    selectedCommMember: PropTypes.any
};
