import * as type from "./types";
import axios from "axios";

/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Aun
 */

export const postExchangeConvertRate = (data) => (dispatch) => {
    axios
        .post(
            process.env.REACT_APP_GM_URL + "/check/convert",
            {
                market_from: data["market_from"],
                market_to: data["market_to"],
                amount_from: data["amount_from"],
                amount_to: data["amount_to"],
                type: data["type"],
            },
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.POST_EXCHANGE_CONVERT_PRICE,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.POST_EXCHANGE_CONVERT_PRICE,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const postExchangeConvert = (data) => (dispatch) => {
    axios
        .post(process.env.REACT_APP_GM_URL + "/member/exchange/add", data, {
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.POST_EXCHANGE_CONVERT,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.POST_EXCHANGE_CONVERT,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const postSelectBalance = (data) => (dispatch) => {
    axios
        .post(
            process.env.REACT_APP_GM_URL + "/member/wallet/balance/lite",
            data,
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.POST_SELECT_BALANCE,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.POST_SELECT_BALANCE,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};
