export const POST_MERCHANT_TAG = "POST_MERCHANT_TAG";
export const POST_MERCHANT_PRODUCTS = "POST_MERCHANT_PRODUCTS";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const POST_DELIVERY_ADDRESS = "POST_DELIVERY_ADDRESS";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_DELIVERY_ADDRESS = "GET_DELIVERY_ADDRESS";
export const POST_PRODUCT_CART = "POST_PRODUCT_CART";
export const POST_ORDER_CONFIRM = "POST_ORDER_CONFIRM";
export const UPDATE_DELIVERY_ADDRESS = "UPDATE_DELIVERY_ADDRESS";
export const GET_EDIT_DELIVERY_ADDRESS = "GET_EDIT_DELIVERY_ADDRESS";
export const DELETE_DELIVERY_ADDRESS = "DELETE_DELIVERY_ADDRESS";
export const GET_ORDER_LIST = 'GET_ORDER_LIST';
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const POST_CONFIRM_PAYMENT = 'POST_CONFIRM_PAYMENT';
export const POST_VARIANT_KEY = 'POST_VARIANT_KEY';
