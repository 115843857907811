import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const marketReducer = createReducer(initialState) ({

    [type.GET_MARKET_PRICE]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

});

export default marketReducer;