import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Progress, Tabs, Select } from "antd";
import { t, setLanguageFileJson } from "../../common/translation";
import InfiniteScroll from "react-infinite-scroller";
import "./trading.scss";
import {
    getMarketList,
    // getOrderTransaction,
    getMemberTradeHistory,
    getMemberOpenOrder,
    getMemberOpenHistory,
    postCancelOrder,
} from "../../store/ducks/market/actions";
import StatementOpen from "../../public/v3/icon/statement-open-order.svg";
import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeaderV2";
import FilterLogo from "../../public/v3/icon/filter.svg";
import Modal from "../../components/modal";
import { openModal, closeModal } from "../../store/ducks/modal/actions";
import { DatePicker } from "antd";
import moment from "moment";

import HyperModal from "../../components/HyperModal";

const { Option } = Select;
const InputGroup = Input.Group;
class TradingHistory extends Component {
    constructor(props) {
        super(props);
        let date_now = moment();
        date_now = date_now.subtract(7, "days");
        date_now = date_now.format("YYYY-MM-DD");
        this.state = {
            dateFirstDay: moment().subtract(7, "days"),
            dateNow: moment(),
            showFilterModel: false,
            show_filter: false,
            market_data: [],
            filter: {
                date_from: "",
                date_to: "",
                action_type: "",
                pair: "",
                crypto_code: "",
            },
            totalPageTrading: 0,
            currentPageTrading: 1,
            hasMoreItemsTrading: true,
            mem_trade_history: [],

            currentPageOpenHistory: 1,
            totalPageOrderHistory: 0,
            hasMoreItemsOrderHistory: true,
            order_history_data: [],

            currentPageOpenOrder: 1,
            totalPageOpenOrder: 0,
            hasMoreItemsOpenOrder: true,
            mem_open_order: [],

            // dateFrom: date_now,
            // dateEnd: moment().format("YYYY-MM-DD"),
            dateFrom: "",
            dateEnd: "",
        };

        this.handleClickOnTag = this.handleClickOnTag.bind(this);
        this.handleSecondaryClose = this.handleSecondaryClose.bind(this);
        this.submitFilter = this.submitFilter.bind(this);
        this.onDateFromChange = this.onDateFromChange.bind(this);
        this.onDateEndChange = this.onDateEndChange.bind(this);
        this.openFilterModal = this.openFilterModal.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.cancelExchange = this.cancelExchange.bind(this);
        this.submitCancelOrder = this.submitCancelOrder.bind(this);
        this.handleClickPair = this.handleClickPair.bind(this);
        this.handleClickType = this.handleClickType.bind(this);
        this.loadMoreListTrading = this.loadMoreListTrading.bind(this);
        this.loadMoreListOrderHistory =
            this.loadMoreListOrderHistory.bind(this);
        this.loadMoreListOpenOrder = this.loadMoreListOpenOrder.bind(this);
        this.renderItemOrderHistory = this.renderItemOrderHistory.bind(this);
        this.renderItemTrading = this.renderItemTrading.bind(this);
        this.renderItemOpenOrder = this.renderItemOpenOrder.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `200px`);
    }
    renderItemOpenOrder(item, i) {
        const itemRows = [
            <div className="open-order-item col-12 d-flex py-3" key={i++}>
                {item.action_type == "BUY" ? (
                    <>
                        <div className="order-percentage col-2 px-0">
                            <div className="font-12 text-green">
                                {t("exchange.buy_limit")}
                            </div>
                            <Progress
                                type="circle"
                                percent={
                                    item.matched_rate == 0
                                        ? 0
                                        : parseFloat(item.matched_rate).toFixed(
                                              2
                                          ) * 100
                                }
                                width={47}
                                strokeColor="green"
                                trailColor="#404040"
                                strokeWidth={12}
                                format={() => (
                                    <span className="text-green">
                                        {parseFloat(item.matched_rate).toFixed(
                                            2
                                        ) * 100}
                                        %
                                    </span>
                                )}
                            />
                        </div>
                        <div className="order-detail col-7 px-2">
                            <div className="font-14 text-uppercase text-white pb-2 font-weight-bold">
                                <span>
                                    {/* {item.wallet_code} /{" "}
                                                {item.wallet_code_to} */}
                                    {item.coin_pair}
                                </span>
                            </div>
                            <div className="col-12 d-flex px-0 font-12">
                                <div className="text-dark-grey w-50">
                                    {t("common.price")}
                                </div>
                                <div className="text-white">
                                    {item.unit_price_display}
                                </div>
                            </div>
                            <div className="col-12 d-flex px-0 font-12">
                                <div className="text-dark-grey w-50">
                                    {t("common.quantity")}
                                </div>
                                <div className="text-white">
                                    {parseFloat(
                                        item.matched_quantity_display
                                    ).toString() + " "}
                                    /{" " + item.total_quantity_display}
                                </div>
                            </div>
                        </div>
                        <div className="order-date col-3 px-0 text-right">
                            <div className="text-dark-grey font-12 pb-2">
                                {item.trans_date_time}
                            </div>
                            <div className="">
                                {item.display_cancel_button ? (
                                    <button
                                        onClick={(e) =>
                                            this.cancelExchange(e, item.doc_no)
                                        }
                                        className="btn bg-light-purple text-white font-12 text-uppercase"
                                    >
                                        {t("common.cancel")}
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="order-percentage col-2 px-0">
                            <div className="font-12 text-red">
                                {t("exchange.sell_limit")}
                            </div>
                            <Progress
                                type="circle"
                                percent={
                                    item.matched_rate == 0
                                        ? 0
                                        : parseFloat(item.matched_rate).toFixed(
                                              2
                                          ) * 100
                                }
                                width={47}
                                strokeColor="red"
                                trailColor="#404040"
                                strokeWidth={12}
                                format={() => (
                                    <span className="text-red">
                                        {" "}
                                        {parseFloat(item.matched_rate).toFixed(
                                            2
                                        ) * 100}
                                        %
                                    </span>
                                )}
                            />
                        </div>
                        <div className="order-detail col-7 px-2">
                            <div className="font-14 text-uppercase text-white pb-2 font-weight-bold">
                                <span>{item.coin_pair}</span>
                            </div>
                            <div className="col-12 d-flex px-0 font-12">
                                <div className="text-dark-grey w-50">
                                    {t("common.price")}
                                </div>
                                <div className="text-white">
                                    {item.unit_price_display}
                                </div>
                            </div>
                            <div className="col-12 d-flex px-0 font-12">
                                <div className="text-dark-grey w-50">
                                    {t("common.quantity")}
                                </div>
                                <div className="text-white">
                                    {item.matched_quantity_display} /{" "}
                                    {item.total_quantity_display}
                                </div>
                            </div>
                        </div>
                        <div className="order-date col-3 px-0 text-right">
                            <div className="text-dark-grey font-12 pb-2">
                                {item.trans_date_time}
                            </div>
                            <div className="">
                                {item.display_cancel_button ? (
                                    <button
                                        onClick={(e) =>
                                            this.cancelExchange(e, item.doc_no)
                                        }
                                        className="btn bg-light-purple text-white font-12 text-uppercase"
                                    >
                                        {t("common.cancel")}
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>,
        ];

        return itemRows;
    }

    renderItemTrading(item, i) {
        const itemRows = [
            <div className="open-order-item col-12 py-3" key={item.key}>
                <>
                    <div className="order-detail col-12 d-flex px-2">
                        <div className="font-14 px-0 col-6 text-uppercase text-white pb-2 font-weight-bold">
                            {item.coin_pair}
                        </div>
                        <div className="text-dark-grey px-0 font-12 col-6 pb-2 text-right">
                            {item.trans_date_time}
                        </div>
                    </div>
                    <div className="col-12 px-2 d-flex">
                        <div
                            className="px-0 font-12 col-4"
                            style={{
                                color: item.action_type_color_code,
                            }}
                        >
                            {item.action_type == "BUY"
                                ? t("common.buy")
                                : t("common.sell")}
                        </div>
                    </div>
                    <div className="col-12 px-2 d-flex">
                        <div className="text-dark-grey px-0 font-12 col-4">
                            {t("common.price")}
                        </div>
                        <div className="text-dark-grey px-0 font-12 col-4">
                            {t("common.filled")}
                        </div>
                        <div className="text-dark-grey px-0 font-12 col-4 text-right">
                            {t("common.amount")}
                        </div>
                    </div>
                    <div className="col-12 px-2 d-flex">
                        <div className="text-white px-0 font-14 col-4">
                            {item.unit_price_display}
                        </div>
                        <div className="text-white px-0 font-14 col-4">
                            {item.matched_quantity_display}
                        </div>
                        <div className="text-white px-0 font-14 col-4 text-right">
                            {item.total_quantity_display}
                        </div>
                    </div>
                </>
            </div>,
        ];
        return itemRows;
    }

    renderItemOrderHistory(item, i) {
        const itemRows = [
            <Link
                key={i++}
                to={{
                    pathname: `/order/detail`,
                    state: { item: item },
                }}
            >
                <div className="open-order-item col-12 py-3" key={item.key}>
                    <>
                        <div className="order-detail col-12 d-flex px-2">
                            <div className="font-14 px-0 col-6 text-uppercase text-white pb-2 font-weight-bold">
                                {item.coin_pair}
                            </div>
                            <div className="text-dark-grey px-0 font-12 col-6 pb-2 text-right">
                                {item.trans_date_time}
                            </div>
                        </div>
                        <div className="col-12 px-2 d-flex">
                            <div className="font-14 px-0 col-6 text-uppercase text-white pb-2 font-weight-bold">
                                {item.action_type === "BUY" ? (
                                    <span className="text-green">
                                        {t("common.limit")}
                                    </span>
                                ) : (
                                    <span className="text-red">
                                        {t("common.limit")}
                                    </span>
                                )}
                            </div>
                            <div className="text-dark-grey px-0 font-12 col-6 pb-2 text-right"></div>
                        </div>
                        <div className="col-12 px-2 d-flex">
                            <div className="text-dark-grey px-0 font-12 col-4">
                                {t("common.price")}
                            </div>
                            <div className="text-dark-grey px-0 font-12 col-4">
                                {t("common.filled")}
                            </div>
                            <div className="text-dark-grey px-0 font-12 col-4 text-right">
                                {t("common.quantity")}
                            </div>
                        </div>
                        <div className="col-12 px-2 d-flex">
                            <div className="text-white px-0 font-14 col-4">
                                {item.unit_price_display}
                            </div>
                            <div className="text-white px-0 font-14 col-4">
                                {item.matched_quantity_display}
                            </div>
                            <div className="text-white px-0 font-14 col-4 text-right">
                                {item.total_quantity_display}
                            </div>
                        </div>
                    </>
                </div>
            </Link>,
        ];
        return itemRows;
    }

    loadMoreListOpenOrder() {
        console.log(">>>", this.state);
        let currentPage = parseInt(this.state.currentPageOpenOrder) + 1;

        if (currentPage >= this.state.totalPageOpenOrder) {
            this.setState({
                hasMoreItemsOpenOrder: false,
                loading: false,
            });
        }
        if (currentPage <= this.state.totalPageOpenOrder) {
            let data3 = {};

            data3.page = currentPage;
            data3.action_type = "";
            data3.crypto_code = "";
            this.props.getMemberOpenOrder(data3);
        }
    }

    loadMoreListOrderHistory() {
        let currentPage = parseInt(this.state.currentPageOpenHistory) + 1;
        if (currentPage >= this.state.totalPageOrderHistory) {
            this.setState({
                hasMoreItemsOrderHistory: false,
                loading: false,
            });
        }

        if (currentPage <= this.state.totalPageOrderHistory) {
            console.log("in here");
            let data3 = {};

            data3.page = currentPage;
            data3.date_from = this.state.dateFrom;
            data3.date_to = this.state.dateEnd;
            data3.action_type = this.state.filter.action_type;
            data3.crypto_code = this.state.filter.pair;

            this.props.getMemberOpenHistory(data3);
        }
    }
    loadMoreListTrading() {
        let currentPage = parseInt(this.state.currentPageTrading) + 1;
        if (currentPage >= this.state.totalPageTrading) {
            this.setState({
                hasMoreItemsTrading: false,
                loading: false,
            });
        }

        if (currentPage <= this.state.totalPageTrading) {
            let data3 = {};

            data3.page = currentPage;
            data3.date_from = this.state.dateFrom;
            data3.date_to = this.state.dateEnd;
            data3.action_type = this.state.filter.action_type;
            data3.crypto_code = this.state.filter.pair;

            this.props.getMemberTradeHistory(data3);
        }
    }
    handleClickPair(value) {
        let filter = this.state.filter;
        filter.pair = value;
        this.setState({
            filter: filter,
        });
    }

    handleClickType(value) {
        let filter = this.state.filter;
        filter.action_type = value;
        this.setState({
            filter: filter,
        });
    }
    submitCancelOrder(e, pwd) {
        let element = document.getElementById("secondary_password");
        let isPwInValid = element.classList.contains("is-invalid");

        if (!isPwInValid & (pwd !== "")) {
            let data = {};
            data.secondary_password = pwd;
            data.doc_no = this.state.doc_no;
            this.props.postCancelOrder(data);

            this.setState({
                confirm_visible: false,
                mem_open_order: [],
            });
        }
    }
    cancelExchange(e, doc_no) {
        this.setState({
            confirm_visible: true,
            doc_no: doc_no,
            // is_cancel: true,
        });
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                });
                break;
        }
    }

    openFilterModal() {
        this.setState({
            showFilterModel: true,
        });
    }
    onDateFromChange = (date, dateString) => {
        this.setState({
            dateFrom: dateString,
        });
    };

    onDateEndChange = (date, dateString) => {
        this.setState({
            dateEnd: dateString,
        });
    };

    submitFilter() {
        let data3 = {};
        let data2 = {};
        data3.page = 1;
        data3.date_from = this.state.dateFrom;
        data3.date_to = this.state.dateEnd;
        data3.action_type = this.state.filter.action_type;
        data3.crypto_code = this.state.filter.pair;

        data2.page = 1;
        data2.date_from = this.state.dateFrom;
        data2.date_to = this.state.dateEnd;
        data2.action_type = this.state.filter.action_type;
        data2.crypto_code = this.state.filter.pair;

        this.props.getMemberTradeHistory(data3);
        this.props.getMemberOpenHistory(data2);

        this.setState({
            showFilterModel: false,
            order_history_data: [],
            mem_trade_history: [],
            currentPageOpenHistory: 1,
            totalPageOrderHistory: 0,
            hasMoreItemsOrderHistory: true,
            currentPageOpenOrder: 1,
            totalPageOpenOrder: 0,
            hasMoreItemsOpenOrder: true,
            mem_open_order: [],
        });
    }
    handleClickOnTag(key) {
        let data3 = {};
        var date_now = moment();
        date_now = date_now.subtract(7, "days");
        date_now = date_now.format("YYYY-MM-DD");
        data3.page = 1;
        data3.date_from = date_now;
        data3.date_to = moment().format("YYYY-MM-DD");

        data3.action_type = "";
        data3.crypto_code = "";
        let show_filter = false;
        switch (key) {
            case "1":
                show_filter = false;

                this.props.getMemberOpenOrder(data3);
                break;
            case "2":
                show_filter = true;
                this.props.getMemberOpenHistory(data3);
                break;
            case "3":
                show_filter = true;
                this.props.getMemberTradeHistory(data3);
                break;
        }

        let filter = {
            date_from: "",
            date_to: "",
            action_type: "",
            pair: "",
        };
        this.setState({
            show_filter: show_filter,
            // dateFrom: "",
            // dateEnd: "",
            mem_open_order: [],
            filter: filter,
            order_history_data: [],
            currentPageOpenHistory: 1,
            totalPageOrderHistory: 0,
            hasMoreItemsOrderHistory: true,
            dateFrom: date_now,
            dateEnd: moment().format("YYYY-MM-DD"),
            mem_trade_history: [],
            currentPageTrading: 1,
            totalPageOrderHistory: 0,
            hasMoreItemsTrading: true,
        });
    }

    handleSecondaryClose() {
        this.setState({
            showFilterModel: false,
        });
    }
    componentDidMount() {
        let data = {};
        data.page = 1;
        this.props.getMarketList(data);
        let data3 = {};
        var date_now = moment();
        date_now = date_now.subtract(7, "days");
        date_now = date_now.format("YYYY-MM-DD");
        data3.page = 1;
        data3.date_from = date_now;
        data3.date_to = moment().format("YYYY-MM-DD");
        data3.action_type = "";
        data3.crypto_code = "";
        let memData = {};
        memData.date_from = "";
        memData.date_to = "";
        memData.action_type = "";
        memData.page = 1;
        this.props.getMemberOpenOrder(memData);
        // this.props.getMemberTradeHistory(data3);
        // this.props.getMemberOpenHistory(data3);
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `200px`);
        });

        // setLocale(sessionStorage.getItem("lang"));
    }
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.market_data !== this.props.market_data) {
            if (nextProps.market_data.data.rst == "1") {
                this.setState({
                    market_data: nextProps.market_data.data.data,
                });
            }
        }
        if (
            nextProps.post_exchange_result !== this.props.post_exchange_result
        ) {
            if (nextProps.post_exchange_result.data.rst == "1") {
                this.setState({
                    modal_msg: nextProps.post_exchange_result.data.msg,
                    modal_type: "success",
                    modal_visible: true,
                });

                // this.props.getMemberTradeHistory(data3);
                // this.props.getMemberOpenHistory(data3);
            } else {
                this.setState({
                    modal_msg: nextProps.post_exchange_result.data.msg,
                    modal_type: "error",
                    modal_visible: true,
                });
            }
            let data3 = {};
            data3.page = 1;
            data3.date_from = "";
            data3.date_to = "";
            data3.action_type = "";
            data3.crypto_code = "";
            this.props.getMemberOpenOrder(data3);
        }
        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }

        if (nextProps.order_history_data !== this.props.order_history_data) {
            if (nextProps.order_history_data.data.rst == "1") {
                var i = 0;
                let array;

                array = this.state.order_history_data;

                nextProps.order_history_data.data.data.current_page_items.map(
                    function (item, i) {
                        array.push(item);

                        return array;
                    }
                );

                if (
                    this.state.currentPage ===
                    nextProps.order_history_data.data.data.total_page
                ) {
                    this.setState({
                        hasMoreItemsOrderHistory: false,
                    });
                }

                if (nextProps.order_history_data.data.data.total_page === "0") {
                    this.setState({
                        hasMoreItemsOrderHistory: false,
                    });
                }

                this.setState({
                    totalPageOrderHistory:
                        nextProps.order_history_data.data.data.total_page,
                });
                this.setState({
                    currentPageOpenHistory:
                        nextProps.order_history_data.data.data.current_page,
                });

                this.setState({
                    order_history_data: array,
                });
            }
        }
        if (nextProps.mem_open_order !== this.props.mem_open_order) {
            if (nextProps.mem_open_order.data.rst == "1") {
                var i = 0;

                let array;

                array = this.state.mem_open_order;

                nextProps.mem_open_order.data.data.current_page_items.map(
                    function (item, i) {
                        array.push(item);

                        return array;
                    }
                );

                if (
                    this.state.currentPage ===
                    nextProps.mem_open_order.data.data.total_page
                ) {
                    this.setState({
                        hasMoreItemsOpenOrder: false,
                    });
                }

                if (nextProps.mem_open_order.data.data.total_page === "0") {
                    this.setState({
                        hasMoreItemsOpenOrder: false,
                    });
                }

                this.setState({
                    totalPageOpenOrder:
                        nextProps.mem_open_order.data.data.total_page,
                });
                this.setState({
                    currentPageOpenOrder:
                        nextProps.mem_open_order.data.data.current_page,
                });

                this.setState({
                    mem_open_order: array,
                });
                // var i = 0;
                // let mem_open_order = nextProps.mem_open_order.data.data.current_page_items.map(
                //     (item) => (
                //         <div
                //             className="open-order-item col-12 d-flex py-3"
                //             key={i++}
                //         >
                //             {item.action_type == "BUY" ? (
                //                 <>
                //                     <div className="order-percentage col-2 px-0">
                //                         <div className="font-12 text-green">
                //                             {t("exchange.buy_limit")}
                //                         </div>
                //                         <Progress
                //                             type="circle"
                //                             percent={
                //                                 item.matched_rate == 0
                //                                     ? 0
                //                                     : parseFloat(
                //                                           item.matched_rate
                //                                       ).toFixed(2) * 100
                //                             }
                //                             width={47}
                //                             strokeColor="green"
                //                             trailColor="#404040"
                //                             strokeWidth={12}
                //                             format={() => (
                //                                 <span className="text-green">
                //                                     {parseFloat(
                //                                         item.matched_rate
                //                                     ).toFixed(2) * 100}
                //                                     %
                //                                 </span>
                //                             )}
                //                         />
                //                     </div>
                //                     <div className="order-detail col-7 px-2">
                //                         <div className="font-14 text-uppercase text-white pb-2 font-weight-bold">
                //                             <span>
                //                                 {/* {item.wallet_code} /{" "}
                //                                 {item.wallet_code_to} */}
                //                                 {item.coin_pair}
                //                             </span>
                //                         </div>
                //                         <div className="col-12 d-flex px-0 font-12">
                //                             <div className="text-dark-grey w-50">
                //                                 {t("common.price")}
                //                             </div>
                //                             <div className="text-white">
                //                                 {item.unit_price_display}
                //                             </div>
                //                         </div>
                //                         <div className="col-12 d-flex px-0 font-12">
                //                             <div className="text-dark-grey w-50">
                //                                 {t("common.amount")}
                //                             </div>
                //                             <div className="text-white">
                //                                 {parseFloat(
                //                                     item.matched_quantity_display
                //                                 ).toString() + " "}
                //                                 /
                //                                 {" " +
                //                                     item.total_quantity_display}
                //                             </div>
                //                         </div>
                //                     </div>
                //                     <div className="order-date col-3 px-0 text-right">
                //                         <div className="text-dark-grey font-12 pb-2">
                //                             {item.trans_date_time}
                //                         </div>
                //                         <div className="">
                //                             {item.display_cancel_button ? (
                //                                 <button
                //                                     onClick={(e) =>
                //                                         this.cancelExchange(
                //                                             e,
                //                                             item.doc_no
                //                                         )
                //                                     }
                //                                     className="btn bg-light-purple text-white font-12 text-uppercase"
                //                                 >
                //                                     {t("common.cancel")}
                //                                 </button>
                //                             ) : (
                //                                 ""
                //                             )}
                //                         </div>
                //                     </div>
                //                 </>
                //             ) : (
                //                 <>
                //                     <div className="order-percentage col-2 px-0">
                //                         <div className="font-12 text-red">
                //                             {t("exchange.sell_limit")}
                //                         </div>
                //                         <Progress
                //                             type="circle"
                //                             percent={
                //                                 item.matched_rate == 0
                //                                     ? 0
                //                                     : parseFloat(
                //                                           item.matched_rate
                //                                       ).toFixed(2) * 100
                //                             }
                //                             width={47}
                //                             strokeColor="red"
                //                             trailColor="#404040"
                //                             strokeWidth={12}
                //                             format={() => (
                //                                 <span className="text-red">
                //                                     {" "}
                //                                     {parseFloat(
                //                                         item.matched_rate
                //                                     ).toFixed(2) * 100}
                //                                     %
                //                                 </span>
                //                             )}
                //                         />
                //                     </div>
                //                     <div className="order-detail col-7 px-2">
                //                         <div className="font-14 text-uppercase text-white pb-2 font-weight-bold">
                //                             <span>{item.coin_pair}</span>
                //                         </div>
                //                         <div className="col-12 d-flex px-0 font-12">
                //                             <div className="text-dark-grey w-50">
                //                                 {t("common.price")}
                //                             </div>
                //                             <div className="text-white">
                //                                 {item.unit_price_display}
                //                             </div>
                //                         </div>
                //                         <div className="col-12 d-flex px-0 font-12">
                //                             <div className="text-dark-grey w-50">
                //                                 {t("common.amount")}
                //                             </div>
                //                             <div className="text-white">
                //                                 {item.matched_quantity_display}{" "}
                //                                 / {item.total_quantity_display}
                //                             </div>
                //                         </div>
                //                     </div>
                //                     <div className="order-date col-3 px-0 text-right">
                //                         <div className="text-dark-grey font-12 pb-2">
                //                             {item.trans_date_time}
                //                         </div>
                //                         <div className="">
                //                             {item.display_cancel_button ? (
                //                                 <button
                //                                     onClick={(e) =>
                //                                         this.cancelExchange(
                //                                             e,
                //                                             item.doc_no
                //                                         )
                //                                     }
                //                                     className="btn bg-light-purple text-white font-12 text-uppercase"
                //                                 >
                //                                     {t("common.cancel")}
                //                                 </button>
                //                             ) : (
                //                                 ""
                //                             )}
                //                         </div>
                //                     </div>
                //                 </>
                //             )}
                //         </div>
                //     )
                // );
                // this.setState({
                //     mem_open_order: mem_open_order,
                // });
            }
        }

        if (nextProps.mem_trade_history !== this.props.mem_trade_history) {
            if (nextProps.mem_trade_history.data.rst == "1") {
                var i = 0;

                let array;

                array = this.state.mem_trade_history;

                nextProps.mem_trade_history.data.data.current_page_items.map(
                    function (item, i) {
                        array.push(item);

                        return array;
                    }
                );

                if (
                    this.state.currentPage ===
                    nextProps.mem_trade_history.data.data.total_page
                ) {
                    this.setState({
                        hasMoreItemsTrading: false,
                    });
                }

                if (nextProps.mem_trade_history.data.data.total_page === "0") {
                    this.setState({
                        hasMoreItemsTrading: false,
                    });
                }

                this.setState({
                    totalPageTrading:
                        nextProps.mem_trade_history.data.data.total_page,
                });
                this.setState({
                    currentPageTrading:
                        nextProps.mem_trade_history.data.data.current_page,
                });

                this.setState({
                    mem_trade_history: array,
                });

                //     let mem_trade_history = nextProps.mem_trade_history.data.data.current_page_items.map(
                //         (item) => (

                //             <div
                //                 className="open-order-item col-12 py-3"
                //                 key={item.key}
                //             >
                //                 <>
                //                     <div className="order-detail col-12 d-flex px-2">
                //                         <div className="font-14 px-0 col-6 text-uppercase text-white pb-2 font-weight-bold">
                //                             {item.coin_pair}
                //                         </div>
                //                         <div className="text-dark-grey px-0 font-12 col-6 pb-2 text-right">
                //                             {item.trans_date_time}
                //                         </div>
                //                     </div>
                //                     <div className="col-12 px-2 d-flex">
                //                         <div
                //                             className="px-0 font-12 col-4"
                //                             style={{
                //                                 color: item.action_type_color_code,
                //                             }}
                //                         >
                //                             {item.action_type}
                //                         </div>
                //                     </div>
                //                     <div className="col-12 px-2 d-flex">
                //                         <div className="text-dark-grey px-0 font-12 col-4">
                //                             {t("common.price")}
                //                         </div>
                //                         <div className="text-dark-grey px-0 font-12 col-4">
                //                             {t("common.filled")}
                //                         </div>
                //                         <div className="text-dark-grey px-0 font-12 col-4 text-right">
                //                             {t("common.total")}
                //                         </div>
                //                     </div>
                //                     <div className="col-12 px-2 d-flex">
                //                         <div className="text-white px-0 font-14 col-4">
                //                             {item.unit_price_display}
                //                         </div>
                //                         <div className="text-white px-0 font-14 col-4">
                //                             {item.matched_quantity_display}
                //                         </div>
                //                         <div className="text-white px-0 font-14 col-4 text-right">
                //                             {item.total_quantity_display}
                //                         </div>
                //                     </div>
                //                 </>
                //             </div>
                //             // </Link>
                //         )
                //     );
                //     this.setState({
                //         mem_trade_history: mem_trade_history,
                //     });
                // }
            }
        }
    }
    render() {
        const TabPane = Tabs.TabPane;
        const {
            loading_visible,
            confirm_visible,
            modal_visible,
            modal_type,
            modal_msg,
            order_history_data,
            mem_trade_history,
            mem_open_order,
        } = this.state;

        let allItemRowsOrderHistory = [];
        let allItemRowsTrading = [];
        let allItemRowsOpenOrder = [];

        mem_open_order.map((item, i) => {
            const perItemRows = this.renderItemOpenOrder(item, i);
            allItemRowsOpenOrder = allItemRowsOpenOrder.concat(perItemRows);

            return allItemRowsOrderHistory;
        });
        order_history_data.map((item, i) => {
            const perItemRows = this.renderItemOrderHistory(item, i);
            allItemRowsOrderHistory =
                allItemRowsOrderHistory.concat(perItemRows);

            return allItemRowsOrderHistory;
        });

        mem_trade_history.map((item, i) => {
            const perItemRows = this.renderItemTrading(item, i);
            allItemRowsTrading = allItemRowsTrading.concat(perItemRows);

            return allItemRowsTrading;
        });

        return (
            <div className="trade-history">
                <div className="pb-4 offset-md-3 col-md-6 px-0">
                    <HyperModal
                        category="response"
                        modalVisible={loading_visible}
                        modalType="loading"
                        footer={[]}
                    />
                    <HyperModal
                        category="s_password"
                        modalVisible={confirm_visible}
                        submitFunc={this.submitCancelOrder}
                        cancelFunc={this.handleModalClose}
                        closable={true}
                    />
                    <HyperModal
                        category="response"
                        modalVisible={modal_visible}
                        modalType={modal_type}
                        modalMsg={modal_msg}
                        modalCloseFunc={this.handleModalClose}
                        cancelFunc={this.handleModalClose}
                    />
                    <Modal
                        title={t("common.filter")}
                        children={
                            <div className="col-12 px-0">
                                <div className="col-12 d-flex form-group">
                                    <div className="col-5 px-0">
                                        <label className="col-12 depth-gray-label text-capitalize pl-0">
                                            {t("common.start_date")}
                                        </label>
                                        <DatePicker
                                            dropdownClassName="calendar-index"
                                            onChange={this.onDateFromChange}
                                            defaultValue={
                                                this.state.dateFirstDay
                                            }
                                            className="datepicker-full-width"
                                        />
                                    </div>
                                    <div className="col-2 px-0"></div>
                                    <div className="col-5 px-0">
                                        <label className="col-12 depth-gray-label text-capitalize pl-0">
                                            {t("common.end_date")}
                                        </label>
                                        {/* <div className="text-right col-12 px-0"> */}
                                        <DatePicker
                                            dropdownClassName="calendar-index"
                                            onChange={this.onDateEndChange}
                                            defaultValue={this.state.dateNow}
                                            className="datepicker-full-width "
                                        />
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="col-12 depth-gray-label text-capitalize pl-0">
                                            {t("exchange.coin")}
                                        </label>
                                        <Select
                                            name="pair"
                                            defaultValue=""
                                            className="select-after select-width w-100"
                                            onSelect={this.handleClickPair}
                                            // value={this.state.filter.pair}
                                        >
                                            <Option
                                                className="text-uppercase"
                                                value=""
                                            >
                                                {t("common.all")}
                                            </Option>
                                            {this.state.market_data.map(
                                                (item) => (
                                                    <Option
                                                        className="text-uppercase"
                                                        value={
                                                            item.crypto_code_from
                                                        }
                                                    >
                                                        {item.crypto_code_from.toUpperCase() +
                                                            "/" +
                                                            item.crypto_code_to}
                                                    </Option>
                                                )
                                            )}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="col-12 depth-gray-label text-capitalize pl-0">
                                            {t("common.type")}
                                        </label>
                                        <Select
                                            // value={
                                            //     this.state.filter.action_type
                                            // }
                                            name="type"
                                            defaultValue=""
                                            className="select-after select-width w-100"
                                            onSelect={this.handleClickType}
                                        >
                                            <Option
                                                className="text-uppercase"
                                                value=""
                                            >
                                                {t("common.all")}
                                            </Option>
                                            <Option
                                                className="text-uppercase"
                                                value="BUY"
                                            >
                                                {t("common.buy")}
                                            </Option>
                                            <Option
                                                className="text-uppercase"
                                                value="SELL"
                                            >
                                                {t("common.sell")}
                                            </Option>
                                        </Select>
                                    </div>
                                </div>
                                <div>
                                    <div className="form-group text-right col-12">
                                        <button
                                            onClick={this.submitFilter}
                                            type="submit"
                                            className="button modal-filter-button"
                                        >
                                            {t("common.apply")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        modalHeadClass={`modal-body-purple`}
                        modalTitleClass={`text-white`}
                        modalBodyClass={`modal-body-purple`}
                        isShowing={this.state.showFilterModel}
                        modalClassId="filter-modal"
                        modalTitleCloseFunc={this.handleSecondaryClose}
                    />

                    <PageHeader
                        title={t("common.trade_history")}
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        historyBack="/market"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        arrowLeft={true}
                        headerBanner={false}
                        btnRight={
                            this.state.show_filter ? (
                                <div className="pointer">
                                    <img
                                        onClick={this.openFilterModal}
                                        src={FilterLogo}
                                        width={16}
                                        height={16}
                                        alt=""
                                    />
                                </div>
                            ) : (
                                ""
                            )
                        }
                    />
                    <Tabs
                        className="tab-open-order col-12 px-0"
                        defaultActiveKey="1"
                        onChange={this.handleClickOnTag}
                    >
                        <TabPane
                            tab={
                                <div className="tab-market-item text-uppercase">
                                    {t("common.open_orders")}
                                </div>
                            }
                            key="1"
                            id="2"
                        >
                            <div className="open-order-area col-12 bg-light-dark px-0 ">
                                <div className="text-white p-3 open-order-title col-12 px-0 d-flex">
                                    <div className="col-6 px-0 text-left">
                                        {t("common.open_orders")}
                                    </div>
                                </div>
                                <div className="open-order-list col-12 px-0 custom-height overflow-auto">
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={this.loadMoreListOpenOrder}
                                        hasMore={
                                            this.state.hasMoreItemsOpenOrder
                                        }
                                        initialLoad={false}
                                        threshold={25}
                                        useWindow={false}
                                    >
                                        {allItemRowsOpenOrder}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </TabPane>

                        <TabPane
                            tab={
                                <div className="tab-market-item text-uppercase">
                                    {t("common.order_history")}
                                </div>
                            }
                            key="2"
                            id="2"
                        >
                            <div className="open-order-area col-12 bg-light-dark px-0 vh-100">
                                <div className="text-white p-3 open-order-title col-12 px-0 d-flex">
                                    <div className="col-6 px-0 text-left">
                                        {t("common.order_history")}
                                    </div>
                                </div>
                                <div className="open-order-list col-12 px-0 open-order-list col-12 px-0 custom-height overflow-auto">
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={this.loadMoreListOrderHistory}
                                        hasMore={
                                            this.state.hasMoreItemsOrderHistory
                                        }
                                        initialLoad={false}
                                        threshold={25}
                                        useWindow={false}
                                    >
                                        {allItemRowsOrderHistory}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane
                            tab={
                                <div className="tab-market-item text-uppercase">
                                    {t("common.trade_history")}
                                </div>
                            }
                            key="3"
                            id="3"
                        >
                            <div className="open-order-area col-12 bg-light-dark px-0 vh-100">
                                <div className="text-white p-3 open-order-title col-12 px-0 d-flex">
                                    <div className="col-6 px-0 text-left">
                                        {t("common.trade_history")}
                                    </div>
                                </div>
                                <div className="open-order-list col-12 px-0 open-order-list col-12 px-0 custom-height overflow-auto">
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={this.loadMoreListTrading}
                                        hasMore={this.state.hasMoreItemsTrading}
                                        initialLoad={false}
                                        threshold={25}
                                        useWindow={false}
                                    >
                                        {allItemRowsTrading}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { marketData, walletBalance } = state;

    return {
        market_data: marketData.market_data,
        // order_list: marketData.order_data,
        mem_open_order: marketData.mem_open_order,
        mem_trade_history: marketData.mem_trade_history,
        order_history_data: marketData.order_history_data,
        isLoading: walletBalance.isLoading,
        post_exchange_result: marketData.post_exchange_result,
    };
};

const mapDispatchToProps = {
    getMarketList,
    // getOrderTransaction,
    getMemberOpenOrder,
    getMemberTradeHistory,
    openModal,
    closeModal,
    getMemberOpenHistory,
    postCancelOrder,
};
export default connect(mapStateToProps, mapDispatchToProps)(TradingHistory);
