import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
    converted_data: []
};

const stakingReducer = createReducer(initialState) ({

    [type.POST_STAKING]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

    [type.POST_CONVERTED_AMOUNT]: (state, action) => ({
        ...state,
        converted_data: action.payload.data,
    }),

});

export default stakingReducer;