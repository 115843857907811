import React, { Component } from "react";
import { Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { setLocale } from "react-i18nify";

import { getMemberProfile } from "../../store/ducks/home/actions";
import { GetSubscriptionStatus } from "../../store/ducks/footer/actions";

import { GenHash } from "../../common/helpers";

import AssetIconOff from "../../public/v2/toolbar/toolbar-asset-off.svg";

import StakingIconOff from "../../public/v2/toolbar/toolbar-cele-program-off.svg";
import CelebreneurIconOff from "../../public/v2/toolbar/toolbar-staking-program-off.svg";
import ProfileIconOff from "../../public/v2/toolbar/toolbar-account-off.svg";

import AssetIconOn from "../../public/v2/toolbar/toolbar-asset-on.svg";

import StakingIconOn from "../../public/v2/toolbar/toolbar-cele-program-on.svg";
import CelebreneurIconOn from "../../public/v2/toolbar/toolbar-staking-program-on.svg";
import ProfileIconOn from "../../public/v2/toolbar/toolbar-account-on.svg";

import GcoxOn from "../../public/v3/toolbar/toolbar-gcox-on.png";
import GcoxOff from "../../public/v3/toolbar/toolbar-gcox-off.png";

import "./index.css";

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subscriptionURL: "/celebreneurProgram",
        };

        this.handleSubscriptionStatus =
            this.handleSubscriptionStatus.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem("lang") != null) {
            setLocale(sessionStorage.getItem("lang"));
        }

        let subscriptionData = {};
        subscriptionData.subscriptionToken = "1gm";
        let subscriptionHash = GenHash(subscriptionData, "s2Y9k5FBnc");

        this.props.GetSubscriptionStatus(subscriptionHash);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // let getSubscriptionStatus =  nextProps.getSubscriptionStatus.subscription_status;
        // let subscriptionToken = sessionStorage.getItem("subscriptionToken");
        //
        // if (getSubscriptionStatus === subscriptionToken) {
        this.setState({
            subscriptionURL: "/celebreneurProgram",
        });
        // }
        // else {
        //     this.setState({
        //         subscriptionURL: '/home'
        //     });
        // }

        // if(nextProps.getMemberProfileData !== this.props.getMemberProfileData){
        //     //Check profile success and set the subscription status to state
        //     if(nextProps.getMemberProfileData.rst === "1"){
        //         const data = {};
        //
        //         data.subscriptionToken = nextProps.getMemberProfileData.data.subscriptionStatus + "gm";
        //         let hash = GenHash(data, "s2Y9k5FBnc");
        //
        //         sessionStorage.setItem("subscriptionToken", hash);
        //
        //         let getSubscriptionStatus =  nextProps.getSubscriptionStatus.subscription_status;
        //         let subscriptionToken = sessionStorage.getItem("subscriptionToken");
        //
        //        if (getSubscriptionStatus === subscriptionToken) {
        //             this.setState({
        //                 subscriptionURL: '/celebreneurProgram'
        //             });
        //         }
        //         else {
        //             this.setState({
        //                 subscriptionURL: '/home'
        //             });
        //         }
        //
        //     }
        // }
    }

    handleSubscriptionStatus() {
        const subscriptionData = {};
        subscriptionData.subscriptionToken = "1gm";
        var subscriptionHash = GenHash(subscriptionData, "s2Y9k5FBnc");

        this.props.GetSubscriptionStatus(subscriptionHash);
    }

    render() {
        const {
            assetActive,
            stakingActive,
            accountActive,
            pinActive,
            shareActive /*, giftCardActive*/,
        } = this.props;
        const { subscriptionURL } = this.state;

        return (
            <div className="grid">
                <div className="row">
                    <nav className="navbar fixed-bottom bg-footer-dark">
                        <div className="col-6 col-offset-1">
                            <Link to="/home">
                                <div className="col-12">
                                    <div
                                        className={`row text-center link ${assetActive} footer-d-block`}
                                    >
                                        {/*<AssetIcon className="footer-icon" />*/}
                                        <img
                                            src={
                                                assetActive === "active"
                                                    ? AssetIconOn
                                                    : AssetIconOff
                                            }
                                            className="footer-icon"
                                            alt="asset-icon"
                                        />
                                    </div>
                                    {/*<div className={`row text-center mt-icon footer-d-block`}>*/}
                                    {/*<span className={`navbar-brand ${assetActive}`}></span>*/}
                                    {/*/!*<span className={`navbar-brand ${assetActive}`}>{t('home.asset')}</span>*!/*/}
                                    {/*</div>*/}
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-2 text-center">
                            <Link to="/program/staking">
                                <div className="col-12">
                                    <div
                                        className={`row text-center link ${stakingActive} footer-d-block`}
                                    >
                                        
                                        <img
                                            src={
                                                stakingActive === "active"
                                                    ? StakingIconOn
                                                    : StakingIconOff
                                            }
                                            className="footer-icon"
                                            alt="market-icon"
                                        />
                                    </div>
                         
                                </div>
                            </Link>
                        </div> */}

                        {/* {subscriptionURL && (
                            <div className="col-2 text-center">
                                <Link to={subscriptionURL}>
                                    <div className="col-12">
                                        <div
                                            className={`row text-center link ${pinActive} footer-d-block`}
                                        >
                                            
                                            <img
                                                src={
                                                    pinActive === "active"
                                                        ? CelebreneurIconOn
                                                        : CelebreneurIconOff
                                                }
                                                className="footer-icon"
                                                alt="celebreneur-icon"
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )} */}

                        {/*<div className="col-2 text-center">*/}
                        {/*    <Link to="/share">*/}
                        {/*        <div className="col-12">*/}
                        {/*            <div className={`row text-center link ${shareActive} footer-d-block`}>*/}
                        {/*                <FontAwesomeIcon icon={faChartLine} className="footer-icon"/>*/}
                        {/*                <img src={shareActive === "active" ? GcoxOn : GcoxOff} className="footer-icon" alt="market-icon" />*/}
                        {/*            </div>*/}
                        {/*            <div className="row text-center mt-icon footer-d-block">*/}
                        {/*                <span className={`navbar-brand ${marketActive}`}></span>*/}
                        {/*                <span className={`navbar-brand ${marketActive}`}>{t('home.market')}</span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}

                        {/*<div className="col-2 text-center">*/}
                        {/*    <Link to="/emall">*/}
                        {/*        <div className="col-12">*/}
                        {/*            <div className={`row text-center link ${emallActive} footer-d-block`}>*/}
                        {/*                <img src={emallActive === "active" ? EmallIconOn : EmallIconOff } className="footer-icon" alt="emall-icon" />*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                        <div className="col-6 text-center">
                            <Link to="/profile">
                                <div className="col-12">
                                    <div
                                        className={`row text-center link ${accountActive} footer-d-block`}
                                    >
                                        {/*<FontAwesomeIcon icon={faUser} className="footer-icon"/>*/}
                                        <img
                                            src={
                                                accountActive === "active"
                                                    ? ProfileIconOn
                                                    : ProfileIconOff
                                            }
                                            className="footer-icon"
                                            alt="profile-icon"
                                        />
                                    </div>
                                    {/*<div className="row text-center mt-icon footer-d-block">*/}
                                    {/*<span className={`navbar-brand ${accountActive}`}></span>*/}
                                    {/*/!*<span className={`navbar-brand ${accountActive}`}>{t('home.account')}</span>*!/*/}
                                    {/*</div>*/}
                                </div>
                            </Link>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { getMemberProfile, getSubscriptionStatus } = state;

    return {
        getMemberProfileData: getMemberProfile.getMemberProfileData,
        getSubscriptionStatus: getSubscriptionStatus.getSubscriptionStatus,
    };
};

const mapDispatchToProps = {
    getMemberProfile,
    GetSubscriptionStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

Index.propTypes = {
    assetActive: PropTypes.string,
    marketActive: PropTypes.string,
    stakingActive: PropTypes.string,
    accountActive: PropTypes.string,
    pinActive: PropTypes.string,
    emallActive: PropTypes.string,
    giftCardActive: PropTypes.string,
    shareActive: PropTypes.string,
};
