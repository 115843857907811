import React, { Component } from "react";
import { setLocale, t } from "react-i18nify";
import { Tabs } from "antd";
import connect from "react-redux/es/connect/connect";
import InfiniteScroll from "react-infinite-scroller";
import { message } from "antd";

import PageHeader from "../../components/PageHeader";
import TabFooter from "../../components/TabFooter";
import Modal from "../../components/modal";
import ErrorIcon from "../../components/ErrorAlert";
import LoadingMask from "../../components/loadingMask";

import { getProfile } from "../../store/ducks/profile/actions";
import { closeModal, openModal } from "../../store/ducks/modal/actions";
import { GetPinList } from "../../store/ducks/pinList/actions";
import { GetPinDetail, PostTopUpPin } from "../../store/ducks/topUpPin/actions";

import { fieldChecking, GenHash } from "../../common/helpers";

import DiamondOn from "../../public/images/icon-diamond-on@3x.png";
import DiamondOff from "../../public/images/icon-diamond-off@3x.png";
import CopyIcon from "../../public/images/icon-copy@3x.png";

import "./sKey.scss";
import StringInput from "../../components/input/StringInput";
import PasswordInput from "../../components/input/PasswordInput";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import SuccessIcon from "../../components/SuccessAlert";
import { formValidation } from "../../common/validator/formValidation";
// inner width - 64 / 2
class sKey extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ranking: 1,
            rankingDiv: [
                <img
                    src={DiamondOff}
                    alt=""
                    key=""
                    width={9}
                    className="mb-1 mr-1"
                />,
            ],
            gPinWidth: window.innerWidth / 2 - 32,
            gPinHeight: window.innerHeight - 70,
            errType: "",
            errMsg: "",
            showErrorModal: false,

            member_pin_list: [],
            loading: true,
            loadingText: true,
            currentPage: "1",
            totalPage: 0,
            status: "",
            type: "",
            packageName: "",
            package: "",
            hasMoreItems: true,
            totalItems: "",

            package_detail: "",
            package_amount: "",
            pin_code: "",
            disabled_button: "",
            secondary_password: "",
            post_data: "",
            showLoadingModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            successHeaderMsg: "",
            successMsg: "",
        };

        this.updateHeight = this.updateHeight.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
        this.handleCopyText = this.handleCopyText.bind(this);

        this.handlePinCodeValue = this.handlePinCodeValue.bind(this);
        this.handleTopUpPin = this.handleTopUpPin.bind(this);
        this.handleSecondaryPassword = this.handleSecondaryPassword.bind(this);

        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleSubmitTopUpPin = this.handleSubmitTopUpPin.bind(this);
    }

    componentDidMount() {
        formValidation("topup-pin-form");
        setLocale(sessionStorage.getItem("lang"));

        let profileObj = {};
        profileObj.username = sessionStorage.getItem("email");
        profileObj.token = sessionStorage.getItem("token");

        let hash = GenHash(profileObj, process.env.REACT_APP_GM_SECRET_KEY);
        profileObj.hash = hash;

        this.props.getProfile(profileObj);

        let username = sessionStorage.getItem("email");
        let token = sessionStorage.getItem("token");

        let data = {};
        data.page = 1;
        data.token = token;
        data.username = username;
        data.viewType = "";
        let pinListhash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = pinListhash;

        this.props.GetPinList(data, process.env.REACT_APP_GM_URL);

        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.get_pin_list !== this.props.get_pin_list) {
            if (nextProps.get_pin_list.rst === "1") {
                let array;

                array = this.state.member_pin_list;
                nextProps.get_pin_list.data.currentPageItems.map(function (
                    item,
                    i
                ) {
                    array.push(item);

                    return array;
                });

                if (
                    this.state.currentPage ===
                    nextProps.get_pin_list.data.totalPage
                ) {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                if (nextProps.get_pin_list.data.totalPage === "0") {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                this.setState({
                    loading: false,
                    member_pin_list: array,
                    totalPage: nextProps.get_pin_list.data.totalPage,
                    currentPage: nextProps.get_pin_list.data.currentPage,
                    totalItems: nextProps.get_pin_list.data.totalPageItems,
                });
            }
        }

        if (nextProps.profile_data !== this.props.profile_data) {
            if (nextProps.profile_data.rst === "1") {
                let rankingImg = [];
                let maxRanking = 5;

                for (let i = 0; i < nextProps.profile_data.data.rank; i++) {
                    rankingImg.push(
                        <img
                            src={DiamondOn}
                            alt={i}
                            key={i}
                            width={9}
                            className="mb-1 mr-1"
                        />
                    );
                }

                for (
                    let i = nextProps.profile_data.data.rank;
                    i < maxRanking;
                    i++
                ) {
                    rankingImg.push(
                        <img
                            src={DiamondOff}
                            alt={i}
                            key={i}
                            width={9}
                            className="mb-1 mr-1"
                        />
                    );
                }

                this.setState({
                    ranking: nextProps.profile_data.data.rank,
                    rankingDiv: rankingImg,
                });
            } else {
                switch (nextProps.profile_data.msg) {
                    case "token_expiry":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");

                        break;
                    default:
                        nextProps.history.push("/login");
                        break;
                }
            }
        }

        if (nextProps.get_pin_detail !== this.props.get_pin_detail) {
            let msg = "";

            if (nextProps.get_pin_detail.rst === "1") {
                switch (nextProps.get_pin_detail.data.amount) {
                    case "10.00":
                        msg = t("purchasePin.GIFTCARD10");
                        break;
                    case "100.00":
                        msg = t("purchasePin.GIFTCARD100");
                        break;
                    default:
                        msg = nextProps.get_pin_detail.data.msg;
                        break;
                }

                this.setState({
                    package_detail: msg,
                    package_amount: nextProps.get_pin_detail.data.amount,
                    disabled_button: false,
                });
            } else {
                this.setState({
                    package_detail: t("topUpPin.no_gift_card_found"),
                    package_amount: "0",
                    disabled_button: true,
                });
            }
        }

        if (nextProps.post_top_up_pin !== this.props.post_top_up_pin) {
            if (nextProps.post_top_up_pin.rst === "1") {
                this.props.openModal("success-modal");

                this.setState({
                    package_detail: "",
                    package_amount: "",
                    pin_code: "",
                    disabled_button: "",
                    secondary_password: "",
                    post_data: "1",
                    showLoadingModal: false,
                    successMsg: t("topUpPin." + nextProps.post_top_up_pin.msg),
                });
            } else {
                let msg = "";

                switch (nextProps.post_top_up_pin.msg) {
                    case "invalid_secondary_password":
                        msg = t("topUpPin.invalid_secondary_password");
                        break;
                    case "please_enter_secondary_password":
                        msg = t("topUpPin.please_enter_secondary_password");
                        break;
                    default:
                        msg = nextProps.post_top_up_pin.msg;
                        break;
                }

                this.setState({
                    errMsg: msg,
                    showLoadingModal: false,
                });

                this.props.openModal("error-modal");
            }
        }

        if (nextProps.classIsShowing.modalName === "topup-pin-loading-modal") {
            this.setState({
                showLoadingModal: nextProps.classIsShowing.isShowing,
            });
        }

        if (nextProps.classIsShowing.modalName === "error-modal") {
            this.setState({
                showErrorModal: nextProps.classIsShowing.isShowing,
            });
        }

        if (nextProps.classIsShowing.modalName === "success-modal") {
            this.setState({
                showSuccessModal: nextProps.classIsShowing.isShowing,
            });
        }

        if (
            nextProps.classIsShowing.modalName ===
            "topup-pin-confirmation-modal"
        ) {
            this.setState({
                showConfirmationModal: nextProps.classIsShowing.isShowing,
            });
        }
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    updateHeight() {
        this.setState({ assetHeight: window.innerHeight - (58 + 344) });
    }

    loadMoreList() {
        let data = {};
        let currentPage = parseInt(this.state.currentPage) + 1;
        let viewType = "LIST";
        let username = sessionStorage.getItem("email");
        let token = sessionStorage.getItem("token");

        data.page = currentPage;
        data.token = token;
        data.username = username;
        data.viewType = viewType;
        var hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        if (currentPage >= this.state.totalPage) {
            this.setState({
                hasMoreItems: false,
                loading: false,
            });
        }

        this.props.GetPinList(data, process.env.REACT_APP_GM_URL);
    }

    handleCopyText(event) {
        this.copied = false;

        const textarea = document.createElement("textarea");
        textarea.value = document
            .getElementById(event.target.id)
            .getAttribute("value");

        document.body.appendChild(textarea);

        textarea.select();

        try {
            document.execCommand("copy");

            this.copied = true;

            message.success(t("copied"));
        } catch (err) {
            this.copied = false;
        }

        textarea.remove();
    }

    handlePinCodeValue(event) {
        let data = {};
        data.code = event.target.value;
        data.token = sessionStorage.getItem("token");
        data.username = sessionStorage.getItem("email");
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({
            pin_code: event.target.value,
        });

        this.props.GetPinDetail(data, process.env.REACT_APP_GM_URL);
    }

    async handleTopUpPin(event) {
        event.preventDefault();

        let validate = fieldChecking(document.getElementById("topup-pin-form"));

        if (validate === false) {
            return false;
        }

        this.props.openModal("topup-pin-confirmation-modal");
    }

    handleSubmitTopUpPin() {
        let data = {};
        data.code = this.state.pin_code;
        data.salesType = "REDEEM";
        data.secondaryPassword = this.state.secondary_password;
        data.token = sessionStorage.getItem("token");
        data.username = sessionStorage.getItem("email");
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({ showConfirmationModal: false });

        this.props.openModal("topup-pin-loading-modal");

        this.props.PostTopUpPin(data, process.env.REACT_APP_GM_URL);
    }

    handleSecondaryPassword(event) {
        this.setState({
            secondary_password: event.target.value,
        });
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/home");
    }

    handleConfirmationClose() {
        this.props.closeModal("topup-pin-confirmation-modal");
    }

    render() {
        const {
            showErrorModal,
            errMsg,
            loading,
            currentPage,
            totalPage,
            member_pin_list,
            hasMoreItems,
            pin_code,
            package_detail,
            package_amount,
            disabled_button,
            secondary_password,
            post_data,
            showLoadingModal,
            showSuccessModal,
            showConfirmationModal,
            successHeaderMsg,
            successMsg,
        } = this.state;

        const { TabPane } = Tabs;

        return (
            <div className="sKey">
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>
                                        {t("topUpPin.confirmation_header_msg")}
                                    </h4>
                                    <p>{t("topUpPin.confirmation_msg")}</p>
                                </div>
                            </div>
                        }
                        closeText={t("topUpPin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={this.handleConfirmationClose}
                        closeId=""
                        submitText={t("topUpPin.modal_submit")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSubmitTopUpPin}
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="topup-pin-confirmation-modal"
                    />
                )}

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={300} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="topup-pin-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successHeaderMsg}</h4>
                                    <p>{successMsg}</p>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div
                    className="overflow-auto"
                    style={{ height: this.state.gPinHeight }}
                >
                    <div>
                        <PageHeader
                            title={t("sKey.g_key")}
                            titleClass="text-center text-uppercase"
                            titleHeader="pt-4"
                            backgroundHeight={80}
                        />

                        <Tabs>
                            <TabPane
                                tab={
                                    <div
                                        style={{ width: this.state.gPinWidth }}
                                        className="text-center"
                                    >
                                        {t("sKey.redeem")}
                                    </div>
                                }
                                key="redeem"
                            >
                                <div className="pinList container col-12">
                                    <div className="card mx-auto">
                                        <div className="card-body px-0">
                                            <form
                                                id="topup-pin-form"
                                                onSubmit={this.handleTopUpPin}
                                                className="needs-validation col-12"
                                                noValidate
                                            >
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="col-12">
                                                            <p className="purchase-pin-sub-title">
                                                                <strong>
                                                                    {t(
                                                                        "topUpPin.code"
                                                                    )}
                                                                </strong>
                                                            </p>
                                                        </div>
                                                        <div className="col-12">
                                                            <StringInput
                                                                id="pin_code"
                                                                name="pin_code"
                                                                onChange={
                                                                    this
                                                                        .handlePinCodeValue
                                                                }
                                                                value={pin_code}
                                                                className="form-control col-12"
                                                                required
                                                                requiredMsg={t(
                                                                    "validator.requiredMsg"
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="col-12">
                                                            <hr className="hr-border-color" />
                                                        </div>

                                                        <div className="col-12">
                                                            <span className="package-name-label">
                                                                <strong>
                                                                    {t(
                                                                        "topUpPin.gift_card_name"
                                                                    )}
                                                                </strong>
                                                            </span>
                                                        </div>

                                                        <div className="col-12 mt-2">
                                                            <span>
                                                                {post_data ===
                                                                ""
                                                                    ? package_detail
                                                                    : ""}
                                                            </span>
                                                        </div>

                                                        <div className="col-12">
                                                            <hr className="hr-border-color" />
                                                        </div>

                                                        <div className="col-12">
                                                            <span className="package-name-label">
                                                                <strong>
                                                                    {t(
                                                                        "topUpPin.Amount"
                                                                    )}{" "}
                                                                    {t(
                                                                        "topUpPin.Units"
                                                                    )}
                                                                </strong>
                                                            </span>
                                                            <span className="float-right">
                                                                {post_data ===
                                                                ""
                                                                    ? package_amount
                                                                    : ""}
                                                            </span>
                                                        </div>

                                                        <div className="col-12">
                                                            <hr className="hr-border-color" />
                                                        </div>

                                                        <div className="col-12">
                                                            <span className="purchase-pin-sub-title">
                                                                <strong>
                                                                    {t(
                                                                        "topUpPin.secondary_password"
                                                                    )}
                                                                </strong>
                                                            </span>
                                                            <PasswordInput
                                                                id="secondary_password"
                                                                name="secondary_password"
                                                                value={
                                                                    secondary_password
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleSecondaryPassword
                                                                }
                                                                className="secondary-password-field form-control"
                                                                required
                                                                requiredMsg={t(
                                                                    "validator.requiredMsg"
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 mt-4">
                                                        <button
                                                            type="submit"
                                                            disabled={
                                                                disabled_button
                                                            }
                                                            className="col-12 btn btn-primary"
                                                        >
                                                            {t(
                                                                "topUpPin.submit"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane
                                tab={
                                    <div
                                        style={{ width: this.state.gPinWidth }}
                                        className="text-center"
                                    >
                                        {t("sKey.list")}
                                    </div>
                                }
                                key="list"
                            >
                                <div className="pinList container">
                                    <div className="px-0">
                                        <InfiniteScroll
                                            key="giftCard"
                                            pageStart={0}
                                            loadMore={this.loadMoreList}
                                            hasMore={hasMoreItems}
                                            loader={
                                                <div className="row">
                                                    <div className="mx-auto">
                                                        <LoadingMask
                                                            width={80}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            initialLoad={false}
                                            threshold={5}
                                        >
                                            {member_pin_list.map(
                                                (member_pin_list, i) => (
                                                    <div
                                                        className="row"
                                                        key={
                                                            member_pin_list.code
                                                        }
                                                    >
                                                        <div className="col-12 mt-3">
                                                            <div>
                                                                <span className="order-doc-no">
                                                                    {
                                                                        member_pin_list.code
                                                                    }
                                                                </span>

                                                                <img
                                                                    id={`pin-code${i}`}
                                                                    onClick={
                                                                        this
                                                                            .handleCopyText
                                                                    }
                                                                    className="ml-2"
                                                                    src={
                                                                        CopyIcon
                                                                    }
                                                                    width={10}
                                                                    alt="copy-icon"
                                                                    value={
                                                                        member_pin_list.code
                                                                    }
                                                                />

                                                                {member_pin_list.redeemedBy !==
                                                                    "" && (
                                                                    <span className="float-right text-uppercase order-doc-no active-status">
                                                                        {t(
                                                                            "pinList.package_used"
                                                                        )}
                                                                    </span>
                                                                )}

                                                                {member_pin_list.redeemedBy ===
                                                                    "" && (
                                                                    <span className="float-right text-uppercase order-doc-no active-used">
                                                                        {t(
                                                                            "pinList.package_active"
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <span className="package-trans-date">
                                                                {" "}
                                                                {
                                                                    member_pin_list.transDate
                                                                }
                                                            </span>
                                                        </div>

                                                        <div className="col-12 mt-2 border-bottom"></div>
                                                    </div>
                                                )
                                            )}
                                        </InfiniteScroll>

                                        {((!loading && totalPage === 1) ||
                                            (!loading &&
                                                currentPage === totalPage)) && (
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="mx-auto">
                                                        <span>
                                                            <small>
                                                                <b>
                                                                    {t(
                                                                        "walletDetails.end_of_record"
                                                                    )}
                                                                </b>
                                                            </small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {!loading && totalPage < 1 && (
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="mx-auto">
                                                        <span>
                                                            <small>
                                                                <b>
                                                                    {t(
                                                                        "walletDetails.no_record"
                                                                    )}
                                                                </b>
                                                            </small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <TabFooter giftCardActive="active" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile, modal, getPinList, getPinDetail, postTopUpPin } = state;

    return {
        profile_data: profile.profileData,
        classIsShowing: modal.classIsShowing,
        get_pin_list: getPinList.GET_PIN_LIST,
        get_pin_detail: getPinDetail.GET_PIN_DETAIL,
        post_top_up_pin: postTopUpPin.POST_TOP_UP_PIN,
    };
};

const mapDispatchToProps = {
    getProfile,
    GetPinList,
    GetPinDetail,
    PostTopUpPin,
    openModal,
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(sKey);
