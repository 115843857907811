import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale, t } from "react-i18nify";
import moment from "moment";

import PageHeader from "../../components/PageHeaderV2";
import LoadingMask from "../../components/loadingMask";

import { postRewardsList } from "../../store/ducks/rewards/actions";

import { GenHash } from "../../common/helpers";

import "./rewardsDetails.scss";
import InfiniteScroll from "react-infinite-scroller";
// import {Link} from "react-router-dom";
// import btnCancel from "../../public/v2/icon/icon-close.svg";

class RewardDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            dateFrom: "",
            dateEnd: "",
            dateFirstDay: moment().startOf("month"),
            dateNow: moment(),
            hasMoreItems: true,
            screenHeight: window.innerHeight - 190,
            rwdType: "",
            currentPage: 1,
            totalPage: 0,
            data: [],
        };

        this.renderItem = this.renderItem.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
        this.handleBack = this.handleBack.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `60px`);
    }

    handleBack() {
        this.props.history.goBack();
    }

    UNSAFE_componentWillMount() {
        if (this.props.location.state !== undefined) {
            this.setState(
                {
                    dateFrom: this.props.location.state
                        .dateStart /*moment().startOf('month').format('YYYY-MM-DD'),*/,
                    dateEnd: this.props.location.state
                        .dateTo /*moment().format('YYYY-MM-DD')*/,
                },
                () => {
                    const data = {};

                    data.email = sessionStorage.getItem("email");
                    data.token = sessionStorage.getItem("token");
                    data.rwdType = this.props.location.state.rewardType;
                    data.page = 1;
                    data.date_from = this.props.location.state.dateStart;
                    data.date_to = this.props.location.state.dateTo;

                    let hash = GenHash(
                        data,
                        process.env.REACT_APP_GM_SECRET_KEY
                    );
                    data.hash = hash;

                    this.props.postRewardsList(data);
                }
            );
        } else {
            this.props.history.push({
                pathname: "/home",
                state: { error: "invalid_bonus_type" },
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });
        setLocale(sessionStorage.getItem("lang"));
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.rewardsListData !== this.props.rewardsListData) {
            if (nextProps.rewardsListData.rst === "1") {
                let array;

                array = this.state.data;

                nextProps.rewardsListData.data.currentPageItems.map(function (
                    item,
                    i
                ) {
                    array.push(item);

                    return array;
                });

                this.setState({
                    data: array,
                    totalPage: nextProps.rewardsListData.data.totalPage,
                    currentPage: nextProps.rewardsListData.data.currentPage,
                    rwdType: this.props.location.state.rewardType,
                    loading: false,
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        }
    }

    loadMoreList(e, page) {
        this.setState({
            loading: true,
        });
        const trans = {};
        let currentPage = parseInt(this.state.currentPage) + 1;
        const { rewardType } =
            this.props.location.state === undefined
                ? ""
                : this.props.location.state;

        trans.email = sessionStorage.getItem("email");
        trans.token = sessionStorage.getItem("token");
        trans.rwdType = rewardType;
        trans.page = currentPage;
        trans.date_from = this.state.dateFrom;
        trans.date_to = this.state.dateEnd;

        let hash = GenHash(trans, process.env.REACT_APP_GM_SECRET_KEY);
        trans.hash = hash;

        if (currentPage >= this.state.totalPage) {
            this.setState({
                hasMoreItems: false,
                loading: false,
            });
        }

        // this.setState({ loading: true });
        this.props.postRewardsList(trans);
    }

    renderItem(item, i) {
        const itemRows = [
            <li className="list-group-item asset-panel-list-group-item" key={i}>
                <div className="pl-4">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="fa-pull-left">
                                <small>{t("rewards.date")}</small>
                                <small>
                                    <b>&nbsp;{item.transDate}</b>
                                </small>
                            </div>
                            <div className="fa-pull-right">
                                <small>{t("rewards.sales")}</small>
                                <small>
                                    <b>&nbsp;{item.sales}</b>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="fa-pull-left">
                                <small>{t("rewards.percentage")}</small>
                                <small>
                                    <b>&nbsp;{item.perc}</b>
                                </small>
                            </div>
                            <div className="fa-pull-right">
                                <small>{t("rewards.bonus")}</small>
                                <small>
                                    <b>&nbsp;{item.bonus}</b>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </li>,
        ];

        return itemRows;
    }

    render() {
        const { loading, data, currentPage, totalPage } = this.state;

        let allItemRows = [];

        data.map((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);

            return allItemRows;
        });

        return (
            <div className="reward-details">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    goBackFunc={this.handleBack}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("rewards.rewards")}
                    arrowLeft={true}
                />

                <div className="walletDetails offset-md-2 col-md-8 custom-height overflow-auto">
                    {/*<div className="py-1 list-header-background">*/}
                    {/*<div className="col-12 pl-4">*/}
                    {/*<label className="details-size-80 my-auto">*/}
                    {/*<b>{t('rewards.'+rwdType)}</b>*/}
                    {/*</label>*/}
                    {/*</div>*/}
                    {/*</div>*/}

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadMoreList}
                        hasMore={this.state.hasMoreItems}
                        loader={
                            !loading ? (
                                ""
                            ) : (
                                <div className="row" key={0}>
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            )
                        }
                        initialLoad={false}
                        threshold={15}
                    >
                        {<aside className="list-group">{allItemRows}</aside>}
                    </InfiniteScroll>

                    {((!loading && totalPage === 1) ||
                        (!loading && currentPage === totalPage)) && (
                        <div className="col-12">
                            <div className="row">
                                <div className="mx-auto">
                                    <span>
                                        <small>
                                            <b className="text-light">
                                                {t(
                                                    "walletDetails.end_of_record"
                                                )}
                                            </b>
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {!loading && totalPage < 1 && (
                        <div className="col-12">
                            <div className="row">
                                <div className="mx-auto">
                                    <span>
                                        <small>
                                            <b>
                                                {t("walletDetails.no_record")}
                                            </b>
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { rewards } = state;

    return {
        rewardsListData: rewards.data,
    };
};

const mapDispatchToProps = {
    postRewardsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardDetails);
