import * as type from './types';
import axios from 'axios';

export const postRegister = (data) => dispatch => {
     axios.post(process.env.REACT_APP_GM_URL + '/member/register', {
         "username":data['username'],
         "password": data['password'],
         "confirmPassword": data['confirmPassword'],
         "mobilePrefix": data['mobilePrefix'],
         "mobileNumber": data['mobileNumber'],
         "email": data['email'],
         "referralUsername": data['referralUsername'],
         "autoLogin": data['autoLogin'],
         "lang": data['langCode'],
         "hash": data['hash']
     }, {
         headers: {
             'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
             'Authorization': sessionStorage.getItem("accessToken")
         }
     }).then( (response) => {
         dispatch ({
             type: type.POST_REGISTER,
             payload: response
         })
     }).catch (error => {
         dispatch ({
             type: type.POST_REGISTER,
             payload: {"rst":"0","msg":"system_error"}
         });

     });
};
