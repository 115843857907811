import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const footerPinReducer = createReducer(initialState) ({

    [type.GET_SUBSCRIPTION_STATUS]: (state, action) => ({
        ...state,
        getSubscriptionStatus: action.payload,
    })

});

export default footerPinReducer;