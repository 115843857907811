import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
    home_data: [],
    lite_data: [],
    memberWallet: [],
};

const homeReducer = createReducer(initialState)({
    [type.POST_WALLET_BALANCE]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

    [type.POST_WALLET_BALANCE_HOME]: (state, action) => ({
        ...state,
        home_data: action.payload.data,
    }),

    [type.POST_WALLET_BALANCE_LITE]: (state, action) => ({
        ...state,
        lite_data: action.payload.data,
    }),

    [type.GET_MEMBER_PROFILE]: (state, action) => ({
        ...state,
        getMemberProfileData: action.payload.data,
    }),

    [type.GET_ANNOUCEMENT_LIST]: (state, action) => ({
        ...state,
        getAnnoucementData: action.payload.data,
    }),

    [type.POST_AUTO_SELL]: (state, action) => ({
        ...state,
        postAutoSell: action.payload,
    }),

    [type.GET_LANGUAGE_LIST]: (state, action) => ({
        ...state,
        getLanguageList: action.payload.data,
    }),

    [type.POST_HOME_PANEL_SETTING]: (state, action) => ({
        ...state,
        homePanel: action.payload,
    }),

    [type.GET_MEMBER_WALLET]: (state, action) => ({
        ...state,
        memberWallet: action.payload,
    }),

    [type.GET_MISSION_SETTING]: (state, action) => ({
        ...state,
        missionData: action.payload,
    }),

    [type.GET_WITHDRAWAL_SETTING]: (state, action) => ({
        ...state,
        withdrawalData: action.payload,
    }),

    [type.GET_EXCHANGE_SETTING]: (state, action) => ({
        ...state,
        exchangeData: action.payload,
    }),

    [type.GET_TRANSFER_SETTING]: (state, action) => ({
        ...state,
        transferData: action.payload,
    }),

    [type.GET_STAKING_SETTING]: (state, action) => ({
        ...state,
        stakingData: action.payload,
    }),

    [type.GET_DEPOSIT_SETTING]: (state, action) => ({
        ...state,
        depositData: action.payload,
    }),

    [type.IS_LOADING]: (state, action) => ({
        ...state,
        isLoading: action.payload,
    }),

    [type.GET_WALLET_STATEMENT]: (state, action) => ({
        ...state,
        walletStatementList: action.payload.data,
    }),

    [type.GET_WALLET_SETTING]: (state, action) => ({
        ...state,
        walletSetting: action.payload.data,
    }),
    [type.GET_LANGUAGE_FILE]: (state, action) => ({
        ...state,
        getLanguageFile: action.payload,
    }),
    [type.GET_CONVERT_SETTING]: (state, action) => ({
        ...state,
        convertData: action.payload,
    }),
});

export default homeReducer;
