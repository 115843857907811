import React, { Component } from "react";
// import {setLocale, t} from "react-i18nify";
import { connect } from "react-redux";

import StringInput from "../../../components/input/StringInput/index";
import PageHeader from "../../../components/PageHeaderV2/index";
import { fieldChecking, GenHash } from "../../../common/helpers";
import LoadingMask from "../../../components/loadingMask";
import ErrorIcon from "../../../components/ErrorAlert";
import SuccessIcon from "../../../components/SuccessAlert";
import Modal from "../../../components/modal";
import { formValidation } from "../../../common/validator/formValidation";

import { postForgotPassword } from "../../../store/ducks/forgotPassword/actions";
import { openModal, closeModal } from "../../../store/ducks/modal/actions";

import "./forgot_password.scss";

import { getLanguageFile } from "../../../store/ducks/home/actions";
import { t, setLanguageFileJson } from "../../../common/translation";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            confirmPassword: "",
            verification_code: "",
            showLoadingModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            errMsg: "",
            sucessMsg: "",
        };

        this.handleUsername = this.handleUsername.bind(this);
        this.handleVerificationCode = this.handleVerificationCode.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleSubmitForgotPassword = this.handleSubmitForgotPassword.bind(
            this
        );
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
    }

    componentDidMount() {
        formValidation("forgot-password-form");

        //  setLocale(sessionStorage.getItem("lang"));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.language_data !== this.props.language_data) {
            setLanguageFileJson();
            // this.setState({
            //     language_data: nextProps.language_data,
            // });
        }
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName ===
                "forgotPassword-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (
            nextProps.forgot_password_data !== this.props.forgot_password_data
        ) {
            if (nextProps.forgot_password_data.data.rst === "1") {
                this.setState({
                    username: "",
                    showLoadingModal: false,
                    successMsg: t(
                        "forgotPassword." +
                            nextProps.forgot_password_data.data.msg
                    ),
                });

                this.props.openModal("success-modal");
            } else {
                this.setState({
                    showLoadingModal: false,
                    errMsg: t(
                        "forgotPassword." +
                            nextProps.forgot_password_data.data.msg
                    ),
                });

                this.props.openModal("error-modal");
            }
        }
    }

    handleSubmitForgotPassword(event) {
        event.preventDefault();

        let validate = fieldChecking(
            document.getElementById("forgot-password-form")
        );
        if (validate === false) {
            return false;
        }

        this.props.openModal("forgotPassword-loading-modal");

        const data = {};

        data.email = this.state.username;
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;
        data.lang = sessionStorage.getItem("lang");

        this.props.postForgotPassword(data);
    }

    handlePassword(event) {
        this.setState({ password: event.target.value });
    }

    handleConfirmPassword(event) {
        this.setState({ confirmPassword: event.target.value });
    }

    handleUsername(event) {
        this.setState({ username: event.target.value });
    }

    handleVerificationCode(event) {
        this.setState({ verification_code: event.target.value });
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");
        this.props.history.push("/login");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    render() {
        const {
            showLoadingModal,
            showErrorModal,
            showSuccessModal,
            errMsg,
            successMsg,
        } = this.state;

        return (
            <div className="forgotPassword">
                <div className="container-fluid px-0">
                    <PageHeader
                        titleHeader="col-12 text-center"
                        navClass=""
                        historyBack="/login"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        title={t("forgotPassword.forgot_password")}
                        arrowLeft={true}
                    />

                    {showLoadingModal && (
                        <Modal
                            title=""
                            children={
                                <div className="container pt-5">
                                    <div className="row">
                                        <div className="mx-auto">
                                            <LoadingMask width={80} />
                                        </div>
                                    </div>
                                </div>
                            }
                            isShowing={showLoadingModal}
                            modalId="loading-modal"
                            modalClassName=""
                            modalClassId="forgotPassword-loading-modal"
                            modalClassContent="loading-bg"
                        />
                    )}

                    {showErrorModal && (
                        <Modal
                            title=""
                            children={
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <ErrorIcon
                                            width="100px"
                                            height="0px !important"
                                        />
                                        <h4>{errMsg}</h4>
                                    </div>
                                </div>
                            }
                            closeText=""
                            closeClassName=""
                            closeFunc=""
                            closeId=""
                            submitText="OK"
                            submitClassName="depth-red-btn"
                            submitFunc={this.handleErrorClose}
                            submitId=""
                            modalFooterClassName="custom-modal-footer"
                            isShowing={showErrorModal}
                            modalClassName=""
                            modalClassId="error-modal"
                        />
                    )}

                    {showSuccessModal && (
                        <Modal
                            title=""
                            children={
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <SuccessIcon
                                            width="100px"
                                            height="0px !important"
                                        />
                                        <h4>{successMsg}</h4>
                                    </div>
                                </div>
                            }
                            closeText=""
                            closeClassName=""
                            closeFunc=""
                            closeId=""
                            submitText="OK"
                            submitClassName="depth-blue-btn"
                            submitFunc={this.handleSuccessClose}
                            submitId=""
                            modalFooterClassName="custom-modal-footer"
                            isShowing={showSuccessModal}
                            modalClassName=""
                            modalClassId="success-modal"
                        />
                    )}

                    <div className="mt-4 container-fluid offset-md-2 col-md-8 px-0">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <form
                                        method="post"
                                        id="forgot-password-form"
                                        onSubmit={
                                            this.handleSubmitForgotPassword
                                        }
                                        className="needs-validation"
                                        noValidate
                                    >
                                        <div className="form-group panel-field">
                                            <label className="depth-gray-label text-uppercase">
                                                {t("forgotPassword.email")}
                                            </label>

                                            <StringInput
                                                id="username"
                                                name="username"
                                                valid_email="true"
                                                emailMsg={t(
                                                    "login.please_enter_valid_email"
                                                )}
                                                onChange={this.handleUsername}
                                                value={this.state.username}
                                                placeholder={t(
                                                    "forgotPassword.please_enter_email"
                                                )}
                                                className="depth-input"
                                                requiredMsg={t(
                                                    "validator.requiredMsg"
                                                )}
                                                required
                                            />
                                        </div>

                                        <div className="row text-right">
                                            <div className="col-12">
                                                <button
                                                    type="submit"
                                                    className="col-12 depth-blue-btn text-uppercase"
                                                >
                                                    {t("common.submit")}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { forgotPassword, modal, walletBalance } = state;
    return {
        forgot_password_data: forgotPassword.data,
        classIsShowing: modal.classIsShowing,
        language_data: walletBalance.getLanguageFile,
    };
};

const mapDispatchToProps = {
    postForgotPassword,
    openModal,
    closeModal,
    getLanguageFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
