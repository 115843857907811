import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { t, setLanguageFileJson } from "../../../common/translation";
import {
    getLanguageFile,
    getLanguageList,
} from "../../../store/ducks/home/actions";
import { message, Modal } from "antd";
import CaptchaMini from "captcha-mini";

import StringInput from "../../../components/input/StringInput/index";
import PasswordInput from "../../../components/input/PasswordInput/index";
import Select from "../../../components/input/Select2";
import LoadingMask from "../../../components/loadingMask";
import AlertUI from "../../../components/AlertUI";

import { postLogin } from "../../../store/ducks/login/actions";

import { verifyTwoFactorAuthentication } from "../../../store/ducks/twoFactorAuthentication/actions";
import { resendAccountActivation } from "../../../store/ducks/resendAccountActivation/actions";

import { formValidation } from "../../../common/validator/formValidation";
import {
    GenHash,
    fakeAuth,
    fieldChecking,
    hashing256,
} from "../../../common/helpers";

import GMLogo from "../../../public/v2/logo/logo-celebreneur.png";

import "./login.scss";
// import socketIOClient from "socket.io-client";

// const ENDPOINT = "http://api.digital-bank.com/ws";
const ENDPOINT = "http://172.105.120.17:4001";
// const ENDPOINT = "http://mpc-api.securelayers.cloud:4001";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            verification_code: "",
            language:
                sessionStorage.getItem("lang") === null
                    ? "en"
                    : sessionStorage.getItem("lang"),
            options: [],
            login_token: "",
            two_fa_code: "",
            captcha: "",
            isShowing: false,
            loading: false,
            errMsg: "",
            successMsg: "",
            success_visible: false,
            error_visible: false,
            twofa_visible: false,
            resend_visible: false,
        };

        this.handleLanguage = this.handleLanguage.bind(this);
        this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
        this.handleTwoFACode = this.handleTwoFACode.bind(this);
        this.handleTwoFACodeValue = this.handleTwoFACodeValue.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleResendClose = this.handleResendClose.bind(this);
        this.handleTwoFAClose = this.handleTwoFAClose.bind(this);
        this.handleResendAccountActivationClose =
            this.handleResendAccountActivationClose.bind(this);
        this.handleResendAccountActivation =
            this.handleResendAccountActivation.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.onChange = this.onChange.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `50px`);
    }

    UNSAFE_componentWillMount() {
        if (sessionStorage.getItem("lang") === null) {
            sessionStorage.setItem("lang", "en");
        }

        // const socket = socketIOClient(ENDPOINT, {transports: ['websocket']});
        //
        // socket.on("MARKET_PRICE_MPCUBNK", data => console.log(data));
        // socket.on("MARKET_DETAILS_MPCUBNK", data => console.log(data));
        // socket.on("BUY_ORDER_BOOK_MPCUBNK", data => console.log(data));
        // socket.on("SELL_ORDER_BOOK_MPCUBNK", data => console.log(data));

        if (sessionStorage.getItem("langList") === null) {
            this.props.getLanguageList();
        } else {
            this.setState({
                options: JSON.parse(sessionStorage.getItem("langList")),
            });
        }

        // setLocale(sessionStorage.getItem("lang"));
    }

    componentDidMount() {
        // socket.emit('merchant_terminal', "WAVHZ7UAJU6I")
        //         socket.on("merchant_terminal_callback", data => {
        //             console.log(data);
        //             socket.disconnect();
        //
        //         });

        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });

        formValidation("login-form");

        this.refreshCaptcha();

        if (sessionStorage.getItem("tokenExpired") === "true") {
            message.error(t("profile.token_expiry"));
            sessionStorage.setItem("tokenExpired", "false");
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.language_list !== this.props.language_list) {
            if (nextProps.language_list.rst === 1) {
                this.setState({
                    options: nextProps.language_list.data,
                });
            }
        }

        if (nextProps.language_data !== this.props.language_data) {
            setLanguageFileJson();

            this.setState({
                loading: false,
            });
        }

        // if (nextProps.language_data !== this.props.language_data) {
        //     console.log(nextProps.language_data);
        //     if (nextProps.language_data.rst === "1") {
        //         this.setState({
        //             options: nextProps.language_data.data,
        //         });

        //         sessionStorage.setItem(
        //             "langList",
        //             JSON.stringify(nextProps.language_data.data)
        //         );
        //     }
        // }

        if (nextProps.verifyTwoFactorData !== this.props.verifyTwoFactorData) {
            if (nextProps.verifyTwoFactorData.rst === "1") {
                fakeAuth.authenticate();
                sessionStorage.setItem(
                    "token",
                    nextProps.verifyTwoFactorData.data.token
                );
                sessionStorage.setItem("uname", this.state.username);

                this.props.history.push("/home");
            } else {
                this.setState({
                    errMsg: t("login." + nextProps.verifyTwoFactorData.msg),
                });
            }
        }

        if (nextProps.login_data !== this.props.login_data) {
            if (nextProps.login_data.rst === "1") {
                if (nextProps.login_data.data.twoFaStatus === "1") {
                    this.setState(
                        {
                            twofa_visible: true,
                            loading: false,
                        },
                        () => {
                            sessionStorage.setItem(
                                "placement",
                                hashing256(
                                    nextProps.profile_data.data
                                        .placementSetting,
                                    process.env.REACT_APP_GM_SECRET_KEY
                                )
                            );
                        }
                    );
                } else {
                    fakeAuth.authenticate();

                    sessionStorage.setItem("token", "123");
                    sessionStorage.setItem(
                        "accessToken",
                        nextProps.login_data.data.access_token
                    );
                    sessionStorage.setItem(
                        "refreshToken",
                        nextProps.login_data.data.refresh_token
                    );
                    // sessionStorage.setItem(
                    //     "kycStatus",
                    //     GenHash(
                    //         [nextProps.login_data.data.kyc_status, this.state.username],
                    //         process.env.REACT_APP_GM_SECRET_KEY
                    //     )
                    // );
                    sessionStorage.setItem("uname", this.state.username);
                    sessionStorage.setItem("email", this.state.username);
                    sessionStorage.setItem("lang", this.state.language);
                    sessionStorage.setItem("showAnnoucement", 0);

                    window.location.href = "/home";
                }
            } else {
                switch (nextProps.login_data.msg) {
                    case "invalid_totp":
                        this.setState({
                            loading: false,
                            twofa_visible: true,
                        });
                        break;
                    case "pleases_activate_the_user":
                        this.setState({
                            loading: false,
                            resend_visible: true,
                            errMsg: t("login." + nextProps.login_data.msg),
                        });
                        break;
                    case "failed":
                        this.setState({
                            error_visible: true,
                            errMsg: t("common.system_error"),
                            loading: false,
                        });
                        break;
                    case "system_error":
                        this.setState({
                            error_visible: true,
                            errMsg: t("common.system_error"),
                            loading: false,
                        });
                        break;
                    default:
                        this.refreshCaptcha();
                        this.setState({
                            error_visible: true,
                            errMsg: t("login." + nextProps.login_data.msg),
                            loading: false,
                        });
                        break;
                }
            }
        }

        if (
            nextProps.resendAccountActivationData !==
            this.props.resendAccountActivationData
        ) {
            if (nextProps.resendAccountActivationData.data.rst === "1") {
                this.setState({
                    success_visible: true,
                    loading: false,
                    successMsg: t(
                        "login." +
                            nextProps.resendAccountActivationData.data.msg
                    ),
                });
            } else {
                let err_msg = "";

                if (
                    nextProps.resendAccountActivationData.data.msg ===
                    "system_error"
                ) {
                    err_msg = t("common.system_error");
                } else {
                    err_msg = t(
                        "login." +
                            nextProps.resendAccountActivationData.data.msg
                    );
                }

                this.setState({
                    loading: false,
                    error_visible: true,
                    errMsg: err_msg,
                });
            }
        }
    }

    onChange(e) {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    refreshCaptcha() {
        let captcha1 = new CaptchaMini({
            length: 6,
            fontSize: 50,
            content: "1234567890",
        });
        captcha1.draw(document.querySelector("#captcha1"), (r) => {
            this.setState({ captcha: r });
        });
    }

    handleLanguage(event) {
        //  setLocale(event.target.value);

        // sessionStorage.setItem("lang", event.target.value);
        // this.setState({ language: event.target.value });
        sessionStorage.setItem("lang", event.target.value);
        this.props.getLanguageFile(sessionStorage.getItem("lang"));
        this.setState({
            language: event.target.value,
            langShow: "",
            loading: true,
        });
    }

    handleTwoFACodeValue(event) {
        this.setState({ two_fa_code: event.target.value });
    }

    handleTwoFACode(event) {
        formValidation("two-factor-auth-form");

        this.setState({
            two_fa_code: event.target.value,
        });

        event.preventDefault();

        let validate = fieldChecking(
            document.getElementById("two-factor-auth-form")
        );
        if (validate === false) {
            return false;
        }

        const data = {};

        data.username = this.state.username;
        data.password = this.state.password;
        data.lang = sessionStorage.getItem("lang");
        data.totp = this.state.two_fa_code;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({
            loading: true,
            twofa_visible: false,
        });

        this.props.postLogin(data, process.env.REACT_APP_GM_URL);
    }

    handleSubmitLogin(event) {
        event.preventDefault();

        let validate = fieldChecking(document.getElementById("login-form"));
        if (validate === false) {
            return false;
        }

        if (this.state.verification_code !== this.state.captcha) {
            this.setState({
                error_visible: true,
                errMsg: t("login.invalid_verification_code"),
                verification_code: "",
            });

            this.refreshCaptcha();
            return false;
        }

        this.setState({
            loading: true,
        });

        const data = {};

        data.username = this.state.username;
        data.password = this.state.password;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;
        data.lang = this.state.language;

        this.props.postLogin(data, process.env.REACT_APP_GM_URL);
    }

    handleClose() {
        this.setState({
            error_visible: false,
            loading: false,
        });
    }

    handleResendClose() {
        this.setState({
            resend_visible: false,
            loading: false,
        });
    }

    handleTwoFAClose() {
        this.setState({
            twofa_visible: false,
            loading: false,
        });
    }

    handleResendAccountActivationClose() {
        this.setState({
            resend_visible: false,
            loading: false,
        });
    }

    handleResendAccountActivation() {
        let data = [];
        data["email"] = this.state.username;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({
            resend_visible: false,
            loading: true,
        });

        this.props.resendAccountActivation(data, process.env.REACT_APP_GM_URL);
    }

    handleSuccessClose() {
        this.setState({
            success_visible: false,
            loading: false,
        });
    }

    render() {
        const {
            loading,
            errMsg,
            successMsg,
            resend_visible,
            twofa_visible,
            success_visible,
            error_visible,
            options,
            verification_code,
            totp,
            username,
            language,
            password,
        } = this.state;

        return (
            <div className="login-panel overflow-auto custom-height">
                <Modal
                    visible={resend_visible}
                    onOk={() => this.handleResendClose}
                    onCancel={this.handleResendClose}
                    closable={false}
                    footer={[
                        <button
                            key="resend"
                            className="col-12 depth-blue-btn text-uppercase pt-1"
                            onClick={this.handleResendAccountActivation}
                        >
                            {t("login.resend")}
                        </button>,
                    ]}
                >
                    <AlertUI
                        alertType="error"
                        msg={errMsg}
                        width="100px"
                        height="0px !important"
                    />
                </Modal>

                <Modal
                    visible={twofa_visible}
                    title={t("login.verify_your_identity")}
                    onOk={() => this.handleTwoFAClose}
                    onCancel={this.handleTwoFAClose}
                    className={`fa-modal`}
                    footer={null}
                >
                    <div className="row">
                        <form
                            id="two-factor-auth-form"
                            onSubmit={this.handleTwoFACode}
                            className="needs-validation col-12"
                            noValidate
                        >
                            <div className="col-12">
                                <p className="verify-2fa-content text-light">
                                    {t("login.please_enter_2fa_code")}:
                                </p>
                            </div>
                            <div className="col-12">
                                <input
                                    type="text"
                                    id="two_fa_code"
                                    name="two_fa_code"
                                    className="form-control depth-input"
                                    value={totp}
                                    onChange={this.handleTwoFACodeValue}
                                    required
                                />
                                <div className="invalid-feedback">
                                    {t("login.requiredMsg")}
                                </div>
                            </div>

                            <div className="col-12 mt-4 mb-4">
                                <button
                                    type="submit"
                                    className="col-12 depth-blue-btn"
                                >
                                    {t("login.continue")}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>

                <Modal
                    visible={loading}
                    title=""
                    footer={null}
                    closable={false}
                    className="LoadingModalAnt"
                >
                    <div className="container pt-5">
                        <div className="row">
                            <div className="mx-auto">
                                <LoadingMask width={80} />
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    visible={error_visible}
                    onOk={() => this.handleClose}
                    onCancel={this.handleClose}
                    closable={false}
                    footer={[
                        <button
                            key="close"
                            className="col-12 depth-blue-btn"
                            onClick={this.handleClose}
                        >
                            {t("login.OK")}
                        </button>,
                    ]}
                >
                    <AlertUI
                        alertType="error"
                        msg={errMsg}
                        width="100px"
                        height="0px !important"
                    />
                </Modal>

                <Modal
                    visible={success_visible}
                    onOk={() => this.handleSuccessClose}
                    onCancel={this.handleSuccessClose}
                    closable={false}
                    footer={[
                        <button
                            key="submit"
                            className="col-12 depth-blue-btn"
                            onClick={this.handleSuccessClose}
                        >
                            {t("login.OK")}
                        </button>,
                    ]}
                >
                    <AlertUI
                        alertType="success"
                        msg={successMsg}
                        width="100px"
                        height="0px !important"
                    />
                </Modal>

                <div className="px-3 pb-4">
                    <div className="text-right">
                        <Select
                            id="language"
                            name="language"
                            className="mt-4 ghost-lang-input"
                            options={options}
                            onChange={this.handleLanguage}
                            value={sessionStorage.getItem("lang")}
                        />
                        <span className="caret"></span>
                    </div>

                    <div className="logo-up-padding text-center mx-auto fixed-form-size pt-5">
                        <img
                            src={GMLogo}
                            alt=""
                            width="70%"
                            className="logo-down-padding"
                        />

                        <div className="px-1 mt-5">
                            <div
                                className="mx-auto"
                                style={{ backgroundColor: "transparent" }}
                            >
                                <div className="">
                                    <form
                                        id="login-form"
                                        onSubmit={this.handleSubmitLogin}
                                        className="needs-validation"
                                        autoComplete="off"
                                        noValidate
                                    >
                                        <div className="row">
                                            <div className="col-12 mb-2">
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    onChange={this.onChange}
                                                    value={username}
                                                    className="custom-field-valid txt-custom-username depth-input username-icon txt-icon-field"
                                                    valid_email="true"
                                                    emailMsg={t(
                                                        "login.please_enter_valid_email"
                                                    )}
                                                    placeholder={t(
                                                        "login.please_enter_email"
                                                    )}
                                                    requiredMsg={t(
                                                        "validator.requiredMsg"
                                                    )}
                                                    language={language}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-12">
                                                <PasswordInput
                                                    id="password"
                                                    name="password"
                                                    onChange={this.onChange}
                                                    value={password}
                                                    placeholder={t(
                                                        "login.please_enter_password"
                                                    )}
                                                    className="custom-field-valid txt-custom-password depth-input password-icon txt-icon-field"
                                                    required
                                                    requiredMsg={t(
                                                        "validator.requiredMsg"
                                                    )}
                                                    minLengthMsg={t(
                                                        "register.minLengthMsg",
                                                        {
                                                            count: 8,
                                                        }
                                                    )}
                                                    minLength="8"
                                                    maxLength="255"
                                                    maxLengthMsg={t(
                                                        "register.maxLengthMsg",
                                                        {
                                                            count: 255,
                                                        }
                                                    )}
                                                    passwordformat=""
                                                    passwordFormatMsg={t(
                                                        "register.passwordFormatMsg"
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-9 col-md-9 mb-3 align-self-end">
                                                <StringInput
                                                    id="verification_code"
                                                    name="verification_code"
                                                    onChange={this.onChange}
                                                    value={verification_code}
                                                    className="col-12 depth-input d-flex"
                                                    placeholder={t(
                                                        "login.please_enter_verification_code"
                                                    )}
                                                    requiredMsg={t(
                                                        "validator.requiredMsg"
                                                    )}
                                                    required
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 mb-2 text-right p-0">
                                                <canvas
                                                    className="border-0 col-12 pl-0"
                                                    width="auto"
                                                    id="captcha1"
                                                ></canvas>
                                            </div>
                                        </div>

                                        <button
                                            type="submit"
                                            className="col-12 depth-blue-btn"
                                        >
                                            {t("login.title")}{" "}
                                        </button>
                                    </form>
                                </div>
                            </div>

                            <div className="row text-center">
                                <div className="col-12 mt-2">
                                    <Link
                                        to="/forgotPassword"
                                        className="depth-blue-label"
                                    >
                                        <b>{t("login.forgot_password")}?</b>
                                    </Link>
                                </div>
                            </div>

                            <div className="row text-center reminder-gray-style">
                                <div className="col-12 mt-4">
                                    <p className="">
                                        {t("login.havent_create_new_wallet")}
                                    </p>
                                </div>
                            </div>

                            <Link
                                to="/register"
                                className="depth-red-btn col-12"
                            >
                                {t("login.create_now")}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        login,
        modal,
        twoFactorAuthentication,
        resendAccountActivation,
        walletBalance,
    } = state;

    return {
        login_data: login.login_data,
        classIsShowing: modal.classIsShowing,
        verifyTwoFactorData: twoFactorAuthentication.verifyTwoFactorData,
        resendAccountActivationData: resendAccountActivation.data,
        language_data: walletBalance.getLanguageFile,
        language_list: walletBalance.getLanguageList,
    };
};

const mapDispatchToProps = {
    postLogin,
    verifyTwoFactorAuthentication,
    resendAccountActivation,
    getLanguageList,
    getLanguageFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
