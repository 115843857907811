import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const purchasePinReducer = createReducer(initialState) ({

    [type.GET_MEMBER_PACKAGE]: (state, action) => ({
        ...state,
        GET_MEMBER_PACKAGE: action.payload,
    }),

    [type.POST_PURCHASE_PACKAGE]: (state, action) => ({
        ...state,
        POST_PURCHASE_PACKAGE: action.payload,
    }),

    [type.GET_MEMBER_PACKAGES]: (state, action) => ({
        ...state,
        GET_MEMBER_PACKAGES: action.payload,
    }),

    [type.POST_PURCHASE_TV_PACKAGE]: (state, action) => ({
        ...state,
        POST_PURCHASE_TV_PACKAGE: action.payload,
    }),

    [type.GET_MEMBER_CV_PACKAGES]: (state, action) => ({
        ...state,
        GET_MEMBER_CV_PACKAGES: action.payload,
    }),

});

export default purchasePinReducer;