import myGroups from "../../pages/multinaryTree";
import myGroupDetails from "../../pages/multinaryDetails";

const routes = [{
    path: "/friends/group",
    component: myGroups
},{
    path: "/friends/group/1",
    component: myGroups
},{
    path: "/friends/group/2",
    component: myGroups
},{
    path: "/friends/group/details",
    component: myGroupDetails
}];

export default routes;