import * as type from './types';
import axios from 'axios';

export const GetPinDetail = (res_data, url) => dispatch => {
    axios.post(url+ '/member/voucher/detail', {
        "code":res_data['code'],
        "token": res_data['token'],
        "username": res_data['username'],
        "hash": res_data['hash'],
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_PIN_DETAIL,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_PIN_DETAIL,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};


export const PostTopUpPin = (res_data, url) => dispatch => {
    axios.post(url+ '/member/sales/add', {
        "code":res_data['code'],
        "salesType": res_data['salesType'],
        "secondaryPassword": res_data['secondaryPassword'],
        "token": res_data['token'],
        "username": res_data['username'],
        "hash": res_data['hash'],
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_TOP_UP_PIN,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_TOP_UP_PIN,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

