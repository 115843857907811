import * as type from './types';
import axios from 'axios';

export const postAutoSellAction = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/autoSell', data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_AUTO_SELL_ACTION,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_AUTO_SELL_ACTION,
            payload: { "rst":"0", "msg":"system_error"}
        });
    });
};

export const getAutoSellStatement = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/autoSell/statements', data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_AUTO_SELL_STATEMENTS,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_AUTO_SELL_STATEMENTS,
            payload: { "rst":"0", "msg":"system_error"}
        });
    });
};
