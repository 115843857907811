import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Progress, Tabs, Select } from "antd";
import { t, setLanguageFileJson } from "../../common/translation";
import "./orderDetails.scss";
import {
    getMarketList,
    // getOrderTransaction,
    getMemberTradeHistory,
    getMemberOpenOrder,
    getOrderDetails,
} from "../../store/ducks/market/actions";
import StatementOpen from "../../public/v3/icon/statement-open-order.svg";
import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeaderV2";
import HyperModal from "../../components/HyperModal";

const InputGroup = Input.Group;
class OrderDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order_detail: {
                total_amount_display: 0,
                action_type: "",
                total_cancel_quantity_display: 0,
            },
            market_list: "",
            loading_visible: true,
        };
    }

    componentDidMount() {
        let data1 = {};
        data1.page = 1;
        this.props.getMarketList(data1);

        if (this.props.location.state && this.props.location.state.item) {
            let data = {};
            data.doc_no = this.props.location.state.item.doc_no;
            this.props.getOrderDetails(data);
        } else {
            window.location.href = "/market/history";
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        //         action_type: "MATCH"
        // action_type_desc: "match"
        // matched_rate_color_code: "#13B126"
        // total_quantity_display: "4.00000000"
        // trans_date_time: "2021-04-13 11:36:00"
        // unit_price_display: "1.50000000"
        if (nextProps.member_order_detail !== this.props.member_order_detail) {
            if (nextProps.member_order_detail.data.rst == "1") {
                let order_details =
                    nextProps.member_order_detail.data.data.trade_detail.map(
                        (item) => (
                            <tr className="text-white font-12">
                                <td>{item.trans_date_time}</td>
                                <td className="text-right">
                                    {item.trading_fee_display}
                                </td>
                                <td className="text-right">
                                    {item.unit_price_display}
                                </td>
                                <td
                                    className="text-right"
                                    style={{
                                        color: item.matched_rate_color_code,
                                    }}
                                >
                                    {item.total_quantity_display}
                                </td>
                            </tr>
                        )
                    );
                this.setState({
                    loading_visible: false,
                    order_details: order_details,
                    order_detail: nextProps.member_order_detail.data.data,
                });
            }
        }
    }

    render() {
        return (
            <div className="order-detail vh-100">
                <HyperModal
                    category="response"
                    modalVisible={this.state.loading_visible}
                    modalType="loading"
                    footer={[]}
                />
                <div className="offset-md-3 col-md-6 px-0">
                    <PageHeader
                        title={t("common.order_details")}
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        historyBack="/market/history"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        arrowLeft={true}
                        headerBanner={false}
                    />
                    <div className="bg-blue">
                        <div className="order-header col-12 p-2 d-flex text-white">
                            <div className="col-12 px-2 font-21 text-uppercase">
                                {this.state.order_detail.coin_pair}
                            </div>
                            {/* <div className="col-6 px-2 text-right font-16 align-self-center"></div> */}
                        </div>
                        <div className="order-info col-12 px-2 py-3 d-flex ">
                            <div className="col-4 px-2 font-14 text-dark-grey">
                                {t("common.type")}
                            </div>
                            <div
                                className="col-8 px-2 text-right font-16 align-self-center"
                                style={{
                                    color:
                                        this.state.order_detail.action_type ==
                                        "BUY"
                                            ? "green"
                                            : "red",
                                }}
                            >
                                {this.state.order_detail.action_type == "BUY"
                                    ? t("common.limit") + "/" + t("common.buy")
                                    : //       "/" +
                                      //  t("common.limit") +
                                      //       "/" +
                                      //       this.state.order_detail.action_type ==
                                      //   "BUY"
                                      //     ? t("common.buy")
                                      //     : t("common.sell")
                                      // : ""}
                                      t("common.limit") +
                                      "/" +
                                      t("common.sell")}
                            </div>
                        </div>
                        <div className="order-info col-12 px-2 pb-3 d-flex ">
                            <div className="col-4 px-2 font-14 text-dark-grey">
                                {t("common.filled")}/{t("common.quantity")}
                            </div>
                            <div className="col-8 px-2 text-right align-self-center">
                                <span className="font-16 text-white">
                                    {
                                        this.state.order_detail
                                            .matched_quantity_display
                                    }
                                </span>
                                <span className="font-14 text-dark-grey">
                                    /
                                    {
                                        this.state.order_detail
                                            .total_quantity_display
                                    }
                                </span>
                            </div>
                        </div>
                        {/* <div className="order-info col-12 px-2 pb-3 d-flex ">
                            <div className="col-6 px-2 font-14 text-dark-grey">
                                Avg.price
                            </div>
                            <div className="col-6 px-2 text-right font-16 align-self-center text-white">
                                {this.state.order_detail.matched_rate}
                            </div>
                        </div> */}
                        {parseInt(
                            this.state.order_detail
                                .total_cancel_quantity_display
                        ) !== 0 ? (
                            <div className="order-info col-12 p-2 pb-3 d-flex">
                                <div className="col-4 px-2 font-14 text-dark-grey">
                                    {t("common.cancel_quantity")}
                                </div>
                                <div className="col-8 px-2 text-right font-16 align-self-center text-white">
                                    {
                                        this.state.order_detail
                                            .total_cancel_quantity_display
                                    }
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="order-info col-12 p-2 pb-3 d-flex">
                            <div className="col-4 px-2 font-14 text-dark-grey">
                                {t("common.fee")}
                            </div>
                            <div className="col-8 px-2 text-right font-16 align-self-center text-white">
                                {this.state.order_detail.total_fee}
                            </div>
                        </div>
                        <div className="order-info col-12 p-2 pb-3 d-flex border-bottom-grey">
                            <div className="col-4 px-2 font-14 text-dark-grey">
                                {t("common.total")}
                            </div>
                            <div className="col-8 px-2 text-right font-16 align-self-center text-white">
                                {this.state.order_detail.total_amount_display}
                            </div>
                        </div>
                    </div>
                    <div className="open-order-area col-12 bg-light-dark px-0">
                        <div className="text-white p-3 open-order-title col-12 px-0">
                            <div className="col-6 px-0 text-left">
                                {t("common.trade_details")}
                            </div>
                        </div>
                        <table className="table">
                            <tbody>
                                <tr className="text-light-grey font-12 bg-blue">
                                    <th>{t("common.date")}</th>
                                    <th className="text-right">
                                        {t("common.fee")}
                                    </th>
                                    <th className="text-right">
                                        {t("common.price")}
                                    </th>
                                    <th className="text-right">
                                        {t("common.quantity")}
                                    </th>
                                </tr>

                                {this.state.order_details}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { marketData } = state;

    return {
        market_data: marketData.market_data,
        // order_list: marketData.order_data,
        mem_open_order: marketData.mem_open_order,
        mem_trade_history: marketData.mem_trade_history,
        member_order_detail: marketData.member_order_detail,
    };
};

const mapDispatchToProps = {
    getMarketList,
    // getOrderTransaction,
    getMemberOpenOrder,
    getMemberTradeHistory,
    getOrderDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
