import * as type from "./types";
import axios from "axios";
// import jwt from 'jsonwebtoken';
//
// import { GenHash } from "../../../assets/js/helpers";
// import { text } from '../../../assets/js/state/middleware/public-key';

/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Shewn
 */

export const postBuyExchange = (data) => (dispatch) => {
    axios
        .post(process.env.REACT_APP_GM_URL + "/member/exchange/buy", data, {
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.POST_EXCHANGE,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.POST_EXCHANGE,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const postSellExchange = (data) => (dispatch) => {
    axios
        .post(process.env.REACT_APP_GM_URL + "/member/exchange/sell", data, {
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.POST_EXCHANGE,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.POST_EXCHANGE,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};
export const getMarketList = (data) => (dispatch) => {
    axios
        .create({ withCredentials: false })
        .get(process.env.REACT_APP_GM_URL + "/member/exchange/markets", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.GET_MARKET_LIST,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.GET_MARKET_LIST,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const getBuyOrderBook = (data1) => (dispatch) => {
    data1.quantitative = "high";
    data1.call = "buy";

    axios
        .create({ withCredentials: false })
        .get(process.env.REACT_APP_GM_URL + "/member/exchange/order/book/buy", {
            params: data1,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.GET_BUY_ORDER_BOOK,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.GET_BUY_ORDER_BOOK,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const getTradingMatch = (param) => (dispatch) => {
    axios
        .create({ withCredentials: false })
        .get(
            process.env.REACT_APP_GM_URL + "/member/exchange/order/match/list",
            {
                params: param,
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.GET_ORDER_MATCH_LIST,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.GET_ORDER_MATCH_LIST,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const getSellOrderBook = (param) => (dispatch) => {
    param.quantitative = "low";
    param.call = "sell";

    axios
        .create({ withCredentials: false })
        .get(
            process.env.REACT_APP_GM_URL + "/member/exchange/order/book/sell",
            {
                params: param,
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.GET_SELL_ORDER_BOOK,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.GET_SELL_ORDER_BOOK,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

// export const getOrderTransaction = (data) => (dispatch) => {
//     let response = [];
//     response["data"] = [
//         {
//             amount: "123",
//             price: "2120.20",
//         },
//         {
//             amount: "0.333",
//             price: "567.20",
//         },
//         {
//             amount: "0.235",
//             price: "123.20",
//         },
//         {
//             amount: "123",
//             price: "2120.20",
//         },
//         {
//             amount: "0.333",
//             price: "567.20",
//         },
//         {
//             amount: "0.235",
//             price: "123.20",
//         },
//         {
//             amount: "123",
//             price: "2120.20",
//         },
//         {
//             amount: "0.333",
//             price: "567.20",
//         },
//         {
//             amount: "0.235",
//             price: "123.20",
//         },
//         {
//             amount: "123",
//             price: "2120.20",
//         },
//         {
//             amount: "0.333",
//             price: "567.20",
//         },
//         {
//             amount: "0.235",
//             price: "123.20",
//         },

//         {
//             amount: "123",
//             price: "2120.20",
//         },
//         {
//             amount: "0.333",
//             price: "567.20",
//         },
//         {
//             amount: "0.235",
//             price: "123.20",
//         },
//         {
//             amount: "123",
//             price: "2120.20",
//         },
//         {
//             amount: "0.333",
//             price: "567.20",
//         },
//         {
//             amount: "0.235",
//             price: "123.20",
//         },
//     ];
//     dispatch({
//         type: type.GET_ORDER_LIST,
//         payload: response,
//     });
// };
export const getMemberOpenOrder = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/exchange/orders", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.GET_MEMBER_OPEN_ORDER,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.GET_MEMBER_OPEN_ORDER,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const getMemberTradeHistory = (data) => (dispatch) => {
    // let response = [];

    axios
        .get(process.env.REACT_APP_GM_URL + "/member/exchange/trade/history", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.GET_MEMBER_TRADE_HISTORY,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.GET_MEMBER_TRADE_HISTORY,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};
export const getMemberOpenHistory = (data) => (dispatch) => {
    // let response = [];

    axios
        .get(process.env.REACT_APP_GM_URL + "/member/exchange/order/history", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.GET_MEMBER_ORDER_HISTORY,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.GET_MEMBER_ORDER_HISTORY,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const postCancelOrder = (data) => (dispatch) => {
    axios
        .put(
            process.env.REACT_APP_GM_URL +
                "/member/exchange/cancel/" +
                data.doc_no,
            data,
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            // axios
            //     .put(
            //         process.env.REACT_APP_GM_URL + "/member/exchange/cancel/" + doc_no,
            //         {
            //             headers: {
            //                 "X-Authorization":
            //                     "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
            //                 Authorization: sessionStorage.getItem("accessToken"),
            //             },
            //         }
            //     )
            //     .then((response) => {
            dispatch({
                type: type.POST_MEMBER_CANCEL_ORDER,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.POST_MEMBER_CANCEL_ORDER,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};
export const getOrderDetails = (data) => (dispatch) => {
    // let response = [];

    axios
        .get(process.env.REACT_APP_GM_URL + "/member/exchange/order/detail", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.GET_MEMBER_ORDER_DETAIL,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.GET_MEMBER_ORDER_DETAIL,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const getTradingView = (param) => (dispatch) => {
    axios
        .create({ withCredentials: false })
        .get(
            process.env.REACT_APP_GM_URL +
                "/member/exchange/trading-view/chart",
            {
                params: param,
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.GET_TRADING_VIEW,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.GET_TRADING_VIEW,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};
