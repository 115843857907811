import * as type from './types';
import axios from 'axios';
// import jwt from 'jsonwebtoken';
//
// import { GenHash } from "../../../assets/js/helpers";
// import { text } from '../../../assets/js/state/middleware/public-key';

/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Shewn
 */
export const postNetwork = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/network', {
        "token": data['token'],
        "username": data['username'],
        "lang": data['lang'],
    }, {
        headers: {
            'X-authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_NETWORK,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_NETWORK,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postRedeemRequest = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/multinary/redeem', {
        "token": data['token'],
        "email": data['email'],
        "lang": data['lang'],
    }, {
        headers: {
            'X-authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_REDEEM_REQUEST,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_REDEEM_REQUEST,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

