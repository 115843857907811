export const formValidation = (form_name) => {

    let form = document.getElementById(form_name);

    if (form === null) {
        return;
    }

    form.addEventListener('submit', (e) => {
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');
        }
    });
};

export const validateDisplay = rules => {

    const validClass = rules.validity === '' ? '' : rules.validity ? 'is-invalid' : 'is-valid' ;

    return [ validClass, rules.message ];
};