import React, { Component } from "react";
import { setLocale, t } from "react-i18nify";
import { message, Input } from "antd";
import connect from "react-redux/es/connect/connect";

import PasswordInput from "../../../components/input/PasswordInput";
import NumberInput from "../../../components/input/NumberInput";
import {
    fieldChecking,
    GenHash,
    slice_decimal_wt_rounding,
} from "../../../common/helpers";
import TabFooter from "../../../components/TabFooter";
import ConfirmationIcon from "../../../components/ConfirmationAlert";
import { formValidation } from "../../../common/validator/formValidation";

import {
    PostPurchasePackage,
    GetMemberCvPackages,
} from "../../../store/ducks/purchasePin/actions";

import "./cvProgram.scss";
import AlertUI from "../../../components/AlertUI";
import PropTypes from "prop-types";
import HyperModal from "../../../components/HyperModal";

class CvProgram extends Component {
    constructor(props) {
        super(props);

        this.state = {
            member_package: [],
            sub_total: "0.00",
            paymentType: "USDT",
            paymentTypeOptions: ["USDT"],
            secondaryPassword: "",
            purchaseQty: {},
            purchasePackage: [],
            purchaseTokenAmount: "",
            post_member_package: [],
            screenHeight: window.innerHeight - (48 + 50),
            loading: false,
            showLoadingModal: this.props.showLoadingModal,
            showErrorModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            errMsg: "",
            errType: "",
            successHeaderMsg: "",
            purchasePackageName: [],
            confirmationPackage: "",
            isvalidClsName: "qty",
            final_amount: 0,
            confirmationHeaderMsg: "",
            usdt_val: 0,
            payment_arry: [],
            validate_status: [],
            convert_arr: [],
            payment_method: [],
            payment_info: [],
            modal_visible: false,
            loading_visible: this.props.loading_visible,
            modal_type: "",
            modal_msg: "",
            confirm_visible: false,
            t_packages: [],
        };

        this.handlePurchaseQty = this.handlePurchaseQty.bind(this);
        this.handleSecondaryPassword = this.handleSecondaryPassword.bind(this);
        this.handlePurchasePin = this.handlePurchasePin.bind(this);
        this.handleSelectPaymentType = this.handleSelectPaymentType.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleSubmitPin = this.handleSubmitPin.bind(this);
        this.handleNullQty = this.handleNullQty.bind(this);
        this.handleTopupAmount = this.handleTopupAmount.bind(this);
        this.handlePayment = this.handlePayment.bind(this);

        let data = {};

        data.email = sessionStorage.getItem("email");
        data.lang = sessionStorage.getItem("lang");
        this.props.GetMemberCvPackages(data);
    }

    componentDidMount() {
        formValidation("purchase-pin-form");
        setLocale(sessionStorage.getItem("lang"));
        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.post_member_package !== this.props.post_member_package) {
            if (nextProps.post_member_package.rst === "1") {
                this.setState({
                    sub_total: 0,
                    secondaryPassword: "",
                    loading_visible: false,
                    modal_type: "success",
                    modal_visible: true,
                    modal_msg:
                        this.state.confirmationPackage !== ""
                            ? t("purchasePin.congrats_to_be") +
                              t("purchasePin." + this.state.confirmationPackage)
                            : t("purchasePin.topup_success"),
                });

                // let purchaseQtyArray = document.getElementsByName("purchaseQty[]");
                //
                // for(let purchaseQtyKey = 0; purchaseQtyKey < purchaseQtyArray.length; purchaseQtyKey++){
                //     purchaseQtyArray[purchaseQtyKey].value = 0;
                //
                // }
            } else {
                this.setState({
                    modal_msg: nextProps.post_member_package.msg,
                    modal_type: "error",
                    modal_visible: true,
                    loading_visible: false,
                });
            }
        }

        if (nextProps.packages_detail !== this.props.packages_detail) {
            if (nextProps.packages_detail.rst === "1") {
                this.setState({
                    loading_visible: false,
                    member_package: nextProps.packages_detail.data.packages,
                    payment_arry:
                        nextProps.packages_detail.data.payment_methods,
                });
            } else {
                this.setState({
                    modal_msg: nextProps.packages_detail.msg,
                    modal_type: "error",
                    modal_visible: true,
                    loading_visible: false,
                });
            }
        }
    }

    updateHeight() {
        this.setState({ screenHeight: window.innerHeight - (48 + 80) });
    }

    handleNullQty(e) {
        if (e.target.value === "") {
            e.target.value = "0";
        }
    }

    handlePayment(e, wallet_type, live_price) {
        let paymentInfo = this.state.payment_info;
        let convertInfo = this.state.convert_arr;
        let amount = 0;
        let grand = 0;
        let validate_status = this.state.validate_status;
        let final_amount = this.state.final_amount;

        if (isNaN(e.target.value) || e.target.value < 0) {
            return;
        }

        paymentInfo[wallet_type] = e.target.value;

        let wallet_payment_info = e.target.value === "" ? "0" : e.target.value;

        convertInfo[wallet_type] = slice_decimal_wt_rounding(
            parseFloat(wallet_payment_info) / parseFloat(live_price)
        );

        this.state.payment_arry.map(function (item, index) {
            amount = 0;

            item.map(function (item2, index2) {
                for (let key in paymentInfo) {
                    if (key === item2.asset_code) {
                        amount += parseInt(paymentInfo[key]);
                        grand += parseInt(paymentInfo[key]);
                    }
                }
            });

            if (
                final_amount * item[0].min_payment_percent > amount ||
                final_amount * item[0].max_payment_percent < amount
            ) {
                validate_status[index] = "invalid-amt";
            } else {
                validate_status[index] = "valid-amt";
            }
        });

        if (grand > final_amount) {
            for (let key in validate_status) {
                validate_status[key] = "invalid-amt";
            }
        }

        this.setState({
            payment_info: paymentInfo,
            convert_arr: convertInfo,
            validate_status: validate_status,
        });
    }

    handleTopupAmount(e) {
        if (
            isNaN(e.target.value) ||
            e.target.value < 0 ||
            e.target.value.indexOf(".") !== -1
        ) {
            return;
        }

        this.setState({
            purchaseTokenAmount: e.target.value,
            final_amount:
                parseFloat(this.state.sub_total) + parseFloat(e.target.value),
        });
    }

    handlePurchaseQty(e, member_package) {
        let purchasePackage = this.state.purchasePackage;
        let sub_total = 0;
        let topup_amt = 0;

        if (
            isNaN(e.target.value) ||
            e.target.value < 0 ||
            e.target.value.indexOf(".") !== -1
        ) {
            return;
        }

        purchasePackage[member_package.display_code] = {
            pkgCode: member_package.display_code,
            pkgName: member_package.display_name,
            qty: e.target.value,
            price: member_package.price,
        };

        for (let key in purchasePackage) {
            sub_total += purchasePackage[key].qty * purchasePackage[key].price;
        }

        if (!isNaN(parseInt(this.state.purchaseTokenAmount))) {
            topup_amt = parseInt(this.state.purchaseTokenAmount);
        }

        this.setState({
            sub_total: sub_total,
            purchasePackage: purchasePackage,
            final_amount: sub_total + topup_amt,
        });
    }

    handleSecondaryPassword(event) {
        this.setState({
            secondaryPassword: event.target.value,
        });
    }

    handleSelectPaymentType(event) {
        this.setState({
            paymentType: event.target.value,
        });
    }

    async handlePurchasePin(event) {
        event.preventDefault();

        let validate = fieldChecking(
            document.getElementById("purchase-pin-form")
        );
        console.log(validate);

        if (validate === false) {
            return false;
        }

        let packageName = [];

        let totalQty = 0;

        let i = 0;

        let purchasePackagesName = this.state.purchasePackage;

        for (let key in purchasePackagesName) {
            if (purchasePackagesName[key].qty > 0) {
                totalQty += parseInt(purchasePackagesName[key].qty);
                packageName[i] = purchasePackagesName[key];
                i++;
            }
        }

        if (totalQty > 10) {
            message.error(t("purchasePin.qty_more_than_10"));
            return;
        }

        if (packageName.length > 1) {
            this.setState({
                modal_msg: t(
                    "purchasePin.please_purchase_each_package_separately"
                ),
                modal_type: "error",
                modal_visible: true,
                loading_visible: false,
            });
            return;
        }

        if (this.state.purchaseTokenAmount > 0 && packageName.length > 0) {
            this.setState({
                modal_msg: t("purchasePin.please_purchase_separately"),
                modal_type: "error",
                modal_visible: true,
                loading_visible: false,
            });
            return;
        }

        if (
            this.state.purchaseTokenAmount > 0 &&
            this.state.purchaseTokenAmount % 10 !== 0
        ) {
            this.setState({
                modal_msg: t("validator.block_of_10"),
                modal_type: "error",
                modal_visible: true,
                loading_visible: false,
            });
            return;
        }

        if (packageName.length > 0) {
            let pay_amount = 0;

            for (let key in this.state.payment_info) {
                pay_amount += parseInt(this.state.payment_info[key]);
            }

            if (this.state.final_amount !== pay_amount) {
                this.setState({
                    modal_msg: t("purchasePin.invalid_payment_amount"),
                    modal_type: "error",
                    modal_visible: true,
                    loading_visible: false,
                });
                return;
            }

            this.setState({
                confirmationHeaderMsg:
                    t("purchasePin.confirmation_purchase_package_header_msg") +
                    t("purchasePin." + packageName.slice(-1)[0]["pkgName"]),
                confirmationPackage: packageName.slice(-1)[0]["pkgName"],
                modal_type: "normal",
                t_packages: packageName,
                confirm_visible: true,
            });
            return;
        }

        this.setState({
            confirmationHeaderMsg:
                packageName.length > 0
                    ? t("purchasePin.confirmation_full_header_msg", {
                          package: packageName.slice(-1)[0]["pkgName"],
                          amount: this.state.purchaseTokenAmount,
                      })
                    : t("purchasePin.confirmation_topup_header_msg"),
            modal_type: "normal",
            confirm_visible: true,
        });
    }

    handleSubmitPin() {
        let data = {};

        let username = sessionStorage.getItem("email");
        let token = sessionStorage.getItem("token");
        let packages = this.state.t_packages;
        let paymentInfo = this.state.payment_info;
        let paymentArr = [];

        for (var key in paymentInfo) {
            paymentArr.push({ walletTypeCode: key, amount: paymentInfo[key] });
        }

        data.packages = JSON.stringify(packages);
        data.walletTypes = JSON.stringify(paymentArr);
        data.username = username;
        data.token = token;
        data.totPayAmount = this.state.sub_total;
        data.secondaryPassword = this.state.secondaryPassword;
        data.autoPlacePin = "1";
        data.topupAmount = this.state.purchaseTokenAmount;
        data.lang = sessionStorage.getItem("lang");

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({
            confirm_visible: false,
            loading_visible: true,
        });

        this.props.PostPurchasePackage(data, process.env.REACT_APP_GM_URL);
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.nav("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    render() {
        const {
            validate_status,
            payment_info,
            final_amount,
            secondaryPassword,
            confirm_visible,
            loading_visible,
            modal_visible,
            modal_type,
            modal_msg,
            successHeaderMsg,
        } = this.state;

        const handlePaymentEvent = this.handlePayment;

        const handleModalClose = this.handleModalClose;

        const convert_arr = this.state.convert_arr;

        let length;

        const payments = this.state.payment_arry.map(function (item, i) {
            length = item.length;

            return item.map(function (detail, j) {
                // console.log(length, j);
                if (i === 0) {
                    // console.log(detail.min_payment_percent)
                    if (length !== j + 1) {
                        return (
                            <div
                                className="col-12 mt-1"
                                key={detail.asset_code}
                            >
                                {/*<NumberInput*/}
                                {/*    name={`payments[${detail.asset_code}]`}*/}
                                {/*    onChange={(e) => handlePaymentEvent(e, detail.asset_code, 1)}*/}
                                {/*    className={`txt-custom-bg txt-custom-${detail.asset_code.toLowerCase()} ${validate_status[i]}`}*/}
                                {/*    placeholder="0.00"*/}
                                {/*    data={detail.asset_code}*/}
                                {/*/>*/}
                                <Input
                                    name={`payments[${detail.asset_code}]`}
                                    defaultValue={""}
                                    placeholder="0.00"
                                    className={`text-light ${validate_status[i]}`}
                                    value={payment_info[detail.asset_code]}
                                    onChange={(e) =>
                                        handlePaymentEvent(
                                            e,
                                            detail.asset_code,
                                            1
                                        )
                                    }
                                    suffix={detail.asset_name}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <>
                                <div
                                    className="col-12 mt-1"
                                    key={detail.asset_code}
                                >
                                    {/*<NumberInput*/}
                                    {/*    name={`payments[${detail.asset_code}]`}*/}
                                    {/*    onChange={(e) => handlePaymentEvent(e, detail.asset_code, 1)}*/}
                                    {/*    className={`txt-custom-bg txt-custom-${detail.asset_code.toLowerCase()} ${validate_status[i]}`}*/}
                                    {/*    placeholder="0.00"*/}
                                    {/*    data={detail.asset_code}*/}
                                    {/*/>*/}
                                    <Input
                                        name={`payments[${detail.asset_code}]`}
                                        defaultValue={""}
                                        placeholder="0.00"
                                        className={`text-light ${validate_status[i]}`}
                                        value={payment_info[detail.asset_code]}
                                        onChange={(e) =>
                                            handlePaymentEvent(
                                                e,
                                                detail.asset_code,
                                                1
                                            )
                                        }
                                        suffix={detail.asset_name}
                                    />
                                </div>
                                {validate_status[i] === "invalid-amt" && (
                                    <div className="col-12">
                                        <p className="subscribe-note">
                                            {t(
                                                "validator.amount_should_more_than"
                                            )}{" "}
                                            80%.
                                        </p>
                                    </div>
                                )}
                            </>
                        );
                    }
                } else {
                    if (length !== j + 1) {
                        return (
                            <>
                                <div className="mt-1 col-12">
                                    <div className="row">
                                        <div className="col-5 text-right text-roboto-bold pr-0">
                                            <input
                                                type="number"
                                                className={`form-control remove-placeholder depth-input border-right-0 bg-transparent txt-custom-bg txt-custom-usdt ${validate_status[i]}`}
                                                name={`payments[${detail.asset_code}]`}
                                                onChange={(e) =>
                                                    handlePaymentEvent(
                                                        e,
                                                        detail.asset_code,
                                                        detail.avg_price
                                                    )
                                                }
                                                placeholder="0.00"
                                            />
                                        </div>
                                        <div className="col-2 text-right text-roboto-bold pl-0 pr-0">
                                            <input
                                                type="number"
                                                className={`form-control middle depth-input border-right-0 border-left-0 rounded-0 bg-transparent ${validate_status[i]}`}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-5 text-right text-roboto-bold pl-0">
                                            <input
                                                type="number"
                                                className={`form-control remove-placeholder depth-input border-left-0 bg-transparent txt-custom-bg txt-custom-${detail.asset_code.toLowerCase()} ${
                                                    validate_status[i]
                                                }`}
                                                name={`payments[${detail.asset_code}]`}
                                                placeholder="0.00"
                                                value={
                                                    convert_arr[
                                                        detail.asset_code
                                                    ] === undefined
                                                        ? "0"
                                                        : convert_arr[
                                                              detail.asset_code
                                                          ]
                                                }
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className="text-light">
                                        {t(
                                            "validator." +
                                                detail.asset_code +
                                                "_balance"
                                        )}
                                        : {detail.current_balance}
                                    </p>
                                </div>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <div className="mt-1 col-12">
                                    {/*<div className="row">*/}
                                    {/*<div className="col-5 text-right text-roboto-bold pr-0">*/}
                                    {/*    <input*/}
                                    {/*        type="number"*/}
                                    {/*        className={`form-control remove-placeholder depth-input border-right-0 bg-transparent txt-custom-bg txt-custom-usdt ${validate_status[i]}`}*/}
                                    {/*        name={`payments[${detail.asset_code}]`}*/}
                                    {/*        onChange={(e) => handlePaymentEvent(e, detail.asset_code, detail.avg_price)}*/}
                                    {/*        placeholder="0.00"*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    {/*<div className="col-2 text-right text-roboto-bold pl-0 pr-0">*/}
                                    {/*    <input type="number"*/}
                                    {/*           className={`form-control middle depth-input border-right-0 border-left-0 rounded-0 bg-transparent ${validate_status[i]}`}*/}
                                    {/*           disabled*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    {/*<div className="col-5 text-right text-roboto-bold pl-0">*/}
                                    {/*    <input*/}
                                    {/*        type="number"*/}
                                    {/*        className={`form-control remove-placeholder depth-input border-left-0 bg-transparent txt-custom-bg txt-custom-${detail.asset_code.toLowerCase()} ${validate_status[i]}`}*/}
                                    {/*        name={`payments[${detail.asset_code}]`}*/}
                                    {/*        placeholder="0.00"*/}
                                    {/*        value={convert_arr[detail.asset_code] === undefined ? "0" : convert_arr[detail.asset_code]}*/}
                                    {/*        disabled*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <Input
                                        name={`payments[${detail.asset_code}]`}
                                        defaultValue={""}
                                        placeholder="0.00"
                                        className={`text-light ${validate_status[i]}`}
                                        value={payment_info[detail.asset_code]}
                                        onChange={(e) =>
                                            handlePaymentEvent(
                                                e,
                                                detail.asset_code,
                                                detail.avg_price
                                            )
                                        }
                                        suffix={detail.asset_name}
                                    />
                                    {/*</div>*/}
                                </div>
                                <div className="col-12">
                                    <p className="text-light">
                                        {t(
                                            "validator." +
                                                detail.asset_code +
                                                "_balance"
                                        )}
                                        : {detail.current_balance}
                                    </p>
                                </div>
                                {validate_status[i] === "invalid-amt" && (
                                    <div className="col-12">
                                        <p className="subscribe-note">
                                            {t(
                                                "validator.amount_should_less_than"
                                            )}{" "}
                                            20%.
                                        </p>
                                    </div>
                                )}
                            </>
                        );
                    }
                }
            });
        });

        return (
            <div className="purchasePin-panel pb-5">
                <div className="overflow-auto col-md-8 offset-md-2 px-0">
                    <HyperModal
                        category="normal"
                        modalVisible={confirm_visible}
                        onOk={this.handleSubmitPin}
                        footer={
                            <div className="col-12 text-center">
                                <button
                                    key="submit"
                                    className="col-3 btn btn-primary"
                                    onClick={this.handleSubmitPin}
                                >
                                    {t("common.submit")}
                                </button>
                                <button
                                    key="submit"
                                    className="col-3 btn btn-danger"
                                    onClick={(e) =>
                                        this.handleModalClose(e, "confirm")
                                    }
                                >
                                    {t("purchasePin.modal_close")}
                                </button>
                            </div>
                        }
                        modalContent={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>
                                        {this.state.confirmationHeaderMsg} ?
                                    </h4>
                                    <p>{t("purchasePin.confirmation_msg")}</p>
                                </div>
                            </div>
                        }
                        onCancel={this.handleModalClose}
                        closable={false}
                    ></HyperModal>

                    <HyperModal
                        category="response"
                        modalVisible={loading_visible}
                        modalType="loading"
                        footer={[]}
                    />

                    <HyperModal
                        category="response"
                        modalVisible={modal_visible}
                        modalType={modal_type}
                        modalMsg={modal_msg}
                        modalCloseFunc={this.handleModalClose}
                        cancelFunc={this.handleModalClose}
                    />

                    <div className="mt-2">
                        <form
                            id="purchase-pin-form"
                            onSubmit={this.handlePurchasePin}
                            className="needs-validation col-12 px-0"
                            noValidate
                        >
                            {this.state.member_package.map(
                                (member_package, index) => {
                                    // if (parseInt(member_package.packageId) > parseInt(sessionStorage.getItem('tierId'))){
                                    let qty =
                                        this.state.purchasePackage[
                                            member_package.display_code
                                        ] === undefined
                                            ? ""
                                            : this.state.purchasePackage[
                                                  member_package.display_code
                                              ].qty;
                                    return (
                                        <div className="mt-1" key={index}>
                                            <div className="card mx-auto">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-7">
                                                            <div className="col-12 px-0">
                                                                <p className="purchase-pin-sub-title px-0 mb-0">
                                                                    {
                                                                        member_package.display_name
                                                                    }
                                                                </p>
                                                                <span className="purchase-pin-price-tag">
                                                                    {t(
                                                                        "purchasePin.price"
                                                                    )}{" "}
                                                                </span>
                                                                <span className="purchase-pin-price">
                                                                    {
                                                                        member_package.price
                                                                    }{" "}
                                                                    USDT
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-5">
                                                            <Input
                                                                step="1"
                                                                pattern="\d+"
                                                                defaultValue={
                                                                    ""
                                                                }
                                                                className="text-light"
                                                                onChange={(e) =>
                                                                    this.handlePurchaseQty(
                                                                        e,
                                                                        member_package
                                                                    )
                                                                }
                                                                value={qty}
                                                                suffix={t(
                                                                    "purchasePin.qty_code"
                                                                )}
                                                            />
                                                            {/*<input*/}
                                                            {/*    type="number"*/}
                                                            {/*    name="purchaseQty[]"*/}
                                                            {/*    step="1"*/}
                                                            {/*    pattern="\d+"*/}
                                                            {/*    defaultValue={""}*/}
                                                            {/*    className="form-control txt-custom-bg txt-custom-qty"*/}
                                                            {/*    onChange={this.handlePurchaseQty}*/}
                                                            {/*    onBlur={this.handleNullQty}*/}
                                                            {/*    packageprice={member_package.price}*/}
                                                            {/*    packagecode={member_package.display_code}*/}
                                                            {/*    packagename={member_package.display_name}*/}
                                                            {/*    required*/}
                                                            {/*/>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                    // }
                                }
                            )}

                            <div className="mt-1">
                                <div className="card mx-auto">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="col-12 px-0">
                                                    <p className="purchase-pin-sub-title px-0 mb-0">
                                                        {t(
                                                            "purchasePin.topup_token"
                                                        )}
                                                    </p>
                                                    <div className="purchase-pin-sub-total text-grey text-roboto-italic text-uppercase">
                                                        {t(
                                                            "purchasePin.amount"
                                                        )}{" "}
                                                        (USDT{" "}
                                                        {t("purchasePin.used")})
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                {/*<NumberInput*/}
                                                {/*    type="number"*/}
                                                {/*    name="purchaseTokenAmount[USDT]"*/}
                                                {/*    className="form-control txt-custom-bg txt-custom-usdt"*/}
                                                {/*    onChange={this.handleTopupAmount}*/}
                                                {/*    onBlur={this.handleNullQty}*/}
                                                {/*    placeholder="0.00000000"*/}
                                                {/*    value={this.state.purchaseTokenAmount}*/}
                                                {/*    blockTen={t("validator.block_of_10")}*/}
                                                {/*    textMoreThan={t("validator.amount_should_more_than")}*/}
                                                {/*    // required*/}
                                                {/*/>*/}
                                                <Input
                                                    step="1"
                                                    pattern="\d+"
                                                    defaultValue={""}
                                                    className="text-light"
                                                    onChange={
                                                        this.handleTopupAmount
                                                    }
                                                    value={
                                                        this.state
                                                            .purchaseTokenAmount
                                                    }
                                                    suffix="USDT"
                                                />
                                            </div>

                                            {/*<div className="col-12 mt-2">*/}
                                            {/*<NumberInput*/}
                                            {/*type="number"*/}
                                            {/*name="purchaseTokenAmount[RW]"*/}
                                            {/*className="form-control txt-custom-bg txt-custom-rw"*/}
                                            {/*onChange={this.handleTopupAmount}*/}
                                            {/*onBlur={this.handleNullQty}*/}
                                            {/*placeholder="0.00000000"*/}
                                            {/*/>*/}
                                            {/*</div>*/}

                                            {/*{sessionStorage.getItem('placement') === hashing256(0, process.env.REACT_APP_GM_SECRET_KEY) &&*/}
                                            {/*    <div className="col-12 mt-1">*/}
                                            {/*        <div*/}
                                            {/*            className="purchase-pin-sub-total text-grey text-roboto-italic text-uppercase">{t('purchasePin.placement')}</div>*/}

                                            {/*        <input*/}
                                            {/*            type="text"*/}
                                            {/*            name="placement"*/}
                                            {/*            placeholder={t('purchasePin.please_enter_placement_email')}*/}
                                            {/*            className="form-control"*/}
                                            {/*            // required*/}
                                            {/*        />*/}
                                            {/*    </div>*/}
                                            {/*}*/}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-4 bg-black">
                                <div className="row">
                                    <div className="col-6 text-roboto-italic text-grey">
                                        {t("purchasePin.grand_amount")}
                                    </div>
                                    <div className="purchase-pin-sub-total-price col-6 text-right">
                                        {final_amount} USDT
                                    </div>
                                </div>
                            </div>

                            <div className="card mx-auto">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="text-uppercase text-grey">
                                                {t("purchasePin.payment")}
                                            </span>
                                        </div>

                                        {payments}

                                        {/*<div className="col-12">*/}
                                        {/*<NumberInput*/}
                                        {/*name="payments[USDT]"*/}
                                        {/*// value={this.state.usdt_val}*/}
                                        {/*onChange={this.handlePayment}*/}
                                        {/*className="txt-custom-bg txt-custom-usdt"*/}
                                        {/*// required*/}
                                        {/*placeholder="0.00"*/}
                                        {/*data="USDT"*/}
                                        {/*// requiredMsg={t('validator.requiredMsg')}*/}
                                        {/*/>*/}
                                        {/*</div>*/}

                                        {/*<div className="col-12 mt-2">*/}
                                        {/*<NumberInput*/}
                                        {/*name="payments[RW]"*/}
                                        {/*// value={secondaryPassword}*/}
                                        {/*// onChange={this.handleSecondaryPassword}*/}
                                        {/*className="txt-custom-bg txt-custom-rw"*/}
                                        {/*// required*/}
                                        {/*placeholder="0.00"*/}
                                        {/*// requiredMsg={t('validator.requiredMsg')}*/}
                                        {/*/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>

                            <div className="card mx-auto mt-1">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="text-uppercase text-grey">
                                                {t(
                                                    "purchasePin.secondary_password"
                                                )}
                                            </span>
                                        </div>

                                        <div className="col-12">
                                            <PasswordInput
                                                id="secondary_password"
                                                name="secondary_password"
                                                value={secondaryPassword}
                                                onChange={
                                                    this.handleSecondaryPassword
                                                }
                                                className=""
                                                required
                                                placeholder={t(
                                                    "purchasePin.please_enter_trading_password"
                                                )}
                                                requiredMsg={t(
                                                    "validator.requiredMsg"
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mt-3 mb-4 px-0">
                                <button
                                    type="submit"
                                    className="col-12 depth-blue-btn text-uppercase"
                                >
                                    {t("purchasePin.submit")}
                                </button>
                            </div>

                            <TabFooter pinActive="active" />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { getMemberPackage, postPurchasePackage, modal } = state;

    return {
        get_member_package: getMemberPackage.GET_MEMBER_PACKAGE,
        classIsShowing: modal.classIsShowing,
        post_member_package: postPurchasePackage.POST_PURCHASE_PACKAGE,
        packages_detail: getMemberPackage.GET_MEMBER_CV_PACKAGES,
    };
};

const mapDispatchToProps = {
    PostPurchasePackage,
    GetMemberCvPackages,
};

export default connect(mapStateToProps, mapDispatchToProps)(CvProgram);

AlertUI.propTypes = {
    nav: PropTypes.func,
    showLoadingModal: PropTypes.bool,
};
