import React, { Component } from "react";
import { connect } from "react-redux";
import { getLanguageFile } from "../../store/ducks/home/actions";
import { t, setLanguageFileJson } from "../../common/translation";

import PageHeader from "../../components/PageHeaderV2";

import { postWalletBalance } from "../../store/ducks/home/actions";
import { getStakingHistory } from "../../store/ducks/walletDetails/actions";
import { postUpdateLang } from "../../store/ducks/profile/actions";
import DiamondOn from "../../public/images/icon-diamond-on@3x.png";
import { List, Icon } from "antd";
import "./languageChange.scss";
import { GenHash } from "../../common/helpers";
// import DiamondOff from "../../public/images/icon-diamond-off@3x.png";

class LanguageChange extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options:
                props.location.state !== undefined
                    ? props.location.state.options
                    : props.history.goBack(),
        };

        this.handleLanguage = this.handleLanguage.bind(this);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.language_data !== this.props.language_data) {
            setLanguageFileJson();
        }
    }

    UNSAFE_componentWillMount() {
        //  setLocale(sessionStorage.getItem("lang"));
    }

    handleLanguage(event, lang) {
        // console.log(lang);
        // setLocale(lang);

        sessionStorage.setItem("lang", lang);

        this.setState({
            languageSelected: lang,
        });

        const data = {};

        data.lang = lang;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postUpdateLang(data);
        this.props.getLanguageFile(sessionStorage.getItem("lang"));
    }

    render() {
        let i = 0;
        let ranking = [];

        if (this.state.rank !== "0") {
            for (i = 0; i < parseInt(this.state.rank); i++) {
                ranking.push(
                    <img
                        src={DiamondOn}
                        alt=""
                        key=""
                        width={9}
                        className="mb-1 mr-1"
                    />
                );
            }
        }
        const data = this.state.options;
        // console.log(data);
        return (
            <div className="languageChange">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack={"/profile"}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("profile.languageChange")}
                    arrowLeft={true}
                />

                <div className="px-0 mt-1 offset-md-2 col-md-8">
                    <div className="mx-auto shadow">
                        <List
                            dataSource={data}
                            renderItem={(item) => (
                                <List.Item
                                    className="col-12"
                                    onClick={(e) =>
                                        this.handleLanguage(e, item.value)
                                    }
                                >
                                    <List.Item.Meta title={item.name} />
                                    {item.value ===
                                        sessionStorage.getItem("lang") && (
                                        <Icon
                                            type="check"
                                            style={{ color: "green" }}
                                        />
                                    )}
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance } = state;

    return { language_data: walletBalance.getLanguageFile };
};

const mapDispatchToProps = {
    postUpdateLang,
    getLanguageFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageChange);
