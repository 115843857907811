import Deposit from "../../pages/transaction/deposit";
import Withdrawal from "../../pages/transaction/withdrawal";
import Transfer from "../../pages/transaction/transfer";
import Staking from "../../pages/transaction/staking";
import TopUp from "../../pages/transaction/topup";
// import GiftCard from "../../pages/transaction/GiftCard";
import productDetails from "../../pages/productDetails";
import myOrders from "../../pages/myOrders";
import orderDetails from "../../pages/orderDetails/index-old";
import myCart from "../../pages/myCart";
import deliveryDetails from "../../pages/deliveryDetails";
import confirmPurchase from "../../pages/confirmPurchase";
import completePurchase from "../../pages/completePurchase";
import Convert from "../../pages/emallConvert";

import addDeliveryDetails from "../../pages/addDeliveryDetails";
import editDeliveryDetails from "../../pages/editDeliveryDetails";

import addressList from "../../pages/addressList";
import addressForm from "../../pages/addressForm";

const routes = [
    {
        path: "/deposit",
        component: Deposit,
    },
    {
        path: "/transfer",
        component: Transfer,
    },
    {
        path: "/withdrawal",
        component: Withdrawal,
    },
    {
        path: "/emall/convert",
        component: Convert,
    },
    {
        path: "/staking",
        component: Staking,
    },
    {
        path: "/topup",
        component: TopUp,
    },
    {
        path: "/emall/product-details",
        component: productDetails,
    },
    {
        path: "/emall/my-orders",
        component: myOrders,
    },
    {
        path: "/emall/order-details/:doc_no",
        component: orderDetails,
    },
    {
        path: "/emall/shopping-cart",
        component: myCart,
    },
    {
        path: "/emall/delivery/add",
        component: addDeliveryDetails,
    },
    {
        path: "/emall/delivery/edit",
        component: editDeliveryDetails,
    },
    {
        path: "/emall/delivery",
        component: deliveryDetails,
    },
    {
        path: "/emall/confirm-purchase",
        component: confirmPurchase,
    },
    {
        path: "/emall/complete-purchase",
        component: completePurchase,
    },
    {
        path: "/address/list",
        component: addressList,
    },
    {
        path: "/address/form",
        component: addressForm,
    },
];

export default routes;
