import * as type from './types';
import axios from 'axios';

export const postRewards = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/reward', {
        "email": data['email'],
        "periodFrom": data['date_from'],
        "periodTo": data['date_to'],
        "langCode": data['lang']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_REWARDS,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_REWARDS,
            payload: {"rst":"0","msg":"system_error"}
        });
    })
};

export const postRewardsList = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/reward/list', {
        "email": data['email'],
        "periodFrom": data['date_from'],
        "periodTo": data['date_to'],
        "rwdType": data['rwdType'],
        "viewType": data['viewType'],
        "token": data['token'],
        "page": data['page']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_REWARDS,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_REWARDS,
            payload: {"rst":"0","msg":"system_error"}
        });
    })
};
