import React, { Component } from "react";
//import { setLocale, t } from "react-i18nify";
import { t } from "../../common/translation";
import connect from "react-redux/es/connect/connect";

import { Input, Select, Tabs, message, Icon, Button } from "antd";
import Tree from "react-vertical-tree";
import PageHeader from "../../components/PageHeaderV2";

import {
    getProfile,
    postAutoPlacement,
    postMemberPlacement,
    getPlacementDetails,
    getPlacementTreeData,
    getPlacementNodes,
} from "../../store/ducks/profile/actions";

import "./placement.scss";
import { GenHash } from "../../common/helpers";
import StringInput from "../../components/input/StringInput";
import Modal from "../../components/modal";
import LoadingMask from "../../components/loadingMask";
import SortableTree, { getTreeFromFlatData } from "react-sortable-tree";
import "react-sortable-tree/style.css";
import SelectInput from "../../components/input/Select2";

import { getUsernameByEmail } from "../../store/ducks/transfer/actions";

const { Option } = Select;

class Placement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            placementSetting: false,
            sponsor_email: "",
            placement_email: "",
            total_count: "0",
            showLoadingModal: true,
            sponsor_lot: 1,
            treeData: [],
            key: 1,
            assign_member: "",
            floating_arr: [],
            search_email: "",
            username: "",
            error: {},
            search_type: "email",
            expandArr: [],
            expandData: [],
        };

        this.reset = this.reset.bind(this);

        this.handleNav = this.handleNav.bind(this);
        this.callback = this.callback.bind(this);
        this.handleAutoPlacement = this.handleAutoPlacement.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNodeChange = this.handleNodeChange.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.onExpand = this.onExpand.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.updateTreeData = this.updateTreeData.bind(this);
        this.onLoadData = this.onLoadData.bind(this);

        let profileObj = {};
        profileObj.username = sessionStorage.getItem("email");
        profileObj.token = sessionStorage.getItem("token");

        let hash = GenHash(profileObj, process.env.REACT_APP_GM_SECRET_KEY);
        profileObj.hash = hash;

        this.props.getProfile(profileObj);

        let data = {};
        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");

        this.props.getPlacementDetails(data);

        let placement_data = {};
        placement_data.searchEmail = "";
        placement_data.searchLot = "";
        placement_data.searchType = this.state.search_type;
        placement_data.refresh = 1;
        this.props.getPlacementNodes(placement_data);
        this.alertNodeInfo = this.alertNodeInfo.bind(this);

        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.registerReferral = this.registerReferral.bind(this);
    }

    UNSAFE_componentWillMount() {
        // setLocale(sessionStorage.getItem("lang"));
    }

    componentDidMount() {}

    reset() {
        console.log("inHere");
        let placement_data = {};
        placement_data.recursive = "0";
        placement_data.lang = sessionStorage.getItem("lang");
        placement_data.master = true;

        this.props.getPlacementTreeData(placement_data);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.profile_data !== this.props.profile_data) {
            if (nextProps.profile_data.rst === "1") {
                this.setState({
                    placementSetting:
                        nextProps.profile_data.data.placementSetting === "1",
                    sponsor_email: nextProps.profile_data.data.email,
                    sponsor_lot: 1,
                    showLoadingModal: false,
                });
            }
        }

        if (nextProps.placement_nodes !== this.props.placement_nodes) {
            if (nextProps.placement_nodes.rst === "1") {
                const handleCheck = this.onCheck;
                if (nextProps.placement_nodes.refresh === 1) {
                    this.setState({
                        treeData: getTreeFromFlatData({
                            flatData: nextProps.placement_nodes.data.map(
                                (node) => ({
                                    ...node,
                                    name: (
                                        <div>
                                            <div className="test pl-4">
                                                {node.email} &nbsp;&nbsp;
                                                <span className="badge badge-custom">
                                                    Username: {node.nick_name}
                                                </span>
                                                {node.member_lot > 1 && (
                                                    <>
                                                        &nbsp;&nbsp;
                                                        <span className="badge badge-custom">
                                                            Lot:{" "}
                                                            {node.member_lot}
                                                        </span>
                                                        &nbsp;
                                                    </>
                                                )}
                                                {node.member_lot < 2 && (
                                                    <>
                                                        &nbsp;&nbsp;
                                                        {/*<span className='badge badge-custom'>*/}
                                                        {/*    BAT BV: 0*/}
                                                        {/*</span>*/}
                                                        {/*&nbsp;&nbsp;*/}
                                                        <span className="badge badge-custom">
                                                            CVT BV:{" "}
                                                            {node.total_bv}
                                                        </span>
                                                    </>
                                                )}
                                                {node.member_lot < 2 && (
                                                    <>
                                                        &nbsp;&nbsp;
                                                        {/*<span className='badge badge-custom'>*/}
                                                        {/*    BAT BV: 0*/}
                                                        {/*</span>*/}
                                                        {/*&nbsp;&nbsp;*/}
                                                        <span className="badge badge-custom">
                                                            Today Left:{" "}
                                                            {node.todayLeft}
                                                        </span>
                                                    </>
                                                )}
                                                {node.member_lot < 2 && (
                                                    <>
                                                        &nbsp;&nbsp;
                                                        {/*<span className='badge badge-custom'>*/}
                                                        {/*    BAT BV: 0*/}
                                                        {/*</span>*/}
                                                        {/*&nbsp;&nbsp;*/}
                                                        <span className="badge badge-custom">
                                                            Today Right:{" "}
                                                            {node.todayRight}
                                                        </span>
                                                    </>
                                                )}
                                                {node.member_lot < 2 && (
                                                    <>
                                                        &nbsp;&nbsp;
                                                        {/*<span className='badge badge-custom'>*/}
                                                        {/*    BAT BV: 0*/}
                                                        {/*</span>*/}
                                                        {/*&nbsp;&nbsp;*/}
                                                        <span className="badge badge-custom">
                                                            Balance Bv:{" "}
                                                            {node.balanceBv}
                                                        </span>
                                                    </>
                                                )}
                                                {node.member_lot < 2 && (
                                                    <>
                                                        &nbsp;
                                                        <a
                                                            target="_blank"
                                                            href={
                                                                process.env
                                                                    .REACT_APP_DOMAIN +
                                                                "/register/friend?placement=" +
                                                                node.email
                                                            }
                                                        >
                                                            <button className="btn btn-danger btn-sm">
                                                                Register
                                                            </button>
                                                        </a>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ),
                                    id: node.member_id,
                                    parent: { id: node.parent_id },
                                    children: [],
                                    isLeaf: false,
                                    expanded: node.expanded ? true : false,
                                })
                            ),
                            getKey: (node) => node.member_id, // resolve a node's key
                            getParentKey: (node) => node.parent_id,
                            rootKey: nextProps.placement_nodes.head,
                        }),
                        rootKey: nextProps.placement_nodes.head,
                        rootData: nextProps.placement_nodes.data,
                        sponsorTreeShow: true,
                        username: nextProps.placement_nodes.username,
                        expandData: [],
                        loading: false,
                        error: {},
                    });
                } else {
                    let array = this.state.rootData.concat(
                        nextProps.placement_nodes.data
                    );

                    this.setState({
                        treeData: getTreeFromFlatData({
                            flatData: array.map((node) => ({
                                ...node,
                                name: (
                                    <div>
                                        <div className="test pl-4">
                                            {node.email} &nbsp;&nbsp;
                                            <span className="badge badge-custom">
                                                Username: {node.nick_name}
                                            </span>
                                            {node.member_lot > 1 && (
                                                <>
                                                    &nbsp;&nbsp;
                                                    <span className="badge badge-custom">
                                                        Lot: {node.member_lot}
                                                    </span>
                                                    &nbsp;
                                                </>
                                            )}
                                            {node.member_lot < 2 && (
                                                <>
                                                    &nbsp;&nbsp;
                                                    {/*<span className='badge badge-custom'>*/}
                                                    {/*    BAT BV: 0*/}
                                                    {/*</span>*/}
                                                    {/*&nbsp;&nbsp;*/}
                                                    <span className="badge badge-custom">
                                                        CVT BV: {node.total_bv}
                                                    </span>
                                                </>
                                            )}
                                            {node.member_lot < 2 && (
                                                <>
                                                    &nbsp;&nbsp;
                                                    {/*<span className='badge badge-custom'>*/}
                                                    {/*    BAT BV: 0*/}
                                                    {/*</span>*/}
                                                    {/*&nbsp;&nbsp;*/}
                                                    <span className="badge badge-custom">
                                                        Today Left:{" "}
                                                        {node.todayLeft}
                                                    </span>
                                                </>
                                            )}
                                            {node.member_lot < 2 && (
                                                <>
                                                    &nbsp;&nbsp;
                                                    {/*<span className='badge badge-custom'>*/}
                                                    {/*    BAT BV: 0*/}
                                                    {/*</span>*/}
                                                    {/*&nbsp;&nbsp;*/}
                                                    <span className="badge badge-custom">
                                                        Today Right:{" "}
                                                        {node.todayRight}
                                                    </span>
                                                </>
                                            )}
                                            {node.member_lot < 2 && (
                                                <>
                                                    &nbsp;&nbsp;
                                                    {/*<span className='badge badge-custom'>*/}
                                                    {/*    BAT BV: 0*/}
                                                    {/*</span>*/}
                                                    {/*&nbsp;&nbsp;*/}
                                                    <span className="badge badge-custom">
                                                        Balance BV:{" "}
                                                        {node.balanceBv}
                                                    </span>
                                                </>
                                            )}
                                            {node.member_lot < 2 && (
                                                <>
                                                    &nbsp;
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            process.env
                                                                .REACT_APP_DOMAIN +
                                                            "/register/friend?placement=" +
                                                            node.email
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm">
                                                            Register
                                                        </button>
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ),
                                id: node.member_id,
                                parent: { id: node.parent_id },
                                children: [],
                                isLeaf: node.isLeaf,
                                expanded: node.expanded ? true : false,
                            })),
                            getKey: (node) => node.member_id, // resolve a node's key
                            getParentKey: (node) => node.parent_id,
                            rootKey: this.state.rootKey,
                        }),
                        rootData: array,
                        loading: false,
                    });
                }
            }
        }

        if (nextProps.placement_data !== this.props.placement_data) {
            if (nextProps.placement_data.rst === "1") {
                let profileObj = {};
                profileObj.username = sessionStorage.getItem("email");
                profileObj.token = sessionStorage.getItem("token");

                let hash2 = GenHash(
                    profileObj,
                    process.env.REACT_APP_GM_SECRET_KEY
                );
                profileObj.hash = hash2;

                this.props.getProfile(profileObj);
            } else {
                this.setState(
                    {
                        showLoadingModal: false,
                        errMsg: t("profile.failed_update_placement"),
                    },
                    () => {}
                );

                this.props.openModal("error-modal");
            }
        }

        if (nextProps.placementDetails !== this.props.placementDetails) {
            if (nextProps.placementDetails.rst === "1") {
                this.setState({
                    placement_email:
                        nextProps.placementDetails.data.floating_node.email,
                    floating_arr: nextProps.placementDetails.data.floating_arr,
                    total_count: nextProps.placementDetails.data.total_count,
                    showLoadingModal: false,
                });
            } else {
                this.setState(
                    {
                        showLoadingModal: false,
                        errMsg: t("profile.failed_update_placement"),
                    },
                    () => {}
                );

                this.props.openModal("error-modal");
            }
        }

        if (nextProps.postPlacement !== this.props.postPlacement) {
            if (nextProps.postPlacement.rst === "1") {
                let data = {};
                data.username = sessionStorage.getItem("email");
                data.token = sessionStorage.getItem("token");

                this.props.getPlacementDetails(data);

                message.success(nextProps.postPlacement.msg);
            } else {
                this.setState({
                    showLoadingModal: false,
                });

                message.error(nextProps.postPlacement.msg);
            }
        }

        if (nextProps.username_data !== this.props.username_data) {
            let msg = "";
            this.state.error.username = "";
            if (nextProps.username_data.rst === "1") {
                this.setState({
                    username: nextProps.username_data.data,
                    error: this.state.error,
                });
            } else {
                this.state.error.username = t("common.email_not_exist");
                this.setState({
                    username: "",
                    error: this.state.error,
                });
            }
        }
    }

    onCheck(e) {
        console.log("check");
    }

    onExpand(e) {
        console.log(this.state.expandArr);
        this.setState({
            expandArr: e,
        });
    }

    onSelect() {}

    onChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        const data = {};
        data.sponsor_email = this.state.sponsor_email;
        data.sponsor_lot = 1;
        data.placement_email = this.state.placement_email;

        this.props.postMemberPlacement(data);

        this.setState({ showLoadingModal: true });
    }

    handleAutoPlacement(e) {
        let auto_placement = "0";

        if (e) {
            auto_placement = "1";
        } else {
            auto_placement = "0";
        }

        let placementObj = {};
        placementObj.username = sessionStorage.getItem("email");
        placementObj.autoPlacement = auto_placement;
        placementObj.token = sessionStorage.getItem("token");

        let hash = GenHash(placementObj, process.env.REACT_APP_GM_SECRET_KEY);
        placementObj.hash = hash;

        this.setState({ showLoadingModal: true });

        this.props.postAutoPlacement(placementObj);
    }

    callback(e) {
        if (e === "2" && this.state.total_count < 1) {
            message.error(t("placement.not_applicable"));
        }
    }

    handleNodeChange(e) {
        this.setState({
            placement_email: e.target.value,
        });
    }

    handleNav(nav_link) {}

    alertNodeInfo = (node) => {
        // console.log(node.children.length);
        console.log(node);
        let placement_data = {};
        placement_data.recursive = "0";
        placement_data.lang = sessionStorage.getItem("lang");
        placement_data.member_id = node.member_id;
        this.props.getPlacementTreeData(placement_data);
    };

    registerReferral(node) {
        this.props.history.push({
            pathname: "/referral",
            state: { r_email: node.email },
        });
    }

    handleChangeSearch(e, v) {
        this.setState({
            search_type: e,
        });
    }

    updateTreeData(list, key, children) {
        return list.map((node) => {
            if (node.key === key) {
                return { ...node, children };
            } else if (node.children) {
                return {
                    ...node,
                    children: this.updateTreeData(node.children, key, children),
                };
            }

            return node;
        });
    }

    onLoadData(e, node) {
        // if (key.props.children.length > 0){
        //     return new Promise((resolve) => {
        //         setTimeout(() => {
        //             resolve();
        //         }, 0);
        //     });
        // }
        let arr = this.state.expandData;

        if (arr.includes(node.key)) {
            return;
        }

        arr.push(node.key);

        this.setState({ expandData: arr, loading: true });

        let placement_data = {};
        placement_data.searchEmail = node.email;
        placement_data.searchLot = node.member_lot;
        placement_data.searchType = "email";
        placement_data.refresh = 0;
        this.props.getPlacementNodes(placement_data);
        // return new Promise((resolve) => {
        //     setTimeout(() => {
        //         resolve();
        //     }, 1000);
        // });
    }

    render() {
        const {
            treeData,
            total_count,
            placementSetting,
            showLoadingModal,
            expandArr,
            loading,
            search_type,
            expandData,
        } = this.state;

        const data = [
            {
                id: 1,
                name: "company",
                parent: null,
                children: [
                    {
                        id: 2,
                        parent: { id: 1 },
                        name: "subcompany1",
                        children: [],
                    },
                    {
                        id: 3,
                        parent: { id: 1 },
                        name: "subcompany2",
                        children: [
                            {
                                id: 4,
                                parent: { id: 3 },
                                name: "example-company",
                                children: [],
                            },
                        ],
                    },
                    {
                        id: 5,
                        parent: { id: 1 },
                        name: "company2",
                        children: [],
                    },
                    {
                        id: 6,
                        parent: { id: 1 },
                        name: "company3",
                        children: [],
                    },
                ],
            },
        ];

        const selectAfter = (
            <Select
                defaultValue="email"
                name="search_type"
                className="select-after select-afteridth-100"
                onSelect={this.handleChangeSearch}
            >
                <Option key={1} value="email">
                    {t("placement.email")}
                </Option>
                <Option key={2} value="username">
                    {t("common.username")}
                </Option>
            </Select>
        );

        const { TabPane } = Tabs;
        console.log(treeData);
        return (
            <div className="package-panel">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack="/profile"
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("placement.placement")}
                    arrowLeft={true}
                />

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="bonus-payout-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}
                {/*<Tabs defaultActiveKey="1" onChange={this.callback}>*/}
                {/*    <TabPane tab={t("placement.placement_tree")} key="1">*/}
                <div className="col-12 offset-md-2 col-md-8">
                    <div className="px-0">
                        <div className="px-2">
                            <div className="card mx-auto shadow">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <h5 className="card-text text-uppercase profile-main-label">
                                                <span className="pl-2">
                                                    {t(
                                                        "placement.search_placement"
                                                    )}
                                                </span>
                                            </h5>
                                        </div>
                                        {/*<div className="col-6 px-0 text-right">*/}
                                        {/*    <Switch*/}
                                        {/*        checked={*/}
                                        {/*            placementSetting*/}
                                        {/*        }*/}
                                        {/*        onChange={*/}
                                        {/*            this*/}
                                        {/*                .handleAutoPlacement*/}
                                        {/*        }*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-lg-6 col-md-8 col-sm-12 pr-0">
                                            <Input
                                                id="search_email"
                                                name="search_email"
                                                className="remove-placeholder exchange-input"
                                                addonAfter={selectAfter}
                                                onChange={this.onChange}
                                                value={this.state.search_email}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-lg-12 col-md-12 col-sm-12 pr-0">
                                            {/*<button*/}
                                            {/*    type="submit"*/}
                                            {/*    className="depth-blue-btn float-right"*/}
                                            {/*    onClick={() => {*/}
                                            {/*        let placement_data = {};*/}
                                            {/*        placement_data.recursive =*/}
                                            {/*            "0";*/}
                                            {/*        placement_data.lang = sessionStorage.getItem(*/}
                                            {/*            "lang"*/}
                                            {/*        );*/}
                                            {/*        placement_data.master = true;*/}
                                            {/*        this.state.search_email =*/}
                                            {/*            "";*/}
                                            {/*        this.props.getPlacementTreeData(*/}
                                            {/*            placement_data*/}
                                            {/*        );*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    {t("placement.reset")}*/}
                                            {/*</button>*/}
                                            <button
                                                type="submit"
                                                className="depth-blue-btn float-right mr-2"
                                                onClick={() => {
                                                    this.setState({
                                                        treeData: [],
                                                        expandArr: [],
                                                    });
                                                    let placement_data = {};
                                                    placement_data.searchEmail = this.state.search_email;
                                                    placement_data.searchLot =
                                                        "1";
                                                    placement_data.searchType = search_type;
                                                    placement_data.refresh = 1;
                                                    this.props.getPlacementNodes(
                                                        placement_data
                                                    );
                                                }}
                                            >
                                                {t("placement.search")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 offset-md-2 col-md-8 overflow-auto min-height text-light">
                    {/*<Tree*/}
                    {/*    // defaultExpandedKeys={['0-0-0', '0-0-1']}*/}
                    {/*    // defaultCheckedKeys={['0-0-0', '0-0-1']}*/}
                    {/*    // onSelect={this.onSelect}*/}
                    {/*    // onCheck={this.onCheck}*/}
                    {/*    onExpand={this.onExpand}*/}
                    {/*    expandedKeys={expandArr}*/}
                    {/*    loadedKeys={expandArr}*/}
                    {/*    switcherIcon={<Icon type="down" />}*/}
                    {/*    selectable={false}*/}
                    {/*    treeData={treeData}*/}
                    {/*    loadData={this.onLoadData}*/}
                    {/*>*/}
                    {/*</Tree>*/}
                    <Tree
                        data={treeData}
                        direction
                        render={(node) => (
                            <div>
                                <div className="test px-4 py-3 mx-auto">
                                    {/*{node.email} &nbsp;&nbsp;*/}
                                    <span className="badge col-12">
                                        <label className="col-12">
                                            Username
                                        </label>
                                        <div className="col-12">
                                            {node.nick_name}
                                            {/*{node.member_lot < 2 &&*/}
                                            {/*<a target="_blank" href={process.env.REACT_APP_DOMAIN + '/register/friend?placement=' + node.email}><Icon type="user-add" /></a>*/}
                                            {/*}*/}
                                        </div>
                                    </span>
                                    {node.member_lot > 1 && (
                                        <>
                                            <span className="badge badge-custom col-10">
                                                Lot: {node.member_lot}
                                            </span>
                                        </>
                                    )}
                                    {node.member_lot < 2 && (
                                        <>
                                            <span className="badge badge-custom col-10">
                                                CVT BV: {node.total_bv}
                                            </span>
                                        </>
                                    )}
                                    {node.member_lot < 2 && (
                                        <>
                                            <span className="badge badge-custom col-10">
                                                Today: {node.todayLeft} |{" "}
                                                {node.todayRight}
                                            </span>
                                        </>
                                    )}
                                    {node.member_lot < 2 && (
                                        <>
                                            <span className="badge badge-custom col-10">
                                                Balance: {node.balanceLeft} |{" "}
                                                {node.balanceRight}
                                            </span>
                                        </>
                                    )}
                                    {node.member_lot < 2 && (
                                        <>
                                            &nbsp;
                                            <a
                                                target="_blank"
                                                href={
                                                    process.env
                                                        .REACT_APP_DOMAIN +
                                                    "/register/friend?placement=" +
                                                    node.email
                                                }
                                            >
                                                {/*<button className='btn btn-danger btn-sm'>*/}
                                                {/*    Register*/}
                                                {/*</button>*/}
                                                <div>
                                                    <span className="badge badge-custom2 col-10">
                                                        <Icon type="user-add" />{" "}
                                                        Register
                                                    </span>
                                                </div>
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    />
                </div>
                {/*</TabPane>*/}
                {/*<TabPane*/}
                {/*    tab={*/}
                {/*        <>*/}
                {/*            {t("placement.member_placement")}&nbsp;*/}
                {/*            {total_count > 0 && (*/}
                {/*                <span className="badge badge-pill badge-danger">*/}
                {/*                    {total_count}*/}
                {/*                </span>*/}
                {/*            )}*/}
                {/*        </>*/}
                {/*    }*/}
                {/*    key="2"*/}
                {/*>*/}
                {/*    {total_count > 0 && (*/}
                {/*        <div className="col-12 offset-md-2 col-md-8">*/}
                {/*            <div className="col-12">*/}
                {/*                <div className="col-12">*/}
                {/*                    <div className="row">*/}
                {/*                        <label className="depth-gray-label text-uppercase">*/}
                {/*                            {t("placement.assign_email")}*/}
                {/*                        </label>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="col-12">*/}
                {/*                    <div className="row">*/}
                {/*                        <SelectInput*/}
                {/*                            id="assign_member"*/}
                {/*                            className="form-control remove-placeholder depth-input text-dark"*/}
                {/*                            name="withdraw_platform"*/}
                {/*                            options={*/}
                {/*                                this.state.floating_arr*/}
                {/*                            }*/}
                {/*                            value={*/}
                {/*                                this.state.placement_email*/}
                {/*                            }*/}
                {/*                            onChange={(e) =>*/}
                {/*                                this.handleNodeChange(e)*/}
                {/*                            }*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-12 mt-3">*/}
                {/*                <div className="col-12">*/}
                {/*                    <div className="row">*/}
                {/*                        <label className="depth-gray-label text-uppercase">*/}
                {/*                            {t("placement.placement_email")}*/}
                {/*                        </label>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="col-12">*/}
                {/*                    <div className="row">*/}
                {/*                        <StringInput*/}
                {/*                            id="sponsor_email"*/}
                {/*                            name="sponsor_email"*/}
                {/*                            className="remove-placeholder depth-input"*/}
                {/*                            onChange={this.onChange}*/}
                {/*                            value={this.state.sponsor_email}*/}
                {/*                            placeholder={t(*/}
                {/*                                "placement.enter_placement_email"*/}
                {/*                            )}*/}
                {/*                            requiredMsg={t(*/}
                {/*                                "validator.requiredMsg"*/}
                {/*                            )}*/}
                {/*                            required*/}
                {/*                            disabled={placementSetting}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="pt-4">*/}
                {/*                <button*/}
                {/*                    type="submit"*/}
                {/*                    className="col-12 depth-blue-btn"*/}
                {/*                    onClick={this.handleSubmit}*/}
                {/*                >*/}
                {/*                    {t("withdrawal.submit")}*/}
                {/*                </button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*    {total_count <= 0 && (*/}
                {/*        <label className="col-12 depth-gray-label text-uppercase text-center">*/}
                {/*            {t("placement.no_record")}*/}
                {/*        </label>*/}
                {/*    )}*/}
                {/*</TabPane>*/}
                {/*</Tabs>*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile, transfer } = state;

    return {
        profile_data: profile.profileData,
        placement_data: profile.placementData,
        postPlacement: profile.postPlacement,
        placementDetails: profile.placementDetails,
        placement_list: profile.placement_list,
        placement_nodes: profile.placement_nodes,
        username_data: transfer.getUsernameByEmail,
    };
};

const mapDispatchToProps = {
    getProfile,
    postAutoPlacement,
    postMemberPlacement,
    getPlacementDetails,
    getPlacementTreeData,
    getUsernameByEmail,
    getPlacementNodes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Placement);
