import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
    product_data: [],
    product_details: [],
    product_cart: [],
    confirm_payment: [],
    variantKey: []
};

const eMallReducer = createReducer(initialState) ({

    [type.POST_MERCHANT_TAG]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

    [type.POST_MERCHANT_PRODUCTS]: (state, action) => ({
        ...state,
        product_data: action.payload.data,
    }),

    [type.GET_PRODUCT_DETAILS]: (state, action) => ({
        ...state,
        product_details: action.payload.data,
    }),

    [type.POST_DELIVERY_ADDRESS]: (state, action) => ({
        ...state,
        add_delivery_address: action.payload.data,
    }),

    [type.GET_COUNTRY]: (state, action) => ({
        ...state,
        get_country: action.payload.data,
    }),

    [type.GET_DELIVERY_ADDRESS]: (state, action) => ({
        ...state,
        get_delivery_address: action.payload.data,
    }),

    [type.POST_PRODUCT_CART]: (state, action) => ({
        ...state,
        product_cart: action.payload.data,
    }),
    [type.POST_ORDER_CONFIRM]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),
    [type.UPDATE_DELIVERY_ADDRESS]: (state, action) => ({
        ...state,
        update_delivery_address: action.payload.data,
    }),

    [type.GET_EDIT_DELIVERY_ADDRESS]: (state, action) => ({
        ...state,
        get_edit_delivery_address: action.payload.data,
    }),

    [type.DELETE_DELIVERY_ADDRESS]: (state, action) => ({
        ...state,
        delete_delivery_address: action.payload.data,
    }),

    [type.GET_ORDER_LIST]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

    [type.GET_ORDER_DETAILS]: (state, action) => ({
        ...state,
        orderDetails: action.payload.data,
    }),

    [type.POST_CONFIRM_PAYMENT]: (state, action) => ({
        ...state,
        confirm_payment: action.payload.data,
    }),

    [type.POST_VARIANT_KEY]: (state, action) => ({
        ...state,
        variantKey: action.payload.data,
    }),
});

export default eMallReducer;