import React, { Component } from "react";
import { connect } from "react-redux";
//import { setLocale, t } from 'react-i18nify';
import { t } from "../../common/translation";
import PageHeader from "../../components/PageHeaderV2";
import "./announcement.scss";
import { Card, Button } from "antd";
import { getAnnoucementList } from "../../store/ducks/home/actions";
import { GenHash } from "../../common/helpers";
import renderHTML from "react-render-html";
import Modal from "../../components/modal";
import DateIcon from "../../components/DateIcon";

class Announcement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAnnoucementModal: false,
            annoucementData: [],
            history_notice: [],
            announcementTitle: t("announcement.title"),
        };

        this.handleAnnoucementClose = this.handleAnnoucementClose.bind(this);
        this.handleNoticeClick = this.handleNoticeClick.bind(this);
        this.handleBack = this.handleBack.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `50px`);
    }

    UNSAFE_componentWillMount() {
        const annoucmentData = {};
        // annoucmentData.langCode = sessionStorage.getItem('lang');
        const hash_annoucement = GenHash(
            annoucmentData,
            process.env.REACT_APP_GM_SECRET_KEY
        );
        annoucmentData.hash = hash_annoucement;

        this.props.getAnnoucementList(annoucmentData);
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.annoucementData !== this.props.annoucementData) {
            if (nextProps.annoucementData.rst === "1") {
                if (nextProps.annoucementData.data.history_notice !== null) {
                    this.setState({
                        history_notice:
                            nextProps.annoucementData.data.history_notice,
                    });
                }
            }
        }
    }

    handleAnnoucementClose(event) {
        this.setState({
            showAnnoucementModal: false,
            annoucementData: [],
        });
    }

    handleNoticeClick(e, value) {
        let array = [];
        this.state.annoucementData = [];
        array.push(value);
        this.state.annoucementData.push(array);

        this.state.annoucementData.map(function (item, i) {
            return null;
        });

        this.setState({
            showAnnoucementModal: true,
            announcementTitle: value.title,
        });
    }

    handleBack() {
        this.props.history.goBack();
    }

    render() {
        const {
            showAnnoucementModal,
            announcementTitle,
            annoucementData,
            history_notice,
        } = this.state;

        const handleNoticeClick = this.handleNoticeClick;

        return (
            <div className="announcement">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    goBackFunc={this.handleBack}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("announcement.title")}
                    arrowLeft={true}
                />

                <Modal
                    title={announcementTitle}
                    children={
                        <div className="row">
                            {annoucementData.map(function (item, i) {
                                return (
                                    <div className="col-12 text-left" key={i}>
                                        {/*<h5><strong className="text-light">{item[i].title}</strong></h5>*/}
                                        <p className="text-light">
                                            {item[i].description}
                                        </p>
                                        {item[i].type === "iframe" &&
                                            renderHTML(
                                                item[i].annoucement_file_path
                                            )}

                                        {item[i].type === "image" && (
                                            <img
                                                src={
                                                    item[i]
                                                        .annoucement_file_path
                                                }
                                                width="100"
                                                height="100"
                                                alt={i}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    }
                    closeText=""
                    closeClassName=""
                    closeFunc=""
                    closeId=""
                    submitText=""
                    modalHeadClass={`modal-header-black modal-title-white`}
                    modalTitleClass={`modal-title-white`}
                    modalBodyClass={`modal-body-purple`}
                    modalTitleCloseFunc={this.handleAnnoucementClose}
                    submitClassName="depth-blue-btn"
                    submitFunc=""
                    submitId=""
                    modalFooterClassName="custom-modal-footer"
                    isShowing={showAnnoucementModal}
                    modalClassName="modal-lg"
                    modalClassId="annoucement-modal"
                />

                <div className="site-card-wrapper col-12 overflow-auto custom-height">
                    <div className="row">
                        {history_notice.map(function (value, index) {
                            return (
                                <div
                                    className="col-12 col-md-6 mt-3"
                                    key={index}
                                >
                                    <Card
                                        bordered={false}
                                        onClick={(e) =>
                                            handleNoticeClick(e, value)
                                        }
                                    >
                                        <div className="row">
                                            <DateIcon
                                                date={value.date_from}
                                                className="col-3 px-0"
                                            />
                                            <div className="col-9">
                                                <div className="col-12">
                                                    <h5 className="text-light">
                                                        <strong>
                                                            {value.title}
                                                        </strong>
                                                    </h5>
                                                </div>
                                                <div className="col-12 text-truncate">
                                                    {value.description}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance } = state;

    return {
        annoucementData: walletBalance.getAnnoucementData,
    };
};

const mapDispatchToProps = {
    getAnnoucementList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcement);
