import * as type from './types';
import { createReducer } from "../../util";

const initialState = {
    data: [],
};

const friendReducer = createReducer(initialState) ({

    [type.POST_MULTINARYTREE_GROUP]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

    [type.POST_MULTINARYTREE_GROUP_DETAILS]: (state, action) => ({
        ...state,
        details: action.payload.data,
    }),

});

export default friendReducer;