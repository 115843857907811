import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale, t } from "react-i18nify";
import InfiniteScroll from "react-infinite-scroller";
import PageHeader from "../../components/PageHeaderV2";
import LoadingMask from "../../components/loadingMask";
import Modal from "../../components/modal";
import ErrorIcon from "../../components/ErrorAlert";

import {
    postWalletBalance,
    postWalletBalanceLite,
} from "../../store/ducks/home/actions";
import { getMissionPointList } from "../../store/ducks/missionPoint/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";

import { GenHash } from "../../common/helpers";

import "./missionPointList.scss";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import SuccessIcon from "../../components/SuccessAlert";

class MissionPointList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            transaction_data: [],
            expandedRows: [],
            depositStatus: 0,
            transferStatus: 0,
            withdrawalStatus: 0,
            convertStatus: 0,
            stakingStatus: 0,
            auctionStatus: 0,
            exchangeStatus: 0,
            kycStatus: 0,
            loading: true,
            loadingText: true,
            totalPage: 0,
            currentPage: 1,
            walletType: "",
            balance: "0.00000000",
            hasMoreItems: true,
            showErrorModal: false,
            isUnderMaintenance: "0",
            kycCheck: "1",
            showNotice: false,
            totalStakingAmount: "0",
            showSuccessModal: false,
            showLoadingModal: false,
            showConfirmationModal: false,
            errMsg: "",
            errType: "",
            successMsg: "",
            docNo: "",
            productCodeArr: {},
            label: undefined,
            transaction_type: "",
            url: "",
            button_label: "",
            walletTypeCode: "",
            displayWalletCode: "",
            mission_data: [],
            limit: 15,
            missionPoint: 0,
        };

        this.handleRowClick = this.handleRowClick.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleNoticeOpen = this.handleNoticeOpen.bind(this);
        this.handleNoticeClose = this.handleNoticeClose.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleSubmitCancelTransConfirmation = this.handleSubmitCancelTransConfirmation.bind(
            this
        );
    }

    loadMoreList(e, page) {
        const trans = {};
        let currentPage = parseInt(this.state.currentPage) + 1;

        trans.username = sessionStorage.getItem("email");
        trans.token = sessionStorage.getItem("token");
        trans.page = currentPage;
        trans.lang = sessionStorage.getItem("lang");
        trans.limit = this.state.limit;
        let hash = GenHash(trans, process.env.REACT_APP_GM_SECRET_KEY);
        trans.hash = hash;

        if (currentPage >= this.state.totalPage) {
            this.setState({
                hasMoreItems: false,
                loading: false,
            });
        }

        // this.setState({ loading: true });
        this.props.getMissionPointList(trans);
    }

    handleNoticeOpen(e) {
        this.props.openModal("notice-modal");
    }

    handleNoticeClose(e) {
        this.props.closeModal("notice-modal");
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/home");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleSubmitWithdrawalClose(event) {
        event.preventDefault();

        this.props.closeModal("cancel-trans-confirmation-modal");
    }

    async handleSubmitCancelTransConfirmation(e, docNo) {
        e.preventDefault();

        this.setState({ docNo: docNo });

        this.props.openModal("cancel-trans-confirmation-modal");
    }

    handleRowClick(rowId) {
        const currentExpandedRows = this.state.expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

        const newExpandedRows = isRowCurrentlyExpanded
            ? currentExpandedRows.filter((id) => id !== rowId)
            : currentExpandedRows.concat(rowId);

        this.setState({ expandedRows: newExpandedRows });
    }

    renderItem(item, i) {
        const itemRows = [
            <li className="list-group-item asset-panel-list-group-item" key={i}>
                <div className="pl-4">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div
                                className="fa-pull-left"
                                style={{ color: item.color }}
                            >
                                <small>
                                    <b>
                                        {window.location.search.slice(1, 10)}{" "}
                                        {item.amount}
                                    </b>
                                </small>
                            </div>
                            <div className="fa-pull-right">
                                <small>
                                    <b>{item.created_at}</b>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 pl-0">
                            <small>
                                <b>
                                    {item.remark}
                                    {/*&nbsp;{item.hash}*/}
                                </b>
                            </small>
                        </div>
                    </div>
                </div>
            </li>,
        ];

        return itemRows;
    }

    componentDidMount() {
        setLocale(sessionStorage.getItem("lang"));

        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.lang = sessionStorage.getItem("lang");
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        const trans = {};

        trans.username = sessionStorage.getItem("email");
        trans.token = sessionStorage.getItem("token");
        trans.page = 1;
        trans.limit = this.state.limit;
        trans.lang = sessionStorage.getItem("lang");
        hash = GenHash(trans, process.env.REACT_APP_GM_SECRET_KEY);
        trans.hash = hash;

        this.props.postWalletBalanceLite(data);
        this.props.getMissionPointList(trans);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "notice-modal") {
                this.setState({
                    showNotice: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName ===
                "cancel-trans-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName ===
                "cancel-trans-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.lite_data !== this.props.lite_data) {
            if (nextProps.lite_data.rst === "1") {
                let array;
                array = nextProps.lite_data.data;

                if (Array.isArray(array.walletDetailsSetup)) {
                    array.walletDetailsSetup = Object.assign(
                        {},
                        array.walletDetailsSetup
                    );
                }

                this.setState({ data: array.walletBalance[0] });
                this.setState({
                    depositStatus:
                        array.walletDetailsSetup.depositSetting !== undefined
                            ? "1"
                            : "0",
                });
                this.setState({
                    transferStatus:
                        array.walletDetailsSetup.transferSetting !== undefined
                            ? array.walletDetailsSetup.transferSetting
                                  .isUnderMaintenance === "0"
                                ? "1"
                                : "0"
                            : "0",
                });
                this.setState({
                    withdrawalStatus:
                        array.walletDetailsSetup.withdrawSetting !== undefined
                            ? "1"
                            : "0",
                });
                this.setState({
                    auctionStatus:
                        array.walletDetailsSetup.auctionSetting !== undefined
                            ? "1"
                            : "0",
                });
                this.setState({
                    isUnderMaintenance:
                        array.walletDetailsSetup.withdrawSetting !== undefined
                            ? Object.keys(
                                  array.walletDetailsSetup.withdrawSetting
                              ).length > 0
                                ? array.walletDetailsSetup.withdrawSetting
                                      .isUnderMaintenance
                                : "0"
                            : "0",
                });
                this.setState({
                    kycCheck:
                        array.walletDetailsSetup.withdrawSetting !== undefined
                            ? Object.keys(
                                  array.walletDetailsSetup.withdrawSetting
                              ).length > 0
                                ? array.walletDetailsSetup.withdrawSetting
                                      .kycCheck
                                : "0"
                            : "0",
                });
                this.setState({
                    kycStatus:
                        array.walletDetailsSetup.withdrawSetting !== undefined
                            ? Object.keys(
                                  array.walletDetailsSetup.withdrawSetting
                              ).length > 0
                                ? array.walletDetailsSetup.withdrawSetting
                                      .kycStatus
                                : "0"
                            : "0",
                });
                this.setState({
                    stakingStatus:
                        array.walletDetailsSetup.stakingSetting !== undefined
                            ? "1"
                            : "0",
                });
                this.setState({
                    exchangeStatus:
                        array.walletDetailsSetup.exchangeSetting !== undefined
                            ? array.walletDetailsSetup.exchangeSetting
                                  .isUnderMaintenance === "0"
                                ? "1"
                                : "0"
                            : "0",
                });
                this.setState({
                    convertStatus:
                        array.walletDetailsSetup.convertSetting !== undefined
                            ? "1"
                            : "0",
                });
                this.setState({
                    totalStakingAmount:
                        array.walletBalance[0].total_staking_amount,
                });
                this.setState({
                    label: array.walletBalance[0].notification_box.label,
                });
                this.setState({
                    transaction_type:
                        array.walletBalance[0].notification_box
                            .transaction_type,
                });
                this.setState({
                    url: array.walletBalance[0].notification_box.url,
                });
                this.setState({
                    button_label:
                        array.walletBalance[0].notification_box.button_label,
                });
                this.setState({
                    walletTypeCode: array.walletBalance[0].walletTypeCode,
                });
                this.setState({
                    displayWalletCode: array.walletBalance[0].walletTypeCode,
                });
                this.setState({ missionPoint: array.missionSetting });
            }
        }

        if (nextProps.mission_data !== this.props.mission_data) {
            if (nextProps.mission_data.rst === 1) {
                let array;

                array = this.state.mission_data;
                nextProps.mission_data.data.currentPageItems.map(function (
                    item,
                    i
                ) {
                    array.push(item);

                    return array;
                });

                if (
                    this.state.currentPage ===
                    nextProps.mission_data.data.totalPage
                ) {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                if (nextProps.mission_data.data.totalPage === 0) {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                this.setState({
                    loading: false,
                });
                this.setState({ transaction_data: array });
                this.setState({
                    totalPage: nextProps.mission_data.data.totalPage,
                });
                this.setState({
                    currentPage: nextProps.mission_data.data.currentPage,
                });
            } else {
                this.setState({
                    errMsg: nextProps.mission_data.msg,
                    loading: false,
                });
                this.props.openModal("error-modal");
            }
        }
    }

    render() {
        const {
            data,
            transaction_data,
            depositStatus,
            transferStatus,
            withdrawalStatus,
            stakingStatus,
            exchangeStatus,
            convertStatus,
            auctionStatus,
            loading,
            totalPage,
            currentPage,
            balance,
            showErrorModal,
            isUnderMaintenance,
            kycCheck,
            errMsg,
            kycStatus,
            showNotice,
            showSuccessModal,
            showLoadingModal,
            successMsg,
            showConfirmationModal,
            productCodeArr,
            label,
            url,
            transaction_type,
            button_label,
            walletTypeCode,
            missionPoint,
        } = this.state;

        // const { walletType } = this.props.location.state === undefined ? "" : this.props.location.state;

        let allItemRows = [];

        transaction_data.map((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);

            return allItemRows;
        });

        return (
            <div className="dark-theme">
                {showNotice && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("profile.maintenance_notice")}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("profile.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleNoticeClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showNotice}
                        modalClassName=""
                        modalClassId="notice-modal"
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="cancel-trans-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("transfer.confirmation_msg")}</h4>
                                </div>
                            </div>
                        }
                        closeText={t("purchasePin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={this.handleSubmitWithdrawalClose}
                        closeId=""
                        submitText={t("common.submit")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleCancelSalesTrans}
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="cancel-trans-confirmation-modal"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}
                <PageHeader
                    titleHeader="col-12 text-center"
                    backgroundHeight={230}
                    prevousPageLink=""
                    navClass=""
                    historyBack="/home"
                    historyBackClass="py-2"
                    avatarDivClass="text-center header offset-md-2 col-md-8"
                    avatarLevel="title white-color"
                    // walletLabel=""
                    // walletType={' '}
                    title={t("walletDetails.mission_point_list")}
                    missionPoint={missionPoint}
                    // lockedAmount={data.locked_amount}
                    // availableAmount={data.available_amount}
                    arrowLeft={true}
                    headerBanner={true}
                />

                <div className="walletDetails">
                    <div className={"pt-3 offset-md-2 col-md-8 px-0"}>
                        <div className="col-12 pl-4 py-2 list-title-background">
                            <div className="details-size-80 list-title-color">
                                <span>
                                    {t("walletDetails.transaction_history")}
                                </span>
                            </div>
                        </div>

                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadMoreList}
                            hasMore={this.state.hasMoreItems}
                            loader={
                                (!loading && totalPage === 1) ||
                                (!loading && currentPage === totalPage) ? (
                                    ""
                                ) : (
                                    <div className="col-12" key={0}>
                                        <div className="mx-auto">
                                            <LoadingMask width={80} />
                                        </div>
                                    </div>
                                )
                            }
                            initialLoad={false}
                            threshold={15}
                        >
                            {
                                <aside className="list-group">
                                    {allItemRows}
                                </aside>
                            }
                        </InfiniteScroll>
                    </div>

                    {((!loading && totalPage === 1) ||
                        (!loading && currentPage === totalPage)) && (
                        <div className="col-12 text-white">
                            <div className="row">
                                <div className="mx-auto">
                                    <span>
                                        <small>
                                            <b>{t("common.end_of_record")}</b>
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {!loading && totalPage < 1 && (
                        <div className="col-12 text-white">
                            <div className="row">
                                <div className="mx-auto">
                                    <span>
                                        <small>
                                            <b>{t("common.no_record")}</b>
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, modal, walletDetails, mission } = state;

    return {
        data: walletBalance.data,
        lite_data: walletBalance.lite_data,
        classIsShowing: modal.classIsShowing,
        mission_data: mission.data,
        cancel_data: walletDetails.cancel_data,
    };
};

const mapDispatchToProps = {
    postWalletBalance,
    postWalletBalanceLite,
    closeModal,
    openModal,
    getMissionPointList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionPointList);
