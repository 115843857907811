import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import CartImg from "../../public/images/icon-my-cart@3x.png";
// import { Icon } from 'antd';
import './ShoppingCart.scss';


class PageHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {

        // const cartIcon = props => <Icon component={CartImg} {...props} />;

        return (
            <Link to="/emall/shopping-cart">
                <div className="shoppingCart">
                    {/*<Badge count={5} className="cartStyle">*/}
                    {/*    <Avatar className="p-1 badge badge-danger" size="large" src={CartImg}/>*/}
                    {/*    /!*<img src={CartImg} width={25}/>*!/*/}
                    {/*</Badge>*/}
                  <span className="badge badge-pill badge-danger cartStyle">
                    <img src={CartImg} width={25} alt={1}/>&nbsp;&nbsp;
                      <label>{sessionStorage.getItem("cartCount")}</label>
                  </span>
                </div>
            </Link>
        );
  }
}

export default PageHeader;

PageHeader.propTypes = {

};
