import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const getCartsReducer = createReducer(initialState) ({

    [type.GET_CARTS]: (state, action) => ({
        ...state,
        cartsData: action.payload,
    }),

    [type.POST_DELETE_CART_ITEM]: (state, action) => ({
        ...state,
        delItem: action.payload,
    }),

    [type.POST_UPDATE_CART_ITEM]: (state, action) => ({
        ...state,
        updateCarts: action.payload,
    }),
});

export default getCartsReducer;