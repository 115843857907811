import Exchange from "../../pages/exchange";

const routes = [
    {
        path: "/exchange",
        component: Exchange,
    },
];

export default routes;
