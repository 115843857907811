import * as type from "./types";
import axios from "axios";

// let loading = false;
// let numRequest = 0;

// axios.interceptors.request.use((res) => {
//     numRequest++;
//     console.log(numRequest, res);
//     if (!loading) {
//     }
//     return res;
// });

export const postWalletBalance = (data) => (dispatch) => {
    axios
        .create({ withCredentials: false })
        .post(
            process.env.REACT_APP_GM_URL + "/member/wallet/balance/list",
            {
                token: data["token"],
                username: data["username"],
                walletType: data["walletType"],
                hash: data["hash"],
            },
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.POST_WALLET_BALANCE,
                payload: response,
            });
        })
        .catch((err) => {
            console.warn(err);
        });
};

export const postWalletBalanceLite = (data) => (dispatch) => {
    axios
        .post(
            process.env.REACT_APP_GM_URL + "/member/wallet/balance/lite",
            data,
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.POST_WALLET_BALANCE_LITE,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.POST_WALLET_BALANCE_LITE,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const postWalletBalanceLiteHome = (data) => (dispatch) => {
    axios
        .create({ withCredentials: false })
        .post(
            process.env.REACT_APP_GM_URL + "/member/wallet/balance/lite",
            data,
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.POST_WALLET_BALANCE_HOME,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.POST_WALLET_BALANCE_HOME,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getMemberProfile = (data, url) => (dispatch) => {
    axios
        .create({ withCredentials: false })
        .post(
            process.env.REACT_APP_GM_URL + "/member/profile",
            {
                token: data["token"],
                username: data["username"],
                hash: data["hash"],
                lang: data["lang"],
            },
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.GET_MEMBER_PROFILE,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_MEMBER_PROFILE,
                payload: err.response,
            });
        });
};

export const getAnnoucementList = (data, url) => (dispatch) => {
    axios
        .post(
            process.env.REACT_APP_GM_URL + "/member/announcements",
            {
                langCode: sessionStorage.getItem("lang"),
                hash: data["hash"],
            },
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.GET_ANNOUCEMENT_LIST,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_ANNOUCEMENT_LIST,
                payload: err.response,
            });
        });
};

export const postAutoSell = (res_data) => (dispatch) => {
    axios
        .put(process.env.REACT_APP_GM_URL + "/member/setting/sell", res_data, {
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.POST_AUTO_SELL,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.POST_AUTO_SELL,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const getLanguageList = (res_data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/languages", {
            params: {
                res_data,
            },
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                /*'Authorization': sessionStorage.getItem('accessToken')*/
            },
        })
        .then((response) => {
            dispatch({
                type: type.GET_LANGUAGE_LIST,
                payload: response,
            });
        })
        .catch((error) => {
            console.log(error);
            // dispatch ({
            //     type: type.GET_LANGUAGE_LIST,
            //     payload: {"rst":"0","errCode":error.response.status,"msg":"system_error"}
            // });
        });
};

export const getLanguageFile = (lang) => async (dispatch) => {
    console.log(lang);

    try {
        let posts = await axios.get(
            process.env.REACT_APP_GM_URL + "/locales/" + lang + "/common.json",
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                },
                // headers: {
                //     "Access-Control-Allow-Origin": "*",
                //     "Access-Control-Allow-Methods":
                //         "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                // },
            }
        );
        dispatch({
            type: type.GET_LANGUAGE_FILE,
            payload: posts,
        });
    } catch (e) {
        console.log(e);
    }
    // await axios
    //     .create({ withCredentials: false })
    //     .get(
    //         process.env.REACT_APP_GM_URL + "/locales/" + lang + "/common.json",
    //         {
    //             headers: {
    //                 "X-Authorization":
    //                     "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
    //                 /*'Authorization': sessionStorage.getItem('accessToken')*/
    //             },
    //         }
    //     )
    //     .then((response) => {
    //         dispatch({
    //             type: type.GET_LANGUAGE_FILE,
    //             payload: response,
    //         });
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
};

export const postHomePanel = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/settings", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            // console.log("here", response);
            dispatch({
                type: type.POST_HOME_PANEL_SETTING,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.POST_HOME_PANEL_SETTING,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getMemberWallet = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/assets", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.GET_MEMBER_WALLET,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_MEMBER_WALLET,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getWalletDetailSetting = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/wallet/detail/setting", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.GET_WALLET_SETTING,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_WALLET_SETTING,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getAllMemberWallet = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/assets", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            dispatch({
                type: type.GET_MEMBER_WALLET,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_MEMBER_WALLET,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getMissionSetting = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/settings/mission", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            //console.log(response);
            dispatch({
                type: type.GET_MISSION_SETTING,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_MISSION_SETTING,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getWithdrawalSetting = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/settings/withdraw", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            //console.log(response);
            dispatch({
                type: type.GET_WITHDRAWAL_SETTING,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_WITHDRAWAL_SETTING,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getExchangeSetting = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/settings/exchange", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            //console.log(response);
            dispatch({
                type: type.GET_EXCHANGE_SETTING,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_EXCHANGE_SETTING,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getTransferSetting = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/settings/transfer", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            //console.log(response);
            dispatch({
                type: type.GET_TRANSFER_SETTING,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_TRANSFER_SETTING,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getStakingSetting = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/settings/staking", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            //console.log(response);
            dispatch({
                type: type.GET_STAKING_SETTING,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_STAKING_SETTING,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getDepositSetting = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/settings/deposit", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            //console.log(response);
            dispatch({
                type: type.GET_DEPOSIT_SETTING,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_DEPOSIT_SETTING,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const isLoadingAction = (data) => (dispatch) => {
    dispatch({
        type: type.IS_LOADING,
        payload: data,
    });
};

export const postWalletStatement = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/wallet/statements", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            //console.log(response);
            dispatch({
                type: type.GET_WALLET_STATEMENT,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_WALLET_STATEMENT,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};

export const getConvertSetting = (data) => (dispatch) => {
    axios
        .get(process.env.REACT_APP_GM_URL + "/member/settings/convert", {
            params: data,
            headers: {
                "X-Authorization":
                    "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                Authorization: sessionStorage.getItem("accessToken"),
            },
        })
        .then((response) => {
            //console.log(response);
            dispatch({
                type: type.GET_CONVERT_SETTING,
                payload: response,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_CONVERT_SETTING,
                payload: { rst: "0", msg: "system_error", data: [] },
            });
        });
};
