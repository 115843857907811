import React, { Component, Fragment } from "react";
import {
    maxValue,
    minValue,
    required,
    number,
    blockTen,
    blockHundreds,
    minLength,
} from "../../../common/validator/validate";
import PropTypes from "prop-types";
import { validateDisplay } from "../../../common/validator/formValidation";

import "./numberInput.scss";

class NumberInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            requiredRules: required(
                this.props.value,
                true,
                this.props.requiredMsg
            ),
            minValueRules: minValue(
                this.props.value,
                this.props.min,
                true,
                this.props.textMoreThan
            ),
            maxValueRules: maxValue(
                this.props.value,
                this.props.max,
                true,
                this.props.textLessThan
            ),
            blockTenRules: blockTen(
                this.props.value,
                true,
                this.props.blockTen
            ),
            blockHundredsRules: blockHundreds(
                this.props.value,
                true,
                this.props.blockHundreds
            ),
            minLengthRules: minLength(
                this.props.value,
                this.props.minLength,
                true,
                this.props.minLengthMsg
            ),
            numberRules: number(this.props.value, true),
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.setState({
            dropdown_block: "block",
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.value !== prevState.value ||
            nextProps.requiredMsg !== prevState.requiredRules.message
        ) {
            let payload = {
                value: nextProps.value,
                numberRules: number(nextProps.value),
            };

            if (nextProps.required) {
                payload["requiredRules"] = required(
                    nextProps.value,
                    false,
                    nextProps.requiredMsg
                );
            }

            if (nextProps.min) {
                payload["minValueRules"] = minValue(
                    nextProps.value,
                    nextProps.min,
                    false,
                    nextProps.textMoreThan
                );
            }

            if (nextProps.max) {
                payload["maxValueRules"] = maxValue(
                    nextProps.value,
                    nextProps.max,
                    false,
                    nextProps.textLessThan
                );
            }

            if (nextProps.minLength) {
                payload["minLengthRules"] = minLength(
                    nextProps.value,
                    nextProps.minLength,
                    false,
                    nextProps.minLengthMsg
                );
            }

            if (nextProps.value && nextProps.blockTen) {
                payload["blockTenRules"] = blockTen(
                    nextProps.value,
                    false,
                    nextProps.blockTen
                );
            }

            if (nextProps.value && nextProps.blockHundreds) {
                payload["blockHundredsRules"] = blockHundreds(
                    nextProps.value,
                    false,
                    nextProps.blockHundreds
                );
            }

            return payload;
        }

        return null;
    }

    render() {
        const {
            id,
            name,
            data,
            onChange,
            className,
            defaultValue,
            disabled,
            required,
            placeholder,
            min,
            max,
            onBlur,
            packagePrice,
            backInputGroup,
            arrayInputGroup,
        } = this.props;
        const {
            value,
            requiredRules,
            minValueRules,
            maxValueRules,
            blockTenRules,
            minLengthRules,
            blockHundredsRules,
        } = this.state;

        // validation
        const [requiredClass, requiredMsg] = validateDisplay(requiredRules);
        const [minValueClass, minValueMsg] = validateDisplay(minValueRules);
        const [maxValueClass, numberMsg] = validateDisplay(maxValueRules);
        const [minLengthClass, minLengthMsg] = validateDisplay(minLengthRules);
        const [blockTenClass, blockTenMsg] = validateDisplay(blockTenRules);
        const [blockHundredsClass, blockHundredsMsg] = validateDisplay(
            blockHundredsRules
        );

        return (
            <Fragment>
                {backInputGroup && (
                    <div className="input-group">
                        <input
                            type="number"
                            id={id}
                            value={value}
                            className={`form-control ${requiredClass} ${minValueClass} ${minLengthClass} ${maxValueClass} ${blockTenClass} ${blockHundredsClass} ${className}`}
                            name={name}
                            defaultValue={defaultValue}
                            onChange={onChange}
                            disabled={disabled}
                            required={required}
                            placeholder={placeholder}
                            min={min}
                            max={max}
                            onBlur={onBlur}
                            data-key={data}
                            packageprice={packagePrice}
                        />
                        {/*<div className="input-group-append" onClick={this.handleChange} style={{display: this.state.dropdown_block}}>*/}
                        {/*/!*<span className="input-group-text depth-input form-control">{backInputGroup}</span>*!/*/}
                        {/*<button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                        {/*{backInputGroup}*/}
                        {/*</button>*/}
                        {arrayInputGroup}
                        {/*</div>*/}
                    </div>
                )}

                {!backInputGroup && (
                    <input
                        type="number"
                        id={id}
                        value={value}
                        className={`form-control ${requiredClass} ${minValueClass} ${minLengthClass} ${maxValueClass} ${blockTenClass} ${blockHundredsClass} ${className}`}
                        name={name}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        disabled={disabled}
                        required={required}
                        placeholder={placeholder}
                        min={min}
                        max={max}
                        onBlur={onBlur}
                        data-key={data}
                        packageprice={packagePrice}
                    />
                )}

                <span className="invalid-feedback">
                    {requiredClass === "is-invalid" && `${requiredMsg}`}
                    {requiredClass === "is-valid" &&
                        minValueClass === "is-invalid" &&
                        minValueMsg}
                    {requiredClass === "is-valid" &&
                        maxValueClass === "is-invalid" &&
                        numberMsg}
                    {requiredClass === "is-valid" &&
                        minLengthClass === "is-invalid" &&
                        minLengthMsg}
                    {requiredClass !== "is-invalid" &&
                        minLengthClass !== "is-invalid" &&
                        minValueClass !== "is-invalid" &&
                        maxValueClass !== "is-invalid" &&
                        blockTenClass === "is-invalid" &&
                        blockTenMsg}
                    {requiredClass !== "is-invalid" &&
                        minLengthClass !== "is-invalid" &&
                        minValueClass !== "is-invalid" &&
                        maxValueClass !== "is-invalid" &&
                        blockTenClass !== "is-invalid" &&
                        blockHundredsClass === "is-invalid" &&
                        blockHundredsMsg}
                </span>
            </Fragment>
        );
    }
}

export default NumberInput;

NumberInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,

    defaultValue: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    decimal: PropTypes.bool,
    onBlur: PropTypes.func,
    packagePrice: PropTypes.number,
    textLessThan: PropTypes.string,
    textMoreThan: PropTypes.string,
    requiredMsg: PropTypes.string,
    blockTen: PropTypes.string,
    blockHundreds: PropTypes.string,
    minLength: PropTypes.string,
    minLengthMsg: PropTypes.string,
    data: PropTypes.string,
    backInputGroup: PropTypes.string,
    arrayInputGroup: PropTypes.any,
};

NumberInput.defaultProps = {
    className: "",
};
