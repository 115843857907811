import * as type from './types';
import axios from 'axios';


/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Aun
 */
export const postStaking = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/sales/add', {
        "token":data['token'],
        "username": data['username'],
        "amount": data['amount'],
        "salesType": data['salesType'],
        "secondaryPassword": data['secondaryPassword'],
        "hash": data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_STAKING,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_STAKING,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postConvertedAmount = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/conversion/staking', {
        "username":data['username'],
        "from":data['from'],
        "to": data['to'],
        "amount": data['amount'],
        "hash": data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_CONVERTED_AMOUNT,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_CONVERTED_AMOUNT,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};
