import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import {
    required,
    minLength,
    maxLength,
    passwordFormat,
    checkPasswordMatch,
} from "../../../common/validator/validate";
import { validateDisplay } from "../../../common/validator/formValidation";
import { checkingInit } from "../../../common/helpers";

class PasswordInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",

            requiredRules: required(
                this.props.value,
                true,
                this.props.requiredMsg
            ),
            minLengthRules: minLength(
                this.props.value,
                this.props.minLength,
                true,
                this.props.minLengthMsg
            ),
            maxLengthRules: maxLength(
                this.props.value,
                this.props.maxLength,
                true
            ),
            passwordFormatRules: passwordFormat(
                this.props.value,
                true,
                this.props.passwordFormatMsg
            ),
            checkPasswordMatchRules: checkPasswordMatch(
                this.props.oldPassword,
                this.props.value,
                true,
                this.props.confirmPasswordMsg
            ),
        };
    }

    // shouldComponentUpdate(nextProps) {
    //     return nextProps.value !== this.props.value;
    // }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.value !== prevState.value ||
            nextProps.requiredMsg !== prevState.requiredRules.message
        ) {
            let isInit = checkingInit(nextProps, prevState);
            let payload = {
                value: nextProps.value,
            };

            if (nextProps.required) {
                payload["requiredRules"] = required(
                    nextProps.value,
                    isInit,
                    nextProps.requiredMsg
                );
            }

            if (nextProps.minLength) {
                payload["minLengthRules"] = minLength(
                    nextProps.value,
                    nextProps.minLength,
                    isInit,
                    nextProps.minLengthMsg
                );
            }

            if (nextProps.maxLength) {
                payload["maxLengthRules"] = maxLength(
                    nextProps.value,
                    nextProps.maxLength,
                    isInit
                );
            }

            if (nextProps.passwordformat) {
                payload["passwordFormatRules"] = passwordFormat(
                    nextProps.value,
                    isInit,
                    nextProps.passwordFormatMsg
                );
            }

            if (nextProps.confirmpassword) {
                payload["checkPasswordMatchRules"] = checkPasswordMatch(
                    nextProps.oldPassword,
                    nextProps.value,
                    isInit,
                    nextProps.confirmPasswordMsg
                );
            }

            return payload;
        }

        return null;
    }

    render() {
        const {
            id,
            name,
            onChange,
            className,
            disabled,
            required,
            placeholder,
            minLength,
            maxLength,
            onBlur,
            passwordformat,
            confirmpassword,
        } = this.props;
        const {
            value,
            requiredRules,
            minLengthRules,
            maxLengthRules,
            passwordFormatRules,
            checkPasswordMatchRules,
        } = this.state;

        // validation
        const [requiredClass, requiredMsg] = validateDisplay(requiredRules);
        const [minLengthClass, minLengthMsg] = validateDisplay(minLengthRules);
        const [maxLengthClass, maxLengthMsg] = validateDisplay(maxLengthRules);
        const [passwordFormatClass, passwordFormatMsg] = validateDisplay(
            passwordFormatRules
        );
        const [
            checkPasswordMatchClass,
            checkPasswordMatchMsg,
        ] = validateDisplay(checkPasswordMatchRules);

        return (
            <Fragment>
                <input
                    type="password"
                    id={id}
                    value={value}
                    className={`form-control ${requiredClass} ${passwordFormatClass} ${maxLengthClass} ${minLengthClass} ${checkPasswordMatchClass} ${className}`}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    placeholder={placeholder}
                    minLength={minLength}
                    maxLength={maxLength}
                    onBlur={onBlur}
                    passwordformat={passwordformat}
                    confirmpassword={confirmpassword}
                />
                <div className="invalid-feedback">
                    {requiredClass !== "is-valid" && requiredMsg}
                    {requiredClass === "is-valid" &&
                        minLengthClass === "is-invalid" &&
                        minLengthMsg}
                    {requiredClass === "is-valid" &&
                        maxLengthClass === "is-invalid" &&
                        maxLengthMsg}
                    {minLengthClass === "is-valid" &&
                        maxLengthClass === "is-valid" &&
                        passwordFormatClass === "is-invalid" &&
                        passwordFormatMsg}
                    {requiredClass === "is-valid" &&
                        checkPasswordMatchClass === "is-invalid" &&
                        checkPasswordMatchMsg}
                </div>
            </Fragment>
        );
    }
}

export default PasswordInput;

PasswordInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    minLength: PropTypes.any,
    maxLength: PropTypes.any,
    onBlur: PropTypes.func,
    requiredMsg: PropTypes.string,
    minLengthMsg: PropTypes.string,
    passwordformat: PropTypes.string,
    passwordFormatMsg: PropTypes.string,
    confirmpassword: PropTypes.string,
    confirmPasswordMsg: PropTypes.string,
    oldPassword: PropTypes.string,
};

PasswordInput.defaultProps = {
    className: "",
};
