import React, { Component } from "react";
import { connect } from "react-redux";
import { t } from "../../../common/translation";
import PageHeader from "../../../components/PageHeaderV2";
import NumberInput from "../../../components/input/NumberInput";
import PasswordInput from "../../../components/input/PasswordInput";
import {
    getMemberWallet,
    getTransferSetting,
} from "../../../store/ducks/home/actions";
import {
    postTransfer,
    getUsernameByEmail,
} from "../../../store/ducks/transfer/actions";
import { formValidation } from "../../../common/validator/formValidation";
import { Input, Select } from "antd";
import LoadingMask from "../../../components/loadingMask";
import ConfirmationIcon from "../../../components/ConfirmationAlert";

import {
    amountChecking8,
    fieldChecking,
    GenHash,
    delimiter,
} from "../../../common/helpers";

import "./transfer.scss";
import WalletBadge from "../../../components/WalletBadge";
import HyperModal from "../../../components/HyperModal";

const { Option } = Select;

class Transfer extends Component {
    constructor(props) {
        super(props);

        let transferFirstBadge = sessionStorage.getItem("transferFirstBadge");

        this.state = {
            data: [],
            s_password: "",
            withdraw_limit: 0,
            address: "",
            amount: "",
            balance: 0,
            showLoadingModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            errMsg: "",
            successMsg: "",
            recipient_email: "",
            username: "",
            transferSetup: [],
            transferSetting: [],
            loading: true,
            search_type: "email",
            error: {},
            transferMin: 0,
            walletType:
                props.location.state === undefined
                    ? transferFirstBadge
                    : props.location.state.walletType,
            displayWalletType:
                props.location.state === undefined
                    ? transferFirstBadge
                    : props.location.state.displayWalletCode,
            transferFirstBadge:
                props.location.state === undefined
                    ? transferFirstBadge
                    : props.location.state.displayWalletCode,
            modal_visible: false,
            loading_visible: this.props.loading_visible,
            modal_type: "",
            modal_msg: "",
            confirm_visible: false,
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmitStaking = this.handleSubmitStaking.bind(this);
        this.handleSubmitTransferConfirmation = this.handleSubmitTransferConfirmation.bind(
            this
        );
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `260px`);
    }

    handleChangeSearch(e, v) {
        this.setState({
            search_type: e,
        });
    }

    componentWillMount() {}

    componentDidMount() {
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `260px`);
        });
        formValidation("transfer-form");

        const data = {};
        data.token = this.state.token;
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        if (this.props.transferSetting) {
            let transferSetting;
            transferSetting = this.props.transferSetting.data.data;
            let firstBadge = transferSetting[0].walletTypeCode;
            let walletTypeCode = "";
            transferSetting
                .filter((item) => item.walletTypeCode === firstBadge)
                .map((filterItem) => {
                    walletTypeCode = filterItem.walletTypeCode;
                });

            this.setState({
                transferSetting: transferSetting,
                transferSetup: transferSetting,
            });
            data.walletType = walletTypeCode;
            this.props.getMemberWallet(data);
        } else {
            this.props.getTransferSetting(data);
        }
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    handleTabChange(event, ew, dw) {
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.walletType = ew;
        data.lang = sessionStorage.getItem("lang");
        var hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.getMemberWallet(data);

        this.setState({
            walletType: ew,
            displayWalletType: ew,
            loading_visible: true,
        });
    }

    async handleSubmitTransferConfirmation(event) {
        event.preventDefault();

        let form = document.getElementById("transfer-form");

        let validate = fieldChecking(form);

        if (validate === false) {
            return false;
        }

        this.setState({
            confirm_visible: true,
        });
    }

    handleSubmitStaking(event, pwd) {
        //here changing
        let element = document.getElementById("secondary_password");
        let isPwInValid = element.classList.contains("is-invalid");

        if (!isPwInValid & (pwd !== "")) {
            event.preventDefault();

            const data = {};
            let hash = "";

            data["transferTo"] = this.state.recipient_email;
            data["amount"] = this.state.amount;
            data["walletType"] = this.state.walletType;
            data["secondaryPassword"] = pwd;
            data["search_type"] = this.state.search_type;
            hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
            data["hash"] = hash;

            this.setState(
                {
                    confirm_visible: false,
                    loading_visible: true,
                },
                () => {
                    this.props.postTransfer(data);
                }
            );
        }
    }

    onChange(e) {
        const { name, value } = e.target;

        if (
            (name === "amount" && amountChecking8(value)) ||
            name !== "amount"
        ) {
            this.setState({ [name]: value });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data !== this.props.data) {
            if (nextProps.data.rst === "1") {
                let array;
                array = nextProps.data.data;

                this.setState({
                    data: array.walletBalance[0],
                    loading: false,
                    loading_visible: false,
                    withdraw_limit:
                        nextProps.data.data.walletBalance[0].displayCode !==
                            "PAC" &&
                        nextProps.data.data.walletBalance[0].displayCode !==
                            "LIGA"
                            ? nextProps.data.data.walletBalance[0]
                                  .available_amount
                            : nextProps.data.data.walletBalance[0]
                                  .available_amount,
                });
            } else {
                this.props.history.push({
                    pathname: "/home",
                    state: { error: "invalid_wallet_type_details" },
                });
            }
        }

        if (nextProps.transferSetting !== this.props.transferSetting) {
            // console.log(nextProps.exchangeSetting);
            if (nextProps.transferSetting.data.rst === "1") {
                let transferSetting;
                transferSetting = nextProps.transferSetting.data.data;

                let walletTypeCode = "";
                let count = 0;
                let transferMin = 0;

                for (let i = 0; i < transferSetting.length; i++) {
                    if (transferSetting[i].isUnderMaintenance === "1") {
                        count += 1;
                        if (count === 2) {
                            this.props.history.push({
                                pathname: "/home",
                                state: { error: "invalid_wallet_type_details" },
                            });
                        }
                    }
                }

                let firstBadge = transferSetting[0].walletTypeCode;
                transferSetting
                    .filter((item) => item.walletTypeCode == firstBadge)
                    .map((filterItem) => {
                        walletTypeCode = filterItem.walletTypeCode;
                        transferMin = filterItem.transferMin;
                    });
                this.setState({
                    transferSetting: transferSetting,
                    transferSetup: transferSetting,
                    transferMin: transferMin,
                    walletType: walletTypeCode,
                    displayWalletType: walletTypeCode,
                });

                const data = {};
                data.token = this.state.token;
                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;
                data.walletType = walletTypeCode;
                this.props.getMemberWallet(data);
            }
        }

        if (nextProps.transfer_data !== this.props.transfer_data) {
            let msg = "";

            if (nextProps.transfer_data.rst === "1") {
                const data = {};
                data.username = sessionStorage.getItem("email");
                data.token = sessionStorage.getItem("token");
                data.walletType = this.state.walletType;
                data.lang = sessionStorage.getItem("lang");
                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;

                this.setState({
                    amount: "",
                    s_password: "",
                    loading_visible: false,
                    modal_visible: true,
                    modal_type: "success",
                    modal_msg: nextProps.transfer_data.msg,
                });

                this.props.getMemberWallet(data);
            } else {
                this.setState({
                    loading_visible: false,
                    modal_visible: true,
                    modal_type: "error",
                    modal_msg: nextProps.transfer_data.msg,
                });
            }
        }

        if (nextProps.username_data !== this.props.username_data) {
            let msg = "";
            this.state.error.username = "";
            if (nextProps.username_data.rst === "1") {
                this.setState({
                    username: nextProps.username_data.data,
                    error: this.state.error,
                });
            } else {
                this.state.error.username = t("common.email_not_exist");
                this.setState({
                    username: "",
                    error: this.state.error,
                });
            }
        }
    }

    render() {
        const {
            data,
            loading,
            balance,
            transferSetting,
            displayWalletType,
            transferSetup,
            confirm_visible,
            loading_visible,
            modal_visible,
            modal_type,
            modal_msg,
        } = this.state;

        const selectAfter = (
            <Select
                defaultValue="email"
                name="search_type"
                className="select-after select-afteridth-100"
                onSelect={this.handleChangeSearch}
            >
                <Option key={1} value="email">
                    {t("placement.email")}
                </Option>
                <Option key={2} value="username">
                    {t("common.username")}
                </Option>
            </Select>
        );

        return (
            <div className="transfer">
                <PageHeader
                    title={t("transfer.transfer")}
                    titleHeader="col-12 text-center"
                    backgroundHeight={230}
                    prevousPageLink=""
                    navClass=""
                    historyBack="/"
                    historyBackClass=""
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={data.displayCode ? data.displayCode : ""}
                    balance={data.total_amount ? data.total_amount : balance}
                    lockedAmount={data.locked_amount}
                    availableAmount={data.available_amount}
                    arrowLeft={true}
                    headerBanner={true}
                />
                <div
                    className="transfer-wrapper col-12 px-0 overflow-auto custom-height"
                    // style={{ height: "calc(100vh - 230px)" }}
                >
                    {transferSetting.length > 0 && (
                        <WalletBadge
                            walletArrProps={transferSetting}
                            badgeActive={displayWalletType}
                            badgeOnClick={this.handleTabChange}
                        />
                    )}
                    <HyperModal
                        category="s_password"
                        modalVisible={confirm_visible}
                        submitFunc={this.handleSubmitStaking}
                        cancelFunc={this.handleModalClose}
                        closable={true}
                    />
                    {/* <HyperModal
                        category="normal"
                        modalVisible={confirm_visible}
                        onOk={this.handleSubmitStaking}
                        footer={
                            <div className="col-12 text-center">
                                <button
                                    key="submit"
                                    className="col-3 btn btn-primary"
                                    onClick={this.handleSubmitStaking}
                                >
                                    {t("common.submit")}
                                </button>
                                <button
                                    key="submit"
                                    className="col-3 btn btn-danger"
                                    onClick={(e) =>
                                        this.handleModalClose(e, "confirm")
                                    }
                                >
                                    {t("purchasePin.modal_close")}
                                </button>
                            </div>
                        }
                        modalContent={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                   
                                    <p>{t("transfer.confirmation_msg")}</p>
                                </div>
                            </div>
                        }
                        onCancel={this.handleModalClose}
                        closable={false}
                    /> */}

                    <HyperModal
                        category="response"
                        modalVisible={loading_visible}
                        modalType="loading"
                        footer={[]}
                    />

                    <HyperModal
                        category="response"
                        modalVisible={modal_visible}
                        modalType={modal_type}
                        modalMsg={modal_msg}
                        modalCloseFunc={this.handleModalClose}
                        cancelFunc={this.handleModalClose}
                    />

                    {!loading && (
                        <div className="offset-md-2 col-md-8 px-2">
                            <form
                                id="transfer-form"
                                onSubmit={this.handleSubmitTransferConfirmation}
                                className="needs-validation col-12 px-0"
                                noValidate
                            >
                                <div className="col-12">
                                    <div className="row">
                                        <label className="depth-gray-label text-uppercase">
                                            {t("transfer.transfer_to")}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row">
                                        <Input
                                            id="search_erecipient_emailmail"
                                            name="recipient_email"
                                            className="remove-placeholder add_on_class"
                                            addonAfter={selectAfter}
                                            onChange={this.onChange}
                                            value={this.state.recipient_email}
                                            type="text"
                                        />
                                        {this.state.recipient_email == "" ? (
                                            <span
                                                class="invalid-feedback"
                                                style={{ display: "block" }}
                                            >
                                                {t(
                                                    "transfer.this_field_is_required"
                                                )}{" "}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>

                                <div className="col-12 pt-3">
                                    <div className="row">
                                        <label className="depth-gray-label text-uppercase">
                                            {t("transfer.amount")}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row">
                                        <NumberInput
                                            id="amount"
                                            name="amount"
                                            onChange={this.onChange}
                                            value={this.state.amount}
                                            // min={delimiter(
                                            //     this.state.transferMin
                                            // )}
                                            // max={delimiter(
                                            //     this.state.withdraw_limit
                                            // )}
                                            placeholder="0.00000000"
                                            className="remove-placeholder depth-input"
                                            requiredMsg={t(
                                                "validator.requiredMsg"
                                            )}
                                            textLessThan={t(
                                                "validator.amount_should_less_than"
                                            )}
                                            textMoreThan={t(
                                                "validator.amount_should_more_than"
                                            )}
                                            required
                                        />
                                        <p className="subscribe-note m-0 text-capitalize">
                                            {t("withdrawal.minimum")}:{" "}
                                            {this.state.transferMin}
                                        </p>
                                    </div>
                                </div>

                                {/* <div className="col-12 pt-3">
                                    <div className="row">
                                        <label className="depth-gray-label text-uppercase">
                                            {t("transfer.secondary_password")}
                                        </label>
                                    </div>
                                </div> */}

                                {/* <div className="col-12">
                                    <div className="row">
                                        <PasswordInput
                                            id="s_password"
                                            name="s_password"
                                            onChange={this.onChange}
                                            value={this.state.s_password}
                                            placeholder={t(
                                                "transfer.secondary_password"
                                            )}
                                            className="remove-placeholder depth-input"
                                            requiredMsg={t(
                                                "validator.requiredMsg"
                                            )}
                                            required
                                        />
                                    </div>
                                </div> */}

                                <div className="pt-4">
                                    <button
                                        type="submit"
                                        className="col-12 depth-blue-btn"
                                    >
                                        {t("transfer.submit")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}

                    {loading && (
                        <div className="container pt-5">
                            <div className="row">
                                <div className="mx-auto">
                                    <LoadingMask width={80} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, transfer } = state;

    return {
        data: walletBalance.memberWallet.data,
        transfer_data: transfer.data,
        username_data: transfer.getUsernameByEmail,
        transferSetting: walletBalance.transferData,
    };
};

const mapDispatchToProps = {
    getMemberWallet,
    postTransfer,
    getUsernameByEmail,
    getTransferSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfer);
