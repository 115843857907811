import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const getTwoFactorAuthenticationReducer = createReducer(initialState) ({

    [type.GET_TWO_FACTOR_AUTHENTICATION]: (state, action) => ({
        ...state,
        twoFactorAuthData: action.payload,
    }),

    [type.POST_TWO_FACTOR_AUTHENTICATION]: (state, action) => ({
        ...state,
        postTwoFactorAuthData: action.payload,
    }),

    [type.VERIFY_TWO_FACTOR_AUTHENTICATION]: (state, action) => ({
        ...state,
        verifyTwoFactorData: action.payload,
    }),

});

export default getTwoFactorAuthenticationReducer;