import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Progress, Tabs, Select } from "antd";
// import { setLocale, t } from "react-i18nify";
import { t, setLanguageFileJson } from "../../common/translation";
import "./market.scss";
import {
    getMarketList,
    // getOrderTransaction,
    getMemberOpenOrder,
    getBuyOrderBook,
    getSellOrderBook,
    postBuyExchange,
    postSellExchange,
    postCancelOrder,
} from "../../store/ducks/market/actions";
import { getAllMemberWallet } from "../../store/ducks/home/actions";
import HalfExchange from "../../public/v3/icon/updown-price.svg";
import RedExchange from "../../public/v3/icon/down-price.png";
import GreenExchange from "../../public/v3/icon/up-price.svg";
import StatementOpen from "../../public/v3/icon/statement-open-order.svg";
import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeaderV2";
import HyperModal from "../../components/HyperModal";
import CandleStickChart from "./candleCart";
import {
    amountChecking8,
    fieldChecking,
    GenHash,
    delimiter,
    slice_decimal_wt_rounding,
} from "../../common/helpers";
// import socketIOClient from "socket.io-client";

const { Option } = Select;
const InputGroup = Input.Group;
class Market extends Component {
    constructor(props) {
        super(props);

        this.state = {
            market_list: "",
            order_list: "",
            buy_order_list: "",
            sell_order_list: "",
            mem_open_order: "",
            is_buy: true,
            sell_order_book: "",
            buy_order_book: "",
            crypto_code: "",
            confirm_visible: false,
            qty: "",
            price: "",
            errors: {},
            balance: 0,
            total_usdt: 0,
            crypto_code_to: "",
            market_data: [],
            order_filter: "HALF",
            current_price: 0,
            is_init: true,
            // decimal_from: 0,
            // decimal_to: 0,
            change_dropdown: false,
            active_tab: "1",
            market_crypto: "",
            change_price: false,
            decimal_display: 0,
        };

        this.handleAmountBtn = this.handleAmountBtn.bind(this);
        this.handleSubmitExchange = this.handleSubmitExchange.bind(this);
        this.handleClickOnTag = this.handleClickOnTag.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleClickWallet = this.handleClickWallet.bind(this);
        this.handleConfirmExchange = this.handleConfirmExchange.bind(this);
        this.changeAmount = this.changeAmount.bind(this);
        this.handlePercent = this.handlePercent.bind(this);
        this.handleOrderBook = this.handleOrderBook.bind(this);
        this.cancelExchange = this.cancelExchange.bind(this);
        // this.handleMarketOrder = this.handleMarketOrder.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `120px`);
    }

    handleMarketOrder(e, crypto_code, market_item, crypto_code_to) {
        // console.log("in market", crypto_code);
        // let data1 = {};
        // data1.walletType = this.state.crypto_code_to.toUpperCase();
        // this.props.getAllMemberWallet(data1);
        // let sellData = { page: 1, crypto_code: crypto_code.toUpperCase() };
        // let buyData = { page: 1, crypto_code: crypto_code.toUpperCase() };

        // this.props.getBuyOrderBook(buyData);
        // this.props.getSellOrderBook(sellData);
        // console.log(market_item);
        // this.setState({
        //     active_tab: "2",
        //     crypto_code: crypto_code,
        //     change_price: true,
        //     price: slice_decimal_wt_rounding(
        //         market_item.unit_price_display,
        //         this.state.decimal_display
        //     ),
        // });
        this.props.history.push({
            pathname: "/market/trading/view",
            state: {
                market_item: market_item,
                crypto_code: crypto_code,
                crypto_code_to: crypto_code_to,
            },
        });
    }
    cancelExchange(e, doc_no) {
        this.setState({
            confirm_visible: true,
            doc_no: doc_no,
            is_cancel: true,
        });
    }

    handleOrderBook(e, type) {
        this.setState({
            order_filter: type,
        });
    }
    handlePercent(e, percent) {
        let total_usdt = 0;
        let qty = 0;
        if (this.state.price !== "") {
            if (this.state.is_buy) {
                total_usdt =
                    parseFloat(this.state.balance) *
                    (parseFloat(percent) / 100);
                qty = parseFloat(total_usdt) / parseFloat(this.state.price);
            } else {
                qty =
                    parseFloat(this.state.balance) *
                    (parseFloat(percent) / 100);
                total_usdt = parseFloat(qty) * parseFloat(this.state.price);
            }
            this.setState({
                total_usdt: slice_decimal_wt_rounding(
                    total_usdt,
                    this.state.decimal_display
                ),
                qty: slice_decimal_wt_rounding(qty, this.state.decimal_display),
                percent: percent,
            });
        }
    }
    changeAmount(e) {
        if (amountChecking8(e.target.value)) {
            if (e.target.value !== "") {
                let total_usdt = this.state.total_usdt;
                let percent = this.state.percent;
                if (e.target.name == "qty" && this.state.price !== "") {
                    total_usdt =
                        parseFloat(e.target.value) *
                        parseFloat(this.state.price);
                    percent = 0;
                } else if (e.target.name == "price" && this.state.qty !== "") {
                    total_usdt =
                        parseFloat(e.target.value) * parseFloat(this.state.qty);
                    percent = 0;
                } else {
                    total_usdt = 0;
                }
                this.setState({
                    total_usdt: slice_decimal_wt_rounding(
                        total_usdt,
                        this.state.decimal_display
                    ),
                    percent: percent,
                });
            }
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    }

    handleClickWallet(e) {
        let sellData = { page: 1, crypto_code: e.toUpperCase() };
        let buyData = { page: 1, crypto_code: e.toUpperCase() };

        this.props.getBuyOrderBook(buyData);
        this.props.getSellOrderBook(sellData);

        let data1 = {};
        if (this.state.is_buy) {
            data1.walletType = "USDT";
        } else {
            data1.walletType = e.toUpperCase();
        }

        this.props.getAllMemberWallet(data1);
        this.setState({
            crypto_code: e,
            change_dropdown: true,
            total_usdt: 0,
            price: 0,
            qty: 0,
        });
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                //this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }
    handleClickOnTag(key) {
        let data1 = {};
        switch (key) {
            case "1":
                this.setState({
                    is_buy: false,
                });
            case "2":
                this.setState({
                    is_buy: true,
                });
                data1.walletType = this.state.crypto_code_to.toUpperCase();
                this.props.getAllMemberWallet(data1);
                break;
            case "3":
                this.setState({
                    is_buy: false,
                });
                data1.walletType = this.state.crypto_code.toUpperCase();
                this.props.getAllMemberWallet(data1);
                break;
        }
        console.log("heree", this.state.crypto_code);

        let sellData = {
            page: 1,
            crypto_code: this.state.crypto_code.toUpperCase(),
        };
        let buyData = {
            page: 1,
            crypto_code: this.state.crypto_code.toUpperCase(),
        };

        this.props.getBuyOrderBook(buyData);
        this.props.getSellOrderBook(sellData);

        this.setState({
            active_tab: key,
            total_usdt: 0,
            qty: 0,
            percent: 0,
        });
    }

    handleSubmitExchange(e, pwd) {
        let element = document.getElementById("secondary_password");
        let isPwInValid = element.classList.contains("is-invalid");

        if (!isPwInValid & (pwd !== "")) {
            if (this.state.is_cancel) {
                let data = {};
                data.secondary_password = pwd;
                data.doc_no = this.state.doc_no;
                this.props.postCancelOrder(data);
            } else {
                if (this.state.is_buy) {
                    let datasubmit = {};
                    datasubmit.qty = this.state.qty;
                    datasubmit.unit_price = this.state.price;
                    datasubmit.wallet_type =
                        this.state.crypto_code.toUpperCase();
                    datasubmit.base_wallet = "USDT";
                    datasubmit.secondary_password = pwd;

                    //buy
                    this.props.postBuyExchange(datasubmit);
                } else {
                    let datasubmit = {};
                    datasubmit.qty = this.state.qty;
                    datasubmit.unit_price = this.state.price;
                    datasubmit.wallet_type =
                        this.state.crypto_code.toUpperCase();
                    datasubmit.base_wallet = "USDT";
                    datasubmit.secondary_password = pwd;

                    //buy
                    this.props.postSellExchange(datasubmit);

                    // {
                    //     "qty": "100",
                    //     "unit_price": "1.2",
                    //     "wallet_type": "LIGA",
                    //     "base_wallet": "USDT",
                    //     "secondary_password": "123123"
                    // }
                }
            }
            this.setState({
                confirm_visible: false,
            });
        }
    }

    async handleConfirmExchange(event) {
        event.preventDefault();
        let form = document.getElementById("exchange-form");
        let validate = fieldChecking(form);

        let gtError = false;

        let errors = this.state.errors;
        if (this.state.qty == "" || this.state.qty <= 0) {
            errors.qty = true;
            gtError = true;
        } else {
            errors.price = false;
        }

        if (this.state.price == "" || this.state.price <= 0) {
            errors.price = true;
            gtError = true;
        } else {
            errors.price = false;
        }
        if (validate === false) {
            gtError = true;
        }

        if (!gtError) {
            this.setState({
                confirm_visible: true,
                errors: errors,
                is_cancel: false,
            });
        } else {
            this.setState({
                errors: errors,
                is_cancel: false,
            });
        }
    }

    decimalCount = (num) => {
        // Convert to String
        const numStr = String(num);
        // String Contains Decimal
        if (numStr.includes(".")) {
            return numStr.split(".")[1].length;
        }
        // String Does Not Contain Decimal
        return 0;
    };

    handleAmountBtn(e, action, name) {
        if (name == "price") {
            let currentD = this.decimalCount(this.state.price);

            let A = "";
            let i;
            for (i = 1; i < parseFloat(currentD); i++) {
                A = A.concat(0);
            }
            A = A.concat(1);
            A = "0." + A;

            let price = 0;
            if (action == "plus") {
                price = parseFloat(this.state.price) + parseFloat(A);
            } else {
                price = parseFloat(this.state.price) - parseFloat(A);
            }
            price = price.toFixed(12);

            if (price >= 0) {
                this.setState({
                    price: (price * 1).toString(),
                });
            }
        } else {
            let currentD = this.decimalCount(this.state.qty);

            let A = "";
            let i;
            for (i = 1; i < parseFloat(currentD); i++) {
                A = A.concat(0);
            }
            A = A.concat(1);
            A = "0." + A;

            let qty = 0;
            if (action == "plus") {
                qty = parseFloat(this.state.qty) + parseFloat(A);
            } else {
                qty = parseFloat(this.state.qty) - parseFloat(A);
            }
            qty = qty.toFixed(12);

            if (qty >= 0) {
                this.setState({
                    qty: (qty * 1).toString(),
                });
            }
            // this.setState({
            //     qty: (qty * 1).toString(),
            // });
        }
    }
    componentDidMount() {
        // const ENDPOINT = "gmsocket.securelayers.cloud";
        // const socket = socketIOClient(ENDPOINT);

        // socket.on("market-price", (data) => {
        //     console.log("new socket", data);
        //     this.setState({
        //         market_data: data.current_page_items,
        //     });
        // });

        if (this.props.location.state) {
            let data1 = {};

            let currentCode = this.props.location.state.crypto_code;
            switch (this.props.location.state.action) {
                case "2":
                    this.setState({
                        is_buy: true,
                    });
                    data1.walletType = currentCode.toUpperCase();
                    this.props.getAllMemberWallet(data1);
                    break;
                case "3":
                    this.setState({
                        is_buy: false,
                    });
                    data1.walletType = currentCode.toUpperCase();
                    this.props.getAllMemberWallet(data1);
                    break;
            }
            this.setState({
                active_tab: this.props.location.state.action,
                total_usdt: 0,
                qty: 0,
                percent: 0,
                currentCode: currentCode,
            });
        }
        let data = {};
        data.page = 1;
        this.props.getMarketList(data);
        // this.props.getOrderTransaction();
        let data3 = {};
        data3.page = 1;
        data3.date_from = "";
        data3.date_to = "";
        data3.action_type = "";
        this.props.getMemberOpenOrder(data3);

        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `120px`);
        });

        // setInterval(() => {
        //     let data = {};
        //     data.page = 1;
        //     this.props.getMarketList(data);
        //     // this.props.getOrderTransaction();
        //     let data3 = {};
        //     data3.page = 1;
        //     data3.date_from = "";
        //     data3.date_to = "";
        //     data3.action_type = "";
        //     this.props.getMemberOpenOrder(data3);
        // }, 5000);

        // setLocale(sessionStorage.getItem("lang"));
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }
        if (
            nextProps.post_exchange_result !== this.props.post_exchange_result
        ) {
            if (nextProps.post_exchange_result.data.rst == "1") {
                this.setState({
                    modal_msg: nextProps.post_exchange_result.data.msg,
                    modal_type: "success",
                    modal_visible: true,
                });
            } else {
                this.setState({
                    modal_msg: nextProps.post_exchange_result.data.msg,
                    modal_type: "error",
                    modal_visible: true,
                });
            }
            let data = {};
            data.page = 1;
            this.props.getMarketList(data);
            let sellData = {
                page: 1,
                crypto_code: this.state.crypto_code.toUpperCase(),
            };
            let buyData = {
                page: 1,
                crypto_code: this.state.crypto_code.toUpperCase(),
            };

            this.props.getBuyOrderBook(buyData);
            this.props.getSellOrderBook(sellData);
            // this.props.getOrderTransaction();
            let data3 = {};
            data3.page = 1;
            data3.date_from = "";
            data3.date_to = "";
            data3.action_type = "";
            this.props.getMemberOpenOrder(data3);
            this.setState({
                is_post_call: true,
            });
        }

        if (nextProps.memberWallet !== this.props.memberWallet) {
            if (nextProps.memberWallet.data.rst === "1") {
                this.setState({
                    memberWallet:
                        nextProps.memberWallet.data.data.walletBalance[0],
                    balance:
                        nextProps.memberWallet.data.data.walletBalance[0]
                            .available_amount,
                });
            }
        }

        if (nextProps.buy_order_book !== this.props.buy_order_book) {
            if (
                nextProps.buy_order_book.data.rst == "1" &&
                nextProps.buy_order_book.data.data
            ) {
                let i = 0;
                console.log("in order book");
                let buy_order_list =
                    nextProps.buy_order_book.data.data.available_trading_price_list.map(
                        (item) => (
                            <div
                                className="col-12 d-flex px-0 font-12"
                                key={i++}
                            >
                                <div className="col-6 px-0 text-green text-left">
                                    {item.unit_price_display}
                                </div>
                                <div className="col-6 px-0 text-right text-white">
                                    {item.quantity_display}
                                </div>
                            </div>
                        )
                    );
                let price =
                    nextProps.buy_order_book.data.data.unit_price_display.replace(
                        " USDT",
                        ""
                    );

                this.setState({
                    buy_order_list: buy_order_list,

                    current_price: parseFloat(price),
                });

                if (this.state.is_init || this.state.change_dropdown) {
                    this.setState({
                        price: parseFloat(price),
                        is_init: false,
                        change_dropdown: false,
                    });
                }
            }
        }
        if (nextProps.sell_order_book !== this.props.sell_order_book) {
            if (
                nextProps.sell_order_book.data.rst == "1" &&
                nextProps.sell_order_book.data.data
            ) {
                let sell_order_list =
                    nextProps.sell_order_book.data.data.available_trading_price_list.map(
                        (item) => (
                            <div
                                className="col-12 d-flex px-0 font-12"
                                key={item.id}
                            >
                                <div className="col-6 px-0 text-red text-left">
                                    {item.unit_price_display}
                                </div>
                                <div className="col-6 px-0 text-right text-white">
                                    {item.quantity_display}
                                </div>
                            </div>
                        )
                    );
                let price =
                    nextProps.sell_order_book.data.data.unit_price_display.replace(
                        " USDT",
                        ""
                    );
                this.setState({
                    sell_order_list: sell_order_list,
                    current_price: parseFloat(price),
                });
            }
        }
        if (nextProps.market_data !== this.props.market_data) {
            // let market_list = [];

            if (nextProps.market_data.data.rst == "1") {
                let crypto_code = this.state.crypto_code;
                let crypto_code_to = this.state.crypto_code_to;
                // let decimal_from = this.state.decimal_from;
                // let decimal_to = this.state.decimal_to;
                let decimal_display = this.state.decimal_display;
                if (this.state.is_init) {
                    crypto_code =
                        nextProps.market_data.data.data[0].crypto_code_from;
                    crypto_code_to =
                        nextProps.market_data.data.data[0].crypto_code_to;
                    decimal_display =
                        nextProps.market_data.data.data[0].decimal_from;
                }

                let sellData = { page: 1 };
                let buyData = { page: 1 };
                if (this.state.is_post_call) {
                    crypto_code = this.state.crypto_code;
                    sellData.crypto_code = crypto_code.toUpperCase();
                    buyData.crypto_code = crypto_code.toUpperCase();
                } else if (this.state.currentCode) {
                    crypto_code = this.state.currentCode;
                    buyData.crypto_code = this.state.currentCode.toUpperCase();
                    sellData.crypto_code = this.state.currentCode.toUpperCase();
                }

                this.props.getBuyOrderBook(buyData);
                this.props.getSellOrderBook(sellData);
                let data1 = {};
                if (this.state.is_init || this.state.is_post_call) {
                    if (this.state.is_buy) {
                        data1.walletType = crypto_code_to.toUpperCase();
                        this.props.getAllMemberWallet(data1);
                    } else {
                        data1.walletType = crypto_code.toUpperCase();
                        this.props.getAllMemberWallet(data1);
                    }
                }

                this.setState({
                    is_post_call: false,
                    // market_list: market_list,
                    market_data: nextProps.market_data.data.data,
                    crypto_code: crypto_code,
                    crypto_code_to: crypto_code_to,
                    decimal_display: decimal_display,
                });
            }
        }

        if (nextProps.mem_open_order !== this.props.mem_open_order) {
            if (nextProps.mem_open_order.data.rst == "1") {
                var i = 0;
                let mem_open_order =
                    nextProps.mem_open_order.data.data.current_page_items.map(
                        (item) => (
                            <div
                                className="open-order-item col-12 d-flex py-3"
                                key={i++}
                            >
                                {item.action_type == "BUY" ? (
                                    <>
                                        <div className="order-percentage col-2 px-0">
                                            <div className="font-12 text-green">
                                                {t("exchange.buy_limit")}
                                            </div>
                                            <Progress
                                                type="circle"
                                                percent={
                                                    item.matched_rate == 0
                                                        ? 0
                                                        : parseFloat(
                                                              item.matched_rate
                                                          ).toFixed(2) * 100
                                                }
                                                width={47}
                                                strokeColor="green"
                                                trailColor="#404040"
                                                strokeWidth={12}
                                                format={() => (
                                                    <span className="text-green">
                                                        {parseFloat(
                                                            item.matched_rate
                                                        ).toFixed(2) * 100}
                                                        %
                                                    </span>
                                                )}
                                            />
                                        </div>
                                        <div className="order-detail col-7 px-2">
                                            <div className="font-14 text-uppercase text-white pb-2 font-weight-bold">
                                                <span>
                                                    {/* {item.wallet_code} /{" "}
                                                {item.wallet_code_to} */}
                                                    {item.coin_pair}
                                                </span>
                                            </div>
                                            <div className="col-12 d-flex px-0 font-12">
                                                <div className="text-dark-grey w-50">
                                                    {t("common.price")}
                                                </div>
                                                <div className="text-white">
                                                    {item.unit_price_display}
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex px-0 font-12">
                                                <div className="text-dark-grey w-50">
                                                    {t("common.amount")}
                                                </div>
                                                <div className="text-white">
                                                    {parseFloat(
                                                        item.matched_quantity_display
                                                    ).toString()}{" "}
                                                    /{" "}
                                                    {
                                                        item.total_quantity_display
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order-date col-3 px-0 text-right">
                                            <div className="text-dark-grey font-12 pb-2">
                                                {item.trans_date_time}
                                            </div>
                                            <div className="">
                                                {item.display_cancel_button ? (
                                                    <button
                                                        onClick={(e) =>
                                                            this.cancelExchange(
                                                                e,
                                                                item.doc_no
                                                            )
                                                        }
                                                        className="btn bg-light-purple text-white font-12 text-uppercase"
                                                    >
                                                        {t("common.cancel")}
                                                    </button>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="order-percentage col-2 px-0">
                                            <div className="font-12 text-red">
                                                {t("exchange.sell_limit")}
                                            </div>
                                            <Progress
                                                type="circle"
                                                percent={
                                                    item.matched_rate == 0
                                                        ? 0
                                                        : parseFloat(
                                                              item.matched_rate
                                                          ).toFixed(2) * 100
                                                }
                                                width={47}
                                                strokeColor="red"
                                                trailColor="#404040"
                                                strokeWidth={12}
                                                format={() => (
                                                    <span className="text-red">
                                                        {parseFloat(
                                                            item.matched_rate
                                                        ).toFixed(2) * 100}
                                                        %
                                                    </span>
                                                )}
                                            />
                                        </div>
                                        <div className="order-detail col-7 px-2">
                                            <div className="font-14 text-uppercase text-white pb-2 font-weight-bold">
                                                <span>{item.coin_pair}</span>
                                            </div>
                                            <div className="col-12 d-flex px-0 font-12">
                                                <div className="text-dark-grey w-50">
                                                    {t("common.price")}
                                                </div>
                                                <div className="text-white">
                                                    {item.unit_price_display}
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex px-0 font-12">
                                                <div className="text-dark-grey w-50">
                                                    {t("common.amount")}
                                                </div>
                                                <div className="text-white">
                                                    {
                                                        item.matched_quantity_display
                                                    }{" "}
                                                    /{" "}
                                                    {
                                                        item.total_quantity_display
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order-date col-3 px-0 text-right">
                                            <div className="text-dark-grey font-12 pb-2">
                                                {item.trans_date_time}
                                            </div>
                                            <div className="">
                                                {item.display_cancel_button ? (
                                                    <button
                                                        onClick={(e) =>
                                                            this.cancelExchange(
                                                                e,
                                                                item.doc_no
                                                            )
                                                        }
                                                        className="btn bg-light-purple text-white font-12 text-uppercase"
                                                    >
                                                        {t("common.cancel")}
                                                    </button>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )
                    );
                this.setState({
                    mem_open_order: mem_open_order,
                });
            }
        }
    }

    render() {
        const TabPane = Tabs.TabPane;
        const selectAfter = "USDT";
        const {
            loading_visible,
            confirm_visible,
            modal_visible,
            modal_type,
            modal_msg,
            crypto_code,
            decimal_from,
            decimal_to,
            market_data,
        } = this.state;

        const market_list = market_data.map((item) => (
            <tr
                className="bg-dark-purple"
                key={item.crypto_code_from}
                onClick={(e) =>
                    this.handleMarketOrder(
                        e,
                        item.crypto_code_from,
                        item,
                        item.crypto_code_to
                    )
                }
            >
                <td>
                    <div>
                        <div className="text-white font-16 text-uppercase">
                            <span>{item.crypto_code_from}</span>/
                            <span className="text-light-blue">
                                {item.crypto_code_to}
                            </span>
                        </div>
                        <div className="text-dark-grey font-12">
                            {item.changes_period_unit +
                                " " +
                                slice_decimal_wt_rounding(
                                    item.total_amount_display,
                                    item.decimal_display
                                )}
                        </div>
                    </div>
                </td>
                <td>
                    {" "}
                    <div
                        className="font-16"
                        style={{ color: item.total_amount_color_code }}
                    >
                        {slice_decimal_wt_rounding(
                            item.unit_price_display,
                            item.decimal_from
                        )}
                    </div>
                    <div className="text-dark-grey font-12">{"= 1 USDT"}</div>
                </td>
                <td className="table-text-center font-16">
                    <div>
                        <span
                            className="p-2 btn text-white"
                            style={{
                                backgroundColor:
                                    item.changes_period_percent_color_code,
                            }}
                        >
                            {item.changes_period_percent_display}
                        </span>
                    </div>
                </td>
            </tr>
        ));
        const buy_page = (
            <div className="col-12 px-0 buy-container bg-blue">
                <div className="col-12 d-flex p-3">
                    <form
                        id="exchange-form"
                        onSubmit={this.handleConfirmExchange}
                        className="needs-validation col-6 px-0"
                        noValidate
                    >
                        <div className="col-12 px-0">
                            <div className="dropdown-area">
                                <div className="form-group">
                                    <Select
                                        className="select-after select-width"
                                        onSelect={this.handleClickWallet}
                                        value={crypto_code}
                                    >
                                        {this.state.market_data.map((item) => (
                                            <Option
                                                key={item.crypto_code_from}
                                                className="text-uppercase"
                                                value={item.crypto_code_from}
                                            >
                                                {item.crypto_code_from.toUpperCase() +
                                                    "/" +
                                                    item.crypto_code_to}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="quantity-area">
                                <div className="form-group">
                                    {/* <Input
                                className="remove-placeholder exchange-input"
                                placeholder="0.00000000"
                                value={this.state.amountSpend}
                                type="number"
                            /> */}
                                    <div className="text-light-blue font-12">
                                        {t("common.price")}
                                    </div>
                                    <Input
                                        required
                                        name="price"
                                        onChange={this.changeAmount}
                                        value={this.state.price}
                                        placeholder={
                                            t("common.amount") +
                                            " (" +
                                            this.state.crypto_code_to +
                                            ")"
                                        }
                                        prefix={
                                            <div
                                                className="text-white pointer"
                                                onClick={(e) =>
                                                    this.handleAmountBtn(
                                                        e,
                                                        "minus",
                                                        "price"
                                                    )
                                                }
                                            >
                                                <span className="font-30">
                                                    {" "}
                                                    -{" "}
                                                </span>
                                            </div>
                                        }
                                        suffix={
                                            <div
                                                className="text-white pointer font-30"
                                                onClick={(e) =>
                                                    this.handleAmountBtn(
                                                        e,
                                                        "plus",
                                                        "price"
                                                    )
                                                }
                                            >
                                                +
                                            </div>
                                        }
                                    />
                                    {this.state.errors.price ? (
                                        <span
                                            class="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {t(
                                                "transfer.this_field_is_required"
                                            )}{" "}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="quantity-area">
                                <div className="form-group">
                                    <div className="text-light-blue font-12">
                                        {t("common.quantity")}
                                    </div>
                                    <Input
                                        name="qty"
                                        onChange={this.changeAmount}
                                        // type="number"
                                        value={this.state.qty}
                                        placeholder={
                                            t("common.amount") +
                                            " (" +
                                            crypto_code +
                                            ")"
                                        }
                                        prefix={
                                            <div
                                                className="text-white pointer"
                                                onClick={(e) =>
                                                    this.handleAmountBtn(
                                                        e,
                                                        "minus",
                                                        "qty"
                                                    )
                                                }
                                            >
                                                <span className="font-30">
                                                    {" "}
                                                    -{" "}
                                                </span>
                                            </div>
                                        }
                                        suffix={
                                            <div
                                                className="text-white pointer font-30"
                                                onClick={(e) =>
                                                    this.handleAmountBtn(
                                                        e,
                                                        "plus",
                                                        "qty"
                                                    )
                                                }
                                            >
                                                +
                                            </div>
                                        }
                                    />
                                    {this.state.errors.qty ? (
                                        <span
                                            class="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {t(
                                                "transfer.this_field_is_required"
                                            )}{" "}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="form-group text-light-blue d-flex">
                                    <div className="w-100 font-12">
                                        {t("common.available")}
                                    </div>
                                    <div className="font-12">
                                        {this.state.balance}
                                    </div>
                                </div>
                            </div>
                            <div className="percentage-area col-12 px-0 d-flex form-group">
                                <div className="col-3 px-1 percent-wrapper">
                                    <div
                                        onClick={(e) =>
                                            this.handlePercent(e, 25)
                                        }
                                        className="col-12 px-0 text-dark-grey text-center percent_border pointer"
                                    >
                                        {this.state.percent == "25" ? (
                                            <span className="font-12 text-white">
                                                25%
                                            </span>
                                        ) : (
                                            <span className="font-12">25%</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-3 px-1 percent-wrapper">
                                    <div
                                        onClick={(e) =>
                                            this.handlePercent(e, 50)
                                        }
                                        className="col-12 px-0 text-dark-grey text-center percent_border pointer"
                                    >
                                        {this.state.percent == "50" ? (
                                            <span className="font-12 text-white">
                                                50%
                                            </span>
                                        ) : (
                                            <span className="font-12">50%</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-3 px-1 percent-wrapper">
                                    <div
                                        onClick={(e) =>
                                            this.handlePercent(e, 75)
                                        }
                                        className="col-12 px-0 text-dark-grey text-center percent_border pointer"
                                    >
                                        {this.state.percent == "75" ? (
                                            <span className="font-12 text-white">
                                                75%
                                            </span>
                                        ) : (
                                            <span className="font-12">75%</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-3 px-1 percent-wrapper">
                                    <div
                                        onClick={(e) =>
                                            this.handlePercent(e, 100)
                                        }
                                        className="col-12 px-0 text-dark-grey text-center percent_border pointer"
                                    >
                                        {this.state.percent == "100" ? (
                                            <span className="font-12 text-white">
                                                100%
                                            </span>
                                        ) : (
                                            <span className="font-12">
                                                100%
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="total-amount-area col-12 px-0">
                                <div className="text-light-blue font-12 form-group">
                                    {t("common.total_amount")}
                                </div>
                                <div className="text-white font-18 form-group">
                                    {this.state.total_usdt}
                                </div>
                            </div>
                            <div className=" col-12 px-0">
                                <button
                                    type="submit"
                                    className="col-12 button border-radius-4"
                                    style={{
                                        backgroundColor: this.state.is_buy
                                            ? "green"
                                            : "red",
                                    }}
                                    // onClick={this.handleConfirmExchange}
                                    // onClick={(e) =>
                                    //     this.handleSubmitExchange(
                                    //         e,
                                    //         this.state.is_buy ? "buy" : "sell"
                                    //     )
                                    // }
                                >
                                    {this.state.is_buy
                                        ? t("common.buy")
                                        : t("common.sell")}
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="col-6 px-0 pl-3">
                        <div className="order-button-area col-12 px-0">
                            <div className="col-12 d-flex form-group">
                                <div
                                    className="col-4 px-0 text-center"
                                    onClick={(e) =>
                                        this.handleOrderBook(e, "HALF")
                                    }
                                >
                                    <div className="col-12 px-0 order-exchange-button pointer">
                                        <img
                                            src={HalfExchange}
                                            width={28}
                                            height={28}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div
                                    className="col-4 px-0 text-center"
                                    onClick={(e) =>
                                        this.handleOrderBook(e, "SELL")
                                    }
                                >
                                    <div className="col-12 px-0 order-exchange-button pointer">
                                        <img
                                            src={RedExchange}
                                            width={28}
                                            height={28}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div
                                    className="col-4 px-0 text-center"
                                    onClick={(e) =>
                                        this.handleOrderBook(e, "BUY")
                                    }
                                >
                                    <div className="col-12 px-0 order-exchange-button pointer">
                                        <img
                                            src={GreenExchange}
                                            width={28}
                                            height={29}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 px-0 form-group">
                            <div className="col-12 d-flex px-2 text-light-grey font-12">
                                <div className="col-6 px-0 text-left">
                                    {t("common.price")}
                                </div>
                                <div className="col-6 px-0 text-right">
                                    {t("common.quantity")}
                                </div>
                            </div>
                            {this.state.order_filter == "HALF" ||
                            this.state.order_filter == "SELL" ? (
                                <div
                                    className="buy-order-list col-12 px-2"
                                    style={{
                                        maxHeight: "110px",
                                        overflow: "auto",
                                    }}
                                >
                                    <div>{this.state.sell_order_list}</div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="bg-light-purple col-12 d-flex p-2 form-group">
                            <div className="col-6 px-0 text-left text-white font-14">
                                {this.state.current_price}
                            </div>
                            <div className="col-6 px-0 text-right text-light-blue font-10 align-self-center">
                                = USDT
                            </div>
                        </div>
                        <div
                            className="col-12 px-2"
                            style={{ maxHeight: "110px", overflow: "auto" }}
                        >
                            {this.state.order_filter == "HALF" ||
                            this.state.order_filter == "BUY" ? (
                                <div className="sell-order-list">
                                    {this.state.buy_order_list}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
                <div className="open-order-area col-12 bg-light-dark px-0">
                    <div className="text-white p-3 open-order-title col-12 px-0 d-flex">
                        <div className="col-6 px-0 text-left">
                            {t("common.open_orders")}
                        </div>
                        <div className="col-6 px-0 text-right">
                            <Link to="/market/history">
                                <img
                                    src={StatementOpen}
                                    //width={16}
                                    // height={16}
                                    alt=""
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="open-order-list col-12 px-0">
                        {this.state.mem_open_order}
                    </div>
                </div>
            </div>
        );

        return (
            // onCancel={this.handleModalClose}
            //             closable={false}
            // cancelFunc={this.handleModalClose}
            // closable={true}
            <div className="market">
                <div className="pb-4 offset-md-3 col-md-6 px-0">
                    <HyperModal
                        category="response"
                        modalVisible={loading_visible}
                        modalType="loading"
                        footer={[]}
                    />
                    {confirm_visible ? (
                        <HyperModal
                            category="s_password"
                            modalVisible={confirm_visible}
                            submitFunc={this.handleSubmitExchange}
                            cancelFunc={this.handleModalClose}
                            closable={true}
                        />
                    ) : (
                        ""
                    )}

                    <HyperModal
                        category="response"
                        modalVisible={modal_visible}
                        modalType={modal_type}
                        modalMsg={modal_msg}
                        modalCloseFunc={this.handleModalClose}
                        cancelFunc={this.handleModalClose}
                    />
                    <PageHeader
                        title={t("common.trade")}
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        historyBack="/home"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        arrowLeft={true}
                        headerBanner={false}
                        btnRight={
                            <Link to="/market/history">
                                <div className="font-12">
                                    {t("common.trade_history")}
                                </div>
                            </Link>
                        }
                    />

                    <Tabs
                        className="tab-market col-12 px-0"
                        defaultActiveKey="1"
                        onChange={this.handleClickOnTag}
                        activeKey={this.state.active_tab}
                    >
                        <TabPane
                            tab={
                                <div className="tab-market-item text-uppercase">
                                    {t("common.market")}
                                </div>
                            }
                            key="1"
                            id="2"
                        >
                            <div className="market-tab custom-height overflow-auto">
                                <table className="table">
                                    <tbody>
                                        <tr className="text-light-grey font-12 bg-blue">
                                            <th>{t("exchange.coin")}</th>
                                            <th>{t("exchange.last_price")}</th>
                                            <th className="text-center">
                                                {t("exchange.24h_changes")}
                                            </th>
                                        </tr>

                                        {market_list}
                                    </tbody>
                                </table>
                            </div>
                        </TabPane>

                        <TabPane
                            tab={
                                <div className="tab-market-item text-uppercase">
                                    {t("common.buy")}
                                </div>
                            }
                            key="2"
                            id="2"
                        >
                            {/* <div className="col-12 px-0">
                                <CandleStickChart />
                            </div> */}
                            <div className="market-tab custom-height overflow-auto">
                                {buy_page}
                            </div>
                        </TabPane>
                        <TabPane
                            tab={
                                <div className="tab-market-item text-uppercase">
                                    {t("common.sell")}
                                </div>
                            }
                            key="3"
                            id="3"
                        >
                            {" "}
                            {/* <div className="col-12 px-0">
                                <CandleStickChart />
                            </div> */}
                            <div className="market-tab custom-height overflow-auto">
                                {buy_page}
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { marketData, walletBalance } = state;

    return {
        market_data: marketData.market_data,
        // order_list: marketData.order_data,
        mem_open_order: marketData.mem_open_order,
        buy_order_book: marketData.buy_order_book,
        sell_order_book: marketData.sell_order_book,
        post_exchange_result: marketData.post_exchange_result,
        memberWallet: walletBalance.memberWallet,
        isLoading: walletBalance.isLoading,
    };
};

const mapDispatchToProps = {
    getMarketList,
    // getOrderTransaction,
    getMemberOpenOrder,
    getBuyOrderBook,
    getSellOrderBook,
    postBuyExchange,
    postSellExchange,
    getAllMemberWallet,
    postCancelOrder,
};
export default connect(mapStateToProps, mapDispatchToProps)(Market);
