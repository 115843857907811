import React, { Component } from "react";
import { Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import QRCode from "qrcode.react";
// import { setLocale, t } from "react-i18nify";
import { t } from "../../../common/translation";
import { message } from "antd";
import copy from "copy-to-clipboard";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { openModal, closeModal } from "../../../store/ducks/modal/actions";
import { postWalletAddress } from "../../../store/ducks/deposit/actions";
import { getMarketPrice } from "../../../store/ducks/globalTrend/actions";
import {
    postWalletBalanceLite,
    getDepositSetting,
} from "../../../store/ducks/home/actions";

import LoadingMask from "../../../components/loadingMask";
import Modal from "../../../components/modal";
import ErrorIcon from "../../../components/ErrorAlert";
import NumberInput from "../../../components/input/NumberInput";
import PageHeader from "../../../components/PageHeaderV2";
import WalletBadge from "../../../components/WalletBadge";

import CopyIcon from "../../../public/images/icon-copy@3x.png";

import {
    GenHash,
    toFixedTrunc,
    amountChecking2,
} from "../../../common/helpers";

import "./deposit.scss";
import HyperModal from "../../../components/HyperModal";

class Deposit extends Component {
    constructor(props) {
        super(props);

        // let depositFirstBadge = sessionStorage.getItem("depositFirstBadge");
        // let depositFirstBadge = "BTC";

        this.state = {
            username: sessionStorage.getItem("email"),
            token: sessionStorage.getItem("token"),
            data: [],
            price_data: [],
            qr_path: "",
            setOpen: false,
            open: false,
            address: "",
            walletType:
                props.location.state === undefined
                    ? ""
                    : props.location.state.walletType,
            // displayWalletType:
            //     props.location.state === undefined
            //         ? depositFirstBadge
            //         : props.location.state.walletType,
            //walletType: "",
            displayWalletType: "",
            cryptoAddress: "",
            showErrorModal: false,
            errMsg: "",
            errType: "",
            estimated_amount: 0,
            amount_usdt: "",
            wallet_balance_data: [],
            display: "not_display",
            showLoadingModal: false,
            depositSetting: [],
            lang: "en",
            // depositFirstBadge:
            //     props.location.state === undefined
            //         ? depositFirstBadge
            //         : props.location.state.walletType,
            depositFirstBadge: "",
            modal_visible: false,
            loading_visible: true,
            modal_type: "",
            modal_msg: "",
            confirm_visible: false,
        };

        this.handleTooltipClose = this.handleTooltipClose.bind(this);
        this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleAmount = this.handleAmount.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `50px`);
    }

    componentWillMount() {
        //  setLocale(sessionStorage.getItem("lang"));
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });

        let data = {};
        const hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.getDepositSetting(data);
        this.props.getMarketPrice();

        this.props.openModal("loading-modal");

        this.setState({
            showLoadingModal: true,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "loading-modal") {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.wallet_balance_data !== this.props.wallet_balance_data) {
            //Check login success update the routes for react
            if (nextProps.wallet_balance_data.rst === "1") {
                let array;
                let display = "";
                let walletType = this.state.walletType;

                array = nextProps.wallet_balance_data.data;

                const depositSetting = array.depositSetting.map((a) => ({
                    ...a,
                }));

                array.depositSetting.map(function (balance_item, i) {
                    if (balance_item.displayWalletCode === walletType) {
                        if (balance_item.showCalculator === "0") {
                            display = "not_display";
                        }
                    }

                    return i;
                });

                this.setState({
                    wallet_balance_data: depositSetting,
                    depositSetting: depositSetting,
                    display: display,
                    showLoadingModal: false,
                });
            }
        }

        if (nextProps.depositSetting !== this.props.depositSetting) {
            if (nextProps.depositSetting.data.rst === "1") {
                let depositSetting;
                let display = "";
                depositSetting = nextProps.depositSetting.data.data;
                let firstBadge = depositSetting[0].walletTypeCode;

                if (this.state.walletType !== "") {
                    firstBadge = this.state.walletType;
                }
                depositSetting.map(function (balance_item, i) {
                    if (balance_item.displayWalletCode === firstBadge) {
                        if (balance_item.showCalculator === "0") {
                            display = "not_display";
                        }
                    }

                    return i;
                });

                this.setState({
                    wallet_balance_data: depositSetting,
                    depositSetting: depositSetting,
                    display: display,
                    showLoadingModal: false,
                    depositFirstBadge: firstBadge,
                    displayWalletType: firstBadge,
                    walletType: firstBadge,
                });
                let data = {};
                data.token = sessionStorage.getItem("token");
                data.cryptoCode = firstBadge;
                data.lang = sessionStorage.getItem("lang");
                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;

                this.props.postWalletAddress(data);
            }
        }

        if (nextProps.market_data !== this.props.market_data) {
            if (nextProps.market_data.rst === "1") {
                let array;
                array = nextProps.market_data.data;

                this.setState({ price_data: array });
            }
        }

        if (nextProps.deposit_data !== this.props.deposit_data) {
            if (nextProps.deposit_data.rst === "1") {
                this.setState({
                    address: nextProps.deposit_data.data.cryptoAddress,
                    showLoadingModal: false,
                });
            } else {
                switch (nextProps.deposit_data.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                        // this.props.history.push({
                        //     pathname: "/home",
                        //     state: {error: "invalid_wallet_type_details"}
                        // });

                        this.setState({
                            // errMsg: nextProps.deposit_data.msg,
                            showLoadingModal: false,
                            modal_msg: nextProps.deposit_data.msg,
                            modal_type: "error",
                            modal_visible: true,
                            loading_visible: false,
                        });

                        // this.props.openModal('error-modal');
                        break;
                }
            }
        }
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleTooltipClose = () => {
        this.setState({ open: false });
    };

    handleTooltipOpen = () => {
        copy(this.state.address);

        message.success(t("common.copied"));
    };

    handleTabChange(event, ew, dw) {
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.cryptoCode = ew;
        data.lang = sessionStorage.getItem("lang");
        let display = "";
        let crypto = dw;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.openModal("loading-modal");

        this.setState({
            showLoadingModal: true,
        });

        this.props.postWalletAddress(data);

        this.state.wallet_balance_data.map(function (balance_item, i) {
            if (balance_item.displayWalletCode === crypto) {
                if (balance_item.showCalculator === "0") {
                    display = "not_display";
                }
            }

            return i;
        });

        this.setState({
            display: display,
            amount_usdt: "",
            estimated_amount: 0,
            walletType: dw,
            cryptoAddress: this.state.cryptoAddress,
            displayWalletType: dw,
        });
        this.props.closeModal("deposit-modal");
    }

    handleAmount(event) {
        let usdt_price = 0;
        let crypto_price = 0;
        let crypto = this.state.walletType;

        this.state.price_data.map(function (item, i) {
            if (item.cryptoType === "USDT") {
                usdt_price = item.price;
            }

            if (item.cryptoType === crypto) {
                crypto_price = item.price;
            }

            return i;
        });
        let estimatedValue = 0.0;
        if (usdt_price > 0 || crypto_price > 0) {
            estimatedValue = (event.target.value * usdt_price) / crypto_price;
        }

        if (amountChecking2(event.target.value)) {
            this.setState({
                estimated_amount: estimatedValue,
                amount_usdt: event.target.value,
            });
        }
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    render() {
        const {
            depositSetting,
            address,
            modal_visible,
            modal_type,
            modal_msg,
            displayWalletType,
            cryptoAddress,
            showErrorModal,
            errMsg,
            estimated_amount,
            amount_usdt,
            display,
            showLoadingModal,
        } = this.state;

        return (
            <div className="deposit">
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                <HyperModal
                    category="response"
                    modalVisible={modal_visible}
                    modalType={modal_type}
                    modalMsg={modal_msg}
                    footer={[
                        <button
                            key="submit"
                            className="col-12 btn btn-primary"
                            onClick={(e) =>
                                this.handleModalClose(e, modal_type)
                            }
                        >
                            {t("purchasePin.modal_close")}
                        </button>,
                    ]}
                    cancelFunc={this.handleModalClose}
                />

                <div className="container-fluid px-0">
                    <PageHeader
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        historyBack="/home"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header offset-md-2 col-md-8"
                        avatarLevel="title white-color"
                        walletLabel=""
                        title={t("deposit.deposit")}
                        arrowLeft={true}
                    />
                </div>
                <div className="overflow-auto custom-height">
                    {depositSetting.length > 0 && (
                        <WalletBadge
                            walletArrProps={depositSetting}
                            badgeActive={this.state.walletType}
                            badgeOnClick={this.handleTabChange}
                        />
                    )}

                    {address !== "" && (
                        <div className="col-md-8 offset-md-2 p-0">
                            <div className="text-center">
                                <QRCode
                                    value={address}
                                    size={200}
                                    className="bg-white p-3"
                                />
                            </div>
                            {/*<div*/}
                            {/*className="mx-auto"*/}
                            {/*style={{ maxWidth: "220px" }}*/}
                            {/*>*/}
                            {/*<div className="bg-white pt-2 pb-2">*/}
                            {/*<div className="text-center mt-3 mb-3">*/}
                            {/*<QRCode value={address} size={200} />*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                        </div>
                    )}

                    <div className="card-body text-center pt-1 pb-1 word-wrap">
                        <div className="pr-3 pl-3 text-light">
                            <strong id="address">{address}</strong>
                        </div>
                    </div>

                    <div
                        className="col-12 text-center pt-3"
                        id="TooltipExample"
                    >
                        <ClickAwayListener
                            onClickAway={this.handleTooltipClose}
                        >
                            <div>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={this.handleTooltipClose}
                                    open={this.state.open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    placement="top"
                                    title="Copy"
                                >
                                    <strong className="text-light">
                                        {t("deposit.your")}{" "}
                                        {t("convert." + displayWalletType)}{" "}
                                        {cryptoAddress} {t("deposit.address")}
                                        &nbsp;
                                        <img
                                            onClick={this.handleTooltipOpen}
                                            className="mb-1"
                                            src={CopyIcon}
                                            width={15}
                                            alt=""
                                        ></img>
                                    </strong>
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                    </div>

                    {display !== "not_display" && (
                        <div>
                            <div className="card card-background pt-2 pb-2 border-0">
                                <div className="card-body pt-1 pb-1">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <span className="convert-note">
                                                <strong>
                                                    {t("convert.convert_note")}
                                                </strong>
                                            </span>
                                        </div>
                                    </div>

                                    <hr />

                                    <form
                                        className="txt-black offset-md-2 col-md-8 needs-validation"
                                        noValidate
                                    >
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <label
                                                    htmlFor="username"
                                                    className="depth-gray-label text-uppercase"
                                                >
                                                    <b>
                                                        {t(
                                                            "convert.amount_usdt"
                                                        )}
                                                    </b>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 mb-2">
                                                <NumberInput
                                                    id="amount"
                                                    name="amount"
                                                    className="remove-placeholder depth-input"
                                                    value={amount_usdt}
                                                    placeholder={t(
                                                        "convert.please_enter_amount_usdt"
                                                    )}
                                                    onChange={this.handleAmount}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 depth-gray-label">
                                                <span>
                                                    <strong>
                                                        {t("convert.estimated")}{" "}
                                                        {displayWalletType}{" "}
                                                        {t("convert.amount")}
                                                    </strong>
                                                </span>
                                            </div>
                                            <div className="col-12">
                                                <b className="form-label text-light">
                                                    {toFixedTrunc(
                                                        estimated_amount,
                                                        8
                                                    )}
                                                </b>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { deposit, modal, market, walletBalance } = state;

    return {
        deposit_data: deposit.data,
        classIsShowing: modal.classIsShowing,
        market_data: market.data,
        wallet_balance_data: walletBalance.lite_data,
        depositSetting: walletBalance.depositData,
    };
};

const mapDispatchToProps = {
    postWalletBalanceLite,
    closeModal,
    postWalletAddress,
    openModal,
    getMarketPrice,
    getDepositSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Deposit);
