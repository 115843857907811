import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const topUpPinReducer = createReducer(initialState) ({

    [type.GET_PIN_DETAIL]: (state, action) => ({
        ...state,
        GET_PIN_DETAIL: action.payload,
    }),

    [type.POST_TOP_UP_PIN]: (state, action) => ({
        ...state,
        POST_TOP_UP_PIN: action.payload,
    })

});

export default topUpPinReducer;