import * as type from './types';
import axios from 'axios';

export const postChangePassword = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/login/password', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_CHANGE_PASSWORD,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_CHANGE_PASSWORD,
            payload: {"rst":"0","msg":"system_error"}
        });
    })
};
