import React, { Component } from "react";
import { connect } from "react-redux";
import { postIsAuthenticated } from "../../store/ducks/auth/actions";
import LoadingMask from "../../components/loadingMask";
import {Modal} from "antd";

export default (ComposedComponent) => {
    class LangCheck extends Component {
        state = {
            isAuthenticated: false,
            loading: true,
        };

        componentDidMount() {
            if (this.props.getLanguageFile !== undefined && this.props.getLanguageFile.status === 200){
                this.setState({loading: false});
            }
        }

        UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
            if (nextProps.getLanguageFile.status === 200){
                this.setState({loading: false});
            }
        }

        render() {
            return this.state.loading === false ? (
                <ComposedComponent {...this.props} />
            ) : (
                <Modal
                    visible={this.state.loading}
                    title=""
                    footer={null}
                    closable={false}
                    className="LoadingModalAnt"
                >
                    <div className="container pt-5">
                        <div className="row">
                            <div className="mx-auto">
                                <LoadingMask width={80} />
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        }
    }

    const mapStateToProps = (state) => {
        const { walletBalance } = state;

        return { getLanguageFile: walletBalance.getLanguageFile };
    };

    const mapDispatchToProps = {
        postIsAuthenticated,
    };

    return connect(mapStateToProps, mapDispatchToProps)(LangCheck);
};
