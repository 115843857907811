import stakingProgram from "../../pages/stakingProgram/index";

const routes = [
    {
        path: "/program/staking",
        component: stakingProgram,
    },
];

export default routes;
