import * as React from 'react';
import './index.css';
import axios from "axios";
//import { widget } from '../../charting_library';
import { widget } from '../../charting_library/charting_library.min'
import Datafeed from './datafeed'

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
function parseJSONorNot(mayBeJSON) {
	if (typeof mayBeJSON === 'string') {
		return JSON.parse(mayBeJSON)
	}
	else {
		return mayBeJSON
	}
}
Date.prototype.getUTCTime = function () {
    return this.getTime() - (this.getTimezoneOffset() * 60000);
};
// const tvWidget = new widget(widgetOptions);
const supportedResolutions = ["5", "30", "240", "D"]

const config = {
    supported_resolutions: supportedResolutions
}; 

export class TVChartContainer extends React.PureComponent {
	static defaultProps = {
		symbol: 'ACM',
		interval: '3000000',
		containerId: 'tv_chart_container',
		datafeedUrl: 'https://demo_feed.tradingview.com',
		libraryPath: '/../../charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
		
	};
	constructor(props) {
		super(props);
		
		this.state = {
			name_market: this.props.test,
			ready:false
		};

	}

	tvWidget = null;
	componentWillReceiveProps(nextProps) {
    
        var rows = [];
		this.setState({
			ready : true
		})
        if (nextProps.test) {
		
			if(this.state.name_market !== this.props.test){
				this.setState({
					name_market: this.props.test,
					
				})


				const widgetOptions = {
					symbol: this.props.test,
					datafeed: Datafeed,
					interval:"5",
					container_id: this.props.containerId,
					library_path: this.props.libraryPath,
					locale: getLanguageFromURL() || 'en',
					//"timeframes_toolbar"
					// disabled_features: ['use_localstorage_for_settings', 
					//     "volume_force_overlay", "left_toolbar", "show_logo_on_all_charts", 
					//      "caption_buttons_text_if_possible", "header_settings", "header_chart_type", 
					//    "header_indicators", "header_compare", "compare_symbol", "header_screenshot", 
					//    "header_widget_dom_node", "header_saveload", "header_undo_redo", 
					//     "header_interval_dialog_button", "show_interval_dialog_on_key_press", 
					//      "header_symbol_search", "header_resolutions", "header_widget"],
					// enabled_features: ["study_templates"],
					//haha
					disabled_features: [ "header_symbol_search", "header_screenshot", "header_undo_redo", "header_compare", "legend_context_menu", "create_volume_indicator_by_default", "timeframes_toolbar", "pane_context_menu", "use_localstorage_for_settings", "show_hide_button_in_legend", ],
					//charts_storage_url: this.props.chartsStorageUrl,
					//charts_storage_api_version: this.props.chartsStorageApiVersion,
					client_id: this.props.clientId,
					user_id: this.props.userId,
					fullscreen: this.props.fullscreen,
					autosize: this.props.autosize,
					///studies_overrides: this.props.studiesOverrides,
					hide_side_toolbar :true,
		
					theme :"dark",
				};
		
				const tvWidget = new widget(widgetOptions);
				this.tvWidget = tvWidget;


				tvWidget.onChartReady(() => {
					console.log('Chart has loaded!')
				});
			}
		
			
			// const name_market = this.props.test
			//let data_feed = {
				// onReady: function (callback) {
				// 	setTimeout(function() {
	
				// 		var config = {
				// 			configurationData: {
				// 				supports_search: true,
				// 				supports_group_request: false,
				// 				supported_resolutions: ['1', '3', '5', '15', '60', '1D', '1W'],
				// 				supports_marks: false,
				// 				supports_timescale_marks: false,
				// 				supports_time: true,
				// 				exchanges: []
				// 			}
				// 		};
				// 		callback(parseJSONorNot(config));
	
				// 	}, 0);
				// },
				// onReady: cb => {
				// 	console.log('=====onReady running')	
				// 		setTimeout(() => cb(config), 0)
						
				// 	},
				// 	searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
				// 		console.log('====Search Symbols running')
				// 	},
				// 	resolveSymbol: function (symbolName, onSymbolResolvedCallback) {
	
				// 		setTimeout (function() {
		
				// 			let config2 = {
				// 				"name": symbolName,
				// 				"timezone": "Asia/Singapore",
				// 				"pricescale": 1000000,
				// 				"minmov": 1,
				// 				"minmov2": 0,
				// 				"ticker": "TEST:TEST",
				// 				"description": "test",
				// 				"session": "24x7",
				// 				"type": "bitcoin",
				// 				"exchange-traded": "",
				// 				"exchange-listed": "",
				// 				"has_intraday": true,
				// 				"intraday_multipliers": ['1', '3', '5', '15', '60', '1D', '1W'],
				// 				"has_weekly_and_monthly": false,
				// 				"has_no_volume": true,
				// 				"regular_session": "24x7"
				// 				// "data_status":"streaming"
				// 			};
		
				// 			onSymbolResolvedCallback(parseJSONorNot(config2));
		
				// 		}, 0);
				// 	},
				// 	getServerTime: function (callback) {
				// 		var config5 = Math.round((new Date()).getTime() / 1000);
		
				// 		callback((config5))
				// 	},
		
				// 	getBars: function(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
				// 		console.log('=====getBars running')
				// 		var date = new Date();
		
				// 		date.setFullYear( date.getFullYear() - 2 );
				// 		var date_from =new Date(date).getTime();
					
				// 		var date_to = new Date(new Date().toUTCString()).getTime()
				// 	console.log("data",from,to)
				// 	var param ={crypto_code:name_market, history:"1day", from: date_from, to : date_to}
					
				// 		// console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)
				// 		// historyProvider.getBars(symbolInfo, resolution, from, to, firstDataRequest)
					
				// 		axios.create({ withCredentials: false }).get(
				// 			process.env.REACT_APP_GM_URL +
				// 				"/member/exchange/trading-view/chart",
				// 			{
				// 				params: param,
				// 				headers: {
				// 					"X-Authorization":
				// 						"INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
				// 					Authorization: sessionStorage.getItem("accessToken"),
				// 				},
				// 			}
				// 		).then(response => {
				// 				if(response.data) {
				// 					// let data_tradding = response.data.trading_view_data;
				// 					// let obj = JSON.parse(data_tradding.replace(/&quot;/g, '"'));
				// 					var bars = [];
				// 					for (var i = 0; i < response.data.t.length; i++) {
										
				// 							var barValue = {
												
				// 									time: response.data.t[i],//in miliseconds
				// 									close: response.data.c[i],
				// 									open: response.data.o[i],
				// 									high: response.data.h[i],
				// 									low: response.data.l[i],
				// 									volume: response.data.v[i]
				// 							};
				// 							bars.push(barValue);
				// 					}
									
				// 					bars.push(barValue);
				// 					if (typeof bars === 'string') {
										
				// 							   bars = JSON.parse(bars);
				// 					}
				// 					console.log("bars", bars.length)
				// 				// 	if (bars.length) {
				// 				// 	   setTimeout(onHistoryCallback(bars, {noData: false, nextTime: null}), 0);
				// 				//    } else {
				// 				// 	   setTimeout(onHistoryCallback(bars, {noData: true}), 0);
				// 				//    }
				// 				if (bars.length) {
				// 					onHistoryCallback(bars, {noData: false})
				// 				} else {
				// 					onHistoryCallback(bars, {noData: true})
				// 				}
				// 				}
				// 			 }).catch(err => {
				// 			   console.log({err})
				// 			   onErrorCallback(err)
				// 		   })
				// 	},
				// 	subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
					
				// 	}
			//}



				// disabled_features: ["use_localstorage_for_settings", "left_toolbar",
				//  "volume_force_overlay", "header_widget",
				//   "header_symbol_search", "header_settings",
				//    "header_indicators", "header_compare", 
				//    "header_undo_redo", "header_screenshot", 
				//    "header_chart_type", "border_around_the_chart"],
			// tvWidget.onChartReady(() => {
			// 	const button = tvWidget.createButton()
			// 		.attr('title', 'Click to show a notification popup')
			// 		.addClass('apply-common-tooltip')
			// 		.on('click', () => tvWidget.showNoticeDialog({
			// 			title: 'Notification',
			// 			body: 'TradingView Charting Library API works correctly',
			// 			callback: () => {
			// 				console.log('Noticed!');
			// 			},
			// 		}));
	
			// 	button[0].innerHTML = 'Check API';
			// });
		
		}
	}
	componentDidMount() {
	
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		return (
			<div>


			<div
				id={ this.props.containerId }
				className={ 'TVChartContainer' }
			/>
			
			</div>
		);
	}
}
