import React, { Component } from "react";
// import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Select from "../../components/input/Select/index";
import StringInput from "../../components/input/StringInput/index";
import PasswordInput from "../../components/input/PasswordInput/index";
// import NumberInput from "../../components/input/NumberInput";
import PageHeader from "../../components/PageHeader";
import { GenHash, fieldChecking } from "../../common/helpers";
import { connect } from "react-redux";
import { formValidation } from "../../common/validator/formValidation";
import Modal from "../../components/modal";
import LoadingMask from "../../components/loadingMask";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";

import { postRegister } from "../../store/ducks/register/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";

import "./addressForm.scss";
import "../index.scss";

import { t, setLocale } from "react-i18nify";

import contactNo from "../../pages/auth/register/contactNo/contactNo";

import { Switch } from "antd";

class AddressForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            email: "",
            referralUsername: "",
            confirmPassword: "",
            country_prefix: "+93",
            mobile_no: "",
            autoLogin: 1,
            country_options: [],
            mobile_prefix: [],
            sms_verification_code: "",
            loading: false,
            showLoadingModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            errMsg: "",
            successMsg: "",
            referralStatus: false,
            giftCardValue: "",
        };

        this.handleUsername = this.handleUsername.bind(this);
        this.handleVerificationCode = this.handleVerificationCode.bind(this);
        this.handleCountryPrefix = this.handleCountryPrefix.bind(this);
        this.handleSubmitRegister = this.handleSubmitRegister.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.handleReferral = this.handleReferral.bind(this);
        this.handleMobileNo = this.handleMobileNo.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleGiftCardCode = this.handleGiftCardCode.bind(this);
    }

    handleSubmitRegister(e) {
        e.preventDefault();

        let validate = fieldChecking(document.getElementById("register-form"));

        if (validate === false) {
            return false;
        }

        this.props.openModal("register-loading-modal");

        const data = [];
        let hash = "";

        data["username"] = this.state.username;
        data["password"] = this.state.password;
        data["confirmPassword"] = this.state.confirmPassword;
        data["mobilePrefix"] = this.state.country_prefix;
        data["mobileNumber"] = this.state.mobile_no;
        data["email"] = this.state.email;
        data["referralUsername"] =
            this.state.referralUsername == null
                ? ""
                : this.state.referralUsername;
        data["autoLogin"] = this.state.autoLogin;
        data["giftCardCode"] = this.state.giftCardValue;
        hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data["hash"] = hash;

        this.props.postRegister(data);
    }

    handleReferral(event) {
        this.setState({ referralUsername: event.target.value });
    }

    handleMobileNo(event) {
        this.setState({ mobile_no: event.target.value });
    }

    handleEmail(event) {
        this.setState({ email: event.target.value });
    }

    handlePassword(event) {
        this.setState({ password: event.target.value });
    }

    handleConfirmPassword(event) {
        this.setState({ confirmPassword: event.target.value });
    }

    handleUsername(event) {
        this.setState({ username: event.target.value });
    }

    handleVerificationCode(event) {
        this.setState({ verification_code: event.target.value });
    }

    handleCountryPrefix(event) {
        this.setState({ country_prefix: event.target.value });
    }

    handleGiftCardCode(event) {
        this.setState({ giftCardValue: event.target.value });
    }

    componentDidMount() {
        contactNo.map((option, index) => {
            this.state.mobile_prefix.push(option.dial_code);
            this.state.country_options.push(option.name);

            return index;
        });

        formValidation("register-form");

        let lang = !sessionStorage.hasOwnProperty("lang")
            ? "en"
            : sessionStorage.getItem("lang");

        setLocale(lang);

        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        this.setState(
            {
                referralUsername:
                    params.get("referral") === null
                        ? ""
                        : params.get("referral"),
                referralStatus: params.get("referral") === null ? false : true,
            },
            () => {}
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName === "register-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.registerData !== this.props.registerData) {
            if (nextProps.registerData.data.rst === "1") {
                this.setState({
                    showLoadingModal: false,
                    successMsg: t(
                        "register." + nextProps.registerData.data.msg
                    ),
                });

                this.props.openModal("success-modal");
            } else {
                this.setState({
                    showLoadingModal: false,
                    errMsg: t("register." + nextProps.registerData.data.msg),
                });

                this.props.openModal("error-modal");
            }
        }
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/login");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");
    }

    render() {
        const {
            showLoadingModal,
            showErrorModal,
            errMsg,
            successMsg,
            showSuccessModal,
        } = this.state;

        return (
            <div className="register-panel">
                <PageHeader
                    subTitle={t("addressList.new_address")}
                    navClass="title white-color"
                    backgroundHeight={80}
                    subPageHistoryBack={"/address/list"}
                    walletType={""}
                    arrowLeft={true}
                />

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="register-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div className="mt-3">
                    <div className="container-fluid pb-3">
                        <form
                            id="register-form"
                            onSubmit={this.handleSubmitRegister}
                            className="txt-black needs-validation"
                            noValidate
                        >
                            <input
                                type="hidden"
                                name="referral_username"
                                value={this.state.referralUsername}
                            />

                            <div className="form-group register-panel-field">
                                <label className="form-label text-uppercase">
                                    {t("register.full_name")}*
                                </label>
                                <StringInput
                                    id="email"
                                    name="email"
                                    onChange={this.handleEmail}
                                    value={this.state.email}
                                    placeholder={t(
                                        "register.please_your_full_name"
                                    )}
                                    required
                                    requiredMsg={t("validator.requiredMsg")}
                                    valid_email="true"
                                    emailMsg={t("register.validEmail")}
                                />
                            </div>

                            <div className="form-group register-panel-field">
                                <label className="form-label text-uppercase">
                                    {t("register.mobile")}
                                </label>
                                <div className="input-group">
                                    <Select
                                        id="status"
                                        className="form-control col-3 register-mobile-country-prefix"
                                        name="country_prefix"
                                        options={this.state.mobile_prefix}
                                        onChange={this.handleCountryPrefix}
                                        countryName={this.state.country_options}
                                    />
                                    <input
                                        type="number"
                                        placeholder="Eg: 122561234"
                                        id="mobile_no"
                                        name="mobile_no"
                                        onChange={this.handleMobileNo}
                                        value={this.state.mobile_no}
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className="form-group register-panel-field">
                                <label className="form-label text-uppercase">
                                    {t("register.email")}*
                                </label>
                                <StringInput
                                    id="username"
                                    name="username"
                                    onChange={this.handleUsername}
                                    value={this.state.username}
                                    placeholder={t(
                                        "register.please_enter_email"
                                    )}
                                    required
                                    requiredMsg={t("validator.requiredMsg")}
                                    minLengthMsg={t("register.minLengthMsg", {
                                        count: 3,
                                    })}
                                    minLength="3"
                                    maxLength="20"
                                    maxLengthMsg={t("register.maxLengthMsg", {
                                        count: 20,
                                    })}
                                />
                            </div>
                            <div className="form-group register-panel-field">
                                <label className="form-label text-uppercase">
                                    {t("register.country")}*
                                </label>
                                <PasswordInput
                                    id="password"
                                    name="password"
                                    onChange={this.handlePassword}
                                    value={this.state.password}
                                    placeholder={t("register.choose_country")}
                                    required
                                    requiredMsg={t("validator.requiredMsg")}
                                    minLengthMsg={t("register.minLengthMsg", {
                                        count: 8,
                                    })}
                                    minLength="8"
                                    maxLength="16"
                                    maxLengthMsg={t("register.maxLengthMsg", {
                                        count: 16,
                                    })}
                                    passwordFormatMsg={t(
                                        "register.passwordFormatMsg"
                                    )}
                                    passwordformat="true"
                                />
                            </div>

                            <div className="form-group register-panel-field">
                                <label className="form-label text-uppercase">
                                    {t("register.state")}*
                                </label>
                                <PasswordInput
                                    id="confirm_password"
                                    name="confirm_password"
                                    onChange={this.handleConfirmPassword}
                                    value={this.state.confirmPassword}
                                    placeholder={t("register.choose_state")}
                                    required
                                    requiredMsg={t("validator.requiredMsg")}
                                    confirmpassword="true"
                                    oldPassword={this.state.password}
                                    confirmPasswordMsg={t(
                                        "register.checkPasswordMatch"
                                    )}
                                />
                            </div>

                            <div className="form-group register-panel-field">
                                <label className="form-label text-uppercase">
                                    {t("register.city")}*
                                </label>
                                <input
                                    type="text"
                                    placeholder={t("register.choose_city")}
                                    onChange={this.handleReferral}
                                    value={this.state.referralUsername}
                                    className="form-control"
                                    disabled={this.state.referralStatus}
                                    required
                                />
                            </div>

                            <div className="form-group register-panel-field">
                                <label className="form-label text-uppercase">
                                    {t("register.postcode")}*
                                </label>
                                <input
                                    type="text"
                                    placeholder={t(
                                        "register.please_enter_postcode"
                                    )}
                                    onChange={this.handleReferral}
                                    value={this.state.referralUsername}
                                    className="form-control"
                                    disabled={this.state.referralStatus}
                                    required
                                />
                            </div>

                            <div className="form-group register-panel-field">
                                <label className="form-label text-uppercase">
                                    {t("register.address")}*
                                </label>
                                <input
                                    type="text"
                                    placeholder={t(
                                        "register.please_enter_address"
                                    )}
                                    onChange={this.handleReferral}
                                    value={this.state.referralUsername}
                                    className="form-control"
                                    disabled={this.state.referralStatus}
                                    required
                                />
                            </div>

                            <div className="col-12 px-0 form-group">
                                <label className="form-label fa-pull-left">
                                    Make default shipping Address
                                </label>
                                <Switch
                                    className="fa-pull-right"
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    defaultChecked
                                />
                            </div>

                            <div className="col-12 px-0 form-group">
                                <label className="form-label fa-pull-left">
                                    Make default billing Address
                                </label>
                                <Switch
                                    className="fa-pull-right"
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    defaultChecked
                                />
                            </div>

                            <div className="form-group pt-3 ">
                                <button
                                    type="submit"
                                    className="col-12 btn btn-primary"
                                >
                                    <small className="text-uppercase">
                                        <b>Submit</b>
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { register, modal } = state;

    return {
        registerData: register.data,
        classIsShowing: modal.classIsShowing,
    };
};

const mapDispatchToProps = {
    postRegister,
    openModal,
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
