import * as type from './types';
import { createReducer } from "../../util";

const initialState = {
    data: [],
};

const convertReducer = createReducer(initialState) ({

    [type.CONVERT_DEPOSIT]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

});

export default convertReducer;