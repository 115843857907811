import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import AlertUI from "../../components/AlertUI";
import LoadingMask from "../../components/loadingMask";
import PasswordInput from "../input/PasswordInput";
import "./modalV2.scss";
// import { t, setLocale } from "react-i18nify";
import { t, setLanguageFileJson } from "../../common/translation";
class HyperModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            secondary_password: "",
        };
        // setLocale(sessionStorage.getItem("lang"));

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    render() {
        const {
            submitFunc,
            modalVisible,
            footer,
            cancelFunc,
            modalType,
            modalMsg,
            category,
            modalContent,
            closable,
            title,
            modalCloseFunc,
        } = this.props;

        const { secondary_password } = this.state;

        let modal_footer;
        const s_password = secondary_password;

        if (modalCloseFunc !== null && modalCloseFunc !== undefined) {
            if (modalType === "error") {
                modal_footer = (
                    <button
                        key="submit"
                        className="col-12 btn btn-submit text-white text-uppercase"
                        onClick={(e) => modalCloseFunc(e, modalType)}
                    >
                        {t("login.close")}
                    </button>
                );
            } else {
                modal_footer = (
                    <button
                        key="submit"
                        className="col-12 btn btn-submit text-white text-uppercase"
                        onClick={(e) => modalCloseFunc(e, modalType)}
                    >
                        {t("login.close")}
                    </button>
                );
            }
        }

        return (
            <div className="modal">
                {category === "response" && modalType === "loading" && (
                    <div className="loading-style">
                        <Modal
                            visible={modalVisible}
                            footer={footer}
                            closable={false}
                            bodyStyle={{ backgroundColor: "transparent" }}
                            wrapClassName="loading-style"
                            centered
                        >
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                )}

                {category === "response" && modalType !== "loading" && (
                    <Modal
                        visible={modalVisible}
                        onOk={submitFunc}
                        footer={modal_footer}
                        onCancel={cancelFunc}
                        closable={closable}
                        centered
                    >
                        <AlertUI
                            alertType={modalType}
                            msg={modalMsg}
                            width="200px"
                            height="0px !important"
                        />
                    </Modal>
                )}

                {category === "normal" && (
                    <Modal
                        title={title}
                        visible={modalVisible}
                        onOk={submitFunc}
                        footer={footer}
                        onCancel={cancelFunc}
                        closable={closable}
                        centered
                    >
                        {modalContent}
                    </Modal>
                )}
                {category === "s_password" && (
                    <Modal
                        title={
                            <h5 className="text-uppercase">
                                {t("withdrawal.secondary_password")}
                            </h5>
                        }
                        visible={modalVisible}
                        footer={
                            <button
                                key="submit"
                                className="col-12 button btn-submit"
                                onClick={(e) => submitFunc(e, s_password)}
                            >
                                {t("common.submit")}
                            </button>
                        }
                        className="s_password_style"
                        onCancel={cancelFunc}
                        closable={closable}
                        centered
                    >
                        <PasswordInput
                            id="secondary_password"
                            name="secondary_password"
                            className="remove-placeholder depth-input"
                            onChange={this.onChange}
                            value={this.state.secondary_password}
                            placeholder={t("common.enter_secondary_password")}
                            minLengthMsg={t("register.minLengthMsg", {
                                count: 6,
                            })}
                            minLength="6"
                            requiredMsg={t("validator.requiredMsg")}
                            required
                        />
                    </Modal>
                )}
            </div>
        );
    }
}

export default HyperModal;

HyperModal.propTypes = {
    modalVisible: PropTypes.bool,
    modalType: PropTypes.string,
    modalMsg: PropTypes.string,
    modalContent: PropTypes.any,
    submitFunc: PropTypes.func,
    cancelFunc: PropTypes.func,
    footer: PropTypes.any,
    title: PropTypes.string,
    category: PropTypes.string,
    closable: PropTypes.bool,
    modalCloseFunc: PropTypes.func,
};

//Sample code for Modal Component

{
    /*
<Modal
    category="response"
    modalVisible={loading}
    modalType={modalType}
    footer={[]}
/>

<Modal
    category="response"
    modalVisible={success_visible}
    modalType={modalType}
    modalMsg={modalMsg}
    footer={[
            <button key="submit" className="col-12 btn btn-primary" onClick={(e) => this.handleClose(e, modalType)}>
                {t('login.close')}
            </button>
            ]}
    cancelFunc={this.handleClose}
/>

<Modal
    category="response"
    modalVisible={error_visible}
    modalType={modalType}
    modalMsg={modalMsg}
    footer={[
        <button key="submit" className="col-12 btn btn-primary" onClick={(e) => this.handleClose(e, modalType)}>
            {t('login.close')}
        </button>
    ]}
    cancelFunc={this.handleClose}
/>*/
}
