import React, { Component } from "react";
// import {setLocale, t} from 'react-i18nify';
import { connect } from "react-redux";
import { t } from "../../common/translation";
import PageHeader from "../../components/PageHeaderV2";
import {
    amountChecking8,
    amountChecking2,
    fieldChecking,
    GenHash,
    toFixedTrunc,
    check_decimal,
    slice_decimal_wt_rounding,
} from "../../common/helpers";
import {
    postWalletBalanceLite,
    getConvertSetting,
    getMemberWallet,
} from "../../store/ducks/home/actions";
import { postConvertDeposit } from "../../store/ducks/convert/actions";
import NumberInput from "../../components/input/NumberInput";
import PasswordInput from "../../components/input/PasswordInput";
import { getMarketPrice } from "../../store/ducks/globalTrend/actions";
import { formValidation } from "../../common/validator/formValidation";
import DropDownBox from "../../components/input/DropDownBox";
import ConfirmationIcon from "../../components/ConfirmationAlert";

import "./convert.scss";
import WalletBadge from "../../components/WalletBadge";
import HyperModal from "../../components/HyperModal";

class DepositConvert extends Component {
    constructor(props) {
        super(props);

        let convertFirstBadge = sessionStorage.getItem("convertFirstBadge");

        this.state = {
            data: [],
            price_data: [],
            remaining: 0,
            s_password: "",
            address: "",
            amount: "",
            amountEstimated: "",
            estimatedAmount: 0,
            balance: "0",
            walletType:
                this.props.location.state === undefined
                    ? ""
                    : this.props.location.state.walletType,
            loading: true,
            convertAdminFee: 0,
            displayWalletType:
                this.props.location.state === undefined
                    ? ""
                    : this.props.location.state.walletType,
            walletTypeReceive: "",
            convertSetting: [],
            convertDetails: [],
            purchase_method: "",
            modal_visible: false,
            loading_visible: this.props.loading_visible,
            modal_type: "",
            modal_msg: "",
            confirm_visible: false,
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmitConvert = this.handleSubmitConvert.bind(this);
        this.handleSubmitConvertConfirmation = this.handleSubmitConvertConfirmation.bind(
            this
        );
        this.handleAmount = this.handleAmount.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `230px`);
    }

    handleTabChange(event, ew, dw) {
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.walletType = ew;
        data.lang = sessionStorage.getItem("lang");
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.getMemberWallet(data);
        // this.props.postWalletBalanceLite(data);
        this.props.getMarketPrice();
        this.setState({
            walletType: dw,
            cryptoAddress: this.state.cryptoAddress,
            displayWalletType: dw,
            estimatedAmount: 0,
            amount: "",
            // loading: true,
        });
        let purchase_method = [];
        let convertDetail = {};
        let convertAdminFee = "";

        this.state.convertSetting
            .filter((item) => item.displayWalletCode == dw)
            .map((filterItem) => {
                purchase_method =
                    filterItem.options.length > 0
                        ? filterItem.options[0].value
                        : "";
                convertDetail = filterItem;
                convertAdminFee = filterItem.convertAdminFee;
            });
        //  console.log(convertAdminFee, purchase_method, convertDetail);
        this.setState({
            convertAdminFee: convertAdminFee,
            purchase_method: purchase_method,
            convertDetails: convertDetail,
        });
    }

    UNSAFE_componentWillMount() {
        //setLocale(sessionStorage.getItem("lang"));
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });
        formValidation("convert-form");
        this.props.getMarketPrice();

        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        //   data.walletType = this.state.walletType;
        data.lang = sessionStorage.getItem("lang");
        var hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;
        this.props.getConvertSetting(data);
        // this.props.getMemberWallet(data);

        //this.props.postWalletBalanceLite(data);
    }

    async handleSubmitConvertConfirmation(event) {
        event.preventDefault();

        var form = document.getElementById("convert-form");
        var validate = fieldChecking(form);

        if (validate === false) {
            return false;
        }

        this.setState({
            confirm_visible: true,
        });
    }

    handleDropDownChange(e) {
        this.setState({
            purchase_method: e,
            amount: "",
            estimatedAmount: 0,
            amountEstimated: 0,
        });
    }

    handleSubmitConvert(event, pwd) {
        event.preventDefault("withdrawal-form");
        let element = document.getElementById("secondary_password");
        let isPwInValid = element.classList.contains("is-invalid");

        if (!isPwInValid & (pwd !== "")) {
            event.preventDefault("convert-form");

            const data = [];
            let hash = "";

            data["convertAmount"] = this.state.amount;
            data["email"] = sessionStorage.getItem("email");
            data["secondaryPassword"] = pwd;
            data["token"] = sessionStorage.getItem("token");
            data["walletTypeFrom"] = this.state.convertDetails.walletFrom;
            // data['walletTypeTo'] = this.state.convertDetails.convertTo;
            data["method"] = this.state.convertDetails.method;
            data["purchase_method"] = this.state.purchase_method;
            data["lang"] = sessionStorage.getItem("lang");
            hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
            data["hash"] = hash;

            this.setState(
                {
                    confirm_visible: false,
                    modal_loading: true,
                },
                () => {
                    this.props.postConvertDeposit(data);
                }
            );
        }
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false, modal_gcox: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.memberWallet !== this.props.memberWallet) {
            if (nextProps.memberWallet.data.rst === "1") {
                //console.log("in", this.state);
                console.log(nextProps.memberWallet.data.data.walletBalance[0]);
                this.setState({
                    data: nextProps.memberWallet.data.data.walletBalance[0],

                    // //  loading: false,
                    // // loading_visible: false,
                    // price_code:
                    //     nextProps.memberWallet.data.data.walletBalance[0]
                    //         .priceCode,
                });
                // console.log(this.state.loading_visible);
            }
        }

        if (nextProps.convertSetting !== this.props.convertSetting) {
            // console.log(nextProps.exchangeSetting);
            if (nextProps.convertSetting.data.rst === "1") {
                let convertSetting;
                convertSetting = nextProps.convertSetting.data.data;

                let purchase_method = [];
                let convertDetail = {};
                let convertAdminFee = "";
                let firstBadge = convertSetting[0].walletTypeCode;
                if (this.state.walletType !== "") {
                    firstBadge = this.state.walletType;
                }

                convertSetting
                    .filter((item) => item.displayWalletCode == firstBadge)
                    .map((filterItem) => {
                        purchase_method =
                            filterItem.options.length > 0
                                ? filterItem.options[0].value
                                : "";
                        convertDetail = filterItem;
                        convertAdminFee = filterItem.convertAdminFee;
                    });

                this.setState({
                    convertAdminFee: convertAdminFee,
                    convertSetting: convertSetting,
                    purchase_method: purchase_method,
                    convertDetails: convertDetail,
                    walletType: firstBadge,
                    displayWalletType: firstBadge,
                });
                let data = {};
                data.token = sessionStorage.getItem("token");
                data.walletType = firstBadge;
                this.props.getMemberWallet(data);
            }
        }
        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }
        if (nextProps.data !== this.props.data) {
            //Check login success update the routes for react
            if (
                nextProps.data.rst === "1" &&
                nextProps.data.data.walletDetailsSetup.convertSetting !==
                    undefined
            ) {
                let array;
                array = nextProps.data.data;
                console.log("wt", array.walletDetailsSetup.convertSetting);
                this.setState({
                    data: array.walletBalance[0],
                    convertAdminFee:
                        array.walletDetailsSetup.convertSetting.convertAdminFee,
                    convertSetting: array.convertSetting,
                    purchase_method:
                        array.walletDetailsSetup.convertSetting.options.length >
                        0
                            ? array.walletDetailsSetup.convertSetting.options[0]
                                  .value
                            : "",
                    convertDetails: array.walletDetailsSetup.convertSetting,
                    loading_visible: false,
                    loading: false,
                });
            } else {
                this.props.history.push({
                    pathname: "/home",
                    state: { error: "invalid_wallet_type_details" },
                });
            }
        }

        if (
            nextProps.post_convert_deposit !== this.props.post_convert_deposit
        ) {
            if (nextProps.post_convert_deposit.rst !== "1") {
                this.setState({
                    modal_msg: nextProps.post_convert_deposit.msg,
                    modal_type: "error",
                    modal_visible: true,
                    loading_visible: false,
                });
            } else {
                const data = {};

                data.username = sessionStorage.getItem("email");
                data.token = sessionStorage.getItem("token");
                data.walletType = this.state.walletType;
                data.lang = sessionStorage.getItem("lang");
                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;

                this.setState({
                    estimatedAmount: 0,
                    amountEstimated: "",
                    amount: "",
                    s_password: "",
                    modal_msg: nextProps.post_convert_deposit.msg,
                    modal_type: "success",
                    modal_visible: true,
                    loading_visible: false,
                });

                this.props.postWalletBalanceLite(data);
            }
        }

        if (nextProps.market_data !== this.props.market_data) {
            if (nextProps.market_data.rst === "1") {
                let array;
                array = nextProps.market_data.data;
                this.setState({ price_data: array });
            }
        }
    }

    onChange(e) {
        const { name, value } = e.target;

        if (
            (name === "amount" && amountChecking8(value)) ||
            name !== "amount"
        ) {
            this.setState({ [name]: value });
        }
    }

    handleAmount(event) {
        let converted_amount = 0;
        let price = 0;

        if (amountChecking2(event.target.value)) {
            if (
                this.state.convertDetails.method === "CRYPTO" &&
                this.state.convertDetails.live_rate_to_usdt !== ""
            ) {
                converted_amount =
                    (event.target.value /
                        this.state.convertDetails.live_rate_to_usdt) *
                    (1 + parseFloat(this.state.convertAdminFee));
            } else if (this.state.convertDetails.method === "SPECIAL") {
                // if (check_decimal(event.target.value) > 2){
                //     return ;
                // }
                price =
                    this.state.purchase_method === "avg_pp"
                        ? this.state.data.avg_purchase_price
                        : this.state.data.avg_trading_price;
                converted_amount = event.target.value / parseFloat(price);
            } else {
                price = 1;
                converted_amount = event.target.value / price;
            }

            this.setState({
                amount: event.target.value,
                estimatedAmount: converted_amount,
                amountEstimated: event.target.value,
            });
        }
    }

    render() {
        const {
            loading,
            data,
            purchase_method,
            convertSetting,
            balance,
            convertDetails,
            estimatedAmount,
            confirm_visible,
            loading_visible,
            modal_visible,
            modal_type,
            modal_msg,
        } = this.state;

        const convert_to =
            convertDetails.convertTo === "ACMV"
                ? "CV"
                : convertDetails.convertTo === "RU"
                ? "RC"
                : convertDetails.convertTo;
        return (
            <div id="withdrawal">
                <PageHeader
                    title={data.displayCode ? data.displayCode : ""}
                    titleHeader="col-12 text-center"
                    backgroundHeight={230}
                    prevousPageLink=""
                    navClass=""
                    historyBack="/home"
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={data.displayCode ? data.displayCode : ""}
                    balance={data.total_amount ? data.total_amount : balance}
                    lockedAmount={data.locked_amount}
                    availableAmount={data.available_amount}
                    arrowLeft={true}
                    headerBanner={true}
                />
                <HyperModal
                    category="s_password"
                    modalVisible={confirm_visible}
                    submitFunc={this.handleSubmitConvert}
                    cancelFunc={this.handleModalClose}
                    closable={true}
                />
                <HyperModal
                    category="response"
                    modalVisible={loading_visible}
                    modalType="loading"
                    footer={[]}
                />
                <div className="overflow-auto custom-height">
                    {convertSetting.length > 0 && (
                        <WalletBadge
                            walletArrProps={convertSetting}
                            badgeActive={
                                data.displayCode === ""
                                    ? this.props.location.state !== undefined
                                        ? this.props.location.state.walletType
                                        : ""
                                    : data.walletTypeCode
                            }
                            badgeOnClick={this.handleTabChange}
                        />
                    )}
                    {/* 
                <HyperModal
                    category="normal"
                    modalVisible={confirm_visible}
                    onOk={this.handleSubmitConvert}
                    footer={
                        <div className="col-12 text-center">
                            <button
                                key="submit"
                                className="col-3 btn btn-primary"
                                onClick={this.handleSubmitConvert}
                            >
                                {t("common.submit")}
                            </button>
                            <button
                                key="submit"
                                className="col-3 btn btn-danger"
                                onClick={(e) =>
                                    this.handleModalClose(e, "confirm")
                                }
                            >
                                {t("purchasePin.modal_close")}
                            </button>
                        </div>
                    }
                    modalContent={
                        <div className="row">
                            <div className="col-12 text-center">
                                <ConfirmationIcon
                                    width="100px"
                                    height="0px !important"
                                />
                              
                                <p>{t("transfer.confirmation_msg")}</p>
                            </div>
                        </div>
                    }
                    onCancel={this.handleModalClose}
                    closable={false}
                ></HyperModal> */}

                    <HyperModal
                        category="response"
                        modalVisible={loading_visible}
                        modalType="loading"
                        footer={[]}
                    />

                    <HyperModal
                        category="response"
                        modalVisible={modal_visible}
                        modalType={modal_type}
                        modalMsg={modal_msg}
                        modalCloseFunc={this.handleModalClose}
                        cancelFunc={this.handleModalClose}
                    />

                    <div className="px-4 pb-4  offset-md-2 col-md-8 ">
                        <form
                            method="post"
                            id="convert-form"
                            className="txt-black needs-validation"
                            noValidate
                            onSubmit={this.handleSubmitConvertConfirmation}
                        >
                            {convertDetails.options !== undefined &&
                                convertDetails.options.length > 0 && (
                                    <>
                                        <React.Fragment>
                                            <div className="col-12 text-white p-1 border-top">
                                                <div className="row">
                                                    <div className="col-6 text-center depth-gray-label text-uppercase">
                                                        {t(
                                                            "convert.avg_purchase_price"
                                                        )}
                                                        <br />
                                                        {
                                                            data.avg_purchase_price
                                                        }
                                                    </div>
                                                    <div className="col-6 text-center border-left-light depth-gray-label text-uppercase">
                                                        {t(
                                                            "convert.avg_trading_price"
                                                        )}
                                                        <br />
                                                        {data.avg_trading_price}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>

                                        <div className="col-12 pt-3">
                                            <div className="row">
                                                <label className="depth-gray-label text-uppercase">
                                                    {t("convert.pricing")}
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <DropDownBox
                                                id="purchase_method"
                                                size="large"
                                                // className="depth-input"
                                                name="purchase_method"
                                                options={convertDetails.options}
                                                value={purchase_method}
                                                onChange={(e) =>
                                                    this.handleDropDownChange(e)
                                                }
                                            />
                                        </div>
                                    </>
                                )}

                            <div className="col-12 pt-3">
                                <div className="row">
                                    <label className="depth-gray-label text-uppercase">
                                        {t("withdrawal.amount")} ({convert_to})
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <NumberInput
                                        id="amount"
                                        name="amount"
                                        onChange={this.handleAmount}
                                        value={this.state.amount}
                                        className="remove-placeholder depth-input"
                                        requiredMsg={t("validator.requiredMsg")}
                                        placeholder="0.00"
                                        required
                                    />
                                </div>
                            </div>

                            {convertDetails.method !== "SPLIT" && (
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <p>
                                            <small className="text-light">
                                                {t("convert.estimated")}{" "}
                                                {data.walletTypeCode !==
                                                undefined
                                                    ? data.walletTypeCode
                                                    : this.state
                                                          .walletType}{" "}
                                                {t("convert.amount")}{" "}
                                            </small>
                                            <strong className="estimate-figure-style">
                                                {toFixedTrunc(
                                                    estimatedAmount,
                                                    8
                                                )}
                                            </strong>
                                            &nbsp;
                                            <small className="text-light">
                                                {t("convert.will_be_deducted")}{" "}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            )}

                            {convertDetails.method === "SPLIT" && (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <p>
                                                <small className="text-light">
                                                    {t("convert.80%_split")}
                                                </small>
                                                &nbsp;
                                                <strong className="estimate-figure-style">
                                                    {slice_decimal_wt_rounding(
                                                        this.state.amount * 0.8
                                                    )}
                                                    &nbsp;
                                                    {convertDetails.walletTo}
                                                </strong>
                                                &nbsp;
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p>
                                                <small className="text-light">
                                                    {t("convert.20%_split")}
                                                </small>
                                                &nbsp;
                                                <strong className="estimate-figure-style">
                                                    {slice_decimal_wt_rounding(
                                                        this.state.amount * 0.2
                                                    )}
                                                    &nbsp;{"TC"}
                                                </strong>
                                                &nbsp;
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )}

                            {/* <div className="col-12 pt-3">
                            <div className="row">
                                <label className="depth-gray-label text-uppercase">
                                    {t("withdrawal.secondary_password")}
                                </label>
                            </div>
                        </div> */}
                            {/* 
                        <div className="col-12">
                            <div className="row">
                                <PasswordInput
                                    id="s_password"
                                    name="s_password"
                                    onChange={this.onChange}
                                    value={this.state.s_password}
                                    placeholder={t(
                                        "withdrawal.secondary_password"
                                    )}
                                    className="remove-placeholder depth-input"
                                    requiredMsg={t("validator.requiredMsg")}
                                    required
                                />
                            </div>
                        </div> */}

                            <div className="pt-4">
                                <button
                                    type="submit"
                                    className="col-12 depth-blue-btn"
                                >
                                    {t("withdrawal.submit")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, withdrawal, market, convertDeposit } = state;

    return {
        data: walletBalance.lite_data,
        withdrawal_data: withdrawal.data,
        market_data: market.data,
        post_convert_deposit: convertDeposit.data,
        isLoading: walletBalance.isLoading,
        memberWallet: walletBalance.memberWallet,
        convertSetting: walletBalance.convertData,
    };
};

const mapDispatchToProps = {
    postWalletBalanceLite,
    getMarketPrice,
    postConvertDeposit,
    getConvertSetting,
    getMemberWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositConvert);
