import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
//import {setLocale, t} from "react-i18nify";
import { t } from "../../common/translation";
import Modal from "../../components/modal";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import LoadingMask from "../../components/loadingMask";
import StringInput from "../../components/input/StringInput";
import PageHeader from "../../components/PageHeaderV2";

import {
    getTwoFactorAuthentication,
    postTwoFactorAuthentication,
} from "../../store/ducks/twoFactorAuthentication/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";

import { fieldChecking, GenHash } from "../../common/helpers";
import { formValidation } from "../../common/validator/formValidation";

import "./disableTwoFactorAuthentication.css";

import HyperModal from "../../components/HyperModal";

class DisableTwoFactorAuthentication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            qrCodeString: "",
            codeUrl: "",
            action: "enable",
            twoFAToken: "",
            twoFAStatus: 0,
            showModal: false,
            showLoadingModal: false,
            showSuccessModal: false,
            showErrorModal: false,
            errMsg: "",
            errType: "",
            successMsg: "",
            modal_type: "",
            modal_msg: "",
            confirm_visible: false,
        };

        this.handleDisable2FA = this.handleDisable2FA.bind(this);
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleToken = this.handleToken.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
    }

    handleToken(event) {
        this.setState({ twoFAToken: event.target.value });
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                this.props.history.push("/profile");
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/profile");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    handleModalSubmit(event) {
        this.props.closeModal("disable-2fa-modal");

        this.setState({
            confirm_visible: false,
            loading_visible: true,
        });

        this.props.openModal("disable-2fa-loading-modal");

        let sTwoFAObj = {};
        sTwoFAObj.username = sessionStorage.getItem("email");
        sTwoFAObj.twoFaToken = this.state.twoFAToken;
        sTwoFAObj.accessToken = sessionStorage.getItem("accessToken");
        // sTwoFAObj.codeUrl = this.state.twoFACodeUrl;
        sTwoFAObj.twoFaAction = "disable";

        let hash = GenHash(sTwoFAObj, process.env.REACT_APP_GM_SECRET_KEY);
        sTwoFAObj.hash = hash;

        this.props.postTwoFactorAuthentication(sTwoFAObj);
    }

    handleSuccess(event) {
        event.preventDefault();

        this.props.history.push("/profile");
    }

    handleError(event) {
        event.preventDefault();

        this.props.history.push("/profile");
    }

    handleDisable2FA(event) {
        event.preventDefault();
        let validate = fieldChecking(
            document.getElementById("disable-2FA-form")
        );

        if (validate === false) {
            return false;
        }

        // this.props.openModal("disable-2fa-modal");

        this.setState({
            modal_type: "normal",
            confirm_visible: true,
            loading_visible: false,
            // showModal: true,
            // showLoadingModal: false
        });
    }

    componentDidMount() {
        let profileObj = {};
        profileObj.username = sessionStorage.getItem("email");
        profileObj.token = sessionStorage.getItem("token");
        profileObj.accessToken = sessionStorage.getItem("accessToken");

        let hash = GenHash(profileObj, process.env.REACT_APP_GM_SECRET_KEY);
        profileObj.hash = hash;
        // setLocale(sessionStorage.getItem("lang"));
        this.props.getTwoFactorAuthentication(profileObj);

        formValidation("disable-2FA-form");
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "disable-2fa-modal") {
                this.setState({
                    showModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.twoFactorAuthData !== this.props.twoFactorAuthData) {
            if (nextProps.twoFactorAuthData.rst === "1") {
                this.setState({
                    qrCodeString: nextProps.twoFactorAuthData.data.secret,
                    codeUrl: nextProps.twoFactorAuthData.data.codeUrl,
                });
            }
        }

        if (
            nextProps.postDisableTwoFactorAuthData !==
            this.props.postDisableTwoFactorAuthData
        ) {
            if (nextProps.postDisableTwoFactorAuthData.rst === "1") {
                this.setState({
                    twoFAStatus: 0,
                    modal_msg: nextProps.postDisableTwoFactorAuthData.msg,
                    modal_type: "success",
                    modal_visible: true,
                    loading_visible: false,
                });
            } else {
                this.setState({
                    twoFAStatus: 0,
                    modal_msg: nextProps.postDisableTwoFactorAuthData.msg,
                    modal_type: "error",
                    modal_visible: true,
                    loading_visible: false,
                });
            }
        }
    }

    handleCloseModal() {
        this.props.closeModal("disable-2fa-modal");
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/profile");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    render() {
        const {
            confirm_visible,
            loading_visible,
            modal_msg,
            modal_visible,
            modal_type,
        } = this.state;

        return (
            <div>
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack={"/profile"}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("disable2FA.disable_2FA")}
                    arrowLeft={true}
                />
                <div className="mt-4">
                    <HyperModal
                        category="response"
                        modalVisible={loading_visible}
                        modalType="loading"
                        footer={[]}
                    />

                    <HyperModal
                        category="normal"
                        modalVisible={confirm_visible}
                        onOk={this.handleModalSubmit}
                        footer={
                            <div className="col-12 text-center">
                                <button
                                    key="submit"
                                    className="col-3 btn btn-primary"
                                    onClick={this.handleModalSubmit}
                                >
                                    {t("common.submit")}
                                </button>
                                <button
                                    key="button"
                                    className="col-3 btn btn-danger"
                                    onClick={(e) =>
                                        this.handleModalClose(e, "confirm")
                                    }
                                >
                                    {t("purchasePin.modal_close")}
                                </button>
                            </div>
                        }
                        modalContent={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>
                                        {t(
                                            "disable2FA.confirmation_header_msg"
                                        )}
                                    </h4>
                                    <p>{t("disable2FA.confirmation_msg")}</p>
                                </div>
                            </div>
                        }
                        onCancel={this.handleModalClose}
                        closable={false}
                    />

                    <HyperModal
                        category="response"
                        modalVisible={modal_visible}
                        modalType={modal_type}
                        modalMsg={modal_msg}
                        modalCloseFunc={this.handleModalClose}
                        cancelFunc={this.handleModalClose}
                    />

                    <div className="offset-md-2 col-md-8 px-0">
                        <form
                            id="disable-2FA-form"
                            onSubmit={this.handleDisable2FA}
                            className="needs-validation col-12"
                            noValidate
                        >
                            <div className="col-12">
                                <h4 className="disable-2fa-content text-light">
                                    {t("disable2FA.disable_2FA_remark")}
                                </h4>

                                <StringInput
                                    className="col-12 form-control depth-input"
                                    id="disable_2fa_token"
                                    name="disable_2fa_token"
                                    placeholder={t("disable2FA.enter_token")}
                                    onChange={this.handleToken}
                                    value={this.state.twoFAToken}
                                    required
                                />

                                <button
                                    type="button"
                                    className="col-12 mt-2 depth-red-btn text-uppercase"
                                    onClick={this.handleDisable2FA}
                                >
                                    {t("disable2FA.disable_2FA_btn")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { twoFactorAuthentication, modal } = state;

    return {
        twoFactorAuthData: twoFactorAuthentication.twoFactorAuthData,
        classIsShowing: modal.classIsShowing,
        postDisableTwoFactorAuthData:
            twoFactorAuthentication.postTwoFactorAuthData,
    };
};

const mapDispatchToProps = {
    getTwoFactorAuthentication,
    postTwoFactorAuthentication,
    openModal,
    closeModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisableTwoFactorAuthentication);
