import * as type from './types';
import axios from 'axios';


/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Aun
 */
export const getMarketPrice = () => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/market-price/get', {}, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_MARKET_PRICE,
            payload: response
        })
    }).catch (err => {
        dispatch ({
            type: type.GET_MARKET_PRICE,
            payload: {"data":{"rst":"0","msg":"failed","data":[]}}
        })
    })
};
