import { setTranslations } from "react-i18nify";

import landingEn from "./landing/en";
import landingZh from "./landing/zh-CN";
import loginEn from "./login/en";
import loginZh from "./login/zh-CN";
import registerEn from "./register/en";
import registerZh from "./register/zh-CN";
import homeEn from "./home/en";
import homeZh from "./home/zh-CN";
import profileEn from "./profile/en";
import profileZh from "./profile/zh-CN";
import globalTrendEn from "./globalTrend/en";
import globalTrendZh from "./globalTrend/zh-CN";
import sKeyEn from "./sKey/en";
import sKeyZh from "./sKey/zh-CN";
import walletDetailsEn from "./walletDetails/en";
import walletDetailsZh from "./walletDetails/zh-CN";
import depositEn from "./deposit/en";
import depositZh from "./deposit/zh-CN";
import withdrawalEn from "./withdrawal/en";
import withdrawalZh from "./withdrawal/zh-CN";
import stakingEn from "./staking/en";
import stakingZh from "./staking/zh-CN";
import purchasePinEn from "./purchasePin/en";
import purchasePinZh from "./purchasePin/zh-CN";
import changePasswordEn from "./changePassword/en";
import changePasswordZh from "./changePassword/zh-CN";
import TopUpPinEn from "./topupPin/en";
import TopUpPinZh from "./topupPin/zh-CN";
import SpecialRegisterEn from "./specialRegister/en";
import SpecialRegisterZh from "./specialRegister/zh-CN";
import PinListEn from "./pinList/en";
import PinListZh from "./pinList/zh-CN";
import Setup2FAEn from "./setup_2fa/en";
import Setup2FAZh from "./setup_2fa/zh-CN";
import Disable2FAEn from "./disable2FA/en";
import Disable2FAZh from "./disable2FA/zh-CN";
import changeTradingPasswordEn from "./changeTradingPassword/en";
import changeTradingPasswordZh from "./changeTradingPassword/zh-CN";
import subscribePinEn from "./subscribePin/en";
import subscribePinZh from "./subscribePin/zh-CN";
import referralEn from "./referral/en";
import referralZh from "./referral/zh-CN";
import myFriendsEn from "./myFriends/en";
import myFriendsZh from "./myFriends/zh-CN";
import emallEn from "./emall/en";
import emallZh from "./emall/zh-CN";
import forgotPasswordEn from "./forgotPassword/en";
import forgotPasswordZh from "./forgotPassword/zh-CN";
import timerEn from "./timer/en";
import timerZh from "./timer/zh-CN";
import validatorEn from "./validator/en";
import validatorZh from "./validator/zh-CN";
import transferEn from "./transfer/en";
import transferZh from "./transfer/zh-CN";
import ConvertEN from "./convert/en";
import ConvertZh from "./convert/zh-CN";
import rewardEn from "./rewards/en";
import rewardZh from "./rewards/zh-CN";
import globalEn from "./global/en";
import globalZh from "./global/zh-CN";
import topUpEn from "./topup/en";
import topUpZh from "./topup/zh-CN";
import productDetailsEn from "./productDetails/en";
import productDetailsZh from "./productDetails/zh-CN";
import myOrdersEn from "./myOrders/en";
import myOrdersZh from "./myOrders/zh-CN";
import giftCardEn from "./giftCard/en";
import giftCardZh from "./giftCard/zh-CN";
import addDeliveryEn from "./delivery/en";
import addDeliveryZh from "./delivery/zh-CN";
import emallConvertEn from "./emallConvert/en";
import emallConvertZh from "./emallConvert/zh-CN";
import resetTradingPasswordEn from "./resetTradingPassword/en";
import resetTradingPasswordZh from "./resetTradingPassword/zh-CN";
import multinaryTreeEn from "./multinaryTree/en";
import multinaryTreeZh from "./multinaryTree/zh-CN";
import stakingProgramEn from "./stakingProgram/en";
import stakingProgramZh from "./stakingProgram/zh-CN";
import networkEn from "./network/en";
import networkZh from "./network/zh-CN";
import personalInfoEn from "./personalInfo/en";
import personalInfoZh from "./personalInfo/zh-CN";
import exchangeEn from "./exchange/en";
import exchangeZh from "./exchange/zh-CN";
import auctionEn from "./auction/en";
import auctionZh from "./auction/zh-CN";
import shareEn from "./share/en";
import shareZh from "./share/zh-CN";
import averageEn from "./average/en";
import placementEn from "./placement/en";
import placementZh from "./placement/zh-CN";
import announcementEn from "./announcement/en";
import announcementZh from "./announcement/zh-CN";
import statementEn from "./programStatement/en";
import statementZh from "./programStatement/zh-CN";

setTranslations({
    en: {
        landing: landingEn,
        global: globalEn,
        login: loginEn,
        register: registerEn,
        home: homeEn,
        profile: profileEn,
        globalTrend: globalTrendEn,
        sKey: sKeyEn,
        walletDetails: walletDetailsEn,
        deposit: depositEn,
        withdrawal: withdrawalEn,
        staking: stakingEn,
        purchasePin: purchasePinEn,
        changePassword: changePasswordEn,
        changeTradingPassword: changeTradingPasswordEn,
        topUpPin: TopUpPinEn,
        setup2FA: Setup2FAEn,
        disable2FA: Disable2FAEn,
        specialRegister: SpecialRegisterEn,
        pinList: PinListEn,
        subscribePin: subscribePinEn,
        referral: referralEn,
        myFriends: myFriendsEn,
        emall: emallEn,
        forgotPassword: forgotPasswordEn,
        timer: timerEn,
        validator: validatorEn,
        transfer: transferEn,
        convert: ConvertEN,
        rewards: rewardEn,
        topup: topUpEn,
        prdDetails: productDetailsEn,
        myOrders: myOrdersEn,
        giftCard: giftCardEn,
        addDelivery: addDeliveryEn,
        emallConvert: emallConvertEn,
        resetTradingPassword: resetTradingPasswordEn,
        multinaryTree: multinaryTreeEn,
        stakingProgram: stakingProgramEn,
        network: networkEn,
        personalInfo: personalInfoEn,
        exchange: exchangeEn,
        auction: auctionEn,
        share: shareEn,
        average: averageEn,
        placement: placementEn,
        announcement: announcementEn,
        programStatement: statementEn,
    },
    "zh-CN": {
        landing: landingZh,
        global: globalZh,
        login: loginZh,
        register: registerZh,
        home: homeZh,
        profile: profileZh,
        globalTrend: globalTrendZh,
        sKey: sKeyZh,
        walletDetails: walletDetailsZh,
        deposit: depositZh,
        withdrawal: withdrawalZh,
        staking: stakingZh,
        purchasePin: purchasePinZh,
        changePassword: changePasswordZh,
        changeTradingPassword: changeTradingPasswordZh,
        topUpPin: TopUpPinZh,
        setup2FA: Setup2FAZh,
        disable2FA: Disable2FAZh,
        specialRegister: SpecialRegisterZh,
        pinList: PinListZh,
        subscribePin: subscribePinZh,
        referral: referralZh,
        myFriends: myFriendsZh,
        emall: emallZh,
        forgotPassword: forgotPasswordZh,
        timer: timerZh,
        validator: validatorZh,
        transfer: transferZh,
        convert: ConvertZh,
        rewards: rewardZh,
        topup: topUpZh,
        prdDetails: productDetailsZh,
        myOrders: myOrdersZh,
        giftCard: giftCardZh,
        addDelivery: addDeliveryZh,
        emallConvert: emallConvertZh,
        resetTradingPassword: resetTradingPasswordZh,
        multinaryTree: multinaryTreeZh,
        stakingProgram: stakingProgramZh,
        network: networkZh,
        personalInfo: personalInfoZh,
        exchange: exchangeZh,
        auction: auctionZh,
        share: shareZh,
        placement: placementZh,
        announcement: announcementZh,
        programStatement: statementZh,
    },
});

export default setTranslations;
