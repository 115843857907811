import React, { Component } from "react";
import { setLocale, t } from "react-i18nify";
import { connect } from "react-redux";

import PageHeader from "../../components/PageHeader";
import TabFooter from "../../components/TabFooter";
import LoadingMask from "../../components/loadingMask";
import Modal from "../../components/modal";

import { getMarketPrice } from "../../store/ducks/globalTrend/actions";

import ErrorIcon from "../../public/images/mark-cancel.png";
import imgDown from "../../public/images/global-trend-percent-chart/chat-red@3x.png";
import imgUp from "../../public/images/global-trend-percent-chart/chat-green@3x.png";

import "./globalTrend.scss";

class GlobalTrend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            assetHeight: window.innerHeight - (58 + 140),
            showErrorModal: false,
            errMsg: "",
            errType: "",
        };

        this.props.getMarketPrice();

        this.updateHeight = this.updateHeight.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
    }

    componentDidMount() {
        setLocale(sessionStorage.getItem("lang"));

        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data !== this.props.data) {
            if (nextProps.data.rst === "1") {
                let array;
                array = nextProps.data.data;

                this.setState({ loading: false });
                this.setState({ data: array });
            } else {
                switch (nextProps.data.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    case "failed":
                        this.setState({ loading: false });
                        this.setState({ data: [] });
                        break;
                    default:
                        nextProps.history.push("/login");
                        break;
                }
            }
        }
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    updateHeight() {
        this.setState({ assetHeight: window.innerHeight - (58 + 140) });
    }

    render() {
        const { data, loading, showErrorModal, errMsg } = this.state;

        const market_list = data.map(function (item, i) {
            if (item.daily >= 0) {
                return (
                    <tr key={i}>
                        <td>
                            <img
                                src={item.cryptoImgFileUrl}
                                width={40}
                                className="mb-1 mr-3"
                                alt=""
                            />
                            <small>
                                <b>{item.cryptoType}</b>
                            </small>
                        </td>
                        <td className="text-center tb-padding">
                            <img src={imgUp} alt={i} width={50} />
                            <small className="up lbl-chart-down">
                                <b>{item.daily * 1}%</b>
                            </small>
                        </td>
                        <td className="text-right">
                            <small>
                                <b>${item.price}</b>
                            </small>
                        </td>
                    </tr>
                );
            } else {
                return (
                    <tr key={i}>
                        <td>
                            <img
                                src={item.cryptoImgFileUrl}
                                width={40}
                                className="mb-1 mr-3"
                                alt=""
                            />
                            <small>
                                <b>{item.cryptoType}</b>
                            </small>
                        </td>
                        <td className="text-center tb-padding">
                            <img src={imgDown} alt={i} width={50} />
                            <small className="down lbl-chart-down">
                                <b>{item.daily * -1}%</b>
                            </small>
                        </td>
                        <td className="text-right tb-padding">
                            <small>
                                <b>${item.price}</b>
                            </small>
                        </td>
                    </tr>
                );
            }
        });

        return (
            <div className="globalTrend">
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <img
                                        src={ErrorIcon}
                                        className="modal-icon"
                                        alt=""
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}
                <PageHeader
                    title={t("globalTrend.global_trend")}
                    titleClass="header-white-color text-center"
                    titleHeader="pt-5 px-4 header-title"
                    backgroundHeight={140}
                />

                <div
                    className="table-height-overflow"
                    style={{ height: this.state.assetHeight }}
                >
                    <table className="table height-auto mb-0">
                        <thead>
                            <tr className="row-background-color">
                                <th scope="col" className="row-text-color">
                                    <small>
                                        <b>{t("globalTrend.coin_type")}</b>
                                    </small>
                                </th>
                                <th
                                    scope="col"
                                    className="text-center row-text-color"
                                >
                                    <div className="">
                                        <small>
                                            <b>
                                                {t("globalTrend.change_24hr")}
                                            </b>
                                        </small>
                                    </div>
                                </th>
                                <th
                                    scope="col"
                                    className="text-right row-text-color"
                                >
                                    <small>
                                        <b>{t("globalTrend.price")}</b>
                                    </small>
                                </th>
                            </tr>
                        </thead>
                        <tbody>{market_list}</tbody>
                    </table>
                    {loading && (
                        <div className="container pt-5">
                            <div className="row">
                                <div className="mx-auto">
                                    <LoadingMask width={80} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <TabFooter marketActive="active" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { market } = state;

    return {
        data: market.data,
    };
};

const mapDispatchToProps = {
    getMarketPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalTrend);
