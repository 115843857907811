import ExchangeChart from "../../pages/exchangeChart";

const routes = [
    {
        path: "/market/trading/view",
        component: ExchangeChart,
    },
];

export default routes;
