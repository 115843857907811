import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import "./translations/i18nify";

import { RoutingTest } from "./pages";
import LandingPage from "./pages/auth/landingPage";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ForgotPassword from "./pages/auth/forgotPassword";
import NotFound from "./exception/notFound";
import Store from "./store/store";
// import Market from "./pages/market/index";
import RequiredAuth from "./common/middleware/authPermission";
import RequiredPublicAuth from "./common/middleware/authPublicPermission";
import LangLoading from "./common/middleware/langLoading";
// import Wrapper from "./components/LoadingWrapper/Wrapper";
// import Timer from "./components/timer";

import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";

// import { text } from "./common/public-key";
import { GenHash } from "./assets/js/helpers";
import axios from "axios";
// import jwt from "jsonwebtoken";
import { isLoadingAction, getLanguageFile } from "./store/ducks/home/actions";
// import create from "antd/lib/icon/IconFont";
// import TradingHistory from "./pages/tradingHistory";
// import OrderDetail from "./pages/orderDetails/index";

function AppRouter() {
    if (sessionStorage.getItem("lang") === null) {
        sessionStorage.setItem("lang", "en");
    }
    Store.dispatch(getLanguageFile(sessionStorage.getItem("lang")));

    // setInterval(function () {
    //     if (!window.location.pathname.includes("FA")) {
    //
    //         if (sessionStorage.getItem("accessToken")) {
    //             axios
    //                 .create({withCredentials: false})
    //                 .post(

    //                     process.env.REACT_APP_GM_URL + "/member/refresh/token",
    //                     {
    //                         hash: GenHash(
    //                             sessionStorage.getItem("refreshToken"),
    //                             process.env.REACT_APP_GM_SECRET_KEY
    //                         ),
    //                     },
    //                     {
    //                         headers: {
    //                             "X-Authorization":
    //                                 "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
    //                             Authorization: sessionStorage.getItem(
    //                                 "accessToken"
    //                             ),
    //                         },
    //                     }
    //                 )
    //                 .then((response) => {
    //                     if (response.data.rst === "1") {
    //                         sessionStorage.setItem(
    //                             "accessToken",
    //                             response.data.data.access_token
    //                         );
    //                     } else {
    //                         sessionStorage.setItem("tokenExpired", "true");
    //                         sessionStorage.removeItem("uname");
    //                         sessionStorage.removeItem("accessToken");
    //                         sessionStorage.removeItem("refreshToken");
    //                         window.location.href = "/login";
    //                     }
    //                 })
    //                 .catch((err) => {
    //                     sessionStorage.setItem("tokenExpired", "true");
    //                     sessionStorage.removeItem("uname");
    //                     sessionStorage.removeItem("accessToken");
    //                     sessionStorage.removeItem("refreshToken");
    //                     window.location.href = "/login";
    //                 });
    //         }
    //     }
    // }, 300000);

    return (
        <Provider store={Store}>
            {/*<Timer />*/}
            <Router>
                <Switch>
                    <Route
                        exact
                        path="/landing"
                        component={LangLoading(RequiredPublicAuth(LandingPage))}
                    />
                    <Route
                        exact
                        path="/"
                        component={LangLoading(RequiredPublicAuth(LandingPage))}
                    />
                    <Route
                        exact
                        path="/login"
                        component={LangLoading(RequiredPublicAuth(Login))}
                    />
                    <Route
                        exact
                        path="/register"
                        component={LangLoading(RequiredPublicAuth(Register))}
                    />
                    <Route
                        exact
                        path="/forgotPassword"
                        component={LangLoading(
                            RequiredPublicAuth(ForgotPassword)
                        )}
                    />

                    {/*<Route path="/register/:referral?" component={Register}/>*/}
                    {/* <Route
                        exact
                        path="/market"
                        component={RequiredPublicAuth(Market)}
                    />
                    <Route
                        exact
                        path="/market/history"
                        component={RequiredPublicAuth(TradingHistory)}
                    />
                    <Route
                        exact
                        path="/order/detail"
                        component={RequiredPublicAuth(OrderDetail)}
                    /> */}
                    {RoutingTest().map((route, index) => (
                        <Route
                            exact
                            path={route.path}
                            key={index}
                            component={LangLoading(
                                RequiredAuth(route.component)
                            )}
                        />
                    ))}
                    <Route component={NotFound} />
                </Switch>
            </Router>
        </Provider>
    );
}

export default AppRouter;
