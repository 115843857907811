import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale, t } from "react-i18nify";
import { message } from "antd";
import copy from "copy-to-clipboard";
import { openModal, closeModal } from "../../store/ducks/modal/actions";
import { formValidation } from "../../common/validator/formValidation";
import { getMarketPrice } from "../../store/ducks/globalTrend/actions";
import { postWalletBalanceLite } from "../../store/ducks/home/actions";
import { postAuction } from "../../store/ducks/auction/actions";
import LoadingMask from "../../components/loadingMask";
import Modal from "../../components/modal";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";
import NumberInput from "../../components/input/NumberInput";
import PasswordInput from "../../components/input/PasswordInput";
import PageHeader from "../../components/PageHeaderV2";
import WalletBadge from "../../components/WalletBadge";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import {
    GenHash,
    fieldChecking,
    toFixedTrunc,
    delimiter,
} from "../../common/helpers";

import "./auction.scss";

class Auction extends Component {
    constructor(props) {
        super(props);

        let auctionFirstBadge = sessionStorage.getItem("auctionFirstBadge");
        this.myRef = React.createRef();
        this.state = {
            username: sessionStorage.getItem("email"),
            token: sessionStorage.getItem("token"),
            lang: sessionStorage.getItem("lang"),
            data: [],
            price_data: [],
            qr_path: "",
            setOpen: false,
            open: false,
            address: "",
            walletType:
                props.location.state !== undefined
                    ? props.location.state.walletType
                    : auctionFirstBadge,
            displayWalletType:
                props.location.state !== undefined
                    ? props.location.state.walletType
                    : auctionFirstBadge,
            cryptoAddress: "",
            showErrorModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            showLoadingModal: true,
            minAmount: 0,
            maxAmount: "",
            errMsg: "",
            errType: "",
            successMsg: "",
            s_password: "",
            estimated_amount: 0,
            available_amount: "0",
            amount_usdt: 0,
            wallet_balance_data: [],
            display: "not_display",
            auctionSetting: [],
        };
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.walletType = this.state.walletType;
        data.lang = sessionStorage.getItem("lang");
        var hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postWalletBalanceLite(data);

        this.handleTooltipClose = this.handleTooltipClose.bind(this);
        this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleSubmitAuctionConfirmation = this.handleSubmitAuctionConfirmation.bind(
            this
        );
        this.handleSubmitAuctionClose = this.handleSubmitAuctionClose.bind(
            this
        );
        this.handleSubmitAuction = this.handleSubmitAuction.bind(this);
    }

    async handleSubmitAuctionConfirmation(event) {
        event.preventDefault();

        if (
            parseFloat(this.state.amount_usdt) >=
            parseFloat(this.state.minAmount)
        ) {
            // document.getElementById('auction-form').classList.remove('was-validated');
            this.props.openModal("auction-confirmation-modal");
        } else {
            document
                .getElementById("withdraw-minimum-note")
                .classList.add("text-danger");
            var form = document.getElementById("auction-form");
            var validate = fieldChecking(form);

            if (validate === false) {
                return false;
            }
        }
    }
    handleSubmitAuctionClose(event) {
        event.preventDefault();
        document
            .getElementById("auction-form")
            .classList.remove("was-validated");

        this.props.closeModal("auction-confirmation-modal");
    }

    handleSubmitAuction(event) {
        event.preventDefault("auction-form");
        document
            .getElementById("auction-form")
            .classList.remove("was-validated");

        const data = [];
        let hash = "";

        data["secondaryPassword"] = this.state.s_password;
        data["token"] = sessionStorage.getItem("token");
        data["username"] = sessionStorage.getItem("email");
        data["walletType"] = this.state.walletType;
        data["withdrawAmount"] = this.state.amount_usdt;
        data["lang"] = sessionStorage.getItem("lang");
        hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data["hash"] = hash;

        this.setState({
            showConfirmationModal: false,
        });

        this.props.openModal("loading-modal");

        this.props.postAuction(data);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "loading-modal") {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }
            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
            if (
                nextProps.classIsShowing.modalName ===
                "auction-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.wallet_balance_data !== this.props.wallet_balance_data) {
            //Check login success update the routes for react
            if (
                nextProps.wallet_balance_data.rst === "1" &&
                nextProps.wallet_balance_data.data.walletDetailsSetup
                    .auctionSetting !== undefined
            ) {
                let array;
                let display = "";

                array = nextProps.wallet_balance_data.data;

                this.setState({
                    wallet_balance_data: array.walletBalance[0],
                    auctionSetting: array.auctionSetting,
                    display: display,
                    available_amount: array.walletBalance[0].available_amount,
                    minAmount:
                        array.walletDetailsSetup.auctionSetting.withdraw_min,
                    amount_usdt: "",
                    maxAmount: array.walletBalance[0].available_amount,
                    showLoadingModal: false,
                });
            } else {
                this.props.history.push({
                    pathname: "/home",
                    state: { error: "invalid_wallet_type_details" },
                });
            }
        }

        if (nextProps.market_data !== this.props.market_data) {
            if (nextProps.market_data.rst === "1") {
                let array;
                array = nextProps.market_data.data;

                this.setState({ price_data: array });
            }
        }
        if (nextProps.auction_data !== this.props.auction_data) {
            if (nextProps.auction_data.data.rst !== "1") {
                let response = "'";
                switch (nextProps.auction_data.data.msg) {
                    case "invalid_session":
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });

                        this.setState({
                            showLoadingModal: false,
                        });

                        this.props.openModal("error-modal");
                        break;

                    case "user_not_exist":
                        response = t("profile.user_not_exist");

                        this.setState({
                            gcoxReturnedMsg: response,
                            nav: nextProps.auction_data.data.nav,
                            showLoadingModal: false,
                        });
                        this.props.openModal("gcox-modal");
                        break;
                    case "kyc_not_passed":
                        this.setState(
                            {
                                errMsg: (
                                    <p>
                                        Please check{" "}
                                        <a
                                            href="https://acm.gcox.com"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            here
                                        </a>{" "}
                                        to do KYC
                                    </p>
                                ),
                                showLoadingModal: false,
                            },
                            () => {
                                this.props.openModal("error-modal");
                            }
                        );
                        break;
                    case "Not Enough Balance":
                        this.setState({
                            errMsg: nextProps.auction_data.data.msg,
                        });
                        this.setState({
                            showLoadingModal: false,
                        });

                        this.props.openModal("error-modal");
                        break;

                    default:
                        this.setState({
                            errMsg: nextProps.auction_data.data.msg,
                        });
                        this.setState({
                            showLoadingModal: false,
                        });

                        this.props.openModal("error-modal");
                        break;
                }
            } else {
                const data = {};

                this.props.openModal("success-modal");

                data.username = sessionStorage.getItem("email");
                data.token = sessionStorage.getItem("token");
                data.walletType = this.props.location.state.walletType;
                data.lang = sessionStorage.getItem("lang");
                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;

                this.setState({
                    amount_usdt: "",
                    s_password: "",
                    successMsg: t("auction." + nextProps.auction_data.data.msg),
                });
                this.setState({
                    showLoadingModal: false,
                });

                this.props.postWalletBalanceLite(data);
            }
        }
    }
    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/home");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleTooltipClose = () => {
        this.setState({ open: false });
    };

    handleTooltipOpen = () => {
        copy(this.state.address);

        message.success(t("copied"));
    };

    handleTabChange(event, ew, dw) {
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.cryptoCode = ew;
        data.walletType = ew;
        data.lang = sessionStorage.getItem("lang");

        let display = "";
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);

        data.hash = hash;
        this.props.postWalletBalanceLite(data);

        this.setState(
            {
                display: display,
                amount_usdt: "",
                estimated_amount: 0,
                walletType: dw,
                cryptoAddress: this.state.cryptoAddress,
                displayWalletType: dw,
                showLoadingModal: true,
            },
            () => {
                this.props.closeModal("deposit-modal");
            }
        );
    }

    onChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    render() {
        const {
            auctionSetting,
            displayWalletType,
            showErrorModal,
            errMsg,
            amount_usdt,
            display,
            showLoadingModal,
            wallet_balance_data,
            available_amount,
            showConfirmationModal,
            successMsg,
            showSuccessModal,
            minAmount,
            walletType,
        } = this.state;

        return (
            <div className="auction">
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="btn depth-red-btn col-12"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}
                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("transfer.confirmation_msg")}</h4>
                                </div>
                            </div>
                        }
                        closeText={t("purchasePin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={this.handleSubmitAuctionClose}
                        closeId=""
                        submitText={t("common.submit")}
                        submitClassName="btn depth-blue-btn"
                        submitFunc={this.handleSubmitAuction}
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="auction-confirmation-modal"
                    />
                )}
                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="col-12 btn depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div className="container-fluid px-0">
                    <PageHeader
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        historyBack="/home"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        availableAmount={wallet_balance_data.available_amount}
                        title={t("auction.celebauction")}
                        arrowLeft={true}
                    />
                </div>
                {auctionSetting.length > 0 && (
                    <WalletBadge
                        walletArrProps={auctionSetting}
                        badgeActive={
                            displayWalletType === ""
                                ? this.props.location.state !== undefined
                                    ? this.props.location.state.walletType
                                    : ""
                                : displayWalletType
                        }
                        badgeOnClick={this.handleTabChange}
                    />
                )}

                {display !== "not_display" && (
                    <div>
                        <div className="card card-background offset-md-2 col-md-8">
                            <div className="card-body pt-1 pb-1">
                                <form
                                    id="auction-form"
                                    className="txt-black needs-validation"
                                    noValidate
                                    onSubmit={
                                        this.handleSubmitAuctionConfirmation
                                    }
                                >
                                    >
                                    <div className="text-white">
                                        <span>
                                            {t("auction.available_balance")}:{" "}
                                            {toFixedTrunc(available_amount, 8)}
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="depth-gray-label text-uppercase">
                                                    {t("convert.amount")} (
                                                    {walletType})
                                                </label>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <NumberInput
                                                    id="amount_usdt"
                                                    name="amount_usdt"
                                                    className="remove-placeholder depth-input"
                                                    value={amount_usdt}
                                                    min={delimiter(minAmount)}
                                                    max={delimiter(
                                                        this.state
                                                            .available_amount
                                                    )}
                                                    placeholder={
                                                        t("auction.amount") +
                                                        walletType
                                                    }
                                                    onChange={this.onChange}
                                                    requiredMsg={t(
                                                        "validator.requiredMsg"
                                                    )}
                                                    textLessThan={t(
                                                        "validator.amount_should_less_than"
                                                    )}
                                                    textMoreThan={t(
                                                        "validator.amount_should_more_than"
                                                    )}
                                                    required
                                                />
                                                <span
                                                    id="withdraw-minimum-note"
                                                    className="depth-gray-label"
                                                >
                                                    {t("auction.minimum")}:{" "}
                                                    {this.state.minAmount}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <label className="depth-gray-label text-uppercase">
                                                <b>
                                                    {t(
                                                        "auction.secondary_password"
                                                    )}
                                                </b>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-12">
                                                <PasswordInput
                                                    id="s_password"
                                                    name="s_password"
                                                    onChange={this.onChange}
                                                    value={
                                                        this.state.s_password
                                                    }
                                                    placeholder={t(
                                                        "withdrawal.secondary_password"
                                                    )}
                                                    className="remove-placeholder depth-input"
                                                    requiredMsg={t(
                                                        "validator.requiredMsg"
                                                    )}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <p className="subscribe-note">
                                                {t("auction.notes")}
                                            </p>
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="col-12 depth-blue-btn"
                                    >
                                        {t("auction.submit")}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auction, modal, market, walletBalance } = state;

    return {
        auction_data: auction.data,
        classIsShowing: modal.classIsShowing,
        market_data: market.data,
        wallet_balance_data: walletBalance.lite_data,
    };
};

const mapDispatchToProps = {
    postWalletBalanceLite,
    closeModal,
    openModal,
    getMarketPrice,
    postAuction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Auction);
