import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Progress, Tabs, Select } from "antd";
// import { setLocale, t } from "react-i18nify";
import { t, setLanguageFileJson } from "../../common/translation";
import "./exchangeChart.scss";
import {
    getMarketList,
    getBuyOrderBook,
    getSellOrderBook,
    getTradingMatch,
    getTradingView,
} from "../../store/ducks/market/actions";
import { getAllMemberWallet } from "../../store/ducks/home/actions";
import HalfExchange from "../../public/v3/icon/updown-price.svg";
import RedExchange from "../../public/v3/icon/down-price.png";
import GreenExchange from "../../public/v3/icon/up-price.svg";
import StatementOpen from "../../public/v3/icon/statement-open-order.svg";
import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeaderV2";
import HyperModal from "../../components/HyperModal";
import CandleStickChart from "../market/candleCart";
import {
    amountChecking8,
    fieldChecking,
    GenHash,
    delimiter,
    slice_decimal_wt_rounding,
} from "../../common/helpers";
// import socketIOClient from "socket.io-client";
import { TVChartContainer } from "../TVChartContainer/index";
const { Option } = Select;
const InputGroup = Input.Group;

class ExchangeChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            market_list: "",
            market_item: {
                unit_price_display: 0,
            },
            buy_order_list: "",
            sell_order_list: "",
            fifteen: true,
            oneHour: false,
            fourHour: false,
            oneDay: false,
            order_match_list: [],
            trading_view: [],
            trading_view_chart: [],
            startRenderChart: false,
        };

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `60px`);

        this.onClickFilterChart = this.onClickFilterChart.bind(this);
        this.handleClickAction = this.handleClickAction.bind(this);
    }
    onClickFilterChart(e, filterValue) {
        var valueFilter = "";
        switch (filterValue) {
            case "15M":
                valueFilter = "15min";
                this.setState({
                    fifteen: true,
                    oneHour: false,
                    fourHour: false,
                    oneDay: false,
                    filterValue: valueFilter,
                });
                this.props.getTradingView({
                    crypto_code:
                        this.props.location.state.crypto_code.toUpperCase(),
                    history: valueFilter,
                });
                break;
            case "1H":
                valueFilter = "60min";
                this.setState({
                    fifteen: false,
                    oneHour: true,
                    fourHour: false,
                    oneDay: false,
                    filterValue: valueFilter,
                });
                this.props.getTradingView({
                    crypto_code:
                        this.props.location.state.crypto_code.toUpperCase(),
                    history: valueFilter,
                });
                break;
            case "4H":
                valueFilter = "240min";
                this.setState({
                    fifteen: false,
                    oneHour: false,
                    fourHour: true,
                    oneDay: false,
                    filterValue: valueFilter,
                });
                this.props.getTradingView({
                    crypto_code:
                        this.props.location.state.crypto_code.toUpperCase(),
                    history: valueFilter,
                });
                break;
            case "1D":
                valueFilter = "1day";
                this.setState({
                    fifteen: false,
                    oneHour: false,
                    fourHour: false,
                    oneDay: true,
                    filterValue: valueFilter,
                });
                this.props.getTradingView({
                    crypto_code:
                        this.props.location.state.crypto_code.toUpperCase(),
                    history: valueFilter,
                });
                break;
        }
    }

    handleClickAction(e, crypto_code, action) {
        this.props.history.push({
            pathname: "/market",
            state: {
                action: action,
                crypto_code: crypto_code,
            },
        });
    }
    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                crypto_code_from: this.props.location.state.crypto_code,
                crypto_code_to: this.props.location.state.crypto_code_to,
                market_item: this.props.location.state.market_item,
            });

            let sellData = {
                page: 1,
                crypto_code:
                    this.props.location.state.crypto_code.toUpperCase(),
            };
            let buyData = {
                page: 1,
                crypto_code:
                    this.props.location.state.crypto_code.toUpperCase(),
            };

            this.props.getBuyOrderBook(buyData);
            this.props.getSellOrderBook(sellData);
            this.props.getTradingMatch({
                crypto_code:
                    this.props.location.state.crypto_code.toUpperCase(),
            });

            this.props.getTradingView({
                crypto_code:
                    this.props.location.state.crypto_code.toUpperCase(),
                history: "1day",
            });
            // this.props.getTradingView({
            //     crypto_code: "LIGA",
            //     period_code: "1day",
            // });
        } else {
            this.props.history.push({
                pathname: "/market",
            });
        }
        // let data = {};
        // data.page = 1;
        // this.props.getMarketList(data);
        // // this.props.getOrderTransaction();
        // let data3 = {};
        // data3.page = 1;
        // data3.date_from = "";
        // data3.date_to = "";
        // data3.action_type = "";
        // this.props.getMemberOpenOrder(data3);

        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `60px`);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }

        if (nextProps.buy_order_book !== this.props.buy_order_book) {
            if (
                nextProps.buy_order_book.data.rst == "1" &&
                nextProps.buy_order_book.data.data
            ) {
                let i = 0;
                let buy_order_list =
                    nextProps.buy_order_book.data.data.available_trading_price_list.map(
                        (item) => (
                            <div
                                className="col-12 d-flex px-0 font-12"
                                key={i++}
                            >
                                <div className="col-6 px-0 text-left text-white">
                                    {item.quantity_display}
                                </div>
                                <div className="col-6 px-0 text-right text-green">
                                    {item.unit_price_display}
                                </div>
                            </div>
                        )
                    );
                let price =
                    nextProps.buy_order_book.data.data.unit_price_display.replace(
                        " USDT",
                        ""
                    );

                this.setState({
                    buy_order_list: buy_order_list,

                    current_price: parseFloat(price),
                });

                // if (this.state.is_init || this.state.change_dropdown) {
                //     this.setState({
                //         price: parseFloat(price),
                //         is_init: false,
                //         change_dropdown: false,
                //     });
                // }
            }
        }
        if (nextProps.sell_order_book !== this.props.sell_order_book) {
            if (
                nextProps.sell_order_book.data.rst == "1" &&
                nextProps.sell_order_book.data.data
            ) {
                let sell_order_list =
                    nextProps.sell_order_book.data.data.available_trading_price_list.map(
                        (item) => (
                            <div
                                className="col-12 d-flex px-0 font-12"
                                key={item.id}
                            >
                                <div className="col-6 px-0 text-red text-left">
                                    {item.unit_price_display}
                                </div>
                                <div className="col-6 px-0 text-right text-white">
                                    {item.quantity_display}
                                </div>
                            </div>
                        )
                    );
                let price =
                    nextProps.sell_order_book.data.data.unit_price_display.replace(
                        " USDT",
                        ""
                    );
                this.setState({
                    sell_order_list: sell_order_list,
                    current_price: parseFloat(price),
                });
            }
        }
        if (nextProps.market_data !== this.props.market_data) {
            // let market_list = [];

            if (nextProps.market_data.data.rst == "1") {
                let crypto_code = this.state.crypto_code;
                let crypto_code_to = this.state.crypto_code_to;
                // let decimal_from = this.state.decimal_from;
                // let decimal_to = this.state.decimal_to;
                let decimal_display = this.state.decimal_display;
                if (this.state.is_init) {
                    crypto_code =
                        nextProps.market_data.data.data[0].crypto_code_from;
                    crypto_code_to =
                        nextProps.market_data.data.data[0].crypto_code_to;
                    decimal_display =
                        nextProps.market_data.data.data[0].decimal_display;
                }

                let sellData = { page: 1 };
                let buyData = { page: 1 };
                if (this.state.is_post_call) {
                    crypto_code = this.state.crypto_code;
                    sellData.crypto_code = crypto_code.toUpperCase();
                    buyData.crypto_code = crypto_code.toUpperCase();
                } else {
                    buyData.crypto_code = crypto_code.toUpperCase();
                    sellData.crypto_code = crypto_code.toUpperCase();
                }

                this.props.getBuyOrderBook(buyData);
                this.props.getSellOrderBook(sellData);
                let data1 = {};
                if (this.state.is_init || this.state.is_post_call) {
                    if (this.state.is_buy) {
                        data1.walletType = crypto_code_to.toUpperCase();
                        this.props.getAllMemberWallet(data1);
                    } else {
                        data1.walletType = crypto_code.toUpperCase();
                        this.props.getAllMemberWallet(data1);
                    }
                }

                this.setState({
                    is_post_call: false,
                    // market_list: market_list,
                    market_data: nextProps.market_data.data.data,
                    crypto_code: crypto_code,
                    crypto_code_to: crypto_code_to,
                    decimal_display: decimal_display,
                });
            }
        }

        if (nextProps.order_match_list !== this.props.order_match_list) {
            // let market_list = [];

            if (nextProps.order_match_list.data.rst == "1") {
                this.setState({
                    order_match_list: nextProps.order_match_list.data.data,
                });
            }
        }

        if (nextProps.trading_view !== this.props.trading_view) {
            // let market_list = [];
            // if (nextProps.trading_view.data.rst == "1") {
            //     this.setState({
            //         trading_view: nextProps.trading_view.data.data.trading_view,
            //         startRenderChart: true,
            //     });
            // }
        }
    }

    render() {
        const TabPane = Tabs.TabPane;
        const selectAfter = "USDT";
        const {
            loading_visible,
            confirm_visible,
            modal_visible,
            modal_type,
            modal_msg,
            crypto_code,
            decimal_from,
            decimal_to,
            market_data,
        } = this.state;

        return (
            // onCancel={this.handleModalClose}
            //             closable={false}
            // cancelFunc={this.handleModalClose}
            // closable={true}
            <div className="market-chart">
                <div className="pb-4 offset-md-3 col-md-6 px-0">
                    <HyperModal
                        category="response"
                        modalVisible={loading_visible}
                        modalType="loading"
                        footer={[]}
                    />
                    {confirm_visible ? (
                        <HyperModal
                            category="s_password"
                            modalVisible={confirm_visible}
                            submitFunc={this.handleSubmitExchange}
                            cancelFunc={this.handleModalClose}
                            closable={true}
                        />
                    ) : (
                        ""
                    )}

                    <HyperModal
                        category="response"
                        modalVisible={modal_visible}
                        modalType={modal_type}
                        modalMsg={modal_msg}
                        modalCloseFunc={this.handleModalClose}
                        cancelFunc={this.handleModalClose}
                    />
                    <PageHeader
                        title={
                            this.state.crypto_code_from +
                            "/" +
                            this.state.crypto_code_to
                        }
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        historyBack="/market"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        arrowLeft={true}
                        headerBanner={false}
                        // btnRight={
                        //     <Link to="/market/history">
                        //         <div className="font-12">
                        //             {t("common.trade_history")}
                        //         </div>
                        //     </Link>
                        // }
                    />

                    <div className="exchange-container custom-height overflow-auto">
                        <div className="col-12 bg-dark-purple">
                            <div className="row p-2">
                                <div className="col-8 font-21 text-white d-flex">
                                    {this.state.market_item.unit_price_display}
                                </div>
                                <div className="col-4 font-21 text-white d-flex px-0">
                                    <div className="col-6 font-12 text-light-blue d-flex p-0">
                                        <span className="p-2">
                                            {"= 1 " + this.state.crypto_code_to}
                                        </span>
                                    </div>
                                    <div className="col-6 font-12 text-light-blue d-flex p-0">
                                        <span
                                            className="p-2 font-12"
                                            style={{
                                                color: this.state.market_item
                                                    .changes_period_percent_color_code,
                                            }}
                                        >
                                            {
                                                this.state.market_item
                                                    .changes_period_percent_display
                                            }{" "}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 py-4">
                            <div className="row">
                                <div
                                    className="px-2"
                                    onClick={(e) =>
                                        this.onClickFilterChart(e, "15M")
                                    }
                                >
                                    {" "}
                                    {this.state.fifteen ? (
                                        <div className="graph-button graph-button-active text-center">
                                            <span className="depth-gray-label text-dark">
                                                15M
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="graph-button text-center">
                                            <span className="depth-gray-label">
                                                15M
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="px-2"
                                    onClick={(e) =>
                                        this.onClickFilterChart(e, "1H")
                                    }
                                >
                                    {this.state.oneHour ? (
                                        <div className="graph-button graph-button-active text-center">
                                            <span className="depth-gray-label text-dark">
                                                1H
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="graph-button text-center">
                                            <span className="depth-gray-label">
                                                1H
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="px-2"
                                    onClick={(e) =>
                                        this.onClickFilterChart(e, "4H")
                                    }
                                >
                                    {this.state.fourHour ? (
                                        <div className="graph-button graph-button-active text-center">
                                            <span className="depth-gray-label text-dark">
                                                4H
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="graph-button text-center">
                                            <span className="depth-gray-label">
                                                4H
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="px-2"
                                    onClick={(e) =>
                                        this.onClickFilterChart(e, "1D")
                                    }
                                >
                                    {this.state.oneDay ? (
                                        <div className="graph-button graph-button-active text-center">
                                            <span className="depth-gray-label text-dark">
                                                1D
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="graph-button text-center">
                                            <span className="depth-gray-label">
                                                1D
                                            </span>
                                        </div>
                                    )}
                                </div>
                            
                            </div>
                        </div> */}
                        <div className="w-100">
                            {/* <CandleStickChart
                                test={this.state.trading_view}
                            ></CandleStickChart> */}

                            <TVChartContainer
                                test={this.state.crypto_code_from}
                            ></TVChartContainer>
                        </div>

                        <div className="col-12 tabs-area py-2 px-0">
                            <Tabs
                                className="tab-market col-12 px-0"
                                defaultActiveKey="1"
                            >
                                <TabPane
                                    tab={
                                        <div className="tab-market-item text-uppercase">
                                            {t("common.order")}
                                        </div>
                                    }
                                    key="1"
                                    id="2"
                                >
                                    <div className="col-12 px-0">
                                        <div className="row">
                                            <div className="col-12 py-2">
                                                <div className="col-12 d-flex px-2 text-light-grey font-12">
                                                    <div className="col-4 px-0 text-left">
                                                        {t("common.amount") +
                                                            "(" +
                                                            this.state
                                                                .crypto_code_from +
                                                            ")"}
                                                    </div>
                                                    <div className="col-4 px-0 text-center">
                                                        {t("common.price") +
                                                            "(" +
                                                            this.state
                                                                .crypto_code_to +
                                                            ")"}
                                                    </div>
                                                    <div className="col-4 px-0 text-right">
                                                        {t("common.amount") +
                                                            "(" +
                                                            this.state
                                                                .crypto_code_from +
                                                            ")"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 px-0 bg-black">
                                            <div className="row px-2 pt-2">
                                                <div className="col-6 pr-1">
                                                    {this.state.buy_order_list}
                                                </div>
                                                <div className="col-6 pl-1">
                                                    {this.state.sell_order_list}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>

                                <TabPane
                                    tab={
                                        <div className="tab-market-item text-uppercase">
                                            {t("common.trade")}
                                        </div>
                                    }
                                    key="2"
                                    id="2"
                                >
                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <div className="col-12 d-flex px-2 text-light-grey font-12">
                                                <div className="col-4 px-0 text-left">
                                                    {t("common.date")}
                                                </div>
                                                <div className="col-4 px-0 text-center">
                                                    {t("common.price")}
                                                </div>
                                                <div className="col-4 px-0 text-right">
                                                    {t("common.quantity")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 px-0 bg-black">
                                        <div className="row px-2 pt-2">
                                            <div className="text-white font-12 col-12">
                                                {this.state.order_match_list.map(
                                                    (item) => (
                                                        <div
                                                            className="col-12 d-flex px-0 font-12"
                                                            key={
                                                                item.created_at
                                                            }
                                                        >
                                                            <div className="col-4 px-0 text-white text-left">
                                                                {
                                                                    item.created_at
                                                                }
                                                            </div>
                                                            <div className="col-4 px-0 text-center text-white">
                                                                {
                                                                    item.unit_price
                                                                }
                                                            </div>
                                                            <div className="col-4 px-0 text-right text-white">
                                                                {
                                                                    item.total_unit
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                        <div className="chart-footer">
                            <div className="col-6 float-left bg-black p-2">
                                <button
                                    onClick={(e) =>
                                        this.handleClickAction(
                                            e,
                                            this.state.crypto_code_from,
                                            "2"
                                        )
                                    }
                                    className="button border-radius-4 btn-buy text-uppercase"
                                >
                                    {" "}
                                    {t("common.buy")}
                                </button>
                            </div>
                            <div className="col-6 float-left bg-black p-2">
                                <button
                                    onClick={(e) =>
                                        this.handleClickAction(
                                            e,
                                            this.state.crypto_code_from,
                                            "3"
                                        )
                                    }
                                    className="button border-radius-4 btn-sell text-uppercase"
                                >
                                    {t("common.sell")}
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { marketData, walletBalance } = state;

    return {
        market_data: marketData.market_data,
        // order_list: marketData.order_data,
        mem_open_order: marketData.mem_open_order,
        buy_order_book: marketData.buy_order_book,
        sell_order_book: marketData.sell_order_book,
        post_exchange_result: marketData.post_exchange_result,
        memberWallet: walletBalance.memberWallet,
        isLoading: walletBalance.isLoading,
        order_match_list: marketData.order_match_list,
        trading_view: marketData.trading_view,
    };
};

const mapDispatchToProps = {
    getMarketList,
    getBuyOrderBook,
    getSellOrderBook,
    getTradingMatch,
    getTradingView,
};
export default connect(mapStateToProps, mapDispatchToProps)(ExchangeChart);
