import React, { Component, Fragment } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";

import { closeModal } from "../../store/ducks/modal/actions";

import "./modal.scss";

class Modal extends Component {
    shouldComponentUpdate(nextProps) {
        return this.props.isShowing !== nextProps.isShowing;
    }

    render() {
        const {
            isShowing,
            title,
            children,
            closeText,
            closeClassName,
            closeFunc,
            closeId,
            submitText,
            submitClassName,
            submitFunc,
            submitId,
            modalFooterClassName,
            modalClassId,
            modalClassContent,
            modalBodyClass,
            modalTitleCloseFunc,
            modalClassName,
            modalHeadClass,
            modalTitleClass,
        } = this.props;

        const className = isShowing ? "modal-show" : "modal-hide";
        const blockClassName = isShowing ? "d-block" : "d-none";

        return (
            <Fragment>
                <div
                    tabIndex="-1"
                    role="dialog"
                    style={{ zIndex: 1500 }}
                    className={`modal overflow-auto ${modalClassId} ${className} ${blockClassName}`}
                >
                    <div
                        className={`modal-dialog modal-dialog-centered ${modalClassName}`}
                        role="document"
                    >
                        <div className={`modal-content ${modalClassContent}`}>
                            {title && (
                                <div
                                    className={`modal-header  ${modalHeadClass}`}
                                >
                                    <h5
                                        className={`modal-title ${modalTitleClass}`}
                                    >
                                        {title}
                                    </h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span
                                            aria-hidden="true"
                                            onClick={modalTitleCloseFunc}
                                        >
                                            &times;
                                        </span>
                                    </button>
                                </div>
                            )}

                            {children && (
                                <div className={`modal-body ${modalBodyClass}`}>
                                    {children}
                                </div>
                            )}

                            {submitText && (
                                <div className={modalFooterClassName}>
                                    {closeText && (
                                        <button
                                            type="button"
                                            id={closeId}
                                            className={`text-uppercase ${closeClassName}`}
                                            onClick={closeFunc}
                                        >
                                            {closeText}
                                        </button>
                                    )}

                                    <button
                                        type="button"
                                        id={submitId}
                                        className={submitClassName}
                                        onClick={submitFunc}
                                    >
                                        {submitText}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={`${
                        isShowing ? "modal-show modal-backdrop fade show" : ""
                    }`}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    classIsShowing: state.modal.classIsShowing,
});

const mapDispatchToProps = {
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

Modal.propTypes = {
    isShowing: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.element,
    closeText: PropTypes.string,
    closeClassName: PropTypes.string,
    closeFunc: PropTypes.any,
    closeId: PropTypes.string,
    submitText: PropTypes.string,
    submitClassName: PropTypes.string,
    submitFunc: PropTypes.any,
    submitId: PropTypes.string,
    modalFooterClassName: PropTypes.string,
    closeModalClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    modalBodyClass: PropTypes.string,
    modalHeadClass: PropTypes.string,
    modalTitleClass: PropTypes.string,
    modalId: PropTypes.string,
    modalClassId: PropTypes.string,
    modalClassContent: PropTypes.string,
    modalTitleCloseFunc: PropTypes.any,
};
