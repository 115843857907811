import * as type from './types';
import axios from 'axios';

export const postFriendLists = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/downline/tree', {
        "token":data['token'],
        "username": data['username'],
        "page": data['page'],
        "viewType": data['viewType'],
        "recursive": data['recursive'],
        "hash": data['hash'],
        "lang": data['lang']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_FRIEND_LIST,
            payload: response
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_FRIEND_LIST,
            payload: {"data": {"rst":"0","msg":"system_error", "data": []}}
        })
    })
};

export const postFriendListsV1 = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/downline/list', {
        "token":data['token'],
        "username": data['username'],
        "rootUser": data['rootUser'],
        "page": data['page'],
        "viewType": data['viewType'],
        "recursive": data['recursive'],
        "hash": data['hash'],
        "lang": data['lang']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_FRIEND_LIST,
            payload: response
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_FRIEND_LIST,
            payload: {"data": {"rst":"0","msg":"system_error", "data": []}}
        })
    })
};