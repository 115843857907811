import * as type from './types';
import axios from 'axios';

export const GetPinList = (res_data, url) => dispatch => {
    axios.post(url+ '/member/get/vouchers', {
        "page":res_data['page'],
        "token": res_data['token'],
        "username": res_data['username'],
        "viewType": res_data['viewType'],
        "hash": res_data['hash'],
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_PIN_LIST,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_PIN_LIST,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
}
