import * as type from './types';
import axios from 'axios';

export const getCarts = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/cart/list', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_CARTS,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.GET_CARTS,
            payload: {"rst":"0","msg":"system_error"}
        });
    })
};

export const postDeleteItem = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/cart/delete', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_DELETE_CART_ITEM,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_DELETE_CART_ITEM,
            payload: {"rst":"0","msg":"system_error"}
        });
    })
};

export const postUpdateMemCart = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/cart/update', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_UPDATE_CART_ITEM,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_UPDATE_CART_ITEM,
            payload: {"rst":"0","msg":"system_error"}
        });
    })
};
