import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { setLocale, t } from "react-i18nify";
import { getLanguageFile } from "../../store/ducks/home/actions";
import { t, setLanguageFileJson } from "../../common/translation";
import renderHTML from "react-render-html";
import TabFooter from "../../components/TabFooter";
import { GenHash } from "../../common/helpers";
import Modal from "../../components/modal";
import LoadingMask from "../../components/loadingMask";
import {
    postWalletBalanceLiteHome,
    getMemberProfile,
    getAnnoucementList,
    postHomePanel,
    getAllMemberWallet,
    getMissionSetting,
    getWithdrawalSetting,
    getExchangeSetting,
} from "../../store/ducks/home/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";
import SecondaryModal from "./secondaryModal";
import { formValidation } from "../../common/validator/formValidation";
import { message } from "antd";
import ErrorIcon from "../../components/ErrorAlert";
import HyperModal from "../../components/HyperModal";
import "./home.scss";

class Home extends Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.state = {
            username: sessionStorage.getItem("email"),
            token: sessionStorage.getItem("token"),
            lang: sessionStorage.getItem("lang"),
            data: [],
            show: false,
            subscriptionStatus: 1,
            loading_visible: true,
            assetHeight: window.innerHeight - (46 + 200),
            title: process.env.REACT_APP_TITLE,
            showDepositModal: false,
            showTransferModal: false,
            showSecondaryModal: false,
            showErrorModal: false,
            showWithdrawalModal: false,
            showStakingModal: false,
            showConvertModal: false,
            showNotice: false,
            errMsg: "",
            withdrawalErrMsg2: "",
            withdrawalErrMsg: "",
            errLink: "",
            errType: "",
            annoucementData: [],
            showAnnoucementModal: false,
            displayAnnoucementData: "",
            setupData: [],
            calculatedMarketValue: {
                convert_btc_amount: "0.00",
                total_estimated_amount: "0.00",
            },
            missionPoint: 0,
            exchangeData: [],
            calculatedMarketAmount: {
                convert_btc_amount: "0.00",
                total_estimated_amount: "0.00",
            },

            homePanel: [],
            memberWallet: [],
            missionSetting: 0,
            exchangeWallet: [],
        };

        this.handleShow = this.handleShow.bind(this);
        this.handleTransfer = this.handleTransfer.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.handleDepositClose = this.handleDepositClose.bind(this);
        this.handleTransferClose = this.handleTransferClose.bind(this);
        this.handleSecondaryClose = this.handleSecondaryClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleWithdrawalClose = this.handleWithdrawalClose.bind(this);
        this.handleWithdrawal = this.handleWithdrawal.bind(this);
        this.handleConvert = this.handleConvert.bind(this);
        this.handleConvertClose = this.handleConvertClose.bind(this);

        this.handleAnnoucementClose = this.handleAnnoucementClose.bind(this);

        this.handleNoticeOpen = this.handleNoticeOpen.bind(this);
        this.handleNoticeClose = this.handleNoticeClose.bind(this);
        this.handleStaking = this.handleStaking.bind(this);
        this.handleStakingClose = this.handleStakingClose.bind(this);
        this.handleGcox = this.handleGcox.bind(this);
        this.handleWarning = this.handleWarning.bind(this);
    }

    UNSAFE_componentWillMount() {
        // if (sessionStorage.getItem("lang") != null) {
        //     setLocale(sessionStorage.getItem("lang"));
        // }

        const data = {};
        data.token = this.state.token;
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;
        this.props.getMemberProfile(data);

        const annoucmentData = {};
        annoucmentData.langCode = sessionStorage.getItem("lang");
        const hash_annoucement = GenHash(
            annoucmentData,
            process.env.REACT_APP_GM_SECRET_KEY
        );
        annoucmentData.hash = hash_annoucement;

        this.props.getAnnoucementList(annoucmentData);

        //liew new
        this.props.postHomePanel(data);
        this.props.getAllMemberWallet(data);
        this.props.getMissionSetting(data);
        // this.props.getWithdrawalSetting(data);
        // this.props.getExchangeSetting(data);
    }

    handleGcox() {
        this.props.history.push("/share");
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.error !== undefined) {
                message.error(t("home." + this.props.location.state.error));
            }
        }
        // this.props.getLanguageFile(sessionStorage.getItem("lang"));

        formValidation("secondary-form");

        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.language_data !== this.props.language_data) {
            setLanguageFileJson();
        }
        if (nextProps.annoucementData !== this.props.annoucementData) {
            if (nextProps.annoucementData.rst === "1") {
                //  console.log(nextProps.annoucementData.data.current_notice);

                if (nextProps.annoucementData.data.current_notice !== null) {
                    this.state.annoucementData.push(
                        nextProps.annoucementData.data.current_notice
                    );

                    this.state.annoucementData.map(function (item, i) {
                        return null;
                    });

                    this.setState({
                        showAnnoucementModal: true,
                    });
                }
            }
        }

        if (
            nextProps.getMemberProfileData !== this.props.getMemberProfileData
        ) {
            if (nextProps.getMemberProfileData.rst === "0") {
                switch (nextProps.getMemberProfileData.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                }
            } else {
                sessionStorage.setItem(
                    "autoSellStatus",
                    nextProps.getMemberProfileData.data.autoSellStatus
                );
                if (
                    nextProps.getMemberProfileData.data
                        .secondaryPasswordStatus === "0"
                ) {
                    this.props.openModal("secondary-modal");
                }

                // sessionStorage.setItem('tierId', nextProps.getMemberProfileData.data.packageId);
            }
        }

        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "notice-modal") {
                this.setState({
                    showNotice: nextProps.classIsShowing.isShowing,
                });

                this.handleWithdrawalClose();
            }

            if (nextProps.classIsShowing.modalName === "deposit-modal") {
                this.setState({
                    showDepositModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "withdrawal-modal") {
                //Checking the kyc status
                // if(sessionStorage.getItem('kycStatus') === "0") {
                //
                //     this.setState({
                //         withdrawalErrMsg2: t("home.please_click"),
                //         errLink: t("home.here"),
                //         withdrawalErrMsg: t("home.to_do_the_kyc_verification"),
                //         showLoadingModal: false
                //     });
                //
                //     this.props.openModal("error-modal");
                // }else{
                this.setState({
                    showWithdrawalModal: nextProps.classIsShowing.isShowing,
                });
                // }
            }

            if (nextProps.classIsShowing.modalName === "transfer-modal") {
                this.setState({
                    showTransferModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "staking-modal") {
                this.setState({
                    showStakingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "convert-modal") {
                this.setState({
                    showConvertModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "secondary-modal") {
                this.setState({
                    showSecondaryModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }
        }
        if (nextProps.homePanel !== this.props.homePanel) {
            if (nextProps.homePanel.data.rst === "1") {
                this.setState({
                    homePanel: nextProps.homePanel.data.data,
                });
            }
        }
        if (nextProps.memberWallet !== this.props.memberWallet) {
            if (nextProps.memberWallet.data.rst === "1") {
                this.setState({
                    loading: false,
                    calculatedMarketAmount:
                        nextProps.memberWallet.data.data.calculatedMarketAmount,
                    memberWallet:
                        nextProps.memberWallet.data.data.walletBalance,
                });
            }
        }

        if (nextProps.missionSetting !== this.props.missionSetting) {
            if (nextProps.missionSetting.data.rst === "1") {
                this.setState({
                    missionSetting: nextProps.missionSetting.data.data,
                });
            }
        }

        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleWarning() {
        message.warning(t("common.function_temporarily_not_available"));
    }

    handleDepositClose() {
        this.props.closeModal("deposit-modal");
    }

    handleTransferClose() {
        this.props.closeModal("transfer-modal");
    }

    handleWithdrawalClose() {
        this.props.closeModal("withdrawal-modal");
    }

    handleStakingClose() {
        this.props.closeModal("staking-modal");
    }

    handleSecondaryClose() {
        this.props.closeModal("secondary-modal");
        this.props.history.push("/login");
    }

    updateHeight() {
        this.setState({ assetHeight: window.innerHeight - (46 + 200) });
    }

    handleNoticeOpen(e) {
        this.props.openModal("notice-modal");
    }

    handleNoticeClose(e) {
        this.props.closeModal("notice-modal");
    }

    handleShow(event) {
        this.props.openModal("deposit-modal");
    }

    handleTransfer(event) {
        this.props.openModal("transfer-modal");
    }

    handleWithdrawal(event) {
        this.props.openModal("withdrawal-modal");
    }

    handleStaking(event) {
        this.props.openModal("staking-modal");
    }

    handleConvert(event) {
        this.props.openModal("convert-modal");
    }

    handleConvertClose(event) {
        this.props.closeModal("convert-modal");
    }

    handleAnnoucementClose(event) {
        this.setState({
            showAnnoucementModal: false,
        });

        sessionStorage.setItem("showAnnoucement", 1);

        this.props.closeModal("annoucement-modal");
    }

    render() {
        const {
            // missionPoint,
            exchangeData,
            //data,
            loading_visible,
            //setupData,
            subscriptionStatus,
            showSecondaryModal,
            showErrorModal,
            errMsg,
            withdrawalErrMsg,
            withdrawalErrMsg2,
            errLink,
            annoucementData,
            showAnnoucementModal,
            homePanel,
            memberWallet,
            calculatedMarketAmount,
            missionSetting,
            exchangeWallet,
        } = this.state;

        let asset_list;
        let home_panel_list;
        let home_panel_list_up;

        const handleWarning = this.handleWarning;

        home_panel_list = homePanel.map(function (item, i) {
            if (item.position === "down" && item.status === "1") {
                return (
                    <div className={`col mb-3 px-1`} key={i}>
                        <Link to={item.url} target={item.target}>
                            <div className="home-panel-style text-center mx-auto">
                                <div className="p-2">
                                    <img
                                        src={item.src}
                                        alt=""
                                        width={25}
                                        className="mx-2"
                                    />
                                    <div className="panel-text-style text-truncate pt-1">
                                        {item.text}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                );
            } else if (item.position === "down" && item.status === "2") {
                return (
                    <div className={`col mb-3 px-1`} key={i}>
                        <Link to="#">
                            <div
                                className="home-panel-style text-center mx-auto"
                                onClick={handleWarning}
                            >
                                <div className="p-2">
                                    <img
                                        src={item.src}
                                        alt=""
                                        width={25}
                                        className="mx-2"
                                    />
                                    <div className="panel-text-style text-truncate pt-1">
                                        {item.text}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                );
            }
            return null;
        });

        home_panel_list_up = homePanel.map(function (item, i) {
            if (item.position === "up" && item.status === "1") {
                return (
                    <div className="col-6 mb-3 px-2" key={i}>
                        <Link to={item.url}>
                            <div className="home-panel-style-up text-center mx-auto">
                                <div className="p-2">
                                    <img
                                        src={item.src}
                                        alt=""
                                        width={20}
                                        className="mx-2"
                                    />
                                    {/*<div className="panel-text-style text-truncate pt-1">*/}
                                    {item.text}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </Link>
                    </div>
                );
            } else if (item.position === "up" && item.status === "2") {
                return (
                    <div className="col-6 mb-3 px-2" key={i}>
                        <Link to="#">
                            <div
                                className="home-panel-style-up text-center mx-auto"
                                onClick={handleWarning}
                            >
                                <div className="p-2">
                                    <img
                                        src={item.src}
                                        alt=""
                                        width={20}
                                        className="mx-2"
                                    />
                                    {/*<div className="panel-text-style text-truncate pt-1">*/}
                                    {item.text}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </Link>
                    </div>
                );
            }

            return null;
        });

        asset_list = memberWallet.map(function (item, i) {
            return (
                <li
                    className="list-group-item asset-panel-list-group-item shadow px-1"
                    key={i}
                >
                    <Link
                        to={{
                            pathname: "/wallet/details",
                            state: {
                                walletType: item.walletTypeCode.toString(),
                                displayCode: item.displayCode,
                            },
                        }}
                    >
                        <div className="col-12">
                            <div className="row">
                                <div>
                                    <img
                                        className="col asset-image px-0"
                                        src={item.fileUrl}
                                        alt=""
                                    />
                                </div>

                                <div className="col pr-0 pl-2 text-light">
                                    <div className="row col-12 px-0 mx-0">
                                        <div className="px-0 col-4 sky-blue-small-text-style text-truncate">
                                            {item.displayName}
                                        </div>
                                        <div className="px-0 col-8">
                                            <small className="fa-pull-right">
                                                {item.total_amount}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="row col-12 px-0 mx-0">
                                        <div className="col-6 px-0">
                                            <span className="sky-blue-small-text-style">
                                                ${item.rate}&nbsp;
                                            </span>
                                            <span className="sky-blue-small-text-style">
                                                USDT&nbsp;
                                            </span>

                                            {/*{item.daily_changes_percentage === 0 &&*/}
                                            {/*<span className="green-text-style">({item.daily_changes_percentage}%)</span>*/}
                                            {/*}*/}

                                            {item.daily_changes_percentage <
                                                0 && (
                                                <span className="green-text-style text-danger">
                                                    (
                                                    {item.daily_changes_percentage *
                                                        -1}
                                                    %)
                                                </span>
                                            )}

                                            {item.daily_changes_percentage >
                                                0 && (
                                                <span className="green-text-style">
                                                    (
                                                    {
                                                        item.daily_changes_percentage
                                                    }
                                                    %)
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-6 text-right px-0">
                                            <small>
                                                ≈ {item.total_estimated_amount}{" "}
                                                USDT
                                            </small>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="dark-gray-text-style">
                                            {t("common.available")}:{" "}
                                        </span>
                                        <span className="dark-gray-text-style">
                                            {" "}
                                            {item.available_amount}
                                        </span>
                                        &nbsp;&nbsp;&nbsp;
                                        <span className="dark-gray-text-style">
                                            {t("common.locked")}:{" "}
                                        </span>
                                        <span className="dark-gray-text-style">
                                            {item.locked_amount}
                                        </span>
                                    </div>
                                </div>

                                {/*<div className="fa-pull-right align-self-center"><img className="fa-pull-right" alt={i} src={RightArrow} width={10}/></div>*/}
                            </div>
                        </div>
                    </Link>
                </li>
            );
        });

        return (
            <div className="home container-fluid px-0">
                <Modal
                    title={t("home.set_up_secondary_pwd")}
                    children={<SecondaryModal />}
                    modalHeadClass={`modal-header-black`}
                    modalTitleClass={`modal-title-white`}
                    modalBodyClass={`modal-body-purple`}
                    isShowing={showSecondaryModal}
                    modalClassId="secondary-modal"
                    modalTitleCloseFunc={this.handleSecondaryClose}
                />

                {sessionStorage.getItem("showAnnoucement") === "0" && (
                    <Modal
                        title={t("home.annoucement")}
                        children={
                            <div className="row">
                                {annoucementData.map(function (item, i) {
                                    return (
                                        <div
                                            className="col-12 text-left"
                                            key={i}
                                        >
                                            {/*<h2><strong>{item[i].title}</strong></h2>*/}
                                            {/*<p>{item[i].description}</p>*/}
                                            {item[i].type === "iframe" &&
                                                renderHTML(
                                                    item[i]
                                                        .annoucement_file_path
                                                )}

                                            {item[i].type === "image" && (
                                                <img
                                                    src={
                                                        item[i]
                                                            .annoucement_file_path
                                                    }
                                                    width="100"
                                                    height="100"
                                                    alt={i}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText=""
                        modalHeadClass={`modal-header-black modal-title-white`}
                        modalTitleClass={`modal-title-white`}
                        modalBodyClass={`modal-body-purple`}
                        modalTitleCloseFunc={this.handleAnnoucementClose}
                        submitClassName="depth-blue-btn"
                        submitFunc=""
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showAnnoucementModal}
                        modalClassName="modal-lg"
                        modalClassId="annoucement-modal"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <img
                                        src={ErrorIcon}
                                        className="modal-icon"
                                        alt=""
                                    />
                                    {errLink && (
                                        <h4>
                                            {withdrawalErrMsg2}{" "}
                                            <a
                                                href="https://gcox.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {errLink}
                                            </a>
                                            {withdrawalErrMsg}
                                        </h4>
                                    )}
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="button btn-submit"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                        onCancel={this.handleErrorClose}
                        closable={true}
                    />
                )}

                <div className="background-header h-height">
                    <div className="text-center text-uppercase header pt-5 px-4 ">
                        <small className="text-light text-uppercase">
                            {t("home.total_assets_amounts")}
                        </small>
                    </div>
                    <div className="text-center">
                        <b className="text-light text-uppercase">
                            {calculatedMarketAmount.convert_btc_amount} BTC
                        </b>
                        <div>
                            <small className="text-light text-uppercase">
                                ≈{" "}
                                {calculatedMarketAmount.total_estimated_amount}{" "}
                                USDT
                            </small>
                        </div>
                    </div>
                    <div className="text-center pt-3 offset-md-2 col-md-8 px-2">
                        <div className="row col-12 px-0 mx-0">
                            {home_panel_list_up}
                        </div>
                    </div>
                </div>

                <div
                    className="container-fluid px-0 header-panel-div overflow-auto offset-md-2 col-md-8 pl-0 pr-0"
                    style={{ height: this.state.assetHeight }}
                >
                    <div
                        className="row col-12 mx-auto px-0 mt-3"
                        style={{ width: "400px" }}
                    >
                        {home_panel_list}
                    </div>
                    {(subscriptionStatus === "0" ||
                        subscriptionStatus === null) && (
                        <div className="mt-3 mx-auto">
                            <div className="alert alert-danger text-center">
                                <h5 className="subscribe-text">
                                    {t(
                                        "home.please_click_here_to_subscribe",
                                        "title"
                                    )}
                                    <Link to="/subscribePin">
                                        {" "}
                                        {t("home.here")}{" "}
                                    </Link>
                                    {t("home.subscribe_pin")}.
                                </h5>
                            </div>
                        </div>
                    )}
                    {/*<div className="gcox-home p-2 mb-2 mx-2">*/}
                    {/*    <div className="gcox-logo"> </div>*/}
                    {/*    <div className="gcox-content offset-md-2 col-md-8">*/}
                    {/*        <h6 className="text-white text-center">{t('share.gcox_panel')}</h6>*/}
                    {/*        <button type="submit" onClick={this.handleGcox} className="col-12 depth-blue-btn">{t('share.purchase')}</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/* <div className="px-0 mb-2">
                        <div className="px-2">
                            <div className="card mx-auto shadow">
                                <div className="card-body">
                                    <Link to="/mission/point/list">
                                        <div className="row">
                                            <div className="col-12 justify-content-center align-self-center">
                                                <h5 className="sky-blue-small-text-style text-uppercase profile-main-label">
                                                    <span>
                                                        {t(
                                                            "home.accelerated_release_program"
                                                        )}
                                                    </span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 justify-content-center align-self-center">
                                                <h3 className="text-light">
                                                    {missionSetting}
                                                </h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/*<div className="col-12">*/}
                    {/*    <h4 className="text-light">{t('home.my_assets')}</h4>*/}
                    {/*</div>*/}
                    <ul className="list-group list-group-flush px-2">
                        {asset_list}
                    </ul>
                    <HyperModal
                        category="response"
                        modalVisible={loading_visible}
                        modalType="loading"
                        footer={[]}
                    />
                    {/* {loading && (
                        <div className="container pt-5">
                            <div className="row">
                                <div className="mx-auto">
                                    <LoadingMask width={80} />
                                </div>
                            </div>
                        </div>
                    )} */}
                </div>
                <TabFooter assetActive="active" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, modal, getMemberProfile } = state;

    return {
        data: walletBalance.home_data,
        annoucementData: walletBalance.getAnnoucementData,
        classIsShowing: modal.classIsShowing,
        getMemberProfileData: getMemberProfile.getMemberProfileData,
        homePanel: walletBalance.homePanel,
        memberWallet: walletBalance.memberWallet,
        missionSetting: walletBalance.missionData,
        withdrawalSetting: walletBalance.withdrawalData,
        exchangeSetting: walletBalance.exchangeData,
        isLoading: walletBalance.isLoading,
        language_data: walletBalance.getLanguageFile,
    };
};

const mapDispatchToProps = {
    postWalletBalanceLiteHome,
    openModal,
    closeModal,
    getMemberProfile,
    getAnnoucementList,
    postHomePanel,
    getAllMemberWallet,
    getMissionSetting,
    getWithdrawalSetting,
    getExchangeSetting,
    getLanguageFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
