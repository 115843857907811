import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setLocale, t} from 'react-i18nify';
import { Carousel, Radio } from 'antd';
import Img from 'react-image';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Input, Icon } from 'antd';

import { postWalletBalance } from '../../store/ducks/home/actions';
import { getProductDetails, postAddToCart, postCartConfirmPayment, postProductVariantKey } from '../../store/ducks/emall/actions';

import './productDetails.scss';
import {Link} from "react-router-dom";
import {GenHash} from "../../common/helpers";
import { message } from 'antd';
import ShoppingCart from "../../components/ShoppingCart";
import LoadingMask from "../../components/loadingMask";

class ProductDetails extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      initialAmount: 1,
      amount: 1,
      headerHeight: window.innerWidth,
      productCode: '',
      productName: '',
      productUrl: '',
      productGroup: '',
      descName: '',
      descShort: '',
      descLong: '',
      productLabel: '',
      variant_label_first: '',
      variant_label_second: '',
      variant_label_third: '',
      master_code: '',
      variant_list_first: [],
      variant_list_second: [],
      variant_list_third: [],
      productImg: [],
      variant_selected: {},
      variant_combination: [],
      priceData: [],
      allowSubmit: true,
      loading: true
    }

    this.crousel = React.createRef();

    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.handleProductVariant = this.handleProductVariant.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleDirectPayment = this.handleDirectPayment.bind(this);
    this.onTest = this.onTest.bind(this);

    const data = {};

    if (this.props.location.state !== undefined) {
      data.product_code = this.props.location.state.productCode;
      data.lang = sessionStorage.getItem("lang");
      let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
      data.hash = hash;

      this.props.getProductDetails(data);
    }else{
      this.props.history.push({
        pathname: "/emall",
        state: {error: "invalid_product"}
      });
    }
  }

  componentDidMount() {
    setLocale(sessionStorage.getItem("lang"));

    window.addEventListener("resize", this.updateHeight);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.product_data !== this.props.product_data) {
      if (nextProps.product_data.rst === "1") {
        let product = nextProps.product_data.data[0];

        this.setState({
          master_code: product.master_code,
          productCode: product.product_code,
          productName: product.product_name,
          productUrl: product.product_url,
          productGroup: product.product_group,
          descName: product.description_name,
          descShort: product.short_description,
          descLong: product.long_description,
          productLabel: product.product_label,
          productImg: product.product_image,
          variant_label_first: product.variant_label_first,
          variant_label_second: product.variant_label_second,
          variant_label_third: product.variant_label_third,
          variant_list_first: product.variant_list[0].variant_first,
          variant_list_second: product.variant_list[0].variant_second,
          variant_list_third: product.variant_list[0].variant_third,
          variant_combination: product.variant_combination,
          priceData: product.price
        });

        this.myRef.current.innerHTML = product.long_description;

        if (product.variant_list[0].variant_first.length < 1){
          this.setState({allowSubmit: false});
        }

        this.setState({loading: false});
      }
      else {

        this.props.history.push("/emall");
      }
    }

    if (nextProps.product_cart !== this.props.product_cart) {

      message.config({
        top: '80%'
      });

      if (nextProps.product_cart.rst === "1") {

        sessionStorage.setItem('cartCount', nextProps.product_cart.cartCount);

        setTimeout(()=>{
          message.success(nextProps.product_cart.msg);
        },2000);


        let imgtodrag = document.getElementsByClassName('slick-slide')[1] === undefined ? document.getElementsByClassName('slick-slide')[0] : document.getElementsByClassName('slick-slide')[1];

        let viewcart = document.getElementsByClassName('cartStyle')[0];
        let imgtodragImage = imgtodrag.querySelector('img');

        let disLeft= imgtodrag.getBoundingClientRect ().left;
        // let disTop= imgtodrag.getBoundingClientRect ().top;
        let cartleft= viewcart.getBoundingClientRect ().left;
        let carttop= viewcart.getBoundingClientRect ().top;
        let image = imgtodragImage.cloneNode(true);

        image.style ='z-index: 1111; width: 100px;opacity:0.8; position:fixed; top:80%;left:'+ disLeft+'px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)';
        var rechange=document.body.appendChild(image);
        setTimeout(function() {
          // console.log(image);
          image.style.left=cartleft+'px';
          image.style.top=carttop+'px';
          image.style.width='40px';
          image.style.opacity='0';
        }, 200);
        setTimeout(function() {
          rechange.parentNode.removeChild(rechange);
        }, 2000);


      }
      else {
        message.error(nextProps.product_cart.msg);
      }
    }

    if (nextProps.variant_key !== this.props.variant_key){
      message.config({
        top: '80%'
      });
      if (nextProps.variant_key.rst === "1") {

        let item_arry = [];

        item_arry[0] = {
          "item_id": this.state.master_code,
          "code": this.state.productCode,
          "quantity": this.state.amount,
          "variantOpt": nextProps.variant_key.data.variant_code
        };

        sessionStorage.setItem('checkout_items', JSON.stringify(item_arry));

        this.props.history.push("/emall/delivery");
      }
      else{
        message.error(nextProps.variant_key.msg);
      }
    }

  }

  handleProductVariant(e) {
    let variant_array = this.state.variant_selected;
    let idx = 0;

    if (e.target.id !== "" && variant_array[e.target.id] !== e.target.innerText){
      if (e.target.id.includes('variant_1')){
        variant_array['variant1'] = e.target.value;
      }
      else if (e.target.id.includes('variant_2')) {
        variant_array['variant2'] = e.target.value;
      }

      if (e.target.id.includes('variant_1')){
        for (const [index, value] of this.state.variant_list_second.entries()) {
          for (const [index2, pair] of this.state.variant_combination.entries()) {
            if(index >= 0 && index2 >= 0 && pair[value].includes(e.target.value) === false){
              document.getElementById("variant_2_"+value).disabled = true;
              document.getElementById("variant_2_"+value).closest('.ant-radio-button-wrapper').classList.add('radio-disabled');
            }
            else{
              document.getElementById("variant_2_"+value).disabled = false;
              document.getElementById("variant_2_"+value).closest('.ant-radio-button-wrapper').classList.remove('radio-disabled');
            }
          }
        }
      }
      else if (e.target.id.includes('variant_2')){
        for (const [index, value] of this.state.variant_list_first.entries()) {
          for (const [index2, pair] of this.state.variant_combination.entries()) {
            if(index >= 0 && index2 >= 0 && pair[value].includes(e.target.value) === false){
              document.getElementById("variant_1_"+value).disabled = true;
              document.getElementById("variant_1_"+value).closest('.ant-radio-button-wrapper').classList.add('radio-disabled');
            }
            else{
              document.getElementById("variant_1_"+value).disabled = false;
              document.getElementById("variant_1_"+value).closest('.ant-radio-button-wrapper').classList.remove('radio-disabled');
            }
          }
        }
      }

    }

    this.setState({variant_selected: variant_array});

    if(this.state.variant_list_first.length > 0) {
      idx = 1;
      if (this.state.variant_list_second.length > 0) {
        idx = 2;
      }
    }

    if(Object.keys(variant_array).length === idx){
      this.setState({allowSubmit: false});
    }
    else {
      this.setState({allowSubmit: true});
    }
  }

  handleAddToCart(e) {

    const data = {};

    data.logID = 'a';
    data.prjCode = 'app';
    data.product_code = this.state.productCode;
    data.variant_opt = JSON.stringify(this.state.variant_selected);
    data.username = sessionStorage.getItem("email");
    data.quantity = this.state.amount;
    data.lang = sessionStorage.getItem("lang");
    let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
    data.hash = hash;

    this.props.postAddToCart(data);
  }

  handleDirectPayment(e){
    let data = {};

    data.username = sessionStorage.getItem("email");
    data.variant_opt = JSON.stringify(this.state.variant_selected);
    data.product_code = this.state.productCode;
    data.qty = this.state.amount;
    data.lang = sessionStorage.getItem('lang');

    this.props.postProductVariantKey(data);
  }

  updateHeight(){
    if (window.innerWidth <= 768) {
      this.setState({headerHeight: window.innerWidth});
    }
    else {
      this.setState({headerHeight: 768});
    }
  }

  onChange(e){
    this.setState({amount: e.target.value});
  }

  onTest(e){
    this.crousel.goTo(1);
  }

  onChangeAmount(e) {

    let amount = this.state.amount;

    if (e.target.id === 'plus'){
      this.setState({amount: parseInt(amount) + 1});
    }
    else if (e.target.id === 'minus' && amount !== 0){
      this.setState({amount: parseInt(amount) - 1});
    }
  }

  render() {

    const {initialAmount, loading, allowSubmit, priceData, amount, headerHeight, productImg, productLabel, productName, variant_label_first, variant_list_first, variant_label_second, variant_list_second/*, variant_label_third, variant_list_third, descShort, descLong*/} = this.state;

    const buttonPlus = <Icon type="plus" id="plus" onClick={this.onChangeAmount}/>;

    const buttonMinus = <Icon type="minus" id="minus" onClick={this.onChangeAmount}/>;

    const variant_type_first = variant_list_first.map(function (item, i){
      return (
          <Radio.Button id={"variant_1_"+item} key={i} value={item}>{item}</Radio.Button>
          );
    });

    const variant_type_second = variant_list_second.map(function (item, i){
      return (
          <Radio.Button id={"variant_2_"+item} key={i} value={item}>{item}</Radio.Button>
          );
    });

    // const variant_type_third = variant_list_third.map(function (item, i){
    //   return (
    //       <Radio.Button id="variant3" key={i} value={item}>{item}</Radio.Button>
    //       );
    // });

    const imgCarousel = productImg.map(function (item, i){

      return <div key={i}>
        <Img
            src={item}
            loader={
              <div className="container">
                <div className="row">
                  <div className="mx-auto">
                    <LoadingMask
                        width="100%"
                    />
                  </div>
                </div>
              </div>
            }
            alt={i}
            style={{width: "100%"}}
        />
        {/*<img src={item} alt={i}/>*/}
      </div>;
    });

    const priceList = priceData.map(function(item, i){
      if (i > 0){
        return (
            <div className="col-6" key={i}>
              <h4 className="priceStyle priceTextSizeStyle"><b>{item.display_code}&nbsp;{item.unit_price}</b></h4>
            </div>
        );
      }
      return null;
    });

    return (
        <div className="productDetails">
          <div className="row col-12 col-sm-12 px-0 mx-auto fixed-768-width">

            <ShoppingCart/>

            {window.innerWidth >= 768 &&
            <div className="navbar">
              <Link to="/emall">
                <span><FontAwesomeIcon icon={faArrowLeft}/></span>
              </Link>
            </div>
            }
            <div className="col-sm-6 px-0">
              <div className="container-fluid px-0 fixed-top fixed-lg-width">
                {window.innerWidth < 768 &&
                <div className="navbar">
                  <Link to="/emall">
                    <span><FontAwesomeIcon icon={faArrowLeft}/></span>
                  </Link>
                </div>
                }

                <Carousel
                    autoplay
                    ref={node => {
                      this.crousel = node;
                    }}
                >
                  {imgCarousel}
                </Carousel>
              </div>
            </div>

            {loading &&
            <div className="container pt-5">
              <div className="row">
                <div className="mx-auto">
                  <LoadingMask
                      width={80}
                  />
                </div>
              </div>
            </div>
            }

            {!loading &&
            <div className="col-12 col-sm-6 px-0">
              <div className="card content-style card-btm-borderless" style={{top: window.innerWidth > 767 ? 0 : headerHeight, marginBottom: 0, minHeight: window.innerWidth < 768 ? 0 : 425}}> {/*root:384*/}
                <div className="container-fluid px-0">
                  <div className="row col-12 imgHolder pt-2 mx-0">
                    {productLabel !== '' &&
                    <div className="col-4 px-0">
                      <span className="px-2 text-uppercase"><small><b>{productLabel}</b></small></span>
                    </div>
                    }

                    {/*<div className="col-3 px-0">*/}
                    {/*  <img src={starOffIcon} width={10} alt={1}/>*/}
                    {/*  <img src={starOffIcon} width={10} alt={2}/>*/}
                    {/*  <img src={starOffIcon} width={10} alt={3}/>*/}
                    {/*  <img src={starOffIcon} width={10} alt={4}/>*/}
                    {/*  <img src={starOffIcon} width={10} alt={5}/>*/}
                    {/*</div>*/}
                    {/*<div className="col-3 px-0 ratingStyle">*/}
                    {/*  <small>*/}
                    {/*    25 {t("prdDetails.ratings")}*/}
                    {/*  </small>*/}
                    {/*</div>*/}
                    {/*<div className="col-1 px-0">*/}
                    {/*  <button>*/}
                    {/*    <img src={shareIcon} width={15} alt={shareIcon}/>*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                    {/*<div className="col-1 px-0">*/}
                    {/*  <button>*/}
                    {/*    <img src={favoriteIcon} width={15} alt={favoriteIcon}/>*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                  </div>

                  <div className="col-12">
                    <b>{productName}</b>
                  </div>

                  <div className="col-12 pt-3">
                    <div className="row col-12">
                      <h4 className="priceStyle"><b>{priceData.length > 0 ? priceData[0].display_code : ''}&nbsp;{priceData.length > 0 ? priceData[0].unit_price : ''}</b></h4>&nbsp;
                      {priceData.length > 1 && <><label className="my-auto">{t("prdDetails.and")}</label>&nbsp;</>}
                    </div>
                  </div>

                  {priceData.length > 1 &&
                  <div className="row col-12">
                    {priceList}
                    {/*<div className="col-6">*/}
                    {/*  <h4 className="priceStyle priceTextSizeStyle"><b>PAC 1000</b></h4>*/}
                    {/*</div>*/}

                    {/*<div className="col-6">*/}
                    {/*  <h4 className="priceStyle priceTextSizeStyle"><b>La Liga 400</b></h4>*/}
                    {/*</div>*/}
                  </div>
                  }

                  <hr />

                  <div className="row col-12">
                    <div className="col-6">
                      <b>
                        {t("prdDetails.qty")}
                      </b>
                    </div>
                    <div className="col-6 px-0">
                      <Input addonBefore={buttonMinus} addonAfter={buttonPlus} defaultValue={initialAmount} value={amount} onChange={this.onChange}/>
                    </div>
                  </div>

                  {variant_label_first !== "" &&
                  <hr/>
                  }
                  {variant_label_first !== "" &&
                  <div className="row col-12">
                    <div className="col-6">
                      <b>
                        {variant_label_first}
                      </b>
                    </div>
                    <div className="row col-6" onClick={this.handleProductVariant}>
                      <Radio.Group buttonStyle="solid">
                        {variant_type_first}
                      </Radio.Group>
                    </div>
                  </div>
                  }

                  {variant_label_second !== "" &&
                  <hr/>
                  }
                  {variant_label_second !== "" &&
                  <div className="row col-12">
                    <div className="col-6">
                      <b>
                        {variant_label_second}
                      </b>
                    </div>
                    <div className="row col-6" onClick={this.handleProductVariant}>
                      <Radio.Group buttonStyle="solid">
                        {variant_type_second}
                      </Radio.Group>
                    </div>
                  </div>
                  }

                  {/*{variant_label_third !== "" &&*/}
                  {/*<hr/>*/}
                  {/*}*/}
                  {/*{variant_label_third !== "" &&*/}
                  {/*<div className="row col-12">*/}
                  {/*  <div className="col-6">*/}
                  {/*    <b>*/}
                  {/*      {variant_label_third}*/}
                  {/*    </b>*/}
                  {/*  </div>*/}
                  {/*  <div className="row col-6" onClick={this.handleProductVariant}>*/}
                  {/*    <Radio.Group size="large">*/}
                  {/*      {variant_type_third}*/}
                  {/*    </Radio.Group>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*}*/}

                  <hr className="hr-invisible"/>
                </div>
              </div>
            </div>
            }

            <div className="col-12 px-0">
              <div className="card content-style card-top-borderless" style={{top: window.innerWidth > 767 ? 0 : headerHeight-2, minHeight: window.innerWidth < 768 ? window.innerHeight - 60 - window.innerWidth : window.innerHeight - 60 - 384}}>
                <div className="container-fluid px-0">
                  <div className="col-12 margin-top">
                    <b>
                      {t("prdDetails.product_info")}
                    </b>
                  </div>
                  <hr />

                  <div className="col-12 margin-btm">
                    <small><b ref={this.myRef}></b></small>

                  </div>
                </div>
              </div>
            </div>

            <div className="card fixed-bottom footer-card">
              <div className="my-auto">
                <div className="row col-12 mx-0">
                  <div className="col-6 text-center">
                    <Link to="#">
                      <button className="col-12 col-sm-6 btn btn-success text-uppercase" onClick={this.handleAddToCart} disabled={allowSubmit}>
                        <small>
                          {t("emall.add_to_cart")}
                        </small>
                      </button>
                    </Link>
                  </div>
                  <div className="col-6 text-center">
                      <button className="col-12 col-sm-6 btn btn-secondary text-uppercase" onClick={this.handleDirectPayment} disabled={allowSubmit}>
                        <small>
                          {t("prdDetails.pay_now")}
                        </small>
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  const { walletBalance, emall } = state;

  return {
    data: walletBalance.data,
    product_data: emall.product_details,
    product_cart: emall.product_cart,
    confirm_payment: emall.confirm_payment,
    variant_key: emall.variantKey
  };
};

const mapDispatchToProps = {
  postWalletBalance,
  getProductDetails,
  postAddToCart,
  postCartConfirmPayment,
  postProductVariantKey
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetails);
