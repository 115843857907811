import React, { Component } from "react";
import { connect } from "react-redux";
// import { setLocale, t } from "react-i18nify";
import { getLanguageFile } from "../../store/ducks/home/actions";
import { t, setLanguageFileJson } from "../../common/translation";
import QRCode from "qrcode.react";
import PageHeader from "../../components/PageHeaderV2";
import CopyIcon from "../../public/images/icon-copy@3x.png";
import { postWalletBalance } from "../../store/ducks/home/actions";
import { getStakingHistory } from "../../store/ducks/walletDetails/actions";
import DiamondOn from "../../public/images/icon-diamond-on@3x.png";
import { message, Tabs, Checkbox } from "antd";
import "./personalInfo.scss";
// import DiamondOff from "../../public/images/icon-diamond-off@3x.png";

class PersonalInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name:
                props.location.state !== undefined
                    ? props.location.state.name
                    : props.history.goBack(),
            email:
                props.location.state !== undefined
                    ? props.location.state.email
                    : props.history.goBack(),
            mobile_no:
                props.location.state !== undefined
                    ? props.location.state.mobile_no
                    : props.history.goBack(),
            tier:
                props.location.state !== undefined
                    ? props.location.state.tier
                    : props.history.goBack(),
            rank:
                props.location.state !== undefined
                    ? props.location.state.rank
                    : props.history.goBack(),

            QR_SIZE: 0,
            rerender: false,
        };

        this.handleCopyText = this.handleCopyText.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.props.getLanguageFile(sessionStorage.getItem("lang"));

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `50px`);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.language_data !== this.props.language_data) {
            setLanguageFileJson();
            // this.setState({
            //     rerender: true,
            // });
        }
    }

    updateHeight() {
        let doc_width = document.getElementById("widthparent").offsetWidth;

        // let minuswidth = (doc_width * 20) / 100;

        this.setState({
            QR_SIZE: doc_width - 20,
        });
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });

        this.props.getLanguageFile(sessionStorage.getItem("lang"));
        let doc_width = document.getElementById("widthparent").offsetWidth;

        // let minuswidth = (doc_width * 20) / 100;
        this.setState({
            QR_SIZE: doc_width - 20,
        });
        // let offsetHeight = document.getElementById("widthparent").offsetHeight;
        // // console.log(offsetHeight, doc_width);
        window.addEventListener("resize", this.updateHeight);
    }

    handleCopyText() {
        this.copied = false;

        const textarea = document.createElement("textarea");
        textarea.value = document.getElementById("referral_link").innerText;

        document.body.appendChild(textarea);

        textarea.select();

        try {
            document.execCommand("copy");

            this.copied = true;

            message.success(t("referral.copied"));
        } catch (err) {
            this.copied = false;
        }

        textarea.remove();
    }

    render() {
        let i = 0;
        let ranking = [];

        if (this.state.rank !== "0") {
            for (i = 0; i < parseInt(this.state.rank); i++) {
                ranking.push(
                    <img
                        src={DiamondOn}
                        alt=""
                        key=""
                        width={9}
                        className="mb-1 mr-1"
                    />
                );
            }
        }

        return (
            <div className="personalInfo">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack={"/profile"}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("personalInfo.personalInfo")}
                    arrowLeft={true}
                />
                <div className="overflow-auto custom-height">
                    <div className="px-0 mt-1 offset-md-2 col-md-8">
                        <div id="" className="mx-auto shadow">
                            <div className="card-body">
                                <div className="form-group border-bottom-label">
                                    <div className="row">
                                        <span className="col-4 text-uppercase label-info">
                                            {t("profile.username")}
                                        </span>
                                        <div className="col-8 text-right label-info">
                                            {this.state.name}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group border-bottom-label">
                                    <div className="row">
                                        <div className="col-4 text-uppercase label-info">
                                            {t("profile.email")}
                                        </div>
                                        <div className="col-8 text-right label-info">
                                            {this.state.email}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group border-bottom-label">
                                    <div className="row">
                                        <div className="col-4 text-uppercase label-info">
                                            {t("profile.mobile")}
                                        </div>
                                        <div className="col-8 text-right label-info">
                                            {this.state.mobile_no}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group border-bottom-label">
                                    <div className="row">
                                        <div className="col-4 text-uppercase label-info">
                                            {t("profile.tier")}
                                        </div>
                                        <div className="col-8 text-right label-info">
                                            {this.state.tier}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group border-bottom-label">
                                    <div className="row">
                                        <div className="col-4 text-uppercase label-info">
                                            {t("profile.rank")}
                                        </div>
                                        <div className="col-8 text-right label-info">
                                            {ranking}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* here */}

                    <div>
                        <div
                            className="container offset-md-2 col-md-8 px-0"
                            // style={{ minWidth: "700px" }}
                        >
                            <div className="card bg-transparent">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h4
                                            id="widthparent"
                                            className="pb-3 col-12 text-center title text-white text-uppercase"
                                        >
                                            {t("referral.refer_friend")}
                                        </h4>
                                        <QRCode
                                            className="bg-white"
                                            style={{ padding: "40px" }}
                                            size={
                                                this.state.QR_SIZE > 400
                                                    ? 400
                                                    : this.state.QR_SIZE
                                            }
                                            // size={256}
                                            value={
                                                process.env.REACT_APP_URL +
                                                "register?referral=" +
                                                this.state.qrCodeReferral
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pt-1 offset-md-2 col-md-8 ">
                            <div className="col-12 text-center depth-gray-label">
                                {t("referral.scan_qr_to_register")}
                            </div>
                        </div>

                        <hr />
                        <div className="card-body text-center pt-1 pb-1 word-wrap ">
                            <div className="pr-3 pl-3 text-light">
                                <small id="referral_link">
                                    {process.env.REACT_APP_URL}
                                    register?referral=
                                    {sessionStorage.getItem("email")}
                                </small>
                            </div>
                        </div>

                        {/* <div className="px-0 mt-1 offset-md-3 col-md-6 pb-3"> */}
                        <div className="col-12 pb-4 pt-3 button-small-padding">
                            <button
                                type="submit"
                                onClick={this.handleCopyText}
                                className="offset-md-3 col-md-6 button-color depth-blue-btn text-uppercase"
                            >
                                {t("referral.copy_your_referral_link")}{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, walletDetails } = state;

    return {
        data: walletBalance.data,
        transaction_list: walletDetails.data,
        language_data: walletBalance.getLanguageFile,
    };
};

const mapDispatchToProps = {
    postWalletBalance,
    getStakingHistory,
    getLanguageFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
