export const required = (value, init = false, requiredMsg) => {
    let message = requiredMsg === "" ? "" : requiredMsg;

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    let stringLength = value.toString().length;

    if (stringLength === 0) {
        return {
            validity: true,
            message,
        };
    }

    return {
        validity: false,
        message: "",
    };
};

export const minLength = (value, length, init = false, minLengthMsg) => {
    let message =
        minLengthMsg === ""
            ? `Minimum ${length} character(s). `
            : minLengthMsg + " " + length;

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    let stringLength = value.toString().length;

    if (stringLength < length) {
        /*let remainingLength = length - stringLength;*/

        let message =
            minLengthMsg === ""
                ? `Minimum ${length} character(s). `
                : minLengthMsg + " " + length;

        return {
            validity: true,
            message,
        };
    }

    return {
        validity: false,
        message: "",
    };
};

export const maxLength = (value, length, init = false, maxLengthMsg) => {
    let message =
        maxLengthMsg === ""
            ? `Maximum ${length} character(s). `
            : maxLengthMsg + " " + length;

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    let stringLength = value.toString().length;

    if (stringLength > length) {
        return {
            validity: true,
            message,
        };
    }

    return {
        validity: false,
        message: "",
    };
};

export const email = (value, init = false, emailMessage) => {
    let message = emailMessage === "" ? "Please enter a email" : emailMessage;

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    // source: https://gist.github.com/gregseth/5582254 (APRA format Internet Text Messages)
    const RFC2822_email_regex = new RegExp(
        "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?"
    );

    if (RFC2822_email_regex.test(value)) {
        return {
            validity: false,
            message: "",
        };
    }

    return {
        validity: true,
        message,
    };
};

export const minValue = (value, minimum, init = false, textMoreThan) => {
    let message =
        textMoreThan === ""
            ? `Number should be more than ${minimum}. `
            : textMoreThan + minimum + ". ";

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    let float = parseFloat(value);
    let minimum_value = parseFloat(minimum);

    if (float >= minimum_value) {
        return {
            validity: false,
            message: "",
        };
    }

    return {
        validity: true,
        message,
    };
};

export const maxValue = (value, maximum, init = false, textLessThan) => {
    let message =
        textLessThan === ""
            ? `Number should be less than ${maximum}. `
            : textLessThan + maximum + ". ";

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    let float = parseFloat(value);
    maximum = parseFloat(maximum);

    if (float > maximum) {
        return {
            validity: true,
            message,
        };
    }

    return {
        validity: false,
        message: "",
    };
};

export const number = (value, init = false) => {
    let message = `This field must be a number. `;

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    let number = parseFloat(value);

    if (!isNaN(number) && isFinite(value)) {
        return {
            validity: true,
            message: "",
        };
    }

    return {
        validity: false,
        message,
    };
};

export const decimal = (value, init = false) => {
    let message = `The number only allow 8 decimal number`;

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    if (value === Math.floor(value)) {
        return {
            validity: false,
            message: "",
        };
    } else {
        // source: https://gist.github.com/gregseth/5582254 (APRA format Internet Text Messages)
        const decimal_regex = new RegExp("^[0-9]+\\.\\d{0,8}$");

        if (decimal_regex.test(value)) {
            return {
                validity: false,
                message: "",
            };
        }

        return {
            validity: true,
            message,
        };
    }
};

export const blockTen = (value, init = false, blockTenMsg) => {
    let message = blockTenMsg === "" ? `Should be block of 10 ` : blockTenMsg;

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    let number = parseFloat(value);

    if (number % 10 <= 0) {
        return {
            validity: false,
            message: "",
        };
    }

    return {
        validity: true,
        message,
    };
};

export const blockHundreds = (value, init = false, blockHundredsMsg) => {
    let message =
        blockHundredsMsg === "" ? `Should be block of 100 ` : blockHundredsMsg;

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    let number = parseFloat(value);

    if (number % 100 <= 0) {
        return {
            validity: false,
            message: "",
        };
    }

    return {
        validity: true,
        message,
    };
};

export const passwordFormat = (value, init = false, passwordFormatMsg) => {
    let message = passwordFormatMsg === "" ? "" : passwordFormatMsg;

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    // source: https://gist.github.com/gregseth/5582254 (APRA format Internet Text Messages)
    const password_regex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!?%*&+*._#/=])[A-Za-z\\d@$!?#%*&._+*/=]{8,}$"
    );

    if (password_regex.test(value)) {
        return {
            validity: false,
            message: "",
        };
    }

    return {
        validity: true,
        message,
    };
};

export const checkPasswordMatch = (
    old_password,
    confirm_password,
    init = false,
    checkPasswordMatchMsg
) => {
    let message = checkPasswordMatchMsg === "" ? "" : checkPasswordMatchMsg;

    if (init) {
        return {
            validity: "",
            message,
        };
    }

    if (old_password !== confirm_password) {
        return {
            validity: true,
            message,
        };
    }

    return {
        validity: false,
        message: "",
    };
};
