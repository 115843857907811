import myFriends from "../../pages/myFriends";
import friendTree from "../../pages/friendTree/index";

const routes = [
    {
        path: "/friends",
        component: myFriends,
    },
    {
        path: "/friends/tree",
        component: friendTree,
    },
];

export default routes;
