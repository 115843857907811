import React, { Component } from "react";
import { connect } from "react-redux";
import { t } from "../../../common/translation";

import PageHeader from "../../../components/PageHeaderV2";
import {
    getMemberWallet,
    getWithdrawalSetting,
} from "../../../store/ducks/home/actions";
import { postWithdrawal } from "../../../store/ducks/withdrawal/actions";
import { postPurchaseRate } from "../../../store/ducks/purchaseRate/actions";

import StringInput from "../../../components/input/StringInput";
import NumberInput from "../../../components/input/NumberInput";
import PasswordInput from "../../../components/input/PasswordInput";
import LoadingMask from "../../../components/loadingMask";
import ConfirmationIcon from "../../../components/ConfirmationAlert";

import {
    amountChecking2,
    fieldChecking,
    GenHash,
    amountChecking8,
    delimiter,
    slice_decimal_wt_rounding,
    parseFloatV2,
} from "../../../common/helpers";

import "./withdrawal.scss";
import WalletBadge from "../../../components/WalletBadge";
import DropDownBox from "../../../components/input/DropDownBox";
import HyperModal from "../../../components/HyperModal";

class Withdrawal extends Component {
    constructor(props) {
        super(props);

        let withdrawFirstBadge = sessionStorage.getItem("withdrawFirstBadge");

        this.state = {
            data: [],
            remaining: 0,
            s_password: "",
            address: "",
            amount: "",
            available_amount: "0",
            minAmount: "100",
            maxAmount: "",
            avg_rate: "0",
            avg_staking_rate: "0",
            toAddressOnOff: "0",
            average_price: 0,
            avg_purchased_rate: 0,
            balance: "0",
            available_balance: "0",
            walletType:
                props.location.state === undefined
                    ? withdrawFirstBadge
                    : props.location.state.walletType,
            extra_admin_fee: "0.00000000",
            total_admin_fee: "0.00000000",
            modal_gcox: false,
            loading: true,
            nav: "",
            errType: "",
            gcoxReturnedMsg: "",
            withdrawAdminFee: "0.00000000",
            displayWalletType:
                props.location.state === undefined
                    ? withdrawFirstBadge
                    : props.location.state.walletType,
            withdrawSetting: [],
            withdrawFirstBadge:
                props.location.state === undefined
                    ? withdrawFirstBadge
                    : props.location.state.walletType,
            purchaseRate: [],
            type_options: [
                {
                    value: "",
                    selected: false,
                    name: t("withdrawal.please_select"),
                },
            ],
            specialWallets: ["PAC", "LIGA", "ACM"],
            specialWallets2: [],
            platform_value: "",
            coexFee: "0",
            modal_visible: false,
            loading_visible: this.props.loading_visible,
            modal_type: "",
            modal_msg: "",
            confirm_visible: false,
            type: [],
            type_selected: "",
            vertex_control: [],
            gcox_control: [],
            b_vertex: 0,
            vertex_username: "",
            gcox_flag: 0,
            vertex_flag: 0,
        };

        // const data = {};
        //
        // data.username = sessionStorage.getItem("email");
        // data.token = sessionStorage.getItem("token");
        // data.walletType =
        //     this.state.walletType === "BC" ? "CC" : this.state.walletType;
        // data.lang = sessionStorage.getItem("lang");
        // let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        // data.hash = hash;
        //
        // this.props.getMemberWallet(data);

        this.onChange = this.onChange.bind(this);
        this.handleSubmitWithdrawal = this.handleSubmitWithdrawal.bind(this);
        this.handleSubmitWithdrawalConfirmation = this.handleSubmitWithdrawalConfirmation.bind(
            this
        );
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handlePlatformChange = this.handlePlatformChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `260px`);
    }

    handleTabChange(event, ew, dw) {
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.walletType = ew;
        data.lang = sessionStorage.getItem("lang");
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        document
            .getElementById("withdraw-minimum-note")
            .classList.remove("text-success");
        this.props.getMemberWallet(data);

        this.setState({
            walletType: ew,
            cryptoAddress: this.state.cryptoAddress,
            displayWalletType: dw,
            amount: "",
            loading_visible: true,
            address: "",
            s_password: "",
        });
    }

    handlePlatformChange(e) {
        if (e === "phil_exchange") {
            this.setState({
                platform_value: e,
                toAddressOnOff: "1",
            });
        } else if (e === "gcox_exchange") {
            this.setState({
                platform_value: e,
                toAddressOnOff: "0",
            });
        } else if (e === "blockchain") {
            this.setState({
                platform_value: e,
                toAddressOnOff: "1",
            });
        } else if (e === "vertex") {
            this.setState({
                platform_value: e,
                toAddressOnOff: "0",
            });
        } else {
            this.setState({
                platform_value: e,
            });
        }
    }

    handleTypeChange(e) {
        console.log(this.state.gcox_control);
        this.setState({
            type_selected: e,
            platform_value: "",
            b_vertex: this.state.vertex_control[e],
            gcox_flag: this.state.gcox_control[e],
            vertex_flag: this.state.vertex_control[e],
        });
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `260px`);
        });

        const data = {};

        data.token = this.state.token;
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        // if (this.props.withdrawalSetting) {
        //     let withdrawSetting;
        //     let type_opt = [];
        //     type_opt.push(this.state.type_options[0]);
        //     let vertex_control = [];
        //     let gcox_control = [];
        //     withdrawSetting = this.props.withdrawalSetting.data.data;
        //
        //     let firstBadge = withdrawSetting[0].walletTypeCode;
        //     let walletTypeCode = "";
        //     withdrawSetting
        //         .filter((item) => item.walletTypeCode === firstBadge)
        //         .map((filterItem) => {
        //             walletTypeCode = filterItem.walletTypeCode;
        //
        //             console.log(filterItem);
        //             if (
        //                 filterItem.type !== undefined &&
        //                 filterItem.type.length > 0
        //             ) {
        //                 filterItem.type.map(function (item, index) {
        //                     if (item.show === 1) {
        //                         type_opt.push({
        //                             value: item.wallet_type,
        //                             selected: false,
        //                             name: item.name,
        //                             b_vertex: item.b_vertex,
        //                         });
        //                     }
        //
        //                     vertex_control[item.wallet_type] = item.b_vertex;
        //                     gcox_control[item.wallet_type] = item.b_gcox;
        //                 });
        //             }
        //         });
        //
        //     this.setState({
        //         withdrawSetting: withdrawSetting,
        //         type_options: type_opt,
        //         vertex_control: vertex_control,
        //         gcox_control: gcox_control,
        //     });
        //     data.walletType = firstBadge;
        //     this.props.getMemberWallet(data);
        // } else {
            this.props.getWithdrawalSetting(data);
        // }
    }

    async handleSubmitWithdrawalConfirmation(event) {
        event.preventDefault();
        // if (
        //     this.state.specialWallets.includes(this.state.displayWalletType) &&
        //     this.state.platform_value === ""
        // ) {
        //     this.setState({
        //         modal_msg: t("withdrawal.platform_cannot_null"),
        //         modal_type: "error",
        //         modal_visible: true,
        //         loading_visible: false,
        //     });
        //     return false;
        // }

        let form = document.getElementById("withdrawal-form");
        let validate = fieldChecking(form);

        if (validate === false) {
            return false;
        }

        this.setState({
            confirm_visible: true,
        });
    }

    handleSubmitWithdrawal(event, pwd) {
        event.preventDefault("withdrawal-form");
        let element = document.getElementById("secondary_password");
        let isPwInValid = element.classList.contains("is-invalid");

        if (!isPwInValid & (pwd !== "")) {
            const data = [];

            data["walletType"] = this.state.walletType;
            data["wallet_type_code"] = this.state.walletType;

            if (
                this.state.type_options.length > 1 &&
                this.state.type_selected === ""
            ) {
                this.setState({
                    modal_msg: t("withdrawal.wallet_type_required"),
                    modal_type: "error",
                    modal_visible: true,
                    modal_gcox: false,
                    loading_visible: false,
                });
                return;
            }

            if (this.state.type_selected !== "") {
                data["walletType"] = this.state.type_selected;
                data["wallet_type_code"] = this.state.type_selected;
            }

            let hash = "";

            data["platform"] = this.state.platform_value;
            data["cryptoAddress"] = this.state.address;
            //  data["secondaryPassword"] = this.state.s_password;

            data["secondaryPassword"] = pwd;
            data["token"] = sessionStorage.getItem("token");
            data["username"] = sessionStorage.getItem("email");
            data["withdrawAmount"] = this.state.amount;
            data["withdrawTo"] = this.state.walletType;
            data["vertex_username"] = this.state.vertex_username;
            hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
            data["hash"] = hash;
            data["lang"] = sessionStorage.getItem("lang");

            this.setState({
                confirm_visible: false,
                loading_visible: true,
            });

            this.props.postWithdrawal(data);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data !== this.props.data) {
            if (nextProps.data.rst === "1") {
                let array;
                array = nextProps.data.data;

                this.setState({
                    data: array.walletBalance[0],
                    average_price:
                        nextProps.data.data.walletBalance[0].avg_rate,
                    avg_purchased_rate:
                        nextProps.data.data.walletBalance[0].avg_token_rate,
                    available_amount:
                        nextProps.data.data.walletBalance[0].walletTypeCode !==
                            "PAC" &&
                        nextProps.data.data.walletBalance[0].walletTypeCode !==
                            "LIGA"
                            ? nextProps.data.data.walletBalance[0]
                                  .available_amount
                            : nextProps.data.data.walletBalance[0]
                                  .available_withdraw_limit,
                    displayWalletType:
                        nextProps.data.data.walletBalance[0].walletTypeCode,
                    maxAmount: nextProps.data.data.walletBalance[0].amount,
                    platform_value: "",
                    type_selected: "",
                    b_vertex:
                        nextProps.data.data.walletBalance[0].walletTypeCode ===
                        "ACM"
                            ? 0
                            : 1,
                });

                // const data = {};
                // data.token = this.state.token;
                // let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                // data.hash = hash;
                // this.props.getWithdrawalSetting(data);
            } else {
                this.props.history.push({
                    pathname: "/home",
                    state: { error: "invalid_wallet_type_details" },
                });
            }
        }

        if (nextProps.withdrawalSetting !== this.props.withdrawalSetting) {
            if (nextProps.withdrawalSetting.data.rst === "1") {
                let withdrawSetting;
                withdrawSetting = nextProps.withdrawalSetting.data.data;

                let filterSetting = "";
                let count = 0;
                let withdrawMin = 0;
                let withdrawAdminFee = 0;
                let toAddressOnOff = "0";
                let gcox_flag = 0;
                let vertex_flag = 0;
                let walletTypeCode = "";
                let displayWalletType = "";
                let price_code = "";

                for (let i = 0; i < withdrawSetting.length; i++) {
                    if (withdrawSetting[i].isUnderMaintenance === "1") {
                        count += 1;
                        if (count === 2) {
                            this.props.history.push({
                                pathname: "/home",
                                state: { error: "invalid_wallet_type_details" },
                            });
                        }
                    }
                }

                let type_opt = [];
                let vertex_control = [];
                let gcox_control = [];
                let firstBadge =
                    this.state.walletType === null
                        ? withdrawSetting[0].walletTypeCode
                        : this.state.walletType;

                withdrawSetting
                    .filter((item) => item.walletTypeCode === firstBadge)
                    .map((filterItem) => {
                        if (
                            filterItem.type !== undefined &&
                            filterItem.type.length > 0
                        ) {
                            filterItem.type.map(function (item, index) {
                                if (type_opt.length <= 0) {
                                    type_opt.push({
                                        value: "",
                                        selected: false,
                                        name: t("withdrawal.please_select"),
                                    });
                                }

                                if (item.show === 1) {
                                    type_opt.push({
                                        value: item.wallet_type,
                                        selected: false,
                                        name: item.name,
                                        b_vertex: item.b_vertex,
                                    });
                                }

                                vertex_control[item.wallet_type] =
                                    item.b_vertex;
                                gcox_control[item.wallet_type] = item.b_gcox;
                            });
                        }

                        withdrawMin = filterItem.withdrawMin;
                        toAddressOnOff = filterItem.toAddressOnOff;
                        walletTypeCode = filterItem.walletTypeCode;
                        displayWalletType = filterItem.walletTypeCode;
                        price_code = filterItem.price_code;
                        withdrawAdminFee = filterItem.withdrawAdminFee;
                    });

                this.setState({
                    gcox_flag: gcox_flag,
                    vertex_flag: vertex_flag,
                    type_options: type_opt,
                    vertex_control: vertex_control,
                    gcox_control: gcox_control,
                    withdrawSetting: withdrawSetting,
                    minAmount: withdrawMin,
                    toAddressOnOff: toAddressOnOff,
                    displayWalletType: walletTypeCode,
                    walletType: walletTypeCode,
                    price_code: price_code,
                    withdrawAdminFee: withdrawAdminFee,
                    loading_visible: false,
                    loading: false,
                });

                const data = {};

                data.username = sessionStorage.getItem("email");
                data.token = sessionStorage.getItem("token");
                data.walletType =
                    this.state.walletType === "BC" ? "CC" : firstBadge;
                data.lang = sessionStorage.getItem("lang");
                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;

                this.props.getMemberWallet(data);
            }
        }

        if (nextProps.withdrawal_data !== this.props.withdrawal_data) {
            if (nextProps.withdrawal_data.data.rst !== "1") {
                if (
                    nextProps.withdrawal_data.data.nav !== undefined &&
                    nextProps.withdrawal_data.data.nav !== ""
                ) {
                    this.setState({
                        modal_msg: nextProps.withdrawal_data.data.msg,
                        nav: nextProps.withdrawal_data.data.nav,
                        modal_type: "error",
                        modal_gcox: true,
                        modal_visible: false,
                        loading_visible: false,
                    });
                } else {
                    this.setState({
                        modal_msg: nextProps.withdrawal_data.data.msg,
                        nav: nextProps.withdrawal_data.data.nav,
                        modal_type: "error",
                        modal_visible: true,
                        modal_gcox: false,
                        loading_visible: false,
                    });
                }
            } else {
                this.setState({
                    address: "",
                    amount: "",
                    s_password: "",
                    modal_msg: nextProps.withdrawal_data.data.msg,
                    modal_type: "success",
                    modal_visible: true,
                    loading_visible: false,
                });
            }
        }
    }

    onChange(e) {
        const { name, value } = e.target;

        if (this.state.walletType === "USDT") {
            if (
                (name === "amount" && amountChecking2(value)) ||
                name !== "amount"
            ) {
                this.setState({ [name]: value });
            }
        } else {
            if (
                (name === "amount" && amountChecking8(value)) ||
                name !== "amount"
            ) {
                this.setState({ [name]: value });
            }
        }
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false, modal_gcox: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    render() {
        const {
            withdrawAdminFee,
            platform_value,
            specialWallets,
            withdrawSetting,
            loading,
            data,
            balance,
            nav,
            displayWalletType,
            toAddressOnOff,
            gcoxReturnedMsg,
            modal_gcox,
            confirm_visible,
            loading_visible,
            modal_visible,
            modal_type,
            modal_msg,
            type_options,
            type_selected,
            vertex_flag,
            gcox_flag,
        } = this.state;

        // const withdraw_opts = [
        //     {
        //         value: "",
        //         selected: false,
        //         name: t("withdrawal.please_select"),
        //         show: true,
        //     },
        //     {
        //         value: "blockchain",
        //         selected: false,
        //         name: t("withdrawal.blockchain"),
        //         // show: b_vertex === 0,
        //         show: false,
        //     },
        //     {
        //         value: "vertex",
        //         selected: false,
        //         name: t("withdrawal.vertex"),
        //         show: vertex_flag,
        //     },
        //     {
        //         value: "gcox_exchange",
        //         selected: false,
        //         name: t("withdrawal.gcox_exchange"),
        //         show: gcox_flag,
        //     },
        // ];

        return (
            <div id="withdrawal-container">
                <PageHeader
                    titleHeader="col-12 text-center"
                    backgroundHeight={230}
                    prevousPageLink=""
                    navClass=""
                    historyBack="/home"
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={data.displayCode ? data.displayCode : ""}
                    title={data.displayCode ? data.displayCode : ""}
                    balance={data.total_amount ? data.total_amount : balance}
                    lockedAmount={data.locked_amount}
                    availableAmount={data.available_amount}
                    arrowLeft={true}
                    headerBanner={true}
                />

                <HyperModal
                    category="s_password"
                    modalVisible={confirm_visible}
                    submitFunc={this.handleSubmitWithdrawal}
                    cancelFunc={this.handleModalClose}
                    closable={true}
                />
                {/* <HyperModal
                    category="normal"
                    modalVisible={confirm_visible}
                    onOk={this.handleSubmitWithdrawal}
                    footer={
                        <div className="col-12 text-center">
                            <button
                                key="submit"
                                className="col-3 btn btn-primary"
                                onClick={this.handleSubmitWithdrawal}
                            >
                                {t("common.submit")}
                            </button>
                            <button
                                key="submit"
                                className="col-3 btn btn-danger"
                                onClick={(e) =>
                                    this.handleModalClose(e, "confirm")
                                }
                            >
                                {t("purchasePin.modal_close")}
                            </button>
                        </div>
                    }
                    modalContent={
                        <div className="row">
                            <div className="col-12 text-center">
                                <ConfirmationIcon
                                    width="100px"
                                    height="0px !important"
                                />
                              
                                <p>{t("transfer.confirmation_msg")}</p>
                            </div>
                        </div>
                    }
                    onCancel={this.handleModalClose}
                    closable={false}
                /> */}
                <HyperModal
                    category="response"
                    modalVisible={loading_visible}
                    modalType="loading"
                    footer={[]}
                />
                <HyperModal
                    category="response"
                    modalVisible={modal_visible}
                    modalType={modal_type}
                    modalMsg={modal_msg}
                    modalCloseFunc={this.handleModalClose}
                    cancelFunc={this.handleModalClose}
                />
                <HyperModal
                    category="normal"
                    modalVisible={modal_gcox}
                    modalType={modal_type}
                    modalMsg={modal_msg}
                    modalCloseFunc={this.handleModalClose}
                    cancelFunc={this.handleModalClose}
                    modalContent={
                        <div className="row">
                            <div className="col-12 text-center">
                                <ConfirmationIcon
                                    width="100px"
                                    height="0px !important"
                                />

                                <h4>
                                    {gcoxReturnedMsg + " "}{" "}
                                    <a
                                        href={nav}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t("profile.here") + " "}
                                    </a>
                                    {t("profile.navigate")}
                                </h4>
                            </div>
                        </div>
                    }
                />
                <div
                    id="withdrawal"
                    className="col-12 px-0 custom-height"
                    // style={{ height: "calc(100vh - 230px)" }}
                >
                    {withdrawSetting.length > 0 && (
                        <WalletBadge
                            walletArrProps={withdrawSetting}
                            badgeActive={displayWalletType}
                            badgeOnClick={this.handleTabChange}
                        />
                    )}
                    {!loading && (
                        <div className="offset-md-2 col-md-8">
                            <form
                                id="withdrawal-form"
                                className="txt-black"
                                noValidate
                                onSubmit={
                                    this.handleSubmitWithdrawalConfirmation
                                }
                            >
                                <>
                                    {type_options.length > 1 && (
                                        <div className="form-group">
                                            <label className="depth-gray-label text-uppercase">
                                                {t("withdrawal.wallet_type")}
                                            </label>
                                            <div className="col-12 mb-2">
                                                <div className="row">
                                                    <DropDownBox
                                                        id="type_options"
                                                        size="large"
                                                        name="type_options"
                                                        options={
                                                            this.state
                                                                .type_options
                                                        }
                                                        value={
                                                            this.state
                                                                .type_selected
                                                        }
                                                        onChange={(e) =>
                                                            this.handleTypeChange(
                                                                e
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/*{specialWallets.includes(*/}
                                    {/*    displayWalletType*/}
                                    {/*) && (*/}
                                    {/*    <div className="form-group">*/}
                                    {/*        <label className="depth-gray-label text-uppercase">*/}
                                    {/*            {t(*/}
                                    {/*                "withdrawal.withdraw_platform"*/}
                                    {/*            )}*/}
                                    {/*        </label>*/}
                                    {/*        <div className="col-12 mb-2">*/}
                                    {/*            <div className="row">*/}
                                    {/*                <DropDownBox*/}
                                    {/*                    id="withdraw_platform"*/}
                                    {/*                    size="large"*/}
                                    {/*                    // className="depth-input"*/}
                                    {/*                    name="purchase_method"*/}
                                    {/*                    type="special"*/}
                                    {/*                    options={withdraw_opts}*/}
                                    {/*                    value={*/}
                                    {/*                        this.state*/}
                                    {/*                            .platform_value*/}
                                    {/*                    }*/}
                                    {/*                    onChange={(e) =>*/}
                                    {/*                        this.handlePlatformChange(*/}
                                    {/*                            e*/}
                                    {/*                        )*/}
                                    {/*                    }*/}
                                    {/*                />*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}

                                    {/*{platform_value === "vertex" && (*/}
                                    {/*    <div className="form-group">*/}
                                    {/*        <label className="depth-gray-label text-uppercase">*/}
                                    {/*            {t(*/}
                                    {/*                "withdrawal.vertex_username"*/}
                                    {/*            )}*/}
                                    {/*        </label>*/}
                                    {/*        <StringInput*/}
                                    {/*            id="vertex_username"*/}
                                    {/*            name="vertex_username"*/}
                                    {/*            className="remove-placeholder depth-input"*/}
                                    {/*            onChange={this.onChange}*/}
                                    {/*            value={*/}
                                    {/*                this.state.vertex_username*/}
                                    {/*            }*/}
                                    {/*            placeholder={t(*/}
                                    {/*                "withdrawal.enter_vertex_username"*/}
                                    {/*            )}*/}
                                    {/*            requiredMsg={t(*/}
                                    {/*                "validator.requiredMsg"*/}
                                    {/*            )}*/}
                                    {/*            required*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*)}*/}

                                    {toAddressOnOff === "1" && (
                                        <>
                                            <div className="form-group">
                                                <label className="depth-gray-label text-uppercase">
                                                    {t("withdrawal.to_address")}
                                                </label>
                                                &nbsp;
                                                {displayWalletType === "USDT" ||
                                                displayWalletType === "CC" ? (
                                                    <label className="depth-gray-label text-uppercase">
                                                        (
                                                        {t(
                                                            "withdrawal.address_remark",
                                                            {
                                                                walletType:
                                                                    "ERC20 USDT",
                                                            }
                                                        )}
                                                        )
                                                    </label>
                                                ) : (
                                                    <label className="depth-gray-label text-uppercase">
                                                        (
                                                        {t(
                                                            "withdrawal.address_remark",
                                                            {
                                                                walletType: displayWalletType,
                                                            }
                                                        )}
                                                        )
                                                    </label>
                                                )}
                                                <StringInput
                                                    id="address"
                                                    name="address"
                                                    className="remove-placeholder depth-input"
                                                    onChange={this.onChange}
                                                    value={this.state.address}
                                                    placeholder={t(
                                                        "withdrawal.enter_address"
                                                    )}
                                                    requiredMsg={t(
                                                        "validator.requiredMsg"
                                                    )}
                                                    required
                                                />
                                            </div>
                                        </>
                                    )}
                                </>

                                <div className="form-group">
                                    <label className="depth-gray-label text-uppercase">
                                        {t("withdrawal.amount")}
                                    </label>
                                    <NumberInput
                                        type="number"
                                        id="amount"
                                        name="amount"
                                        onChange={this.onChange}
                                        value={this.state.amount}
                                        min={delimiter(this.state.minAmount)}
                                        placeholder="0.00000000"
                                        className="remove-placeholder depth-input"
                                        requiredMsg={t("validator.requiredMsg")}
                                        textLessThan={t(
                                            "validator.amount_should_less_than"
                                        )}
                                        textMoreThan={t(
                                            "validator.amount_should_more_than"
                                        )}
                                        required
                                    />

                                    <p
                                        id="withdraw-minimum-note"
                                        className="text-capitalize"
                                    >
                                        {t("withdrawal.minimum")}:{" "}
                                        {this.state.minAmount}{" "}
                                        {t("withdrawal.limit")}:{" "}
                                        {this.state.available_amount}
                                    </p>
                                </div>

                                {/* <div className="form-group">
                                <label className="depth-gray-label text-uppercase">
                                    {t("withdrawal.secondary_password")}
                                </label>
                                <PasswordInput
                                    id="s_password"
                                    name="s_password"
                                    onChange={this.onChange}
                                    value={this.state.s_password}
                                    placeholder={t(
                                        "withdrawal.secondary_password"
                                    )}
                                    className="remove-placeholder depth-input"
                                    requiredMsg={t("validator.requiredMsg")}
                                    required
                                />
                            </div> */}

                                {slice_decimal_wt_rounding(
                                    parseFloatV2(withdrawAdminFee)
                                ) > 0 && (
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <p className="subscribe-note">
                                                {t("withdrawal.admin_fee", {
                                                    amount: slice_decimal_wt_rounding(
                                                        parseFloatV2(
                                                            withdrawAdminFee
                                                        )
                                                    ),
                                                    walletType:
                                                        this.state
                                                            .displayWalletType ===
                                                        ""
                                                            ? this.props
                                                                  .location
                                                                  .state ===
                                                              undefined
                                                                ? this.state
                                                                      .withdrawFirstBadge
                                                                : this.props
                                                                      .location
                                                                      .state
                                                                      .walletType
                                                            : this.state
                                                                  .displayWalletType,
                                                })}
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    className="col-12 depth-blue-btn"
                                >
                                    {t("withdrawal.submit")}
                                </button>
                            </form>
                        </div>
                    )}
                </div>
                {loading && (
                    <div className="container pt-5">
                        <div className="row">
                            <div className="mx-auto">
                                <LoadingMask width={80} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, withdrawal, purchaseRate } = state;

    return {
        data: walletBalance.memberWallet.data,
        withdrawal_data: withdrawal.data,
        purchase_rate: purchaseRate.data,
        withdrawalSetting: walletBalance.withdrawalData,
    };
};

const mapDispatchToProps = {
    getMemberWallet,
    postWithdrawal,
    postPurchaseRate,
    getWithdrawalSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdrawal);
