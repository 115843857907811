import React, { Component } from "react";
import { setLocale, t } from "react-i18nify";
import connect from "react-redux/es/connect/connect";

import "./packagePanel.scss";

import { Input, Tabs } from "antd";
import PageHeader from "../../components/PageHeaderV2";
import TabFooter from "../../components/TabFooter";
import CelebProgram from "../celebreneurProgram/cv/index";
import TvProgram from "../celebreneurProgram/tv/index";
import {
    GetMemberPackages,
    GetMemberCvPackages,
} from "../../store/ducks/purchasePin/actions";
import HyperModal from "../../components/HyperModal";

class PackagePanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoadingModal: true,
            showLoadingModal2: true,
            tv_show: true,
            cv_show: true,
            loading_visible: false,
        };

        this.handleNav = this.handleNav.bind(this);
        this.callback = this.callback.bind(this);

        const data = {};

        data.email = sessionStorage.getItem("email");
        data.lang = sessionStorage.getItem("lang");
        this.props.GetMemberPackages(data);

        this.props.GetMemberCvPackages(data);
    }

    UNSAFE_componentWillMount() {
        setLocale(sessionStorage.getItem("lang"));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.packages_detail !== this.props.packages_detail) {
            if (nextProps.packages_detail.rst === "1") {
                this.setState({
                    showLoadingModal: false,
                    member_package: nextProps.packages_detail.data.packages,
                    payment_arry:
                        nextProps.packages_detail.data.payment_methods,
                });
            }
        }

        if (nextProps.packages_cv_detail !== this.props.packages_cv_detail) {
            if (nextProps.packages_cv_detail.rst === "1") {
                this.setState({
                    showLoadingModal2: false,
                    member_package: nextProps.packages_cv_detail.data.packages,
                    payment_arry:
                        nextProps.packages_cv_detail.data.payment_methods,
                });
            }
        }

        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }
    }

    callback(e) {
        const data = {};

        data.email = sessionStorage.getItem("email");
        data.lang = sessionStorage.getItem("lang");

        if (e === "1") {
            this.setState({
                showLoadingModal: false,
                showLoadingModal2: true,
                tv_show: true,
                cv_show: false,
            });

            this.props.GetMemberPackages(data);
        } else {
            this.setState({
                showLoadingModal: true,
                showLoadingModal2: false,
                tv_show: false,
                cv_show: true,
            });

            this.props.GetMemberCvPackages(data);
        }
    }

    handleNav(nav_link) {
        this.props.history.push(nav_link);
    }

    render() {
        const {
            tv_show,
            cv_show,
            showLoadingModal,
            showLoadingModal2,
            loading_visible,
        } = this.state;

        const { TabPane } = Tabs;

        return (
            <div className="package-panel">
                <HyperModal
                    category="response"
                    modalVisible={loading_visible}
                    modalType="loading"
                    footer={[]}
                />
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack="/home"
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("purchasePin.purchase_pin")}
                    arrowLeft={false}
                />
                {/* <Tabs defaultActiveKey="1" onChange={this.callback}>
                    <TabPane tab={t("purchasePin.token_voucher")} key="1">
                        {tv_show&&
                        <TvProgram
                            nav={this.handleNav}
                            showLoadingModal={showLoadingModal}
                        />
                        }
                    </TabPane> */}
                {/* <TabPane tab={t("purchasePin.celebreneur_voucher")} key="2"> */}
                {cv_show && (
                    <CelebProgram
                        nav={this.handleNav}
                        showLoadingModal={showLoadingModal2}
                    />
                )}
                {/* </TabPane>
                </Tabs> */}
                <TabFooter pinActive="active" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { getMemberPackage, walletBalance } = state;

    return {
        packages_cv_detail: getMemberPackage.GET_MEMBER_CV_PACKAGES,
        packages_detail: getMemberPackage.GET_MEMBER_PACKAGES,
        isLoading: walletBalance.isLoading,
    };
};

const mapDispatchToProps = {
    GetMemberCvPackages,
    GetMemberPackages,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackagePanel);
