import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale, t } from "react-i18nify";
import { Icon } from "antd";

import {
    getDeliveryDetails,
    deleteShipment,
} from "../../store/ducks/emall/actions";
import { closeModal, openModal } from "../../store/ducks/modal/actions";

import { GenHash } from "../../common/helpers";

import "./deliveryDetails.scss";
import PageHeader from "../../components/PageHeader";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import LoadingMask from "../../components/loadingMask";
import IconAdd from "../../public/images/icon-add@3x.png";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";

class DeliveryDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialAmount: 1,
            amount: 1,
            checkOutShow: false,
            showLoadingModal: false,
            showConfirmationModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            delivery_data: [],
            screenHeight: window.innerHeight - (96 + 80),
            headerHeight: window.innerWidth,
            disabled: true,
            checked_data: "",
            delete_data: "",
            errMsg: "",
            successMsg: "",
        };

        this.crousel = React.createRef();

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.handleCheckOutOnOff = this.handleCheckOutOnOff.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
        this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
        this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(
            this
        );
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteClose = this.handleDeleteClose.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.onTest = this.onTest.bind(this);
    }

    componentDidMount() {
        setLocale(sessionStorage.getItem("lang"));

        let data = {};

        data.user_id = sessionStorage.getItem("email");

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({
            showLoadingModal: true,
        });

        this.props.openModal("delivery-details-modal");

        this.props.getDeliveryDetails(data);

        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data !== this.props.data) {
            let delivery_data = nextProps.data.data;
            let btn_disabled = true;
            let checked_data = "";
            delivery_data.map((option, i) => {
                if (option.default_address === 1) {
                    btn_disabled = false;
                    checked_data = option.shipment_id;
                }
                return i;
            });
            if (nextProps.data.rst === "1") {
                this.setState({
                    delivery_data: nextProps.data.data,
                    showLoadingModal: false,
                    disabled: btn_disabled,
                    checked_data: checked_data,
                });

                this.props.closeModal("delivery-details-modal");
            }
        }

        if (
            nextProps.delete_delivery_address_data !==
            this.props.delete_delivery_address_data
        ) {
            if (nextProps.delete_delivery_address_data.rst !== "1") {
                this.setState({
                    showLoadingModal: false,
                    showErrorModal: true,
                    errMsg: nextProps.delete_delivery_address_data.msg,
                });

                this.props.openModal("error-modal");
            } else {
                this.setState({
                    showLoadingModal: false,
                    showSuccessModal: true,
                    successMsg: nextProps.delete_delivery_address_data.msg,
                });

                this.props.openModal("success-modal");
            }
        }

        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName ===
                "delivery-details-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName ===
                "shipment-delete-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }
    }

    updateHeight() {
        if (window.innerWidth <= 768) {
            this.setState({ headerHeight: window.innerWidth });
        } else {
            this.setState({ headerHeight: 768 });
        }
    }

    onTest(e) {
        this.crousel.goTo(1);
    }

    onChangeAmount(e) {
        let amount = this.state.amount;

        if (e.target.id === "plus") {
            this.setState({ amount: amount + 1 });
        } else if (e.target.id === "minus" && amount !== 0) {
            this.setState({ amount: amount - 1 });
        }
    }

    handleCheckOutOnOff(e, show) {
        this.setState({ checkOutShow: show === true ? false : true });
    }

    handleCheckBox(e) {
        var myCheckbox = document.getElementsByName("shipment_address");

        Array.prototype.forEach.call(myCheckbox, function (el) {
            el.checked = false;
        });
        e.target.checked = true;

        this.setState({
            checked_data: e.target.value,
            disabled: false,
        });
    }

    handlePlaceOrder() {
        sessionStorage.setItem("checkoutAddress", this.state.checked_data);
    }

    async handleDeleteConfirmation(shipment_id) {
        this.setState({
            delete_data: shipment_id,
        });

        this.props.openModal("shipment-delete-confirmation-modal");
    }

    handleDelete(event) {
        event.preventDefault();

        const data = [];
        let hash = "";

        data["user_id"] = sessionStorage.getItem("email");
        data["shipment_id"] = this.state.delete_data;
        data["lang"] = sessionStorage.getItem("lang");

        hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data["hash"] = hash;

        this.setState({
            showConfirmationModal: false,
        });

        this.props.openModal("delivery-details-loading-modal");

        this.props.deleteShipment(data);
    }

    handleDeleteClose(event) {
        event.preventDefault();

        this.props.closeModal("shipment-delete-confirmation-modal");
    }

    handleSuccessClose() {
        let data = {};

        data.user_id = sessionStorage.getItem("email");

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({
            showLoadingModal: true,
        });

        this.props.openModal("delivery-details-modal");

        this.props.getDeliveryDetails(data);

        this.props.closeModal("success-modal");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");
    }

    render() {
        const {
            /*checkOutShow,*/ screenHeight,
            delivery_data,
            showLoadingModal,
            showConfirmationModal,
            showErrorModal,
            showSuccessModal,
            successMsg,
            errMsg,
            disabled,
        } = this.state;

        // const TabPane = Tabs.TabPane;
        //
        // const buttonPlus = <Icon type="plus" id="plus" onClick={this.onChangeAmount}/>;
        //
        // const buttonMinus = <Icon type="minus" id="minus" onClick={this.onChangeAmount}/>;

        const allItemRows = delivery_data.map((option, i) => {
            return (
                <li
                    className="list-group-item asset-panel-list-group-item shadow"
                    key={i}
                >
                    <div className="fa-pull-left px-0">
                        <input
                            type="checkbox"
                            name="shipment_address"
                            defaultChecked={
                                option.default_address === 1 ? true : false
                            }
                            value={option.shipment_id}
                            onClick={this.handleCheckBox}
                        />
                    </div>
                    <div className="col-11 container-fluid pr-0 mx-3">
                        <div className="col-6 fa-pull-left px-0">
                            <label>
                                <b>{t("addDelivery.shipping_details")}</b>
                            </label>
                        </div>
                        <div className="col-6 fa-pull-right px-0">
                            <label
                                onClick={() =>
                                    this.handleDeleteConfirmation(
                                        option.shipment_id
                                    )
                                }
                                className="fa-pull-right ml-1 text-danger"
                                value={option.shipment_id}
                            >
                                {t("addDelivery.delete")}
                            </label>
                            <Link
                                to={{
                                    pathname: `/emall/delivery/edit`,
                                    state: {
                                        shipment_id: option.shipment_id,
                                        country_id: option.country_id,
                                    },
                                }}
                            >
                                <label className="fa-pull-right">
                                    {t("addDelivery.edit")} |{" "}
                                </label>
                            </Link>
                        </div>

                        {/*Address*/}
                        <div className="row col-11 px-0">
                            {/*Delivery Adrress*/}
                            <div className="col-12">
                                <small>
                                    <b>{t("addDelivery.delivery_address")}</b>
                                </small>
                            </div>
                            <div className="col-2">
                                <Icon type="environment" />
                            </div>

                            <div className="col-10 mx-0 px-0 container-fluid">
                                <div className="col-12 px-0 mx-0">
                                    <small>{option.shipment_name}</small>
                                </div>
                                <div className="col-12 px-0 mx-0">
                                    <small>
                                        {option.address_1}, {option.address_2},{" "}
                                        {option.address_3} {option.country_name}
                                    </small>
                                </div>
                            </div>
                        </div>
                        {/*Contact*/}
                        <div className="row col-11 px-0">
                            <div className="col-2">
                                <Icon type="phone" />
                            </div>

                            <div className="col-10 mx-0 px-0 container-fluid">
                                <div className="col-12 px-0 mx-0">
                                    <small>
                                        {option.mobile_prefix}
                                        {option.mobile_no}
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/*Address*/}
                        {/*<div className="row col-11 px-0">*/}
                        {/*/!*Billing Adrress*!/*/}
                        {/*<div className="col-12">*/}
                        {/*<small><b>Billing Address</b></small>*/}
                        {/*</div>*/}
                        {/*<div className="col-2">*/}
                        {/*<Icon type="home" />*/}
                        {/*</div>*/}

                        {/*<div className="col-10 mx-0 px-0 container-fluid">*/}
                        {/*<div className="col-12 px-0 mx-0">*/}
                        {/*<small>No.8F-2, Tower 2 @ PFCC, Jalan Puteri 1/2, Bandar Puteri Puchong, 47100 Puchong, Selangor.</small>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        {/*/!*Contact*!/*/}
                        {/*<div className="row col-11 px-0">*/}
                        {/*<div className="col-2">*/}
                        {/*<Icon type="mail" />*/}
                        {/*</div>*/}

                        {/*<div className="col-10 mx-0 px-0 container-fluid">*/}
                        {/*<div className="col-12 px-0 mx-0">*/}
                        {/*<small>John@gmail.com</small>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </li>
            );
        });

        const addItemRows = (
            <li className="list-group-item asset-panel-list-group-item shadow mt-1">
                <div className="col-11 container-fluid pr-0 mx-3">
                    <div className="col-9 fa-pull-left px-0">
                        <Link to={{ pathname: `/emall/delivery/add` }}>
                            <strong>
                                <label className="add_new_address">
                                    {t("addDelivery.add_new_delivery_address")}
                                </label>
                            </strong>
                        </Link>
                    </div>
                    <div className="col-2 fa-pull-right px-0">
                        <Link to={{ pathname: `/emall/delivery/add` }}>
                            <img
                                src={IconAdd}
                                className="add_icon fa-pull-right"
                                alt={1}
                            />
                        </Link>
                    </div>
                </div>
                {/*<div className="col-12 container-fluid pr-0 mx-3">*/}
                {/*<div className="col-12 px-0">*/}
                {/*<Link to={{pathname: `/emall/delivery/add`}}><label className="fa-pull-right">{t('addDelivery.add_new_delivery_address')}</label></Link>*/}
                {/*</div>*/}
                {/*</div>*/}
            </li>
        );

        const orderList = <aside className="list-group">{allItemRows}</aside>;
        const newDeliveryAddressList = (
            <aside className="list-group">{addItemRows}</aside>
        );

        return (
            <div className="myCart home">
                <div className="container-fluid px-0">
                    <PageHeader
                        subTitle={t("emall.delivery_address")}
                        navClass="title white-color"
                        backgroundHeight={80}
                        subPageHistoryBack={"/emall/shopping-cart"}
                        walletType={""}
                        arrowLeft={true}
                    />
                </div>

                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("transfer.confirmation_msg")}</h4>
                                </div>
                            </div>
                        }
                        closeText={t("purchasePin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={this.handleDeleteClose}
                        closeId=""
                        submitText={t("common.submit")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleDelete}
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="shipment-delete-confirmation-modal"
                    />
                )}

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="delivery-details-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div className="overflow-auto" style={{ height: screenHeight }}>
                    {orderList}
                    {newDeliveryAddressList}
                </div>

                <div className="card fixed-bottom">
                    <div className="py-3 text-center">
                        <Link to="/emall/confirm-purchase">
                            <button
                                className="col-10 btn btn-success text-uppercase"
                                disabled={disabled}
                                onClick={this.handlePlaceOrder}
                            >
                                {t("emall.place_order")}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { emall, modal } = state;

    return {
        data: emall.get_delivery_address,
        delete_delivery_address_data: emall.delete_delivery_address,
        classIsShowing: modal.classIsShowing,
    };
};

const mapDispatchToProps = {
    getDeliveryDetails,
    deleteShipment,
    openModal,
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetails);
