import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale, t } from "react-i18nify";
import { Switch } from "antd";

import { fieldChecking, GenHash } from "../../common/helpers";

import "./editDeliveryDetails.scss";
import PageHeader from "../../components/PageHeader";
import StringInput from "../../components/input/StringInput";
import Select from "../../components/input/Select/index";
import NumberInput from "../../components/input/NumberInput";

import {
    getEditDeliveryDetails,
    getCountry,
    updateShipment,
} from "../../store/ducks/emall/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";
import { formValidation } from "../../common/validator/formValidation";
import Modal from "../../components/modal";
import LoadingMask from "../../components/loadingMask";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";

class EditDeliveryDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            country_prefix: "",
            country: "Afghanistan",
            country_id: "",
            state: "",
            mobile_no: "",
            mobile_prefix: [],
            country_options: [],
            add_one: "",
            add_two: "",
            add_three: "",
            loading: false,
            showLoadingModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            errMsg: "",
            successMsg: "",
            country_new: [],
            state_new: [],
            state_value: [],
            country_value: [],
            checked_default_address: 0,
            stateShow: false,
        };

        this.onChange = this.onChange.bind(this);
        this.handleCountryPrefix = this.handleCountryPrefix.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.handleState = this.handleState.bind(this);
        this.handleMobileNo = this.handleMobileNo.bind(this);
        this.handleAddDeliveryAddress = this.handleAddDeliveryAddress.bind(
            this
        );
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleDefaultAddress = this.handleDefaultAddress.bind(this);
    }

    componentDidMount() {
        formValidation("add-delivery-form");

        setLocale(sessionStorage.getItem("lang"));

        let data = {};
        let dataDeliveryDetails = {};

        data.langCode = sessionStorage.getItem("lang");
        data.parentId = this.props.location.state.country_id;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.getCountry(data);

        dataDeliveryDetails.user_id = sessionStorage.getItem("email");
        dataDeliveryDetails.shipment_id = this.props.location.state.shipment_id;
        let hashDeliveryDetail = GenHash(
            dataDeliveryDetails,
            process.env.REACT_APP_GM_SECRET_KEY
        );
        dataDeliveryDetails.hash = hashDeliveryDetail;

        this.props.getEditDeliveryDetails(dataDeliveryDetails);

        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName ===
                "add-delivery-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.rst === "1") {
                this.setState({
                    country_new: nextProps.country_data.data[0].country,
                    state_new: nextProps.country_data.data[0].state,
                    stateShow:
                        this.state.country !== "" &&
                        nextProps.country_data.data[0].state !== false
                            ? true
                            : false,
                });
            }
        }

        if (
            nextProps.get_edit_delivery_address !==
            this.props.get_edit_delivery_address
        ) {
            if (nextProps.get_edit_delivery_address.rst === "1") {
                this.setState({
                    add_one: nextProps.get_edit_delivery_address.data.address_1,
                    add_two: nextProps.get_edit_delivery_address.data.address_2,
                    add_three:
                        nextProps.get_edit_delivery_address.data.address_3,
                    name:
                        nextProps.get_edit_delivery_address.data.shipment_name,
                    checked_default_address:
                        nextProps.get_edit_delivery_address.data
                            .default_address,
                    country:
                        nextProps.get_edit_delivery_address.data.country_name,
                    country_id:
                        nextProps.get_edit_delivery_address.data.country_id,
                    mobile_no:
                        nextProps.get_edit_delivery_address.data.mobile_no,
                    country_prefix:
                        nextProps.get_edit_delivery_address.data.mobile_prefix,
                    state: parseInt(
                        nextProps.get_edit_delivery_address.data.state
                    ),
                });
            }
        }

        if (nextProps.data !== this.props.data) {
            if (nextProps.data.rst === "1") {
                this.setState({
                    showLoadingModal: false,
                    successMsg: nextProps.data.msg,
                });

                this.props.openModal("success-modal");
            } else {
                this.setState({
                    showLoadingModal: false,
                    errMsg: nextProps.data.msg,
                });

                this.props.openModal("error-modal");
            }
        }
    }

    updateHeight() {
        if (window.innerWidth <= 768) {
            this.setState({ headerHeight: window.innerWidth });
        } else {
            this.setState({ headerHeight: 768 });
        }
    }

    onChange(e) {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    handleCountryPrefix(event) {
        this.setState({ country_prefix: event.target.value, state: "" });
    }

    handleCountry(event) {
        let data = {};

        data.langCode = sessionStorage.getItem("lang");
        data.parentId = event.target.value;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({ country: event.target.value });

        this.props.getCountry(data);
    }

    handleState(event) {
        this.setState({ state: event.target.value });
    }

    handleMobileNo(event) {
        this.setState({ mobile_no: event.target.value });
    }

    handleAddDeliveryAddress(e) {
        e.preventDefault();

        let validate = fieldChecking(
            document.getElementById("add-delivery-form")
        );

        if (validate === false) {
            return false;
        }

        this.props.openModal("add-delivery-loading-modal");

        const data = {};
        let hash = "";

        data["user_id"] = sessionStorage.getItem("email");
        data["shipment_id"] = this.props.location.state.shipment_id;
        data["type"] = "SHIPPING";
        data["name"] = this.state.name;
        data["mobile_prefix"] = this.state.country_prefix;
        data["mobile_no"] = this.state.mobile_no;
        data["address_one"] = this.state.add_one;
        data["address_two"] = this.state.add_two;
        data["address_three"] = this.state.add_three;
        data["default_address"] = this.state.checked_default_address;
        data["country"] = this.state.country_id;
        data["state"] = this.state.state ? this.state.state : "";
        data["lang"] = sessionStorage.getItem("lang");
        hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data["hash"] = hash;

        this.props.updateShipment(data);
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        // this.props.history.push('/login');
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");
    }

    handleDefaultAddress(checked) {
        if (checked === true) {
            this.setState({
                checked_default_address: 1,
            });
        } else {
            this.setState({
                checked_default_address: 0,
            });
        }
    }

    render() {
        const {
            showLoadingModal,
            showErrorModal,
            errMsg,
            successMsg,
            showSuccessModal,
            country_new,
            country_prefix,
            stateShow,
            state_new,
            checked_default_address,
        } = this.state;

        let stateArry = [
            {
                country_id: "",
                country_name: t("addDelivery.please_enter_state"),
            },
        ];
        let countryArry = [
            {
                country_id: "",
                country_name: t("addDelivery.please_enter_country"),
            },
        ];
        let countryWithNoArry = [
            {
                country_id: "",
                country_name: t("addDelivery.please_enter"),
                country_mobile_no_prefix: "",
            },
        ];

        country_new.map((option, i) => {
            if (this.state.country === option.name) {
                countryArry.push({
                    country_id: option.id,
                    country_name: option.name,
                    selected: "selected",
                });
                countryWithNoArry.push({
                    country_id: option.calling_no_prefix,
                    country_name: option.name,
                    country_mobile_no_prefix: option.calling_no_prefix,
                    selected: "selected",
                });
            } else {
                countryArry.push({
                    country_id: option.id,
                    country_name: option.name,
                });
                countryWithNoArry.push({
                    country_id: option.calling_no_prefix,
                    country_name: option.name,
                    country_mobile_no_prefix: option.calling_no_prefix,
                });
            }
            return i;
        });

        if (state_new !== false) {
            state_new.map((option, i) => {
                if (this.state.state === option.id) {
                    stateArry.push({
                        country_id: option.id,
                        country_name: option.name,
                        selected: "selected",
                    });
                } else {
                    stateArry.push({
                        country_id: option.id,
                        country_name: option.name,
                    });
                }
                return i;
            });
        }

        return (
            <div className="deliveryDetail">
                <div className="container-fluid px-0">
                    <PageHeader
                        subTitle={t("addDelivery.edit_delivery_address")}
                        navClass="title white-color"
                        backgroundHeight={80}
                        subPageHistoryBack={"/emall"}
                        walletType={""}
                        arrowLeft={true}
                    />
                </div>

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="add-delivery-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div className="p-4 form-bg-transparent">
                    <form
                        id="add-delivery-form"
                        onSubmit={this.handleAddDeliveryAddress}
                        className="form-bg-transparent needs-validation"
                        noValidate
                    >
                        <div className="form-group register-panel-field">
                            <div className="row">
                                <label className="form-label text-uppercase">
                                    {t("addDelivery.name")}
                                </label>
                                <StringInput
                                    id="name"
                                    name="name"
                                    className="remove-placeholder"
                                    onChange={this.onChange}
                                    value={this.state.name}
                                    placeholder={t(
                                        "addDelivery.enter_shipment_name"
                                    )}
                                    requiredMsg={t("validator.requiredMsg")}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group register-panel-field">
                            <div className="row">
                                <label className="form-label text-uppercase">
                                    {t("addDelivery.mobile_no")}
                                </label>
                            </div>

                            <div className="input-group register-panel-field row">
                                <Select
                                    id="country_prefix"
                                    className="form-control register-mobile-country-prefix col-5"
                                    name="country_prefix"
                                    options={countryWithNoArry}
                                    onChange={this.handleCountryPrefix}
                                    value={country_prefix}
                                    required={true}
                                />
                                <NumberInput
                                    type="number"
                                    placeholder={t(
                                        "addDelivery.please_enter_phone_number"
                                    )}
                                    id="mobile_no"
                                    name="mobile_no"
                                    onChange={this.handleMobileNo}
                                    value={this.state.mobile_no}
                                    className="form-control mobile-no row"
                                    required
                                    requiredMsg={t("addDelivery.requiredMsg")}
                                    minLength="5"
                                    minLengthMsg={t(
                                        "register.checkMobileNoMsg"
                                    )}
                                />
                            </div>
                        </div>

                        <div className="form-group register-panel-field">
                            <div className="row">
                                <label className="form-label text-uppercase">
                                    {t("addDelivery.country")}
                                </label>
                                <Select
                                    id="country"
                                    className="form-control"
                                    name="country"
                                    options={countryArry}
                                    onChange={this.handleCountry}
                                    required={true}
                                />
                            </div>
                        </div>

                        {stateShow && (
                            <div className="form-group register-panel-field">
                                <div className="row">
                                    <label className="form-label text-uppercase">
                                        {t("addDelivery.state")}
                                    </label>
                                    <Select
                                        className="form-control"
                                        name="state"
                                        options={stateArry}
                                        onChange={this.handleState}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="form-group register-panel-field">
                            <div className="row">
                                <label className="form-label text-uppercase">
                                    {t("addDelivery.add_one")}
                                </label>
                                <StringInput
                                    id="add_one"
                                    name="add_one"
                                    className="remove-placeholder"
                                    onChange={this.onChange}
                                    value={this.state.add_one}
                                    placeholder={t("addDelivery.enter_address")}
                                    requiredMsg={t("validator.requiredMsg")}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group register-panel-field">
                            <div className="row">
                                <label className="form-label text-uppercase">
                                    {t("addDelivery.add_two")}
                                </label>
                                <StringInput
                                    id="add_two"
                                    name="add_two"
                                    className="remove-placeholder"
                                    onChange={this.onChange}
                                    value={this.state.add_two}
                                    placeholder={t("addDelivery.enter_address")}
                                />
                            </div>
                        </div>

                        <div className="form-group register-panel-field">
                            <div className="row">
                                <label className="form-label text-uppercase">
                                    {t("addDelivery.add_three")}
                                </label>
                                <StringInput
                                    id="add_three"
                                    name="add_three"
                                    className="remove-placeholder"
                                    onChange={this.onChange}
                                    value={this.state.add_three}
                                    placeholder={t("addDelivery.enter_address")}
                                />
                            </div>
                        </div>

                        <div className="form-group register-panel-field">
                            <div className="row">
                                <label className="form-label text-uppercase col-7">
                                    {t(
                                        "addDelivery.make_default_shipping_address"
                                    )}
                                </label>
                                <div className="col-5 float-right">
                                    <Switch
                                        className="float-right"
                                        onChange={this.handleDefaultAddress}
                                        checked={checked_default_address === 1}
                                    />
                                </div>
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary col-12 text-uppercase"
                        >
                            {t("addDelivery.update")}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { emall, modal } = state;

    return {
        data: emall.update_delivery_address,
        classIsShowing: modal.classIsShowing,
        country_data: emall.get_country,
        state_data: emall.get_country,
        get_edit_delivery_address: emall.get_edit_delivery_address,
    };
};

const mapDispatchToProps = {
    getEditDeliveryDetails,
    updateShipment,
    getCountry,
    openModal,
    closeModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDeliveryDetails);
