import * as type from './types';
import axios from 'axios';

export const postForgotPassword = (data) => dispatch => {
     axios.post(process.env.REACT_APP_GM_URL + '/member/password/reset', data, {
         headers: {
             'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
             'Authorization': sessionStorage.getItem("accessToken")
         }
     }).then( (response) => {
         dispatch ({
             type: type.POST_FORGOT_PASSWORD,
             payload: response
         })
     }).catch (error => {
         dispatch ({
             type: type.POST_FORGOT_PASSWORD,
             payload: {"rst":"0","msg":"system_error"}
         });

     });
};
