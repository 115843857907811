import * as type from './types';
import axios from 'axios';


/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Aun
 */

export const postMerchantTags = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/emall/tags', {
        'language_id': data['language_id'],
        'hash': data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_MERCHANT_TAG,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_MERCHANT_TAG,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postMerchantProducts = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/emall/product', {
        'page': data['page'],
        'limit': data['limit'],
        'email': data['email'],
        'sort': data['sort'],
        'language_id': data['language_id'],
        'product_name': data['product_name'],
        'tags': data['tags'],
        'hash': data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_MERCHANT_PRODUCTS,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_MERCHANT_PRODUCTS,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const getProductDetails = (data) => dispatch => {
    axios.get(process.env.REACT_APP_GM_URL + '/emall/product/details', {
        params: {
            'product_code': data['product_code'],
            'language_id': data['lang'],
            'hash': data['hash']
        },
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_PRODUCT_DETAILS,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_PRODUCT_DETAILS,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};


export const addDeliveryAddress = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/emall/add/shipment', data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_DELIVERY_ADDRESS,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_DELIVERY_ADDRESS,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postAddToCart = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/cart/add', {
        'logID': data['logID'],
        'prjCode': data['prjCode'],
        'product_code': data['product_code'],
        'variant_opt': data['variant_opt'],
        'username': data['username'],
        'quantity': data['quantity'],
        'lang': data['lang'],
        'hash': data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_PRODUCT_CART,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_PRODUCT_CART,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const getCountry = (data) => dispatch => {
    axios.get(process.env.REACT_APP_GM_URL + '/member/country', {
        params: {
            'langCode': data['langCode'],
            'parentId': data['parentId'],
            'hash': data['hash']
        },
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_COUNTRY,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_COUNTRY,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const getDeliveryDetails = (data) => dispatch => {
    axios.get(process.env.REACT_APP_GM_URL + '/emall/get/shipment', {
        params: {
            'user_id': data['user_id'],
            'hash': data['hash']
        },
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_DELIVERY_ADDRESS,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_DELIVERY_ADDRESS,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const getEditDeliveryDetails = (data) => dispatch => {
    axios.get(process.env.REACT_APP_GM_URL + '/emall/edit/shipment', {
        params: {
            'user_id': data['user_id'],
            'shipment_id': data['shipment_id'],
            'hash': data['hash']
        },
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_EDIT_DELIVERY_ADDRESS,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_EDIT_DELIVERY_ADDRESS,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const updateShipment = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/emall/update/shipment', {
        'user_id': data['user_id'],
        'shipment_id': data['shipment_id'],
        'type': data['type'],
        'name': data['name'],
        'mobile_prefix': data['mobile_prefix'],
        'mobile_no': data['mobile_no'],
        'address_one': data['address_one'],
        'country': data['country'],
        'state': data['state'],
        'address_two': data['address_two'],
        'address_three': data['address_three'],
        'default_address': data['default_address'],
        'lang': data['lang'],
        'hash': data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.UPDATE_DELIVERY_ADDRESS,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.UPDATE_DELIVERY_ADDRESS,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postCartConfirmOrder = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/cart/order/confirm', {
        'username': data['username'],
        'items': data['items'],
        'lang': data['lang']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_ORDER_CONFIRM,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_ORDER_CONFIRM,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const deleteShipment = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/emall/delete/shipment', {
        'user_id': data['user_id'],
        'shipment_id': data['shipment_id'],
        'lang': data['lang'],
        'hash': data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.DELETE_DELIVERY_ADDRESS,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.DELETE_DELIVERY_ADDRESS,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const getOrderList = (data) => dispatch => {
    axios.get(process.env.REACT_APP_GM_URL + '/order/list', {
        params: {
            'username': data['username'],
            'lang': data['lang'],
            'doc_no': data['doc_no'],
            'type': data['type']
        },
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_ORDER_LIST,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_ORDER_LIST,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const getOrderDetails = (data) => dispatch => {
    axios.get(process.env.REACT_APP_GM_URL + '/order/details', {
        params: data,
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_ORDER_DETAILS,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_ORDER_DETAILS,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postCartConfirmPayment = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/cart/payment', {
        'username': data['username'],
        'checkout_items': data['checkout_items'],
        'delivery_id': data['delivery_id'],
        'secondaryPassword': data['secondaryPassword'],
        'lang': data['lang'],
        'directPayment': data['directPayment'],
        'hash': data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_CONFIRM_PAYMENT,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_CONFIRM_PAYMENT,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postProductVariantKey = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/product/variant/key', {
        'username': data['username'],
        'variant_opt': data['variant_opt'],
        'product_code': data['product_code'],
        'qty': data['qty'],
        'lang': data['lang']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
            /*'Authorization': sessionStorage.getItem('accessToken')*/
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_VARIANT_KEY,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_VARIANT_KEY,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};


