import * as type from './types';
import axios from 'axios';

export const getProfile = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/profile', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_PROFILE,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.GET_PROFILE,
            payload: {"rst":"0","msg":"system_error"}
        })
    })
};

export const postUpdateLang = (res_data) => dispatch => {
    axios.put(process.env.REACT_APP_GM_URL + '/member/profile/lang', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_UPDATE_LANG,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.GET_PROFILE,
            payload: {"rst":"0","msg":"system_error"}
        })
    })
};

export const postLogout = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/logout', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_LOGOUT,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_LOGOUT,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postChangeBonusPayoutStatus = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/update', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_BONUS_PAYOUT_STATUS,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_BONUS_PAYOUT_STATUS,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
}

export const postUploadAvatar = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/update', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_UPLOAD_AVATAR,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_UPLOAD_AVATAR,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
}

export const postAutoPlacement = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/update', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_UPDATE_PLACEMENT,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_UPDATE_PLACEMENT,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
}

export const postMemberPlacement = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/placement', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_MEMBER_PLACEMENT,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_MEMBER_PLACEMENT,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
}

export const getPlacementDetails = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/placement/details', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_PLACEMENT_DETAILS,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_PLACEMENT_DETAILS,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
}

export const getPlacementTreeData = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/placement/tree', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_PLACEMENT_TREE_DATA,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_PLACEMENT_TREE_DATA,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
}

export const getPlacementNodes = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/placement/node', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_PLACEMENT_TREE_NODES,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_PLACEMENT_TREE_NODES,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
}

export const getSponsorNodes = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/sponsor/node', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_SPONSOR_TREE_NODES,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.GET_SPONSOR_TREE_NODES,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
}

