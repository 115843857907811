import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const loginReducer = createReducer(initialState) ({

    [type.POST_LOGIN]: (state, action) => ({
        ...state,
        login_data: action.payload,
    }),

});

export default loginReducer;