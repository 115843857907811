import referral from "../../pages/referral";
// import registerFriend from "../../pages/registerFriend";

const routes = [{
    path: "/referral",
    component: referral
}
// ,{
//     path: "/register/friend",
//     component: registerFriend
// }
];


export default routes;