export const POST_WALLET_BALANCE = "POST_WALLET_BALANCE";
export const POST_WALLET_BALANCE_HOME = "POST_WALLET_BALANCE_HOME";
export const GET_MEMBER_PROFILE = "GET_MEMBER_PROFILE";
export const GET_ANNOUCEMENT_LIST = "GET_ANNOUCEMENT_LIST";
export const POST_WALLET_BALANCE_LITE = "POST_WALLET_BALANCE_LITE";
export const POST_AUTO_SELL = "POST_AUTO_SELL";
export const GET_LANGUAGE_LIST = "GET_LANGUAGE_LIST";
export const POST_HOME_PANEL_SETTING = "POST_HOME_PANEL_SETTING";
export const GET_MEMBER_WALLET = "GET_MEMBER_WALLET";
export const GET_MISSION_SETTING = "GET_MISSION_SETTING";
export const GET_WITHDRAWAL_SETTING = "GET_WITHDRAWAL_SETTING";
export const GET_EXCHANGE_SETTING = "GET_EXCHANGE_SETTING";
export const GET_TRANSFER_SETTING = "GET_EXCHANGE_SETTING";
export const GET_STAKING_SETTING = "GET_STAKING_SETTING";
export const GET_DEPOSIT_SETTING = "GET_DEPOSIT_SETTING";
export const IS_LOADING = "IS_LOADING";
export const GET_WALLET_STATEMENT = "GET_WALLET_STATEMENT";
export const GET_WALLET_SETTING = "GET_WALLET_SETTING";
export const GET_LANGUAGE_FILE = "GET_LANGUAGE_FILE";
export const GET_CONVERT_SETTING = "GET_CONVERT_SETTING";
