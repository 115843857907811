import * as type from './types';
import axios from 'axios';

export const postLogin = (res_data, url) => dispatch => {
    axios.post(url + '/member/login', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_LOGIN,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_LOGIN,
            payload: {"rst":"0","msg":"system_error"}
        })
    })
};
