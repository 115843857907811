import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const getProfileReducer = createReducer(initialState) ({

    [type.GET_PROFILE]: (state, action) => ({
        ...state,
        profileData: action.payload
    }),

    [type.POST_UPDATE_LANG]: (state, action) => ({
        ...state,
        updateLangData: action.payload
    }),

    [type.POST_LOGOUT]: (state, action) => ({
        ...state,
        logoutData: action.payload,
    }),

    [type.POST_BONUS_PAYOUT_STATUS]: (state, action) => ({
        ...state,
        bonusPayoutStatusData: action.payload,
    }),

    [type.POST_UPLOAD_AVATAR]: (state, action) => ({
        ...state,
        uploadAvatarData: action.payload,
    }),

    [type.POST_UPDATE_PLACEMENT]: (state, action) => ({
        ...state,
        placementData: action.payload,
    }),

    [type.POST_MEMBER_PLACEMENT]: (state, action) => ({
        ...state,
        postPlacement: action.payload,
    }),

    [type.GET_PLACEMENT_DETAILS]: (state, action) => ({
        ...state,
        placementDetails: action.payload,
    }),

    [type.GET_PLACEMENT_TREE_DATA]: (state, action) => ({
        ...state,
        placement_list: action.payload,
    }),

    [type.GET_PLACEMENT_TREE_NODES]: (state, action) => ({
        ...state,
        placement_nodes: action.payload,
    }),

    [type.GET_SPONSOR_TREE_NODES]: (state, action) => ({
        ...state,
        sponsor_nodes: action.payload,
    }),
});

export default getProfileReducer;