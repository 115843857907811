import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Lottie from "lottie-react-web";
import SuccessIcon from "../../../../../../src/public/alert-animation/json/sucees_anim";

class SuccessAlert extends Component {

    render() {

        const {width, height} = this.props;

        /* Refer https://www.npmjs.com/package/lottie-react-web */
        const LoadingMask = () => (
            <Lottie
                options={{
                    animationData: SuccessIcon
                }}
                width={width}
                height={height}
            />
        );

        return (
            <LoadingMask />
        )
    }
}

export default SuccessAlert;

SuccessAlert.propTypes = {
    width: PropTypes.any.isRequired,
    height: PropTypes.any
};