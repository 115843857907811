import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const refreshTokenReducer = createReducer(initialState) ({

    [type.POST_SHAREPRICE]: (state, action) => ({
        ...state,
        data: action.payload,
    }),

    [type.POST_SHARE]: (state, action) => ({
        ...state,
        share_data: action.payload,
    }),

    [type.POST_SHARELIST]: (state, action) => ({
        ...state,
        list_data: action.payload,
    }),

    [type.POST_SHAREPAYMENT]: (state, action) => ({
        ...state,
        payment_data: action.payload,
    }),

    [type.POST_SHAREMETHOD]: (state, action) => ({
        ...state,
        method_data: action.payload,
    }),

    [type.POST_SHARECONTRACT]: (state, action) => ({
        ...state,
        contract_data: action.payload,
    })
});

export default refreshTokenReducer;