import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
    convert_date: []
};

const exchangeConvertReducer = createReducer(initialState) ({

    [type.POST_EXCHANGE_CONVERT_PRICE]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

    [type.POST_EXCHANGE_CONVERT]: (state, action) => ({
        ...state,
        convert_data: action.payload.data,
    }),

    [type.POST_SELECT_BALANCE]: (state, action) => ({
        ...state,
        select_balance: action.payload.data,
    }),
});
export default exchangeConvertReducer;