import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const transferReducer = createReducer(initialState)({
    [type.POST_TRANSFER]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

    [type.GET_USERNAME_BY_EMAIL]: (state, action) => ({
        ...state,
        getUsernameByEmail: action.payload.data,
    }),
});

export default transferReducer;
