import React, { Component } from "react";
// import { setLocale, t } from "react-i18nify";
import { t, setLanguageFileJson } from "../../common/translation";
import Modal from "../../components/modal";
import { Input, Select, Tabs } from "antd";
import connect from "react-redux/es/connect/connect";
import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeaderV2";
import NumberInput from "../../components/input/NumberInput";
import PasswordInput from "../../components/input/PasswordInput";
import {
    fieldChecking,
    GenHash,
    toFixedTrunc,
    slice_decimal_wt_rounding,
} from "../../common/helpers";
import TabFooter from "../../components/TabFooter";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import WalletBadge from "../../components/WalletBadge";
import { formValidation } from "../../common/validator/formValidation";

import {
    postWalletBalanceLite,
    getStakingSetting,
    getMemberWallet,
} from "../../store/ducks/home/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";
import {
    getStakingProgram,
    postPurchaseStakingProgram,
    getStakingProduct,
} from "../../store/ducks/stakingProgram/actions";

import "./stakingProgram.scss";
import HyperModal from "../../components/HyperModal";
import NextArrow from "../../public/v2/icon/icon-next.svg";
import InformationIcon from "../../public/v2/icon/icon-infor.svg";
import DropDownBox from "../../components/input/DropDownBox";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
class StakingProgram extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal_visible: false,
            loading_visible: true,
            modal_type: "",
            modal_msg: "",
            confirm_visible: false,
            // total_staking_amount: 100,
            // maximum_capacity_amount: 1000,
            show_staking_desc: false,
            displayWalletType: "BAT",
            secondary_password: "",
            amount: 0,
            screenHeight: window.innerHeight - (48 + 80),
            extra_info: [],
            staking_max_amount: 0,
            total_staking_amount: 0,
            minAmount: "100",
            payment_new: {},
            is_repurchase: 0,
            package_new: [],
            // package_value: "",
            payment_info: {},
            package_qty: {},
            errors: {},
            grand_amount: 0,
            payment_total_amount: 0,
            payment_new_1: {},
            package_value: {},
            rate_list: {},
        };

        this.handleSecondaryPassword = this.handleSecondaryPassword.bind(this);
        this.handlePurchasePin = this.handlePurchasePin.bind(this);
        this.handleSelectPaymentType = this.handleSelectPaymentType.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleSubmitPin = this.handleSubmitPin.bind(this);

        this.handlePayment = this.handlePayment.bind(this);

        // this.handleClickWallet = this.handleClickWallet.bind(this);
        this.onCallBack = this.onCallBack.bind(this);

        this.handleOpenDescModal = this.handleOpenDescModal.bind(this);
        this.handleClickQty = this.handleClickQty.bind(this);
        this.changePackage = this.changePackage.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
    }

    handleAmountChange(e) {
        let regexp = /^[1-9]\d*$/;

        if (regexp.test(e.target.value) || e.target.value == "") {
            this.setState({
                [e.target.name]: e.target.value,
                grand_amount: e.target.value,
            });
        }
    }

    changePackage(e) {
        // e.preventDefault();
        let value = e.target.value;

        if (this.state.package_qty[value]) {
            this.state.package_qty[value] += 1;
        } else {
            this.state.package_qty[value] = 1;
        }
        this.state.package_value[value] = value;
        let grand_amount = 0;

        Object.keys(this.state.package_qty).map((detail) => {
            // console.log(this.state.package_qty[detail]);
            let amount_split = detail.split("_");
            grand_amount =
                parseFloat(grand_amount) +
                parseFloat(amount_split[1]) *
                    parseFloat(this.state.package_qty[detail]);
        });

        this.setState({
            package_value: this.state.package_value,
            package_qty: this.state.package_qty,
            grand_amount: grand_amount,
        });
    }

    handleClickQty(e, name, action) {
        e.preventDefault();

        // let newQty = [];
        if (action == "plus") {
            if (this.state.package_qty[name]) {
                this.state.package_qty[name] += 1;
            } else {
                this.state.package_qty[name] = 1;
            }
        } else {
            if (this.state.package_qty[name]) {
                this.state.package_qty[name] -= 1;
            } else {
                this.state.package_qty[name] = 0;
            }
        }
        if (this.state.package_qty[name] == 0) {
            delete this.state.package_value[name];
        } else {
            this.state.package_value[name] = name;
        }

        let grand_amount = 0;

        Object.keys(this.state.package_qty).map((detail) => {
            //  console.log(this.state.package_qty[detail]);
            let amount_split = detail.split("_");
            grand_amount =
                parseFloat(grand_amount) +
                parseFloat(amount_split[1]) *
                    parseFloat(this.state.package_qty[detail]);
        });

        this.setState({
            package_qty: this.state.package_qty,
            package_value: this.state.package_value,
            grand_amount: grand_amount,
        });
    }
    handleOpenDescModal(e) {
        this.setState({
            show_staking_desc: true,
        });
    }

    componentDidMount() {
        let data = {};

        const hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        data.walletType = "BAT";
        this.props.getMemberWallet(data);
        this.props.getStakingSetting(data);
        this.props.getStakingProduct(data);
        //this.props.getStakingProgram(data);

        window.addEventListener("resize", this.updateHeight);
    }
    updateHeight() {
        this.setState({ screenHeight: window.innerHeight - (48 + 80) });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.stakingSetting !== this.props.stakingSetting) {
            if (nextProps.stakingSetting.data.rst === "1") {
                let stakingSetting = [];

                stakingSetting = nextProps.stakingSetting.data.data;

                let extra_info;
                let staking_max_amount;
                let total_amount;
                let currentSetting = stakingSetting
                    .filter(
                        (item) =>
                            item.walletTypeCode == this.state.displayWalletType
                    )
                    .map((filterItem) => {
                        extra_info = filterItem.extra_info;
                        staking_max_amount = filterItem.staking_max_amount;
                        total_amount = filterItem.total_amount;
                    });

                this.setState({
                    extra_info: extra_info,
                    staking_max_amount: staking_max_amount,
                    total_staking_amount: total_amount,
                });
            }
        }

        if (nextProps.product_data !== this.props.product_data) {
            if (nextProps.product_data.data.rst === "1") {
                let product_new = nextProps.product_data.data.data;

                if (product_new.package.length > 0) {
                    product_new.package.map((detail) => {
                        this.state.package_qty[detail.code] = 0;
                    });
                }
                // if (product_new.payment.length > 0) {
                //     product_new.payment.map((detail) => {
                //         this.state.payment_info[detail.currency_code] = 0;
                //     });
                // }

                Object.keys(product_new.new_payments).map((item) =>
                    product_new.new_payments[item].wallets.map((detail) => {
                        this.state.rate_list[detail["currency_code"]] = 0;
                    })
                );
                //  console.log(this.state.rate_list);

                this.setState({
                    is_repurchase: product_new.repurchase,
                    // payment_new: product_new.payment,
                    package_new: product_new.package,
                    package_qty: this.state.package_qty,
                    payment_new_1: product_new.new_payments,
                    rate_list: this.state.rate_list,
                });
            }
        }
        //
        if (nextProps.memberWallet !== this.props.memberWallet) {
            if (nextProps.memberWallet.data.rst === "1") {
                if (nextProps.memberWallet.data.data.walletBalance.length > 0) {
                    this.setState({
                        data: nextProps.memberWallet.data.data.walletBalance[0],
                        loading: false,

                        price_code:
                            nextProps.memberWallet.data.data.walletBalance[0]
                                .priceCode,
                    });
                }
            }
        }

        if (nextProps.postStakingResult !== this.props.postStakingResult) {
            if (nextProps.postStakingResult.rst === "1") {
                this.setState({
                    sub_total: 0,
                    secondary_password: "",
                    showConfirmationModal: false,
                    modal_msg: nextProps.postStakingResult.msg,
                    modal_type: "success",
                    modal_visible: true,
                    loading_visible: false,
                });
                let purchaseQtyArray = document.getElementsByName(
                    "purchaseQty[]"
                );
                for (
                    let purchaseQtyKey = 0;
                    purchaseQtyKey < purchaseQtyArray.length;
                    purchaseQtyKey++
                ) {
                    purchaseQtyArray[purchaseQtyKey].value = 0;
                }
            } else {
                this.setState({
                    modal_msg: nextProps.postStakingResult.msg,
                    modal_type: "error",
                    modal_visible: true,
                    loading_visible: false,
                });
            }
        }
        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }
    }

    onCallBack(e) {
        let data = {};

        const hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        data.walletType = e;
        this.props.getMemberWallet(data);
        this.props.getStakingSetting(data);
        this.props.getStakingProduct(data);
        this.setState({
            amount: 0,
            secondary_password: "",
            displayWalletType: e,
            package_value: {},
            payment_new_1: {},
            is_repurchase: 0,
            package_new: [],
            // package_value: "",
            payment_info: {},
            package_qty: {},
            grand_amount: 0,
            payment_total_amount: 0,
            error: {},
        });
    }

    handlePayment(e, min, max, wallets_code, rate) {
        //this.state.payment_info[e.target.id] = e.target.value;
        delete this.state.errors["grand_amount"];

        let estimated_amount = 0;
        this.state.rate_list[e.target.id] = 0;

        //get sum for all wallet code
        let sumAmount = 0;
        let regexp = /^[1-9]\d*$/;

        if (regexp.test(e.target.value) || e.target.value == "") {
            this.state.payment_info[e.target.id] = e.target.value;

            if (e.target.value > 0) {
                if (e.target.id == "BAT" || e.target.id == "CVT") {
                    estimated_amount =
                        parseFloat(e.target.value) / parseFloat(rate);
                } else {
                    estimated_amount =
                        parseFloat(e.target.value) * parseFloat(rate);
                }

                this.state.rate_list[e.target.id] = estimated_amount;
                if (min > 0) {
                    let sumForAllWalletGrp = 0;
                    let stringwallet = "";
                    let stringwallet_new = "";
                    wallets_code.map((detail, i) => {
                        sumForAllWalletGrp =
                            parseFloat(sumForAllWalletGrp) +
                            parseFloat(this.state.payment_info[detail]);
                        if (i == 0) {
                            stringwallet += detail;
                        } else {
                            stringwallet += " and " + detail;
                        }
                    });

                    let minPercent = min * 100;
                    let minAmount =
                        (parseFloat(this.state.grand_amount) * minPercent) /
                        100;

                    // stringwallet += "(" + minAmount + ")";
                    stringwallet_new =
                        t(
                            "stakingProgram.please_enter_a_value_greater_than_or_equal_to"
                        ) +
                        minAmount +
                        " for " +
                        stringwallet;

                    if (minAmount > sumForAllWalletGrp) {
                        this.state.errors[e.target.id] = stringwallet_new;
                    } else {
                        // this.state.errors = Object.keys(this.state.errors).filter(
                        //     (item) => item !== e.target.id
                        // );

                        delete this.state.errors[e.target.id];
                    }
                }
                if (max > 0) {
                    let sumForAllWalletGrp = 0;
                    let stringwallet = "";
                    let stringwallet_new = "";
                    wallets_code.map((detail, i) => {
                        sumForAllWalletGrp =
                            parseFloat(sumForAllWalletGrp) +
                            parseFloat(
                                this.state.payment_info[detail]
                                    ? this.state.payment_info[detail]
                                    : 0
                            );
                        if (i == 0) {
                            stringwallet += detail;
                        } else {
                            stringwallet += " and " + detail;
                        }
                    });

                    let maxPercent = max * 100;
                    let maxAmount =
                        (parseFloat(this.state.grand_amount) * maxPercent) /
                        100;

                    //stringwallet += "(" + maxAmount + ")";
                    stringwallet_new =
                        t(
                            "stakingProgram.please_enter_a_value_less_than_or_equal_to"
                        ) +
                        maxAmount +
                        " for " +
                        stringwallet;
                    // console.log(maxAmount, sumForAllWalletGrp);
                    if (maxAmount < sumForAllWalletGrp) {
                        this.state.errors[e.target.id] = stringwallet_new;
                    } else {
                        delete this.state.errors[e.target.id];
                    }
                }

                Object.keys(this.state.payment_info).map((detail) => {
                    sumAmount += parseFloat(
                        this.state.payment_info[detail] == ""
                            ? 0
                            : this.state.payment_info[detail]
                    );
                });
            }
        }

        this.setState({
            payment_info: this.state.payment_info,
            errors: this.state.errors,
            payment_total_amount: sumAmount,
            rate_list: this.state.rate_list,
        });
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/home");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                    show_staking_desc: false,
                });
                break;
        }
    }

    handleSecondaryPassword(event) {
        this.setState({
            secondary_password: event.target.value,
        });
    }

    handleSelectPaymentType(event) {
        this.setState({
            paymentType: event.target.value,
        });
    }

    async handlePurchasePin(event) {
        event.preventDefault();

        let validate = fieldChecking(document.getElementById(event.target.id));

        if (validate === false || Object.keys(this.state.errors).length > 0) {
            return false;
        }
        let sumAmount = 0;
        //this one can change grandamount
        if (this.state.is_repurchase) {
            this.state.amount = 0;
            Object.keys(this.state.package_qty).map((detail) => {
                let amount_split = detail.split("_");
                this.state.amount +=
                    parseFloat(amount_split[1]) *
                    parseFloat(this.state.package_qty[detail]);
            });
        }
        Object.keys(this.state.payment_info).map((detail) => {
            sumAmount += parseFloat(
                this.state.payment_info[detail] == ""
                    ? 0
                    : this.state.payment_info[detail]
            );
        });

        if (sumAmount > this.state.grand_amount) {
            this.state.errors["grand_amount"] = t(
                "stakingProgram.payment_cannot_more_than_grand_amount"
            );

            this.setState({
                errors: this.state.errors,
            });
            return false;
        } else if (sumAmount < this.state.grand_amount) {
            this.state.errors["grand_amount"] = t(
                "stakingProgram.payment_cannot_less_than_grand_amount"
            );
            this.setState({
                errors: this.state.errors,
            });
            return false;
        } else {
            // this.state.errors = Object.keys(this.state.errors).filter(
            //     (item) => item !== "grand_amount"
            // );
            delete this.state.errors["grand_amount"];
            this.setState({
                errors: this.state.errors,
            });
        }

        this.setState({
            modal_type: "normal",
            confirm_visible: true,
            // loading_visible: false,
        });
    }

    handleSubmitPin() {
        //   let data = {};

        // let total = 0;
        // let packages = this.state.purchasePackage;
        // let paymentInfo = this.state.payment_info;
        // let paymentArr = [];

        // paymentArr.push({
        //     walletTypeCode: this.state.displayWalletType,
        //     amount: this.state.amount,
        // });

        // data.payments = JSON.stringify(paymentArr);

        // data.secondary_password = this.state.secondary_password;
        // data.amount = this.state.amount;
        // data.lang = sessionStorage.getItem("lang");
        // data.asset = this.state.displayWalletType;

        // const hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        // data.hash = hash;

        this.setState({
            confirm_visible: false,
        });

        let newdata = {};
        // if (this.state.package_value == "") {
        //     newdata.package = this.state.package_new[0].code;
        // } else {
        //     newdata.package = this.state.package_value;
        // }

        // newdata.qty = this.state.package_qty[this.state.package_value]
        //     ? this.state.package_qty[this.state.package_value]
        //     : 1;
        newdata.secondary_password = this.state.secondary_password;
        newdata.amount = this.state.amount;
        newdata.asset = this.state.displayWalletType;
        newdata.payments = JSON.stringify(this.state.payment_info);

        if (this.state.displayWalletType == "BAT") {
            this.state.package_qty["bat_staking"] = 1;
        }
        if (
            this.state.is_repurchase == 0 &&
            this.state.displayWalletType == "CVT"
        ) {
            this.state.package_qty["cvt_staking"] = 1;
        }
        newdata.qty = JSON.stringify(this.state.package_qty);

        this.props.postPurchaseStakingProgram(newdata);
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");
        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleConfirmationClose() {
        this.props.closeModal("purchase-pin-confirmation-modal");
    }

    render() {
        const {
            loading_visible,
            confirm_visible,
            modal_visible,
            modal_type,
            modal_msg,
            secondary_password,
            extra_info,
            displayWalletType,
            minAmount,
        } = this.state;
        const { Option } = Select;

        const { TabPane } = Tabs;

        // const package_view = (
        //     <div className="p-0 col-12 text-white staking-radio">
        //         <div class="col-12 mt-3">
        //             <label class="option">
        //                 <div className="col-6 d-flex">
        //                     <span class="option-control">
        //                         <span class="radio">
        //                             <input
        //                                 type="radio"
        //                                 name="m_option_1"
        //                                 value="2"
        //                             />
        //                             <span></span>
        //                         </span>
        //                     </span>

        //                     <span class="option-label">
        //                         <span>1,000 (Re-purchase)</span>
        //                     </span>
        //                 </div>
        //                 <div className="col-6 d-inline-block text-right">
        //                     <span>QTY</span>
        //                     <button
        //                         name="plus"
        //                         onClick={(e) =>
        //                             this.handleClickQty(e, "name", "plus")
        //                         }
        //                         class="ml-3 button qty-button-color button5"
        //                     >
        //                         +
        //                     </button>
        //                     <span className="ml-3">{this.state.value_1}</span>
        //                     <button
        //                         name="minus"
        //                         onClick={(e) =>
        //                             this.handleClickQty(e, "name", "minus")
        //                         }
        //                         class="ml-3 button qty-button-color button5"
        //                     >
        //                         -
        //                     </button>
        //                 </div>
        //             </label>
        //         </div>
        //     </div>
        // );
        const payment_info = this.state.payment_info;
        const rate_list = this.state.rate_list;
        const handlePayment = this.handlePayment;
        const errors = this.state.errors;
        // const selectAfter = <img>text_url</img>;
        const payment_new_1 = Object.keys(this.state.payment_new_1).map(
            (item) =>
                this.state.payment_new_1[item].wallets.map((detail) => (
                    <div className="col-12" key={detail.currency_code}>
                        <div className="form-group">
                            <Input
                                //className="custom-test"
                                addonAfter={
                                    <div>
                                        <img
                                            height="13px"
                                            src={detail.text_url}
                                        ></img>
                                    </div>
                                }
                                name={`payment_info[${detail.currency_code}]`}
                                id={detail.currency_code}
                                name="amount"
                                onChange={(e) =>
                                    handlePayment(
                                        e,
                                        this.state.payment_new_1[item]
                                            .min_payment_percent,
                                        this.state.payment_new_1[item]
                                            .max_payment_percent,
                                        this.state.payment_new_1[item]
                                            .wallets_code,
                                        detail.rate
                                    )
                                }
                                placeholder="0"
                                value={payment_info[detail.currency_code]}
                            />
                            <div className="text-grey">
                                <span>
                                    {t("stakingProgram.balance") + " : "}
                                    {slice_decimal_wt_rounding(
                                        detail.balance,
                                        8
                                    ) +
                                        " " +
                                        t("stakingProgram.estimated_amount") +
                                        " :" +
                                        rate_list[detail.currency_code]}{" "}
                                </span>
                            </div>
                            {errors && errors[detail.currency_code] ? (
                                <div
                                    style={{ display: "block" }}
                                    className="invalid-feedback"
                                >
                                    {errors[detail.currency_code]}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                ))
        );

        return (
            <div className="stakingProgramPanel">
                <div className="container-fluid px-0">
                    <PageHeader
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        historyBack="/home"
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        walletType={""}
                        title={t("stakingProgram.staking_program")}
                        arrowLeft={false}
                    />
                </div>
                <div
                    className="overflow-auto purchase-pin-container"
                    style={{ height: this.state.screenHeight }}
                >
                    <Modal
                        title={
                            this.state.displayWalletType +
                            t("stakingProgram.staking_info")
                        }
                        children={
                            <div className="mb-4">
                                <div className="col-12 p-0">
                                    <div className="text-left staking-info-header">
                                        <table className="table">
                                            <thead className="staking-body-header">
                                                <tr>
                                                    <th>
                                                        {t(
                                                            "stakingProgram.price"
                                                        )}
                                                    </th>
                                                    <th>
                                                        {t(
                                                            "stakingProgram.daily_release"
                                                        )}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {extra_info
                                                    ? extra_info.map(
                                                          (item, index) => (
                                                              <tr key={index}>
                                                                  <td>
                                                                      {
                                                                          item.range
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          item.rate
                                                                      }
                                                                  </td>
                                                              </tr>
                                                          )
                                                      )
                                                    : ""}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        //submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleOpenDescModal}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={this.state.show_staking_desc}
                        modalClassName=""
                        modalClassId=""
                        modalHeadClass="staking-info-header"
                        modalTitleClass="text-white"
                        modalClassId="staking-info"
                        modalBodyClass="body-color"
                        modalTitleCloseFunc={this.handleModalClose}
                    />
                    <HyperModal
                        category="normal"
                        modalVisible={confirm_visible}
                        onOk={this.handleSubmitPin}
                        footer={
                            <div className="col-12 text-center">
                                <button
                                    key="submit"
                                    className="col-3 btn btn-primary"
                                    onClick={this.handleSubmitPin}
                                >
                                    {t("common.submit")}
                                </button>
                                <button
                                    key="button"
                                    className="col-3 btn btn-danger"
                                    onClick={(e) =>
                                        this.handleModalClose(e, "confirm")
                                    }
                                >
                                    {t("purchasePin.modal_close")}
                                </button>
                            </div>
                        }
                        modalContent={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>
                                        {t(
                                            "stakingProgram.confirmation_purchase_staking_header_msg",
                                            {
                                                walletType: displayWalletType,
                                            }
                                        )}
                                        ?
                                    </h4>
                                    <p>{t("purchasePin.confirmation_msg")}</p>
                                </div>
                            </div>
                        }
                        onCancel={this.handleModalClose}
                        closable={false}
                    ></HyperModal>

                    <HyperModal
                        category="response"
                        modalVisible={loading_visible}
                        modalType="loading"
                        footer={[]}
                    />

                    <HyperModal
                        category="response"
                        modalVisible={modal_visible}
                        modalType={modal_type}
                        modalMsg={modal_msg}
                        modalCloseFunc={this.handleModalClose}
                        cancelFunc={this.handleModalClose}
                    />

                    {/* <Tabs defaultActiveKey="BAT" onChange={this.onCallBack}>
                        <TabPane
                            tab={t("stakingProgram.bat_staking")}
                            key="BAT"
                        > */}
                    {/* {!loading_visible && ( */}
                    <div className="offset-md-2 col-md-8 px-0 mt-3">
                        <div className="col-12">
                            <div className="card mx-auto">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4 text-left">
                                            <span className="text-white">
                                                {t(
                                                    "stakingProgram.total_staking"
                                                )}
                                            </span>
                                        </div>
                                        <div className="col-8 text-right">
                                            <h5 className="text-white">
                                                {parseFloat(
                                                    this.state
                                                        .total_staking_amount
                                                ).toFixed(8)}

                                                <Link
                                                    className="text-light pl-2"
                                                    to={{
                                                        pathname:
                                                            "/program/statement",
                                                        state: {
                                                            staking_type: "BAT",
                                                        },
                                                    }}
                                                >
                                                    <img
                                                        className="mb-1"
                                                        alt={1}
                                                        src={NextArrow}
                                                        width={10}
                                                    />
                                                </Link>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-light-blue">
                                            <span>
                                                {t(
                                                    "stakingProgram.max_capacity_amount"
                                                )}
                                                :{" "}
                                                {parseFloat(
                                                    this.state
                                                        .staking_max_amount
                                                ).toFixed(8)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <div className="row">
                                <h5 className="col-6 d-inline text-light text-left text-uppercase">
                                    <b> {t("stakingProgram.bat_staking")}</b>
                                </h5>
                                <h5
                                    onClick={this.handleOpenDescModal}
                                    className="col-6 d-inline text-light text-right text-uppercase"
                                >
                                    <img
                                        src={InformationIcon}
                                        alt=""
                                        width={25}
                                    />
                                </h5>
                            </div>
                        </div>
                        <div className="mt-4">
                            {formValidation("purchase-pin-form")}
                            <form
                                id="purchase-pin-form"
                                onSubmit={this.handlePurchasePin}
                                className="needs-validation col-12 p-0"
                                noValidate
                            >
                                <div className="col-12">
                                    <div className="row">
                                        {/* <div className=""> */}
                                        <div className="col-12">
                                            <span className="text-uppercase package-title">
                                                {this.state.is_repurchase == 0
                                                    ? t(
                                                          "stakingProgram.input_amount"
                                                      )
                                                    : t(
                                                          "stakingProgram.choose_package"
                                                      )}
                                            </span>
                                        </div>
                                        {this.state.package_new.map(
                                            (detail) => (
                                                <div
                                                    className="mt-3 col-12"
                                                    key={detail.code}
                                                >
                                                    <NumberInput
                                                        //   name={
                                                        //       detail.code
                                                        //   }
                                                        id={detail.code}
                                                        min={
                                                            this.state.minAmount
                                                        }
                                                        name="amount"
                                                        onChange={
                                                            this
                                                                .handleAmountChange
                                                        }
                                                        className="depth-input"
                                                        placeholder="0.00"
                                                        value={
                                                            this.state.amount
                                                        }
                                                        //data={detail.asset_code}
                                                        // value={payment_info[detail.currency_code]}
                                                        //  data={this.state.displayWalletType}
                                                        textMoreThan={t(
                                                            "validator.amount_should_more_than"
                                                        )}
                                                        required
                                                        requiredMsg={t(
                                                            "validator.requiredMsg"
                                                        )}
                                                    />
                                                </div>
                                            )
                                        )}
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div
                                    className="col-12 pt-4 pb-4 mt-3"
                                    style={{ backgroundColor: "black" }}
                                >
                                    <div className="row">
                                        <span className="col-6 d-inline text-grey text-left text-uppercase">
                                            <b>
                                                {t(
                                                    "stakingProgram.grand_amount"
                                                )}
                                            </b>
                                        </span>
                                        <h5 className="col-6 d-inline text-light text-right text-uppercase">
                                            <b>{this.state.grand_amount}</b>
                                        </h5>
                                    </div>
                                </div>

                                <div className="card mx-auto mt-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <span className="text-uppercase text-blue">
                                                    {t(
                                                        "stakingProgram.payment"
                                                    )}
                                                </span>
                                            </div>
                                            {payment_new_1}
                                            {/* {payment_cvt} */}
                                            {/* {payments_new} */}
                                            <div className="col-12">
                                                <div className="row">
                                                    <span className="col-6 d-inline text-light text-left">
                                                        {t(
                                                            "stakingProgram.total_amount"
                                                        )}
                                                    </span>
                                                    <h5 className="col-6 d-inline text-light text-right">
                                                        <b>
                                                            {
                                                                this.state
                                                                    .payment_total_amount
                                                            }
                                                            /
                                                            {
                                                                this.state
                                                                    .grand_amount
                                                            }
                                                        </b>
                                                    </h5>
                                                </div>
                                                {errors &&
                                                errors["grand_amount"] ? (
                                                    <div
                                                        style={{
                                                            display: "block",
                                                        }}
                                                        className="invalid-feedback"
                                                    >
                                                        {errors["grand_amount"]}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mx-auto mt-2">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <span className="text-uppercase text-grey">
                                                    {t(
                                                        "purchasePin.secondary_password"
                                                    )}
                                                </span>
                                            </div>

                                            <div className="col-12">
                                                <PasswordInput
                                                    id="secondary_password"
                                                    name="secondary_password"
                                                    value={secondary_password}
                                                    onChange={
                                                        this
                                                            .handleSecondaryPassword
                                                    }
                                                    className="depth-input"
                                                    placeholder={t(
                                                        "purchasePin.please_enter_trading_password"
                                                    )}
                                                    requiredMsg={t(
                                                        "validator.requiredMsg"
                                                    )}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 mt-3 mb-4">
                                    <button
                                        type="submit"
                                        className="col-12 depth-blue-btn text-uppercase"
                                    >
                                        {t("purchasePin.submit")}
                                    </button>
                                </div>

                                <TabFooter stakingActive="active" />
                            </form>
                        </div>
                    </div>
                    {/* )} */}
                    {/* </TabPane> */}
                    {/* <TabPane
                            tab={t("stakingProgram.cvt_staking")}
                            key="CVT"
                        > */}

                    <div
                        className="offset-md-2 col-md-8 px-0"
                        style={{ display: "none" }}
                    >
                        <div className="col-12">
                            <div className="card mx-auto">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4 text-left">
                                            <span className="text-white">
                                                {t(
                                                    "stakingProgram.total_staking"
                                                )}
                                            </span>
                                        </div>
                                        <div className="col-8 text-right">
                                            <h5 className="text-white">
                                                {parseFloat(
                                                    this.state
                                                        .total_staking_amount
                                                ).toFixed(8)}

                                                <Link
                                                    className="text-light pl-2"
                                                    to={{
                                                        pathname:
                                                            "/program/statement",
                                                        state: {
                                                            staking_type: "CVT",
                                                        },
                                                    }}
                                                >
                                                    <img
                                                        className="mb-1"
                                                        alt={1}
                                                        src={NextArrow}
                                                        width={10}
                                                    />
                                                </Link>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-light-blue">
                                            <span>
                                                {t(
                                                    "stakingProgram.max_capacity_amount"
                                                )}
                                                :{" "}
                                                {parseFloat(
                                                    this.state
                                                        .staking_max_amount
                                                ).toFixed(8)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <div className="row">
                                <h5 className="col-6 d-inline text-light text-left text-uppercase">
                                    <b> {t("stakingProgram.cvt_staking")}</b>
                                </h5>
                                <h5
                                    onClick={this.handleOpenDescModal}
                                    className="col-6 d-inline text-light text-right text-uppercase"
                                >
                                    <img
                                        src={InformationIcon}
                                        alt=""
                                        width={25}
                                    />
                                </h5>
                            </div>
                        </div>
                        <div className="mt-4">
                            {formValidation("purchase-pin-form_1")}
                            <form
                                id="purchase-pin-form_1"
                                onSubmit={this.handlePurchasePin}
                                className="needs-validation col-12 p-0"
                                noValidate
                            >
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="text-uppercase package-title">
                                                {this.state.is_repurchase == 0
                                                    ? t(
                                                          "stakingProgram.input_amount"
                                                      )
                                                    : t(
                                                          "stakingProgram.choose_package"
                                                      )}
                                            </span>
                                        </div>
                                        {/* {this.state.is_repurchase
                                                    ? this.state.package_new.map(
                                                          (detail) => (
                                                              <div
                                                                  className="p-0 col-12 text-white staking-radio"
                                                                  key={
                                                                      detail.code
                                                                  }
                                                              >
                                                                  <div className="col-12 mt-2">
                                                                      <label className="option">
                                                                          <div className="col-3 p-0 d-flex">
                                                                              <span className="option-control">
                                                                                  <span className="radio">
                                                                                      <input
                                                                                          type="radio"
                                                                                          name={
                                                                                              detail.code
                                                                                          }
                                                                                          value={
                                                                                              detail.code
                                                                                          }
                                                                                          checked={
                                                                                              this
                                                                                                  .state
                                                                                                  .package_value[
                                                                                                  detail
                                                                                                      .code
                                                                                              ] ==
                                                                                              detail.code
                                                                                          }
                                                                                          onChange={
                                                                                              this
                                                                                                  .changePackage
                                                                                          }
                                                                                      />
                                                                                      <span></span>
                                                                                  </span>
                                                                              </span>

                                                                              <span className="option-label">
                                                                                  <span>
                                                                                      {
                                                                                          detail.name
                                                                                      }
                                                                                  </span>
                                                                              </span>
                                                                          </div>
                                                                          <div className="col-9 p-0 d-inline-block text-right">
                                                                              <span>
                                                                                  {t(
                                                                                      "stakingProgram.qty"
                                                                                  )}
                                                                              </span>
                                                                              <button
                                                                                  name="plus"
                                                                                  onClick={(
                                                                                      e
                                                                                  ) =>
                                                                                      this.handleClickQty(
                                                                                          e,
                                                                                          detail.code,
                                                                                          "plus"
                                                                                      )
                                                                                  }
                                                                                  className="ml-3 button qty-button-color button5"
                                                                              >
                                                                                  +
                                                                              </button>
                                                                              <span className="ml-3">
                                                                                  {this
                                                                                      .state
                                                                                      .package_qty[
                                                                                      detail
                                                                                          .code
                                                                                  ]
                                                                                      ? this
                                                                                            .state
                                                                                            .package_qty[
                                                                                            detail
                                                                                                .code
                                                                                        ]
                                                                                      : 0}
                                                                              </span>
                                                                              <button
                                                                                  name={
                                                                                      detail.code
                                                                                  }
                                                                                  onClick={(
                                                                                      e
                                                                                  ) =>
                                                                                      this.handleClickQty(
                                                                                          e,
                                                                                          detail.code,
                                                                                          "minus"
                                                                                      )
                                                                                  }
                                                                                  className="ml-3 button qty-button-color button5"
                                                                              >
                                                                                  -
                                                                              </button>
                                                                          </div>
                                                                      </label>
                                                                  </div>
                                                              </div>
                                                          )
                                                      )
                                                    :  */}
                                        {this.state.package_new.map(
                                            (detail) => (
                                                <div
                                                    className="mt-3 col-12"
                                                    key={detail.code}
                                                >
                                                    <NumberInput
                                                        //   name={
                                                        //       detail.code
                                                        //   }
                                                        id={detail.code}
                                                        min={
                                                            this.state.minAmount
                                                        }
                                                        name="amount"
                                                        onChange={
                                                            this
                                                                .handleAmountChange
                                                        }
                                                        className="depth-input"
                                                        placeholder="0.00"
                                                        value={
                                                            this.state.amount
                                                        }
                                                        //data={detail.asset_code}
                                                        // value={payment_info[detail.currency_code]}
                                                        //  data={this.state.displayWalletType}
                                                        textMoreThan={t(
                                                            "validator.amount_should_more_than"
                                                        )}
                                                        required
                                                        requiredMsg={t(
                                                            "validator.requiredMsg"
                                                        )}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                                {/* </div> */}
                                <div
                                    className="col-12 pt-4 pb-4 mt-3"
                                    style={{ backgroundColor: "black" }}
                                >
                                    <div className="row">
                                        <span className="col-6 d-inline text-grey text-left text-uppercase">
                                            <b>
                                                {t(
                                                    "stakingProgram.grand_amount"
                                                )}
                                            </b>
                                        </span>
                                        <h5 className="col-6 d-inline text-light text-right text-uppercase">
                                            <b>{this.state.grand_amount}</b>
                                        </h5>
                                    </div>
                                </div>

                                <div className="card mx-auto">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <span className="text-uppercase text-blue">
                                                    {t(
                                                        "stakingProgram.payment"
                                                    )}
                                                </span>
                                            </div>
                                            {payment_new_1}
                                            {/* {payment_cvt} */}
                                            <div className="col-12">
                                                <div className="row">
                                                    <span className="col-6 d-inline text-light text-left">
                                                        {t(
                                                            "stakingProgram.total_amount"
                                                        )}
                                                    </span>
                                                    <h5 className="col-6 d-inline text-light text-right">
                                                        <b>
                                                            {
                                                                this.state
                                                                    .payment_total_amount
                                                            }
                                                            /
                                                            {
                                                                this.state
                                                                    .grand_amount
                                                            }
                                                        </b>
                                                    </h5>
                                                </div>
                                                {errors &&
                                                errors["grand_amount"] ? (
                                                    <div
                                                        style={{
                                                            display: "block",
                                                        }}
                                                        className="invalid-feedback"
                                                    >
                                                        {errors["grand_amount"]}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mx-auto mt-2">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <span className="text-uppercase text-grey">
                                                    {t(
                                                        "purchasePin.secondary_password"
                                                    )}
                                                </span>
                                            </div>

                                            <div className="col-12">
                                                <PasswordInput
                                                    id="secondary_password"
                                                    name="secondary_password"
                                                    value={secondary_password}
                                                    onChange={
                                                        this
                                                            .handleSecondaryPassword
                                                    }
                                                    className="depth-input"
                                                    placeholder={t(
                                                        "purchasePin.please_enter_trading_password"
                                                    )}
                                                    requiredMsg={t(
                                                        "validator.requiredMsg"
                                                    )}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-3 mb-4">
                                    <button
                                        type="submit"
                                        className="col-12 depth-blue-btn text-uppercase"
                                    >
                                        {t("purchasePin.submit")}
                                    </button>
                                </div>
                                <TabFooter stakingActive="active" />
                            </form>
                        </div>
                    </div>
                    {/* )} */}
                    {/* </TabPane> */}
                    {/* </Tabs> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, stakingProgram, modal } = state;

    return {
        stakingPackages: stakingProgram.details,
        postStakingResult: stakingProgram.postStakingData,
        classIsShowing: modal.classIsShowing,
        //lite_data: walletBalance.lite_data,
        payment_method: stakingProgram.payment_method,
        stakingSetting: walletBalance.stakingData,
        memberWallet: walletBalance.memberWallet,
        isLoading: walletBalance.isLoading,
        product_data: stakingProgram.product_data,
    };
};

const mapDispatchToProps = {
    postWalletBalanceLite,
    getStakingProgram,
    postPurchaseStakingProgram,
    openModal,
    closeModal,
    getStakingSetting,
    getMemberWallet,
    getStakingProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(StakingProgram);
