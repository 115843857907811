import React, { Component } from "react";
import { formValidation } from "../../../common/validator/formValidation";
import { t } from "../../../common/translation";
import PasswordInput from "../../../components/input/PasswordInput";
import { fieldChecking, GenHash } from "../../../common/helpers";
import Modal from "../../../components/modal";
import LoadingMask from "../../../components/loadingMask";
import ErrorIcon from "../../../components/ErrorAlert";
import SuccessIcon from "../../../components/SuccessAlert";
import { postChangeSecondaryPassword } from "../../../store/ducks/changeSecondaryPassword/actions";
import { closeModal, openModal } from "../../../store/ducks/modal/actions";
import connect from "react-redux/es/connect/connect";
import StringInput from "../../../components/input/StringInput";
import { getMemberProfile } from "../../../store/ducks/home/actions";

class SecondaryModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: sessionStorage.getItem("email"),
            token: sessionStorage.getItem("token"),
            data: [],
            password: "",
            confirmPassword: "",
            showLoadingModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            errMsg: "",
            successMsg: "",
            referral_email: "",
            exists_referral: "",
        };

        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleSecondaryClose = this.handleSecondaryClose.bind(this);
        this.handleSubmitChangePassword = this.handleSubmitChangePassword.bind(
            this
        );
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleReferralEmail = this.handleReferralEmail.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem("lang") != null) {
            // setLocale(sessionStorage.getItem("lang"));
        }

        formValidation("secondary-form");
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.changeSecondaryPasswordData !==
            this.props.changeSecondaryPasswordData
        ) {
            if (nextProps.changeSecondaryPasswordData.rst !== "1") {
                this.setState({
                    showLoadingModal: false,
                    errMsg: t(
                        "changePassword." +
                            nextProps.changeSecondaryPasswordData.msg
                    ),
                });

                this.props.openModal("error-secondary-modal");
            } else {
                this.setState({
                    showLoadingModal: false,
                    successMsg: t(
                        "changePassword." +
                            nextProps.changeSecondaryPasswordData.msg
                    ),
                });

                this.props.openModal("success-modal");
            }
        }

        if (
            nextProps.getMemberProfileData !== this.props.getMemberProfileData
        ) {
            if (nextProps.getMemberProfileData.rst === "1") {
                this.setState({
                    exists_referral:
                        nextProps.getMemberProfileData.data.sponsorUsername,
                });
            } else {
                this.setState({
                    exists_referral: "",
                });
            }
        }

        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName === "secondary-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName === "error-secondary-modal"
            ) {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }
    }

    handleSubmitChangePassword(e) {
        e.preventDefault();

        let validate = fieldChecking(document.getElementById("secondary-form"));

        if (validate === false) {
            return false;
        }

        this.props.openModal("secondary-loading-modal");

        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.currentSecondaryPassword = this.state.currentPassword;
        data.newSecondaryPassword = this.state.password;
        data.confirmNewSecondaryPassword = this.state.confirmPassword;
        data.referralUsername = this.state.referral_email;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postChangeSecondaryPassword(data);
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");
        window.location.reload();
    }

    handleErrorClose() {
        this.props.closeModal("error-secondary-modal");
    }

    handlePassword(event) {
        this.setState({ password: event.target.value });
    }

    handleConfirmPassword(event) {
        this.setState({ confirmPassword: event.target.value });
    }

    handleSecondaryClose() {
        this.props.closeModal("secondary-modal");
        this.props.history.push("/login");
    }

    handleReferralEmail(event) {
        this.setState({ referral_email: event.target.value });
    }

    render() {
        const {
            showLoadingModal,
            showErrorModal,
            showSuccessModal,
            errMsg,
            successMsg,
            exists_referral,
        } = this.state;

        return (
            <form
                method="post"
                id="secondary-form"
                className="needs-validation"
                noValidate
                onSubmit={this.handleSubmitChangePassword}
            >
                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="secondary-loading-modal"
                        modalClassName=""
                        modalClassId="changeTradingPassword-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center bg-dark-purple">
                                    <ErrorIcon
                                        width="200px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        // closeText=""
                        // closeClassName=""
                        // closeFunc=""
                        // closeId=""
                        submitText={t("common.close")}
                        submitClassName="col-12 button btn-submit"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-secondary-modal"
                        onCancel={this.handleErrorClose}
                        closable={true}
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center bg-dark-purple">
                                    <SuccessIcon
                                        width="200px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="col-12 button btn-submit"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                        onCancel={this.handleSuccessClose}
                        closable={true}
                    />
                )}
                <div className="form-group panel-field">
                    <label
                        htmlFor="confirm_password"
                        className="depth-gray-label"
                    >
                        {t("changeTradingPassword.trading_password")}*
                    </label>
                    <PasswordInput
                        id="password"
                        name="password"
                        onChange={this.handlePassword}
                        value={this.state.password}
                        placeholder={t(
                            "changeTradingPassword.please_enter_trading_password"
                        )}
                        className="depth-input"
                        required
                        requiredMsg={t("validator.requiredMsg")}
                        minLength={6}
                        minLengthMsg={t("register.minLengthMsg", { count: 6 })}
                    />
                </div>

                <div className="form-group panel-field">
                    <label
                        htmlFor="confirm_password"
                        className="depth-gray-label"
                    >
                        {t("changeTradingPassword.confirm_trading_password")}*
                    </label>
                    <PasswordInput
                        id="confirm_password"
                        name="confirm_password"
                        onChange={this.handleConfirmPassword}
                        value={this.state.confirmPassword}
                        placeholder={t(
                            "changeTradingPassword.please_reenter_trading_password"
                        )}
                        className="depth-input"
                        required
                        requiredMsg={t("validator.requiredMsg")}
                        minLength={6}
                        minLengthMsg={t("register.minLengthMsg", { count: 6 })}
                    />
                </div>

                {exists_referral === "" && (
                    <div className="form-group panel-field">
                        <label
                            htmlFor="referral_email"
                            className="depth-gray-label"
                        >
                            {t("changeTradingPassword.referral_email")}
                        </label>
                        <StringInput
                            id="referral_email"
                            name="referral_email"
                            onChange={this.handleReferralEmail}
                            value={this.state.referral_email}
                            className="depth-input"
                            valid_email="true"
                            emailMsg={t(
                                "changeTradingPassword.please_enter_valid_email"
                            )}
                            placeholder={t(
                                "changeTradingPassword.please_enter_email"
                            )}
                            required
                        />
                    </div>
                )}

                <div className="form-group text-right">
                    <button type="submit" className="col-12 button btn-submit">
                        {t("changeTradingPassword.submit")}
                    </button>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const { changeSecondaryPassword, modal, getMemberProfile } = state;

    return {
        changeSecondaryPasswordData:
            changeSecondaryPassword.changeSecondaryPasswordData,
        classIsShowing: modal.classIsShowing,
        getMemberProfileData: getMemberProfile.getMemberProfileData,
    };
};

const mapDispatchToProps = {
    postChangeSecondaryPassword,
    getMemberProfile,
    openModal,
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryModal);
