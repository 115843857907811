import React, { Component } from "react";
import { connect } from "react-redux";
//import { setLocale, t } from 'react-i18nify';
import { DatePicker, Modal } from "antd";
import moment from "moment";
import { t } from "../../common/translation";
import PageHeader from "../../components/PageHeaderV2";
import LoadingMask from "../../components/loadingMask";

import { postRewards } from "../../store/ducks/rewards/actions";

import { GenHash, toFixedTrunc } from "../../common/helpers";

import RewardImg from "../../public/images/profile/icon-reward-orange@3x.png";
import ArrowOrange from "../../public/images/arrow-next-orange@3x.png";

import "./rewards.scss";
import { Link } from "react-router-dom";

class Rewards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            listLoading: true,
            dateFrom: "",
            dateEnd: "",
            referralAmt: toFixedTrunc(0, 2),
            referralSalesRevenueAmt: toFixedTrunc(0, 2),
            starAmt: toFixedTrunc(0, 2),
            partnerSalesAmt: toFixedTrunc(0, 2),
            grandTotal: toFixedTrunc(0, 2),
            dateFirstDay: moment().startOf("month"),
            dateNow: moment(),
            data: [],
            screenHeight: window.innerHeight - 190,
        };

        this.updateHeight = this.updateHeight.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `50px`);
    }

    UNSAFE_componentWillMount() {
        this.setState(
            {
                dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
                dateEnd: moment().format("YYYY-MM-DD"),
            },
            () => {
                const data = {};

                data.email = sessionStorage.getItem("email");
                data.token = sessionStorage.getItem("token");
                data.rwdType = "";
                data.lang = sessionStorage.getItem("lang");
                data.date_from = this.state.dateFrom;
                data.date_to = this.state.dateEnd;

                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;

                this.props.postRewards(data);
            }
        );
    }

    componentDidMount() {
        // setLocale(sessionStorage.getItem("lang"));
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `230px`);
        });
        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.rewardsData !== this.props.rewardsData) {
            if (nextProps.rewardsData.rst === "1") {
                this.setState({
                    loading: false,
                    listLoading: false,
                    data: nextProps.rewardsData.data,
                    // referralAmt: (nextProps.rewardsData.data[0]['total'] !== undefined) ? toFixedTrunc(nextProps.rewardsData.data[0]['total'], 2) : toFixedTrunc(0, 2),
                    // referralSalesRevenueAmt: (nextProps.rewardsData.data[1]['total'] !== undefined) ? toFixedTrunc(nextProps.rewardsData.data[1]['total'], 2) : toFixedTrunc(0, 2),
                    // starAmt: (nextProps.rewardsData.data[2]['total'] !== undefined) ? toFixedTrunc(nextProps.rewardsData.data[2]['total'], 2) : toFixedTrunc(0, 2),
                    // partnerSalesAmt: (nextProps.rewardsData.data[3]['total'] !== undefined) ? toFixedTrunc(nextProps.rewardsData.data[3]['total'], 2) : toFixedTrunc(0, 2),
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        }
    }

    onSearch = () => {
        this.setState({
            loading: true,
        });

        const data = {};

        data.email = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.rwdType = "";
        data.lang = sessionStorage.getItem("lang");
        data.date_from = this.state.dateFrom;
        data.date_to = this.state.dateEnd;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postRewards(data);
    };

    onDateFromChange = (date, dateString) => {
        this.setState({
            dateFrom: dateString,
        });
    };

    onDateEndChange = (date, dateString) => {
        this.setState({
            dateEnd: dateString,
        });
    };

    updateHeight() {
        this.setState({ screenHeight: window.innerHeight - 190 });
    }

    render() {
        const {
            loading,
            referralAmt,
            data,
            listLoading,
            referralSalesRevenueAmt,
            partnerSalesAmt,
            starAmt,
            dateNow,
            dateFirstDay,
            dateFrom,
            dateEnd,
        } = this.state;

        let reward_list;

        let total_bns = 0;
        data.map(function (item, i) {
            total_bns += parseFloat(item.rwdDetails[0].total);
        });

        total_bns = toFixedTrunc(total_bns, 2);

        reward_list = data.map(function (item, i) {
            return (
                <div className="mt-2 px-0" key={i}>
                    <div className="">
                        <div className="card mx-auto border-0">
                            <div className="card-body">
                                {item.rwdDetails.length > 0 &&
                                    item.rwdDetails.map(function (item2, j) {
                                        if (item.rwdType === "bingo") {
                                            return (
                                                <Link to={item2.nav}>
                                                    <div className="row">
                                                        <div className="pl-2 justify-content-center">
                                                            <img
                                                                src={RewardImg}
                                                                alt=""
                                                                width={20}
                                                            />
                                                        </div>
                                                        <div className="col-9 col-md-10">
                                                            <b className="card-text-orange text-uppercase">
                                                                {
                                                                    item2.rwdTypeName
                                                                }
                                                            </b>
                                                            <br />
                                                            <b className="card-text-light">
                                                                {item2.total}
                                                            </b>
                                                        </div>
                                                        <div className="col-1 my-auto">
                                                            <img
                                                                src={
                                                                    ArrowOrange
                                                                }
                                                                alt=""
                                                                width={10}
                                                            />
                                                        </div>
                                                    </div>
                                                </Link>
                                            );
                                        } else {
                                            return (
                                                <Link
                                                    to={{
                                                        pathname: item2.nav,
                                                        state: {
                                                            rewardType:
                                                                item.rwdType,
                                                            dateStart: dateFrom,
                                                            dateTo: dateEnd,
                                                        },
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="pl-2 justify-content-center">
                                                            <img
                                                                src={RewardImg}
                                                                alt=""
                                                                width={20}
                                                            />
                                                        </div>
                                                        <div className="col-9 col-md-10">
                                                            <b className="card-text-orange text-uppercase">
                                                                {
                                                                    item2.rwdTypeName
                                                                }
                                                            </b>
                                                            <br />
                                                            <b className="card-text-light">
                                                                {item2.total}
                                                            </b>
                                                        </div>
                                                        <div className="col-1 my-auto">
                                                            <img
                                                                src={
                                                                    ArrowOrange
                                                                }
                                                                alt=""
                                                                width={10}
                                                            />
                                                        </div>
                                                    </div>
                                                </Link>
                                            );
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className="rewards">
                {loading && (
                    <Modal
                        visible={loading}
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        footer={null}
                        closable={false}
                        className="LoadingModalAnt"
                    />
                )}

                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack={"/profile"}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("rewards.rewards")}
                    arrowLeft={true}
                />

                <div className="mt-4 px-3 mt-4 px-3 offset-md-2 col-md-8">
                    <label className="col-12 depth-gray-label text-capitalize pl-0">
                        {t("rewards.date")}
                    </label>
                    <div className="row">
                        <div className="col-6">
                            <DatePicker
                                onChange={this.onDateFromChange}
                                defaultValue={dateFirstDay}
                                className="datepicker-full-width"
                            />
                        </div>

                        <div className="col-6">
                            <DatePicker
                                onChange={this.onDateEndChange}
                                defaultValue={dateNow}
                                className="datepicker-full-width"
                            />
                        </div>

                        <div className="col-12 pb-4">
                            <button
                                type="button"
                                className="col-12 mt-2 depth-blue-btn"
                                onClick={this.onSearch}
                            >
                                {t("rewards.search")}
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className="bg-purple custom-height overflow-auto"
                    // style={{ height: this.state.screenHeight }}
                >
                    <div className="pt-4 px-4 offset-md-2 col-md-8">
                        <div className="form-group">
                            <strong className="form-label text-uppercase text-light">
                                {t("rewards.commission_daily_summary")}
                            </strong>
                        </div>

                        <div className="form-group">
                            <div className="col-12 px-0">
                                <label className="col-5 pl-0 depth-gray-label">
                                    {t("rewards.grand_total")}{" "}
                                </label>
                                <label className="col-7 text-light">
                                    {total_bns}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="offset-md-2 col-md-8 h-100">
                        {!listLoading && reward_list}

                        {listLoading && (
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { rewards } = state;

    return {
        rewardsData: rewards.data,
    };
};

const mapDispatchToProps = {
    postRewards,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
