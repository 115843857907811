import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale, t } from "react-i18nify";

import {
    postCartConfirmOrder,
    postCartConfirmPayment,
} from "../../store/ducks/emall/actions";
import { postTransactionList } from "../../store/ducks/walletDetails/actions";
import { getEditDeliveryDetails } from "../../store/ducks/emall/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";

import "./confirmPurchase.scss";
import PageHeader from "../../components/PageHeader";

import { GenHash } from "../../common/helpers";
import PasswordInput from "../../assets/js/views/components/input/PasswordInput";
import LoadingMask from "../../components/loadingMask";
import Modal from "../../components/modal";
import ErrorIcon from "../../components/ErrorAlert";

class ConfirmPurchase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialAmount: 1,
            amount: 1,
            secondaryPassword: "",
            directPayment: false,
            loading: true,
            loading2: true,
            showErrorModal: false,
            showLoadingModal: false,
            data: [],
            prices: [],
            address_detail_array: {},
            errMsg: "",

            headerHeight: window.innerWidth,
        };

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleConfirmPayment = this.handleConfirmPayment.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
    }

    componentDidMount() {
        setLocale(sessionStorage.getItem("lang"));

        window.addEventListener("resize", this.updateHeight);

        const data = {};
        const dataDeliveryDetails = {};

        data.username = sessionStorage.getItem("email");
        data.items = sessionStorage.getItem("checkout_items");
        data.lang = sessionStorage.getItem("lang");

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        dataDeliveryDetails.user_id = sessionStorage.getItem("email");
        dataDeliveryDetails.shipment_id = sessionStorage.getItem(
            "checkoutAddress"
        );
        let hashDeliveryDetail = GenHash(
            dataDeliveryDetails,
            process.env.REACT_APP_GM_SECRET_KEY
        );
        dataDeliveryDetails.hash = hashDeliveryDetail;

        this.props.getEditDeliveryDetails(dataDeliveryDetails);

        this.props.postCartConfirmOrder(data);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data !== this.props.data) {
            if (nextProps.data.rst === "1") {
                let array = nextProps.data.data;

                this.setState({
                    data: array.data,
                    prices: array.prices,
                    directPayment: array.directPayment,
                    loading: false,
                });
            }
        }

        if (nextProps.address_detail !== this.props.address_detail) {
            if (nextProps.address_detail.rst === "1") {
                this.setState({
                    address_detail_array: nextProps.address_detail.data,
                    loading2: false,
                });
            }
        }

        if (nextProps.confirm_payment !== this.props.confirm_payment) {
            if (nextProps.confirm_payment.rst === "1") {
                this.props.history.push({
                    pathname: "/emall/complete-purchase",
                    showLoadingModal: false,
                    state: { doc_no: nextProps.confirm_payment.data },
                });
            } else {
                this.setState({
                    errMsg: nextProps.confirm_payment.msg,
                    showLoadingModal: false,
                });

                this.props.openModal("error-modal");
            }
        }

        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName === "purchase-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }
        }
    }

    handleConfirmPayment(e) {
        this.props.openModal("purchase-loading-modal");
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.checkout_items = sessionStorage.getItem("checkout_items");
        data.delivery_id = sessionStorage.getItem("checkoutAddress");
        data.secondaryPassword = this.state.secondaryPassword;
        data.lang = sessionStorage.getItem("lang");
        data.directPayment = this.state.directPayment;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postCartConfirmPayment(data);
    }

    updateHeight() {
        if (window.innerWidth <= 768) {
            this.setState({ headerHeight: window.innerWidth });
        } else {
            this.setState({ headerHeight: 768 });
        }
    }

    onChange(e) {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    onChangeAmount(e) {
        let amount = this.state.amount;

        if (e.target.id === "plus") {
            this.setState({ amount: amount + 1 });
        } else if (e.target.id === "minus" && amount !== 0) {
            this.setState({ amount: amount - 1 });
        }
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");
    }

    render() {
        const {
            showLoadingModal,
            showErrorModal,
            errMsg,
            loading,
            loading2,
            secondaryPassword,
            data,
            prices,
            address_detail_array,
        } = this.state;

        const orderDetails = data.map(function (item, i) {
            return (
                <li
                    key={i}
                    className="list-group-item asset-panel-list-group-item col-12 px-0"
                >
                    <div className="col-4 col-md-2 fa-pull-left">
                        <img src={item.image} sizes={10} alt={1} />
                    </div>
                    <div className="col-8  col-md-10 fa-pull-right px-0 pt-2">
                        <div className="col-12">
                            <small className="row mx-0 px-0">{item.name}</small>
                        </div>
                        <div className="col-12">
                            {item.price.map(function (price, k) {
                                return (
                                    <small key={k}>
                                        <b>
                                            {price.b_display_code}&nbsp;
                                            {price.unit_price}&nbsp;
                                        </b>
                                    </small>
                                );
                            })}
                        </div>
                        <div className="col-12">
                            <sub>
                                {t("emall.qty")}: {item.quantity}
                            </sub>
                        </div>
                    </div>
                </li>
            );
        });

        const priceList = prices.map(function (item2, j) {
            return (
                <div key={j}>
                    <div className="col-12 text-right">
                        <b>
                            {item2.display_code}&nbsp;{item2.amount}
                        </b>
                    </div>
                </div>
            );
        });

        return (
            <div className="myOrders">
                <div className="container-fluid px-0">
                    <PageHeader
                        subTitle={t("emall.confirm_purchase")}
                        navClass="title white-color"
                        backgroundHeight={80}
                        subPageHistoryBack={"/emall/shopping-cart"}
                        walletType={""}
                        arrowLeft={true}
                    />
                </div>

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="purchase-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {loading && loading2 && (
                    <div className="container pt-5">
                        <div className="row">
                            <div className="mx-auto">
                                <LoadingMask width={80} />
                            </div>
                        </div>
                    </div>
                )}

                {!loading && !loading2 && (
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                <li className="list-group-item asset-panel-list-group-item col-12 px-0">
                                    <div className="col-12">
                                        <small>{t("emall.ship_bill_to")}</small>
                                    </div>
                                    <div className="col-12">
                                        <small>
                                            <b>
                                                {
                                                    address_detail_array.shipment_name
                                                }
                                            </b>
                                        </small>
                                    </div>
                                    <div className="col-12">
                                        <small>
                                            <b>
                                                {address_detail_array.mobile_no}
                                            </b>
                                        </small>
                                    </div>
                                    <div className="col-12">
                                        <small className="text-uppercase">
                                            {address_detail_array.address_1}{" "}
                                            {address_detail_array.address_2 !==
                                                "" &&
                                                ", " +
                                                    address_detail_array.address_2}{" "}
                                            {address_detail_array.address_3 !==
                                                "" &&
                                                ", " +
                                                    address_detail_array.address_3}{" "}
                                            {address_detail_array.state_name !==
                                                "" &&
                                                ", " +
                                                    address_detail_array.state_name}{" "}
                                            {", " +
                                                address_detail_array.country_name}
                                            .
                                        </small>
                                    </div>
                                </li>
                            </div>

                            <div className="row">{orderDetails}</div>
                        </div>

                        <hr className="invisible-hr" />

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 px-0">
                                    <div className="fa-pull-left col-6">
                                        <small>
                                            <b>{t("emall.total")}</b>
                                        </small>
                                    </div>
                                    <div className="fa-pull-right col-6">
                                        <div className="col-12 px-0">
                                            {priceList}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="container-fluid">*/}
                        {/*  <div className="row">*/}
                        {/*    <div className="col-12 px-0">*/}
                        {/*      <div className="fa-pull-left col-6">*/}
                        {/*        <small><b>{t('emall.total')}</b></small>*/}
                        {/*      </div>*/}
                        {/*      <div className="fa-pull-right col-6">*/}
                        {/*        <label className="mb-0 fa-pull-right">USDT 300.00</label>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/*<hr className="invisible-hr"/>*/}

                        {/*<div className="container-fluid">*/}
                        {/*  <div className="row">*/}
                        {/*    <div className="col-12 px-0">*/}
                        {/*      <div className="fa-pull-left col-6">*/}
                        {/*        <small>{t('emall.subtotal')}</small>*/}
                        {/*      </div>*/}
                        {/*      <div className="fa-pull-right col-6">*/}
                        {/*        <div className="col-12 pr-0">*/}
                        {/*          <label className="fa-pull-right mb-0">USDT 300.00</label>*/}
                        {/*          <label className="fa-pull-right mb-0">AV 18.00</label>*/}
                        {/*        </div>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}

                        {/*    <div className="col-12  px-0">*/}
                        {/*      <div className="fa-pull-left col-6">*/}
                        {/*        <small>{t('emall.shipping_fee')}</small>*/}
                        {/*      </div>*/}
                        {/*      <div className="fa-pull-right col-6">*/}
                        {/*        <div className="col-12 pr-0">*/}
                        {/*          <label className="fa-pull-right">USDT 0.00</label>*/}
                        {/*        </div>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        <hr />

                        <div className="col-12">
                            <div className="col-12 px-0">
                                <small className="text-uppercase">
                                    <b>{t("emall.secondary_password")}</b>
                                </small>
                            </div>
                            <div className="col-12 px-0">
                                {/*<StringInput*/}
                                {/*    className="col-12 form-control"*/}
                                {/*    id="voucherCode"*/}
                                {/*    name="voucherCode"*/}
                                {/*    placeholder={t("emall.enter_secondary_password")}*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    value={voucherCode}*/}
                                {/*/>*/}
                                <PasswordInput
                                    id="secondaryPassword"
                                    name="secondaryPassword"
                                    placeholder={t(
                                        "emall.please_enter_secondary_password"
                                    )}
                                    value={secondaryPassword}
                                    onChange={this.onChange}
                                    className="form-control secondary-password-field"
                                    required
                                />
                            </div>

                            <div className="col-12 px-0 pt-3">
                                {/*<Link to="/emall/complete-purchase">*/}
                                <button
                                    type="button"
                                    className="btn btn-success text-uppercase col-12"
                                    onClick={this.handleConfirmPayment}
                                >
                                    <small>{t("emall.confirm_payment")}</small>
                                </button>
                                {/*</Link>*/}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { emall, walletDetails, modal } = state;

    return {
        data: emall.data,
        confirm_payment: emall.confirm_payment,
        address_detail: emall.get_edit_delivery_address,
        transaction_list: walletDetails.data,
        classIsShowing: modal.classIsShowing,
    };
};

const mapDispatchToProps = {
    postCartConfirmOrder,
    postCartConfirmPayment,
    postTransactionList,
    getEditDeliveryDetails,
    openModal,
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPurchase);
