import * as type from "./types";
import axios from "axios";
// import jwt from 'jsonwebtoken';
//
// import { GenHash } from "../../../assets/js/helpers";
// import { text } from '../../../assets/js/state/middleware/public-key';

/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Aun
 */
export const postWithdrawal = (data) => (dispatch) => {
    axios
        .post(
            process.env.REACT_APP_GM_URL + "/member/wallet/withdrawal/add",
            {
                platform: data["platform"],
                cryptoAddress: data["cryptoAddress"],
                secondaryPassword: data["secondaryPassword"],
                token: data["token"],
                username: data["username"],
                walletType: data["walletType"],
                withdrawAmount: data["withdrawAmount"],
                withdrawTo: data["withdrawTo"],
                hash: data["hash"],
                lang: data["lang"],
                wallet_type_code: data["wallet_type_code"],
                vertex_username: data["vertex_username"],
            },
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.POST_WITHDRAWAL,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.POST_WITHDRAWAL,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

// const originalRequest = error.config;
//
// if (error.response.status === 401) {
//     if (sessionStorage.getItem("accessToken") != null) {
//         jwt.verify(sessionStorage.getItem("accessToken"), text, {algorithm: 'RS256'}, function (err, decoded_token) {
//             if (err !== null && decoded_token !== null) {
//
//                 jwt.verify(sessionStorage.getItem("refreshToken"), text, {algorithm: 'RS256'}, function (refresh_err, refresh_decoded_token) {
//                     if (refresh_err !== null && refresh_decoded_token !== null) {
//                         sessionStorage.setItem("tokenExpired", "true");
//                         sessionStorage.removeItem("uname");
//                         sessionStorage.removeItem("accessToken");
//                         sessionStorage.removeItem("refreshToken");
//                         window.location.href = "/login";
//                     }
//                 });
//             }
//         });
//
//         axios.post(process.env.REACT_APP_GM_URL + "/member/refresh/token", {
//             "refresh_token": sessionStorage.getItem("refreshToken"),
//             "hash": GenHash(sessionStorage.getItem("refreshToken"), process.env.REACT_APP_GM_SECRET_KEY)
//         }, {
//             headers: {
//                 'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
//                 'Authorization': sessionStorage.getItem("accessToken")
//             }
//         }).then((response) => {
//
//             sessionStorage.setItem("accessToken", response.data.data.access_token);
//             sessionStorage.setItem("refreshToken", response.data.data.refresh_token);
//
//             console.log(sessionStorage.getItem("accessToken"));
//             error.config.headers.Authorization = sessionStorage.getItem("accessToken");
//
//             return axios(originalRequest).then( (response) => {
//                 dispatch ({
//                     type: type.POST_WITHDRAWAL,
//                     payload: response
//                 })
//             });
//
//         }).catch(err => {
//             sessionStorage.setItem("tokenExpired", "true");
//             sessionStorage.removeItem("uname");
//             sessionStorage.removeItem("accessToken");
//             sessionStorage.removeItem("refreshToken");
//             window.location.href = "/login";
//         });
//     }
// }
