import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    classIsShowing: []
};

const modalReducer = createReducer(initialState) ({

    [type.GET_OPEN_MODAL]: (state, action) => ({
        ...state,
        classIsShowing: action.payload
    }),

});

export default modalReducer;