import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Select2 extends Component {

    render() {
        const { id, name, className, onChange, style, required, value, disabled } = this.props;

        return (
            <select
                className={className}
                id={id}
                name={name}
                onChange={onChange}
                style={style}
                required={required}
                value={value}
                disabled={disabled}
            >
                {this.props.options.map((option, index) =>

                    <option key={index} value={option.value}>
                        {option.name}
                    </option>
                )}
            </select>
        )
    }
}

export default Select2;

Select2.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.any,
    style: PropTypes.object,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool
};

Select2.defaultProps = {
    className: ''
};