import historyProvider from "./historyProvider";

const supportedResolutions = ["5", "30", "240", "D"];

const config = {
    supported_resolutions: supportedResolutions,
};

export default {
    onReady: (cb) => {
        setTimeout(() => cb(config), 0);
    },
    searchSymbols: (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
    ) => {},
    resolveSymbol: (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback
    ) => {
        // expects a symbolInfo object in response

        // console.log('resolveSymbol:',{symbolName})
        var split_data = symbolName.split(/[:/]/);
        // console.log({split_data})
        var symbol_stub = {
            name: symbolName,
            description: "",
            type: "crypto",
            session: "24x7",
            //timezone: "Asia/Singapore",
            // timezone: "Etc/UTC",
            ticker: symbolName,
            exchange: split_data[0],
            minmov: 1,
            pricescale: 1000000,
            //has_intraday: false,
            has_intraday: true,
            // intraday_multipliers: ["1", "60"],
            //intraday_multipliers: ["1", "3", "5", "15", "60", "1D", "1W"],
            supported_resolution: supportedResolutions,
            // exchanges: [
            //     {
            //         value: "ACM",
            //         name: "ACM",
            //         desc: "ACM",
            //     },
            //     {
            //         // `exchange` argument for the `searchSymbols` method, if a user selects this exchange
            //         value: "Kraken",

            //         // filter name
            //         name: "Kraken",

            //         // full exchange name displayed in the filter popup
            //         desc: "Kraken bitcoin exchange",
            //     },
            // ],
            //volume_precision: 8,
            //data_status: "streaming",
            minmov2: 0,
            has_no_volume: true,
            //old
            // name: symbolName,
            // timezone: "Asia/Singapore",
            // pricescale: 1000000,
            // minmov: 1,
            // minmov2: 0,
            // ticker: "TEST:TEST",
            // description: "test",
            // session: "24x7",
            // type: "bitcoin",
            // "exchange-traded": "",
            // "exchange-listed": "",
            // has_intraday: true,
            // intraday_multipliers: ["1", "3", "5", "15", "60", "1D", "1W"],
            // has_weekly_and_monthly: false,
            // has_no_volume: true,
            // regular_session: "24x7",
        };

        // if (split_data[2].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
        //     symbol_stub.pricescale = 100;
        // }
        // symbol_stub.pricescale = 100;
        setTimeout(function () {
            onSymbolResolvedCallback(symbol_stub);
        }, 0);

        // onResolveErrorCallback('Not feeling it today')
    },
    getBars: function (
        symbolInfo,
        resolution,
        from,
        to,
        onHistoryCallback,
        onErrorCallback,
        firstDataRequest
    ) {
        // console.log('function args',arguments)
        // console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)
        historyProvider
            .getBars(symbolInfo, resolution, from, to, firstDataRequest)
            .then((bars) => {
                if (bars.length) {
                    onHistoryCallback(bars, { noData: false });
                } else {
                    onHistoryCallback(bars, { noData: true });
                }
            })
            .catch((err) => {
                onErrorCallback(err);
            });
    },
    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback
    ) => {},
    unsubscribeBars: (subscriberUID) => {},
    calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
        //optional

        // while optional, this makes sure we request 24 hours of minute data at a time
        // CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
        return resolution < 60
            ? { resolutionBack: "D", intervalBack: "1" }
            : undefined;
    },
    getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
        //optional
    },
    getTimeScaleMarks: (
        symbolInfo,
        startDate,
        endDate,
        onDataCallback,
        resolution
    ) => {
        //optional
    },
    getServerTime: (cb) => {},
};
