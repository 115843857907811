import React, { Component } from 'react';

import './index.scss';

import notFoundImg from '../../public/images/img-404-page-2.jpg';


class NotFound extends Component{
    state = {
        targetLocation: "/login"
    }

    UNSAFE_componentWillMount = () => {
        if (window.location.pathname.includes("/member/gateway")) {
            let str = window.location.pathname;
            let token = "";

            token = str.replace("/member/gateway/", "");

            sessionStorage.setItem("accessToken", token);

            window.location.href = "/home";
        }
        this.setState({
            targetLocation: (sessionStorage.getItem("accessToken")) ? "/home" : "/login"
        })
    }

    render() {
        return (
            <div className="page-not-found">
                <div className="pt-5">

                    <img src={notFoundImg} alt={'error404'} width={'100%'}/>

                </div>


            </div>
        )
    }
}

export default NotFound;
