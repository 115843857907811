import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { getLanguageFile } from "../../store/ducks/home/actions";
import { t, setLanguageFileJson } from "../../common/translation";
import PasswordInput from "../../components/input/PasswordInput";
import PageHeader from "../../components/PageHeaderV2";

import { postChangeSecondaryPassword } from "../../store/ducks/changeSecondaryPassword/actions";

import { fieldChecking, GenHash } from "../../common/helpers";
import { formValidation } from "../../common/validator/formValidation";

import "./changeSecondaryPassword.scss";
import { Link } from "react-router-dom";
import HyperModal from "../../components/HyperModal";

class changeSecondaryPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transLabel: "changeTradingPassword.",
            currentPassword: "",
            password: "",
            confirmPassword: "",
            errType: "",
            modal_visible: false,
            loading_visible: this.props.loading_visible,
            modal_type: "",
            modal_msg: "",
        };

        this.handlePassword = this.handlePassword.bind(this);
        this.handleCurrentPassword = this.handleCurrentPassword.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.handleSubmitChangePassword = this.handleSubmitChangePassword.bind(
            this
        );
        this.handleTradingPwdShow = this.handleTradingPwdShow.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `70px`);
        //  setLocale(sessionStorage.getItem("lang"));
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `70px`);
        });
        formValidation("change-secondary-password-form");
        this.props.getLanguageFile(sessionStorage.getItem("lang"));
    }

    handleTradingPwdShow(event) {
        this.setState({ changeTradingPwdShow: true });
    }

    handlePassword(event) {
        this.setState({ password: event.target.value });
    }

    handleCurrentPassword(event) {
        this.setState({ currentPassword: event.target.value });
    }

    handleConfirmPassword(event) {
        this.setState({ confirmPassword: event.target.value });
    }

    handleSubmitChangePassword(event) {
        event.preventDefault();

        let validate = fieldChecking(
            document.getElementById("change-secondary-password-form")
        );

        if (validate === false) {
            return false;
        }

        this.setState({
            loading_visible: true,
        });

        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.currentSecondaryPassword = this.state.currentPassword;
        data.newSecondaryPassword = this.state.password;
        data.confirmNewSecondaryPassword = this.state.confirmPassword;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postChangeSecondaryPassword(data);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.language_data !== this.props.language_data) {
            setLanguageFileJson();
            // this.setState({
            //     rerender: true,
            // });
        }
        if (
            nextProps.changeSecondaryPasswordData !==
            this.props.changeSecondaryPasswordData
        ) {
            if (nextProps.changeSecondaryPasswordData.rst !== "1") {
                this.setState({
                    modal_msg: nextProps.changeSecondaryPasswordData.msg,
                    modal_type: "error",
                    modal_visible: true,
                    loading_visible: false,
                });
            } else {
                this.setState({
                    modal_msg: nextProps.changeSecondaryPasswordData.msg,
                    modal_type: "success",
                    modal_visible: true,
                    loading_visible: false,
                });
            }
        }
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false, modal_gcox: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/profile");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    render() {
        const {
            transLabel,
            loading_visible,
            modal_visible,
            modal_type,
            modal_msg,
        } = this.state;

        return (
            <div className="changeTradingPassword">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack={"/profile"}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("changeTradingPassword.change_trading_password")}
                    arrowLeft={true}
                />

                <HyperModal
                    category="response"
                    modalVisible={loading_visible}
                    modalType="loading"
                    footer={[]}
                />

                <HyperModal
                    category="response"
                    modalVisible={modal_visible}
                    modalType={modal_type}
                    modalMsg={modal_msg}
                    modalCloseFunc={this.handleModalClose}
                    cancelFunc={this.handleModalClose}
                />

                <div className="pt-4 px-4 offset-md-2 col-md-8 overflow-auto custom-height">
                    <form
                        method="post"
                        id="change-secondary-password-form"
                        className="txt-black needs-validation"
                        noValidate
                        onSubmit={this.handleSubmitChangePassword}
                    >
                        <div className="form-group">
                            <label className="depth-gray-label text-uppercase">
                                {t(transLabel + "current_trading_password")}*
                            </label>
                            <PasswordInput
                                id="currentPassword"
                                name="currentPassword"
                                onChange={this.handleCurrentPassword}
                                value={this.state.currentPassword}
                                placeholder={t(
                                    transLabel +
                                        "please_enter_current_trading_password"
                                )}
                                className="form-control depth-input"
                                requiredMsg={t("validator.requiredMsg")}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="confirm_password"
                                className="depth-gray-label text-uppercase"
                            >
                                {t(transLabel + "trading_password")}*
                            </label>
                            <PasswordInput
                                id="password"
                                name="password"
                                onChange={this.handlePassword}
                                value={this.state.password}
                                placeholder={t(
                                    transLabel + "please_enter_trading_password"
                                )}
                                className="form-control depth-input"
                                requiredMsg={t("validator.requiredMsg")}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="confirm_password"
                                className="depth-gray-label text-uppercase"
                            >
                                {t(transLabel + "confirm_trading_password")}*
                            </label>
                            <PasswordInput
                                id="confirm_password"
                                name="confirm_password"
                                onChange={this.handleConfirmPassword}
                                value={this.state.confirmPassword}
                                placeholder={t(
                                    transLabel +
                                        "please_reenter_trading_password"
                                )}
                                className="form-control depth-input"
                                oldPassword={this.state.password}
                                confirmpassword="true"
                                confirmPasswordMsg={t(
                                    "changeTradingPassword.checkPasswordMatch"
                                )}
                                requiredMsg={t("validator.requiredMsg")}
                                required
                            />
                        </div>

                        <div className="form-group text-right">
                            <button
                                type="submit"
                                className="col-12 depth-blue-btn"
                            >
                                {t(transLabel + "save")}
                            </button>
                        </div>
                        <div className="row text-center">
                            <div className="col-12 pt-2">
                                <Link
                                    to="/secondaryPassword/reset"
                                    className="forgot-password"
                                >
                                    {t(
                                        "changeTradingPassword.forgot_trading_password"
                                    )}
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { changeSecondaryPassword, walletBalance } = state;

    return {
        changeSecondaryPasswordData:
            changeSecondaryPassword.changeSecondaryPasswordData,
        language_data: walletBalance.getLanguageFile,
    };
};

const mapDispatchToProps = {
    postChangeSecondaryPassword,
    getLanguageFile,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(changeSecondaryPassword);
