import React, { Component } from "react";
import { connect } from "react-redux";
// import {setLocale, t} from 'react-i18nify';
import InfiniteScroll from "react-infinite-scroller";
import { t } from "../../common/translation";
import PageHeader from "../../components/PageHeader";
import LoadingMask from "../../components/loadingMask";
import Modal from "../../components/modal";
import ErrorIcon from "../../components/ErrorAlert";

import { postWalletBalance } from "../../store/ducks/home/actions";
import { getStakingHistory } from "../../store/ducks/walletDetails/actions";

import "./stakingHistory.scss";

class StakingHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            transaction_data: [],
            expandedRows: [],
            depositStatus: 0,
            transferStatus: 0,
            withdrawalStatus: 0,
            convertStatus: 0,
            stakingStatus: 0,
            loading: true,
            loadingText: true,
            totalPage: 0,
            currentPage: 1,
            walletType: "",
            balance: "0.00000000",
            hasMoreItems: true,
            showErrorModal: false,
            totalStakingAmount: "0",
            errMsg: "",
            errType: "",
        };

        this.handleRowClick = this.handleRowClick.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
    }

    loadMoreList(e, page) {
        const trans = {};
        let currentPage = parseInt(this.state.currentPage) + 1;
        // const { walletType } = this.props.location.state === undefined ? "" : this.props.location.state;

        trans.username = sessionStorage.getItem("email");
        trans.page = currentPage;
        trans.type = this.props.location.state.walletType;
        if (currentPage >= this.state.totalPage) {
            this.setState({
                hasMoreItems: false,
                loading: false,
            });
        }

        this.props.getStakingHistory(trans);
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    handleRowClick(rowId) {
        const currentExpandedRows = this.state.expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

        const newExpandedRows = isRowCurrentlyExpanded
            ? currentExpandedRows.filter((id) => id !== rowId)
            : currentExpandedRows.concat(rowId);

        this.setState({ expandedRows: newExpandedRows });
    }

    renderItem(item, i) {
        const itemRows = [
            <li className="list-group-item asset-panel-list-group-item" key={i}>
                <div className="pl-4">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div
                                className="fa-pull-left"
                                style={{ color: "#25BD2B" }}
                            >
                                <small>
                                    <b>
                                        {window.location.search.slice(1, 10)}{" "}
                                        {item.total_amount}
                                    </b>
                                </small>
                            </div>
                            <div className="fa-pull-right">
                                <small>
                                    <b>{item.created_at + " "}</b>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 pl-0 fa-pull-left">
                            <small>
                                <b>
                                    {t("walletDetails.rate_desc")}
                                    {item.price_rate}
                                </b>
                            </small>
                        </div>
                        {item.blk_explorer && (
                            <div className="col-6 pl-0 text-right">
                                <small>
                                    <a
                                        href={item.blk_explorer}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {" "}
                                        {t("walletDetails.view_on_explorer")}
                                    </a>
                                </small>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-12 pl-0">
                            <small>
                                <b>{item.remark}</b>
                            </small>
                        </div>
                    </div>
                </div>
            </li>,
        ];

        return itemRows;
    }

    componentDidMount() {
        //setLocale(sessionStorage.getItem("lang"));

        const trans = {};

        trans.username = sessionStorage.getItem("email");
        trans.page = 1;
        trans.type = this.props.location.state.walletType;
        this.props.getStakingHistory(trans);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data !== this.props.data) {
            //Check login success update the routes for react
            if (nextProps.data.rst === "1") {
                let array;
                array = nextProps.data.data;

                this.setState({ data: array.walletBalance[0] });
                this.setState({
                    depositStatus: array.walletSetupList[0].depositStatus,
                });
                this.setState({
                    transferStatus: array.walletSetupList[0].transferStatus,
                });
                this.setState({
                    withdrawalStatus: array.walletSetupList[0].withdrawStatus,
                });
                this.setState({
                    stakingStatus: array.walletSetupList[0].stakingStatus,
                });
                this.setState({
                    convertStatus: array.walletSetupList[0].convertStatus,
                });
                this.setState({
                    totalStakingAmount:
                        array.walletBalance[0].total_staking_amount,
                });
            } else {
                switch (nextProps.data.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                        this.setState({
                            errMsg: nextProps.data.msg,
                        });
                        this.props.openModal("error-modal");
                        break;
                }
            }
        }

        if (nextProps.transaction_list !== this.props.transaction_list) {
            if (nextProps.transaction_list.rst === 1) {
                let array;

                array = this.state.transaction_data;
                nextProps.transaction_list.data.currentPageItems.map(function (
                    item,
                    i
                ) {
                    array.push(item);

                    return array;
                });

                if (
                    this.state.currentPage ===
                    nextProps.transaction_list.data.totalPage
                ) {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                if (nextProps.transaction_list.data.totalPage === "0") {
                    this.setState({
                        hasMoreItems: false,
                    });
                }

                this.setState({ loading: false });
                this.setState({ transaction_data: array });
                this.setState({
                    totalPage: nextProps.transaction_list.data.totalPage,
                });
                this.setState({
                    currentPage: nextProps.transaction_list.data.currentPage,
                });
            } else {
                switch (nextProps.transaction_list.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                        this.setState({
                            errMsg: nextProps.transaction_list.msg,
                        });
                        this.props.openModal("error-modal");
                        break;
                }
            }
        }
    }

    render() {
        const {
            transaction_data,
            /*withdrawalStatus,*/
            loading,
            totalPage,
            currentPage,
            showErrorModal,
            errMsg,
        } = this.state;

        let allItemRows = [];

        transaction_data.map((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);

            return allItemRows;
        });

        return (
            <div>
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}
                <PageHeader
                    subTitle={t("walletDetails.staking_history")}
                    navClass="title white-color"
                    backgroundHeight={80}
                    subPageHistoryBack={"/home"}
                    walletType={""}
                    arrowLeft={true}
                />

                <div className="walletDetails">
                    <div>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadMoreList}
                            hasMore={this.state.hasMoreItems}
                            loader={
                                (!loading && totalPage === 1) ||
                                (!loading && currentPage === totalPage) ? (
                                    ""
                                ) : (
                                    <div className="row" key={0}>
                                        <div className="mx-auto">
                                            <LoadingMask width={80} />
                                        </div>
                                    </div>
                                )
                            }
                            initialLoad={false}
                            threshold={15}
                        >
                            {
                                <aside className="list-group">
                                    {allItemRows}
                                </aside>
                            }
                        </InfiniteScroll>
                    </div>

                    {((!loading && totalPage === 1) ||
                        (!loading && currentPage === totalPage)) && (
                        <div className="col-12">
                            <div className="row">
                                <div className="mx-auto">
                                    <span>
                                        <small>
                                            <b>
                                                {t(
                                                    "walletDetails.end_of_record"
                                                )}
                                            </b>
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {!loading && totalPage < 1 && (
                        <div className="col-12">
                            <div className="row">
                                <div className="mx-auto">
                                    <span>
                                        <small>
                                            <b>
                                                {t("walletDetails.no_record")}
                                            </b>
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, walletDetails } = state;

    return {
        data: walletBalance.data,
        transaction_list: walletDetails.data,
    };
};

const mapDispatchToProps = {
    postWalletBalance,
    getStakingHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(StakingHistory);
