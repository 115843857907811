import React, { Component } from "react";
import { setLocale, t } from "react-i18nify";
import { connect } from "react-redux";

import PageHeader from "../../../components/PageHeader";
import NumberInput from "../../../components/input/NumberInput";
import PasswordInput from "../../../components/input/PasswordInput";
import Modal from "../../../components/modal";
import LoadingMask from "../../../components/loadingMask";
import ErrorIcon from "../../../components/ErrorAlert";
import SuccessIcon from "../../../components/SuccessAlert";
import ConfirmationIcon from "../../../components/ConfirmationAlert";

import { postWalletBalance } from "../../../store/ducks/home/actions";
import { postWithdrawal } from "../../../store/ducks/withdrawal/actions";
import { postStaking } from "../../../store/ducks/staking/actions";
import { openModal, closeModal } from "../../../store/ducks/modal/actions";

import { formValidation } from "../../../common/validator/formValidation";
import {
    amountChecking8,
    fieldChecking,
    GenHash,
    delimiter,
} from "../../../common/helpers";

import usdIcon from "../../../public/images/crypto/coin-USD.png";

import "./topup.scss";

class TopUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            remaining: 0,
            s_password: "",
            address: "",
            amount: "",
            minAmount: "100",
            maxAmount: "",
            balance: "0",
            walletType: "",
            showLoadingModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            errMsg: "",
            errType: "",
            successMsg: "",
            withdrawAdminFee: "",
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmitWithdrawal = this.handleSubmitWithdrawal.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleSubmitWithdrawalConfirmation = this.handleSubmitWithdrawalConfirmation.bind(
            this
        );
        this.handleSubmitWithdrawalClose = this.handleSubmitWithdrawalClose.bind(
            this
        );
    }

    componentDidMount() {
        formValidation("withdrawal-form");
        setLocale(sessionStorage.getItem("lang"));

        if (this.props.location.state !== undefined) {
            const data = {};

            data.username = sessionStorage.getItem("email");
            data.token = sessionStorage.getItem("token");
            data.walletType = this.props.location.state.walletFrom;

            let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);

            data.hash = hash;

            this.props.postWalletBalance(data);
        } else {
            this.props.history.push({
                pathname: "/home",
                state: { error: "invalid_wallet_type_details" },
            });
        }
    }

    async handleSubmitWithdrawalConfirmation(event) {
        event.preventDefault();

        var form = document.getElementById("withdrawal-form");
        var validate = fieldChecking(form);

        if (validate === false) {
            return false;
        }

        this.props.openModal("withdrawal-confirmation-modal");
    }

    handleSubmitWithdrawalClose(event) {
        event.preventDefault();

        this.props.closeModal("withdrawal-confirmation-modal");
    }

    handleSubmitWithdrawal(event) {
        event.preventDefault("withdrawal-form");

        const data = [];
        let hash = "";

        data["amount"] = this.state.amount;
        data["secondaryPassword"] = this.state.s_password;
        data["token"] = sessionStorage.getItem("token");
        data["username"] = sessionStorage.getItem("email");
        data["salesType"] = "ACMV";
        hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data["hash"] = hash;

        this.setState({
            showConfirmationModal: false,
        });

        this.props.openModal("withdrawal-loading-modal");

        this.props.postStaking(data);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName ===
                "withdrawal-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName ===
                "withdrawal-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.data !== this.props.data) {
            //Check login success update the routes for react
            if (nextProps.data.rst === "1") {
                let array;
                array = nextProps.data.data;

                this.setState({
                    data: array.walletBalance[0],
                    maxAmount: nextProps.data.data.walletBalance[0].amount,
                    minAmount:
                        nextProps.data.data.walletSetupList[0].withdrawSetting
                            .withdrawMin,
                    withdrawAdminFee:
                        nextProps.data.data.walletSetupList[0].withdrawSetting
                            .withdrawAdminFee,
                });
            } else {
                switch (nextProps.data.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                }
            }
        }

        if (nextProps.staking_data !== this.props.staking_data) {
            let msg = "";

            if (nextProps.staking_data.rst === "1") {
                const data = {};
                data.username = sessionStorage.getItem("email");
                data.token = sessionStorage.getItem("token");
                data.walletType = this.props.location.state.walletFrom;

                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;

                this.setState({
                    amount: "",
                    s_password: "",
                    showLoadingModal: false,
                    successMsg: t("staking." + nextProps.staking_data.msg),
                });

                this.props.openModal("success-modal");

                this.props.postWalletBalance(data);
            } else {
                msg = t("staking." + nextProps.staking_data.msg);
                this.setState({
                    showLoadingModal: false,
                    errMsg: msg,
                });

                this.props.openModal("error-modal");
            }
        }
    }

    onChange(e) {
        const { name, value } = e.target;

        if (
            (name === "amount" && amountChecking8(value)) ||
            name !== "amount"
        ) {
            this.setState({ [name]: value });

            if (name === "amount") {
                if (this.props.location.state !== undefined) {
                    this.props.location.state.paymentSettings.map(function (
                        item,
                        i
                    ) {
                        item.amount = value * item.payment_percentage;
                        return null;
                    });
                }
            }
        }
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/home");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    render() {
        const {
            data,
            balance,
            showLoadingModal,
            showErrorModal,
            errMsg,
            successMsg,
            showSuccessModal,
            showConfirmationModal,
        } = this.state;
        let paymentSettings;

        if (this.props.location.state !== undefined) {
            paymentSettings = this.props.location.state.paymentSettings.map(
                function (item, i) {
                    let amount = item.amount === undefined ? 0 : item.amount;

                    return (
                        <div className="row">
                            <small className="col-6 pl-0">
                                {item.wallet_type}
                            </small>
                            <small className="col-6 pl-0 text-right">
                                {amount.toFixed(2)}
                            </small>
                        </div>
                    );
                }
            );
        } else {
            this.props.history.push({
                pathname: "/home",
                state: { error: "invalid_wallet_type_details" },
            });
        }

        return (
            <div id="withdrawal">
                <PageHeader
                    title={t("topup.topup")}
                    titleHeader="col-12 text-center"
                    backgroundHeight={210}
                    prevousPageLink=""
                    navClass=""
                    historyBack="/home"
                    historyBackClass="pt-2 sub-title-div"
                    avatarDivClass="text-center header"
                    avatarLevel="col-12 title white-color"
                    walletLabel="avatar-star mx-auto"
                    walletType={
                        this.props.location.state === undefined
                            ? data.walletTypeCode
                                ? data.walletTypeCode
                                : ""
                            : this.props.location.state.walletFrom
                    }
                    balance={data.amount ? data.amount : balance}
                    imgLink={usdIcon}
                    arrowLeft={true}
                />

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="withdrawal-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("transfer.confirmation_msg")}</h4>
                                </div>
                            </div>
                        }
                        closeText={t("purchasePin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={this.handleSubmitWithdrawalClose}
                        closeId=""
                        submitText={t("purchasePin.modal_submit")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSubmitWithdrawal}
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="withdrawal-confirmation-modal"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div className="p-4 form-bg-transparent">
                    <form
                        method="post"
                        id="withdrawal-form"
                        className="form-bg-transparent txt-black needs-validation"
                        noValidate
                        onSubmit={this.handleSubmitWithdrawalConfirmation}
                    >
                        {/*<div className="col-12">*/}
                        {/*    <div className="row">*/}
                        {/*        <label className="form-label text-uppercase">{t('withdrawal.to_address')}</label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="col-12">*/}
                        {/*    <div className="row">*/}
                        {/*        <StringInput*/}
                        {/*            id="address"*/}
                        {/*            name="address"*/}
                        {/*            className="remove-placeholder"*/}
                        {/*            onChange={this.onChange}*/}
                        {/*            value={this.state.address}*/}
                        {/*            placeholder={t('withdrawal.enter_address')}*/}
                        {/*            requiredMsg={t('validator.requiredMsg')}*/}
                        {/*            required*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="col-12 pt-3">
                            <div className="row">
                                <label className="form-label text-uppercase">
                                    {t("withdrawal.amount")} (
                                    {t(
                                        "topup." + data.walletTypeCode + "_used"
                                    )}
                                    )
                                </label>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <NumberInput
                                    id="amount"
                                    name="amount"
                                    onChange={this.onChange}
                                    value={this.state.amount}
                                    min={delimiter(this.state.minAmount)}
                                    max={delimiter(this.state.maxAmount)}
                                    onBlur={this.onAmountBlur}
                                    placeholder="0.00000000"
                                    className="remove-placeholder"
                                    requiredMsg={t("validator.requiredMsg")}
                                    textLessThan={t(
                                        "validator.amount_should_less_than"
                                    )}
                                    textMoreThan={t(
                                        "validator.amount_should_more_than"
                                    )}
                                    blockTen={
                                        this.props.location.state === undefined
                                            ? false
                                            : this.props.location.state
                                                  .walletFrom === "USDT"
                                            ? t("validator.block_of_10")
                                            : false
                                    }
                                    required
                                />
                                <p className="subscribe-note">
                                    {t("withdrawal.minimum")}:{" "}
                                    {this.state.minAmount}
                                </p>
                            </div>
                        </div>

                        {this.props.location.state !== undefined &&
                            this.props.location.state.paymentSettings.length >
                                0 && (
                                <div className="col-12">
                                    <small className="row pl-0 text-danger">
                                        {t(
                                            "withdrawal.amount_will_be_deducted"
                                        )}
                                        :
                                    </small>
                                    {paymentSettings}
                                </div>
                            )}

                        <div className="col-12 pt-3">
                            <div className="row">
                                <label className="form-label text-uppercase">
                                    {t("withdrawal.secondary_password")}
                                </label>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <PasswordInput
                                    id="s_password"
                                    name="s_password"
                                    onChange={this.onChange}
                                    value={this.state.s_password}
                                    placeholder={t(
                                        "withdrawal.secondary_password"
                                    )}
                                    className="remove-placeholder"
                                    requiredMsg={t("validator.requiredMsg")}
                                    required
                                />
                            </div>
                        </div>

                        {/*<div className="col-12 pt-3">*/}
                        {/*    <div className="row">*/}
                        {/*        <p className="subscribe-note">{t('withdrawal.admin_fee', { amount: withdrawAdminFee, walletType: this.props.location.state.walletType })}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="pt-4">
                            <button
                                type="submit"
                                className="col-12 btn btn-primary"
                            >
                                {t("withdrawal.submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, withdrawal, modal, staking } = state;

    return {
        data: walletBalance.data,
        withdrawal_data: withdrawal.data,
        staking_data: staking.data,
        classIsShowing: modal.classIsShowing,
    };
};

const mapDispatchToProps = {
    postWalletBalance,
    openModal,
    closeModal,
    postWithdrawal,
    postStaking,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopUp);
