import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";

import * as reducers from "./ducks/index";
import Raven from "raven-js";
import axios from "axios";
import * as type from "./ducks/home/types";
import { isLoadingAction, getLanguageFile } from "./ducks/home/actions";

let loading = false;
let numRequest = 0;

const rootReducer = (state, action) => {
    return combineReducers(reducers)(state, action);
};

// const crashReporter = (store) => (next) => (action) => {
//     try {
//         if (action.payload.msg === "invalid_token") {
//             // sessionStorage.removeItem("accessToken");
//             // sessionStorage.removeItem("refreshToken");
//             sessionStorage.clear();
//             setTimeout(function () {}, 500);
//             window.location.href = "/login";
//         }
//
//         return next(action);
//     } catch (err) {
//         console.error("Caught an exception!", err);
//         Raven.captureException(err, {
//             extra: {
//                 action,
//                 state: store.getState(),
//             },
//         });
//         throw err;
//     }
// };

const storejs = createStore(
    rootReducer,
    applyMiddleware(thunkMiddleware)
);

//try here

//storejs.dispatch(getLanguageFile(sessionStorage.getItem("lang")));

axios.interceptors.request.use((res) => {
    numRequest++;
    if (!loading) {
        storejs.dispatch(isLoadingAction(true));
    }
    return res;
});

axios.interceptors.response.use(
    (res) => {
        numRequest--;
        if (numRequest < 1) {
            storejs.dispatch(isLoadingAction(false));
        }
        return res;
    },
    (err) => {
        if (err.response.status === 401) {
            console.log("401", err.response);
            sessionStorage.clear();
            setTimeout(function () {}, 500);
            window.location.href = "/login";
        }
    }
);

export default storejs;
