import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale, t } from "react-i18nify";

import PageHeader from "../../components/PageHeader";
// import { Carousel } from 'antd';
// import product1 from "../../public/product-image/product1.jpg";
// import product2 from "../../public/product-image/product2.jpg";
// import product3 from "../../public/product-image/product3.jpg";
// import product4 from "../../public/product-image/product4.jpg";
// import starOnIcon from "../../public/images/star-off@3x.png";
// import starOffIcon from "../../public/images/star-off@3x.png";
// import favoriteIcon from "../../public/images/icon-Heart-off@3x.png";
// import shareIcon from "../../public/images/icon-share@3x.png";
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Input, Icon, message } from "antd";
// import InfiniteScroll from 'react-infinite-scroller';
//
// import PageHeader from '../../components/PageHeader';
// import TransPanel from '../../components/transPanel';
// import LoadingMask from '../../components/loadingMask';
// import Modal from "../../components/modal";
// import ErrorIcon from "../../components/ErrorAlert";

import {
    getCarts,
    postDeleteItem,
    postUpdateMemCart,
} from "../../store/ducks/myCart/actions";

import "./myCart.scss";

import deleteIcon from "../../public/images/icon-bin@3x.png";
import arrowUp from "../../public/arrow-up@3x.png";
import arrowDown from "../../public/arrow-down@3x.png";
import { Link } from "react-router-dom";
import { GenHash } from "../../common/helpers";
import Modal from "../../components/modal";
import LoadingMask from "../../components/loadingMask";
import { closeModal, openModal } from "../../store/ducks/modal/actions";
import ConfirmationIcon from "../../components/ConfirmationAlert";

class MyCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialAmount: 1,
            amount: 1,
            checkOutShow: false,
            headerHeight: window.innerWidth,
            showLoadingModal: false,
            showConfirmationModal: false,
            cartItemsList: [],
            cartQuantity: [],
            priceList: [],
            deletedItem: "",
            dataDel: "",
            checkedStatus: true,
            screenHeight: window.innerHeight - (96 + 80),
        };

        this.crousel = React.createRef();

        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.handleCheckOutOnOff = this.handleCheckOutOnOff.bind(this);
        this.onTest = this.onTest.bind(this);
        this.handleDelItem = this.handleDelItem.bind(this);
        this.handleCheckout = this.handleCheckout.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleGoShoppingClick = this.handleGoShoppingClick.bind(this);
        this.handleCartDelClose = this.handleCartDelClose.bind(this);
        this.handleDelConfirmation = this.handleDelConfirmation.bind(this);
        this.handleUpdateMemberCartData = this.handleUpdateMemberCartData.bind(
            this
        );
    }

    componentWillMount() {
        this.setState({ showLoadingModal: true });

        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.lang = sessionStorage.getItem("lang");

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.getCarts(data);
    }

    componentDidMount() {
        setLocale(sessionStorage.getItem("lang"));

        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName ===
                "delete-item-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (
                nextProps.classIsShowing.modalName ===
                "del-cart-confirmation-modal"
            ) {
                this.setState({
                    showConfirmationModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.delItem !== this.props.delItem) {
            if (nextProps.delItem.rst === "1") {
                let deletedPid = this.state.deletedItem;
                this.setState({
                    showLoadingModal: false,
                    cartItemsList: this.state.cartItemsList.filter(
                        (item) => item.pId !== deletedPid
                    ),
                    deletedItem: "",
                });
            }
        }

        if (nextProps.updateCarts !== this.props.updateCarts) {
            if (nextProps.updateCarts.rst === "1") {
                this.props.history.push("/emall/delivery");
            } else {
                message.config({
                    top: "80%",
                });

                message.error(nextProps.updateCarts.msg);
            }
        }

        if (nextProps.carts !== this.props.carts) {
            if (nextProps.carts.rst === "1") {
                let item_arry = [];

                for (const [i, item] of nextProps.carts.data.data.entries()) {
                    item.checked = false;
                    item_arry[i] = item;
                }

                this.setState({
                    cartItemsList: item_arry,
                    showLoadingModal: false,
                    checkedStatus: true,
                });
            }
        }
    }

    handleUpdateMemberCartData(e) {
        // const data = {};
        // data.username = sessionStorage.getItem('email');
        // data.product_code = sessionStorage.getItem('token');
        // data.variant_opt = sessionStorage.getItem('lang');
        // data.quantity = this.state.dataDel;
        //
        // this.setState({ deletedItem: data.cart_item });
        //
        // let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        // data.hash = hash;
        //
        // this.setState({
        //     showConfirmationModal: false,
        // });
        //
        // this.props.postDeleteItem(data);
    }

    handleGoShoppingClick(e) {
        this.props.history.push("/emall");
    }

    handleCartDelClose(e) {
        this.props.closeModal("del-cart-confirmation-modal");
    }

    handleCheckout(e) {
        const data = {};
        let item_arry = [];

        for (const [i, item] of this.state.cartItemsList.entries()) {
            if (item.checked === true) {
                item_arry[i] = {
                    item_id: item.pId,
                    code: item.code,
                    quantity: item.quantity,
                };
            }
        }

        sessionStorage.setItem("checkout_items", JSON.stringify(item_arry));

        data.username = sessionStorage.getItem("email");
        data.checkoutList = JSON.stringify(this.state.cartItemsList);
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postUpdateMemCart(data);
    }

    handleChecked(e) {
        let item_arry = [];
        let price_arry = [];
        let status = true;

        for (const [i, item] of this.state.cartItemsList.entries()) {
            if (item.pId === e.target.getAttribute("data-pid")) {
                item.checked = e.target.checked;
            }

            if (item.checked === true) {
                status = false;
            }

            item_arry[i] = item;

            if (item.checked) {
                for (const [priceKey, priceItem] of item.price.entries()) {
                    if (priceKey >= 0 && price_arry.length === 0) {
                        price_arry.push({
                            amount: item.quantity * priceItem.unit_price,
                            display_code: priceItem.b_display_code,
                        });
                    } else {
                        let status = 0;
                        for (const [pKey, pItem] of price_arry.entries()) {
                            if (
                                pItem.display_code === priceItem.b_display_code
                            ) {
                                price_arry[pKey]["amount"] +=
                                    item.quantity * priceItem.unit_price;
                                status = 1;
                            }
                        }

                        if (status === 0) {
                            price_arry.push({
                                amount: item.quantity * priceItem.unit_price,
                                display_code: priceItem.b_display_code,
                            });
                        }
                    }
                }
            }
        }

        this.setState({
            cartItemList: item_arry,
            checkedStatus:
                this.state.cartItemsList.length === 0 ? false : status,
            priceList: price_arry,
        });
    }

    async handleDelConfirmation(e) {
        e.preventDefault();

        this.setState({ dataDel: e.target.getAttribute("data-pid") });

        this.props.openModal("del-cart-confirmation-modal");
    }

    handleDelItem(e) {
        this.props.openModal("delete-item-loading-modal");

        const data = {};

        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.lang = sessionStorage.getItem("lang");
        data.cart_item = this.state.dataDel;

        this.setState({ deletedItem: data.cart_item });

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({
            showConfirmationModal: false,
        });

        this.props.postDeleteItem(data);
    }

    onChange(e) {
        let item_arry = [];
        let price_arry = [];
        // let amount = this.state.amount;
        for (const [i, item] of this.state.cartItemsList.entries()) {
            if (i >= 0 && item.pId === e.target.getAttribute("data-pid")) {
                if (e.target.value > item.max_qty) {
                    item.quantity = item.max_qty;
                } else if (e.target.value === "" && item.max_qty !== 0) {
                    item.quantity = 1;
                } else if (e.target.value === "" && item.max_qty === 0) {
                    item.quantity = 0;
                } else {
                    item.quantity = e.target.value;
                }
            }

            item_arry.push(item);

            if (item.checked) {
                for (const [priceKey, priceItem] of item.price.entries()) {
                    if (priceKey >= 0 && price_arry.length === 0) {
                        price_arry.push({
                            amount: item.quantity * priceItem.unit_price,
                            display_code: priceItem.b_display_code,
                        });
                    } else {
                        let status = 0;
                        for (const [pKey, pItem] of price_arry.entries()) {
                            if (
                                pItem.display_code === priceItem.b_display_code
                            ) {
                                price_arry[pKey]["amount"] +=
                                    item.quantity * priceItem.unit_price;
                                status = 1;
                            }
                        }

                        if (status === 0) {
                            price_arry.push({
                                amount: item.quantity * priceItem.unit_price,
                                display_code: priceItem.b_display_code,
                            });
                        }
                    }
                }
            }
        }

        this.setState({
            cartItemList: item_arry,
            priceList: price_arry,
        });
    }

    onChangeAmount(e) {
        let item_arry = [];
        let price_arry = [];
        let amount = this.state.amount;
        if (e.target.id === "plus") {
            for (const [i, item] of this.state.cartItemsList.entries()) {
                if (i >= 0 && item.pId === e.target.getAttribute("data-pid")) {
                    if (item.quantity + 1 > item.max_qty) {
                        item.quantity = item.max_qty;
                    } else if (item.max_qty === 0) {
                        item.quantity = 0;
                    } else {
                        item.quantity++;
                    }
                }

                item_arry.push(item);

                if (item.checked) {
                    for (const [priceKey, priceItem] of item.price.entries()) {
                        if (priceKey >= 0 && price_arry.length === 0) {
                            price_arry.push({
                                amount: item.quantity * priceItem.unit_price,
                                display_code: priceItem.b_display_code,
                            });
                        } else {
                            let status = 0;
                            for (const [pKey, pItem] of price_arry.entries()) {
                                if (
                                    pItem.display_code ===
                                    priceItem.b_display_code
                                ) {
                                    price_arry[pKey]["amount"] +=
                                        item.quantity * priceItem.unit_price;
                                    status = 1;
                                }
                            }

                            if (status === 0) {
                                price_arry.push({
                                    amount:
                                        item.quantity * priceItem.unit_price,
                                    display_code: priceItem.b_display_code,
                                });
                            }
                        }
                    }
                }
            }
        } else if (e.target.id === "minus" && amount !== 0) {
            this.state.cartItemsList.map(function (item, i) {
                if (i >= 0 && item.pId === e.target.getAttribute("data-pid")) {
                    if (item.max_qty === 0) {
                        item.quantity = 0;
                    } else if (item.max_qty > 0 && item.quantity - 1 < 1) {
                        item.quantity = 1;
                    } else {
                        item.quantity--;
                    }
                }

                item_arry.push(item);

                if (item.checked) {
                    for (const [priceKey, priceItem] of item.price.entries()) {
                        if (priceKey >= 0 && price_arry.length === 0) {
                            price_arry.push({
                                amount: item.quantity * priceItem.unit_price,
                                display_code: priceItem.b_display_code,
                            });
                        } else {
                            let status = 0;
                            for (const [pKey, pItem] of price_arry.entries()) {
                                if (
                                    pItem.display_code ===
                                    priceItem.b_display_code
                                ) {
                                    price_arry[pKey]["amount"] +=
                                        item.quantity * priceItem.unit_price;
                                    status = 1;
                                }
                            }

                            if (status === 0) {
                                price_arry.push({
                                    amount:
                                        item.quantity * priceItem.unit_price,
                                    display_code: priceItem.b_display_code,
                                });
                            }
                        }
                    }
                }
                return null;
            });
        }

        this.setState({
            cartItemList: item_arry,
            priceList: price_arry,
        });
    }

    updateHeight() {
        if (window.innerWidth <= 768) {
            this.setState({ headerHeight: window.innerWidth });
        } else {
            this.setState({ headerHeight: 768 });
        }
    }

    onTest(e) {
        this.crousel.goTo(1);
    }

    handleCheckOutOnOff(e, show) {
        this.setState({ checkOutShow: show === true ? false : true });
    }

    render() {
        const {
            /*initialAmount, amount,*/ showConfirmationModal,
            checkOutShow,
            screenHeight,
            cartItemsList,
            priceList,
            showLoadingModal,
            checkedStatus,
        } = this.state;

        // const delEvent = this.handleDelItem;

        const changeAmount = this.onChangeAmount;

        const onChange = this.onChange;

        const checkedItem = this.handleChecked;

        const delConfirmation = this.handleDelConfirmation;

        let cartItems = cartItemsList.map(function (item, i) {
            const buttonPlus = (
                <Icon
                    type="plus"
                    id="plus"
                    data-pid={item.pId}
                    onClick={changeAmount}
                />
            );

            const buttonMinus = (
                <Icon
                    type="minus"
                    id="minus"
                    data-pid={item.pId}
                    onClick={changeAmount}
                />
            );

            let prices = item.price.map(function (pItem, p) {
                return (
                    <small key={p}>
                        <b>
                            {pItem.b_display_code}&nbsp;{pItem.unit_price}
                            {item.price.length !== p + 1 ? "," : ""}&nbsp;
                        </b>
                    </small>
                );
            });

            let variant_str = "";

            if (item.variant1 !== "") {
                variant_str = item.variant_group1 + ": " + item.variant1;
            }

            if (item.variant2 !== "") {
                variant_str += " " + item.variant_group2 + ": " + item.variant2;
            }

            if (item.variant3 !== "") {
                variant_str += " " + item.variant_group3 + ": " + item.variant3;
            }

            return (
                <li
                    className="list-group-item asset-panel-list-group-item col-12"
                    key={i}
                >
                    <div className="row">
                        <div className="col-1 fa-pull-left px-0 text-center">
                            <input
                                type="checkbox"
                                className=""
                                data-pid={item.pId}
                                checked={
                                    item.max_qty > 0 ? item.checked : false
                                }
                                onChange={checkedItem}
                                disabled={item.max_qty > 0 ? false : true}
                            />
                        </div>

                        <div className="col-3 container fa-pull-left px-0">
                            <Link
                                to={{
                                    pathname: "/emall/product-details",
                                    state: { productCode: item.code },
                                }}
                            >
                                <img src={item.image} sizes={10} alt={1} />
                            </Link>
                        </div>

                        <div className="col-8 container fa-pull-right">
                            <div className="row col-12 mx-0 px-0 overflow-hidden">
                                <small>{item.name}</small>
                            </div>
                            <div className="">
                                <div className="row col-12 mx-0 px-0">
                                    <div className="col-12 px-0">
                                        <div className="row col-12">
                                            {prices}
                                        </div>
                                    </div>
                                </div>

                                <small>{variant_str}</small>
                                <div className="row col-12 mx-0 px-0">
                                    <div className="col-7 pl-0 pr-0">
                                        {item.max_qty > 0 ? (
                                            <Input
                                                data-pid={item.pId}
                                                addonBefore={buttonMinus}
                                                addonAfter={buttonPlus}
                                                defaultValue={item.quantity}
                                                value={item.quantity}
                                                onChange={onChange}
                                            />
                                        ) : (
                                            <small className="text-danger">
                                                <b>
                                                    {t("emall.out_of_stock")}!!!
                                                </b>
                                            </small>
                                        )}
                                    </div>
                                    <div className="col-5 text-right pr-0">
                                        <img
                                            src={deleteIcon}
                                            alt=""
                                            style={{ width: "20px" }}
                                            onClick={delConfirmation}
                                            data-pid={item.pId}
                                        />
                                    </div>
                                </div>
                                {/*<Input addonBefore={buttonMinus} addonAfter={buttonPlus} defaultValue={initialAmount} value={amount}/>*/}
                            </div>
                        </div>
                    </div>
                </li>
            );
        });

        let itemPrices = priceList.map(function (item, i) {
            return (
                <span key={i}>
                    <div className="row col-12">
                        <small className="col-12 text-right">
                            <b>
                                {item.display_code} {item.amount}
                            </b>
                        </small>
                    </div>

                    <hr className="hr-m-top hr-m-bottom" />
                </span>
            );
        });

        const orderList = <aside className="list-group">{cartItems}</aside>;

        return (
            <div className="myCart">
                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="cart-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("transfer.confirmation_msg")}</h4>
                                </div>
                            </div>
                        }
                        closeText={t("purchasePin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={this.handleCartDelClose}
                        closeId=""
                        submitText={t("common.submit")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleDelItem}
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="del-cart-confirmation-modal"
                    />
                )}

                <div className="container-fluid px-0">
                    <PageHeader
                        subTitle={t("emall.shopping_cart")}
                        navClass="title white-color"
                        backgroundHeight={80}
                        subPageHistoryBack={"/emall"}
                        walletType={""}
                        arrowLeft={true}
                    />
                </div>

                {!(cartItemsList.length > 0) && (
                    <div className="text-center">
                        <div className="text-uppercase">
                            <small>{t("emall.cart_is_empty")}</small>
                        </div>
                        <div>
                            <button
                                className="col-10 btn btn-success text-uppercase"
                                onClick={this.handleGoShoppingClick}
                            >
                                {t("emall.go_shopping")}
                            </button>
                        </div>
                    </div>
                )}

                {cartItemsList.length > 0 && (
                    <>
                        <div
                            className="container-fluid px-0 overflow-auto"
                            style={{ height: screenHeight }}
                        >
                            {orderList}
                        </div>

                        <div className="card fixed-bottom">
                            <div
                                className="text-center"
                                onClick={(e) =>
                                    this.handleCheckOutOnOff(e, checkOutShow)
                                }
                            >
                                <img
                                    src={
                                        checkOutShow === true
                                            ? arrowDown
                                            : arrowUp
                                    }
                                    width={20}
                                    alt=""
                                />
                            </div>
                            {checkOutShow === true && (
                                <div>
                                    {/*<div className="col-12">*/}
                                    {/*<small className="">{t("emall.shipping")}:</small>*/}
                                    {/*<small className="fa-pull-right">USDT 0.00</small>*/}
                                    {/*</div>*/}

                                    <hr />

                                    <div className="col-12">
                                        <small className="">
                                            {t("emall.total_amount")}:
                                        </small>
                                    </div>
                                    {itemPrices}
                                </div>
                            )}

                            <div className="py-3 text-center">
                                {/*<Link to="/emall/delivery">*/}
                                <button
                                    className="col-10 btn btn-success text-uppercase"
                                    disabled={checkedStatus}
                                    onClick={this.handleCheckout}
                                >
                                    {t("emall.check_out")}
                                </button>
                                {/*</Link>*/}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { cart, modal } = state;

    return {
        carts: cart.cartsData,
        delItem: cart.delItem,
        updateCarts: cart.updateCarts,
        classIsShowing: modal.classIsShowing,
    };
};

const mapDispatchToProps = {
    openModal,
    closeModal,
    getCarts,
    postDeleteItem,
    postUpdateMemCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCart);
