import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const stakingProgramReducer = createReducer(initialState)({
    [type.GET_STAKING_PROGRAM]: (state, action) => ({
        ...state,
        details: action.payload,
    }),

    [type.POST_PURCHASE_STAKING_PACKAGE]: (state, action) => ({
        ...state,
        postStakingData: action.payload,
    }),

    [type.PUT_STAKING_PAYMENT_METHOD]: (state, action) => ({
        ...state,
        payment_method: action.payload,
    }),

    [type.GET_STAKING_PRODUCT]: (state, action) => ({
        ...state,
        product_data: action.payload,
    }),
});

export default stakingProgramReducer;
