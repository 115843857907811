import Market from "../../pages/market";

const routes = [
    {
        path: "/market",
        component: Market,
    },
];

export default routes;
