import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setLocale, t} from 'react-i18nify';

import { getOrderList } from '../../store/ducks/emall/actions';
import { postTransactionList } from '../../store/ducks/walletDetails/actions';

// import { GenHash } from '../../common/helpers';

import './completePurchase.scss';
import PageHeader from "../../components/PageHeader";
// import product1 from "../../public/product-image/product1.jpg";
// import StringInput from "../../components/input/StringInput";
import {Link} from "react-router-dom";
import SuccessIcon from "../../assets/js/views/components/SuccessAlert";
import {GenHash} from "../../assets/js/helpers";
import LoadingMask from "../../components/loadingMask";
// import {Link} from "react-router-dom";

class CompletePurchase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialAmount: 1,
      amount: 1,
      voucherCode: '',
      data: {},
      itemData: [],
      paymentData: [],
      addressData: {},
      loading: true,
      headerHeight: window.innerWidth
    };

    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    setLocale(sessionStorage.getItem("lang"));

    window.addEventListener("resize", this.updateHeight);

    if (this.props.location.state !== undefined) {
      const data = {};

      data.username = sessionStorage.getItem("email");
      data.lang = sessionStorage.getItem("lang");
      data.doc_no = this.props.location.state.doc_no;
      data.type = '';
      var hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
      data.hash = hash;

      this.props.getOrderList(data);
    }
    else {

      this.props.history.push({
        pathname: "/home",
        state: {error: "no_payment"}
      });
    }

  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.data !== this.props.data){
      if(nextProps.data.rst === "1"){
        var array;
        array = nextProps.data.data;

        this.setState({
          data: array[0],
          paymentData: array[0].payment,
          itemData: array[0].items,
          addressData: array[0].address,
          loading: false
        });
      }
    }
  }

  updateHeight(){
    if (window.innerWidth <= 768) {
      this.setState({headerHeight: window.innerWidth});
    }
    else {
      this.setState({headerHeight: 768});
    }
  }

  onChange(e){
    const { name, value } = e.target;

    this.setState({[name]: value});
  }

  onChangeAmount(e) {

    let amount = this.state.amount;

    if (e.target.id === 'plus'){
      this.setState({amount: amount + 1});
    }
    else if (e.target.id === 'minus' && amount !== 0){
      this.setState({amount: amount - 1});
    }
  }

  render() {

    const {loading, data, itemData, paymentData, addressData} = this.state;

    const itemList = itemData.map(function(item, i){
        return (
            <li className="list-group-item asset-panel-list-group-item col-12 px-0" key={i}>
              <div className="col-4 col-md-2 fa-pull-left">
                <img src={item.image} sizes={10} alt={1}/>
              </div>
              <div className="col-8 col-md-10 fa-pull-right px-0 pt-2">
                <div className="col-12 product-list-label-style">
                  <small className="row mx-0 px-0">{item.product_name}</small>
                </div>
                <div className="col-12">
                  {item.prices.map(function (item2, j) {
                    return (
                        <small key={j}><b>{item2.display_name}&nbsp;{item2.unit_price}{item.prices.length !== j + 1 ? ',' : ''}&nbsp;</b></small>
                    );
                  })}
                </div>
                <div className="col-12">
                  <sub>{t("emall.qty")}: {item.quantity}</sub>
                </div>
              </div>
            </li>
        );
    });

      const priceList = paymentData.map(function(item2, j){
        return (
            <div key={j}>
              <div className="col-12 text-right"><b>{item2.display_name}&nbsp;{item2.total_amount}</b></div>
            </div>
        );
      });

    return (
        <div className="completePurchase">
          <div className="container-fluid px-0">
            <PageHeader
                subTitle={t('emall.confirm_purchase')}
                navClass="title white-color"
                backgroundHeight={80}
                subPageHistoryBack={"/emall/my-orders"}
                walletType={""}
                arrowLeft={true}
            />
          </div>

          {loading &&
          <div className="container pt-5">
            <div className="row">
              <div className="mx-auto">
                <LoadingMask
                    width={80}
                />
              </div>
            </div>
          </div>
          }

          {!loading &&
              <>
                <div className="container-fluid pb-3">
                  <div className="text-center">
                    <SuccessIcon width="150px" height="0px !important"/>
                    <h4 className="col-12" style={{color: '#00ca72'}}><b>{t("emall.thank_you_for_your_purchase")}!</b></h4>
                    <label className="col-12 m-0"><b>{t("emall.your_inv_no")}&nbsp;{data.doc_no}</b></label>
                    <small className="col-12 pb-3">{t("emall.paid_on")}&nbsp; {data.created_at}</small>
                  </div>
                </div>

                <div className="container-fluid">
                  <div className="row">
                    <li className="list-group-item asset-panel-list-group-item col-12 px-0">
                      <div className="col-12">
                        <small>{t('emall.ship_bill_to')}</small>
                      </div>
                      <div className="col-12">
                        <small><b>{addressData.shipment_name}</b></small>
                      </div>
                      <div className="col-12">
                        <small><b>{addressData.mobile_prefix}&nbsp;{addressData.mobile_no}</b></small>
                      </div>
                      <div className="col-12">
                        <small className="text-uppercase">{addressData.address_1}{addressData.address_2 !== undefined && addressData.address_2 !== '' ? ', ' + addressData.address_2 : ''}{addressData.address_3 !== undefined && addressData.address_3 !== '' ? ', ' + addressData.address_3 : ''} {addressData.state_name !== "" && ", "+addressData.state_name} {", "+addressData.country_name}.</small>
                      </div>
                    </li>
                  </div>

                  <div className="row">
                    {itemList}
                  </div>
                </div>

                <hr className="invisible-hr"/>

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 px-0">
                      <div className="fa-pull-left col-6">
                        <small><b>{t('emall.total')}</b></small>
                      </div>
                      <div className="fa-pull-right col-6">
                        <div className="col-12 pr-0">
                          {priceList}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*<hr className="invisible-hr"/>*/}

                {/*<div className="container-fluid">*/}
                {/*  <div className="row">*/}
                {/*    <div className="col-12 px-0">*/}
                {/*      <div className="fa-pull-left col-6">*/}
                {/*        <small>{t('emall.subtotal')}</small>*/}
                {/*      </div>*/}
                {/*      <div className="fa-pull-right col-6">*/}
                {/*        <div className="col-12 pr-0">*/}
                {/*          <label className="fa-pull-right mb-0">USDT 300.00</label>*/}
                {/*          <label className="fa-pull-right mb-0">AV 18.00</label>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}

                {/*    <div className="col-12  px-0">*/}
                {/*      <div className="fa-pull-left col-6">*/}
                {/*        <small>{t('emall.shipping_fee')}</small>*/}
                {/*      </div>*/}
                {/*      <div className="fa-pull-right col-6">*/}
                {/*        <div className="col-12 pr-0">*/}
                {/*          <label className="fa-pull-right">USDT 0.00</label>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <hr/>

                <div className="col-12 pb-3">
                  <div className="row">
                    <div className="col-12 text-center">
                      <Link to="/home">
                        <button className="col-12 col-sm-6 btn btn-outline-primary text-uppercase">
                          <small>
                            {t("emall.close")}
                          </small>
                        </button>
                      </Link>
                    </div>
                    {/*<div className="col-6 text-center">*/}
                    {/*  <Link to="/emall/order-details">*/}
                    {/*    <button className="col-12 col-sm-6 btn btn-primary text-uppercase">*/}
                    {/*      <small>*/}
                    {/*        {t("prdDetails.check_order")}*/}
                    {/*      </small>*/}
                    {/*    </button>*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </>
          }

        </div>
    );
  }
}

const mapStateToProps = state => {
  const { emall, walletDetails } = state;

  return {
    data: emall.data,
    transaction_list: walletDetails.data
  };
};

const mapDispatchToProps = {
  getOrderList,
  postTransactionList
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompletePurchase);
