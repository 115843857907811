import * as type from './types';

export const openModal = (load) => dispatch => {
    dispatch ({
        type: type.GET_OPEN_MODAL,
        payload: {
            'modalName' : load,
            'isShowing' : true
        }
    });
};

export const closeModal = (load) => dispatch => {
    dispatch ({
        type: type.GET_OPEN_MODAL,
        payload: {
            'modalName' : load,
            'isShowing' : false
        }
    });
};