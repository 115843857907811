import * as type from './types';
import axios from 'axios';
// import jwt from 'jsonwebtoken';
//
// import { GenHash } from "../../../assets/js/helpers";
// import { text } from '../../../assets/js/state/middleware/public-key';

/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Shewn
 */
export const postAuction = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/auction/add', {
         
        "secondaryPassword": data['secondaryPassword'],
        "token": data['token'],
        "email": data['username'],
        "walletType": data['walletType'],
        "amount": data['withdrawAmount'],
        "lang": data['lang'],
        "hash": data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_AUCTION,
            payload: response
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_AUCTION,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

// const originalRequest = error.config;
//
// if (error.response.status === 401) {
//     if (sessionStorage.getItem("accessToken") != null) {
//         jwt.verify(sessionStorage.getItem("accessToken"), text, {algorithm: 'RS256'}, function (err, decoded_token) {
//             if (err !== null && decoded_token !== null) {
//
//                 jwt.verify(sessionStorage.getItem("refreshToken"), text, {algorithm: 'RS256'}, function (refresh_err, refresh_decoded_token) {
//                     if (refresh_err !== null && refresh_decoded_token !== null) {
//                         sessionStorage.setItem("tokenExpired", "true");
//                         sessionStorage.removeItem("uname");
//                         sessionStorage.removeItem("accessToken");
//                         sessionStorage.removeItem("refreshToken");
//                         window.location.href = "/login";
//                     }
//                 });
//             }
//         });
//
//         axios.post(process.env.REACT_APP_GM_URL + "/member/refresh/token", {
//             "refresh_token": sessionStorage.getItem("refreshToken"),
//             "hash": GenHash(sessionStorage.getItem("refreshToken"), process.env.REACT_APP_GM_SECRET_KEY)
//         }, {
//             headers: {
//                 'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
//                 'Authorization': sessionStorage.getItem("accessToken")
//             }
//         }).then((response) => {
//
//             sessionStorage.setItem("accessToken", response.data.data.access_token);
//             sessionStorage.setItem("refreshToken", response.data.data.refresh_token);
//
//             console.log(sessionStorage.getItem("accessToken"));
//             error.config.headers.Authorization = sessionStorage.getItem("accessToken");
//
//             return axios(originalRequest).then( (response) => {
//                 dispatch ({
//                     type: type.POST_WITHDRAWAL,
//                     payload: response
//                 })
//             });
//
//         }).catch(err => {
//             sessionStorage.setItem("tokenExpired", "true");
//             sessionStorage.removeItem("uname");
//             sessionStorage.removeItem("accessToken");
//             sessionStorage.removeItem("refreshToken");
//             window.location.href = "/login";
//         });
//     }
// }
