import React, { Component } from "react";
//import { setLocale, t } from "react-i18nify";
import connect from "react-redux/es/connect/connect";
import { t } from "../../common/translation";
import { Input, Select, Tabs, message, Icon, Tree } from "antd";
import PageHeader from "../../components/PageHeaderV2";
import InfiniteScroll from "react-infinite-scroller";
import {
    getProfile,
    postAutoPlacement,
    postMemberPlacement,
    getPlacementDetails,
    getPlacementTreeData,
    getPlacementNodes,
    getSponsorNodes,
} from "../../store/ducks/profile/actions";

import "./friendTree.scss";
import { GenHash } from "../../common/helpers";
import Modal from "../../components/modal";
import LoadingMask from "../../components/loadingMask";
import { getTreeFromFlatData } from "react-sortable-tree";
import "react-sortable-tree/style.css";
import FilterLogo from "../../public/v3/icon/filter.svg";
import { getUsernameByEmail } from "../../store/ducks/transfer/actions";

const { Option } = Select;

class FriendTree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading_visible: false,
            // statement_type: true,
            totalPage: 0,
            currentPage: 1,
            transaction_data: [],
            hasMoreItems: true,
            showFilterModel: false,
            search_email: "",
        };

        // this.handleRowClick = this.handleRowClick.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
        this.onChange = this.onChange.bind(this);
        this.closeFilterModal = this.closeFilterModal.bind(this);
        this.openFilterModal = this.openFilterModal.bind(this);
    }

    closeFilterModal() {
        this.setState({
            showFilterModel: false,
        });
    }

    openFilterModal() {
        this.setState({
            showFilterModel: true,
        });
    }

    onChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
        // console.log(name, value);

        // this.setState({
        //     search_email: "hahahah",
        // });
    }
    renderItem(item, i) {
        const itemRows = [
            <tr key={i}>
                <td>{item.email}</td>
            </tr>,
            // <div>{item.email}</div>,
        ];
        return itemRows;
    }
    loadMoreList(e, page) {
        const trans = {};
        let currentPage = parseInt(this.state.currentPage) + 1;
        trans.page = currentPage;
        trans.limit = 15;
        if (currentPage >= this.state.totalPage) {
            this.setState({
                hasMoreItems: false,
                loading_visible: false,
            });
        } else {
            this.setState({
                loading_visible: true,
            });
        }

        this.props.getSponsorNodes(trans);
    }

    UNSAFE_componentWillMount() {
        //  setLocale(sessionStorage.getItem("lang"));
    }

    componentDidMount() {
        let data = {};
        data["page"] = 1;
        data["limit"] = 15;
        this.props.getSponsorNodes(data);
        // downline_list: [],
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ loading_visible: false });
        if (nextProps.sponsor_nodes !== this.props.sponsor_nodes) {
            if (nextProps.sponsor_nodes.rst === "1") {
                let array;
                array = this.state.transaction_data;

                if (nextProps.sponsor_nodes.data.currentPageItems.length > 0) {
                    nextProps.sponsor_nodes.data.currentPageItems.map(function (
                        item,
                        i
                    ) {
                        array.push(item);

                        return array;
                    });

                    if (
                        this.state.currentPage ===
                        nextProps.sponsor_nodes.data.totalPage
                    ) {
                        this.setState({
                            hasMoreItems: false,
                        });
                    }

                    if (nextProps.sponsor_nodes.data.totalPage === "0") {
                        this.setState({
                            hasMoreItems: false,
                        });
                    }

                    this.setState({ transaction_data: array });
                }
                this.setState({
                    totalPage: nextProps.sponsor_nodes.data.totalPage,
                });
                this.setState({
                    currentPage: nextProps.sponsor_nodes.data.currentPage,
                });
            } else {
                switch (nextProps.sponsor_nodes.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                        this.setState({
                            errMsg: nextProps.sponsor_nodes.msg,
                        });
                        this.props.openModal("error-modal");
                        break;
                }
            }
        }
    }

    render() {
        const {
            showLoadingModal,
            search_type,
            transaction_data,
            loading_visible,
            totalPage,
            currentPage,
        } = this.state;

        const selectAfter = (
            <Select
                defaultValue="email"
                name="search_type"
                className="select-after select-afteridth-100"
                onSelect={this.handleChangeSearch}
            >
                <Option key={1} value="email">
                    {t("placement.email")}
                </Option>
                <Option key={2} value="username">
                    {t("common.username")}
                </Option>
            </Select>
        );
        let allItemRows = [];

        transaction_data.map((item, i) => {
            const perItemRows = this.renderItem(item, i);
            allItemRows = allItemRows.concat(perItemRows);

            return allItemRows;
        });
        return (
            <div className="friend-tree">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack="/profile"
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("profile.my_friend")}
                    arrowLeft={true}
                    btnRight={
                        <div className="pointer">
                            <img
                                onClick={this.openFilterModal}
                                src={FilterLogo}
                                width={16}
                                height={16}
                                alt=""
                            />
                        </div>
                    }
                />
                <Modal
                    title={t("trade.filter")}
                    children={
                        <div className="col-12 px-0">
                            <div className="col-12 px-0">
                                <div className="form-group">
                                    <label className="col-12 depth-gray-label text-capitalize pl-0">
                                        {t("common.email")}
                                    </label>
                                    <Input
                                        id="search_email"
                                        name="search_email"
                                        className="remove-placeholder exchange-input"
                                        // addonAfter={selectAfter}
                                        onChange={this.onChange}
                                        //value={this.state.search_email}
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="form-group text-right col-12 px-0">
                                    <button
                                        onClick={() => {
                                            this.setState({
                                                showFilterModel: false,
                                                transaction_data: [],
                                            });
                                            let placement_data = {};
                                            placement_data.searchEmail = this.state.search_email;
                                            placement_data.limit = 15;
                                            this.props.getSponsorNodes(
                                                placement_data
                                            );
                                        }}
                                        type="submit"
                                        className="button modal-filter-button"
                                    >
                                        {t("placement.search")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    modalHeadClass={`modal-body-purple`}
                    modalTitleClass={`text-white`}
                    modalBodyClass={`modal-body-purple`}
                    isShowing={this.state.showFilterModel}
                    modalClassId="filter-modal"
                    modalTitleCloseFunc={this.closeFilterModal}
                />
                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="bonus-payout-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}
                <div className="col-12 offset-md-2 col-md-8 px-0">
                    <div className="px-0">
                        <div className="px-0">
                            <div className="card mx-auto">
                                {/* <div className="card-body">
                                    <div className="row">
                                        <div className="col-6 px-0">
                                            <h5 className="card-text text-uppercase profile-main-label">
                                                <span className="">
                                                    {t(
                                                        "placement.search_sponsor"
                                                    )}
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-lg-8 col-md-8 col-8 pr-0 px-0">
                                            <Input
                                                id="search_email"
                                                name="search_email"
                                                className="remove-placeholder exchange-input"
                                                // addonAfter={selectAfter}
                                                onChange={this.onChange}
                                                value={this.state.search_email}
                                                type="text"
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-4 pr-0">
                                            <button
                                                type="submit"
                                                className="depth-blue-btn float-left mr-2 btn-sm"
                                                onClick={() => {
                                                    this.setState({
                                                        treeData: [],
                                                        expandArr: [],
                                                    });
                                                    let placement_data = {};
                                                    placement_data.searchEmail = this.state.search_email;
                                                    placement_data.limit = 15;
                                                    this.props.getSponsorNodes(
                                                        placement_data
                                                    );
                                                }}
                                            >
                                                {t("placement.search")}
                                            </button>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="">
                                    <div
                                        id="friend-list"
                                        className="friend-list"
                                    >
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={this.loadMoreList}
                                            hasMore={true}
                                            initialLoad={false}
                                            threshold={15}
                                            useWindow={false}
                                            loader={
                                                loading_visible ? (
                                                    <div
                                                        className="row"
                                                        key={0}
                                                    >
                                                        <div className="mx-auto">
                                                            <LoadingMask
                                                                width={80}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )
                                            }
                                        >
                                            <table className="table">
                                                <thead className="friend-header">
                                                    <tr>
                                                        <th>Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{allItemRows}</tbody>
                                            </table>
                                            {/* {
                                                <aside className="list-group">
                                                    {allItemRows}
                                                </aside>
                                            } */}
                                        </InfiniteScroll>
                                    </div>
                                    {((!loading_visible && totalPage === "1") ||
                                        (!loading_visible &&
                                            currentPage === totalPage)) && (
                                        <div className="col-12 text-white">
                                            <div className="row">
                                                <div className="mx-auto">
                                                    <span>
                                                        <small>
                                                            <b>
                                                                {t(
                                                                    "common.end_of_record"
                                                                )}
                                                            </b>
                                                        </small>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!loading_visible && totalPage < 1 && (
                                        <div className="col-12 text-white">
                                            <div className="row">
                                                <div className="mx-auto">
                                                    <span>
                                                        <small>
                                                            <b>
                                                                {t(
                                                                    "common.no_record"
                                                                )}
                                                            </b>
                                                        </small>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile, transfer } = state;

    return {
        profile_data: profile.profileData,
        placement_data: profile.placementData,
        postPlacement: profile.postPlacement,
        placementDetails: profile.placementDetails,
        placement_list: profile.placement_list,
        sponsor_nodes: profile.sponsor_nodes,
        username_data: transfer.getUsernameByEmail,
    };
};

const mapDispatchToProps = {
    getProfile,
    postAutoPlacement,
    postMemberPlacement,
    getPlacementDetails,
    getPlacementTreeData,
    getUsernameByEmail,
    getPlacementNodes,
    getSponsorNodes,
};

export default connect(mapStateToProps, mapDispatchToProps)(FriendTree);
