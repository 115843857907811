import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

import "./WalletBadge.css";

class WalletBadge extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            walletArr: [],
            totalPage: 0,
        };

        this.handleBadgePaging = this.handleBadgePaging.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
    }

    componentDidMount() {
        this.handleChangePage(this.state.page, 1);
    }

    handleChangePage(paging, indicator) {
        if (indicator === 1) {
            let badgeActive = this.props.badgeActive;
            let currentPage = paging;

            this.props.walletArrProps.map(function (item, index) {
                if (item.displayWalletCode === badgeActive) {
                    currentPage = Math.ceil((index + 1) / 4);
                }

                return null;
            });

            paging = currentPage;

            this.setState({ page: currentPage });
        }

        let totalLength = this.props.walletArrProps.length;
        let idx = (paging - 1) * 4;
        let array = [];
        let i = 0;
        let limit = idx + 4 > totalLength - 1 ? totalLength : idx + 4;

        for (i = idx; i < limit; i++) {
            array.push(this.props.walletArrProps[i]);
        }

        this.setState({
            walletArr: array,
            totalPage: totalLength / 4,
        });
    }

    handleBadgePaging(e, action) {
        let paging = 0;
        if (action === "plus") {
            this.setState({ page: this.state.page + 1 });
            paging = this.state.page + 1;
        } else {
            this.setState({ page: this.state.page - 1 });
            paging = this.state.page - 1;
        }

        this.handleChangePage(paging, 0);
    }

    render() {
        const { walletArr, totalPage, page } = this.state;
        const { badgeActive, badgeOnClick, currentTab } = this.props;
        var activeClass = "";
        const walletBadge = walletArr.map(function (item, i) {
            if (item.walletTypeCode !== badgeActive) {
                activeClass = "wallet-badge-style text-center mx-auto";
            } else {
                activeClass = "active-wallet-badge-style text-center mx-auto";
            }
            // if (currentTab) {
            //     if (
            //         (currentTab == "sell" && item.show_sell) ||
            //         (currentTab == "buy" && item.show_buy)
            //     ) {
            //         return (
            //             <div
            //                 key={i}
            //                 className="col-3 mb-3 px-1"
            //                 onClick={(e) =>
            //                     badgeOnClick(
            //                         e,
            //                         item.walletTypeCode,
            //                         item.displayWalletCode
            //                     )
            //                 }
            //             >
            //                 <div className={activeClass}>
            //                     <div className="px-2 py-4">
            //                         <img
            //                             src={item.fileUrl}
            //                             alt=""
            //                             width={30}
            //                             className="mx-2"
            //                         />
            //                         <div className="panel-text-style text-truncate pt-1">
            //                             {item.displayWalletName}
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         );
            //     }
            // } else {
            return (
                <div
                    key={i}
                    className="col-3 mb-3 px-1"
                    onClick={(e) =>
                        badgeOnClick(
                            e,
                            item.walletTypeCode,
                            item.displayWalletCode
                        )
                    }
                >
                    <div className={activeClass}>
                        <div className="px-2 py-4">
                            <img
                                src={item.fileUrl}
                                alt=""
                                width={30}
                                className="mx-2"
                            />
                            <div className="panel-text-style text-truncate pt-1">
                                {item.displayWalletName}
                            </div>
                        </div>
                    </div>
                </div>
            );
            // }

            // if (
            //     (currentTab == "sell" && item.show_sell) ||
            //     (currentTab == "buy" && item.show_buy)
            // ) {
            // console.log(item);
            // console.log(item.walletTypeCode, badgeActive);

            // if (item.displayWalletCode !== badgeActive) {
            //     return (
            //         <div
            //             key={i}
            //             className="col-3 mb-3 px-1"
            //             onClick={(e) =>
            //                 badgeOnClick(
            //                     e,
            //                     item.walletTypeCode,
            //                     item.displayWalletCode
            //                 )
            //             }
            //         >
            //             <div className="wallet-badge-style text-center mx-auto">
            //                 <div className="px-2 py-4">
            //                     <img
            //                         src={item.fileUrl}
            //                         alt=""
            //                         width={30}
            //                         className="mx-2"
            //                     />
            //                     <div className="panel-text-style text-truncate pt-1">
            //                         {item.displayWalletName}
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     );
            // } else {
            //     return (
            //         <div
            //             key={i}
            //             className="col-3 mb-3 px-1"
            //             onClick={(e) =>
            //                 badgeOnClick(
            //                     e,
            //                     item.walletTypeCode,
            //                     item.displayWalletCode
            //                 )
            //             }
            //         >
            //             <div className="active-wallet-badge-style text-center mx-auto">
            //                 <div className="px-2 py-4">
            //                     <img
            //                         src={item.fileUrl}
            //                         alt=""
            //                         width={30}
            //                         className="mx-2"
            //                     />
            //                     <div className="panel-text-style text-truncate pt-1">
            //                         {item.displayWalletName}
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     );
            // }
            // }
        });

        return (
            <div className="offset-md-2 col-md-8 px-0">
                <div className="row mx-0 px-0">
                    {totalPage !== 0 && page > 1 && (
                        <Icon
                            type="caret-left"
                            className="px-0 col-1 my-auto text-light"
                            onClick={(e) => this.handleBadgePaging(e, "minus")}
                        />
                    )}
                    <div className="row col-10 mx-auto py-4 px-0 responsive-panel-width">
                        {walletBadge}
                    </div>
                    {totalPage > page && (
                        <Icon
                            type="caret-right"
                            className="px-0 col-1 my-auto text-light"
                            onClick={(e) => this.handleBadgePaging(e, "plus")}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default WalletBadge;

WalletBadge.propTypes = {
    walletArrProps: PropTypes.any,
    badgeOnClick: PropTypes.func,
    badgeActive: PropTypes.string,
};
