import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";
import { t } from 'react-i18nify';

import depositImg from "../../public/v2/icon/icon-m-deposit.svg";
import withdrawalImg from "../../public/v2/icon/icon-m-send.svg";
import transferImg from "../../public/v2/icon/icon-m-transfer.svg";
import convertImg from "../../public/v2/icon/icon-m-convert.svg";
import stakingImg from "../../public/v2/icon/icon-m-staking.svg";
import auctionImg from "../../public/v2/icon/icon-m-celeauction.svg";
// import exchangeImg from "../../public/v2/icon/icon-m-exchange.svg";

import "./transPanelV2.css";

class TransPanelV2 extends Component {
    render() {
        const {/*exchangeStatus,*/ displayWalletCode, auctionStatus, depositStatus, transferStatus, withdrawalStatus, walletType, convertStatus, stakingStatus, convertNavProps} = this.props;


        let sum_status = (depositStatus ? 1 : 0) + (withdrawalStatus ? 1 : 0) + (transferStatus ? 1 : 0) + (convertStatus ? 1 : 0) + (stakingStatus ? 1 : 0) + (auctionStatus ? 1 : 0);
        // + (exchangeStatus ? 1 : 0)

        let iconClass = sum_status === 1 ? "col-12 text-center" : sum_status === 2 ? "col-6 text-center" :
            sum_status === 3 ? "col-4 text-center" : sum_status === 4 ? "col-3 text-center" : "col-2 text-center offset-1";

        return (
            <div>
                {(depositStatus || withdrawalStatus || stakingStatus || transferStatus || convertStatus || auctionStatus) &&
                <div className="card mx-auto pt-2">
                    <div className="card-body p-0">
                        <div className="p-1">
                            <div className="row">
                                {depositStatus === true &&
                                <div
                                    className={iconClass}>
                                    <div className="col-12">
                                        {(depositStatus === true) &&
                                        <Link to={{pathname: `/deposit`, state: {walletType: walletType, cryptoAddress: walletType}}}>
                                            <div className={`row text-center link panel-d-block`}>
                                                <img src={depositImg} width={25} alt=""/>
                                            </div>
                                            <div className="row text-center mt-icon panel-d-block asset-list">
                                                <span className="detail-panel-font-color "><small>{t('home.deposit')}</small></span>
                                            </div>
                                        </Link>
                                        }

                                    </div>
                                </div>
                                }

                                {withdrawalStatus === true &&
                                <div
                                    className={iconClass}>
                                    {(withdrawalStatus === true)&&
                                    <Link to={{pathname: `/withdrawal`, state: {walletType: walletType}}}>
                                        <div className="col-12 px-0">
                                            <div className={`row text-center link panel-d-block`}>
                                                <img src={withdrawalImg} width={25} alt=""/>
                                            </div>
                                            <div className="row text-center mt-icon panel-d-block asset-list">
                                                <span className="detail-panel-font-color text-truncate d-block"><small>{t('home.withdrawal')}</small></span>
                                            </div>
                                        </div>
                                    </Link>
                                    }
                                </div>
                                }

                                {stakingStatus === true &&
                                <div
                                    className={iconClass}>
                                    {(stakingStatus === true)&&
                                    <Link to={{pathname: `/program/staking`, state: {walletType: walletType}}}>
                                        <div className="col-12 px-0">
                                            <div className={`row text-center link panel-d-block`}>
                                                <img src={stakingImg} width={30} alt=""/>
                                            </div>
                                            <div className="row text-center mt-icon panel-d-block asset-list">
                                                <span className="detail-panel-font-color"><small>{t('home.staking')}</small></span>
                                            </div>
                                        </div>
                                    </Link>
                                    }
                                </div>
                                }

                                {transferStatus === true &&
                                <div
                                    className={iconClass}>

                                    {(transferStatus === true) &&
                                    <Link to={{pathname: `/transfer`, state: {walletType: walletType, displayWalletCode: displayWalletCode}}}>
                                        <div className="col-12 px-0">
                                            <div className={`row text-center link panel-d-block`}>
                                                <img src={transferImg} width={30} alt=""/>
                                            </div>
                                            <div className="row text-center mt-icon panel-d-block asset-list">
                                                <span className="detail-panel-font-color"><small>{t('home.transfer')}</small></span>
                                            </div>
                                        </div>
                                    </Link>
                                    }
                                </div>
                                }
                                {convertStatus === true &&
                                <div
                                    className={iconClass}>
                                    {(convertStatus === true && convertNavProps === null)&&
                                    <Link to={{pathname: `/buy/convert`, state: {walletType: walletType}}}>
                                        <div className="col-12 px-0">
                                            <div className={`row text-center link panel-d-block`}>
                                                <img src={convertImg} width={30} alt=""/>
                                            </div>
                                            <div className="row text-center mt-icon panel-d-block asset-list">
                                                <span className="detail-panel-font-color"><small>{t('home.convert')}</small></span>
                                            </div>
                                        </div>
                                    </Link>
                                    }
                                    {(convertStatus === true && convertNavProps !== null)&&
                                    <Link to={{pathname: `/staking`, state: {paymentSettings: convertNavProps.paymentSettings, walletFrom: convertNavProps.walletFrom, walletTo: convertNavProps.walletTo, convertTo: convertNavProps.convertTo, productName: convertNavProps.productName, productCode: convertNavProps.productCode}}}>
                                        <div className="col-12 px-0">
                                            <div className={`row text-center link panel-d-block`}>
                                                <img src={convertImg} width={30} alt=""/>
                                            </div>
                                            <div className="row text-center mt-icon panel-d-block asset-list">
                                                <span className="detail-panel-font-color"><small>{t('home.convert')}</small></span>
                                            </div>
                                        </div>
                                    </Link>
                                    }
                                </div>
                                }
                                {auctionStatus === true &&
                                <div
                                    className={iconClass}>
                                    <div className="col-12">
                                        {(auctionStatus === true) &&
                                        <Link to={{pathname: `/celeauction`, state: {walletType: walletType, cryptoAddress: walletType}}}>
                                            <div className={`row text-center link panel-d-block`}>
                                                <img src={auctionImg} width={25} alt=""/>
                                            </div>
                                            <div className="row text-center mt-icon panel-d-block asset-list">
                                                <span className="detail-panel-font-color "><small>{t('home.auction')}</small></span>
                                            </div>
                                        </Link>
                                        }

                                    </div>
                                </div>
                                }

                                {/*{exchangeStatus === true &&*/}
                                {/*<div*/}
                                {/*    className={iconClass}>*/}
                                {/*    <div className="col-12">*/}
                                {/*        {(exchangeStatus === true) &&*/}
                                {/*        <Link to={{pathname: `/exchange`, state: {walletType: walletType, cryptoAddress: walletType}}}>*/}
                                {/*            <div className={`row text-center link panel-d-block`}>*/}
                                {/*                <img src={exchangeImg} width={25} alt=""/>*/}
                                {/*            </div>*/}
                                {/*            <div className="row text-center mt-icon panel-d-block asset-list">*/}
                                {/*                <span className="detail-panel-font-color "><small>{t('home.exchange')}</small></span>*/}
                                {/*            </div>*/}
                                {/*        </Link>*/}
                                {/*        }*/}

                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*}*/}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default TransPanelV2;

TransPanelV2.propTypes = {
    depositStatus: PropTypes.bool,
    transferStatus: PropTypes.bool,
    withdrawalStatus: PropTypes.bool,
    stakingStatus: PropTypes.bool,
    walletType: PropTypes.string,
    displayWalletCode: PropTypes.string,
    convertStatus: PropTypes.bool,
    convertNavProps: PropTypes.any
};