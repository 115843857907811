import * as type from "./types";
import axios from "axios";

/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * @author Aun
 */
export const postTransfer = (data) => (dispatch) => {
    axios
        .post(
            process.env.REACT_APP_GM_URL + "/member/wallet/transfer/add",
            data,
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            dispatch({
                type: type.POST_TRANSFER,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.POST_TRANSFER,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};

export const getUsernameByEmail = (data) => (dispatch) => {
    axios
        .post(
            process.env.REACT_APP_GM_URL + "/member/getUsername",
            {
                token: data["token"],
                search_email: data["search_email"],
                lang: data["lang"],
                hash: data["hash"],
            },
            {
                headers: {
                    "X-Authorization":
                        "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                    Authorization: sessionStorage.getItem("accessToken"),
                },
            }
        )
        .then((response) => {
            // console.log(response);
            dispatch({
                type: type.GET_USERNAME_BY_EMAIL,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: type.GET_USERNAME_BY_EMAIL,
                payload: { rst: "0", msg: "system_error" },
            });
        });
};
