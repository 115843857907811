import Network from "../../pages/network/index";

const routes = [
    {
        path: "/network",
        component: Network,
    },
];

export default routes;
