import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
    cancel_data: []
};

const walletDetailsReducer = createReducer(initialState) ({

    [type.POST_WALLET_TRANSACRION]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

    [type.GET_STAKING_HISTORY]: (state, action) => ({
        ...state,
        data: action.payload.data,
    }),

    [type.POST_TRANS_CANCEL]: (state, action) => ({
        ...state,
        cancel_data: action.payload.data,
    }),


});

export default walletDetailsReducer;