import axios from "axios";
const history = {};
//var rp = require("request-promise").defaults({ json: true });

export default {
    history: history,

    getBars: function (symbolInfo, resolution, from, to, first, limit) {
        var historyPeriod = resolution;
        console.log("period", resolution);
        if (resolution == "1D") {
            historyPeriod = "1day";
        } else {
            historyPeriod += "min";
        }

        var param = {
            crypto_code: symbolInfo.name,
            history: historyPeriod,
            // from: date_from,
            // to: date_to,
        };
        return axios
            .create({ withCredentials: false })
            .get(
                process.env.REACT_APP_GM_URL +
                    "/member/exchange/trading-view/chart",
                {
                    params: param,
                    headers: {
                        "X-Authorization":
                            "INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1",
                        Authorization: sessionStorage.getItem("accessToken"),
                    },
                }
            )
            .then((response) => {
                // if (response.data) {
                // let data_tradding = response.data.trading_view_data;
                // let obj = JSON.parse(data_tradding.replace(/&quot;/g, '"'));

                // var bars = [];
                // for (var i = 0; i < response.data.t.length; i++) {
                //     var barValue = {
                //         time: response.data.t[i], //in miliseconds
                //         close: response.data.c[i],
                //         open: response.data.o[i],
                //         high: response.data.h[i],
                //         low: response.data.l[i],
                //         volume: response.data.v[i],
                //     };
                //     bars.push(barValue);
                // }
                var clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
                if (clientTimezoneOffset > 0) {
                    clientTimezoneOffset = -Math.abs(clientTimezoneOffset);
                } else {
                    clientTimezoneOffset = Math.abs(clientTimezoneOffset);
                }

                var totalAdd = clientTimezoneOffset * 3600 * 1000;
                if (response.data.length) {
                    var bars = response.data.map((el) => {
                        return {
                            time: el.t + totalAdd, //TradingView requires bar time in ms
                            low: el.l,
                            high: el.h,
                            open: el.o,
                            close: el.c,
                            volume: el.v,
                        };
                    });
                    if (first) {
                        var lastBar = bars[bars.length - 1];
                        history[symbolInfo.name] = { lastBar: lastBar };
                        return bars;
                    } else {
                        return [];
                    }
                } else {
                    return [];
                }
            });
    },
};
