import React from "react";
import { Route } from "react-router-dom";

import HomeRoutes from "../routes/home/routes";
import GlobalTrendRoutes from "../routes/globalTrend/routes";

// Transaction
import TransactionRoutes from "../routes/transaction/routes";

// Program
import StakingProgram from "../routes/stakingProgram/routes";
import CelebreneurProgram from "../routes/purchasePin/routes";

import UserProfileRoutes from "../routes/profile/routes";
import ChangePassword from "../routes/changePassword/routes";
import ChangeSecondaryPassword from "../routes/changeSecondaryPassword/routes";
import TwoFA from "../routes/twoFactorAuthetication/routes";
import ReferralRoutes from "../routes/referral/routes";
import WalletDetailsRoutes from "../routes/walletDetails/routes";
import MyFriends from "../routes/myFriends/routes";
import MyGroups from "../routes/multinaryTree/routes";
import Convert from "../routes/convert/routes";
import DepositConvert from "../routes/depositConvert/routes";
import Rewards from "../routes/rewards/routes";
import Auction from "../routes/auction/routes";
import Network from "../routes/network/routes";
import ExchangeRoutes from "../routes/exchange/routes";
import PersonalInfoRoutes from "../routes/personalInfo/routes";
import Share from "../routes/share/routes";
import Average from "../routes/average/routes";

// import PinList from '../routes/pinList/routes';
// import GiftCardList from '../routes/giftCardList/routes';
import SKeyRoutes from "../routes/sKey/routes";
import MissionPoint from "../routes/missionPointList/routes";
import Placement from "../routes/placement/routes";
import Announcement from "../routes/announcement/routes";

import AutoSellStatement from "../routes/autoSellStatement/routes";

import ProgramStatement from "../routes/programStatement/routes";

import TradingView from "../routes/tradingView/routes";
// import TopUpPin from '../routes/topUpPin/routes';
// import SubscribePin from '../routes/subscribePin/routes';
// import TopUpAV from './routes/topUpAV/routes';
// import SpecialRegister from './routes/specialRegister/routes';
import "./index.scss";

import TradeMarket from "../routes/market/routes";
import TradingHistory from "../routes/tradingHistory/routes";
import OrderDetail from "../routes/orderDetail/routes";

//markt

let rootRoutes = Array.prototype.concat(
    TradeMarket,
    TradingHistory,
    OrderDetail,
    HomeRoutes,
    GlobalTrendRoutes,
    TransactionRoutes,
    StakingProgram,
    CelebreneurProgram,
    // eMall,
    UserProfileRoutes,
    ChangePassword,
    ChangeSecondaryPassword,
    TwoFA,
    ReferralRoutes,
    WalletDetailsRoutes,
    MyFriends,
    MyGroups,
    Convert,
    DepositConvert,
    Rewards,
    PersonalInfoRoutes,
    TradingView,
    // TopUpPin,
    // PinList,
    SKeyRoutes,
    Auction,
    Network,
    ExchangeRoutes,
    // Share,
    Average,
    MissionPoint,
    Placement,
    Announcement,
    AutoSellStatement,
    ProgramStatement
    // GiftCardList
    // SubscribePin,
    // TopUpAV,
    // SpecialRegister,
);

export const RoutingTest = () => {
    let reactRoutes = rootRoutes.map((route, index) => (
        <Route
            exact={true}
            key={index}
            path={route.path}
            component={route.component}
        />
    ));

    let temp = [];

    reactRoutes.map((route, index) => temp.push(route.props));

    return temp;
};
