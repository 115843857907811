import React, { Component } from 'react';
import { connect } from 'react-redux';
import jwt from "jsonwebtoken";
import moment from "moment";
import axios from "axios";

import { text } from "../public-key";
import { GenHash } from "../../assets/js/helpers";
import { postIsAuthenticated } from "../../store/ducks/auth/actions";
import {Redirect} from "react-router-dom";

export default (ComposedComponent) => {
    class Authentication extends Component {
        state = {
            isAuthenticated: false,
            loading: false
        }

        UNSAFE_componentWillMount() {
            jwt.verify(sessionStorage.getItem("accessToken"), text, {algorithm: 'RS256'}, function (err, decoded_token) {
                if (err === null) {
                    this.setState({
                        isAuthenticated: true
                    }, () => {
                        this.props.postIsAuthenticated(false);
                    });
                } else {

                }

                console.log("decode_err:"+err);
            }.bind(this));
        }

        render() {
            return (this.state.isAuthenticated === true) ? <ComposedComponent {...this.props}/> : <Redirect to='/login' />;
        }
    }

    const mapStateToProps = state => {
        const { login } = state;

        return { authenticated: login.login_data };
    }

    const mapDispatchToProps = {
        postIsAuthenticated
    };

    return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}