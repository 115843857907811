import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const postChangePasswordReducer = createReducer(initialState) ({

    [type.POST_CHANGE_PASSWORD]: (state, action) => ({
        ...state,
        changePasswordData: action.payload,
    }),

});

export default postChangePasswordReducer;