import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
//import {setLocale, t} from "react-i18nify";
import { getLanguageFile } from "../../store/ducks/home/actions";
import { t, setLanguageFileJson } from "../../common/translation";
import PasswordInput from "../../components/input/PasswordInput";
import PageHeader from "../../components/PageHeaderV2";

import { postChangePassword } from "../../store/ducks/changePassword/actions";

import { fieldChecking, GenHash } from "../../common/helpers";
import { formValidation } from "../../common/validator/formValidation";

import "./changePassword.scss";
import HyperModal from "../../components/HyperModal";

class changePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPassword: "",
            password: "",
            confirmPassword: "",
            twoFA: "",
            modal_visible: false,
            loading_visible: this.props.loading_visible,
            modal_type: "",
            modal_msg: "",
        };

        this.handlePassword = this.handlePassword.bind(this);
        this.handleCurrentPassword = this.handleCurrentPassword.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.handleSubmitChangePassword = this.handleSubmitChangePassword.bind(
            this
        );
        this.handle2FA = this.handle2FA.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `50px`);
    }

    componentWillMount() {
        this.props.getLanguageFile(sessionStorage.getItem("lang"));
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });
        formValidation("change-password-form");
    }

    handlePassword(event) {
        this.setState({ password: event.target.value });
    }

    handleCurrentPassword(event) {
        this.setState({ currentPassword: event.target.value });
    }

    handleConfirmPassword(event) {
        this.setState({ confirmPassword: event.target.value });
    }

    handle2FA(event) {
        this.setState({ twoFA: event.target.value });
    }

    handleSubmitChangePassword(event) {
        console.log("new");
        event.preventDefault();

        let validate = fieldChecking(
            document.getElementById("change-password-form")
        );
        if (validate === false) {
            return false;
        }

        this.setState({
            loading_visible: true,
        });

        const data = {};

        // data.username = sessionStorage.getItem('email');
        // data.token = sessionStorage.getItem('token');
        data.currentPassword = this.state.currentPassword;
        data.newPassword = this.state.password;
        data.confirmNewPassword = this.state.confirmPassword;
        // data.totp = this.state.twoFA;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postChangePassword(data);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.language_data !== this.props.language_data) {
            setLanguageFileJson();
            // this.setState({
            //     rerender: true,
            // });
        }
        if (nextProps.changePasswordData !== this.props.changePasswordData) {
            if (nextProps.changePasswordData.rst === "1") {
                this.setState({
                    currentPassword: "",
                    password: "",
                    confirmPassword: "",
                    loading_visible: false,
                    modal_visible: true,
                    modal_type: "success",
                    modal_msg: nextProps.changePasswordData.msg,
                });
            } else {
                this.setState({
                    modal_msg: nextProps.changePasswordData.msg,
                    modal_type: "error",
                    modal_visible: true,
                    loading_visible: false,
                });
            }
        }
    }

    handleModalClose(e, type) {
        switch (type) {
            case "loading":
                this.setState({ loading_visible: false });
                break;
            case "error":
                this.setState({ modal_visible: false, modal_gcox: false });
                break;
            case "success":
                this.setState({ modal_visible: false });
                this.props.history.push("/profile");
                break;
            case "normal":
                this.setState({ confirm_visible: false });
                break;
            default:
                this.setState({
                    confirm_visible: false,
                    modal_visible: false,
                    loading_visible: false,
                });
                break;
        }
    }

    render() {
        const {
            loading_visible,
            modal_visible,
            modal_type,
            modal_msg,
        } = this.state;

        return (
            <div className="changePassword">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack={"/profile"}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("changePassword.change_password")}
                    arrowLeft={true}
                />

                <HyperModal
                    category="response"
                    modalVisible={loading_visible}
                    modalType="loading"
                    footer={[]}
                />

                <HyperModal
                    category="response"
                    modalVisible={modal_visible}
                    modalType={modal_type}
                    modalMsg={modal_msg}
                    modalCloseFunc={this.handleModalClose}
                    cancelFunc={this.handleModalClose}
                />

                <div className="pt-4 offset-md-2 col-md-8 overflow-auto custom-height">
                    <form
                        method="post"
                        id="change-password-form"
                        onSubmit={this.handleSubmitChangePassword}
                        className="txt-black needs-validation"
                        noValidate
                    >
                        <div className="form-group changePassword-panel-field">
                            <label
                                htmlFor="password"
                                className="depth-gray-label text-uppercase"
                            >
                                {t("changePassword.current_password")}*
                            </label>
                            <PasswordInput
                                id="password"
                                name="password"
                                onChange={this.handleCurrentPassword}
                                value={this.state.currentPassword}
                                placeholder={t(
                                    "changePassword.please_enter_password"
                                )}
                                className="form-control depth-input"
                                passwordformat="true"
                                passwordFormatMsg={t(
                                    "changePassword.passwordFormatMsg"
                                )}
                                requiredMsg={t("validator.requiredMsg")}
                                minLengthMsg={t("changePassword.minLengthMsg", {
                                    count: 8,
                                })}
                                minLength="8"
                                maxLength="16"
                                maxLengthMsg={t("changePassword.maxLengthMsg", {
                                    count: 16,
                                })}
                                required
                            />
                        </div>

                        <div className="form-group changePassword-panel-field">
                            <label
                                htmlFor="confirm_password"
                                className="depth-gray-label text-uppercase"
                            >
                                {t("changePassword.password")}*
                            </label>
                            <PasswordInput
                                id="confirm_password"
                                name="confirm_password"
                                onChange={this.handlePassword}
                                value={this.state.password}
                                placeholder={t(
                                    "changePassword.please_enter_password"
                                )}
                                className="form-control depth-input"
                                passwordformat="true"
                                passwordFormatMsg={t(
                                    "changePassword.passwordFormatMsg"
                                )}
                                requiredMsg={t("validator.requiredMsg")}
                                minLengthMsg={t("changePassword.minLengthMsg", {
                                    count: 8,
                                })}
                                minLength="8"
                                maxLength="16"
                                maxLengthMsg={t("changePassword.maxLengthMsg", {
                                    count: 16,
                                })}
                                required
                            />
                        </div>

                        <div className="form-group changePassword-panel-field">
                            <label
                                htmlFor="confirm_password"
                                className="depth-gray-label text-uppercase"
                            >
                                {t("changePassword.confirm_password")}*
                            </label>
                            <PasswordInput
                                id="confirm_new_password"
                                name="confirm_new_password"
                                onChange={this.handleConfirmPassword}
                                value={this.state.confirmPassword}
                                placeholder={t(
                                    "changePassword.please_reenter_password"
                                )}
                                className="form-control depth-input"
                                oldPassword={this.state.password}
                                confirmpassword="true"
                                confirmPasswordMsg={t(
                                    "changePassword.checkPasswordMatch"
                                )}
                                requiredMsg={t("validator.requiredMsg")}
                                required
                            />
                        </div>

                        {this.props.profile_data !== undefined &&
                            this.props.profile_data.data.twoFaStatus ===
                                "1" && (
                                <div className="form-group changePassword-panel-field">
                                    <label
                                        htmlFor="totp"
                                        className="depth-gray-label text-uppercase"
                                    >
                                        {t("changePassword.twoFA_code")}*
                                    </label>
                                    <PasswordInput
                                        id="totp"
                                        name="totp"
                                        onChange={this.handle2FA}
                                        value={this.state.twoFA}
                                        placeholder={t(
                                            "changePassword.please_enter_2fa"
                                        )}
                                        className="form-control"
                                        requiredMsg={t("validator.requiredMsg")}
                                        required
                                    />
                                </div>
                            )}

                        <div className="form-group text-right">
                            <button
                                type="submit"
                                className="col-12 depth-blue-btn forgot-password-button-text"
                            >
                                {t("changePassword.save")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile, changePassword, walletBalance } = state;

    return {
        changePasswordData: changePassword.changePasswordData,
        profile_data: profile.profileData,
        language_data: walletBalance.getLanguageFile,
    };
};

const mapDispatchToProps = {
    postChangePassword,
    getLanguageFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(changePassword);
