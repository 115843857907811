import React, { Component } from "react";
import { setLocale, t } from "react-i18nify";
import { connect } from "react-redux";

import PageHeader from "../../../components/PageHeader";
import NumberInput from "../../../components/input/NumberInput";
import PasswordInput from "../../../components/input/PasswordInput";
import LoadingMask from "../../../components/loadingMask";
import Modal from "../../../components/modal";

import { postWalletBalance } from "../../../store/ducks/home/actions";
import {
    postStaking,
    postConvertedAmount,
} from "../../../store/ducks/staking/actions";
import { openModal, closeModal } from "../../../store/ducks/modal/actions";

import { formValidation } from "../../../common/validator/formValidation";

import {
    amountChecking8,
    fieldChecking,
    GenHash,
} from "../../../common/helpers";

import ErrorIcon from "../../../components/ErrorAlert";
import SuccessIcon from "../../../components/SuccessAlert";

import "./staking.scss";

class Staking extends Component {
    constructor(props) {
        super(props);

        this.stakingForm = React.createRef();

        this.state = {
            data: [],
            s_password: "",
            address: "",
            amount: "",
            balance: 0,
            showLoadingModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            showConfirmationModal: false,
            minAmount: "100",
            errMsg: "",
            successMsg: "",
            current_price: "0.00",
            converted_amount: "0",
            walletTo:
                props.location.state === undefined
                    ? this.props.history.push({
                          pathname: "/home",
                          state: { error: "invalid_wallet_type_details" },
                      })
                    : props.location.state.walletTo,
            walletFrom:
                props.location.state === undefined
                    ? this.props.history.push({
                          pathname: "/home",
                          state: { error: "invalid_wallet_type_details" },
                      })
                    : props.location.state.walletFrom,
            convertTo:
                props.location.state === undefined
                    ? this.props.history.push({
                          pathname: "/home",
                          state: { error: "invalid_wallet_type_details" },
                      })
                    : props.location.state.convertTo,
            payment_settings:
                props.location.state === undefined
                    ? []
                    : props.location.state.paymentSettings,
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmitStaking = this.handleSubmitStaking.bind(this);
        this.getAllAmount = this.getAllAmount.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.onAmountBlur = this.onAmountBlur.bind(this);
    }

    componentDidMount() {
        formValidation("staking-form");
        setLocale(sessionStorage.getItem("lang"));

        if (this.props.location.state !== undefined) {
            const data = {};

            data.username = sessionStorage.getItem("email");
            data.token = sessionStorage.getItem("token");
            data.walletType = this.props.location.state.walletFrom;
            let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
            data.hash = hash;

            this.props.postWalletBalance(data);
        } else {
            this.props.history.push({
                pathname: "/home",
                state: { error: "invalid_wallet_type_details" },
            });
        }
    }

    handleSubmitStaking(event) {
        event.preventDefault();

        var form = document.getElementById("staking-form");

        var validate = fieldChecking(form);

        if (validate === false) {
            return false;
        }

        const data = [];
        var hash = "";

        data["amount"] = this.state.amount;
        data["secondaryPassword"] = this.state.s_password;
        data["token"] = sessionStorage.getItem("token");
        data["username"] = sessionStorage.getItem("email");
        data["salesType"] =
            this.props.location.state.walletTo === "LIGAV"
                ? "LIGA"
                : this.props.location.state.walletTo;
        hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data["hash"] = hash;

        this.props.openModal("staking-loading-modal");

        this.props.postStaking(data);
    }

    onChange(e) {
        this.stakingForm.current.classList.remove("was-validated");

        const { name, value } = e.target;

        if (
            (name === "amount" && amountChecking8(value)) ||
            name !== "amount"
        ) {
            this.setState({ [name]: value });

            if (name === "amount") {
                if (this.props.location.state.paymentSettings !== undefined) {
                    this.props.location.state.paymentSettings.map(function (
                        item,
                        i
                    ) {
                        item.amount = value * item.payment_percentage;
                        return null;
                    });
                }
            }
        }
    }

    onAmountBlur(e) {
        const data2 = {};

        data2.username = sessionStorage.getItem("email");
        data2.from = this.props.location.state.walletFrom;
        data2.to = this.props.location.state.convertTo;
        data2.amount = e.target.value;
        var hash = GenHash(data2, process.env.REACT_APP_GM_SECRET_KEY);
        data2.hash = hash;

        this.props.postConvertedAmount(data2);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName === "staking-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.data !== this.props.data) {
            if (nextProps.data.rst === "1") {
                var array;
                array = nextProps.data.data;

                this.setState({ data: array.walletBalance[0] });
            }
        }

        if (nextProps.converted_data !== this.props.converted_data) {
            if (nextProps.converted_data.rst === "1") {
                this.setState({
                    converted_amount: nextProps.converted_data.data,
                    current_price: nextProps.converted_data.rate,
                });
            }
        }

        if (nextProps.staking_data !== this.props.staking_data) {
            let msg = "";

            if (nextProps.staking_data.rst === "1") {
                const data = {};
                data.username = sessionStorage.getItem("email");
                data.token = sessionStorage.getItem("token");
                data.walletType = this.props.location.state.walletFrom;

                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;

                this.setState({
                    amount: "",
                    s_password: "",
                    showLoadingModal: false,
                    successMsg: t("staking." + nextProps.staking_data.msg),
                });

                this.props.openModal("success-modal");

                this.props.postWalletBalance(data);
            } else {
                msg = t("staking." + nextProps.staking_data.msg);
                this.setState({
                    showLoadingModal: false,
                    errMsg: msg,
                });

                this.props.openModal("error-modal");
                /*switch(nextProps.staking_data.msg){
                    case "please_enter_amount" :
                        msg = t('staking.please_enter_amount');
                        this.setState({
                            showLoadingModal: false,
                            errMsg: msg
                        });

                        this.props.openModal("error-modal");
                        break;
                    case "invalid_secondary_password" :
                        msg = t('staking.invalid_secondary_password');
                        this.setState({
                            showLoadingModal: false,
                            errMsg: msg
                        });

                        this.props.openModal("error-modal");
                        break;
                    default:
                        this.setState({
                            showLoadingModal: false,
                            errMsg: nextProps.staking_data.msg
                        });

                        this.props.openModal("error-modal");
                        return;
                }*/
            }
        }
    }

    getAllAmount() {
        document.getElementById("amount_input").value = 1.87767678;
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/home");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");
    }

    render() {
        const {
            payment_settings,
            data,
            current_price,
            balance,
            showLoadingModal,
            showErrorModal,
            showSuccessModal,
            errMsg,
            successMsg,
            walletFrom,
            converted_amount,
            convertTo,
        } = this.state;

        const paymentSettings = payment_settings.map(function (item, i) {
            let amount = item.amount === undefined ? 0 : item.amount;

            return (
                <div className="row">
                    <small className="col-6 pl-0">{item.display_code}</small>
                    <small className="col-6 pl-0 text-right">
                        {amount.toFixed(2)}
                    </small>
                </div>
            );
        });

        return (
            <div className="staking">
                <PageHeader
                    title={
                        walletFrom === "ACM"
                            ? t("staking.convert")
                            : this.props.location.state === undefined
                            ? ""
                            : this.props.location.state.productName
                    }
                    titleHeader="col-12 text-center"
                    backgroundHeight={230}
                    prevousPageLink=""
                    navClass=""
                    historyBack="/home"
                    historyBackClass="pt-3"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={data.displayCode ? data.displayCode : ""}
                    textBelowBalance={data.walletTypeName}
                    balance={data.amount ? data.amount : balance}
                    arrowLeft={true}
                />

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="staking-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div className="p-4 form-bg-transparent">
                    <div className="col-12 pt-3">
                        <div className="row">
                            <label className="form-label text-uppercase">
                                {t("staking.amount")} (
                                {t("staking." + walletFrom + "_used")})
                            </label>
                        </div>
                    </div>

                    <form
                        id="staking-form"
                        ref={this.stakingForm}
                        onSubmit={this.handleSubmitStaking}
                        className="form-bg-transparent needs-validation col-12 px-0"
                        noValidate
                    >
                        <div className="col-12">
                            <div className="row">
                                <NumberInput
                                    id="amount"
                                    name="amount"
                                    onChange={this.onChange}
                                    onBlur={this.onAmountBlur}
                                    value={this.state.amount}
                                    min={this.state.minAmount}
                                    placeholder="0.00000000"
                                    className="remove-placeholder"
                                    requiredMsg={t("validator.requiredMsg")}
                                    textMoreThan={t(
                                        "validator.amount_should_more_than"
                                    )}
                                    blockTen={
                                        this.props.location.state === undefined
                                            ? false
                                            : this.props.location.state
                                                  .walletFrom === "ACMV" &&
                                              this.props.location.state
                                                  .walletFrom !== "USDT"
                                            ? t("validator.block_of_10")
                                            : false
                                    }
                                    blockHundreds={
                                        this.props.location.state === undefined
                                            ? false
                                            : this.props.location.state
                                                  .walletFrom === "USDT"
                                            ? t("validator.block_of_100")
                                            : false
                                    }
                                    required
                                />
                                <div className="text-danger">
                                    <small>
                                        {t("staking.your_converted_amount")}
                                        {converted_amount + " "}
                                        {t("staking." + convertTo)}.{" "}
                                        {t("staking.current_price")}{" "}
                                        {current_price}
                                    </small>
                                </div>
                            </div>
                        </div>

                        {payment_settings.length > 0 && (
                            <div className="col-12 mt-3">
                                <small className="row pl-0">
                                    {t("withdrawal.amount_will_be_deducted")}:
                                </small>
                                {paymentSettings}
                            </div>
                        )}

                        <div className="col-12 pt-3">
                            <div className="row">
                                <label className="form-label text-uppercase">
                                    {t("staking.secondary_password")}
                                </label>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <PasswordInput
                                    id="s_password"
                                    name="s_password"
                                    onChange={this.onChange}
                                    value={this.state.s_password}
                                    placeholder={t(
                                        "staking.secondary_password"
                                    )}
                                    className="remove-placeholder"
                                    requiredMsg={t("validator.requiredMsg")}
                                    required
                                />
                            </div>
                        </div>

                        <div className="pt-4">
                            <button
                                type="submit"
                                className="col-12 btn btn-primary text-uppercase"
                            >
                                {t("staking.submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walletBalance, staking, modal } = state;

    return {
        data: walletBalance.data,
        classIsShowing: modal.classIsShowing,
        staking_data: staking.data,
        converted_data: staking.converted_data,
    };
};

const mapDispatchToProps = {
    postWalletBalance,
    postStaking,
    openModal,
    closeModal,
    postConvertedAmount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Staking);
