import React, { Component, Fragment } from "react";
import {
    maxLength,
    minLength,
    required,
    email,
} from "../../../common/validator/validate";
import PropTypes from "prop-types";
import { validateDisplay } from "../../../common/validator/formValidation";

class StringInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            requiredRules: required(
                this.props.value,
                true,
                this.props.requiredMsg
            ),
            minLengthRules: minLength(
                this.props.value,
                this.props.minLength,
                true,
                this.props.minLengthMsg
            ),
            maxLengthRules: maxLength(
                this.props.value,
                this.props.maxLength,
                true,
                this.props.maxLengthMsg
            ),
            emailRules: email(this.props.value, true, this.props.emailMsg),
        };
    }

    // shouldComponentUpdate (nextProps, nextState, nextContext) {
    //     return nextProps.value !== this.props.value;
    // }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.value !== prevState.value ||
            nextProps.requiredMsg !== prevState.requiredRules.message
        ) {
            let payload = {
                value: nextProps.value,
            };

            if (nextProps.required) {
                payload["requiredRules"] = required(
                    nextProps.value,
                    true,
                    nextProps.requiredMsg
                );
            }

            if (nextProps.minLength) {
                payload["minLengthRules"] = minLength(
                    nextProps.value,
                    nextProps.minLength,
                    true,
                    nextProps.minLengthMsg
                );
            }

            if (nextProps.maxLength) {
                payload["maxLengthRules"] = maxLength(
                    nextProps.value,
                    nextProps.maxLength,
                    true,
                    nextProps.maxLengthMsg
                );
            }

            if (nextProps.valid_email) {
                payload["emailRules"] = email(
                    nextProps.value,
                    true,
                    nextProps.emailMsg
                );
            }

            return payload;
        }

        return null;
    }

    render() {
        const {
            id,
            name,
            onChange,
            className,
            defaultValue,
            disabled,
            required,
            placeholder,
            minLength,
            maxLength,
            onBlur,
            valid_email,
        } = this.props;

        const {
            value,
            requiredRules,
            minLengthRules,
            maxLengthRules,
            emailRules,
        } = this.state;

        // validation
        const [requiredClass, requiredMsg] = validateDisplay(requiredRules);
        const [minLengthClass, minLengthMsg] = validateDisplay(minLengthRules);
        const [maxLengthClass, maxLengthMsg] = validateDisplay(maxLengthRules);
        const [emailClass, emailMsg] = validateDisplay(emailRules);

        return (
            <Fragment>
                <input
                    type="text"
                    id={id}
                    value={value}
                    className={`form-control ${requiredClass} ${minLengthClass} ${maxLengthClass} ${emailClass} ${className}`}
                    name={name}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    placeholder={placeholder}
                    minLength={minLength}
                    maxLength={maxLength}
                    onBlur={onBlur}
                    valid_email={valid_email}
                />

                <div className="invalid-feedback">
                    {requiredClass !== "is-valid" && requiredMsg}
                    {requiredClass === "is-valid" &&
                        minLengthClass === "is-invalid" &&
                        minLengthMsg}
                    {requiredClass === "is-valid" &&
                        maxLengthClass === "is-invalid" &&
                        maxLengthMsg}
                    {requiredClass === "is-valid" &&
                        emailClass === "is-invalid" &&
                        emailMsg}
                </div>
            </Fragment>
        );
    }
}

export default StringInput;

StringInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,

    defaultValue: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    minLength: PropTypes.string,
    maxLength: PropTypes.string,
    onBlur: PropTypes.func,
    requiredMsg: PropTypes.string,
    minLengthMsg: PropTypes.string,
    maxLengthMsg: PropTypes.string,
    valid_email: PropTypes.string,
    emailMsg: PropTypes.string,
};

StringInput.defaultProps = {
    className: "",
};
