import OrderDetail from "../../pages/orderDetails/index";

const routes = [
    {
        path: "/order/detail",
        component: OrderDetail,
    },
];

export default routes;
