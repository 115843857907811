import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
//import { t, setLocale } from "react-i18nify";
// import { t } from "../../common/translation";
import PasswordInput from "../../components/input/PasswordInput";
import PageHeader from "../../components/PageHeaderV2";
import LoadingMask from "../../components/loadingMask";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";
import Modal2 from "../../components/modal";
import { Modal } from "antd";
import { t, setLanguageFileJson } from "../../common/translation";

import { postResetSecondaryPassword } from "../../store/ducks/changeSecondaryPassword/actions";
import { openModal, closeModal } from "../../store/ducks/modal/actions";
import { postLogin } from "../../store/ducks/login/actions";

import { fieldChecking, GenHash } from "../../common/helpers";
import { formValidation } from "../../common/validator/formValidation";

import "./resetSecondaryPassword.scss";

class resetTradingPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transLabel: "resetTradingPassword.",
            errType: "",
            changeTradingPwdShow: false,
            loginPassword: "",
            showTwoFAModal: false,
            totp: "",
        };

        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleTradingPwdSubmit = this.handleTradingPwdSubmit.bind(this);
        this.handleLoginPassword = this.handleLoginPassword.bind(this);
        this.handleTwoFACodeValue = this.handleTwoFACodeValue.bind(this);

        //  setLocale(sessionStorage.getItem("lang"));
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `50px`);
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });
        formValidation("reset-secondary-password-form");
    }

    handleTwoFACodeValue(event) {
        this.setState({ totp: event.target.value });
    }

    handleTradingPwdSubmit(e) {
        e.preventDefault();

        let validate = fieldChecking(
            document.getElementById("reset-secondary-password-form")
        );

        if (validate === false) {
            return false;
        }

        const data = {};

        data.username = sessionStorage.getItem("email");
        data.password = this.state.loginPassword;
        data.lang = sessionStorage.getItem("lang");
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.openModal("changeTradingPassword-loading-modal");

        this.props.postLogin(data, process.env.REACT_APP_GM_URL);
    }

    handleOk(e, loginPassword, totp) {
        const data = {};

        data.username = sessionStorage.getItem("email");
        data.password = loginPassword;
        data.lang = sessionStorage.getItem("lang");
        data.totp = totp;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.setState({
            showLoadingModal: true,
            twofa_visible: false,
        });

        this.props.openModal("changeTradingPassword-loading-modal");

        this.props.postLogin(data, process.env.REACT_APP_GM_URL);

        this.setState({
            showTwoFAModal: false,
        });

        this.props.openModal("changeTradingPassword-loading-modal");
    }

    handleCancel(e) {
        this.setState({
            showTwoFAModal: false,
        });
    }

    handleLoginPassword(event) {
        document
            .getElementById("reset-secondary-password-form")
            .classList.remove("was-validated");
        this.setState({ loginPassword: event.target.value });
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");
        this.props.history.push("/profile");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.resetSecondaryPasswordData !==
            this.props.resetSecondaryPasswordData
        ) {
            if (nextProps.resetSecondaryPasswordData.rst === "1") {
                this.setState({
                    showLoadingModal: false,
                    successMsg: nextProps.resetSecondaryPasswordData.msg,
                });

                this.props.openModal("success-modal");
            } else {
                this.setState({
                    error_visible: true,
                    errMsg: nextProps.resetSecondaryPasswordData.msg,
                    showLoadingModal: false,
                });
                this.props.openModal("error-modal");
            }
        }

        if (nextProps.login_data !== this.props.login_data) {
            if (nextProps.login_data.rst === "1") {
                const data = {};

                data.email = sessionStorage.getItem("email");
                data.lang = sessionStorage.getItem("lang");
                let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
                data.hash = hash;

                this.props.postResetSecondaryPassword(data);
            } else {
                switch (nextProps.login_data.msg) {
                    case "invalid_totp":
                        this.setState({
                            showTwoFAModal: true,
                        });
                        break;
                    case "verify_email_required":
                        this.setState({
                            resend_visible: true,
                            errMsg: t("login." + nextProps.login_data.msg),
                            showResendAccountActivationModal: true,
                        });
                        this.props.openModal("error-modal");
                        break;
                    case "failed":
                        this.setState({
                            error_visible: true,
                            errMsg: t("common.system_error"),
                            showLoadingModal: false,
                            showErrorModal: true,
                        });
                        this.props.openModal("error-modal");
                        break;
                    case "system_error":
                        this.setState({
                            error_visible: true,
                            errMsg: t("common.system_error"),
                            showLoadingModal: false,
                            showErrorModal: true,
                        });
                        this.props.openModal("error-modal");
                        break;
                    default:
                        this.setState({
                            error_visible: true,
                            errMsg: t("login." + nextProps.login_data.msg),
                            showLoadingModal: false,
                        });
                        this.props.openModal("error-modal");
                        break;
                }
                this.setState({
                    showLoadingModal: false,
                });
            }
        }

        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName ===
                "changeTradingPassword-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }
    }

    render() {
        const {
            transLabel,
            showLoadingModal,
            showErrorModal,
            showSuccessModal,
            errMsg,
            successMsg,
            showTwoFAModal,
        } = this.state;

        return (
            <div className="resetTradingPassword">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    historyBack={"/secondaryPassword/update"}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={t("resetTradingPassword.reset_trading_password")}
                    arrowLeft={true}
                />

                {showLoadingModal && (
                    <Modal2
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="changeTradingPassword-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showErrorModal && (
                    <Modal2
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn col-12"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showSuccessModal && (
                    <Modal2
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn col-12"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <Modal
                    title={t("changeTradingPassword.forgot_trading_password")}
                    visible={showTwoFAModal}
                    onOk={(e) =>
                        this.handleOk(
                            e,
                            this.state.loginPassword,
                            this.state.totp
                        )
                    }
                    footer={[
                        <button
                            key="submit"
                            className="col-12 btn btn-primary"
                            onClick={(e) =>
                                this.handleOk(
                                    e,
                                    this.state.loginPassword,
                                    this.state.totp
                                )
                            }
                        >
                            {t("common.ok")}
                        </button>,
                    ]}
                    onCancel={this.handleCancel}
                >
                    <div className="col-12">
                        <p className="verify-2fa-content">
                            {t("login.please_enter_2fa_code")}:
                        </p>
                    </div>
                    <div className="col-12">
                        <input
                            type="text"
                            id="two_fa_code"
                            name="two_fa_code"
                            className="form-control"
                            value={this.state.totp}
                            onChange={this.handleTwoFACodeValue}
                            required
                        />
                        <div className="invalid-feedback">
                            {t("login.requiredMsg")}
                        </div>
                    </div>
                </Modal>

                <div className="pt-4 px-4 offset-md-2 col-md-8 overflow-auto custom-height">
                    <form
                        method="post"
                        id="reset-secondary-password-form"
                        className="needs-validation"
                        noValidate
                        onSubmit={this.handleTradingPwdSubmit}
                    >
                        <div className="form-group">
                            <label className="depth-gray-label text-uppercase">
                                {t("changeTradingPassword.login_password")}*
                            </label>
                            <PasswordInput
                                id="loginPassword"
                                name="loginPassword"
                                onChange={this.handleLoginPassword}
                                value={this.state.loginPassword}
                                placeholder={t(
                                    "register.please_enter_password"
                                )}
                                className="form-control depth-input"
                                required
                                requiredMsg={t("validator.requiredMsg")}
                                minLengthMsg={t("register.minLengthMsg", {
                                    count: 8,
                                })}
                                minLength="8"
                                maxLength="255"
                                maxLengthMsg={t("register.maxLengthMsg", {
                                    count: 255,
                                })}
                                passwordformat="true"
                                passwordFormatMsg={t(
                                    "register.passwordFormatMsg"
                                )}
                            />
                        </div>

                        <div className="form-group text-right">
                            <button
                                type="submit"
                                className="col-12 depth-blue-btn"
                            >
                                {t(transLabel + "reset")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { login, changeSecondaryPassword, modal } = state;

    return {
        resetSecondaryPasswordData:
            changeSecondaryPassword.resetSecondaryPasswordData,
        classIsShowing: modal.classIsShowing,
        login_data: login.login_data,
    };
};

const mapDispatchToProps = {
    postResetSecondaryPassword,
    openModal,
    closeModal,
    postLogin,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(resetTradingPassword);
