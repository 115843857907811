import React, { Component } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import Select from '../../../components/input/Select/index';
import StringInput from "../../../components/input/StringInput/index";
import PasswordInput from "../../../components/input/PasswordInput/index";
import NumberInput from "../../../components/input/NumberInput";
import { Icon, Input, Select } from "antd";
import { GenHash, fieldChecking } from "../../../common/helpers";
import { connect } from "react-redux";
import { formValidation } from "../../../common/validator/formValidation";
import Modal from "../../../components/modal";
import LoadingMask from "../../../components/loadingMask";
import ErrorIcon from "../../../components/ErrorAlert";
import SuccessIcon from "../../../components/SuccessAlert";

import { getLanguageList } from "../../../store/ducks/home/actions";
import { postRegister } from "../../../store/ducks/register/actions";
import { openModal, closeModal } from "../../../store/ducks/modal/actions";
import { getCountry } from "../../../store/ducks/emall/actions";

import "./registration.scss";
import "../index.scss";

import { t } from "../../../common/translation";

const { Option } = Select;

class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            email: "",
            referralUsername: "",
            confirmPassword: "",
            country_prefix: "+93",
            mobile_no: "",
            autoLogin: 1,
            country_options: [],
            mobile_prefix: [],
            sms_verification_code: "",
            loading: false,
            showLoadingModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            errMsg: "",
            successMsg: "",
            referralStatus: false,
            giftCardValue: "",
            country_new: [],
            options: [],
            language: "",
        };

        this.handleLanguage = this.handleLanguage.bind(this);
        this.handleUsername = this.handleUsername.bind(this);
        this.handleVerificationCode = this.handleVerificationCode.bind(this);
        this.handleCountryPrefix = this.handleCountryPrefix.bind(this);
        this.handleSubmitRegister = this.handleSubmitRegister.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.handleReferral = this.handleReferral.bind(this);
        this.handleMobileNo = this.handleMobileNo.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleGiftCardCode = this.handleGiftCardCode.bind(this);
        this.onLangChange = this.onLangChange.bind(this);
        this.onLangClickOuside = this.onLangClickOuside.bind(this);
        this.onDropDownChange = this.onDropDownChange.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `50px`);
    }

    onDropDownChange(e) {
        this.setState({ country_prefix: e });
    }

    onLangClickOuside() {
        this.setState({ langShow: "" });
    }

    onLangChange() {
        if (this.state.langShow === "show") {
            this.setState({ langShow: "" });
        } else {
            this.setState({ langShow: "show" });
        }
    }

    UNSAFE_componentWillMount() {
        if (sessionStorage.getItem("langList") === null) {
            this.props.getLanguageList();
        } else {
            this.setState({
                options: JSON.parse(sessionStorage.getItem("langList")),
            });
        }
    }

    handleLanguage(event, lang) {
        //  setLocale(lang);

        sessionStorage.setItem("lang", lang);
        this.setState({ language: lang, langShow: "" });

        let data = {};

        data.langCode = lang;
        data.parentId = 0;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.getCountry(data);
    }

    handleSubmitRegister(e) {
        e.preventDefault();

        let validate = fieldChecking(document.getElementById("register-form"));

        if (validate === false) {
            return false;
        }

        this.props.openModal("register-loading-modal");

        const data = [];
        let hash = "";

        data["username"] = this.state.username;
        data["password"] = this.state.password;
        data["confirmPassword"] = this.state.confirmPassword;
        data["mobilePrefix"] = this.state.country_prefix;
        data["mobileNumber"] = this.state.mobile_no;
        data["email"] = this.state.email;
        data["referralUsername"] =
            this.state.referralUsername == null
                ? ""
                : this.state.referralUsername;
        data["autoLogin"] = this.state.autoLogin;
        data["giftCardCode"] = this.state.giftCardValue;
        data["langCode"] = sessionStorage.getItem("lang");
        hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data["hash"] = hash;

        this.props.postRegister(data);
    }

    handleReferral(event) {
        this.setState({ referralUsername: event.target.value });
    }

    handleMobileNo(event) {
        this.setState({ mobile_no: event.target.value });
    }

    handleEmail(event) {
        this.setState({ email: event.target.value });
    }

    handlePassword(event) {
        this.setState({ password: event.target.value });
    }

    handleConfirmPassword(event) {
        this.setState({ confirmPassword: event.target.value });
    }

    handleUsername(event) {
        if (
            !event.target.value.match(
                /[\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF]+/g
            )
        ) {
            this.setState({ username: event.target.value });
        }
    }

    handleVerificationCode(event) {
        this.setState({ verification_code: event.target.value });
    }

    handleCountryPrefix(event) {
        this.setState({ country_prefix: event.target.value });
    }

    handleGiftCardCode(event) {
        this.setState({ giftCardValue: event.target.value });
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });

        let data = {};

        if (
            sessionStorage.getItem("lang") === undefined ||
            sessionStorage.getItem("lang") === null
        ) {
            sessionStorage.setItem("lang", "en");
        }

        data.langCode = sessionStorage.getItem("lang");
        data.parentId = 0;

        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.getCountry(data);

        formValidation("register-form");

        let lang = !sessionStorage.hasOwnProperty("lang")
            ? "en"
            : sessionStorage.getItem("lang");

        //setLocale(lang);

        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        this.setState(
            {
                referralUsername:
                    params.get("referral") === null
                        ? ""
                        : params.get("referral"),
                referralStatus: params.get("referral") === null ? false : true,
            },
            () => {}
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.language_data !== this.props.language_data) {
            console.log(nextProps.language_data);
            if (nextProps.language_data.rst === "1") {
                this.setState({
                    options: nextProps.language_data.data,
                });

                sessionStorage.setItem(
                    "langList",
                    JSON.stringify(nextProps.language_data.data)
                );
            }
        }

        if (nextProps.classIsShowing !== this.props.classIsShowing) {
            if (
                nextProps.classIsShowing.modalName === "register-loading-modal"
            ) {
                this.setState({
                    showLoadingModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "error-modal") {
                this.setState({
                    showErrorModal: nextProps.classIsShowing.isShowing,
                });
            }

            if (nextProps.classIsShowing.modalName === "success-modal") {
                this.setState({
                    showSuccessModal: nextProps.classIsShowing.isShowing,
                });
            }
        }

        if (nextProps.registerData !== this.props.registerData) {
            if (nextProps.registerData.data.rst === "1") {
                this.setState({
                    showLoadingModal: false,
                    successMsg: nextProps.registerData.data.msg,
                });

                this.props.openModal("success-modal");
            } else {
                this.setState({
                    showLoadingModal: false,
                    errMsg: nextProps.registerData.data.msg,
                });

                this.props.openModal("error-modal");
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.rst === "1") {
                let prefix_arr = [];

                this.setState({
                    country_new: nextProps.country_data.data[0].country,
                });

                nextProps.country_data.data[0].country.map((option, i) => {
                    prefix_arr.push({
                        country_id: option.calling_no_prefix,
                        country_name: option.name,
                        country_mobile_no_prefix: option.calling_no_prefix,
                        country_code: option.code,
                    });
                    return i;
                });

                this.setState({ mobile_prefix: prefix_arr });
            }
        }
    }

    handleSuccessClose() {
        this.props.closeModal("success-modal");

        this.props.history.push("/login");
    }

    handleErrorClose() {
        this.props.closeModal("error-modal");
    }

    render() {
        const {
            showLoadingModal,
            showErrorModal,
            errMsg,
            successMsg,
            showSuccessModal,
            langShow,
            options,
        } = this.state;

        const handleLanguage = this.handleLanguage;

        const selection = options.map(function (item, index) {
            return (
                <a
                    key={index}
                    className="dropdown-item"
                    onClick={(e) => handleLanguage(e, item.value)}
                >
                    {item.name}
                </a>
            );
        });
        return (
            <div className="register-panel">
                <div className="row container-fluid page-header-style mx-0">
                    <div className={`fa-pull-left mt-3 sub-title-div`}>
                        <a
                            onClick={this.props.history.goBack}
                            className={`text-light`}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </a>
                    </div>

                    <h4 className="col-12 text-center header-title-style">
                        {t("register.title")}
                    </h4>
                    <Icon
                        type={"global"}
                        className="header-title-style sub-lang-div"
                        onClick={this.onLangChange}
                    />
                </div>

                <div
                    className={
                        "dropdown-menu " + langShow + " lang-select-style"
                    }
                    aria-labelledby="dropdownMenuButton"
                    x-placement="bottom-start"
                >
                    {selection}
                </div>

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="register-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={this.handleSuccessClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}

                <div
                    className="offset-md-2 col-md-8 px-0 overflow-auto custom-height"
                    // style={{ height: "calc(var(--vh, 1vh) * 90)" }}
                    onClick={this.onLangClickOuside}
                >
                    <div className="px-3 pb-3">
                        <form
                            id="register-form"
                            onSubmit={this.handleSubmitRegister}
                            className="txt-black needs-validation"
                            noValidate
                        >
                            <input
                                type="hidden"
                                name="referral_username"
                                value={this.state.referralUsername}
                            />

                            <label className="depth-gray-label text-uppercase">
                                {t("register.email")}*
                            </label>
                            <div className="form-group">
                                <StringInput
                                    id="email"
                                    name="email"
                                    onChange={this.handleEmail}
                                    value={this.state.email}
                                    placeholder={t(
                                        "register.please_enter_email"
                                    )}
                                    className="remove-placeholder depth-input"
                                    required
                                    requiredMsg={t("validator.requiredMsg")}
                                    valid_email="true"
                                    emailMsg={t("register.validEmail")}
                                />
                            </div>

                            <label className="depth-gray-label text-uppercase">
                                {t("register.username")}*
                            </label>
                            <div className="form-group">
                                <StringInput
                                    id="username"
                                    name="username"
                                    onChange={this.handleUsername}
                                    value={this.state.username}
                                    placeholder={t(
                                        "register.please_enter_username"
                                    )}
                                    className="remove-placeholder depth-input"
                                    required
                                    requiredMsg={t("validator.requiredMsg")}
                                    minLengthMsg={t("register.minLengthMsg", {
                                        count: 3,
                                    })}
                                    minLength="3"
                                    maxLength="20"
                                    maxLengthMsg={t("register.maxLengthMsg", {
                                        count: 20,
                                    })}
                                />
                                <p className="remark-gray-style">
                                    {t("register.username_remark")}
                                </p>
                            </div>

                            <label className="depth-gray-label text-uppercase">
                                {t("register.password")}*
                            </label>
                            <div className="form-group">
                                <PasswordInput
                                    id="password"
                                    name="password"
                                    onChange={this.handlePassword}
                                    value={this.state.password}
                                    placeholder={t(
                                        "register.please_enter_password"
                                    )}
                                    className="remove-placeholder depth-input"
                                    required
                                    requiredMsg={t("validator.requiredMsg")}
                                    minLengthMsg={t("register.minLengthMsg", {
                                        count: 8,
                                    })}
                                    minLength="8"
                                    maxLength="16"
                                    maxLengthMsg={t("register.maxLengthMsg", {
                                        count: 16,
                                    })}
                                    passwordFormatMsg={t(
                                        "register.passwordFormatMsg"
                                    )}
                                    passwordformat="true"
                                />
                                <p className="remark-gray-style">
                                    {t("register.password_remark")}
                                </p>
                            </div>

                            <label className="depth-gray-label text-uppercase">
                                {t("register.confirm_password")}*
                            </label>
                            <div className="form-group">
                                <PasswordInput
                                    id="confirm_password"
                                    name="confirm_password"
                                    onChange={this.handleConfirmPassword}
                                    value={this.state.confirmPassword}
                                    placeholder={t(
                                        "register.please_re_enter_password"
                                    )}
                                    className="remove-placeholder depth-input"
                                    required
                                    requiredMsg={t("validator.requiredMsg")}
                                    confirmpassword="true"
                                    oldPassword={this.state.password}
                                    confirmPasswordMsg={t(
                                        "register.checkPasswordMatch"
                                    )}
                                />
                            </div>

                            <label className="depth-gray-label text-uppercase">
                                {t("register.mobile")}
                            </label>
                            <div className="form-group">
                                <Input.Group compact>
                                    <Select
                                        size="large"
                                        style={{ width: "40%" }}
                                        defaultValue={this.state.country_prefix}
                                        value={this.state.country_prefix}
                                        id="status"
                                        name="country_prefix"
                                        onChange={this.onDropDownChange}
                                    >
                                        {this.state.mobile_prefix.map(
                                            (option, index) => (
                                                <Option
                                                    value={option.country_id}
                                                >
                                                    {option.country_mobile_no_prefix &&
                                                        option.country_name +
                                                            " - "}

                                                    {
                                                        option.country_mobile_no_prefix
                                                    }
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                    <Input
                                        style={{ width: "60%" }}
                                        id="mobile_no"
                                        name="mobile_no"
                                        size="large"
                                        onChange={this.handleMobileNo}
                                        value={this.state.mobile_no}
                                        className="depth-input"
                                        placeholder={t(
                                            "register.please_enter_phone_number"
                                        )}
                                    />
                                </Input.Group>
                                {/*<div className="input-group">*/}
                                {/*    <Select*/}
                                {/*        id="status"*/}
                                {/*        className="form-control col-3 register-mobile-country-prefix depth-input"*/}
                                {/*        name="country_prefix"*/}
                                {/*        options={this.state.mobile_prefix}*/}
                                {/*        onChange={this.handleCountryPrefix}*/}
                                {/*    />*/}
                                {/*    <NumberInput*/}
                                {/*        type="number"*/}
                                {/*        id="mobile_no"*/}
                                {/*        name="mobile_no"*/}
                                {/*        onChange={this.handleMobileNo}*/}
                                {/*        value={this.state.mobile_no}*/}
                                {/*        className="form-control depth-input"*/}
                                {/*        placeholder={t("register.please_enter_phone_number")}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>

                            <label className="depth-gray-label text-uppercase">
                                {t("register.referral_username_optional")}
                            </label>
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder={t(
                                        "register.please_enter_referral_username"
                                    )}
                                    onChange={this.handleReferral}
                                    value={this.state.referralUsername}
                                    className="form-control remove-placeholder depth-input"
                                    disabled={this.state.referralStatus}
                                />
                            </div>

                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="col-12 depth-blue-btn"
                                >
                                    {t("register.register")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { register, modal, emall, walletBalance } = state;

    return {
        registerData: register.data,
        classIsShowing: modal.classIsShowing,
        country_data: emall.get_country,
        language_data: walletBalance.getLanguageList,
    };
};

const mapDispatchToProps = {
    postRegister,
    getCountry,
    openModal,
    closeModal,
    getLanguageList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
