import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setLocale, t} from 'react-i18nify';
// import { Carousel } from 'antd';
// import product1 from "../../public/product-image/product1.jpg";
// import product2 from "../../public/product-image/product2.jpg";
// import product3 from "../../public/product-image/product3.jpg";
// import product4 from "../../public/product-image/product4.jpg";
// import starOnIcon from "../../public/images/star-off@3x.png";
// import starOffIcon from "../../public/images/star-off@3x.png";
// import favoriteIcon from "../../public/images/icon-Heart-off@3x.png";
// import shareIcon from "../../public/images/icon-share@3x.png";
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Icon, Tabs } from 'antd';
// import InfiniteScroll from 'react-infinite-scroller';
//
// import PageHeader from '../../components/PageHeader';
// import TransPanel from '../../components/transPanel';
// import LoadingMask from '../../components/loadingMask';
// import Modal from "../../components/modal";
// import ErrorIcon from "../../components/ErrorAlert";

import { getOrderList } from '../../store/ducks/emall/actions';
import { postTransactionList } from '../../store/ducks/walletDetails/actions';

// import { GenHash } from '../../common/helpers';

import './myOrders.scss';
import PageHeader from "../../components/PageHeader";
// import product1 from "../../public/product-image/product1.jpg";
import {Link} from "react-router-dom";
import {GenHash} from "../../assets/js/helpers";
// import {Link} from "react-router-dom";

class MyOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialAmount: 1,
      amount: 1,
      data: [],
      headerHeight: window.innerWidth
    };

    this.crousel = React.createRef();

    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
    this.handleClickOnTag = this.handleClickOnTag.bind(this);
    this.onTest = this.onTest.bind(this);
  }

  componentDidMount() {
    setLocale(sessionStorage.getItem("lang"));

    window.addEventListener("resize", this.updateHeight);

    const data = {};

    data.username = sessionStorage.getItem("email");
    data.lang = sessionStorage.getItem("lang");
    data.type = '';
    let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
    data.hash = hash;

    this.props.getOrderList(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

    if(nextProps.data !== this.props.data){

      if(nextProps.data.rst === "1"){
        let array;
        array = nextProps.data.data;

        this.setState({data: array});
      }
    }
  }

  updateHeight(){
    if (window.innerWidth <= 768) {
      this.setState({headerHeight: window.innerWidth});
    }
    else {
      this.setState({headerHeight: 768});
    }
  }

  onTest(e){
    this.crousel.goTo(1);
  }

  handleClickOnTag(key) {
    const data = {};

    data.username = sessionStorage.getItem("email");
    data.lang = sessionStorage.getItem("lang");
    data.type = key==='all' ? '' : key;
    let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
    data.hash = hash;

    this.props.getOrderList(data);
  }

  onChangeAmount(e) {

    let amount = this.state.amount;

    if (e.target.id === 'plus'){
      this.setState({amount: amount + 1});
    }
    else if (e.target.id === 'minus' && amount !== 0){
      this.setState({amount: amount - 1});
    }
  }

  render() {

    const {data} = this.state;

    const TabPane = Tabs.TabPane;

    let allItemRows;

    if (data.length > 0){
      allItemRows = data.map(function (item, i){
        return (
            <Link to={"/emall/order-details/" + item.doc_no} key={i}>
              <li className="list-group-item asset-panel-list-group-item col-12">
                <div className="row">
                  <div className="col-10 fa-pull-left">
                    <b>{t('emall.order')}&nbsp; {item.doc_no}</b>
                  </div>
                  <div className="col-2 fa-pull-right">
                    <small className="fa-pull-right">{item.status ? item.status : 'Pending'}</small>
                  </div>
                </div>
                <div className="row">
                  <small className="col-12">{t('emall.placed_on')}&nbsp; {item.created_at}</small>
                </div>
                {/*<div className="row">*/}
                {/*  <small className="col-12">Paid on 20 AUg 2019 16:22:13</small>*/}
                {/*</div>*/}
                {item.items.map(function (item2, k) {
                  return (
                      <div className="row" key={k}>
                        <div className="col-4 col-md-2 container fa-pull-left pr-0">
                          <img src={item2.image} sizes={10} alt={1}/>
                        </div>
                        <div className="col-8 col-md-10 container fa-pull-right pl-0 pt-2">
                          <div className="col-12 product-list-label-style">
                            <small>{item2.product_name}</small>
                          </div>
                          <div className="col-12">
                            {item2.prices.map(function (item3, j) {
                              return (
                                  <small key={j}><b>{item3.display_name}&nbsp;{item3.unit_price}{item2.prices.length !== j + 1 ? ',' : ''}&nbsp;</b></small>
                              );
                            })}
                          </div>
                          <div className="col-12">
                            <sub><b>{t('emall.qty')}: {item2.quantity}</b></sub>
                          </div>
                        </div>
                      </div>
                  );
                })}

                <div className="row">
                  <div className="col-12 container pt-2">
                    {item.delivery_status && item.delivery_label_class &&
                    <span className={"badge badge-pill badge-"+item.delivery_label_class+" fa-pull-right"}>{item.delivery_status} ></span>
                    }

                  </div>
                  {/*<div className="col-12 container pt-2">*/}
                  {/*  <small className="fa-pull-right">1 Item, Total: RM308.00</small>*/}
                  {/*</div>*/}
                </div>
              </li></Link>
        );
      });
    }else{
      allItemRows = <div className="col-12">
        <div className="row">
          <div className="mx-auto"><span><small><b>{t('emall.no_record')}</b></small></span></div>
        </div>
      </div>
    }


    const orderList = <aside className="list-group">{allItemRows}</aside>;

    return (
        <div className="myOrders">
          <div className="container-fluid px-0">
            <PageHeader
                subTitle={t('myOrders.my_orders')}
                navClass="title white-color"
                backgroundHeight={80}
                subPageHistoryBack={"/profile"}
                walletType={""}
                arrowLeft={true}
            />
          </div>

          <Tabs defaultActiveKey="1" onChange={this.handleClickOnTag}>
            <TabPane tab={<span><Icon type="unordered-list" />{t('myOrders.all')}</span>} key="all" id="all">{orderList}</TabPane>
            {/*<TabPane tab={<span><Icon type="clock-circle" />Pending</span>} key="2">{orderList}</TabPane>*/}
            <TabPane tab={<span><Icon type="loading-3-quarters" />{t('myOrders.processing')}</span>} key="processing" id="process">{orderList}</TabPane>
            <TabPane tab={<span><Icon type="shopping-cart" />{t('myOrders.delivering')}</span>} key="delivering" id="delivering">{orderList}</TabPane>
            <TabPane tab={<span><Icon type="check-circle" />{t('myOrders.delivered')}</span>} key="delivered" id="delivered">{orderList}</TabPane>
          </Tabs>
        </div>
    );
  }
}

const mapStateToProps = state => {
  const { emall, walletDetails } = state;

  return {
    data: emall.data,
    transaction_list: walletDetails.data
  };
};

const mapDispatchToProps = {
  getOrderList,
  postTransactionList
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyOrders);
