import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Lottie from "react-lottie";
import animation from "../../public/gm-loading";

class LoadingMask extends Component {

    render() {

        const {width} = this.props;

        const LoadingMask = () => (
            <Lottie
                options={{
                    animationData: animation
                }}
                width={width}
            />
        );

        return (
            <LoadingMask />
        )
    }
}

export default LoadingMask;

LoadingMask.propTypes = {
    width: PropTypes.any.isRequired
};