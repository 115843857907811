import store from "../store/store";
var languageFile = {};

export function t(value, transArray = {}) {
    let splitedValue = value.split(".");
    let group = splitedValue[0];
    let name = splitedValue[1];
    if (Object.keys(languageFile).length > 0) {
        if (languageFile[group] && languageFile[group][name]) {
            if (Object.keys(transArray).length > 0) {
                let wording = languageFile[group][name];
                Object.keys(transArray).map((value) => {
                    wording = wording.replaceAll(
                        "%{" + value + "}",
                        transArray[value]
                    );
                });
                return wording;
            }
            return languageFile[group][name];
        }
    } else {
        let state = store.getState();
        if (
            state.walletBalance.getLanguageFile &&
            Object.keys(state.walletBalance.getLanguageFile).length
        ) {
            languageFile = state.walletBalance.getLanguageFile.data;

            if (languageFile[group] && languageFile[group][name]) {
                return languageFile[group][name];
            }
        }
    }
    return name;
}

export function setLanguageFileJson() {
    let state = store.getState();
    if (state.walletBalance.getLanguageFile) {
        languageFile = state.walletBalance.getLanguageFile.data;
    }
}
