import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale, t } from "react-i18nify";

import { Link } from "react-router-dom";

import PageHeader from "../../components/PageHeaderV2";

import { postMultinaryTreeDetails } from "../../store/ducks/multinaryTree/actions";

import { GenHash } from "../../common/helpers";

import btnPlus from "../../public/images/btn-plus@3x.png";
import btnMinus from "../../public/images/btn-minus@3x.png";
import btnCancel from "../../public/v2/icon/icon-close.svg";

import "react-accessible-accordion/dist/fancy-example.css";
import "./multinaryDetails.scss";

class myGroupDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expandedKeys: [],
            searchValue: "",
            autoExpandParent: true,
            sponsor_tree_data: [],
            treeData: [],
            sponsorTreeShow: false,
            screenHeight: window.innerHeight - 80,
            community_group: 1,
            group_data: {
                l_accumulate: "0.00",
                r_accumulate: "0.00",
                l_group_yesterday_amount: "0.00",
                r_group_yesterday_amount: "0.00",
                l_group_today_amount: "0.00",
                r_group_today_amount: "0.00",
            },
            group_one: {
                accumulate: "0.00",
                total: "0.00",
                bringForward: "0.00",
                new: "0.00",
                leg: [],
            },
            group_two: {
                accumulate: "0.00",
                total: "0.00",
                bringForward: "0.00",
                new: "0.00",
                leg: [],
            },
            leg: [],
            legTwo: [],
            accordionItem1ExpandStatus: btnPlus,
            accordionHeading1Css: "",
            accordionItem2ExpandStatus: btnPlus,
            accordionHeading2Css: "",
            limit: 25,
            currentPage: 1,
            totalPage: 0,
            hasMoreItems: true,
            prevPath: "",
        };

        const data = {};

        data.username =
            this.props.location.state === undefined
                ? sessionStorage.getItem("email")
                : this.props.location.state.member;
        data.token = sessionStorage.getItem("token");
        data.lang = sessionStorage.getItem("lang");
        data.page = 1;
        data.group = this.props.location.state.group;
        data.lot =
            this.props.location.state.lot === undefined
                ? 1
                : this.props.location.state.lot;
        data.limit = this.state.limit;
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postMultinaryTreeDetails(data);

        this.updateHeight = this.updateHeight.bind(this);
        this.onClickAccordionOne = this.onClickAccordionOne.bind(this);
        this.onClickAccordionTwo = this.onClickAccordionTwo.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.handleBack = this.handleBack.bind(this);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.setProperty("--header", `50px`);
    }

    renderItem(item, i) {
        const itemRows = [
            <div className="p-2 bottom-color" key={i}>
                <div className="row">
                    <div className="col-4 text-left">
                        <Link
                            to={{
                                pathname: "/friends/group",
                                state: {
                                    selectedCommMember: item.email,
                                    lot: item.lot,
                                },
                            }}
                        >
                            {item.display_email}
                        </Link>
                    </div>
                    <div className="col-4 text-right">{item.new}</div>
                    <div className="col-4 text-right">{item.bringForward}</div>
                </div>
            </div>,
        ];

        return itemRows;
    }

    loadMoreList(e, page) {
        const data = {};
        let currentPage = parseInt(this.state.currentPage) + 1;

        data.page = currentPage;
        data.limit = this.state.limit;
        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.lang = sessionStorage.getItem("lang");
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        if (currentPage >= this.state.totalPage) {
            this.setState({
                hasMoreItems: false,
                loading: false,
            });
        }

        this.props.postMultinaryTreeGroup(data);
    }

    handleBack() {
        this.props.history.goBack();
    }

    componentWillMount() {
        setLocale(sessionStorage.getItem("lang"));

        if (this.props.location.state !== undefined) {
            this.setState({
                community_group: this.props.location.state.group,
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--header", `50px`);
        });
        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.multinary_group !== this.props.multinary_group) {
            if (nextProps.multinary_group.rst === "1") {
                let array;

                array = this.state.leg;

                nextProps.multinary_group.data.l_members.map(function (
                    item,
                    i
                ) {
                    array.push(item);
                    return array;
                });

                this.setState(
                    {
                        group_data: nextProps.multinary_group.data,
                        group_one: nextProps.multinary_group.data.l_members,
                        group_two: nextProps.multinary_group.data.r_members,
                        leg: array,
                        legTwo: nextProps.multinary_group.data.r_members,
                        currentPage: nextProps.multinary_group.data.currentPage,
                        totalPage: nextProps.multinary_group.data.totalPage,
                        loading: false,
                        prevPath: this.props.location,
                    },
                    () => {}
                );
            } else {
                switch (nextProps.multinary_group.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                        });
                        break;
                    case "system_error":
                        this.setState({
                            errMsg: t(
                                "common.err." + nextProps.multinary_group.msg
                            ),
                            errType: "system_error",
                            sponsorTreeShow: true,
                        });
                        break;
                    default:
                        this.setState({
                            errMsg: nextProps.multinary_group.msg,
                        });
                        break;
                }
            }
        }
    }

    updateHeight() {
        this.setState({ screenHeight: window.innerHeight - 80 });
    }

    onClickAccordionOne(e) {
        if (e.length > 0) {
            this.setState({
                accordionItem1ExpandStatus: btnMinus,
                accordionHeading1Css: "bottom__radius",
            });
        } else {
            this.setState({
                accordionItem1ExpandStatus: btnPlus,
                accordionHeading1Css: "",
            });
        }
    }

    onClickAccordionTwo(e) {
        if (e.length > 0) {
            this.setState({
                accordionItem2ExpandStatus: btnMinus,
                accordionHeading2Css: "bottom__radius",
            });
        } else {
            this.setState({
                accordionItem2ExpandStatus: btnPlus,
                accordionHeading2Css: "",
            });
        }
    }

    render() {
        let memberOneRows = [];

        const { leg } = this.state;

        if (leg.length > 0) {
            leg.map((item, i) => {
                const perItemRows = this.renderItem(item, i);
                memberOneRows = memberOneRows.concat(perItemRows);

                return memberOneRows;
            });
        }

        const memberTwoRows = this.state.legTwo.map(function (item, i) {
            return (
                <div className="p-2" key={i}>
                    <div className="row">
                        <div className="col-4 text-left">
                            <Link
                                to={{
                                    pathname: "/friends/group",
                                    state: {
                                        selectedCommMember: item.email,
                                        lot: item.lot,
                                    },
                                }}
                            >
                                {item.display_email}
                            </Link>
                        </div>
                        <div className="col-4 text-right">{item.new}</div>
                        <div className="col-4 text-right">
                            {item.bringForward}
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className="myGroupDetails">
                <PageHeader
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    goBackFunc={this.handleBack}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    walletType={""}
                    title={
                        t("multinaryTree.group") +
                        " " +
                        this.state.community_group
                    }
                    arrowLeft={true}
                    cancelBtnRight={
                        <Link to="/friends/group">
                            <img src={btnCancel} width={15} alt="" />
                        </Link>
                    }
                />

                <div className="background overflow-auto offset-md-2 col-md-8 px-0 custom-height">
                    <div className="card-header px-2">
                        <div className="row mt-3">
                            <div className="col-6 text-left text-secondary">
                                {t("multinaryTree.accumulative_left")}
                            </div>
                            <div className="col-6 text-right text-secondary">
                                {t("multinaryTree.accumulative_right")}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-left text-light">
                                {this.state.group_data.l_accumulate}
                            </div>
                            <div className="col-6 text-right text-light">
                                {this.state.group_data.r_accumulate}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-left text-secondary">
                                <strong>
                                    {t("multinaryTree.yesterday")} :
                                </strong>{" "}
                                <span className="text-white">
                                    {
                                        this.state.group_data
                                            .l_group_yesterday_amount
                                    }
                                </span>
                            </div>
                            <div className="col-6 text-right text-secondary">
                                <strong>
                                    {t("multinaryTree.yesterday")} :
                                </strong>{" "}
                                <span className="text-white">
                                    {
                                        this.state.group_data
                                            .r_group_yesterday_amount
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-left text-secondary">
                                <strong>{t("multinaryTree.today")} :</strong>{" "}
                                <span className="text-white">
                                    {this.state.group_data.l_group_today_amount}
                                </span>
                            </div>
                            <div className="col-6 text-right text-secondary">
                                <strong>{t("multinaryTree.today")} :</strong>{" "}
                                <span className="text-white">
                                    {this.state.group_data.r_group_today_amount}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div>
                            {/*<CoolTabs*/}
                            {/*    tabKey={'1'}*/}
                            {/*    style={{ width:  'auto', height:  200, background:  'white' }}*/}
                            {/*    activeTabStyle={{ background:  '#32076E', color:  '#96BAFF' }}*/}
                            {/*    unActiveTabStyle={{ background:  '#32076E', color:  'white' }}*/}
                            {/*    activeLeftTabBorderBottomStyle={{ background:  'white', height:  4 }}*/}
                            {/*    activeRightTabBorderBottomStyle={{ background:  'white', height:  4 }}*/}
                            {/*    // tabsBorderBottomStyle={{ background:  'orange', height:  4 }}*/}
                            {/*    leftContentStyle={{ background:  '#18072E' }}*/}
                            {/*    rightContentStyle={{ background:  '#18072E' }}*/}
                            {/*    leftTabTitle={<span className="text-uppercase">{t('multinaryTree.left')}</span>}*/}
                            {/*    rightTabTitle={<span className="text-uppercase">{t('multinaryTree.right')}</span>}*/}
                            {/*    leftContent={*/}
                            {/*        <div>*/}
                            {/*            <div className="p-2 group-container-member-header">*/}
                            {/*                <div className="row">*/}
                            {/*                    <div className="col-4 text-left">{t('multinaryTree.direct_member')}</div>*/}
                            {/*                    <div className="col-4 text-right">{t('multinaryTree.new')}</div>*/}
                            {/*                    <div className="col-4 text-right">{t('multinaryTree.accumulative')}</div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            {memberOneRows}*/}
                            {/*        </div>*/}
                            {/*    }*/}
                            {/*    rightContent={*/}
                            {/*        <div>*/}
                            {/*            <div className="p-2 group-container-member-header">*/}
                            {/*                <div className="row">*/}
                            {/*                    <div className="col-4 text-left">{t('multinaryTree.direct_member')}</div>*/}
                            {/*                    <div className="col-4 text-right">{t('multinaryTree.new')}</div>*/}
                            {/*                    <div className="col-4 text-right">{t('multinaryTree.accumulative')}</div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            {memberTwoRows}*/}
                            {/*        </div>*/}
                            {/*    }*/}
                            {/*    contentTransitionStyle={'transform 0.6s ease-in'}*/}
                            {/*    borderTransitionStyle={'all 0.6s ease-in'}/>*/}

                            <div>
                                <div className="p-2 group-container-member-header">
                                    <div className="row">
                                        <div className="col-4 text-left">
                                            {t("multinaryTree.direct_member")}
                                        </div>
                                        <div className="col-4 text-right">
                                            {t("multinaryTree.new")}
                                        </div>
                                        <div className="col-4 text-right">
                                            {t("multinaryTree.accumulative")}
                                        </div>
                                    </div>
                                </div>

                                {memberOneRows}

                                {memberTwoRows.length > 0 && (
                                    <hr className="my-2" />
                                )}

                                {memberTwoRows}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { multinaryTreeGroup } = state;

    return {
        multinary_group: multinaryTreeGroup.details,
    };
};

const mapDispatchToProps = {
    postMultinaryTreeDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(myGroupDetails);
