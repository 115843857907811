import * as type from './types';
import axios from 'axios';

/**
 *
 * @summary GET Admin Downline Data
 * @description Obtain admin's downline data with current role.
 *
 * @param {int}     [page=1]        Current page.
 * @param {int}     [limit=10]      Number of records.
 * @param {obj}     [order={}]      Sort with specific columns.
 * @param {string}  [admin_id=""]   Filter with Admin ID.
 * @param {string}  [email=""]      Filter with Email
 *
 * @returns {Function}
 *
 * 
 */

export const postSharePrice = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + "/member/shares/price", data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
       },
   }).then( (response) => {
        dispatch ({
            type: type.POST_SHAREPRICE,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_SHAREPRICE,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postShare = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + "/member/shares", data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
       },
   }).then( (response) => {
        dispatch ({
            type: type.POST_SHARE,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_SHARE,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};


export const postShareList = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + "/member/shares/contracts", data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
       },
   }).then( (response) => {
        dispatch ({
            type: type.POST_SHARELIST,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_SHARELIST,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postSharePayment = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + "/member/shares/payment", data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
       },
   }).then( (response) => {
        dispatch ({
            type: type.POST_SHAREPAYMENT,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_SHAREPAYMENT,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postShareMethod = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + "/member/shares/payment/method", data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
       },
   }).then( (response) => {
        dispatch ({
            type: type.POST_SHAREMETHOD,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_SHAREMETHOD,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};

export const postShareContract = (data) => dispatch => {
    axios.put(process.env.REACT_APP_GM_URL + "/member/shares/contracts/"+data.doc_no, data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem("accessToken")
       },
   }).then( (response) => {
        dispatch ({
            type: type.POST_SHARECONTRACT,
            payload: response.data
        })
    }).catch (error => {
        dispatch ({
            type: type.POST_SHARECONTRACT,
            payload: {"rst":"0","msg":"system_error"}
        });

    });
};
