import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Lottie from "react-lottie";
import SuccessIcon from "../../../src/public/alert-animation/json/sucees_anim";
import ErrorIcon from "../../../src/public/alert-animation/json/error-anim";
import WarningIcon from "../../../src/public/alert-animation/json/warning_anim";

class AlertUI extends Component {

    render() {

        const {width, height, alertType, msg} = this.props;

        /* Refer https://www.npmjs.com/package/lottie-react-web */
        const AlertMask = () => (
            <Lottie
                options={{
                    animationData: alertType === 'error' ? ErrorIcon : (alertType === 'success') ? SuccessIcon : WarningIcon
                }}
                width={width}
                height={height}
            />
        );

        return (
            <div className="row">
                <div className="col-12 text-center">
                    <AlertMask />
                    <h4>{msg}</h4>
                </div>
            </div>

        )
    }
}

export default AlertUI;

AlertUI.propTypes = {
    width: PropTypes.any.isRequired,
    height: PropTypes.any,
    msg: PropTypes.string.isRequired,
    alertType: PropTypes.string.isRequired,
};