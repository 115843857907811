import TwoFA from "../../pages/twoFactorAuthentication";
import DisableTwoFA from "../../pages/disableTwoFactorAuthentication";

const routes = [
    {
        path: "/2FA",
        component: TwoFA
    },
    {
        path: "/Disable2FA",
        component: DisableTwoFA
    }
];

export default routes;