import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: []
};

const giftCardReducer = createReducer(initialState) ({

    [type.GENERATE_GIFT_CARD]: (state, action) => ({
        ...state,
        data: action.payload.data,
    })

});

export default giftCardReducer;