import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale, t } from "react-i18nify";
import PageHeader from "../../components/PageHeaderV2";
import { Tooltip } from "antd";

import {
    postNetwork,
    postRedeemRequest,
} from "../../store/ducks/network/actions";
import { postPurchaseRate } from "../../store/ducks/purchaseRate/actions";
import "./average.scss";
import Modal from "../../components/modal";
import ConfirmationIcon from "../../components/ConfirmationAlert";
import ErrorIcon from "../../components/ErrorAlert";
import SuccessIcon from "../../components/SuccessAlert";
import LoadingMask from "../../components/loadingMask";

class Average extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: sessionStorage.getItem("email"),
            token: sessionStorage.getItem("token"),
            lang: sessionStorage.getItem("lang"),
            data: [],
            response: [],
            total_income: "0.00",
            last_week_income: "0.00",
            last_month_income: "0.00",
            accumulative_income: "0.00",
            direct_sponsor: "0.00",
            total_network: "0.00",
            total_sales: "0.00",
            direct_sponsor_income: "0.00",
            community_bonus_income: "0.00",
            star_bonus_income: "0.00",
            celeb_star_income: "0.00",
            referral_revenue_income: "0.00",
            referral_income: "0.00",
            challenge_days: 0,
            challenge_amount: 0,
            valid_redeem: "0",
            purchaseRate: [],
            showConfirmationModal: false,
        };

        const data = {};

        data.username = sessionStorage.getItem("email");
        data.lang = sessionStorage.getItem("lang");
        data.wallet_type =
            this.props.location.state !== undefined
                ? this.props.location.state.wallet_type
                : "PAC";

        this.props.postPurchaseRate(data);

        this.handleRedemption = this.handleRedemption.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        setLocale(sessionStorage.getItem("lang"));
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.network_data !== this.props.network_data) {
            if (nextProps.network_data.data.rst === "1") {
                let network_data = nextProps.network_data.data.data;

                this.setState({
                    total_income: network_data.total_income,
                    last_week_income: network_data.last_week_income,
                    last_month_income: network_data.last_month_income,
                    accumulative_income: network_data.accumulative_income,
                    direct_sponsor: network_data.direct_sponsor,
                    total_network: network_data.total_network,
                    total_sales: network_data.total_sales,
                    direct_sponsor_income: network_data.direct_sponsor_income,
                    community_bonus_income: network_data.community_bonus_income,
                    star_bonus_income: network_data.star_bonus_income,
                    celeb_star_income: network_data.celeb_star_income,
                    referral_income: network_data.referral_income,
                    referral_revenue_income:
                        network_data.referral_revenue_income,
                    challenge_days: network_data.challenge_days,
                    challenge_amount: network_data.challenge_amount,
                    valid_redeem: network_data.valid_redeem,
                });
            }
        }

        if (nextProps.redeem_data !== this.props.redeem_data) {
            if (nextProps.redeem_data.data.rst === "1") {
                this.setState({
                    showSuccessModal: true,
                    showLoadingModal: false,
                    successMsg: nextProps.redeem_data.data.msg,
                });
            } else {
                this.setState({
                    showErrorModal: true,
                    showLoadingModal: false,
                    errMsg: nextProps.redeem_data.data.msg,
                });
            }
        }

        if (nextProps.purchase_rate !== this.props.purchase_rate) {
            if (nextProps.purchase_rate.rst === "1") {
                let array;
                array = nextProps.purchase_rate;
                this.setState(
                    {
                        showLoadingModal: false,
                        successMsg: t("withdrawal." + array.msg),
                        purchaseRate: array.data,
                    },
                    () => {}
                );
            } else {
                this.setState({
                    errMsg: nextProps.purchase_rate.msg,
                });
            }
        }
    }

    handleBack() {
        this.props.history.goBack();
    }

    handleModalClose(e, type) {
        switch (type) {
            case "error":
                this.setState({ showErrorModal: false });
                break;
            case "success":
                this.setState({ showSuccessModal: false });
                break;
            default:
                break;
        }
    }

    handleRedemption(e, type, bool) {
        if (type === "submit") {
            this.setState({
                showConfirmationModal: false,
                showLoadingModal: true,
            });
            const data = {};

            data.email = sessionStorage.getItem("email");
            data.lang = sessionStorage.getItem("lang");

            this.props.postRedeemRequest(data);
        } else {
            this.setState({ showConfirmationModal: bool });
        }
    }

    render() {
        const {
            star_bonus_income,
            showConfirmationModal,
            showErrorModal,
            showSuccessModal,
            showLoadingModal,
            errMsg,
            successMsg,
            purchaseRate,
        } = this.state;

        const text = <span>{t("network.click_to_redeem")}</span>;

        const p_rate = purchaseRate.map(function (item, i) {
            return (
                <div className="px-0 offset-md-2 col-md-8">
                    <div className="card mx-auto shadow">
                        <div className="card-body text-light">
                            <div className="row">
                                <div className="col-6 justify-content-center align-self-center">
                                    <h5 className="card-text text-uppercase profile-main-label">
                                        <span>{item.email}</span>
                                    </h5>
                                </div>
                                <div className="col-6 text-right">
                                    <span className="span-password-asterisk">
                                        {item.avg_price}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className="network">
                <PageHeader
                    title={t("average.title")}
                    titleHeader="col-12 text-center"
                    prevousPageLink=""
                    navClass=""
                    goBackFunc={this.handleBack}
                    historyBackClass="py-2"
                    avatarDivClass="text-center header"
                    avatarLevel="title white-color"
                    walletLabel=""
                    arrowLeft={true}
                    headerBanner={false}
                />

                {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="withdrawal-loading-modal"
                        modalClassContent="loading-bg"
                    />
                )}

                {showConfirmationModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ConfirmationIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{t("network.are_you_sure_redeem")}</h4>
                                </div>
                            </div>
                        }
                        closeText={t("purchasePin.modal_close")}
                        closeClassName="btn modal-btn"
                        closeFunc={(e) =>
                            this.handleRedemption(e, "normal", false)
                        }
                        closeId=""
                        submitText={t("common.submit")}
                        submitClassName="depth-blue-btn"
                        submitFunc={(e) =>
                            this.handleRedemption(e, "submit", null)
                        }
                        submitId="submit"
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showConfirmationModal}
                        modalClassName=""
                        modalClassId="withdrawal-confirmation-modal"
                    />
                )}

                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={(e) => this.handleModalClose(e, "error")}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {showSuccessModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SuccessIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{successMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-blue-btn"
                        submitFunc={(e) => this.handleModalClose(e, "success")}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showSuccessModal}
                        modalClassName=""
                        modalClassId="success-modal"
                    />
                )}
                <div className="title offset-md-2 col-md-8">
                    <div className="row">
                        <div className="col-6 text-left">
                            <span>{t("average.email")}</span>
                        </div>
                        <div className="col-6 text-right">
                            <span>{t("average.average_rate")}</span>
                        </div>
                    </div>
                </div>
                {p_rate}
                <div className="container pt-5 ">
                    <div className="row">
                        <div className="mx-auto"></div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const { network, modal, market, walletBalance, purchaseRate } = state;

    return {
        network_data: network.data,
        redeem_data: network.redeem_data,
        classIsShowing: modal.classIsShowing,
        market_data: market.data,
        wallet_balance_data: walletBalance.lite_data,
        purchase_rate: purchaseRate.data,
    };
};

const mapDispatchToProps = {
    postNetwork,
    postRedeemRequest,
    postPurchaseRate,
};
export default connect(mapStateToProps, mapDispatchToProps)(Average);
