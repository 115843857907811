import React, { Component } from "react";
import PageHeader from "../../components/PageHeaderV2";
import { closeModal } from "../../store/ducks/modal/actions";
import { connect } from "react-redux";
import { GenHash } from "../../common/helpers";
import { postFriendListsV1 } from "../../store/ducks/myFriends/actions";
import { t } from "../../common/translation";
import LoadingMask from "../../components/loadingMask";
import DiamondOn from "../../public/images/icon-diamond-on@3x.png";
import DiamondOff from "../../public/images/icon-diamond-off@3x.png";
import HyperModal from "../../components/HyperModal";
import "./myFriends.scss";
import Modal from "../../components/modal";
import ErrorIcon from "../../components/ErrorAlert";
import { Link } from "react-router-dom";
import btnCancel from "../../public/v2/icon/icon-close.svg";

class myFriends extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: sessionStorage.getItem("email"),
            token: sessionStorage.getItem("token"),
            total_level_active_username: [],
            first_level_active_username: [],
            first_level_username: [],
            total_level_username: [],
            loading: false,
            screenHeight: window.innerHeight - 230,
            totalPageItems: "0",
            total_direct_downline: "-",
            total_direct_active_downline: "-",
            total_downline: "-",
            total_active_downline: "-",
            showErrorModal: false,
            total_level_show: false,
            first_level_show: true,
            total_level_active_show: false,
            first_level_active_show: false,
            showLoadingModal: true,
            errMsg: "",
            errType: "",
            searchIdx: 0,
            searchArr: [],
            loading_visible: false,
        };

        const data = {};

        data.rootUser = sessionStorage.getItem("email");
        data.username = sessionStorage.getItem("email");
        data.token = sessionStorage.getItem("token");
        data.page = 1;
        data.viewType = "LIST";
        // data.recursive = 1;
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;

        this.props.postFriendListsV1(data);

        this.renderItem = this.renderItem.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
        this.handleTotalLevelShow = this.handleTotalLevelShow.bind(this);
        this.handleFirstLevelShow = this.handleFirstLevelShow.bind(this);
        this.handleFirstLevelActiveShow = this.handleFirstLevelActiveShow.bind(
            this
        );
        this.handleTotalLevelActiveShow = this.handleTotalLevelActiveShow.bind(
            this
        );
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        // setLocale(sessionStorage.getItem("lang"));

        window.addEventListener("resize", this.updateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.friend_list !== this.props.friend_list) {
            if (nextProps.friend_list.rst === "1") {
                this.setState({
                    total_level_active_username:
                        nextProps.friend_list.data.total_level_active_username,
                    first_level_active_username:
                        nextProps.friend_list.data.first_level_active_username,
                    total_level_username:
                        nextProps.friend_list.data.total_level_username,
                    first_level_username:
                        nextProps.friend_list.data.first_level_username,
                    loading: false,
                    totalPageItems: nextProps.friend_list.data.totalPageItems,
                    total_direct_downline:
                        nextProps.friend_list.data.totalPageItems,
                    total_direct_active_downline:
                        nextProps.friend_list.data
                            .total_first_level_active_downline,
                    total_downline:
                        nextProps.friend_list.data.total_level_downline,
                    total_active_downline:
                        nextProps.friend_list.data.total_level_active_downline,
                    showLoadingModal: false,
                });
            } else {
                switch (nextProps.friend_list.msg) {
                    case "invalid_session":
                        let response = "'";
                        response = t("profile.token_expiry");

                        this.setState({
                            errMsg: response,
                            errType: "token_expiry",
                            showErrorModal: true,
                            showLoadingModal: false,
                        });
                        break;
                    default:
                        this.setState({
                            errMsg: nextProps.friend_list.msg,
                            showErrorModal: true,
                            showLoadingModal: false,
                        });
                        break;
                }
            }
        }

        if (nextProps.isLoading !== this.props.isLoading) {
            this.setState({
                loading_visible: nextProps.isLoading,
            });
        }
    }

    handleBack() {
        let searchIdx = this.state.searchIdx - 1;
        let searchArr = this.state.searchArr;
        let data = {};

        if (searchIdx < 0) {
            this.props.history.goBack();
        } else {
            data.rootUser = sessionStorage.getItem("email");
            data.username = searchArr[searchIdx];
            data.token = sessionStorage.getItem("token");
            data.page = 1;
            data.viewType = "LIST";
            // data.recursive = 1;
            let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
            data.hash = hash;
            data.lang = sessionStorage.getItem("lang");

            this.props.postFriendListsV1(data);

            this.setState({
                username: data.username,
                searchIdx: searchIdx,
                searchArr: searchArr.slice(0, searchIdx),
                showLoadingModal: true,
            });
        }
    }

    handleClickSearch(e, search_username) {
        let searchArr = this.state.searchArr;
        let searchIdx = this.state.searchIdx;
        let data = {};

        searchIdx += 1;
        searchArr.push(this.state.username);

        data.rootUser = sessionStorage.getItem("email");
        data.username = search_username;
        data.token = sessionStorage.getItem("token");
        data.page = 1;
        data.viewType = "LIST";
        // data.recursive = 1;
        let hash = GenHash(data, process.env.REACT_APP_GM_SECRET_KEY);
        data.hash = hash;
        data.lang = sessionStorage.getItem("lang");

        this.props.postFriendListsV1(data);

        this.setState({
            username: data.username,
            searchIdx: searchIdx,
            searchArr: searchArr,
            showLoadingModal: true,
        });
    }

    handleFirstLevelActiveShow() {
        this.setState({
            total_level_show: false,
            first_level_show: false,
            total_level_active_show: false,
            first_level_active_show: true,
        });
    }

    handleTotalLevelActiveShow() {
        this.setState({
            total_level_show: false,
            first_level_show: false,
            total_level_active_show: true,
            first_level_active_show: false,
        });
    }

    handleFirstLevelShow() {
        this.setState({
            total_level_show: false,
            first_level_show: true,
            total_level_active_show: false,
            first_level_active_show: false,
        });
    }

    handleTotalLevelShow() {
        this.setState({
            total_level_show: true,
            first_level_show: false,
            total_level_active_show: false,
            first_level_active_show: false,
        });
    }

    handleErrorClose() {
        this.setState({ showErrorModal: false });

        if (this.state.errType === "token_expiry") {
            this.props.history.push("/login");
        }
    }

    updateHeight() {
        this.setState({ screenHeight: window.innerHeight - 230 });
    }

    renderItem(item, i) {
        let rankingImg = [];
        let maxRanking = 5;

        for (let i = 0; i < item.rank; i++) {
            rankingImg.push(
                <img
                    src={DiamondOn}
                    alt={i}
                    key={i}
                    width={9}
                    className="mb-1 mr-1"
                />
            );
        }

        for (let i = item.rank; i < maxRanking; i++) {
            rankingImg.push(
                <img
                    src={DiamondOff}
                    alt={i}
                    key={i}
                    width={9}
                    className="mb-1 mr-1"
                />
            );
        }

        const itemRows = [
            <li
                className="list-group-item asset-panel-list-group-item"
                key={i}
                onClick={(e) => this.handleClickSearch(e, item.username)}
            >
                <div className="row">
                    <div className="col-7">
                        <div className="col-12 text-left px-0 text-truncate">
                            <label className="asset-list text-truncate">
                                {"****" + item.username.slice(4)}
                            </label>
                        </div>
                        <div className="col-12 text-left px-0 text-truncate">
                            {item.rank !== "0" && (
                                <div className="col-12 text-left px-0">
                                    {rankingImg}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-5">
                        {item.package !== "0" && (
                            <div className="col-12 text-right px-0 text-truncate">
                                <small className="text-uppercase">
                                    {t("myFriends." + item.package)}
                                </small>
                            </div>
                        )}
                        {item.package === "0" && (
                            <div className="col-12 text-right px-0 text-truncate">
                                <small className="text-uppercase">
                                    {t("myFriends.celeb")}
                                </small>
                            </div>
                        )}
                        {item.date_joined !== "0" && (
                            <div className="col-12 text-right px-0 text-truncate">
                                <small>{t("myFriends.date_joined")}:</small>
                                &nbsp;
                                <small className="text-uppercase">
                                    {item.date_joined}
                                </small>
                            </div>
                        )}
                        <div className="col-12 text-right px-0">
                            <small>{t("myFriends.sales")}</small>
                            &nbsp;
                            <small>${item.sales}</small>
                        </div>
                    </div>
                </div>
            </li>,
        ];

        return itemRows;
    }

    render() {
        const {
            username,
            showLoadingModal,
            first_level_username,
            total_level_username,
            total_level_active_show,
            first_level_active_show,
            total_level_show,
            first_level_show,
            total_level_active_username,
            first_level_active_username,
            loading,
            total_direct_downline,
            total_downline,
            showErrorModal,
            errMsg,
            loading_visible,
        } = this.state;

        let total_level_active_Rows = [];
        let first_level_active_Rows = [];
        let total_level_Rows = [];
        let first_level_Rows = [];

        if (first_level_username.length > 0) {
            first_level_username.map((item, i) => {
                const perItemRows = this.renderItem(item, i);
                first_level_Rows = first_level_Rows.concat(perItemRows);

                return first_level_Rows;
            });
        }

        if (total_level_username.length > 0) {
            total_level_username.map((item, i) => {
                const perItemRows = this.renderItem(item, i);
                total_level_Rows = total_level_Rows.concat(perItemRows);

                return total_level_Rows;
            });
        }

        if (total_level_active_username.length > 0) {
            total_level_active_username.map((item, i) => {
                const perItemRows = this.renderItem(item, i);
                total_level_active_Rows = total_level_active_Rows.concat(
                    perItemRows
                );

                return total_level_active_Rows;
            });
        }

        if (first_level_active_username.length > 0) {
            first_level_active_username.map((item, i) => {
                const perItemRows = this.renderItem(item, i);
                first_level_active_Rows = first_level_active_Rows.concat(
                    perItemRows
                );

                return first_level_active_Rows;
            });
        }

        return (
            <div className="myFriends">
                {showErrorModal && (
                    <Modal
                        title=""
                        children={
                            <div className="row">
                                <div className="col-12 text-center">
                                    <ErrorIcon
                                        width="100px"
                                        height="0px !important"
                                    />
                                    <h4>{errMsg}</h4>
                                </div>
                            </div>
                        }
                        closeText=""
                        closeClassName=""
                        closeFunc=""
                        closeId=""
                        submitText={t("common.close")}
                        submitClassName="depth-red-btn"
                        submitFunc={this.handleErrorClose}
                        submitId=""
                        modalFooterClassName="custom-modal-footer"
                        isShowing={showErrorModal}
                        modalClassName=""
                        modalClassId="error-modal"
                    />
                )}

                {/* {showLoadingModal && (
                    <Modal
                        title=""
                        children={
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="mx-auto">
                                        <LoadingMask width={80} />
                                    </div>
                                </div>
                            </div>
                        }
                        isShowing={showLoadingModal}
                        modalId="loading-modal"
                        modalClassName=""
                        modalClassId="loading-modal"
                        modalClassContent="loading-bg"
                    />
                )} */}

                <div className="container-fluid px-0 pb-2">
                    <PageHeader
                        titleHeader="col-12 text-center"
                        prevousPageLink=""
                        navClass=""
                        // historyBack="/profile"
                        goBackFunc={this.handleBack}
                        historyBackClass="py-2"
                        avatarDivClass="text-center header"
                        avatarLevel="title white-color"
                        walletLabel=""
                        walletType={""}
                        title={t("profile.my_friend")}
                        arrowLeft={true}
                        cancelBtnRight={
                            this.state.searchIdx > 0 ? (
                                <Link to="/profile">
                                    <img src={btnCancel} width={15} alt="" />
                                </Link>
                            ) : (
                                ""
                            )
                        }
                    />
                    {/*<div className="text-center myFriends-list">*/}
                    {/*    <div className="col-12">*/}
                    {/*        <img src={userIcon} alt="" width="90px"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className="offset-md-2 col-md-8 px-0">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <div className="form-group">
                                <div className="row">
                                    <small className="col-12 label-info">
                                        <b>
                                            {username}
                                            {t("myFriends.cardTitle")}
                                        </b>
                                    </small>
                                </div>
                            </div>
                            <div className="form-group border-bottom-label">
                                <div
                                    className="row"
                                    onClick={this.handleFirstLevelShow}
                                >
                                    <span className="col-8 text-uppercase label-info">
                                        {t("myFriends.total_direct_downline")}
                                    </span>
                                    <div className="col-4 text-right">
                                        {total_direct_downline}
                                    </div>
                                </div>
                            </div>
                            {/*<div className="form-group border-bottom-label">*/}
                            {/*    <div className="row" onClick={this.handleFirstLevelActiveShow}>*/}
                            {/*        <span className="col-8 text-uppercase label-info">{t("myFriends.total_direct_active_downline")}</span>*/}
                            {/*        <div className="col-4 text-right">{total_direct_active_downline}</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="form-group border-bottom-label">
                                <div
                                    className="row"
                                    onClick={this.handleFirstLevelShow}
                                >
                                    <span className="col-8 text-uppercase label-info">
                                        {t("myFriends.total_downline")}
                                    </span>
                                    <div className="col-4 text-right">
                                        {total_downline}
                                    </div>
                                </div>
                            </div>
                            {/*<div className="form-group border-bottom-label">*/}
                            {/*    <div className="row" onClick={this.handleTotalLevelActiveShow}>*/}
                            {/*        <span className="col-8 text-uppercase label-info">{t("myFriends.total_active_downline")}</span>*/}
                            {/*        <div className="col-4 text-right">{total_active_downline}</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>

                <div
                    className="container-fluid overflow-auto offset-md-2 col-md-8 px-0"
                    style={{ height: this.state.screenHeight }}
                >
                    <ul className="list-group list-group-flush mt-1">
                        {total_level_active_show === true &&
                            total_level_active_Rows}

                        {first_level_active_show === true &&
                            first_level_active_Rows}

                        {total_level_show === true && total_level_Rows}

                        {first_level_show === true && first_level_Rows}
                    </ul>
                </div>
                <HyperModal
                    category="response"
                    modalVisible={loading_visible}
                    modalType="loading"
                    footer={[]}
                />
                {/* {loading && (
                    <div className="container pt-5">
                        <div className="row">
                            <div className="mx-auto">
                                <LoadingMask width={80} />
                            </div>
                        </div>
                    </div>
                )} */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { friends, walletBalance } = state;

    return {
        friend_list: friends.data,
        isLoading: walletBalance.isLoading,
    };
};

const mapDispatchToProps = {
    closeModal,
    postFriendListsV1,
};

export default connect(mapStateToProps, mapDispatchToProps)(myFriends);
