export const GET_PROFILE = "GET_PROFILE";
export const POST_UPDATE_LANG = "POST_UPDATE_LANG";
export const POST_LOGOUT = "POST_LOGOUT";
export const POST_BONUS_PAYOUT_STATUS = "POST_BONUS_PAYOUT_STATUS";
export const POST_UPLOAD_AVATAR = "POST_UPLOAD_AVATAR";
export const POST_UPDATE_PLACEMENT = "POST_UPDATE_PLACEMENT";
export const POST_MEMBER_PLACEMENT = "POST_MEMBER_PLACEMENT";
export const GET_PLACEMENT_DETAILS = "GET_PLACEMENT_DETAILS";
export const GET_PLACEMENT_TREE_DATA = "GET_PLACEMENT_TREE_DATA";
export const GET_PLACEMENT_TREE_NODES = "GET_PLACEMENT_TREE_NODES";
export const GET_SPONSOR_TREE_NODES = "GET_SPONSOR_TREE_NODES";
