import programStatement from "../../pages/ProgramStatement";

const routes = [
    {
        path: "/program/statement",
        component: programStatement,
    },
];

export default routes;
