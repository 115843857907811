import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
    redeem_data: []
};

const networkReducer = createReducer(initialState) ({

    [type.POST_NETWORK]: (state, action) => ({
        ...state,
        data: action.payload,
    }),

    [type.POST_REDEEM_REQUEST]: (state, action) => ({
        ...state,
        redeem_data: action.payload,
    })

});

export default networkReducer;