import * as type from './types';
import axios from 'axios';

export const postMultinaryTreeGroup = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/multinary', data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_MULTINARYTREE_GROUP,
            payload: response
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_MULTINARYTREE_GROUP,
            payload: {"data": {"rst":"0","msg":"system_error", "data": []}}
        })
    })
};

export const postMultinaryTreeDetails = (data) => dispatch => {
    axios.get(process.env.REACT_APP_GM_URL + '/member/multinary/details', {
        params: data,
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_MULTINARYTREE_GROUP_DETAILS,
            payload: response
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_MULTINARYTREE_GROUP_DETAILS,
            payload: {"data": {"rst":"0","msg":"system_error", "data": []}}
        })
    })
};