import * as type from './types';
import axios from 'axios';

export const getTwoFactorAuthentication = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/twoFA', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.GET_TWO_FACTOR_AUTHENTICATION,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.GET_TWO_FACTOR_AUTHENTICATION,
            payload: {"rst":"0","msg":"system_error"}
        })
    })
};

export const postTwoFactorAuthentication = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/twoFA/enable', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.POST_TWO_FACTOR_AUTHENTICATION,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_TWO_FACTOR_AUTHENTICATION,
            payload: {"rst":"0","msg":"system_error"}
        })
    })
};

export const verifyTwoFactorAuthentication = (res_data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/twoFA/verify', res_data, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }).then( (response) => {
        dispatch ({
            type: type.VERIFY_TWO_FACTOR_AUTHENTICATION,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.VERIFY_TWO_FACTOR_AUTHENTICATION,
            payload: {"rst":"0","msg":"system_error"}
        })
    })
}
