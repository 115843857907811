import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const postChangeSecondaryPasswordReducer = createReducer(initialState) ({

    [type.POST_CHANGE_SECONDARY_PASSWORD]: (state, action) => ({
        ...state,
        changeSecondaryPasswordData: action.payload,
    }),

    [type.POST_RESET_SECONDARY_PASSWORD]: (state, action) => ({
        ...state,
        resetSecondaryPasswordData: action.payload,
    }),
});

export default postChangeSecondaryPasswordReducer;