import * as type from './types';
import axios from 'axios';

export const resendAccountActivation = (data) => dispatch => {
    axios.post(process.env.REACT_APP_GM_URL + '/member/resend/account/activation', {
       "email": data['email'],
       "hash": data['hash']
    }, {
        headers: {
            'X-Authorization': 'INWILGX2OiteEiZBVpPTHiixPBJu8mjA5Bo0ekLnXra8KaK1PbTanAqr0lZic0w1'
        }
    }).then( (response) => {
        dispatch ({
            type: type.RESEND_ACCOUNT_ACTIVATION,
            payload: response
        })
    }).catch (err => {
        dispatch ({
            type: type.RESEND_ACCOUNT_ACTIVATION,
            payload: {"data":{"rst":"0","msg":"system_error"}}
        })
    })
};
