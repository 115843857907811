import ChangeSecondaryPassword from "../../pages/changeSecondaryPassword";
import ResetSecondaryPassword from "../../pages/resetSecondaryPassword";

const routes = [
    {
        path: "/secondaryPassword/update",
        component: ChangeSecondaryPassword
    },
    {
        path: "/secondaryPassword/reset",
        component: ResetSecondaryPassword
    }];

export default routes;